import {
  Alert,
  Box,
  Button,
  Menu,
  MenuItem,
  Snackbar,
  Typography
} from '@mui/material'

import Icon from 'components/atoms/Icons'
import useMenu from 'hooks/useMenu'
import useCommunityKebabMenuDropdown from './style'
import useModal from 'hooks/useModal'
import useSnackbar from 'hooks/useSnackbar'
import { Community } from 'ymca/models/community.model'
import { PublicUser } from 'ymca/models/user.model'
import { CommunityRole } from 'ymca/models/community-role.model'
import CommunityUpdateUserRoleModal from '../CommunityUpdateUserRoleModal'
import { useState } from 'react'

interface CommunityKebabMenuDropdownProps {
  community: Community
  member: PublicUser
  refetchQuery: Function
}

const CommunityManageMemberKebabMenu = ({
  member,
  community,
  refetchQuery
}: CommunityKebabMenuDropdownProps) => {
  const classes = useCommunityKebabMenuDropdown()
  const [updatingRole, setUpdatingRole] = useState<CommunityRole | undefined>(
    undefined
  )

  const {
    isOpen: isKebabMenuOpen,
    open: openKebabMenu,
    anchorEl: kebabMenuAnchorEl,
    close: closeKebabMenu
  } = useMenu()

  const {
    isOpen: isUpdateRoleOpen,
    open: openUpdateRole,
    close: closeUpdateRole
  } = useModal()

  const { isOpen: isCpSnkbarOpen, close: closeCpSnkbar } = useSnackbar()

  const handleMenuOpenDropdown = (e: any) => {
    openKebabMenu(e)
  }
  const handleMenuCloseDropdwon = (e: any) => {
    closeKebabMenu()
  }

  const handleUpdateRole = (e: any, updatingRole: CommunityRole) => {
    setUpdatingRole(updatingRole)
    openUpdateRole()
    closeKebabMenu()
  }

  const { communityRole } = member

  const isNotBannedUser = communityRole && communityRole !== 'banned'

  const canMakeAdmin = isNotBannedUser && communityRole !== 'admin'
  const canMakeModerator = isNotBannedUser && communityRole !== 'moderator'

  const canUnbanUser = communityRole && communityRole === 'banned'

  const canPerformAnAction = canUnbanUser || canMakeAdmin || canMakeModerator

  return (
    <>
      {canPerformAnAction && (
        <Button
          className={classes.KebabMenuBtn}
          onClick={handleMenuOpenDropdown}
          aria-controls={
            isKebabMenuOpen ? 'manage-community-kebab-menu' : undefined
          }
          aria-haspopup='true'
          aria-expanded={isKebabMenuOpen ? 'true' : undefined}
          id='manage-community-kebab-menu'
        >
          <Icon icon='kebab-menu' />
        </Button>
      )}

      <Menu
        anchorEl={kebabMenuAnchorEl}
        id='manage-community-kebab-menu'
        aria-labelledby='manage-community-kebab-menu'
        open={isKebabMenuOpen}
        onClose={handleMenuCloseDropdwon}
        className={classes.kebabMenuMenu}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom'
        }}
      >
        {canMakeAdmin && (
          <MenuItem
            className={`${classes.kebabMenuMainMenuItem}`}
            onClick={(e: any) => handleUpdateRole(e, 'admin')}
          >
            <Box className={`${classes.kebabMenuMainMenuItemContent}`}>
              <Typography className={classes.kebabMenuMainMenuText}>
                Make Admin
              </Typography>
            </Box>
          </MenuItem>
        )}
        {canMakeModerator && (
          <MenuItem
            className={`${classes.kebabMenuSecondaryMenuItem}`}
            onClick={(e: any) => handleUpdateRole(e, 'moderator')}
          >
            <Typography className={classes.kebabMenuSecondaryMenuText}>
              Make Moderator
            </Typography>
          </MenuItem>
        )}

        {/* {isNotBannedUser && (
          <MenuItem className={`${classes.kebabMenuMainMenuItem}`}>
            <Box className={`${classes.kebabMenuMainMenuItemContent}`}>
              <Typography className={classes.kebabMenuMainMenuText}>
                Ban User
              </Typography>
            </Box>
          </MenuItem>
        )}
        {canUnbanUser && (
          <MenuItem className={`${classes.kebabMenuMainMenuItem}`}>
            <Box className={`${classes.kebabMenuMainMenuItemContent}`}>
              <Typography className={classes.kebabMenuMainMenuText}>
                Unban User
              </Typography>
            </Box>
          </MenuItem>
        )} */}
      </Menu>

      {community.id && updatingRole && (
        <CommunityUpdateUserRoleModal
          isOpen={isUpdateRoleOpen}
          close={closeUpdateRole}
          avatarSrc={community.avatar?.urlOptimised500x500}
          community={community}
          member={member}
          refetchQuery={refetchQuery}
          updatingRole={updatingRole}
        />
      )}
      <Snackbar
        open={isCpSnkbarOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={closeCpSnkbar}
      >
        <Alert>Link Copied</Alert>
      </Snackbar>
    </>
  )
}

export default CommunityManageMemberKebabMenu
