import { styled, IconButton } from "@mui/material";
import Icon from "components/atoms/Icons";
import Typography from "components/atoms/Typography";
import { usePublicationContext } from "context/PublicationContext";
import { BREAKPOINT_MOBILE_SMALL } from "theme/shared/breakpoint";

const Wrapper = styled(IconButton, {
  name: "PublicationCommentCountWrapper",
})(({ theme }) => ({
  "&.MuiButtonBase-root": {
    display: "flex",
    alignItems: "center",
    gap: ".3rem",
    padding: ".2rem .5rem",
    borderRadius: "6.2rem",
    transition: "transform 0.1s ease-out",

    border: `1px solid ${theme.palette.divider}`,

    "&:hover": {
      transform: "translateY(1px)",
    },
    "&:active": {
      boxShadow: "none",
      transform: "translateY(4px)",
    },

    [`@media (max-width: ${BREAKPOINT_MOBILE_SMALL}px)`]: {
      gap: ".2rem",
    },
  },
}));

const CommentIcon = styled(Icon)(({ theme }) => ({
  fontSize: "1.2rem",
  [`@media (max-width: ${BREAKPOINT_MOBILE_SMALL}px)`]: {
    fontSize: "1rem",
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-body1": {
    fontSize: "1rem",
    [`@media (max-width: ${BREAKPOINT_MOBILE_SMALL}px)`]: {
      fontSize: ".75rem",
    },
  },
}));

export interface PublicationCommentCountProp {
  onModalToggle: Function | any;
}

const PublicationCommentCount = ({
  onModalToggle = () => {},
}: PublicationCommentCountProp): JSX.Element => {
  const { totalComments } = usePublicationContext();
  return (
    <Wrapper onClick={onModalToggle}>
      <CommentIcon icon='comment' />
      <StyledTypography fontWeight={800}>{totalComments}</StyledTypography>
    </Wrapper>
  );
};

export default PublicationCommentCount;
