import { makeStyles } from '@material-ui/core/styles'
import colors from 'theme/dark/colors'

const useStyles = makeStyles({
  root: {
    border: (props: RoundedButtonProps) => props.border ?? '1px solid',
    height: 'fit-content !important',
    fontSize: 'inherit !important',
    borderColor: (props: RoundedButtonProps) => props.borderColor,
    background: (props: RoundedButtonProps) => props.background ?? colors.background,
    width: (props: RoundedButtonProps) => props.width,
    minWidth: (props: RoundedButtonProps) => props.minWidth,
    borderRadius: (props: RoundedButtonProps) => props.borderRadius ?? '25px',
    padding: (props: RoundedButtonProps) => props.padding ?? '10px 25px',
    cursor: (props: RoundedButtonProps) => props.cursor ?? 'pointer',
    textAlign: 'center',
    '& h2': {
      fontSize: (props: RoundedButtonProps) => props.fontSize,
      color: (props: RoundedButtonProps) => props.color,
      transition: 'all .5s',
      '&:hover': {
        color: (props: RoundedButtonProps) => props.textColor ?? colors.white
      }
    },
    '&:hover': {
      background: (props: RoundedButtonProps) => props.hoverColor ?? 'var(--main-gradient)',
      opacity: '0.8',
      '& h2': {
        color: (props: RoundedButtonProps) => props.textColor ?? colors.white
      }
    }
  },
  disabled: {
    pointerEvents: 'none',
    color: 'rgba(16, 16, 16, 0.3)',
    background: 'rgba(19, 1, 1, 0.3) !important'
  }
})

interface RoundedButtonProps {
  border?: string
  borderRadius?: string
  width?: string
  hoverColor?: string
  title?: string
  color?: string
  borderColor?: string
  minWidth?: string
  padding?: string
  fontSize?: string
  cursor?: string
  textColor?: string
  background?: string
  handleSubmit?: any
  disabled?: any
}

const RoundedButton = (props: RoundedButtonProps): JSX.Element => {
  const classes = useStyles(props)

  return (
    <button
      onClick={props.handleSubmit}
      className={`${classes.root} ${props.disabled}`}
    >
      <h2 className='m-0 font-medium'>{props.title}</h2>
    </button>
  )
}

export default RoundedButton
