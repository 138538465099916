import { makeStyles } from '@material-ui/core/styles'

const useImagePictureStyles = makeStyles({
  picture_default: {
    display: 'flex',
    height: '100%',
    width: '100%',
    'justify-content': 'center'
  }
})

export default useImagePictureStyles
