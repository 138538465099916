import {
  FileType,
  FileTypeMap,
  ImageFileTypeExtensionEnum,
  ImageFileTypeExtensionType,
  VideoFileTypeExtensionType
} from 'constants/meme'

interface FileMetadata {
  size: number
  type: FileType
  extension: ImageFileTypeExtensionType | VideoFileTypeExtensionType
}

const getFileMetadata = (file: File): FileMetadata => {
  const { size, type: fileType } = file
  const typeInfo = fileType.split('/')
  let type = typeInfo?.[0] as FileType
  const extension = typeInfo?.[1] as
    | ImageFileTypeExtensionType
    | VideoFileTypeExtensionType

  if (type === FileTypeMap.image) {
    // check if extension is gif
    if (extension === ImageFileTypeExtensionEnum.gif) {
      type = FileTypeMap.gif
    }
  }

  return {
    type,
    extension,
    size
  }
}
export default getFileMetadata
