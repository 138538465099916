import { makeStyles } from "@material-ui/core/styles";
import colors from "theme/dark/colors";
import { BREAKPOINT_LAPTOP } from "theme/shared/breakpoint";

const useCommunitiesGridContainerStyles = makeStyles({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(260px, 1fr))",
    gridAutoRows: "1fr",
    gap: "1rem",
  },
  pageTitleCreateFabBtn: {
    display: "none",
    borderRadius: "6.25rem",

    [`@media (max-width: ${BREAKPOINT_LAPTOP}px)`]: {
      display: "flex",
      alignItems: "center",

      border: `1px solid ${colors.primary500}`,
      background: "rgba(51, 64, 92, .8)",
      boxShadow: "0px 4px 8px 0px rgba(14, 20, 34, 0.20)",

      color: "#FFF",
      textAlign: "center",
      fontFamily: "Inter",
      fontSize: "0.9375rem",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "1.1875rem",
      letterSpacing: "-0.04375rem",

      position: "fixed",
      bottom: "10%",
    },
  },
});

export default useCommunitiesGridContainerStyles;
