import { Image as MemeImage } from 'ymca/models/image.model'

interface RenderedImage extends MemeImage {
  urlOptimised50x50?: string
}

interface ImageResolutionResults {
  x1: string
  x2: string
  x3: string
  x4: string
  fallback: string
  srcSet: string
}

const getImageResolutions = (image: RenderedImage): ImageResolutionResults => {
  if (!image)
    return {
      x1: '',
      x2: '',
      x3: '',
      x4: '',
      fallback: '',
      srcSet: ''
    }
  const x1 = image.urlOptimised50x50 ?? image.urlOptimised200x200
  const x2 = image.urlOptimised200x200
  const x3 = image.urlOptimised500x500
  const x4 = image.urlOptimised1000x1000
  return {
    x1,
    x2,
    x3,
    x4,
    fallback: image.jpegUrl,
    srcSet: `${x1} 1x, ${x2} 2x, ${x3} 3x, ${x4} 4x`
  }
}

type ImageSourceType = 'image/avif' | 'image/webp' | 'image/jpeg' | 'image/png'

export const getImageUrlSourceType = (imageUrl: string): ImageSourceType => {
  const isAvif = imageUrl.endsWith('.avif')
  const isWebp = imageUrl.endsWith('.webp')
  const isJpeg = imageUrl.endsWith('.jpeg')
  const isPng = imageUrl.endsWith('.png')

  let imageSourceType: ImageSourceType = 'image/png'

  if (isAvif) {
    imageSourceType = 'image/avif'
  }
  if (isWebp) {
    imageSourceType = 'image/webp'
  }
  if (isJpeg) {
    imageSourceType = 'image/jpeg'
  }

  if (isPng) {
    imageSourceType = 'image/png'
  }

  return imageSourceType
}


export default getImageResolutions
