import { useCallback, useContext } from "react";
import toast from 'react-hot-toast';
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { ConnectorNames, connectorsByName } from "../utils/web3/web3React";
import {
  connectorChainLocalStorageKey,
  connectorLocalStorageKey,
} from "../config";
import { setupNetwork } from "utils/web3/wallet";
import { NoEthereumProviderError, UserRejectedRequestError as UserRejectedRequestErrorInjected } from "@web3-react/injected-connector";
import { WalletConnectConnector, UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from "@web3-react/walletconnect-connector";
const useWeb3 = () => {
  
  const { activate, deactivate } = useWeb3React();

  const login = useCallback(
    async (connectorID: ConnectorNames, chainId: number) => {
      const connector = connectorsByName[connectorID];
      if (connector) {
        activate(connector, async (error: Error) => {
          if (error instanceof UnsupportedChainIdError) {
            let hasSetup
            if (connector instanceof WalletConnectConnector) {
              toast.error('Unsupported network')
              return;
            } else {
              const provider = await connector.getProvider()
              hasSetup = await setupNetwork(chainId, provider)
            }
            if (hasSetup) {
              setTimeout(() => activate(connector), 500)
            }
          } else {
            localStorage.removeItem(connectorLocalStorageKey)
            if (error instanceof NoEthereumProviderError) {
              toast.error('No provider was found.')
              return;
            } else if (
              error instanceof UserRejectedRequestErrorInjected || error instanceof UserRejectedRequestErrorWalletConnect
            ) {
              if (connector instanceof WalletConnectConnector) {
                const walletConnector = connector as WalletConnectConnector
                walletConnector.walletConnectProvider = undefined;
              }
              toast.error('Please allow access to your account')
              return;
            } else {
              console.error(error)
              toast.error('Unable to connect to wallet at the moment')
              return;
            }
          }
        });
      } else {
        console.error("The connector config is wrong");
        return("The connector config is wrong");
      }
    },
    [activate]
  );

  const logout = useCallback(() => {
    if (localStorage.getItem("walletconnect")) {
      connectorsByName.walletconnect.close();
      connectorsByName.walletconnect.walletConnectProvider = null;
    }
    if (localStorage.getItem(connectorLocalStorageKey)) {
      localStorage.removeItem(connectorLocalStorageKey);
    }
    if (localStorage.getItem(connectorChainLocalStorageKey)) {
      localStorage.removeItem(connectorChainLocalStorageKey);
    }
    deactivate();
  }, [deactivate]);

  return { login, logout };
};

export default useWeb3;
