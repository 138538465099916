import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Typography } from '@material-ui/core'
import colors from 'theme/dark/colors'

export interface UnfollowModalProps {
  open: boolean // initial state of the modal
  handleClose: () => void // function called when modal is closed
  displayName: string // name of the user to unfollow
  handleRemoveFollow: () => void // function called when unfollow button is clicked
}

const useStyles = makeStyles(({
  paper: {
    position: 'absolute',
    width: 371,
    height: 264,
    backgroundColor: colors.darkBlue,
    boxShadow: '0px 3px 10px #0000001A',
    boxSizing: 'border-box',
    borderRadius: '15px',
    overflow: 'hidden !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&:focus': {
      outline: 'none'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: 20,
    marginBottom: '10px',
    fontWeight: 700,
    color: '#E2E4E9'
  },
  content: {
    textAlign: 'left',
    fontSize: 14,
    color: '#808893',
    fontWeight: 500,
    letterSpacing: '-0.3px',
    marginTop: 8,
    lineHeight: '24px'
  },
  backbtn: {
    height: '44px',
    width: '40%',
    borderRadius: '7px',
    fontWeight: 600,
    fontSize: '14px',
    color: '#808893',
    boxShadow: 'none',
    marginRight: 8,
    border:'1px solid #FF5178'
  },
  changebtn: {
    height: '44px',
    width: 256,
    backgroundColor: '#FF5178',
    borderRadius: '7px',
    fontWeight: 600,
    fontSize: '14px',
    color: '#FFFFFF',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#FF5178'
    }
  },
  btnbox: {
    alignSelf: 'center',
    backgroundColor: '#161A21',
    width: '100%',
    height: 80,
    alignItems: 'center',
    padding:'0 1rem'
  }
}))

const UnfollowModal = ({
  open,
  handleClose,
  displayName,
  handleRemoveFollow,
}: UnfollowModalProps): JSX.Element => {
  const classes = useStyles()

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <Box className={classes.paper}>
        <Box style={{ padding: 30 }} data-test-memer-unfollow-modal>
          <Typography className={classes.title}>
            Unfollow {displayName}?
          </Typography>
          <Typography className={classes.content}>
            Their memes and overall profile activity, will stop showing in your feed. You can always check out their profile anytime. Are you sure about this?
          </Typography>
        </Box>
        <Box display='flex' className={classes.btnbox}>
          <Button onClick={handleClose} className={classes.backbtn}>
            Cancel
          </Button>
          <Button onClick={handleRemoveFollow} className={classes.changebtn} data-test-memer-unfollow-confirm>
            Unfollow
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default UnfollowModal
