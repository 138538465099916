import useShow from "hooks/useShow";
import { useState } from "react";
import { Post } from "ymca/models/post.model";
import { DefaultYMCA } from "ymca/ymca";

interface UseBookmarkProps {
  post: Post;
}
export interface UseBookmark {
  isBookmarked: boolean;
  handleBookmark: (post: Post) => void;
  isShowing: boolean;
  hideShowUpdate: () => void;
}

const useBookmark = ({ post }: UseBookmarkProps): UseBookmark => {
  const { show, hide, isShowing } = useShow(false);
  const [isBookmarked, setIsBookmarked] = useState<boolean>(
    post.isBookmarkedByUser
  );

  const addBookmarks = async (post: Post): Promise<void> => {
    await DefaultYMCA.bookmarkService.createBookmark(post);
  };

  const removeBookmark = async (post: Post): Promise<void> => {
    await DefaultYMCA.bookmarkService.deleteBookmark(post);
  };

  const handleBookmark = async (post: Post) => {
    if (isBookmarked) {
      await removeBookmark(post);
      // show snackbar
      setIsBookmarked(false);
      show();
    } else {
      await addBookmarks(post);
      // show snackbar
      setIsBookmarked(true);
      show();
    }
  };

  return {
    isBookmarked,
    handleBookmark,
    isShowing,
    hideShowUpdate: hide,
  };
};

export default useBookmark;
