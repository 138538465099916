import Helmet from 'react-helmet';

export type MetaTagsProps = {
    show: boolean;
    title?: string;
    description?: string;
    thumbnail?: string;
    url?: string;
}

/**
 * MetaTags component, for SEO purposes.
 * Set `props.show` to true to change the meta tags. This is useful
 * especially if you have some common components that are used across pages.
 */
const MetaTags = (props: MetaTagsProps): JSX.Element => {
    let { show, title, description, thumbnail, url } = props;
    if (!show) return <></>;
    if (!title) title = 'The Best Memes! | YouMeme';
    if (!description) description = 'The World’s First Meme-to-Earn Social Network. Select a meme template, add text, images, stickers and save your meme. Your daily dose of meme is here.';
    if (!thumbnail) thumbnail = `${process.env.REACT_APP_DEPLOYMENT_URL}/thumb.jpg`;
    if (!url) url = `${process.env.REACT_APP_DEPLOYMENT_URL}`;
    return (
        <Helmet>
          <link
            rel='canonical'
            href={url}
            data-react-helmet='true'
          />
          <title>{title}</title>
          <meta
            name='description'
            data-react-helmet='true'
            content={description}
          />
          <meta
            name='thumbnail'
            content={thumbnail}
            data-react-helmet='true'
          />
          {/*Facebook*/}
          <meta
            property='og:title'
            content={title}
            data-react-helmet='true'
          />
          <meta
            property='og:description'
            content={description}
            data-react-helmet='true'
          />
          <meta
            property='og:url'
            content={url}
            data-react-helmet='true'
          />
          <meta
            property='og:image'
            content={thumbnail}
            data-react-helmet='true'
          />
          {/*Twitter*/}
          <meta
            name='twitter:title'
            content={title}
            data-react-helmet='true'
          />
          <meta
            name='twitter:description'
            data-react-helmet='true'
            content={description}
          />
          <meta
            name='twitter:url'
            content={url}
            data-react-helmet='true'
          />
          <meta
            name='twitter:image'
            data-react-helmet='true'
            content={thumbnail}
          />
        </Helmet>
    );
}

export default MetaTags;
