import { Box, Button, Popover, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { Post as PostModel } from 'ymca/models/post.model'
import { MetaTags } from 'components/shared'
import AlertText from 'components/shared/AlertText'
import PublicationContent from '../PublicationContent/PublicationContent'
import PublicationHeader from '../PublicationHeader/PublicationHeader'
import PublicationFooter from '../PublicationFooter/PublicationFooter'

import PublicationContentTitle from '../PublicationContentTitle/PublicationContentTitle'
import type { HeadingType } from 'components/memePublication/PublicationContentTitle/PublicationContentTitle'
import PublicationContentDescription from '../PublicationContentDescription/PublicationContentDescription'
import colors from 'theme/dark/colors'
import { BREAKPOINT_MOBILE_LARGE } from 'theme/shared/breakpoint'
import usePublicationMainStyles from './style'
import PublicationModalMain from './publication-modal-main'

interface PublicationMainGridContainerProps {
  modal?: boolean
  show_comment?: boolean
}
const PublicationMainGridContainer = styled(`div`, {
  name: 'PublicationMainGridContainer'
})<PublicationMainGridContainerProps>(({ modal, show_comment }) => {
  const style = {
    padding: '1rem',
    display: 'grid',

    gridTemplateAreas: `
      "publcationHeader"
      "publicationContentTitle"
      "publicationContent"
      "publicationContentDescription"
      "publicationFooter"
    `,
    gridTemplateRows: 'repeat(5, max-content)',
    gridTemplateColumns: '1fr',
    gap: '.5rem',
    borderBottom: `${show_comment ? '0' : '1px'} solid ${colors.border}`,

    [`@media (max-width: ${BREAKPOINT_MOBILE_LARGE}px)`]: {
      padding: '.625rem'
    }
  }

  if (modal) {
    style.gridTemplateAreas = `
      "publicationModalContentLeft publicationModalContentRight"
    `
    style.gridTemplateRows = '100vh'
    style.gridTemplateColumns = 'minmax(400px,2fr) minmax(350px, 1fr)'

    style.borderBottom = '0'
    style.padding = '0'
  }

  return style
})

export interface IonScreenProps {
  isOnScreen: boolean
  modal: boolean
}
export interface PublicationMainProp {
  post: PostModel
  onModalToggle?: () => void
  modal?: boolean
  play?: boolean // auto plays video
  onScreenProps?: IonScreenProps
  fontSize?: string
  setEvent?: any
  modalPage?: boolean
  contestModal?: boolean
  showComment?: boolean
  headingType?: HeadingType
  disableEditMeme?: boolean
  copyLinkBasePath?: string
}

const PublicationMain = (props: PublicationMainProp): JSX.Element => {
  const classes = usePublicationMainStyles()

  let {
    post,
    modal,
    modalPage,
    onModalToggle = () => {},
    showComment,
    headingType
  } = props

  const [copy, setCopy] = useState<boolean>(false)
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<{
    element: Element
    type: string
  } | null>(null)

  const navigate = useNavigate()
  const [actionToPerform, setActionToPerform] = useState('')
  const currentUrl = window.location.href
  const isMemePage = currentUrl.includes('/meme/')
  if (isMemePage) headingType = 'h1'

  useEffect(() => {
    setTimeout(() => {
      setCopy(false)
    }, 2000)
  }, [copy])

  useEffect(() => {
    setTimeout(() => {
      setDeleteAlert(false)
    }, 2000)
  }, [deleteAlert])

  return (
    <Box>
      {deleteAlert && (
        <AlertText show={deleteAlert} text='Meme has been deleted' />
      )}
      <MetaTags
        show={isMemePage && window.location.href.includes(post.id)}
        title={post.seo.title}
        description={post.seo.description}
        thumbnail={post.seo.image}
        url={post.seo.url}
      />

      <PublicationMainGridContainer
        modal={modal}
        data-test-post={post}
        show_comment={showComment}
      >
        {modal ? (
          <PublicationModalMain {...props} />
        ) : (
          <>
            <PublicationHeader {...props} />
            <PublicationContentTitle post={post} headingType={headingType} />
            <PublicationContent {...props} onToggle={onModalToggle} />
            <PublicationContentDescription {...props} />
            <PublicationFooter
              {...props}
              onModalToggle={onModalToggle}
              showComment={showComment}
            />
          </>
        )}
      </PublicationMainGridContainer>

      <Popover
        open={Boolean(anchorEl?.element) && anchorEl?.type === 'authPopover'}
        anchorEl={anchorEl?.element}
        onClose={() => {
          setActionToPerform('')
          setAnchorEl(null)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        disablePortal
      >
        <Box className={classes.authPopoverWrapper}>
          <Box className={classes.authPopoverContentWrapper}>
            <Typography className={classes.authPopoverTitle}>
              {actionToPerform === 'comment'
                ? 'Log in or sign up to leave a comment'
                : 'Log in or sign up to add a reaction'}
            </Typography>
          </Box>
          <Box className={classes.authPopoverContentWrapper}>
            <Button variant='contained' onClick={() => navigate('/auth')}>
              Sign up/Log in
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}

export default PublicationMain
