import { makeStyles } from "@material-ui/core/styles";
import colors from "theme/dark/colors";
import { BREAKPOINT_TABLET_MEDIUM } from "theme/shared/breakpoint";

const useCommunityMembersStyles = makeStyles({
  wrapper: {},
  mainAdmin: {
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #2A3753",

    gap: ".75rem",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    gap: ".75rem",
    borderBottom: "1px solid #2A3753",

    padding: "0 1rem 1rem",

    fontSize: "1.5rem",

    [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
      padding: ".5rem .25rem",
      fontSize: "1.1rem",
    },
  },
  headerTitle: {
    color: colors.textPrimary,
    fontFamily: "Inter",
    fontSize: "1em",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.01875rem",
  },
  goBackBtn: {
    "&.MuiIconButton-root": {
      padding: 0,
    },
  },
});

export default useCommunityMembersStyles;
