import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'
import { useMediaQuery } from '@material-ui/core'
import colors from 'theme/dark/colors'
import { Image } from 'components/shared'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'
import {
  BREAKPOINT_MOBILE_LARGE,
  BREAKPOINT_MOBILE_MEDIUM,
  BREAKPOINT_TABLET_MEDIUM
} from 'theme/shared/breakpoint'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

const useStyles = makeStyles({
  root: {
    fontSize: '1rem',
    width: '68.375em',
    borderRadius: 15,
    background: colors.mainGreyOpaque,
    display: 'flex',
    padding: '1.875em',
    boxSizing: 'border-box'
  },
  wrapper: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '.75rem'
  },
  modalBtnGroup: {
    right: 0,
    position: 'absolute',
    marginTop: 15,
    marginRight: 15,
    display: 'flex'
  },
  closeIcon: {
    width: 44,
    height: 44,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginLeft: 6,
    '&:hover': {
      background: '#1A1E28'
    }
  },
  backBtn: {
    width: 110,
    color: '#C2C9D4',
    backgroundColor: colors.memeGenBlue50
  },
  back: {
    display: 'none'
  },
  titleWrapper: {
    paddingTop: 15,
    paddingLeft: 15
  },
  titleTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > picture': {
      height: '1.28rem'
    }
  },
  wrapperMobile: {},
  title: {
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: 22,
    lineHeight: '27px',
    color: '#C2C9D4',
    paddingBottom: 10
  },
  logotitle: {
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '1.5rem',
    marginLeft: '0.3rem',
    color: '#FFFFFF'
  },
  lawText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '0.75em',
    lineHeight: 1.192,
    letterSpacing: -0.025,
    position: 'absolute',
    bottom: '-3em',
    width: '100%',
    left: 0,
    right: 0,
    textAlign: 'center',

    '& > a': {
      textDecoration: 'var(--gradient-fall-back) solid underline'
    }
  },
  closeIconNew: {
    width: '3.18664em',
    height: '3.18664em',
    borderRadius: '50%',
    position: 'absolute',
    cursor: 'pointer',
    background: 'var(--gradient-fall-back)',
    display: 'flex',
    fontSize: '0.82375em',
    alignItems: 'center',
    justifyContent: 'center',
    top: '-4.55em',
    right: '-4.17em',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.8)'
  },
  description: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '23px',
    color: '#808893',
    width: 641,
    display: 'inline-flex'
  },
  purpleDesc: {
    color: 'var(--gradient-fall-back)',
    margin: 0
  },
  w_auto: {
    width: 'auto'
  },
  linkTxt: {
    textDecoration: 'underline',
    margin: 0,
    display: 'contents'
  },
  homePopupWrapper: {
    padding: 0,

    '& > p': {
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '1.5em',
      lineHeight: '1.21em',
      letterSpacing: '-0.3px'
    },

    '& > span': {
      fontSize: '0.875em',
      lineHeight: '1.21em',
      letterSpacing: '-0.3px',
      color: '#808893',
      fontStyle: 'normal',
      fontWeight: 500
    }
  },
  '@media (max-width: 1160px)': {
    root: {
      width: '90vw'
    },
    closeIcon: {
      display: 'none'
    }
  },
  '@media (max-height: 700px)': {
    root: {
      height: '31.875em !important'
    }
  },
  mobileRoot: {
    width: '100%'
  },
  [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
    linkTxt: {
      display: 'none'
    },
    title: {
      textAlign: 'center',
      marginLeft: '1rem',
      color: 'white',
      fontSize: '2rem'
    },
    root: {
      width: '100vw',
      height: '100vh !important',
      padding: '0.8rem',
      flexDirection: 'column',
      background:
        'linear-gradient(136.82deg, #DB00FF 3.67%, #7000FF 40.94%, #3D0063 49.85%, #09000B 62.27%)',
      borderRadius: 0
    },
    titleWrapper: {
      width: '100%',
      paddingLeft: 0,
      marginTop: 0,
      paddingTop: 'unset'
    },
    homePopupWrapper: {
      padding: 0,
      width: '100%',
      '& > p': {
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: '2rem',
        letterSpacing: '-0.3px',
        textAlign: 'center'
      },

      '& > span': {
        display: 'none'
      }
    },
    description: {
      width: 'auto',
      color: 'rgba(255, 255, 255, 0.7) !important',
      margin: 0
    },
    modalBtnGroup: {
      right: 'unset',
      left: 0,
      top: 0,
      margin: 0
    },
    closeIconNew: {
      display: 'none'
    },

    purpleDesc: {
      display: 'none',
      color: '#120717'
    },
    desktopOnly: {
      display: 'none'
    },
    cameraBackBtn: {
      left: 10,
      top: 20,
      zIndex: 1
    },
    height100: {
      height: '100%'
    },
    mobileCameraRoot: {
      padding: 0
    },
    lawText: {
      position: 'relative',
      bottom: '-0.5em',
      fontSize: '0.75em'
    },
    wrapperMobile: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    scroll: {
      overflow: 'auto !important',
      overflowX: 'hidden !important',
      paddingRight: 0
    },
    closeIcon: {
      marginTop: '.5rem',
      display: 'block',
      borderRadius: '100%',
      backdropFilter: 'blur(15px)',
      '&:hover': {
        background: 'rgba(18, 7, 23, 0.3)'
      }
    },
    back: {
      display: 'flex',
      marginLeft: 0,
      background: 'rgba(18, 7, 23, 0.3)',
      color: colors.white,
      '& > svg': {
        fill: colors.white
      }
    },
    titleTextWrapper: {
      marginLeft: '4rem',
      '& > picture': {
        height: '1.875rem'
      },
      '& > p': {
        fontFamily: 'Inter',
        fontSize: '2.5rem',
        fontWeight: 800,
        lineHeight: '3.873rem',
        letterSpacing: '-0.2rem',
        paddingBottom: 'unset'
      }
    }
  },
  [`@media (max-width: ${BREAKPOINT_MOBILE_LARGE}px)`]: {
    lawText: {
      bottom: 0
    },
    closeIcon: {
      width: '3rem',
      height: '3rem',
      padding: '1rem'
    },
    titleTextWrapper: {
      marginLeft: '4rem',
      '& > picture': {
        height: '1.875rem'
      },
      '& > p': {
        fontFamily: 'Inter',
        fontSize: '2.5rem',
        fontWeight: 800,
        lineHeight: '3.873rem',
        letterSpacing: '-0.2rem',
        paddingBottom: 'unset'
      }
    },
    back: {
      fontSize: '1.2rem',
      '& > svg': {
        width: '1em',
        height: '1em'
      }
    }
  },
  [`@media (max-width: ${BREAKPOINT_MOBILE_MEDIUM}px)`]: {
    closeIcon: {
      marginTop: '.7rem',
      width: '2.5rem',
      height: '2.5rem'
    },
    titleTextWrapper: {
      marginLeft: '3rem',
      '& > picture': {
        height: '1.75rem'
      },
      '& > p': {
        fontSize: '2.25rem'
      }
    }
  }
})

const ImageUploadLayout = (props) => {
  const classes = useStyles()
  const {
    children,
    txt,
    onClose,
    handleTab,
    flag,
    mobile,
    hide = false
  } = props
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`)

  return (
    <Box
      display={hide ? 'none' : 'flex'}
      className={`${classes.root} ${mobile && classes.mobileRoot} ${
        flag === 2 && classes.mobileCameraRoot
      }`}
      style={{ height: '41.875em', margin: 'auto' }}
    >
      <Box
        className={`${classes.wrapper} ${
          flag === 0 && `${classes.scroll} ${classes.wrapperMobile}`
        } ${flag === 2 && classes.height100}`}
      >
        <Box
          className={`${classes.modalBtnGroup} ${
            flag === 2 && classes.cameraBackBtn
          }`}
        >
          {flag !== 0 && !isMobile && (
            <Box
              className={`${classes.closeIcon} ${classes.backBtn}`}
              onClick={() => handleTab(0)}
            >
              <ChevronLeftIcon htmlColor='#546277' />
              Go back
            </Box>
          )}
          <Box className={classes.closeIconNew} onClick={() => onClose()}>
            <CloseIcon htmlColor='#FFFFFF' />
          </Box>
          <Box
            className={`${classes.closeIcon} ${classes.back}`}
            onClick={() => {
              if (flag === 0) onClose()
              else handleTab(0)
            }}
          >
            <ArrowBackIosRoundedIcon htmlColor='#FFFFFF' />
          </Box>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Box
            className={`${classes.titleWrapper} ${
              flag === 2 && classes.desktopOnly
            } ${flag === 0 && classes.homePopupWrapper}`}
          >
            <div className={classes.titleTextWrapper}>
              {txt.logo && <Image alt='logo' src={txt.logo} height='100%' />}
              <Typography
                className={`${
                  flag !== 0 ? classes.title : classes.logotitle
                } text-title`}
              >
                {txt.title}
              </Typography>
            </div>
            <Typography
              component='span'
              className={`${flag !== 0 && classes.description} text-body`}
            >
              {flag === 1 && (
                <p className={classes.purpleDesc}>Unsplash&nbsp;</p>
              )}
              {txt.desc}
              {flag === 3 && (
                <p className={classes.linkTxt}>official licence</p>
              )}
              {flag === 3 && (
                <p
                  style={{
                    margin: 0,
                    display: !isMobile ? 'contents' : 'none'
                  }}
                >
                  &nbsp;for.
                </p>
              )}
            </Typography>
          </Box>
        </Box>
        {children}
      </Box>
    </Box>
  )
}
export default ImageUploadLayout
