import { makeStyles } from "@material-ui/core/styles";
import colors from "theme/dark/colors";

const useRightSidebarTopCommunitiesStyles = makeStyles({
  wrapper: {
    background: colors.secondary900,
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    gap: '0.5rem',

    borderRadius: '8px'
  },
  headerTitle: {
    color: '#E9F0F9',
    'font-family': 'Inter',
    'font-size': '1.125rem',
    'font-style': 'normal',
    'font-weight': 800,
    'line-height': 'normal',
    'letter-spacing': '-0.0625rem'
  },
  seeMoreBtn: {
    borderRadius: '6.25rem',
    border: '1.5px solid #1C263B',
    background: colors.secondary900,
    boxShadow: ' 2px 0px 2px 0px #1C263B',

    padding: '.25rem 0',

    // Typography
    color: '#E9F0F9',
    textAlign: 'center',
    'font-family': 'Inter',
    'font-size': '0.875rem',
    'font-style': 'normal',
    'font-weight': 700,
    'line-height': '1.5rem',
    'letter-spacing': '-0.01875rem'
  },
  communitiesListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    padding: '.25rem 0rem',
    borderRadius: 100,
    boxSizing: 'border-box',
    transition: 'all 0.3s',

    '&:hover': {
      backgroundColor: colors.darkBlue50,
      padding: '.25rem'
    }
  },
  communitiesListItemLink: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem'
  },
  communitiesListItemAvatar: {
    width: '2.25rem',
    height: '2.25rem'
  },
  communitiesListItemName: {
    color: '#E9F0F9',
    'font-family': 'Inter',
    'font-size': '0.925rem',
    'font-style': 'normal',
    'font-weight': 700,
    'line-height': 'normal',
    'letter-spacing': '-0.01875rem'
  },
  communitiesListItemMemberCount: {
    color: '#8A98B4',
    'font-family': 'Inter',
    'font-size': '0.8125rem',
    'font-style': 'normal',
    'font-weight': 400,
    'line-height': 'normal',
    'letter-spacing': '-0.01875rem'
  },
  communitiesListItemJoinBtn: {
    borderRadius: '6.25rem',
    border: '1.5px solid #1C263B',
    background: colors.secondary900,
    boxShadow: ' 2px 0px 2px 0px #1C263B',

    display: 'flex',
    alignItems: 'center',

    // Typography
    color: '#E9F0F9',
    textAlign: 'center',
    'font-family': 'Inter',
    'font-size': '0.875rem',
    'font-style': 'normal',
    'font-weight': 700,
    'line-height': '1.5rem',
    'letter-spacing': '-0.01875rem'
  }
})

export default useRightSidebarTopCommunitiesStyles;
