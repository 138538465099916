import { QuerySearchParamsDto } from './common.dto'

export class GetBookmarksDto extends QuerySearchParamsDto {
  // userId to fetch bookmarks for
  public userId: string = ''
  // optional: if postIds are provided, only these posts will be returned
  public postIds: string[] = []
  public toURLSearchParams (): URLSearchParams {
    const params = super.toURLSearchParams()
    params.append('userId', this.userId)
    if (this.postIds.length > 0) {
      params.append('postIds', this.postIds.join(','))
    }
    return params
  }
}
