import { makeStyles } from "@material-ui/core/styles";
import { borderRadius } from "constants/variables";
import colors from "theme/dark/colors";
import {
  BREAKPOINT_LAPTOP,
  BREAKPOINT_MOBILE_LARGE,
  BREAKPOINT_MOBILE_SMALL,
  BREAKPOINT_TABLET_MEDIUM,
} from "theme/shared/breakpoint";

const useHeaderStyles = makeStyles({
  w_full: {
    width: '100%'
  },
  a_center: {
    alignItems: 'center'
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  search: {
    width: '100%',
    height: 40,
    backgroundColor: colors.searchBg,
    borderRadius: 100,
    marginLeft: 20,
    marginRight: 20,
    zIndex: 99,

    [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
      display: 'none',
      marginLeft: 0,
      marginRight: 0
    }
  },
  input: {
    fontSize: 14,
    fontWeight: 500,
    color: 'white',
    '&::placeholder': {
      color: '#6C7B93',
      opacity: 1
    }
  },
  searchBtn: {
    color: '#5D6E87',
    paddingRight: 9,
    paddingLeft: 18
  },
  iconBtn: {
    width: 40,
    height: 40,
    borderRadius: '100%',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#252A3A',
      opacity: 0.8
    }
  },
  transparent: {
    background: 'transparent'
  },
  button: {
    borderRadius: '3.25rem',
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    fontWeight: 700,
    margin: 'auto 15px',
    minWidth: '184px',
    padding: '0.8125rem 1.375rem',
    transition: '0.4s',
    textShadow: '0px 0px 5px rgba(0,0,0,0.1)',
    position: 'relative',
    '&.youmeme': {
      color: colors.white,
      '&::after': {
        backgroundImage: 'linear-gradient(270deg, #cd31f5 , #625fff, #7573f8)'
      }
    },
    '&.lens': {
      color: colors.black,
      '&::after': {
        backgroundImage: `linear-gradient(90deg, ${colors.lensBrandColor}, ${colors.lensBrandColorDark})`
      }
    },
    '&::after': {
      position: 'absolute !important',
      content: " '' ",
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '3.25rem',
      transition: '0.5s',
      backgroundImage: 'linear-gradient(270deg, #cd31f5 , #625fff, #7573f8)',
      animation: '$gradAnimation 3s linear infinite',
      zIndex: -1,
      backgroundSize: '300% 300%'
    },
    '& > span': {
      zIndex: 9,
      whiteSpace: 'nowrap'
    },
    '&:hover': {
      background: 'black',
      opacity: 0.3
    }
  },
  '@keyframes gradAnimation': {
    '50%': {
      backgroundPosition: '100% 50%'
    }
  },
  authBtn: {
    '&.MuiButton-root': {
      width: '10rem',
      borderRadius: '5rem',
      fontWeight: 700,
      padding: '.6rem 1rem',
      transition: '0.4s',
      textShadow: '0px 0px 5px rgba(0,0,0,0.1)',

      [`@media (max-width: ${BREAKPOINT_MOBILE_LARGE}px)`]: {
        width: '11rem',
        padding: '.6rem .8rem'
      },

      '&:hover': {
        opacity: 0.5
      }
    }
  },
  btnGroup: {
    display: 'flex',
    gridTemplateColumns: 'auto auto auto auto',
    gridGap: 15
  },
  desktopOnly: {
    display: 'flex',
    alignItems: 'center',
    [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
      display: 'none'
    }
  },
  mobileOnly: {
    display: 'none !important',

    [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
      display: 'flex !important',
      padding: 0
    }
  },
  pad_0: {
    minWidth: 0
  },
  mobile_avatar: {
    width: '2rem !important',
    height: '2rem !important',
    borderRadius,
    boxSizing: 'border-box',
    overflow: 'unset !important',
    backgroundColor: colors.profileImageBackground,
    '&:hover': {
      cursor: 'pointer'
    },

    [`@media (max-width: ${BREAKPOINT_MOBILE_SMALL}px)`]: {
      width: '1.75rem !important',
      height: '1.75rem !important'
    }
  },
  siteLogo: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& picture': {
      height: '100%',
      width: '100%',
      display: ' flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
      height: '1.4rem',
      width: '1.25rem'
    },
    [`@media (max-width: ${BREAKPOINT_MOBILE_SMALL}px)`]: {
      height: '1.25rem',
      width: '5.5rem'
    }
  },
  desktopNotificationIcon: {
    marginRight: '1.188rem'
  },
  authPopoverWrapper: {
    background: '#202A41',
    borderRadius: '12px',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '20rem',
    padding: '1rem',
    gap: 15,
    '& button': {
      backgroundColor: colors.blueBtn,
      color: 'white',
      borderRadius: 100,
      width: '100%',
      marginTop: 10,
      padding: 10,
      fontWeight: 700,
      '&:hover': {
        backgroundColor: colors.blueBtn,
        opacity: 0.5
      }
    }
  },
  authPopoverContentWrapper: {
    width: '100%'
  },
  authPopoverTitle: {
    lineHeight: '14px',
    fontWeight: 700,
    letterSpacing: '-0.7px',
    color: '#E9F0F9'
  },
  authPopoverMessage: {
    fontSize: '0.875rem',
    color: '#E9F0F9'
  },
  switchWrapper: {
    position: 'relative',
    cursor: 'pointer',
    marginLeft: '1.188rem',
    '& > text': {
      position: 'absolute',
      fontSize: '14px',
      lineHeight: '18px',
      bottom: '11px',
      color: colors.greyScale500,
      '&.active': {
        fontWeight: 700
      },
      '&:nth-child(2)': {
        left: '21px',
        '&.active': {
          color: colors.white
        }
      },
      '&:last-child': {
        right: '21px',
        '&.active': {
          color: colors.greyScale900
        }
      }
    },
    [`@media (max-width: ${BREAKPOINT_LAPTOP}px)`]: {
      display: 'none'
    }
  },
  iosSwitch: {
    '&.MuiSwitch-root': {
      padding: '0',
      width: '178px',
      height: '40px',
      background: colors.greyScale900,
      borderRadius: '20px'
    },
    '& .MuiIconButton-root': {
      boxShadow: 'none !important'
    },
    '& .MuiSwitch-switchBase': {
      padding: 0
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(102px)',
      color: '#ffffff'
    },
    '& .MuiSwitch-switchBase .MuiSwitch-thumb': {
      backgroundSize: '60%',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundColor: colors.primary500,
      border: '1px solid transparent',
      width: '110px'
    },
    '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
      backgroundSize: '60%',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundColor: colors.lensBrandColor,
      border: '1px solid transparent',
      width: '74px'
    },
    '& .MuiSwitch-thumb': {
      height: '39px',
      borderRadius: '19px'
    },
    '& .MuiSwitch-track': {
      borderRadius: '20px',
      opacity: 0,
      boxSizing: 'border-box'
    },
    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      opacity: 0,
      borderRadius: '20px'
    }
  },
  notificationBadge: {
    '& .MuiBadge-badge': {
      backgroundColor: colors.primary500,
      color: 'white',

      [`@media (max-width: ${BREAKPOINT_MOBILE_SMALL}px)`]: {
        fontSize: '.625rem'
      }
    },

    '& .iconNotification ': {
      fontSize: '1.5rem',
      [`@media (max-width: ${BREAKPOINT_MOBILE_SMALL}px)`]: {
        fontSize: '1.25rem'
      }
    }
  },
  '@media (max-width: 1440px)': {
    search: {
      flexGrow: 1,
      width: 'auto'
    },
    pad_0: {
      padding: 0,
      minWidth: 0
    }
  },
  '@media (max-width: 750px)': {
    button: {
      width: 90
    }
  },
  '@media (max-width: 550px)': {
    desktopNotificationIcon: {
      marginRight: 0
    },
    center: {
      display: 'none'
    }
  }
})

export default useHeaderStyles;
