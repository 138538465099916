import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRef, useState } from "react";
import {
  CommunityUserRoleDTO,
  GetCommunitiesDTO,
  GetCommunityDTO,
  ListCommunityMembersDTO
} from 'ymca/dtos/community.dto'
import { APIResponse } from "ymca/jsonFetcher";
import { CommunityRole } from "ymca/models/community-role.model";
import { DefaultYMCA } from "ymca/ymca";

type CommunityViewTypes = "explore" | "joined" | "managed";
interface IUseGetCommunities {
  viewType: CommunityViewTypes;
  userId?: string;
  enabled?: boolean;
}

interface IUseGetCommunitiesSidebar {
  enabled?: boolean;
}
interface IUseGetCommunity {
  id: string;
}

interface IUseGetCommunityBySlug {
  slug: string;
  enabled?: boolean;
}

interface IUseGetCommunityPublication {
  id: string;
}

interface JoinMutationData {
  id: string;
}
interface useJoinCommunityMutationProps {
  onSuccess: Function;
  onError: Function;
  data: JoinMutationData;
}

interface useLeaveCommunityMutationProps {
  onSuccess: Function;
  onError: Function;
}

interface useUpdateCommunityMemberRoleMutationProps {
  onSuccess: Function
  onError: Function
}

interface IUseGetCommunityMembers {
  id?: string;
}

export const useGetCommunitiesQuery = ({
  viewType,
  userId,
  enabled = true,
}: IUseGetCommunities) => {
  const generatorRef = useRef<any>();
  const previousData = useRef<any[]>([]);
  const hasNextPageRef = useRef<any>(true);
  const totalItemReturnRef = useRef<any>(0);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<string>("");

  const resetPage = () => {
    generatorRef.current = null;
    previousData.current = [];
    hasNextPageRef.current = true;
    totalItemReturnRef.current = 0;
    // setPage(0);
  };

  const getData = async (props: any) => {
    let generator = undefined;
    if (!generatorRef.current) {
      let dto = new GetCommunitiesDTO();
      dto.search = search;
      if (viewType === "joined") {
        dto.membershipRoles = ["member"];
        dto.joinedByUserId = userId!;
      } else if (viewType === "managed") {
        dto.membershipRoles = ["admin", "moderator"];
        dto.joinedByUserId = userId!;
      } else {
        dto.hideJoined = true;
      }
      generator = await DefaultYMCA.communityService.getCommunities(dto);

      generatorRef.current = await generator;
    } else {
      console.log("generatorRef.current", generatorRef.current);
    }

    const data = await generatorRef.current.next();
    const { value, done } = data;
    let newData = value?.data;
    if (!newData || !Array.isArray(newData)) {
      newData = [];
    }
    totalItemReturnRef.current += newData.length ?? 0;
    if (value?.count > totalItemReturnRef.current) {
      hasNextPageRef.current = true;
    } else {
      hasNextPageRef.current = false;
    }
    if (previousData.current.length > 0) {
      previousData.current = [...previousData.current, ...newData];
    } else {
      previousData.current = newData;
    }

    return {
      data: previousData.current,
      hasMore: hasNextPageRef.current,
    };
  };
  const query = useQuery({
    queryKey: ["communities", viewType, page, search],
    queryFn: getData,
    keepPreviousData: true,
    enabled,
  });

  return {
    ...query,
    fetchNextPage: () => setPage((prev) => ++prev),
    searchRefetch: (value: string) => {
      resetPage();
      setSearch(value);
    },
  };
};

export const useGetCommunitiesSidebarQueryv2 = ({
  enabled = true,
}: IUseGetCommunitiesSidebar) => {
  const generatorRef = useRef<any>();
  const previousData = useRef<any[]>([]);
  const hasNextPageRef = useRef<any>(true);
  const totalItemReturnRef = useRef<any>(0);
  const [page, setPage] = useState(0);

  const resetPage = () => {
    generatorRef.current = null;
    previousData.current = [];
    hasNextPageRef.current = true;
    totalItemReturnRef.current = 0;
    // setPage(0);
  };

  const getData = async (props: any) => {
    let generator = undefined;
    if (!generatorRef.current) {
      let dto = new GetCommunitiesDTO();
      dto.hideJoined = true;
      dto.limit = 5;
      generator = await DefaultYMCA.communityService.getCommunities(dto);
      generatorRef.current = await generator;
    } else {
      console.log("generatorRef.current", generatorRef.current);
    }

    const data = await generatorRef.current.next();
    const { value, done } = data;
    let newData = value?.data;
    if (!newData || !Array.isArray(newData)) {
      newData = [];
    }
    totalItemReturnRef.current += newData.length ?? 0;
    if (value?.count > totalItemReturnRef.current) {
      hasNextPageRef.current = true;
    } else {
      hasNextPageRef.current = false;
    }
    if (previousData.current.length > 0) {
      previousData.current = [...previousData.current, ...newData];
    } else {
      previousData.current = newData;
    }

    return {
      data: previousData.current,
      hasMore: hasNextPageRef.current,
    };
  };
  const query = useQuery({
    queryKey: ["communities", "explore", page],
    queryFn: getData,
    keepPreviousData: true,
    enabled,
  });

  return {
    ...query,
    fetchNextPage: () => setPage((prev) => ++prev),
    refetch: () => {
      resetPage();
      query.refetch();
    },
  };
};

export const useGetCommunitiesSidebarQuery = ({
  enabled = true,
}: IUseGetCommunitiesSidebar) => {
  const hasNextPageRef = useRef<any>(true);

  const getData = async (props: any) => {
    let dto = new GetCommunitiesDTO();
    dto.hideJoined = true;
    dto.limit = 5;
    const generator = await DefaultYMCA.communityService.getCommunities(dto);

    const data = await generator.next();
    const { value, done } = data;

    let newData = value?.data;
    if (!newData || !Array.isArray(newData)) {
      newData = [];
    }

    const totalItem = newData.length ?? 0;
    if (value?.count && value?.count > totalItem) {
      hasNextPageRef.current = true;
    } else {
      hasNextPageRef.current = false;
    }

    return {
      data: newData,
      hasMore: hasNextPageRef.current,
    };
  };
  const query = useQuery({
    queryKey: ["communities", "explore"],
    queryFn: getData,
    keepPreviousData: true,
    enabled,
  });

  return {
    ...query,
  };
};

export const useGetCommunityQuery = ({ id }: IUseGetCommunity) => {
  const getCommunityDto = new GetCommunityDTO();
  getCommunityDto.setId(id);

  const getData = async () => {
    const data = await DefaultYMCA.communityService.getCommunity(
      getCommunityDto
    );
    return data?.data?.data?.[0];
  };
  const query = useQuery({
    queryKey: ["communities", { id: getCommunityDto.ids?.[0] }],
    queryFn: getData,
  });

  return query;
};
export const useGetCommunityBySlugQuery = ({
  slug,
  enabled = true,
}: IUseGetCommunityBySlug) => {
  const getCommunityDto = new GetCommunityDTO();
  getCommunityDto.slug = slug;

  const getData = async () => {
    const data = await DefaultYMCA.communityService.getCommunity(
      getCommunityDto
    );

    return data?.data?.data?.[0];
  };
  const query = useQuery({
    queryKey: ["communities", { slug: slug }],
    queryFn: getData,
    enabled,
  });

  return query;
};

export const useGetCommunityPublicationsQuery = ({
  id,
}: IUseGetCommunityPublication) => {
  const getCommunityDto = new GetCommunityDTO();
  getCommunityDto.setId(id);

  const getData = async () => {
    const data = await DefaultYMCA.communityService.getCommunity(
      getCommunityDto
    );
    return data?.data?.data?.[0];
  };
  const query = useQuery({
    queryKey: ["communities", id, "publications"],
    queryFn: getData,
    enabled: !!id,
  });

  return query;
};

export const useJoinCommunityMutation = ({
  onSuccess,
  onError,
  data,
}: useJoinCommunityMutationProps) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: () => {
      return DefaultYMCA.communityService.joinCommunity(data.id);
    },
    onError: (error: any, variables: any, context: any) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context.id}`);
      onError({ error });
    },
    onSuccess: (dataPayload: APIResponse<any>) => {
      const { isSuccess, data } = dataPayload;
      if (isSuccess) {
        queryClient.invalidateQueries({
          queryKey: ["communities", { id: data.id }],
        });
        onSuccess({ data });
      } else {
        const { message } = data;
        const error = {
          message,
        };
        onError({ error });
      }
    },
  });

  return mutation;
};

export const useLeaveCommunityMutation = ({
  onSuccess,
  onError,
}: useLeaveCommunityMutationProps) => {
  const mutation = useMutation({
    mutationFn: ({ id }: { id: string }) => {
      return DefaultYMCA.communityService.leaveCommunity(id);
    },
    onError: (error: any, variables: any, context: any) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context.id}`);
      onError({ error });
    },
    onSuccess: (dataPayload: APIResponse<any>) => {
      const { isSuccess, data } = dataPayload;
      if (isSuccess) {
        onSuccess({ data });
      } else {
        onError({
          error: {
            message: data?.message,
          },
        });
      }
    },
  });

  return mutation;
};

export const useDeleteCommunityMutation = ({
  onSuccess,
  onError,
}: useLeaveCommunityMutationProps) => {
  const mutation = useMutation({
    mutationFn: ({ id, ...rest }: { id: string }) => {
      return DefaultYMCA.communityService.deleteCommunity(id);
    },
    onError: (error: any, variables: any, context: any) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context.id}`);
      onError({ error });
    },
    onSuccess: (data: any, variables: any, context: any) => {
      onSuccess({ data });
    },
  });

  return mutation;
};

export const useGetCommunityMembersQuery = ({ id }: IUseGetCommunityMembers) => {
  const generatorRef = useRef<any>()
  const previousData = useRef<any[]>([])
  const hasNextPageRef = useRef<any>(true)
  const totalItemReturnRef = useRef<any>(0)
  const [page, setPage] = useState(0)
  const [role, setRole] = useState<CommunityRole | undefined>()
  const [search, setSearch] = useState<string | undefined>()

  const resetPage = () => {
    generatorRef.current = null
    previousData.current = []
    hasNextPageRef.current = true
    totalItemReturnRef.current = 0
    setPage(0)
  }

  const getData = async (props: any) => {
    let generator = undefined
    if (!generatorRef.current) {
      const dto = new ListCommunityMembersDTO()
      dto.communityId = id!
      if (role) {
        dto.role = [role]
      }

      if (search) {
        dto.search = search
      }

      generator = await DefaultYMCA.communityService.listCommunityMemberships(
        dto
      )

      generatorRef.current = await generator
    }

    const data = await generatorRef.current.next()
    const { value, done } = data
    let newData = value?.data

    if (!newData || !Array.isArray(newData)) {
      newData = []
    }

    totalItemReturnRef.current += newData.length ?? 0
    if (value?.count > totalItemReturnRef.current) {
      hasNextPageRef.current = true
    } else {
      hasNextPageRef.current = false
    }

    if (previousData.current.length > 0) {
      previousData.current = [...previousData.current, ...newData]
    } else {
      previousData.current = newData
    }

    return {
      data: previousData.current,
      hasMore: hasNextPageRef.current
    }
  }

  const queryKey = ['communities', { id: id }, 'members', page]
  if (role) {
    queryKey.push(role)
  }
  if (search) {
    queryKey.push(search)
  }
  const query = useQuery({
    queryKey,
    queryFn: getData,
    keepPreviousData: true,
    enabled: !!id
  })

  return {
    ...query,
    fetchNextPage: () => setPage((prev) => ++prev),
    searchRefetch: ({
      role,
      search
    }: {
      role?: CommunityRole
      search?: string
    }) => {
      resetPage()
      if (role) {
        if (role === 'member') {
          setRole(undefined)
        } else {
          setRole(role)
        }
      }
      setSearch(search)
    },
    role
  }
}

export const useUpdateCommunityMemberRoleMutation = ({
  onSuccess,
  onError
}: useUpdateCommunityMemberRoleMutationProps) => {
  const mutation = useMutation({
    mutationFn: (dto: CommunityUserRoleDTO) => {
      return DefaultYMCA.communityService.updateCommunityMemberRole(dto)
    },
    onError: (error: any, variables: any, context: any) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context.id}`)
      onError({ error })
    },
    onSuccess: (dataPayload: APIResponse<any>) => {
      const { isSuccess, data } = dataPayload
      if (isSuccess) {
        onSuccess({ data })
      } else {
        onError({
          error: {
            message: data?.message
          }
        })
      }
    }
  })

  return mutation
}
