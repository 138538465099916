import { makeStyles } from '@material-ui/core/styles'
import { borderRadius } from 'constants/variables'
import borders from 'theme/dark/borders'
import colors from 'theme/dark/colors'

const useAddCommentFormStyles = makeStyles((theme: any) => ({
  form_wrapper: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  form: {
    display: 'flex',
    flexDirection: 'unset',
    alignItems: 'flex-end',
    width: '100%',
    justifyContent: 'space-between',
    gap: '1rem'
  },
  comment_section: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
    gap: '.1rem',
    justifyContent: 'space-between',
    borderRadius: 30,
    border: borders.formBorderDefault
  },
  text_area_section: {
    flex: 1,

    '& .MuiInputBase-root': {
      borderRadius: 30,
      '&.MuiInput-root': {
        '&:before': {
          borderBottom: 'unset'
        },
        '&:after': {
          borderBottom: 'unset'
        }
      }
    },

    '& textarea': {
      padding: '.5rem 1rem'
    },
    '& .MuiInputAdornment-root': {
      alignItems: 'center',
      maxHeight: 'unset',
      paddingRight: '.5rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      marginLeft: 'unset',
      flex: 1,
      gap: '.25rem'
    }
  },
  action_btn_section: {
    height: '100%',
    display: 'flex',
    padding: '.5rem',
    gap: '.25rem'
  },
  reactionBtn: {
    padding: 0
  },

  userIcon: {
    width: 36,
    height: 36,
    borderRadius,
    backgroundColor: colors.profileImageBackground,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  emojiMenu: {
    '& .MuiPaper-root': {
      'background-color': 'unset',
      'border-radius': '0.75rem'
    },
    '& .MuiList-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '.5rem',
      padding: 0
    },
    display: 'block'
  },
  postComment: {
    background: 'rgba(41, 54, 82, 0.5)',
    color: '#4F5D79',
    borderRadius: 100,
    fontWeight: 700
  }
}))

export default useAddCommentFormStyles
