import { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import {
  Backdrop,
  Box,
  Typography,
  Button,
  Fade,
  Divider,
  Slide
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { MetaMask } from "assets/MetaMask";
import { Rainbow } from "assets/Rainbow";
import { Coinbase } from "assets/Coinbase";
import { Argent } from "assets/Argent";
import { Phantom } from "assets/Phantom";
import { ArrowBack } from "assets/ArrowBack";
import { ArrowRight } from "assets/ArrowRight";
import useWeb3 from "hooks/useWeb3";

const useStyles = makeStyles({
    
  closeBtn: {
    position: "absolute",
    top: -20,
    right: 0,
    color: "white",
    background: "var(--gradient-fall-back)",
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.8)',
    height: '42px',
    width: '42px',
    "&:hover":{
      background: "var(--gradient-fall-back)",
    }
  },
  div: {
    content: " '' ",
    height: '63px',
    background: 'var(--main-gradient)',
    width: '90%',
    borderRadius: '10px',
    position: 'absolute',
    marginTop: '-6px',
    marginLeft:12
  },
  footerText: {
    fontSize: 13,
    fontWeight: 500,
  },
  footer: {
    display: "flex",
    alignItems: "center",
    padding: "0 34px",
    background:
      "linear-gradient(90deg, rgba(32, 42, 65, 0.9) 0%, rgba(32, 42, 65, 0) 100%)",
    filter: "drop-shadow(0px 0px 70px rgba(14, 20, 34, 0.4))",
    backdropFilter: "blur(15px)",
    borderRadius: " 0px 0px 18px 18px",
    height: 64,
  },
  selectListContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  createButton: {
    background: "#625FFF",
    width: 169,
    height: 46,
    borderRadius: 100,
    color: "white",
    fontWeight: 700,
    fontSize: 14,
    "&:hover": {
      background: "#625FFF",
    },
  },
  flex: {
    display: "flex",
    overflowX: "auto",
    paddingBottom: 20,
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  questionSubtitle: {
    color: "#8C9AB6E5",
    fontSize: 13,
    fontWeight: 500,
  },
  questionBody: {
    color: "#E9F0F9",
    fontSize: 16,
    fontWeight: 700,
  },
  questionTitle: {
    color: "#E9F0F9",
    fontSize: 18,
    fontWeight: 800,
  },
  rightContent: {
    padding: 34,
  },
  right: {
    width: "60%",
  },
  walletBox: {
    display: "flex",
    alignItems: "center",
  },
  iconBox: {
    display: "grid",
    placeItems: "center",
    marginRight: 20,
  },
  walletName: {
    color: "#8A98B4E5",
    fontWeight: 600,
    marginLeft: 12,
  },
  walletList: {
    marginTop: 27,
  },
  suggested: {
    fontSize: 13,
    color: "#8A98B4E5",
    fontWeight: 500,
    marginLeft: 14,
  },
  leftContent: {
    padding: "34px",
  },
  icontitle: {
    fontSize: 13,
    fontWeight: 700,
    color: "#FFFFFF",
    marginTop: 11,
    textAlign: "center",
  },
  titleBox: {
    height: 90,
    width: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #293651",
    padding: "0 48px",
  },
  left: {
    borderRight: "1px solid #293651",
    width: "40%",
  },
  paper: {
    width: "100%",
    mixBlendMode: "normal",
    boxSizing: "border-box",
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: "85%",
    bottom: 0,
    background: "#202A41",
    overflow:"visible !important",
    boxShadow: " 0px 0px 70px rgba(0, 6, 18, 0.4)",
    backdropFilter: "blur(10px)",
    borderRadius: "18px 18px 0 0",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  modal: {
    position: "absolute",
    backdropFilter: "blur(10px)",
    transform: "scale(0)",
    animation: "$fade 100ms forwards",
  },
  "@keyframes fade": {
    to: {
      transform: "scale(1)",
    },
  },
  content: {
    boxSizing: "border-box",
    display: "flex",
    width: "100%",
  },
});

const LensMobileModal = (props: {
  open: boolean;
  handleClose(event: any, reason?: any): void;
  connect(wallet: string): void;
}): JSX.Element => {
  const { open, handleClose } = props;
  const { logout } = useWeb3();
  const classes = useStyles();
  const [stage, setStage] = useState<"question" | "get">("question");
  useEffect(() => {
    if (stage === "question") logout();
  }, [stage])
  useEffect(() => {
    if (!open) setStage("question")
  }, [open])
  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        handleClose(event, reason);
        setStage("question");
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
    <Box style={{position:"fixed",bottom:0,width:"100%"}}>
      <Box className={classes.div} />
        <Box className={classes.paper}>
        <IconButton className={classes.closeBtn} onClick={(event)=>{
          handleClose(event);
          setStage("question");
          logout();
        }}>
            <Close />
          </IconButton>
          <Box className={classes.content}>
            {stage === "question" && (
              <Box style={{ width: "100%" }}>
                <Box style={{ padding: "0 15px" }}>
                  <Typography
                    className={classes.questionTitle}
                    style={{ marginBottom: 26, marginTop: 26 }}
                  >
                    Connect Wallet
                  </Typography>
                  <Box className={classes.flex}>
                    <Box className={classes.iconBox} onClick={() => props.connect('metamask')} style={{ cursor: 'pointer' }}>
                      <MetaMask style={{ fontSize: 60 }} />
                      <Typography className={classes.icontitle}>
                        MetaMask
                      </Typography>
                    </Box>
                    <Box className={classes.iconBox} onClick={() => props.connect('walletconnect')} style={{ cursor: 'pointer' }}>
                      <img
                        src="/assets/connectIcons/wallet_connect_icon.svg"
                        style={{ width: 60, height: 60 }}
                      />
                      <Typography className={classes.icontitle}>
                        WalletConnect
                      </Typography>
                    </Box>
                    <Box className={classes.iconBox}>
                      <Rainbow style={{ fontSize: 60 }} />
                      <Typography className={classes.icontitle}>
                        Rainbow
                      </Typography>
                    </Box>

                    <Box className={classes.iconBox}>
                      <Coinbase style={{ fontSize: 60 }} />
                      <Typography className={classes.icontitle}>
                        Coinbase
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Divider style={{ border: "1px solid #293651" }} />
                <Box style={{ width: "100%" , padding:'15px'}}>
                   <img
                        src="/assets/gifs/ez.gif"
                        style={{ width: "100%", height:256.19 ,objectFit:'cover',borderRadius:15 }}
                      />
                </Box>
                <Box style={{ padding: "0 26px" }}>
                  <Typography className={classes.questionTitle}>
                    New in Crypto? Create a wallet
                  </Typography>
                  <Typography className={classes.questionSubtitle}>
                    The place where you keep your digital assets safe.
                  </Typography>
                  <Box className={classes.flex} style={{ marginTop: 20 }}>
                    <Button
                      className={classes.createButton}
                      onClick={() => setStage("get")}
                    >
                      Create New Wallet
                    </Button>
                    <Button
                      style={{
                        background: "transparent",
                        color: "#817EFF",
                        marginLeft: 20,
                      }}
                      href="https://youmemeworld.medium.com/how-to-set-up-a-metamask-wallet-ca24bf53ca8f"
                    target="_blank"
                    >
                      Learn More
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
            {stage === "get" && (
              <Slide in={stage === "get"} direction="up">
                <Box style={{ width: "100%" }}>
                  <Box style={{ padding: "0 26px" }}>
                    <Typography
                      className={classes.questionTitle}
                      style={{ marginBottom: 26, marginTop: 26 }}
                    >
                      Get a Wallet
                    </Typography>
                    <Box className={classes.selectListContainer}>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <MetaMask style={{ fontSize: 46 }} />
                        <Box style={{ marginLeft: 16 }}>
                          <Typography
                            style={{ fontWeight: 800, color: "#E9F0F9" }}
                          >
                            MetaMask
                          </Typography>
                          <Typography
                            style={{
                              color: "#8A98B4E5",
                              fontSize: 13,
                              fontWeight: 500,
                            }}
                          >
                            Mobile Wallet and Extension
                          </Typography>
                        </Box>
                      </Box>
                      <Button
                        style={{
                          fontWeight: 700,
                          background: "#293651",
                          color: "white",
                          borderRadius: 100,
                          width: 88,
                          height: 46,
                        }}
                        onClick={() => window.open('https://metamask.io')}
                      >
                        GET
                      </Button>
                    </Box>
                    {/* <Box className={classes.selectListContainer}>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Phantom style={{ fontSize: 46 }} />
                        <Box style={{ marginLeft: 16 }}>
                          <Typography style={{ fontWeight: 800 }}>
                            WalletConnect
                          </Typography>
                          <Typography
                            style={{
                              color: "#8A98B4E5",
                              fontSize: 13,
                              fontWeight: 500,
                            }}
                          >
                            Mobile Wallet
                          </Typography>
                        </Box>
                      </Box>
                      <Button
                        style={{
                          fontWeight: 700,
                          background: "#293651",
                          color: "white",
                          borderRadius: 100,
                          width: 88,
                          height: 46,
                        }}
                        onClick={() => window.open('https://www.walletconnect.com')}
                      >
                        GET
                      </Button>
                    </Box> */}
                    <Box
                      className={classes.selectListContainer}
                      style={{ pointerEvents: "none", opacity: 0.5 }}
                    >
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Coinbase style={{ fontSize: 46 }} />
                        <Box style={{ marginLeft: 16 }}>
                          <Typography style={{ fontWeight: 800 }}>
                            Coinbase Wallet
                          </Typography>
                          <Typography
                            style={{
                              color: "#8A98B4E5",
                              fontSize: 13,
                              fontWeight: 500,
                            }}
                          >
                            Mobile Wallet
                          </Typography>
                        </Box>
                      </Box>
                      <Button
                        style={{
                          fontWeight: 700,
                          background: "#293651",
                          color: "white",
                          borderRadius: 100,
                          width: 88,
                          height: 46,
                        }}
                      >
                        GET
                      </Button>
                    </Box>
                    <Box
                      className={classes.selectListContainer}
                      style={{ pointerEvents: "none", opacity: 0.5 }}
                    >
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Rainbow style={{ fontSize: 46 }} />
                        <Box style={{ marginLeft: 16 }}>
                          <Typography style={{ fontWeight: 800 }}>
                            Rainbow
                          </Typography>
                          <Typography
                            style={{
                              color: "#8A98B4E5",
                              fontSize: 13,
                              fontWeight: 500,
                            }}
                          >
                            Mobile Wallet and Extension
                          </Typography>
                        </Box>
                      </Box>
                      <Button
                        style={{
                          fontWeight: 700,
                          background: "#293651",
                          color: "white",
                          borderRadius: 100,
                          width: 88,
                          height: 46,
                        }}
                      >
                        GET
                      </Button>
                    </Box>
                    <Box
                      className={classes.selectListContainer}
                      style={{ pointerEvents: "none", opacity: 0.5 }}
                    >
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Argent style={{ fontSize: 46 }} />
                        <Box style={{ marginLeft: 16 }}>
                          <Typography style={{ fontWeight: 800 }}>
                            Argent
                          </Typography>
                          <Typography
                            style={{
                              color: "#8A98B4E5",
                              fontSize: 13,
                              fontWeight: 500,
                            }}
                          >
                            Mobile Wallet and Extension
                          </Typography>
                        </Box>
                      </Box>
                      <Button
                        style={{
                          fontWeight: 700,
                          background: "#293651",
                          color: "white",
                          borderRadius: 100,
                          width: 88,
                          height: 46,
                        }}
                      >
                        GET
                      </Button>
                    </Box>
                  </Box>
                  <Divider style={{ border: "1px solid #293651" }} />
                  <Box style={{ width: "100%" }}></Box>
                  <Box style={{ padding: "26px" }}>
                    <Typography className={classes.questionTitle}>
                      Not what you were looking for?
                    </Typography>
                    <Typography
                      className={classes.questionSubtitle}
                      style={{ marginTop: 10 }}
                    >
                      That’s all good! Select a wallet on the left to get
                      started with a different wallet provider.
                    </Typography>
                  </Box>
                </Box>
              </Slide>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default LensMobileModal;
