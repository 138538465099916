import { FollowRelationshipType } from 'ymca/models/follow.model'
import { QuerySearchParamsDto } from './common.dto'

export class GetFollowDto extends QuerySearchParamsDto {
  // the subject being queried
  public subjectUserId: string = ''
  // if provided, only tests relationship against these users
  public testUserIds: string[] = []
  // the follow relationship type to query
  public relationshipType: FollowRelationshipType = 'follower'

  public toURLSearchParams (): URLSearchParams {
    const params = super.toURLSearchParams()
    params.append('subjectUserId', this.subjectUserId)
    params.append('relationshipType', this.relationshipType)
    if (this.testUserIds.length > 0) {
      params.append('testUserIds', this.testUserIds.join(','))
    }
    return params
  }
}
