import { makeStyles } from '@material-ui/core/styles'
import { imagesPng } from 'constants/images'
import { borderRadius } from 'constants/variables'

const useContestRewardsStyles = makeStyles({
  userThumbnailBox: {
    width: 50,
    height: 50,
    borderRadius,
    position: 'absolute',
    bottom: -20,
    left: 10,
    border: '6px solid #202A41',
    backgroundSize: 'contain',
    backgroundColor: 'white',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  container: {
    left: 0,
    right: 0,
    bottom: 0,
    height: '100vh',
    background: '#151E31',
    zIndex: 9,
    paddingBottom: 120
  },
  banner: {
    height: '5.8rem',
    backgroundImage: `url("/assets/banner/${imagesPng.newContestBanner}")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    left: 0,
    zIndex: 9
  },
  bannerContainer: {
    maxWidth: '1024px',
    zIndex: 99,
    height: '100%',
    margin: '0 auto',
    width: '100%',
    position: 'relative',
    '& img': {
      width: 108,
      height: 108,
      position: 'absolute',
      bottom: -50,
      left: 22,
      zIndex: 99,
      borderRadius,
      border: '8px solid #202A41',
      background: '#2B3853',
      display: 'none'
    },
    '& button': {
      display: 'none'
    }
  },
  body: {
    marginTop: '2rem',
    padding: '0 10px',
    position: 'relative',
    overflow: 'hidden !important',
    maxWidth: '1024px',
    margin: '0 auto'
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 900,
    color: '#E9F0F9'
  },
  feature: {
    fontSize: 18,
    fontWeight: 700,
    color: '#E9F0F9',
    marginLeft: 11
  },
  featureTextBox: {
    display: 'none',
    alignItems: 'center',
    marginTop: 34
  },
  subtitle: {
    fontSize: '1rem',
    fontWeight: 500,
    color: '#8A98B4',
    marginTop: '.5rem'
  },
  root: {
    display: 'block',
    marginTop: 8
  },
  tabs: {
    color: '#fff !important',
    fontWeight: 700,
    '& .MuiTabs-indicator': {
      backgroundColor: '#8B89FF',
      height: 3
    },
    '& .MuiTab-root.Mui-selected': {
      color: '#E9F0F9'
    },
    '& .MuiTab-textColorPrimary': {
      color: '#8A98B4'
    },
    '& 	.MuiTab-root': {
      fontSize: 14,
      textTransform: 'capitalize'
    }
  },
  cards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: 26,
    marginTop: 27
  },
  contestCard: {
    width: '100%',
    background: '#2B3853',
    boxShadow: '0px 0px 20px rgba(0, 7, 24, 0.1)',
    borderRadius: 12,
    padding: 14,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    cursor: 'pointer',
    overflow: 'hidden'
  },
  contentBox: {
    marginTop: 27,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block'
    // -webkit-line-clamp: 2,
    // -webkit-box-orient: vertical
  },
  bannerBox: {
    height: 100,
    backgroundSize: 'cover',
    borderRadius: 12,
    position: 'relative',
    backgroundColor: '#7075FF',
    backgroundPosition: 'center',
    '& img': {
      width: 50,
      height: 50,
      borderRadius,
      position: 'absolute',
      bottom: -20,
      left: 10,
      border: '6px solid #202A41'
    }
  },
  contentInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 10
  },
  contestTitle: {
    color: '#E9F0F9',
    fontSize: 20,
    fontWeight: 800,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
    // "&:first-letter": {
    //   textTransform: "uppercase"
    // }
  },
  contentSubtitle: {
    fontSize: 14,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
    // "&:first-letter": {
    //   textTransform: "uppercase"
    // }
  },
  contestButton: {
    borderRadius: 100,
    height: 56,
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: 15,
    marginTop: 22,
    transition: '0.3s',
    '&:hover': {
      background: 'black',
      opacity: 0.3
    }
  },
  timerBox: {
    position: 'absolute',
    padding: '.4rem',
    background: 'rgba(43, 56, 83, 0.7)',
    borderRadius: 100,
    right: 3,
    top: 3,
    display: 'flex',
    gap: 7,
    alignItems: 'center'
  },
  timer: {
    color: '#FFFFFF',
    fontSize: '.75rem',
    fontWeight: 800
  },
  crumbs: {
    display: 'flex',
    gap: 10,
    '& p': {
      color: '#8A98B4',
      fontSize: 13,
      fontWeight: 600,
      '&:last-child': {
        color: '#8B89FF'
      }
    }
  },
  emptyMessage: {
    marginTop: 34
  },
  addText: {
    bottom: 150,
    color: 'white',
    zIndex: 99,
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '1rem',
    '& span': {
      textDecoration: 'underline'
    }
  },
  floatButton: {
    borderRadius: 100,
    height: 56,
    color: '#FFFFFF',
    fontWeight: 700,
    position: 'fixed',
    fontSize: 15,
    bottom: 50,
    right: 50,
    zIndex: 99,
    padding: '0 2rem',
    background: 'linear-gradient(90deg, #625FFF 0%, #FA00FF 238.69%)',
    display: 'none',
    transition: '0.3s',
    '&:hover': {
      opacity: '0.4'
    }
  },
  '@media (min-width: 1023px)': {
    title: {
      fontSize: '2.2rem'
    },
    subtitle: {
      marginTop: '1rem'
    },
    featureTextBox: {
      display: 'flex'
    },
    addText: {
      display: 'none'
    },
    floatButton: {
      display: 'flex'
    },
    banner: {
      backgroundPosition: 'center',
      minWidth: '100%',
      height: 175,
      '& img': {
        left: 117
      },
      '& button': {
        display: 'flex',
        width: 60,
        height: 60,
        position: 'absolute',
        top: 30,
        left: 117,
        background: 'rgba(43, 56, 83, 0.8)',
        mixBlendMode: 'normal',
        border: '2px solid rgba(138, 152, 180, 0.15)',
        boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.2)',
        borderRadius: 100
      }
    },
    bannerContainer: {
      '& img': {
        left: 0,
        display: 'block'
      },
      '& button': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 40,
        height: 40,
        position: 'absolute',
        top: 30,
        left: 0,
        background: 'rgba(43, 56, 83, 0.8)',
        mixBlendMode: 'normal',
        border: '2px solid rgba(138, 152, 180, 0.15)',
        boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.2)',
        borderRadius: '50%'
      }
    },
    body: {
      padding: 0
    },
    tabs: {
      width: 450,
      textAlign: 'center',
      '& .MuiTabs-indicator': {
        backgroundColor: '#8B89FF',
        height: 3
      },
      '& .MuiTab-root.Mui-selected': {
        color: '#E9F0F9'
      },
      '& .MuiTab-textColorPrimary': {
        color: '#8A98B4'
      },
      '& 	.MuiTab-root': {
        fontSize: 16,
        textTransform: 'capitalize',
        textAlign: 'center'
      }
    },
    root: {
      marginTop: 32
    }
  }
})

export default useContestRewardsStyles
