import { Post } from "ymca/models/post.model";
import Publication from "../Publication";
import config from "utils/config";
import useInfiniteScroll from 'hooks/useInfiniteScroll/useInfiniteScroll'

export interface PublicationsProp {
  publications: Post[]
  width: number
  onDeletePublication: (args: { postId: string; postIndex: number }) => void
  eventProps: {
    event: boolean
    setEvent: (value: boolean) => void
  }
  disableEditMeme?: boolean
  copyLinkBasePath?: string // ensure base path does not end with /
  isFetching?: boolean // if fetch more data
  startLoadingMoreIndex?: number // the index of list where load more will kick start from, index starts from 0
  hasMore?: boolean // Load more component ref
  onLoadMore?: VoidFunction // Load more function
  handleImageOnLoad?: VoidFunction
}

const PublicationList = (props: PublicationsProp): JSX.Element => {
  const {
    publications,
    width,
    eventProps,
    onDeletePublication,
    disableEditMeme = false,
    copyLinkBasePath = config.deploymentUrl,
    startLoadingMoreIndex = 3,
    onLoadMore = () => {},
    hasMore,
    isFetching,
    handleImageOnLoad
  } = props
  const { event, setEvent } = eventProps

  const [infiniteRef] = useInfiniteScroll({
    loading: Boolean(isFetching),
    hasNextPage: Boolean(hasMore),
    onLoadMore,
    rootMargin: '50px'
  })

  return (
    <>
      {publications.map((publication, index) => {
        return (
          <>
            <Publication
              key={index.toString()}
              post={publication}
              fontSize={width < 450 ? '17px' : '19px'}
              setEvent={() => setEvent(!event)}
              onDeletePublication={() =>
                onDeletePublication({
                  postId: publication.id,
                  postIndex: index
                })
              }
              disableEditMeme={disableEditMeme}
              copyLinkBasePath={copyLinkBasePath}
              ref={
                publications.length - (index + 1) === startLoadingMoreIndex &&
                hasMore
                  ? infiniteRef
                  : undefined
              }
              handleImageOnLoad={handleImageOnLoad}
            />
          </>
        )
      })}
    </>
  )
}

export default PublicationList;
