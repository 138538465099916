import React, { Ref, forwardRef } from 'react'
import { getAssetsPath } from 'utils/path'

export interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string
  width?: string
  height?: string
  onClick?: (event: React.MouseEvent<HTMLImageElement>) => void
  crossOrigin?: 'anonymous' | 'use-credentials' | ''
}

export const Image = forwardRef(
  (
    {
      src,
      className,
      alt,
      width,
      height,
      onClick,
      crossOrigin = '',
      onLoad = () => {},
      srcSet
    }: ImageProps,
    ref?: Ref<HTMLImageElement>
  ): JSX.Element => {
    return (
      <img
        src={getAssetsPath(src)}
        className={className}
        srcSet={srcSet}
        alt={alt}
        onClick={(event: React.MouseEvent<HTMLImageElement>) => {
          if (onClick) {
            onClick(event)
          }
        }}
        width={width}
        height={height}
        ref={ref}
        crossOrigin={crossOrigin}
        onLoad={onLoad}
      />
    )
  }
)

export default Image
