import { useRef } from "react";
import { Box, Typography, Alert, AlertTitle } from "@mui/material";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";

import useCreateCommunityModalStyles from "../style";
import Icon from "components/atoms/Icons";
import getImageBase64DataUri from "utils/getImageBase64DataUri";
import { CreateCommunityWizardFormProps } from "constants/community";
import FormTextField from "components/molecules/FormTextField";

const StageTwoWizard = ({
  formikFormProps,
  errorAlertProps,
  errorAlertRef,
}: CreateCommunityWizardFormProps) => {
  const classes = useCreateCommunityModalStyles();
  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const { values, handleBlur, handleChange, errors, touched, setFieldValue } =
    formikFormProps;

  const handleOnFileUploadClick = () => {
    uploadInputRef?.current?.click();
  };

  const handleFileUploadChange = async (event: any) => {
    const { files } = event?.target as HTMLInputElement
    const file = files?.[0]

    if (file) {
      try {
        const base64DataUriRes = await getImageBase64DataUri(file)
        setFieldValue('bannerImage', base64DataUriRes)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const cancelImageUpload = () => {
    setFieldValue("bannerImage", null);
  };

  return (
    <Box>
      <Box component='section' className={classes.dialogContentTopSection}>
        <Typography className={classes.dialogContentWizardStepIndicator}>
          Step 2
        </Typography>
        <Typography className={classes.dialogContentHeaderTitle}>
          Customize your community
        </Typography>
        <Box className={classes.dialogContentTopSectionSeparator} />
        <input
          ref={uploadInputRef}
          type='file'
          accept='image/png,image/jpg,image/jpeg'
          style={{ display: "none" }}
          onChange={handleFileUploadChange}
        />
        <Box display='flex' flexDirection='column' gap='2rem'>
          {!values.bannerImage ? (
            <Box>
              <Box
                className={`${classes.dialogContentFileUploader} ${
                  touched?.bannerImage && Boolean(errors?.bannerImage)
                    ? classes.dialogContentFileUploaderError
                    : ""
                }`}
                onClick={handleOnFileUploadClick}
              >
                <Box display='flex' flexDirection='column' alignItems='center'>
                  <Typography className={classes.uploadFileTitle}>
                    Upload a Banner or <span>browse image</span>
                  </Typography>
                  <Typography className={classes.uploadFileSubtitle}>
                    Supported: PNG, JPEG
                  </Typography>
                </Box>
                <Box display='flex' flexDirection='column' alignItems='center'>
                  <Icon icon='illustration-upload-file' fontSize='5rem' />
                </Box>
              </Box>
              {touched?.bannerImage && Boolean(errors?.bannerImage) && (
                <Typography
                  style={{
                    color: "#d32f2f",
                    fontFamily: "Inter,Arial,Helvetica,sans-serif",
                    fontWeight: 400,
                    fontSize: "0.75rem",
                    lineHeight: 1.66,
                    textAlign: "left",
                    marginRight: "14px",
                  }}
                  component='span'
                >
                  {errors?.bannerImage}
                </Typography>
              )}
            </Box>
          ) : (
            <Box
              className={classes.dialogContentFileUploaded}
              style={{
                color: "white",
                background: `url(${values.bannerImage}) lightgray 50% / cover no-repeat`,
              }}
            >
              <Box
                display='flex'
                alignItems='center'
                justifyContent='flex-end'
                pt='.25rem'
                pr='.5rem'
                gap='.5rem'
              >
                <Box
                  className={classes.dialogContentFileUploadedActions}
                  onClick={handleOnFileUploadClick}
                >
                  <EditIcon />
                </Box>
                <Box
                  className={classes.dialogContentFileUploadedActions}
                  onClick={cancelImageUpload}
                >
                  <CloseIcon />
                </Box>
              </Box>
            </Box>
          )}
          <Box className={classes.dialogContentFormFieldContainer}>
            <FormTextField
              textFieldProps={{
                placeholder: "Paste Website URL",
                id: "socials.website",
                name: "socials.website",
                fullWidth: true,
                onChange: handleChange,
                onBlur: handleBlur,
                value: values?.socials.website,
              }}
              labelProps={{
                children: "Website URL",
                shrink: true,
                htmlFor: "socials.website",
              }}
              errorProps={{
                error: errors?.socials?.website,
                touched: touched?.socials?.website,
              }}
            />
            <Box className={classes.dialogContentFormFieldGridContainer}>
              <FormTextField
                textFieldProps={{
                  placeholder: "Paste Twitter URL",
                  id: "socials.twitter",
                  name: "socials.twitter",
                  fullWidth: true,
                  onChange: handleChange,
                  onBlur: handleBlur,
                  value: values?.socials?.twitter,
                }}
                labelProps={{
                  children: "Twitter",
                  shrink: true,
                  htmlFor: "socials.twitter",
                }}
                errorProps={{
                  error: errors?.socials?.twitter,
                  touched: touched?.socials?.twitter,
                }}
              />
              <FormTextField
                textFieldProps={{
                  placeholder: "Paste Telegram URL",
                  id: "socials.telegram",
                  name: "socials.telegram",
                  fullWidth: true,
                  onChange: handleChange,
                  onBlur: handleBlur,
                  value: values?.socials?.telegram,
                }}
                labelProps={{
                  children: "Telegram",
                  shrink: true,
                  htmlFor: "socials.telegram",
                }}
                errorProps={{
                  error: errors?.socials?.telegram,
                  touched: touched?.socials?.telegram,
                }}
              />
              <FormTextField
                textFieldProps={{
                  placeholder: "Paste Facebook URL",
                  id: "socials.facebook",
                  name: "socials.facebook",
                  fullWidth: true,
                  onChange: handleChange,
                  onBlur: handleBlur,
                  value: values?.socials?.facebook,
                }}
                labelProps={{
                  children: "Facebook",
                  shrink: true,
                  htmlFor: "socials.facebook",
                }}
                errorProps={{
                  error: errors?.socials?.facebook,
                  touched: touched?.socials?.facebook,
                }}
              />
              <FormTextField
                textFieldProps={{
                  placeholder: "Paste Instagram URL",
                  id: "socials.instagram",
                  name: "socials.instagram",
                  fullWidth: true,
                  onChange: handleChange,
                  onBlur: handleBlur,
                  value: values?.socials?.instagram,
                }}
                labelProps={{
                  children: "Instagram",
                  shrink: true,
                  htmlFor: "socials.instagram",
                }}
                errorProps={{
                  error: errors?.socials?.instagram,
                  touched: touched?.socials?.instagram,
                }}
              />
            </Box>
          </Box>
          {errorAlertProps?.isOpen && (
            <Alert severity='error' ref={errorAlertRef}>
              <AlertTitle>Error</AlertTitle>
              <Typography>{errorAlertProps.message}</Typography>
            </Alert>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default StageTwoWizard;
