import { ReactElement } from "react";
import { Box, styled } from "@mui/material";
import { BREAKPOINT_TABLET_MEDIUM } from 'theme/shared/breakpoint'

const Wrapper = styled(Box, {
  name: "PageTemplateWrapper",
})(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",

  [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
    marginTop: 0,
  },
}));

interface PageTemplateProps {
  children: any;
  appToolbar?: ReactElement;
  onPublishMemeSuccess?: VoidFunction;
}

const topBarHeight = "2rem";

const ContentContainer = styled(Box)<{ showAppToolBar: boolean }>(
  ({ showAppToolBar, theme }) => ({
    [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
      marginTop: showAppToolBar ? topBarHeight : 0,
    },
  })
);
const AppToolBar = styled(Box)(({ theme }) => ({
  [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: "100%",
    position: "fixed",
    minHeight: topBarHeight,
    zIndex: theme.zIndex.appBar,
    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.5)",
  },
}));

const PageTemplate = ({
  children,
  appToolbar,
}: PageTemplateProps): JSX.Element => {
  return (
    <Wrapper>
      {/*appToolbar && <AppToolBar>{appToolbar}</AppToolBar>*/}
      <ContentContainer showAppToolBar={false}>{children}</ContentContainer>
    </Wrapper>
  )
};

const StandardPageTemplateWrapper = styled(Box, {
  name: "StandardPageTemplateWrapper",
})({
  display: "grid",
  gridTemplateColumns: "1fr",
});

export const StandardPageTemplate = ({
  children,
}: {
  children: any;
}): JSX.Element => {
  return <StandardPageTemplateWrapper>{children}</StandardPageTemplateWrapper>;
};

export default PageTemplate;
