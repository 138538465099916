import { KVStore } from './kvStore'
import { StoredItemWithTTL } from './storedItem'

const KEYSET_STORAGE_KEY = 'web_storage_kv_store_key_list'

export class WebStorageKVStore extends KVStore {
  protected storage: Storage
  protected isDisabled: boolean

  public constructor (storage: Storage, isDisabled: boolean = false) {
    super()
    this.storage = storage
    this.isDisabled = isDisabled
  }

  protected async ensureKeySet (): Promise<void> {
    if (this.storage.getItem(KEYSET_STORAGE_KEY) === null) {
      this.storage.setItem(KEYSET_STORAGE_KEY, '{}')
    }
  }

  protected async getKeySet (): Promise<Map<string, number>> {
    const dataBlob = this.storage.getItem(KEYSET_STORAGE_KEY) ?? '{}'
    const data = JSON.parse(dataBlob) as Record<string, number>
    const dataMap = new Map(Object.entries(data))
    return dataMap
  }

  protected async setKeySet (keyset: Map<string, number>): Promise<void> {
    const jsonObject = Object.fromEntries(keyset)
    const jsonString = JSON.stringify(jsonObject)
    this.storage.setItem(KEYSET_STORAGE_KEY, jsonString)
  }

  protected async garbageCollectKeySet (): Promise<void> {
    const keyset = await this.getKeySet()
    const tNow = (new Date()).getTime()
    for (const [key, expiresAt] of Object.entries(keyset)) {
      if (expiresAt > 0 && expiresAt < tNow) {
        this.storage.removeItem(key)
      }
    }
  }

  protected async addToKeySet (key: string, expiresAt: number): Promise<void> {
    await this.garbageCollectKeySet()
    const keyset = await this.getKeySet()
    keyset.set(key, expiresAt)
    await this.setKeySet(keyset)
  }

  protected async removeFromKeySet (key: string): Promise<void> {
    await this.garbageCollectKeySet()
    const keyset = await this.getKeySet()
    keyset.delete(key)
    await this.setKeySet(keyset)
  }

  public async set<T>(
    key: string,
    value: T,
    expiresInSeconds: number = 3600,
    skipCache = false
  ): Promise<void> {
    if (skipCache || this.isDisabled) return
    const expiresAt = expiresInSeconds === 0 ? 0 : Date.now() + expiresInSeconds * 1000
    const storedItem = new StoredItemWithTTL<T>(key, value, expiresAt)
    const storedData = storedItem.asJSONString()
    this.storage.setItem(key, storedData)
    await this.addToKeySet(key, expiresAt)
  }

  public async get<T>(key: string, skipCache = false): Promise<T | null> {
    if (skipCache || this.isDisabled) return null
    const storedData = this.storage.getItem(key)
    if (storedData == null) return null
    const storedItem = StoredItemWithTTL.fromJSONString<T>(key, storedData)
    if (storedItem === null) {
      await this.del(key)
      return null
    }
    return storedItem.value
  }

  public async del (key: string): Promise<void> {
    this.storage.removeItem(key)
    await this.removeFromKeySet(key)
  }

  public async exists (key: string): Promise<boolean> {
    return this.get(key) !== null
  }

  public async clear (): Promise<void> {
    this.storage.clear()
  }
}
