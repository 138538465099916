import React, { Ref, forwardRef } from 'react'
import { getAssetsPath } from 'utils/path'

import { ImageProps } from 'components/atoms/Image'
import useImagePictureStyles from './style'

export interface ImagePictureProps extends ImageProps {
  pictureSources?: { srcSet: string; media?: string; type: string }[]
}

const ImagePicture = forwardRef(
  (
    {
      src,
      className,
      alt,
      width,
      height,
      onClick,
      crossOrigin = '',
      onLoad = () => {},
      pictureSources,
      srcSet,
      ...rest
    }: ImagePictureProps,
    ref?: Ref<HTMLImageElement>
  ): JSX.Element => {
    const classes = useImagePictureStyles()
    return (
      <picture className={`${classes.picture_default} ${className}`}>
        {pictureSources?.map((source, index) => {
          return <source key={index.toString()} {...source} />
        })}
        <img
          src={getAssetsPath(src)}
          srcSet={srcSet}
          alt={alt}
          onClick={(event: React.MouseEvent<HTMLImageElement>) => {
            if (onClick) {
              onClick(event)
            }
          }}
          width={width}
          height={height}
          ref={ref}
          crossOrigin={crossOrigin}
          onLoad={onLoad}
          {...rest}
        />
      </picture>
    )
  }
)

export default ImagePicture
