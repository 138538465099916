import { Box, Button, InputBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { SwitchButton, Image } from 'components/shared/index'
import { imagesSvg } from 'constants/images'

const useStyles = makeStyles({
  item: {
    display: 'flex',
    borderBottom: '1px solid #EFEFEF',
    padding: '6px 0 6px 10px',
    margin: '0 0 0 10px',
    width: '100%',
    justifyContent: 'space-between'
  },
  itemtitle: {
    font: 'normal normal normal 12px/31px Gotham Rounded',
    color: '#1A0030',
    width: '33%',
    fontWeight: 'bold'
  },
  itemcontent: {
    font: 'normal normal normal 12px/31px Gotham Rounded',
    color: '#808080',
    lineHeight: '27px',
    width: '60%'
  },
  itemimage: {
    minWidth: '30px'
  },
  dbcolor: {
    color: '#1A0030',
    fontWeight: 'bold'
  },
  switch: {
    marginLeft: 'auto',
    marginRight: 0
  },
  authentication: {
    font: 'normal normal normal 12px/31px Gotham Rounded',
    color: '#1A0030',
    width: '40%',
    fontWeight: 'bold'
  }
})

const SecuritySetting = (): JSX.Element => {
  const classes = useStyles()

  const [showPassword, setShowPassword] = useState<boolean>(false)

  const handleClickShowPassword = (): void => {
    setShowPassword(true)
  }

  const keyPress = (e: any): void => {
    if (e.keyCode === 13) {
      setShowPassword(false)
    }
  }

  return (
    <Box>
      <Box className={classes.item}>
        <Typography className={classes.itemtitle}>Password</Typography>
        {showPassword
          ? (
            <InputBase
              className={classes.itemcontent}
              disabled={false}
              type='text'
              defaultValue='current_password:)'
              onKeyDown={keyPress}
            />
            )
          : (
            <InputBase
              className={classes.itemcontent}
              disabled
              type='password'
              defaultValue='password'
              onKeyDown={keyPress}
            />
            )}
        <Button
          className={classes.itemimage}
          onClick={() => handleClickShowPassword()}
        >
          <Image src={`checkIcons/${imagesSvg.editText}`} alt='logo' />
        </Button>
      </Box>
      <Box className={classes.item}>
        <Typography className={classes.authentication}>
          2FA Authentification
        </Typography>
        <SwitchButton
          width={42}
          height={26}
          thumb={22}
          transform={16}
          thumbColor='#884BFF'
          trackColor='#292F3A'
          checkColor='rgba(136, 75, 255, 0.2)'
        />
      </Box>
    </Box>
  )
}

export default SecuritySetting
