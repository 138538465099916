import {Box, Button, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {imagesSvg} from 'constants/images'
import moment from 'moment'
import {Image} from 'components/shared/index'
import {FacebookShareButton, TelegramShareButton, TwitterShareButton} from 'react-share'
import {google} from "calendar-link";
import React from 'react'
import { Event } from 'ymca/models/event.model'
import colors from 'theme/dark/colors'


const useStyles = makeStyles({

  cardsContainer: {
    marginTop: 26,
    display: 'flex',
    flexWrap: 'wrap'
  },

  events: {
    marginTop: 25
  },
  title: {
    fontSize: 20,
    fontWeight: 800,
    color: '#E2E4E9'
  },
  name: {
    fontSize: 18,
    fontWeight: 900,
    color: '#E2E4E9',
    textTransform: "capitalize"
  },
  eventBox: {
    display: "flex",
    gap: 16,
    width: "100%",
  },
  banner: {
    width: '40%',
    //   height: 163,
    borderRadius: 7,
    backgroundColor: '#7075FF',
    backgroundPosition: "center"
  },
  date: {
    fontSize: 11,
    fontWeight: 700,
    color: '#7075FF',
    textTransform: "uppercase"
  },
  location: {
    color: "#808893",
    fontSize: 13,
    fontWeight: 700,
    marginTop: 11,
    display: "flex",
    alignItems: "center",
    gap: 5
  },

  tags: {
    display: "flex",
    gap: 5,
    marginTop: 16,
    "& .tag": {
      padding: '4px 11px',
      heigth: 24,
      background: "#21282E",
      borderRadius: 7,
      "& p": {
        fontSize: 12,
        fontWeight: 700,
        color: '#E2E4E9'
      }
    }
  },
  addCalendarButtonSection: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  addToCalendarButton: {
    width: '100%',
    height: '50px',
    borderRadius: '7px',
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      marginRight: '8.44px'
    },
    '&:hover': {
      backgroundColor: '#7075FF'
    }
  },
  optionEventBtn: {
    height: '50px',
    width: '50px',
    backgroundColor: '#21282E',
    borderRadius: '7px',
    boxSizing: 'border-box',
    marginLeft: '7px',
    '&:hover': {
      backgroundColor: '#21282E'
    }
  },
  resourceEventBtn: {
    height: '49px',
    width: '154px',
    backgroundColor: '#21282E',
    borderRadius: '7px',
    boxSizing: 'border-box',
    alignSelf: 'flex-start',
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: '14px',
    letterSpacing: '-0.3px',
    lineHeight: '24px',
    marginTop: '16px',
    '&:hover': {
      backgroundColor: '#21282E'
    }
  },
  resourceEventBtns: {
    display: 'flex'
  },
  twitterEventBtn: {
    height: '50px',
    backgroundColor: '#21282E',
    borderRadius: '7px',
    boxSizing: 'border-box',
    marginLeft: '7px',
    alignSelf: 'flex-end',
    '&:hover': {
      backgroundColor: '#21282E'
    }
  },
  sharegroup: {
    display: 'flex',
    gap: 5,
    marginTop: '16px'
  },
  reactbtn: {
    height: '50px',
    width: '50px',
    backgroundColor: '#21282E',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '7px'
  },
  imageStyles: {
    width: '20px',
    height: '20px',
    alignSelf: 'center'
  },
  admin:{
    color: '#E2E4E9',
    textAlign:"center",
    fontSize:"1rem"
  }
})


function getDifference(date1: any, date2: any, endDate?: any) {
  const diffInMs = date2 - date1
  const closedate = endDate - date1

  if (diffInMs / (1000 * 60 * 60 * 24) + diffInMs / (1000 * 60 * 60) + diffInMs / (1000 * 60) + diffInMs / 1000 > 0) {
    return 'upcoming'
  } else if (closedate / (1000 * 60 * 60) + closedate / (1000 * 60) + closedate / 1000 < 0) {
    return "closed"
  }
  return "live"
}

function getDuration(date1: any, endDate?: any) {
  const diffInMs = endDate - date1
  if (Math.floor(diffInMs / (1000 * 60 * 60 * 24)) === 0 && Math.floor(diffInMs / (1000 * 60 * 60)) === 0 && Math.floor(diffInMs / (1000 * 60)) === 0) {
    return `${Math.floor((endDate.getTime() - date1.getTime()) / 1000)} secs`
  }
  if (Math.floor(diffInMs / (1000 * 60 * 60 * 24)) === 0 && Math.floor(diffInMs / (1000 * 60 * 60)) === 0) {
    return `${Math.floor((endDate.getTime() - date1.getTime()) / (1000 * 60))} mins`
  }
  if (Math.floor(diffInMs / (1000 * 60 * 60 * 24)) === 0) {
    return `${Math.floor((endDate.getTime() - date1.getTime()) / (1000 * 60 * 60))} hours`
  }

  return `${Math.floor(diffInMs / (1000 * 60 * 60 * 24))} days`
}

function EventBox({ item, category }: { item: Event, category: "live" | "closed" | "upcoming" }) {
  const classes = useStyles()
  return (
    <Box className={classes.eventBox}>
      <Box className={classes.banner} style={{
        backgroundImage: `url(${item?.image?.jpegUrl})`,
        backgroundSize: "cover"
      }}></Box>

      <Box style={{ width: '60%' }}>
        <Typography className={classes.date}>{category !== 'live' && moment(new Date(item.startingDate)).format('ddd ,Do MMMM')}  {category !== 'live' && moment(item.startingDate).format('LT z') + "-"} {moment(new Date(item.endingDate)).format('ddd ,Do MMMM')} {moment(item.endingDate).format('LT z')} </Typography>
        <Typography className={classes.name}>{item.name}</Typography>
        {item?.type === 'default' && item.urlWebsite && item.urlWebsite!=='null'&& <Typography className={classes.location}> <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.99398 1.33337C4.31398 1.33337 1.33398 4.32004 1.33398 8.00004C1.33398 11.68 4.31398 14.6667 7.99398 14.6667C11.6807 14.6667 14.6673 11.68 14.6673 8.00004C14.6673 4.32004 11.6807 1.33337 7.99398 1.33337ZM12.614 5.33337H10.6473C10.434 4.50004 10.1273 3.70004 9.72732 2.96004C10.954 3.38004 11.974 4.23337 12.614 5.33337ZM8.00065 2.69337C8.55398 3.49337 8.98732 4.38004 9.27398 5.33337H6.72732C7.01398 4.38004 7.44732 3.49337 8.00065 2.69337ZM2.84065 9.33337C2.73398 8.90671 2.66732 8.46004 2.66732 8.00004C2.66732 7.54004 2.73398 7.09337 2.84065 6.66671H5.09398C5.04065 7.10671 5.00065 7.54671 5.00065 8.00004C5.00065 8.45337 5.04065 8.89337 5.09398 9.33337H2.84065ZM3.38732 10.6667H5.35398C5.56732 11.5 5.87398 12.3 6.27398 13.04C5.04732 12.62 4.02732 11.7734 3.38732 10.6667ZM5.35398 5.33337H3.38732C4.02732 4.22671 5.04732 3.38004 6.27398 2.96004C5.87398 3.70004 5.56732 4.50004 5.35398 5.33337ZM8.00065 13.3067C7.44732 12.5067 7.01398 11.62 6.72732 10.6667H9.27398C8.98732 11.62 8.55398 12.5067 8.00065 13.3067ZM9.56065 9.33337H6.44065C6.38065 8.89337 6.33398 8.45337 6.33398 8.00004C6.33398 7.54671 6.38065 7.10004 6.44065 6.66671H9.56065C9.62065 7.10004 9.66732 7.54671 9.66732 8.00004C9.66732 8.45337 9.62065 8.89337 9.56065 9.33337ZM9.72732 13.04C10.1273 12.3 10.434 11.5 10.6473 10.6667H12.614C11.974 11.7667 10.954 12.62 9.72732 13.04ZM10.9073 9.33337C10.9607 8.89337 11.0007 8.45337 11.0007 8.00004C11.0007 7.54671 10.9607 7.10671 10.9073 6.66671H13.1607C13.2673 7.09337 13.334 7.54004 13.334 8.00004C13.334 8.46004 13.2673 8.90671 13.1607 9.33337H10.9073Z" fill="#808893" />
        </svg>
          {item?.urlWebsite}</Typography>}
        <Typography className={classes.location}>
          <svg width="16px" height="16px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path fill="#808893" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z" /></svg>Duration: {getDuration(new Date(item.startingDate), new Date(item.endingDate))}</Typography>
        <Box className={classes.resourceEventBtns}>
          {item?.type !== 'competition' && <><Box className={classes.sharegroup}>
            {item.eventMetadata?.contestDetail?.social?.twitter && <Box className={classes.reactbtn}>
              <TwitterShareButton windowWidth={750} windowHeight={600} url={''}>
                <Image alt="twitter" src={`events/${imagesSvg.twitterResourceLink}`} />
              </TwitterShareButton>
            </Box>}
            {item.eventMetadata?.contestDetail?.social?.facebook && <Box className={classes.reactbtn}>
              <FacebookShareButton windowWidth={750} windowHeight={600} url={''}>
                <Image src={`shareIcons/${imagesSvg.facebookShare}`} alt='facebook share icon' />
              </FacebookShareButton>
            </Box>}
            {item.eventMetadata?.contestDetail?.social?.telegram && <Box className={classes.reactbtn}>
              <TelegramShareButton windowWidth={750} windowHeight={600} url={''}>
                <Image src={`shareIcons/${imagesSvg.telegramShare}`} alt='telegram share icon' />
              </TelegramShareButton>
            </Box>}
            {item.eventMetadata?.contestDetail?.social?.instagram && <Box className={classes.reactbtn}>
              <TelegramShareButton windowWidth={750} windowHeight={600} url={''}>
                <Image src={`shareIcons/${imagesSvg.inshare}`} alt='telegram share icon' />
              </TelegramShareButton>
            </Box>}
          </Box>
          </>
          }
        </Box>
        {
          item?.type === 'competition' &&
          <Box>
            <Typography className={classes.location}>  Number of winners: {item?.eventMetadata?.contestDetail?.maxWinners}</Typography>
            <Typography className={classes.location}> Reward: {item?.eventMetadata?.contestDetail?.reward}</Typography>
          </Box>
        }
        <Box className={classes.addCalendarButtonSection}>
          <Button href={google({
            title: item?.name,
            description: item?.description,
            start: item?.startingDate,
            end: item?.endingDate
          })}
            target="_blank" rel="noreferrer"
            className={classes.addToCalendarButton}
            disabled={category === 'live' ? false : category === 'upcoming' ? false : true}
            style={{ pointerEvents: category === 'live' ? 'auto' : category === 'upcoming' ? 'auto' : 'none', color: category === "closed" ? "gray" : "white", filter: category === "closed" ? 'grayscale(1)' : "none", background: category === "closed" ? colors.darkBlue50 : '#7075FF' }}
          >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill="currentColor" d="M8.00013 12.8308L11.8043 15.1317C12.501 15.5533 13.3535 14.93 13.1701 14.1417L12.1618 9.81499L15.526 6.89999C16.1401 6.36832 15.8101 5.35999 15.0035 5.29582L10.576 4.91999L8.84346 0.831655C8.53179 0.0891553 7.46846 0.0891553 7.15679 0.831655L5.42429 4.91082L0.996794 5.28666C0.190128 5.35082 -0.139872 6.35916 0.474294 6.89082L3.83846 9.80582L2.83013 14.1325C2.64679 14.9208 3.49929 15.5442 4.19596 15.1225L8.00013 12.8308Z" />
            </svg>

            Add to calendar
          </Button>
        </Box>
      </Box>
    </Box>
  )
}


export function Eventlist({ events,upcoming, getNeccessaryComponent , live }: { events: any,upcoming:any, getNeccessaryComponent?: any ,live:any}) {
  const classes = useStyles()
  return (
    events.length === 0 ? getNeccessaryComponent() : <>

{
  live?.length>0&&<>
        <Box className={classes.events}>
        <Typography variant="h1" className={classes.title}>Live Events</Typography>
      </Box>
      <Box>
        {live?.length > 0
          ? <Box className={classes.cardsContainer} style={{ gap: 35 }}>
            {live?.map((item: any, index: number) => (
              <React.Fragment key={item.id}>
                {getDifference(new Date(), new Date(item.startingDate), new Date(item.endingDate)) === 'live' &&
                  <EventBox item={item} category='live' key={item.id} />
                }
              </React.Fragment>
            ))}
          </Box>
          : getNeccessaryComponent()}
      </Box>
  </>
}


    {
     upcoming?.length>0&& <>
        <Box className={classes.events}>
        <Typography variant="h1" className={classes.title}>Upcoming Events</Typography>
      </Box>
      <Box>
        {upcoming?.length > 0
          ? <Box className={classes.cardsContainer} style={{ gap: 35 }}>
            {upcoming?.map((item: any, index: number) => (
              <React.Fragment key={item.id}>
                {getDifference(new Date(), new Date(item.startingDate), new Date(item.endingDate)) === 'upcoming' &&
                  <EventBox item={item} category='upcoming' key={item.id} />
                }
              </React.Fragment>
            ))}
          </Box>
          : getNeccessaryComponent()}
      </Box>
      </>
    }

 {
  events?.length>0&&<>
       <Box className={classes.events}>
        <Typography variant="h1" className={classes.title}>Closed Events</Typography>
      </Box>
      <Box>
        {events?.length > 0
          ? <Box className={classes.cardsContainer} style={{ gap: 35 }}>
            {events?.map((item: any, index: number) => (
              <React.Fragment key={item.id}>
                {getDifference(new Date(), new Date(item.startingDate), new Date(item.endingDate)) === 'closed' &&
                  <EventBox item={item} category='closed' key={item.id} />
                }
              </React.Fragment>
            ))}
          </Box>
          : getNeccessaryComponent()}
      </Box>
  </>
 }

<p className={classes.admin}>To add and promote your event, contact an <a target="_blank" href="https://t.me/Alex_andre_18" style={{ color: "white" }}><u>admin</u></a></p>
    </>
  )
}