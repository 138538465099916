import { Typography } from '@material-ui/core'

export interface EmptyItemProps {
  itemName: string
  isAuthenticated: boolean | undefined
  className: string
}

export const EmptyItem = ({ itemName, isAuthenticated, className }: EmptyItemProps): JSX.Element => {
  return (
    <Typography className={className}>
      {isAuthenticated === true
        ? <Typography>{itemName === 'events' ? <>To add and promote your event, contact an <a target="_blank" href="https://t.me/Alex_andre_18" style={{ color: "white" }}><u>admin</u></a>.</> : `No ${itemName} to show`}</Typography>
        : <Typography>{itemName}</Typography>}
    </Typography>
  )
}
