import { forwardRef, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@material-ui/icons/Close";
import * as Yup from "yup";
import { useFormik } from "formik";

import useCreateCommunityModalStyles from "./style";
import StageOneWizard from "./StageOneWizard";

import StageTwoWizard from "./StageTwoWizard";
import useWindowSize from "hooks/useWindowSize";
import { BREAKPOINT_TABLET_LARGE } from "theme/shared/breakpoint";
import { DefaultYMCA } from "ymca/ymca";
import { UpdateCommunityDTO } from "ymca/dtos/community.dto";
import Loading from "components/loading/Loading";
import useSnackbar from "hooks/useSnackbar";
import { useMutation } from "@tanstack/react-query";
import useAlert from "hooks/useAlert";
import { Community } from "ymca/models/community.model";
import { useGetCommunityBySlugQuery } from "hooks/api-hooks/useCommunity";
import AtomLoading from "components/atoms/AtomLoading";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

interface ManageCommunityProps {
  community: Community;
  onSuccess: Function;
  isOpen: boolean;
  close: () => void;
}

const ManageCommunityModal = ({
  isOpen,
  close,
  community,
  onSuccess,
}: ManageCommunityProps) => {
  const classes = useCreateCommunityModalStyles();

  const [stage, setStage] = useState<number>(1);

  const [formData, setFormData] = useState<Record<string, any> | null>(null);
  const { width } = useWindowSize();
  const showDialogFullScreen = width <= BREAKPOINT_TABLET_LARGE;
  const {
    isOpen: isSnackbarOpen,
    close: closeSnackbar,
    open: showSnackbar,
  } = useSnackbar();
  const {
    isOpen: isErrorSnkbarOpen,
    close: closeErrorSnkbar,
    open: showErrorSnkbar,
  } = useSnackbar();
  const errorAlert = useAlert();

  const errorAlertSectionRef = useRef<HTMLElement | null>(null);

  const onClose = () => {
    resetForm({ close, resetStage: true });
  };

  const { isLoading, data } = useGetCommunityBySlugQuery({
    slug: community.slug,
    enabled: isOpen,
  });

  const mutation = useMutation({
    mutationFn: (data) => {
      const updateDTO = new UpdateCommunityDTO();
      updateDTO.name = data?.name;
      updateDTO.description = data?.description;
      updateDTO.isPrivate = data?.isPrivate;
      updateDTO.isNsfw = false;
      if (jpegUrl !== data?.bannerImage) {
        updateDTO.backgroundImage = data?.bannerImage;
      }

      updateDTO.socialLinks = data?.socials;
      return DefaultYMCA.communityService.updateCommunity(
        community.id,
        updateDTO
      );
    },
    onError: (error: any, variables: any, context: any) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (data: any, variables: any, context: any) => {
      const { data: responseData, isSuccess } = data;
      console.log("onSuccess", { data, variables, context });

      if (isSuccess) {
        // show snackbar notice
        showSnackbar();

        onSuccess();

        // close form
        onClose();
      } else {
        const { message } = responseData;
        showErrorSnkbar();
        errorAlert.open({ message });
        if (errorAlertSectionRef?.current) {
          errorAlertSectionRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          });
        }
      }
    },
  });

  const { description, name, isPrivate, background, socialLinks } = data ?? {};
  const { jpegUrl } = background ?? {};
  const { twitter, facebook, website, telegram, instagram } = socialLinks ?? {};

  console.log("background", background);

  const formikStage1 = useFormik({
    initialValues: {
      description,
      isPrivate,
      name
    },
    validationSchema: () =>
      Yup.object().shape({
        description: Yup.string()
          .min(10, 'Too Short!')
          .max(200, 'Too Long!')
          .matches(
            /^[a-zA-Z0-9_ \\\\.\\\\-\\\\?]*$/,
            'Special character not allowed'
          )
          .required('Required'),
        name: Yup.string()
          .min(5, 'Too Short!')
          .max(15, 'Too Long!')
          .matches(/^[a-zA-Z0-9- ]+$/, 'Only (-) character is allowed')
          .required('Required'),
        isPrivate: Yup.bool().required('Required')
      }),
    onSubmit: (values, { setSubmitting }) => {
      setFormData(values)
      setSubmitting(false)
      setStage(2)
    }
  })

  const handleStage1Submission = () => {
    formikStage1.handleSubmit();
  };

  const formikStage2 = useFormik({
    initialValues: {
      bannerImage: jpegUrl,
      socials: {
        twitter,
        instagram,
        facebook,
        telegram,
        website,
      },
    },
    validationSchema: () =>
      Yup.object().shape({
        bannerImage: Yup.string().required("Required"),
        socials: Yup.object().shape({
          twitter: Yup.string()
            .url("Invalid URL")
            .matches(
              /^(https?:\/\/)?(?:www\.)?(?:twitter\.com)\/[a-zA-Z0-9_]{1,15}\/?$/,
              "Invalid Twitter URL"
            ),
          telegram: Yup.string()
            .url("Invalid URL")
            .matches(
              /^(https?:\/\/)?(?:www\.)?(?:t\.me)\/[a-zA-Z0-9_]{1,15}\/?$/,
              "Invalid Telegram URL"
            ),
          instagram: Yup.string()
            .url("Invalid URL")
            .matches(
              /^(https?:\/\/)?(?:www\.)?(?:instagram\.com)\/[a-zA-Z0-9_]{1,15}\/?$/,
              "Invalid Instagram URL"
            ),
          facebook: Yup.string()
            .url("Invalid URL")
            .matches(
              /^(https?:\/\/)?(?:www\.)?(?:facebook\.com)\/[a-zA-Z0-9_]{1,15}\/?$/,
              "Invalid Facebook URL"
            ),
          website: Yup.string().url(),
        }),
      }),
    onSubmit: (values, { setSubmitting }) => {
      setFormData((prev) => ({ ...prev, ...values }));

      mutation.mutate({ ...formData, ...values });
    },
  });

  const handleStage2Submission = () => {
    formikStage2.handleSubmit();
  };

  const resetForm = ({
    close,
    resetStage,
  }: {
    close?: VoidFunction;
    resetStage?: boolean;
  }) => {
    // clear form before closing

    mutation.reset();
    setFormData(null);

    close && close();

    if (stage !== 1 && resetStage) {
      setTimeout(() => setStage(1), 500);
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby='create-community-modal'
        open={isOpen}
        className={classes.dialog}
        fullScreen={showDialogFullScreen}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }} className={classes.appBar}>
          <Toolbar>
            <Typography variant='h6' flex={1}>
              {stage === 2 ? "Step 2" : "Step 1"}
            </Typography>
            <IconButton
              edge='start'
              color='inherit'
              onClick={close}
              aria-label='cancel-create-community'
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent className={classes.dialogContent}>
          <Box component='section' className={classes.dialogContentLeft} />
          {isLoading ? (
            <AtomLoading />
          ) : (
            <Box component='section' className={classes.dialogContentRight}>
              <Box className={classes.dialogCloseWrapper}>
                <IconButton
                  className={classes.dialogCloseBtn}
                  color='inherit'
                  edge='start'
                  onClick={onClose}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              {stage === 2 ? (
                <StageTwoWizard
                  formikFormProps={formikStage2}
                  errorAlertProps={errorAlert}
                  errorAlertRef={errorAlertSectionRef}
                />
              ) : (
                <StageOneWizard formikFormProps={formikStage1} />
              )}
            </Box>
          )}
        </DialogContent>
        <Snackbar
          open={isErrorSnkbarOpen}
          autoHideDuration={3000}
          onClose={closeErrorSnkbar}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Alert severity='error'>Error creating community</Alert>
        </Snackbar>
        <DialogActions className={classes.dialogActions}>
          <Typography className={classes.dialogActionsTypography}>
            By continuing, you agree to YouMeme’s{" "}
            <span className={classes.dialogActionsTypographyLink}>
              Terms of Service
            </span>{" "}
            and our{" "}
            <span className={classes.dialogActionsTypographyLink}>
              Privacy Policy.
            </span>
          </Typography>

          <Box className={classes.dialogActionsBtns}>
            <Button
              onClick={onClose}
              className={classes.dialogActionsCancelBtn}
            >
              Cancel
            </Button>

            {stage === 2 && (
              <Button
                onClick={() => setStage(1)}
                className={classes.dialogActionsGoBackBtn}
              >
                Back
              </Button>
            )}

            {stage === 2 ? (
              <Button
                autoFocus
                onClick={handleStage2Submission}
                className={classes.dialogActionsContinueBtn}
                disabled={mutation.isLoading}
              >
                <div>Create</div>
                {mutation.isLoading && (
                  <Box>
                    <Loading style={{ width: "2rem", height: "2rem" }} />
                  </Box>
                )}
              </Button>
            ) : (
              <Button
                autoFocus
                onClick={handleStage1Submission}
                className={classes.dialogActionsContinueBtn}
              >
                Continue
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert severity='success'>Community created successfully</Alert>
      </Snackbar>
    </>
  );
};

export default ManageCommunityModal;
