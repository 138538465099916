import React from 'react'
import { Box, ClickAwayListener } from '@material-ui/core'
import { Alert, IconButton, Snackbar, styled } from '@mui/material'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  VKShareButton,
  WhatsappShareButton
} from 'react-share'

import { imagesSvg } from 'constants/images'
import { Image } from 'components/shared'
import useWindowSize from 'hooks/useWindowSize'
import Icon from 'components/atoms/Icons'
import Typography from 'components/atoms/Typography'
import { Post as PostModel } from 'ymca/models/post.model'
import {
  BREAKPOINT_MOBILE_LARGE,
  BREAKPOINT_MOBILE_SMALL
} from 'theme/shared/breakpoint'
import usePublicationShareStyles from './style'
import useSnackbar from 'hooks/useSnackbar'
import ShareDropDown from './components/ShareDropDown'
import useMenu, { UseMenuResult } from 'hooks/useMenu'
import useDrawer, { UseDrawerResult } from 'hooks/useDrawer'

const Wrapper = styled(IconButton, {
  name: 'PublicationCommentCountWrapper'
})(({ theme }) => ({
  '&.MuiButtonBase-root': {
    display: 'flex',
    alignItems: 'center',
    gap: '.3rem',
    padding: '.2rem .5rem',
    borderRadius: '6.2rem',
    transition: 'transform 0.1s ease-out',

    border: `1px solid ${theme.palette.divider}`,

    '&:hover': {
      transform: 'translateY(1px)'
    },
    '&:active': {
      boxShadow: 'none',
      transform: 'translateY(4px)'
    },

    [`@media (max-width: ${BREAKPOINT_MOBILE_SMALL}px)`]: {
      gap: '.2rem'
    }
  }
}))

const ShareIcon = styled(Icon)(({ theme }) => ({
  fontSize: '1.2rem',
  [`@media (max-width: ${BREAKPOINT_MOBILE_SMALL}px)`]: {
    fontSize: '1rem'
  }
}))
const StyledTypography = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-body1': {
    fontSize: '0.9rem',
    [`@media (max-width: ${BREAKPOINT_MOBILE_SMALL}px)`]: {
      fontSize: '0.7rem'
    }
  }
}))

export interface PublicationShareProp {
  post: PostModel
  isModal?: boolean
}

const PublicationShare = (props: PublicationShareProp): JSX.Element => {
  const isMobile: boolean = useWindowSize().width <= BREAKPOINT_MOBILE_LARGE

  const { close, open, isOpen } = useSnackbar()

  const shareSocialMenuProps = useMenu()
  const shareSocialDrawerops = useDrawer()

  const handleOpenShareSocial = (event: React.MouseEvent<HTMLElement>) => {
    if (isMobile) {
      shareSocialDrawerops.open()
    } else {
      shareSocialMenuProps.open(event)
    }
  }

  const handleCloseShareSocial = () => {
    shareSocialDrawerops?.close()
    shareSocialMenuProps?.close()
  }

  const onClickAwayHandler = () => {
    handleCloseShareSocial()
  }

  const showShare = shareSocialMenuProps.isOpen || shareSocialDrawerops.isOpen

  return (
    <>
      <ClickAwayListener onClickAway={onClickAwayHandler}>
        <Box sx={{ position: 'relative' }}>
          <Wrapper onClick={handleOpenShareSocial}>
            <ShareIcon icon='share' />
            <StyledTypography fontWeight={700}>Share</StyledTypography>
          </Wrapper>

          {showShare ? (
            <SharePanel
              {...props}
              shareSocialMenuProps={shareSocialMenuProps}
              shareSocialDrawerops={shareSocialDrawerops}
              onClose={handleCloseShareSocial}
              onCopy={open}
            />
          ) : null}
        </Box>
      </ClickAwayListener>
      <Snackbar
        open={isOpen}
        autoHideDuration={5000}
        onClose={close}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert variant='standard' severity='info'>
          Copied to clipboard!
        </Alert>
      </Snackbar>
    </>
  )
}

interface SharePanelProps extends PublicationShareProp {
  onClose: () => void
  shareSocialMenuProps: UseMenuResult
  shareSocialDrawerops: UseDrawerResult
  onCopy: () => void
}
function SharePanel({
  post,
  onClose,
  shareSocialMenuProps,
  shareSocialDrawerops,
  onCopy
}: SharePanelProps) {
  const classes = usePublicationShareStyles()
  const { width } = useWindowSize()
  const isMobile: boolean = width <= BREAKPOINT_MOBILE_LARGE

  const emailSubject = `Check out this funny meme on YouMeme.com! :D`
  const emailBody = `${post.title ? post.title : ''}\n\n`

  const { isOpen: isDrawerOpen } = shareSocialDrawerops

  return (
    <>
      {isMobile ? (
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={isDrawerOpen}
          onClose={onClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={isDrawerOpen}>
            <div className={classes.mobilesharepanel}>
              <button onClick={onClose} className={classes.mobilesharedash}>
                <span style={{ visibility: 'hidden' }}>ss</span>
              </button>
              <p> Share meme </p>
              <CopyToClipboard
                text={post.seo.url}
                onCopy={() => {
                  onCopy()
                  onClose()
                }}
              >
                <button className={classes.mobilesharecopybutton}>
                  <>
                    <Image
                      src={`reactionIcons/${imagesSvg.copyLink}`}
                      alt='copy link icon'
                      className={classes.imgStyle}
                    />
                    <span id='copymemetext'>Copy Link</span>
                  </>
                </button>
              </CopyToClipboard>
              <EmailShareButton
                style={{ marginTop: '20px' }}
                subject={emailSubject}
                className={classes.shareItem}
                body={emailBody}
                url={post.seo.url}
              >
                <button className={classes.mobilesharecopybutton}>
                  <Image
                    src={`shareIcons/${imagesSvg.envelope}`}
                    alt='share email icon'
                  />
                  <Typography style={{ paddingLeft: '10px' }}>
                    Share via email
                  </Typography>
                </button>
              </EmailShareButton>
              <div className={classes.shareiconsbox}>
                <div className={classes.share2}>
                  <TwitterShareButton
                    windowWidth={750}
                    windowHeight={600}
                    title={post.title}
                    url={post.seo.url}
                    via={'YouMemeWorld'}
                  >
                    <Image
                      alt='twitter icon'
                      src={`shareIcons/${imagesSvg.mobileTwitter}`}
                    />
                    <p>twitter</p>
                  </TwitterShareButton>
                  <FacebookShareButton
                    windowWidth={750}
                    windowHeight={600}
                    quote={`${post.title} via @YouMemeWorld`}
                    url={post.seo.url}
                  >
                    <Image
                      alt='facebook icon'
                      src={`shareIcons/${imagesSvg.mobileFacebook}`}
                    />
                    <p>Facebook</p>
                  </FacebookShareButton>
                  <RedditShareButton
                    windowWidth={750}
                    windowHeight={600}
                    title={post.title}
                    url={post.seo.url}
                  >
                    <Image
                      alt='reddit icon'
                      src={`shareIcons/${imagesSvg.mobileReddit}`}
                    />
                    <p>Reddit</p>
                  </RedditShareButton>
                  <WhatsappShareButton
                    windowWidth={750}
                    windowHeight={600}
                    title={post.title}
                    url={post.seo.url}
                  >
                    <Image
                      alt='whatsapp icon'
                      src={`shareIcons/${imagesSvg.mobileWhatsapp}`}
                    />
                    <p>WhatsApp</p>
                  </WhatsappShareButton>
                </div>
                <div className={classes.share2}>
                  <LinkedinShareButton
                    windowWidth={750}
                    windowHeight={600}
                    title={post.title}
                    summary={post.description}
                    source={'https://www.linkedin.com/company/youmeme/'}
                    url={post.seo.url}
                  >
                    <Image
                      alt='linkedin icon'
                      src={`shareIcons/${imagesSvg.mobileLinkedin}`}
                    />
                    <p>LinkedIn</p>
                  </LinkedinShareButton>
                  <PinterestShareButton
                    windowWidth={750}
                    windowHeight={600}
                    url={post.seo.url}
                    media={post.seo.image}
                    description={post.title}
                  >
                    <Image
                      alt='pinterest icon'
                      src={`shareIcons/${imagesSvg.mobilePinterest}`}
                    />
                    <p>Pinterest</p>
                  </PinterestShareButton>
                  <TelegramShareButton
                    windowWidth={750}
                    windowHeight={600}
                    title={post.title}
                    url={post.seo.url}
                  >
                    <Image
                      alt='telegram icon'
                      src={`shareIcons/${imagesSvg.mobileTelegram}`}
                    />
                    <p>Telegram</p>
                  </TelegramShareButton>
                  <VKShareButton
                    windowWidth={750}
                    windowHeight={600}
                    title={post.title}
                    image={post.seo.image}
                    url={post.seo.url}
                  >
                    <Image
                      alt='vk icon'
                      src={`shareIcons/${imagesSvg.mobileVk}`}
                    />
                    <p>VKontakte</p>
                  </VKShareButton>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      ) : (
        <ShareDropDown
          post={post}
          onCopy={onCopy}
          emailProps={{
            subject: emailSubject,
            body: emailBody
          }}
          onClose={onClose}
          shareSocialMenuProps={shareSocialMenuProps}
        />
      )}
    </>
  )
}

export default PublicationShare
