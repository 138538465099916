import Box from '@material-ui/core/Box'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { makeStyles } from '@material-ui/core/styles'
import colors from 'theme/dark/colors'

export interface SizeProps {
  size: number
}

const useStyles = makeStyles({
  root: {
    display: 'block'
  },
  indicator: {
    backgroundColor: colors.primary500,
    height: 4,
    borderRadius: 5
  },
  tabs: {
    color: '#fff',
    fontWeight: 700
  },
  tab: {
    fontSize: 14,
    borderBottom: (props: SizeProps) =>
      `${props.size}px solid ${colors.border}`,
    '&.MuiTab-root': {
      'min-width': '70px'
    }
  }
})

interface MobileTabsProp {
  value: number
  setValue: (value: number) => void
  items: any
  tab?: number
}

const MobileTabs = ({ value, setValue, items, tab = 0 }: MobileTabsProp): JSX.Element => {
  const classes = useStyles({ size: tab ?? 0 })

  const handleChange = (_: any, newValue: any): void => {
    setValue(newValue)
  }

  return (
    <Box className={classes.root}>
      <Box color='default'>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          variant='fullWidth'
          classes={{ indicator: classes.indicator, root: classes.tabs }}
        >
          {items.map((item: any, index: number) => (
            <Tab label={item} key={index} className={classes.tab} />
          ))}
        </Tabs>
      </Box>
    </Box>
  )
}

export default MobileTabs
