import { BaseService, CommonArgs } from './base.service'
import {
  LensClient,
  PaginatedResult,
  ProfileFragment,
  development,
  production,
  BroadcastRequest,
  LimitType,
  ExplorePublicationsOrderByType,
  AuthChallengeFragment,
  RelaySuccessFragment,
  AnyPublicationFragment,
  ArticleMetadataV3Fragment,
  AudioMetadataV3Fragment,
  CheckingInMetadataV3Fragment,
  EmbedMetadataV3Fragment,
  EventMetadataV3Fragment,
  ImageMetadataV3Fragment,
  LinkMetadataV3Fragment,
  LiveStreamMetadataV3Fragment,
  MintMetadataV3Fragment,
  SpaceMetadataV3Fragment,
  StoryMetadataV3Fragment,
  TextOnlyMetadataV3Fragment,
  ThreeDMetadataV3Fragment,
  TransactionMetadataV3Fragment,
  VideoMetadataV3Fragment,
  NftImageFragment,
  ProfilePictureSetFragment,
  PublicationReactionType,
  PublicationType,
  PublicationOperationsFragment,
  ExplorePublicationType,
  PublicationMetadataMainFocusType,
  Environment
} from '@lens-protocol/client'
import { image, textOnly, video } from '@lens-protocol/metadata'
import { Image, MimeType } from 'ymca/models/image.model'
import {
  getFileFromBase64Embed,
  DEFAULT_AVATAR_IMAGE,
  DEFAULT_BACKGROUND_IMAGE
} from './image.service'
import { IPFSUploadResult } from 'ymca/dtos/ipfs.dto'
import { v4 as uuidv4 } from 'uuid'
import { Meme, Post, PostContentType, PostType } from 'ymca/models/post.model'
import { ReactionType } from 'ymca/models/reaction.model'
import {
  PaginatedGenerator,
  runPaginatedGeneratorFilter,
  runPaginatedGeneratorTransformer
} from 'ymca/dtos/common.dto'
import { UserProfilePageModel } from 'ymca/models/user.model'
import { Comment } from 'ymca/models/comment.model'
import { SelfService } from './self.service'
import { LensTombstoneService } from './lens-tombstone.service'
import config from 'utils/config'

export type PublicationMetadata =
  | ArticleMetadataV3Fragment
  | AudioMetadataV3Fragment
  | CheckingInMetadataV3Fragment
  | EmbedMetadataV3Fragment
  | EventMetadataV3Fragment
  | ImageMetadataV3Fragment
  | LinkMetadataV3Fragment
  | LiveStreamMetadataV3Fragment
  | MintMetadataV3Fragment
  | SpaceMetadataV3Fragment
  | StoryMetadataV3Fragment
  | TextOnlyMetadataV3Fragment
  | ThreeDMetadataV3Fragment
  | TransactionMetadataV3Fragment
  | VideoMetadataV3Fragment

/**
 * This interface is used to sign messages for the Lens SDK.
 * It is an abstraction over the web3 provider, and can be used
 * to sign messages using metamask, or a hardware wallet, or
 * wallet connect, or any other provider.
 */
export interface LensFriendlyWallet {
  signMessage: (message: string) => Promise<string>
}

export class LensService extends BaseService {
  protected selfService: SelfService
  protected tombstoneService: LensTombstoneService
  protected appId: string
  public lensProxy: Environment
  public lensEnvironment: Environment
  public lens: LensClient
  public wallet: LensFriendlyWallet | undefined
  public readonly lensChainId: number

  public constructor(
    common: CommonArgs,
    selfService: SelfService,
    tombstoneService: LensTombstoneService
  ) {
    super(common)
    this.selfService = selfService
    this.tombstoneService = tombstoneService
    this.appId = common.config.lensAppId
    this.lensProxy = new Environment(
      development.name,
      common.config.lensProxy,
      development.gated
    )
    const lensEnvironmentName = common.config.lensEnvironment
    console.log(`Lens environment: ${lensEnvironmentName}`)
    if (lensEnvironmentName === 'production') {
      this.lensEnvironment = production
    } else if (lensEnvironmentName === 'proxy') {
      this.lensEnvironment = this.lensProxy
    } else {
      this.lensEnvironment = development
    }
    this.lens = new LensClient({
      environment: this.lensEnvironment,
      storage: this.common.localStorage
    })
    this.lensChainId =
      common.config.lensEnvironment === 'production' ? 137 : 80001
  }

  protected profileId: string = ''

  public newUUID(): string {
    return uuidv4()
  }

  public setLensWallet(wallet: LensFriendlyWallet): void {
    this.wallet = wallet
  }

  public async getProfileIdForAddress(address: string): Promise<string> {
    const profile = await this.lens.profile.fetchDefault({
      for: address
    })
    if (profile == null) {
      return ''
    } else {
      return profile.id
    }
  }

  public async publishPostToBackend(
    postId: string,
    accessToken: string
  ): Promise<any> {
    const body = { postId, accessToken }
    const res = await this.common.jsonFetcher.fetchJSON<any>(
      'PUT',
      '/api/lens',
      undefined,
      body
    )
    return res
  }

  public async requestChallenge(
    address: string,
    profileId: string
  ): Promise<AuthChallengeFragment> {
    const challenge = await this.lens.authentication.generateChallenge({
      signedBy: address,
      for: profileId
    })
    return challenge
  }

  public async requestChallengeWithoutProfile(
    address: string
  ): Promise<AuthChallengeFragment> {
    const profileId = await this.getDefaultProfileForWallet(address)
    const challenge = await this.lens.authentication.generateChallenge({
      signedBy: address,
      for: profileId
    })
    return challenge
  }

  public getDataFromJWT(token: string): any {
    // split token into three dot separated parts
    // confirm there ARE three parts
    // decode the middle part
    // return it if it is valid
    // throw errors if encountered
    const parts = token.split('.')
    if (parts.length !== 3) {
      const msg = `Invalid token; expected 3 parts but found ${parts.length}`
      console.error(msg)
      throw new Error(msg)
    }
    const base64Url = parts[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonString = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
    const jsonObject = JSON.parse(jsonString)
    console.debug(`Decoded JWT: ${jsonString}`)
    return jsonObject
  }

  public verifyLensTokenFields(lensAccessToken: string): boolean {
    const tokenData = this.getDataFromJWT(lensAccessToken)
    if (typeof tokenData?.id !== 'string') {
      console.error('Invalid lens access token; id missing')
      return false
    } else {
      console.debug(`Lens access token id: ${tokenData.id}`)
    }
    if (typeof tokenData?.evmAddress !== 'string') {
      console.error('Invalid lens access token; evmAddress missing')
      return false
    } else {
      console.debug(`Lens access token evmAddress: ${tokenData.evmAddress}`)
    }
    return true
  }

  public async verifyLensTokenAuthenticity(
    lensAccessToken: string
  ): Promise<boolean> {
    const isValidToken = await this.lens.authentication.verify(lensAccessToken)
    if (!isValidToken) {
      console.error(`Failed to validate lens access token`)
      return false
    }
    return true
  }

  public async verifyLensAccessToken(
    lensAccessToken: string
  ): Promise<boolean> {
    const hasAllfields = this.verifyLensTokenFields(lensAccessToken)
    if (!hasAllfields) {
      return false
    }
    const isAuthentic = await this.verifyLensTokenAuthenticity(lensAccessToken)
    if (!isAuthentic) {
      return false
    }
    return true
  }

  /**
   *
   * @param id the id of the challenge
   * @param signature a signed version of the challenge's message
   * @returns the lens access token
   */
  public async authenticate(id: string, signature: string): Promise<string> {
    console.debug(
      `Authenticating with Lens id ${id} and signature ${signature}`
    )
    await this.lens.authentication.authenticate({ id, signature })
    const isAuthenticated = await this.lens.authentication.isAuthenticated()
    if (!isAuthenticated) {
      throw new Error('Failed to authenticate')
    }
    const lensAccessToken = await this.getAccessToken()
    const isValidToken = await this.verifyLensAccessToken(lensAccessToken)
    if (!isValidToken) {
      throw new Error('Failed to validate lens access token')
    }
    return lensAccessToken
  }

  public async authenticateWithSigner(
    signer: LensFriendlyWallet,
    address: string,
    profileId?: string
  ): Promise<string> {
    this.setLensWallet(signer)
    if (profileId == null) {
      console.debug(`No profileId provided; fetching one for ${address}`)
      profileId = await this.getDefaultProfileForWallet(address)
    }
    console.debug(
      `Authenticating address ${address} with Lens profileId ${profileId} and requesting a challenge`
    )
    const challenge = await this.requestChallenge(address, profileId)
    console.debug(challenge)
    const signature = await signer.signMessage(challenge.text)
    console.debug(`Received signature: ${signature}`)
    const lensCredentials = await this.authenticate(challenge.id, signature)
    console.debug(`Received lens credentials: ${lensCredentials}`)
    return lensCredentials
  }

  public async isAuthenticated(): Promise<boolean> {
    const res = await this.lens.authentication.isAuthenticated()
    return res
  }

  public async setSelfLensProfileManager(
    enable?: boolean
  ): Promise<any | undefined> {
    // Check if lens instance is authenticated
    const res = await this.isAuthenticated()
    if (!res) {
      return
    }
    const isEnabled = await this.isSelfLensProfileManagerEnabled()
    if (isEnabled === enable) {
      return null
    }
    const typedDataResult =
      await this.lens.profile.createChangeProfileManagersTypedData({
        approveSignless: enable
      })
    if (typedDataResult.isFailure()) {
      const err = typedDataResult.unwrap()
      console.error(err)
      return false
    }
    // typedDataResult is a Result object
    const data = typedDataResult.unwrap()
    console.debug(data)
    return data
  }

  public async broadcastResult(
    request: BroadcastRequest
  ): Promise<RelaySuccessFragment | null> {
    const broadcastResult = await this.lens.transaction.broadcastOnchain(
      request
    )
    const unwrappedResult = broadcastResult.unwrap()
    if (unwrappedResult.__typename === 'RelayError') {
      const err = unwrappedResult
      console.error(err)
      return null
    } else {
      console.debug('Broadcasted result: ', unwrappedResult)
      return unwrappedResult
    }
  }

  /**
   * This method obtains the lens access token from the SDK
   * @returns The access token, or an empty string if not authenticated.
   */
  public async getAccessToken(): Promise<string> {
    const token = await this.lens.authentication.getAccessToken()
    if (token.isFailure()) {
      const errToken = token.unwrap()
      console.error(errToken)
      return ''
    } else if (token.isSuccess()) {
      const accessToken = token.unwrap()
      return accessToken
    } else {
      console.error('Unknown error fetching access token')
      return ''
    }
  }

  public async getSelfEthAddress(): Promise<string> {
    const token = await this.getAccessToken()
    const tokenFields = this.getDataFromJWT(token)
    const ethAddress = tokenFields.evmAddress ?? ''
    return ethAddress
  }

  public async getDefaultProfileForWallet(ethAddress: string): Promise<string> {
    const defaultProfile = await this.lens.profile.fetchDefault({
      for: ethAddress
    })
    if (defaultProfile != null) {
      return defaultProfile.id
    } else {
      return ''
    }
  }

  /**
   *
   * @param fallbackEthAddress if user is not logged in, pass an address instead
   * @returns the lens profile Id of the current user, or empty string
   */
  public async getSelfProfileId(): Promise<string> {
    if (this.profileId.length > 0) {
      return this.profileId
    }
    const accessToken = await this.getAccessToken()
    const tokenFields = this.getDataFromJWT(accessToken)
    this.profileId = tokenFields.id ?? ''
    return this.profileId
  }

  public async getUserProfile(
    profileId: string
  ): Promise<ProfileFragment | null> {
    const lensRes = await this.lens.profile.fetch({ forProfileId: profileId })
    return lensRes
  }

  public async isSelfLensProfileManagerEnabled(): Promise<boolean> {
    const selfProfileId = await this.getSelfProfileId()
    const profile = await this.getUserProfile(selfProfileId)
    const isLensProfileManagerEnabled = profile?.signless ?? false
    console.debug(
      `For profile ${selfProfileId}, signless is ${
        isLensProfileManagerEnabled ? 'enabled' : 'disabled'
      }`
    )
    return isLensProfileManagerEnabled
  }

  public async uploadToIPFS(data: File | object): Promise<string> {
    if (data == null) {
      return ''
    }
    const mimetype =
      'File' in globalThis && data instanceof File
        ? data.type
        : 'application/json'
    const res = await this.common.jsonFetcher.fetchJSON<IPFSUploadResult>(
      'POST',
      '/api/ipfs',
      undefined,
      data,
      mimetype as MimeType
    )
    if (!res.isSuccess) {
      console.error(res)
      throw new Error('Failed to upload to IPFS')
    }
    const ipfsHash = res.data.uri
    console.debug('Uploaded to IPFS: ', ipfsHash)
    return ipfsHash
  }

  // Large View Adaptor Code Ahead
  protected lensReactionToReaction(
    ops: PublicationOperationsFragment
  ): ReactionType | '' {
    if (ops.hasUpvoted) {
      return 'Like'
    } else if (ops.hasDownvoted) {
      return 'Dislike'
    } else {
      return ''
    }
  }

  protected buildImageFromURL(url: string, isVerified = false): Image {
    if (url.startsWith('ipfs://')) {
      url = 'https://ipfs.io/ipfs/' + url.slice(7)
    }
    return {
      id: '',
      formatImage: 'jpeg',
      jpegUrl: url,
      urlOriginal: url,
      urlOptimised1000x1000: url,
      urlOptimised500x500: url,
      urlOptimised200x200: url,
      isVerified,
      typeImage: 'meme',
      urlImage: url
    }
  }

  protected getImageFromMediaSetFragment(
    mediaSet: ProfilePictureSetFragment | NftImageFragment | null | undefined,
    backup: string = DEFAULT_BACKGROUND_IMAGE
  ): Image {
    let url = backup
    if (mediaSet?.__typename === 'NftImage') {
      // OMG! They actually support optimized and raw images!
      url = mediaSet.image.optimized?.uri ?? mediaSet.image.raw.uri
    } else if (mediaSet?.__typename === 'ImageSet') {
      url = mediaSet.optimized?.uri ?? mediaSet.raw.uri
    } else {
      url = backup
    }
    return this.buildImageFromURL(url)
  }

  protected userFragmentToUser(profile: ProfileFragment): UserProfilePageModel {
    const background = this.buildImageFromURL(
      profile.metadata?.coverPicture?.optimized?.uri ??
        profile.metadata?.coverPicture?.raw.uri ??
        DEFAULT_BACKGROUND_IMAGE
    )
    const avatarData = profile.metadata?.picture
    let avatar = this.buildImageFromURL(DEFAULT_AVATAR_IMAGE)
    if (avatarData?.__typename === 'ImageSet') {
      const url = avatarData.optimized?.uri ?? avatarData.raw.uri
      avatar = this.buildImageFromURL(url)
    } else if (avatarData?.__typename === 'NftImage') {
      const url = avatarData.image.optimized?.uri ?? avatarData.image.raw.uri
      avatar = this.buildImageFromURL(url)
    }
    return {
      id: `_${profile.id}`,
      username: profile.handle?.fullHandle ?? '',
      displayName: profile.handle?.fullHandle ?? '',
      avatar,
      background,
      // @ts-ignore
      isUserFollowed: profile.isFollowedByMe, // FIXME not present in types
      isVerified: true,
      biography: profile.metadata?.bio ?? 'A Lens user',
      country: 'Lensverse',
      nCompetitionPosts: 0,
      createdAt: new Date(0),
      updatedAt: new Date(0),
      urlWebsite: 'https://youmeme.com',
      followingList: [],
      hobbies: profile.interests ?? [],
      nPosts: profile.stats.posts,
      role: 'user',
      followers: profile.stats.followers,
      ethAddress: profile.ownedBy.address,
      rewardEthAddress: profile.ownedBy.address,
      email: 'contact@youmeme.com',
      activeSessions: [],
      categoryInterestedIn: [],
      ymdBalance: 0,
      referredBy: '',
      isAmbassador: false
    }
  }

  protected metadataToContentType(
    metadata:
      | ArticleMetadataV3Fragment
      | AudioMetadataV3Fragment
      | CheckingInMetadataV3Fragment
      | EmbedMetadataV3Fragment
      | EventMetadataV3Fragment
      | ImageMetadataV3Fragment
      | LinkMetadataV3Fragment
      | LiveStreamMetadataV3Fragment
      | MintMetadataV3Fragment
      | SpaceMetadataV3Fragment
      | StoryMetadataV3Fragment
      | TextOnlyMetadataV3Fragment
      | ThreeDMetadataV3Fragment
      | TransactionMetadataV3Fragment
      | VideoMetadataV3Fragment
  ): PostContentType {
    const typename = metadata.__typename
    switch (typename) {
      case 'ArticleMetadataV3':
        return 'article'
      case 'AudioMetadataV3':
        return 'article'
      case 'CheckingInMetadataV3':
        return 'article'
      case 'EmbedMetadataV3':
        return 'article'
      case 'EventMetadataV3':
        return 'article'
      case 'ImageMetadataV3':
        return 'meme'
      case 'LinkMetadataV3':
        return 'article'
      case 'LiveStreamMetadataV3':
        return 'article'
      case 'MintMetadataV3':
        return 'article'
      case 'SpaceMetadataV3':
        return 'article'
      case 'StoryMetadataV3':
        return 'article'
      case 'TextOnlyMetadataV3':
        return 'article'
      case 'ThreeDMetadataV3':
        return 'article'
      case 'TransactionMetadataV3':
        return 'article'
      case 'VideoMetadataV3':
        return 'video'
      default:
        return 'meme'
    }
  }

  protected isPublicationAllowed(publication: AnyPublicationFragment): boolean {
    const postId = `_${publication.id}`
    const userId = `_${publication.by.id}`
    const isUserBanned = this.common.bannedLensUsers.has(userId)
    const isPostBanned = this.common.bannedLensPosts.has(postId)
    const notDeleted = !isUserBanned && !isPostBanned
    return notDeleted
  }

  protected publicationToPost(publication: AnyPublicationFragment): Post {
    if (publication.__typename !== 'Post') {
      throw new Error(
        `Pubication isn't a post; it is a ${publication.__typename}`
      )
    }

    const metadataType = publication.metadata.__typename
    const contentType = this.metadataToContentType(publication.metadata)
    let title = 'Post on Lens'
    let description = ''
    let renderedImageUrl = DEFAULT_BACKGROUND_IMAGE
    let thumbnailImageUrl = DEFAULT_BACKGROUND_IMAGE
    if (metadataType === 'ImageMetadataV3') {
      renderedImageUrl =
        publication.metadata.asset.image.optimized?.uri ??
        publication.metadata.asset.image.raw.uri
      thumbnailImageUrl = renderedImageUrl
      title = publication.metadata.title ?? 'image on lens'
      description = publication.metadata.content ?? 'an image on lens'
    } else if (metadataType === 'VideoMetadataV3') {
      renderedImageUrl =
        publication.metadata.asset.video.optimized?.uri ??
        publication.metadata.asset.video.raw.uri
      thumbnailImageUrl =
        publication.metadata.asset.cover?.optimized?.uri ??
        publication.metadata.asset.cover?.raw.uri ??
        DEFAULT_BACKGROUND_IMAGE
      title = publication.metadata.title ?? 'video on lens'
      description = publication.metadata.content ?? 'a video on lens'
    } else if (
      metadataType === 'TextOnlyMetadataV3' ||
      metadataType === 'ArticleMetadataV3'
    ) {
      title = 'Article on Lens'
      description = publication.metadata.content ?? 'an article on lens'
    }
    const meme: Meme = {
      renderedImage: this.buildImageFromURL(renderedImageUrl),
      thumbnailImage: this.buildImageFromURL(thumbnailImageUrl)
    }
    const post: Post = {
      id: `_${publication.id}`,
      fkUserId: `_${publication.by.id}`,
      title,
      description,
      isSponsored: false,
      isPublished: true,
      nLikes: publication.stats.upvoteReactions,
      nComments: publication.stats.comments,
      nShares: publication.stats.mirrors,
      nDislikes: 0,
      isOptimized: false,
      createdAt: publication.createdAt,
      updatedAt: publication.createdAt,
      type: 'public',
      contentType,
      tags: publication.metadata.tags ?? ['memes'],
      userReactionState: this.lensReactionToReaction(publication.operations),
      seo: {
        title,
        description,
        image: thumbnailImageUrl,
        url: `${this.common.config.deploymentUrl}/meme/_${publication.id}`
      },
      meme,
      isBookmarkedByUser: publication.operations.hasBookmarked,
      isLens: true,
      isDeletableByUser:
        publication.by.id === this.profileId ||
        this.selfService.getIsModerator(''),
      user: this.userFragmentToUser(publication.by)
    }
    if (post.description.trim() === post.title.trim()) {
      post.description = ''
    }
    return post
  }

  protected publicationToComment(input: AnyPublicationFragment): Comment {
    if (input.__typename !== 'Comment') {
      throw new Error('Expected Comment but found ' + input.__typename)
    }
    const comment = input
    const fromUser = this.userFragmentToUser(comment.by)
    let content = 'lol'
    if (input.metadata.__typename === 'TextOnlyMetadataV3') {
      content = input.metadata.content
    } else if (input.metadata.__typename === 'ArticleMetadataV3') {
      content = input.metadata.content
    }
    return {
      id: `_${comment.id}`,
      fkUserId: fromUser.id,
      user: fromUser,
      comment: content,
      isUserDeletable: input.by.id === this.profileId,
      fkPostId: `_${comment.commentOn.id}`,
      createdAt: new Date(comment.createdAt),
      updatedAt: new Date(comment.createdAt)
    }
  }
  // Done With Large View Adaptor Code

  public async *paginatedResultToPaginatedGenerator<T>(
    input: PaginatedResult<T> | null
  ): PaginatedGenerator<T> {
    let paginatedResult = input
    while (paginatedResult !== null) {
      yield {
        count: 99999999, // paginatedResult.pageInfo.totalCount ?? 0,
        data: paginatedResult.items ?? []
      }
      paginatedResult = await paginatedResult.next()
    }
  }

  public async getLatestPostsInLensFormatForTesting(): Promise<
    PaginatedResult<AnyPublicationFragment>
  > {
    const res = await this.lens.explore.publications({
      orderBy: ExplorePublicationsOrderByType.Latest, // 'LATEST'; can also be 'TOP_COMMENTED'
      where: {
        publicationTypes: [ExplorePublicationType.Post], // 'POST'
        metadata: {
          publishedOn: [this.appId]
        }
      },
      limit: LimitType.Ten
    })
    return res
  }

  public async getPostsWithFilters(
    hasAllTags: string[] = [],
    mainContentFocus: PublicationMetadataMainFocusType[] = [],
    fromProfileIds: string[] = [],
    orderBy: ExplorePublicationsOrderByType = ExplorePublicationsOrderByType.Latest,
    limit: LimitType = LimitType.Ten
  ): Promise<PaginatedGenerator<Post>> {
    let res: PaginatedResult<AnyPublicationFragment>
    if (fromProfileIds.length > 0) {
      res = await this.lens.publication.fetchAll({
        where: {
          from: fromProfileIds,
          publicationTypes: [PublicationType.Post], // 'POST'
          metadata: {
            publishedOn: [this.appId],
            ...(hasAllTags.length > 0 && { tags: { all: hasAllTags } }),
            ...(mainContentFocus.length > 0 && mainContentFocus)
          }
        },
        limit
      })
    } else {
      res = await this.lens.explore.publications({
        where: {
          publicationTypes: [ExplorePublicationType.Post], // 'POST'
          metadata: {
            publishedOn: [this.appId],
            ...(hasAllTags.length > 0 && { tags: { all: hasAllTags } }),
            ...(mainContentFocus.length > 0 && mainContentFocus)
          }
        },
        orderBy, // 'LATEST'; can also be 'TOP_COMMENTED'
        limit
      })
    }
    const lensGenerator = this.paginatedResultToPaginatedGenerator(res)
    const filteredLensGenerator = runPaginatedGeneratorFilter(
      lensGenerator,
      this.isPublicationAllowed.bind(this)
    )
    const postGenerator = runPaginatedGeneratorTransformer<
      AnyPublicationFragment,
      Post
    >(filteredLensGenerator, this.publicationToPost.bind(this))
    return postGenerator
  }

  public async getLatestPosts(): Promise<PaginatedGenerator<Post>> {
    const res = await this.getPostsWithFilters(
      [],
      [],
      [],
      ExplorePublicationsOrderByType.Latest,
      LimitType.Ten
    )
    return res
  }

  public async getPopularPosts(): Promise<PaginatedGenerator<Post>> {
    const res = await this.getPostsWithFilters(
      [],
      [],
      [],
      ExplorePublicationsOrderByType.TopCommented,
      LimitType.Ten
    )
    return res
  }

  public async getUserPosts(
    profileId: string
  ): Promise<PaginatedGenerator<Post>> {
    const res = await this.getPostsWithFilters(
      [],
      [],
      [profileId],
      ExplorePublicationsOrderByType.Latest,
      LimitType.Ten
    )
    return res
  }

  public async getSelfPosts(): Promise<PaginatedGenerator<Post>> {
    const profileId = await this.getSelfProfileId()
    const res = await this.getUserPosts(profileId)
    return res
  }

  public async getCommentsForPost(
    postId: string,
    limit: LimitType = LimitType.Ten
  ): Promise<PaginatedGenerator<Comment>> {
    const res = await this.lens.publication.fetchAll({
      limit,
      where: {
        publicationTypes: [PublicationType.Comment], // 'COMMENT'
        commentOn: {
          id: postId
        }
      }
    })
    const lensGenerator = this.paginatedResultToPaginatedGenerator(res)
    const commentGenerator = runPaginatedGeneratorTransformer<
      AnyPublicationFragment,
      Comment
    >(lensGenerator, this.publicationToComment.bind(this))
    return commentGenerator
  }

  public async getPostById(postId: string): Promise<Post | null> {
    const lensPost = await this.lens.publication.fetch({
      forId: postId
    })
    if (lensPost == null) {
      return null
    }
    if (lensPost.__typename !== 'Post') {
      console.error('Expected Post but found ' + lensPost.__typename)
      console.error(lensPost)
      return null
    }
    const post = this.publicationToPost(lensPost)
    return post
  }

  public getVideopostMetadata(
    id: string,
    title: string,
    description: string,
    tags: string[],
    mediaURI: string = '',
    coverURI: string = '',
    coverMimeType: MimeType | '' = '',
    postType: PostType
  ): VideoMetadataV3Fragment {
    const res = video({
      title,
      id,
      tags,
      content: description,
      locale: 'en-US',
      attributes: [
        {
          key: 'youmemePostContentType',
          value: 'video',
          // @ts-ignore unfortunately, the type isn't exported
          type: 'String'
        },
        {
          key: 'youmemePostType',
          value: postType,
          // @ts-ignore unfortunately, the type isn't exported
          type: 'String'
        }
      ],
      marketplace: {
        external_url: `${config.deploymentUrl}/meme/_${id}`
      },
      video: {
        item: mediaURI,
        //@ts-ignore
        type: 'video/mp4',
        cover: coverURI,
        coverType: coverMimeType,
        altTag: description.length > 0 ? description : title
      },
      appId: this.appId
    })
    return res as unknown as VideoMetadataV3Fragment
  }

  public getImagePostMetadata(
    id: string,
    title: string,
    description: string,
    tags: string[],
    mediaURI: string = '',
    mediaMimeType: MimeType,
    postType: PostType
  ): ImageMetadataV3Fragment {
    const res = image({
      title,
      id,
      tags,
      content: description,
      locale: 'en-US',
      attributes: [
        {
          key: 'youmemePostContentType',
          value: 'meme',
          // @ts-ignore unfortunately, the type isn't exported
          type: 'String'
        },
        {
          key: 'youmemePostType',
          value: postType,
          // @ts-ignore unfortunately, the type isn't exported
          type: 'String'
        }
      ],
      marketplace: {
        external_url: `${config.deploymentUrl}/meme/_${id}`
      },
      image: {
        item: mediaURI,
        //@ts-ignore
        type: mediaMimeType,
        altTag: description.length > 0 ? description : title
      },
      appId: this.appId
    })
    return res as unknown as ImageMetadataV3Fragment
  }

  public getCommentMetadata(
    commentId: string,
    publicationId: string,
    comment: string
  ) {
    const res = textOnly({
      id: commentId,
      tags: [],
      content: comment,
      locale: 'en-US',
      attributes: [
        {
          key: 'commentOn',
          value: publicationId,
          // @ts-ignore unfortunately, the type isn't exported
          type: 'String'
        }
      ],
      marketplace: {
        external_url: `${config.deploymentUrl}/meme/_${publicationId}` // TODO add intra-page link to commentId so we can scroll to it
      },
      appId: this.appId
    })
    return res as unknown as TextOnlyMetadataV3Fragment
  }

  public async createPostWithContentURI(contentURI: string): Promise<any> {
    // Fetch profile Id
    const profileId = await this.getSelfProfileId()

    console.debug(
      `Creating post with profileId: ${profileId} and contentURI: ${contentURI}`
    )

    // create a post via lens profile manager
    // you need to have the lens profile manager enabled for your profile
    const viaLensProfileManagerResult =
      await this.lens.publication.createOnchainPostTypedData({ contentURI })

    console.debug(viaLensProfileManagerResult)
    if (viaLensProfileManagerResult.isFailure()) {
      console.error(viaLensProfileManagerResult.error)
      throw new Error('Failed to create post')
    } else {
      const resultFragment = viaLensProfileManagerResult.unwrap()
      return resultFragment.id
    }
  }

  public async createPostWithIPFSImages(
    id: string,
    postType: PostType,
    name: string,
    description: string,
    content: string,
    tags: string[],
    mainMediaURI: string,
    mainMediaMimeType: MimeType,
    coverMediaURI: string,
    coverMediaMimeType: MimeType
  ): Promise<string> {
    // TODO add guards for all these params

    // Create metadata
    const metadata =
      mainMediaMimeType === 'video/mp4'
        ? this.getVideopostMetadata(
            id,
            name,
            description,
            tags,
            mainMediaURI,
            coverMediaURI,
            coverMediaMimeType,
            postType
          )
        : this.getImagePostMetadata(
            id,
            name,
            description,
            tags,
            mainMediaURI,
            mainMediaMimeType,
            postType
          )
    // validate metadata
    const jsonMetadata = JSON.stringify(metadata)
    const isMetadataValid = await this.lens.publication.validateMetadata({
      json: jsonMetadata
    })
    if (!isMetadataValid.valid) {
      console.error(isMetadataValid.reason)
      console.error(metadata)
      throw new Error('Failed to validate metadata')
    }
    // Upload Metadata to IPFS
    const contentURI = await this.uploadToIPFS(metadata)

    const res = await this.createPostWithContentURI(contentURI)
    return res
  }

  /**
   * Upload a base64 encoded media to IPFS. Handles null/empty inputs
   * @param mediaInput the base64 encoded media
   * @returns url of the media on IPFS alongside the mimetype
   */
  public async uploadB64ToIPFS(
    mediaInput?: string
  ): Promise<{ url: string; mimetype: MimeType } | null> {
    if (
      mediaInput == null ||
      (typeof mediaInput === 'string' && mediaInput.length === 0)
    ) {
      return null
    }
    const media = getFileFromBase64Embed(mediaInput)
    const mimetype = media.type as MimeType
    const url = await this.uploadToIPFS(media)
    return {
      url,
      mimetype
    }
  }

  public async createPostWithBase64Data(
    postType: PostType,
    name: string,
    description: string,
    content: string,
    tags: string[],
    mainMediaB64: string,
    coverMediaB64?: string
  ): Promise<string> {
    const mainMedia = await this.uploadB64ToIPFS(mainMediaB64)
    if (mainMedia == null) {
      console.error(`No main media provided; Received "${mainMediaB64}"`)
      throw new Error('No main media provided')
    }
    const coverMedia = await this.uploadB64ToIPFS(coverMediaB64)
    const postId = this.newUUID()
    const txId = await this.createPostWithIPFSImages(
      postId,
      postType,
      name,
      description,
      content,
      tags,
      mainMedia.url,
      mainMedia.mimetype,
      coverMedia?.url ?? '',
      coverMedia?.mimetype ?? 'application/octet-stream'
    )
    return txId
  }

  public async createCommentWithContentURI(
    publicationId: string,
    contentURI: string
  ): Promise<boolean> {
    const profileId = await this.getSelfProfileId()

    console.debug(
      `Creating comment on publicationId: ${publicationId} with profileId: ${profileId} having contentURI: ${contentURI}`
    )

    const result = await this.lens.publication.createOnchainCommentTypedData({
      commentOn: publicationId,
      contentURI
    })
    const details = result.unwrap()
    console.debug(details)

    if (result.isFailure()) {
      console.error(result.error)
      throw new Error('Failed to create comment')
    } else {
      const resultFragment = result.unwrap()
      console.debug(resultFragment)
      return true
    }
  }

  public async createComment(
    publicationId: string,
    comment: string
  ): Promise<boolean> {
    const commentId = this.newUUID()
    const metadata = this.getCommentMetadata(commentId, publicationId, comment)
    await this.lens.publication.validateMetadata({
      json: JSON.stringify(metadata)
    })
    const contentURI = await this.uploadToIPFS(metadata)
    const txId = await this.createCommentWithContentURI(
      publicationId,
      contentURI
    )
    return txId
  }

  public async hidePostOrComment(publicationId: string): Promise<boolean> {
    const res = await this.lens.publication.hide({
      for: publicationId
    })
    const isFailure = res.isFailure()
    if (isFailure) {
      const err = res.error
      console.error(`Failed to hide post or comment: ${publicationId};`, err)
      return false
    }
    return true
  }

  public async createReaction(
    publicationId: string,
    upVote = true
  ): Promise<boolean> {
    try {
      const profileId = await this.getSelfProfileId()
      console.debug(
        `Creating reaction on publicationId: ${publicationId} with ${
          upVote ? 'like' : 'dislike'
        } for profileId ${profileId}`
      )
      const res = await this.lens.publication.reactions.add({
        for: publicationId,
        reaction: upVote
          ? PublicationReactionType.Upvote
          : PublicationReactionType.Downvote
      })
      const isFailure = res.isFailure()
      if (isFailure) {
        const err = res.error
        console.error(
          `Failed to create reaction on publicationId: ${publicationId} with profileId: ${profileId} having reaction: ${
            upVote ? 'Upvote' : 'Downvote'
          }`,
          err
        )
        return false
      }
      return true
    } catch (error) {
      return false
    }
  }

  public async deleteReaction(
    publicationId: string,
    upVote = true
  ): Promise<boolean> {
    try {
      const profileId = await this.getSelfProfileId()
      const res = await this.lens.publication.reactions.remove({
        for: publicationId,
        reaction: upVote
          ? PublicationReactionType.Upvote
          : PublicationReactionType.Downvote
      })
      const isFailure = res.isFailure()
      if (isFailure) {
        const err = res.error
        console.error(
          `Failed to delete reaction on publicationId: ${publicationId} with profileId: ${profileId} having reaction: ${
            upVote ? 'Upvote' : 'Downvote'
          }`,
          err
        )
        return false
      }
      return true
    } catch (error) {
      return false
    }
  }
}
