import { GetBookmarksDto } from 'ymca/dtos/bookmark.dto'
import { getEmptyPaginatedResponse, PaginatedResponse } from 'ymca/dtos/common.dto'
import { BaseService, CommonArgs } from './base.service'
import { PostIdDto } from '../dtos/post.dto'
import { Post } from 'ymca/models/post.model'
import { SelfService } from './self.service'

export const BOOKMARK_CACHE_TTL_SECONDS = 60 // 1 minute
export const BOOKMARK_PAGE_SIZE = 100 // you can set a smaller number to test!

export class BookmarkService extends BaseService {
  protected selfService: SelfService

  public constructor (
    common: CommonArgs,
    selfService: SelfService
  ) {
    super(common)
    this.selfService = selfService
  }

  public async getBookmarksByDto (dto: GetBookmarksDto): Promise<PaginatedResponse<string>> {
    const res = await this.common.jsonFetcher.fetchJSON<PaginatedResponse<string>>('GET', '/api/bookmark', dto)
    if (!res.isSuccess) {
      const msg = `Failed to get bookmarks: ${dto.toURLSearchParams().toString()}`
      console.error(msg)
      throw new Error(msg)
    }
    return res.data
  }

  protected getBookmarkCacheKey (userId: string, postId: string): string {
    return `bookmark:${userId}:${postId}`
  }

  public getBookmarkKeyFromSelf (postId: string): string {
    return this.getBookmarkCacheKey(this.selfService.getId(), postId)
  }

  protected async cacheBookmark (userId: string, postId: string, isBookmarked: boolean, skipCache: boolean): Promise<void> {
    const key = this.getBookmarkCacheKey(userId, postId)
    await this.common.kvStore.set(key, isBookmarked, BOOKMARK_CACHE_TTL_SECONDS, skipCache)
    this.common.pubsub.publish(key, isBookmarked)
  }

  protected async getBookmarkFromCache (userId: string, postId: string, skipCache: boolean): Promise<boolean | null> {
    const key = this.getBookmarkCacheKey(userId, postId)
    const isBookmarked = await this.common.kvStore.get<boolean>(key, skipCache)
    return isBookmarked
  }

  protected async getBookmarksForPostIdsFromAPI (userId: string, postIds: string[]): Promise<Map<string, boolean>> {
    const res = new Map<string, boolean>()
    if (postIds.length === 0 || userId === '') {
      return res
    }
    for (let i = 0; i < postIds.length; i += BOOKMARK_PAGE_SIZE) {
      const page = postIds.slice(i, i + BOOKMARK_PAGE_SIZE)
      const dto = new GetBookmarksDto()
      dto.postIds = page
      dto.limit = BOOKMARK_PAGE_SIZE
      dto.offset = i
      dto.userId = userId
      const bookmarks = await this.getBookmarksByDto(dto)
      for (const bookmark of bookmarks.data) {
        res.set(bookmark, true)
      }
    }
    for (const postId of postIds) {
      if (!res.has(postId)) {
        res.set(postId, false)
      }
    }
    for (const postId of postIds) {
      await this.cacheBookmark(userId, postId, res.get(postId) ?? false, true)
    }
    return res
  }

  public async getBookmarksForPostIds (userId: string, postIds: string[], skipCache = false): Promise<Map<string, boolean>> {
    const res = new Map<string, boolean>()
    if (postIds.length === 0 || userId === '') {
      return res
    }
    for (const postId of postIds) {
      const cached = await this.getBookmarkFromCache(userId, postId, skipCache)
      if (cached !== null) {
        res.set(postId, cached)
      }
    }
    const uncachedPostIds = postIds.filter((postId) => !res.has(postId))
    if (uncachedPostIds.length > 0) {
      const uncachedBookmarks = await this.getBookmarksForPostIdsFromAPI(userId, uncachedPostIds)
      for (const postId of uncachedPostIds) {
        res.set(postId, uncachedBookmarks.get(postId) ?? false)
      }
    }
    return res
  }

  public async getBookmarksByUserId (
    userId: string,
    limit: number = 10,
    offset: number = 0
  ): Promise<PaginatedResponse<string>> {
    if (userId === '' || limit <= 0 || offset < 0 || userId === null || userId === undefined) {
      return getEmptyPaginatedResponse<string>()
    }
    const dto = new GetBookmarksDto()
    dto.userId = userId
    dto.limit = limit
    dto.offset = offset
    const res = await this.getBookmarksByDto(dto)
    return res
  }

  public async createBookmark (post: Post): Promise<boolean> {
    if (post.id.startsWith('_')) {
      // Do not bookmark lens posts
      return false
    }
    const dto = new PostIdDto()
    dto.postId = post.id
    const res = await this.common.jsonFetcher.fetchJSON('POST', '/api/bookmark', dto)
    if (res.isSuccess) {
      post.isBookmarkedByUser = true
      this.common.pubsub.publish(post.id, post)
    }
    return res.isSuccess
  }

  public async deleteBookmark (post: Post): Promise<boolean> {
    if (post.id.startsWith('_')) {
      // Do not bookmark lens posts
      return false
    }
    const dto = new PostIdDto()
    dto.postId = post.id
    const res = await this.common.jsonFetcher.fetchJSON('DELETE', '/api/bookmark', dto)
    if (res.isSuccess) {
      post.isBookmarkedByUser = false
      this.common.pubsub.publish(post.id, post)
    }
    return res.isSuccess
  }
}
