import { Box, Fab } from "@mui/material";
import useCreateCommunityMemeStyles from "./style";
import Icon from "components/atoms/Icons";
import PublishMemeModal from "components/memePublication/PublishMemeModal";
import useModal from "hooks/useModal";
import { useAuth } from "context/auth/authContext";
import { useQueryClient } from "@tanstack/react-query";

// open: boolean;
// imageData?: any;
// flag?: boolean;
// onClose: (value: boolean) => void;
// onParentClose?: () => void;
// setAnimation?: any;
// onSuccess?: Function;
interface CommunityCreateMemeProps {
  communityId: string;
  refetchMemes: Function;
}

const CommunityCreateMeme = ({
  communityId,
  refetchMemes,
}: CommunityCreateMemeProps) => {
  const classes = useCreateCommunityMemeStyles();
  const { isAuthenticated } = useAuth();

  const {
    open: openCreateMemeModal,
    isOpen,
    close: closeCreateMemeModal,
  } = useModal();

  const onCreateSuccess = (data: any) => {
    refetchMemes();
  };

  if (!isAuthenticated) return null;

  return (
    <Box display='flex' justifyContent='flex-end' px='1rem'>
      <Fab
        variant='extended'
        size='medium'
        color='primary'
        aria-label='create-meme'
        className={classes.createMemeBtn}
        onClick={openCreateMemeModal}
      >
        <Icon icon='plus' fontSize='2rem' />
        Meme
      </Fab>
      <PublishMemeModal
        open={isOpen}
        onClose={() => closeCreateMemeModal()}
        onSuccess={onCreateSuccess}
        path='community'
        pathRefId={communityId}
        setAnimation={() => {}}
      />
    </Box>
  );
};

export default CommunityCreateMeme;
