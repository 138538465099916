import { useEffect, useState, lazy } from "react";
import { Alert } from "@material-ui/lab";

import Dialog from "@material-ui/core/Dialog";
import ImageUpload from "meme-generator/imageUpload";
import { AlertDialog } from "components/shared/index";
import { makeStyles } from "@material-ui/core/styles";

import { positions, Provider as AlertProvider, transitions } from "react-alert";
import { useNavigate } from "react-router-dom";
import {
  FileType,
  FileTypeMap,
  PublishMemeModalPath,
  SourceImageType,
} from "constants/meme";
import { BREAKPOINT_TABLET_MEDIUM } from "theme/shared/breakpoint";

const ImageEdit = lazy(() => import("meme-generator/imageEdit/imageedit"));
const ImageGifEdit = lazy(() => import("meme-generator/ImageGifEdit"));

const options: object = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
};

const useStyles = makeStyles({
  paper: {
    padding: "0px 0px",
    borderRadius: 10,
    background: "transparent",
    boxShadow: "none",
    margin: 0,
    overflow: "visible",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",

    [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
      overflow: "initial",
    },
  },
  background: {
    background: "rgba(0,0,0,0.8)",
    height: "100%",
  },
  widthSm: {
    maxWidth: "fit-content",
    backgroundColor: "transparent",
    margin: 0,
  },
  container: {
    position: "relative",
  },
});

export interface PublishMemeModalProp {
  open: boolean;
  imageData?: any;
  flag?: boolean;
  onClose: (value: boolean) => void;
  onParentClose?: () => void;
  path?: PublishMemeModalPath;
  setAnimation?: any;
  tab?: number;
  onSuccess?: Function;
  /**
   * @description this works closely together with the path
   * @example
   * if provided and not community, the value will be used to set the `eventId` for creation action
   * if provided and path=community, then the value will be used to set `communityId`
   * if not provided and path is not then do the default using categories
   */
  pathRefId?: string;
}

export interface ISourceImage {
  data: string;
  imageResolution: {
    width: number;
    height: number;
  };
  type: string;
  imageType: SourceImageType;
  fileType: FileType;
  caption?: string[];
}

const PublishMemeModal = ({
  open,
  flag,
  onClose,
  onParentClose,
  imageData,
  path,
  setAnimation,
  tab = 0,
  onSuccess = () => {},
  ...rest
}: PublishMemeModalProp): JSX.Element => {
  const classes = useStyles();

  const [sourceImg, setSourceImg] = useState<ISourceImage | null>(null);
  const [isAlert, setAlert] = useState(false)
  const [meme, setMeme] = useState(false)
  const navigate = useNavigate()
  const loadInNewEditor =
    sourceImg?.fileType === FileTypeMap.gif ||
    sourceImg?.fileType === FileTypeMap.video

  const handleClose = (
    isEdited: boolean = false,
    isRedirected: boolean = false,
    isPublished: boolean = false
  ): any => {
    if (sourceImg !== null) {
      
      if (isEdited) {
        setAlert(true)
      } else {
        setSourceImg(null)
        if(isPublished) {
          onClose(false)
        }
      }
    } else {
      onClose(false)
      setSourceImg(null)
    }
    if (isRedirected) {
      navigate('/auth')
    }
  }

  const handleAnimation = (e: boolean) => {
    setAnimation(e)
  }

  useEffect(() => {
    if (meme) {
      setSourceImg(null)
      setAlert(false)
      setMeme(false)
    }
  }, [isAlert, meme, onClose])

  if (imageData) {
    return (
      <Dialog
        onClose={() => handleClose}
        open={open}
        fullScreen
        classes={{ paper: classes.widthSm, container: classes.container }}
        BackdropProps={{
          classes: {
            root: classes.background
          }
        }}
      >
        <AlertProvider template={Alert} {...options}>
          {loadInNewEditor ? (
            <ImageGifEdit
              flag={flag}
              sourceImg={imageData}
              handleCloseModal={handleClose}
              parentClose={onParentClose}
              setAnimation={(e: boolean) => handleAnimation(e)}
              onSuccess={onSuccess}
            />
          ) : (
            <ImageEdit
              flag={flag}
              handleCloseModal={handleClose}
              onSuccess={onSuccess}
              parentClose={onParentClose}
              setAnimation={(e: boolean) => handleAnimation(e)}
              sourceImg={imageData}
            />
          )}
          <AlertDialog
            open={isAlert}
            handleAlert={setAlert}
            handleMeme={setMeme}
          />
        </AlertProvider>
      </Dialog>
    )
  }
  return (
    <Dialog
      onClose={() => handleClose}
      open={open}
      fullScreen
      classes={{
        paper: classes.paper,
        container: sourceImg ? classes.container : ''
      }}
      BackdropProps={{
        classes: {
          root: classes.background
        }
      }}
    >
      <AlertProvider template={Alert} {...options}>
        {sourceImg && (
          <>
            {loadInNewEditor ? (
              <ImageGifEdit
                sourceImg={sourceImg}
                onSuccess={onSuccess}
                handleCloseModal={handleClose}
                path={path}
                setAnimation={(e: boolean) => handleAnimation(e)}
                {...rest}
              />
            ) : (
              <ImageEdit
                handleCloseModal={handleClose}
                onSuccess={onSuccess}
                path={path}
                setAnimation={(e: boolean) => handleAnimation(e)}
                sourceImg={sourceImg}
                {...rest}
              />
            )}
          </>
        )}
        <ImageUpload
          navigateToPage={(imageObj: ISourceImage) => {
            setSourceImg(imageObj)
          }}
          uploadType={tab}
          hide={Boolean(sourceImg)}
          onClose={handleClose}
        />

        <AlertDialog
          open={isAlert}
          handleAlert={setAlert}
          handleMeme={setMeme}
        />
      </AlertProvider>
    </Dialog>
  )
};

export default PublishMemeModal;
