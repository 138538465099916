import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "context/auth/authContext";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Typography, styled } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { checkIsPostContentPlayable } from "utils/postContent";
import { Image } from "components/shared";
import { Post } from "ymca/models/post.model";
import { MoreIcon } from "components/shared/SvgIcons";
import PublicationOtherMenu from "components/memePublication/PublicationOtherMenu";
import getTotalReactions from 'utils/getTotalReaction'

const VideoPlayerIcon = styled(Image)({
  position: "absolute",
  width: "2rem",
  height: "2rem",
  left: ".3rem",
  marginTop: ".4rem",
});

export interface UserPostItemProps {
  itemData: Post;
  postUserID: string;
  lensPostUserId?: string;
  setEvent?: any;
  setDeleted?: any;
  isLensPost?: boolean
}

const useStyles = makeStyles({
  card: {
    position: 'relative'
  },
  imgContainer: {
    height: 190,
    borderRadius: 5,
    backgroundSize: 'cover',
    padding: 5,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    position: 'relative'
  },
  btn: {
    height: 32,
    width: 32,
    borderRadius: 7,
    minWidth: 0,
    backgroundColor: 'rgba(0,0,0,.5)',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.5)'
    }
  },
  text1: {
    fontSize: 15,
    fontWeight: 800,
    overflowWrap: 'break-word'
  },
  text2: {
    color: '#808893',
    fontSize: 13
  },
  txtContainer: {
    marginTop: 10,
    marginBottom: 10
  },
  timerBox: {
    position: 'absolute',
    padding: 10,
    background: '#202A41',
    borderRadius: 100,
    right: 8,
    bottom: 8,
    display: 'flex',
    gap: 7,
    fontSize: 13,
    alignItems: 'center'
  },
  timer: {
    color: '#FFFFFF',
    fontSize: 13,
    fontWeight: 700
  }
})

const UserPostItem = ({
  itemData,
  setEvent,
  setDeleted,
  isLensPost,
}: UserPostItemProps): JSX.Element => {
  const classes = useStyles();

  const { isAuthenticated } = useAuth();

  const [openca, setOpenCA] = useState<boolean>(false);

  const handleClickAway = (): void => {
    setOpenCA(false);
  };

  const handleClick = (): void => {
    setOpenCA((prev) => !prev);
  };

  const {meme, contentType} = itemData
  const {thumbnailImage, renderedImage} = meme ?? {}

  const isPlayable = checkIsPostContentPlayable(contentType)
  const imageUrl = isLensPost ? renderedImage?.jpegUrl : thumbnailImage ?  thumbnailImage?.jpegUrl : renderedImage?.jpegUrl;

  return (
    <>
      {itemData && (
        <>
          <Box className={classes.card}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <Box position='absolute' style={{ right: 10, top: 10 }}>
                {isAuthenticated === true && (
                  <Button className={classes.btn} onClick={handleClick}>
                    <MoreIcon fill='#E2E4E9' />
                  </Button>
                )}
                {openca && (
                  <PublicationOtherMenu
                    itemData={itemData}
                    menuClose={() => setOpenCA(false)}
                    onProfile
                    setEvent={setEvent}
                    setDelete={setDeleted}
                  />
                )}
              </Box>
            </ClickAwayListener>
            <Link to={`/meme/${itemData?.id}`}>
              <Box
                className={classes.imgContainer}
                style={{
                  backgroundImage: `url(${imageUrl})`
                }}
              >
                {isPlayable && (
                  <VideoPlayerIcon
                    alt='Meme'
                    src='meme/video-player-icon.png'
                  />
                )}
                {itemData.type === 'news' && (
                  <Box className={`${classes.timerBox}`}>
                    <Typography className={classes.timer}>News</Typography>
                  </Box>
                )}
              </Box>
            </Link>

            <Box className={classes.txtContainer}>
              <Typography className={classes.text1}>
                {itemData.description}
              </Typography>
              <Typography className={classes.text2}>
                {getTotalReactions(itemData)} Reactions
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </>
  )
};

export default UserPostItem;
