import { makeStyles } from "@material-ui/core/styles";

import colors from "theme/dark/colors";

const usePublicationDetailStyles = makeStyles({
  container: {
    display: "flex",
  },
  paper: {
    backgroundColor: colors.darkBlue,
    boxShadow: "0px 3px 10px #0000001A",
    boxSizing: "border-box",
    borderRadius: "15px",
    overflow: "hidden !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "&:focus": {
      outline: "none",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    textAlign: "center",
    fontSize: 16,
    color: "#808893",
    fontWeight: 500,
    letterSpacing: "-0.3px",
    marginTop: 8,
    lineHeight: "24px",
  },
  backbtn: {
    height: "44px",
    width: "40%",
    borderRadius: "7px",
    fontWeight: 600,
    fontSize: "14px",
    color: "#FFFFFF",
    boxShadow: "none",
    marginRight: 8,
    border: "1px solid #35a6eb",
  },
  changebtn: {
    height: "44px",
    width: 256,
    backgroundColor: "#35a6eb",
    borderRadius: "7px",
    fontWeight: 600,
    fontSize: "14px",
    color: "#FFFFFF",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#35a6eb",
    },
  },
  btnbox: {
    alignSelf: "center",
    backgroundColor: "#161A21",
    width: "100%",
    height: 80,
    alignItems: "center",
    padding: "0 1rem",
  },
  ".MuiPaper-root": {
    display: "none !important",
  },
  root: {
    "& .MuiDialog-paper": {
      maxWidth: "initial",
      borderRadius: 15,
      background: "#202A41",
      padding: 0,
    },
  },
});

export default usePublicationDetailStyles;
