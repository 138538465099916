import { URLSearchParamsCapable } from 'ymca/jsonFetcher'
import { User } from 'ymca/models/user.model'
import { QuerySearchParamsDto } from './common.dto'

export type LoginTarget = 'website' | 'admin'

export type TokenSource = 'email' | 'google' | 'lens'
export const TOKEN_SOURCE_EMAIL = 'email'
export const TOKEN_SOURCE_GOOGLE = 'google'
export const TOKEN_SOURCE_LENS = 'lens'

export interface RefreshTokenResponse {
  user: User
  sessionToken: string
  accessToken: string
}

export interface LocalStorageUserData {
  userData: User
  token: string
  sessionToken: string
}

export function adaptLoginCredentialsForStorage (response: RefreshTokenResponse): LocalStorageUserData {
  return {
    userData: response.user,
    token: response.accessToken,
    sessionToken: response.sessionToken
  }
}

export class LoginTargetDto implements QuerySearchParamsDto {
  target: LoginTarget = 'website'
  redirectURL = ''

  toURLSearchParams (): URLSearchParams {
    const params = new URLSearchParams()
    params.append('target', this.target)
    params.append('redirectURL', this.redirectURL)
    return params
  }
}

export interface CompleteRegistrationDto {
  email: string
  username: string
  displayName: string
  token: string
  'g-recaptcha-response': string
}

export class CompleteLoginQueryDto implements URLSearchParamsCapable {
  source: TokenSource = TOKEN_SOURCE_EMAIL
  referrer?: string
  toURLSearchParams (): URLSearchParams {
    const params = new URLSearchParams()
    params.append('source', this.source)
    if (this.referrer !== undefined && this.referrer !== null) {
      params.append('referrer', this.referrer)
    }
    return params
  }
}

export interface CompleteLoginBodyDto {
  token: string
}
