export const defaultColors = [
  ['#D54177', 'Purple'],
  ['#D54177', 'Pink'],
  ['#EF895B', 'Silver'],
  ['#F0CD4E', 'Yellow'],
  ['#5094CF', 'Blue'],
  ['#8545C1', 'Purple'],
  ['#FFFFFF', 'White'],
  ['#000000', 'Black'],
];

export const fakeData = [
  'Cats',
  'Funny',
  'esports',
  'Environment',
  'Gaming',
  'cryptocurrency',
  'NFT',
  'social media',
];

export const clauseConjunctions = [
  'and',
  'but',
  'or',
  'yet',
  'nor',
  'so',
  'although',
  'because',
  'since',
  'unless',
  'whenever',
  'while',
]