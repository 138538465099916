import { makeStyles } from '@material-ui/core/styles'
import { borderRadius } from 'constants/variables'

import colors from 'theme/dark/colors'

import { BREAKPOINT_MOBILE_LARGE } from 'theme/shared/breakpoint'

const usePublicationHeaderStyles = makeStyles((theme: any) => ({
  memerProfile: {
    position: 'absolute',
    left: 55,
    top: -55,
    zIndex: 5,
    transition: 'opacity 0.3s',
    opacity: 1,
    '& .hide': {
      opacity: 0,
      pointerEvents: 'none'
    }
  },
  authPopoverWrapper: {
    background: '#202A41',
    borderRadius: '12px',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '1rem',
    gap: 15,
    '& button': {
      backgroundColor: colors.blueBtn,
      color: 'white',
      borderRadius: 100,
      width: '100%',
      marginTop: 10,
      padding: 10,
      fontWeight: 700,
      '&:hover': {
        backgroundColor: colors.blueBtn,
        opacity: 0.5
      }
    }
  },
  authPopoverContentWrapper: {
    width: '100%'
  },
  authPopoverTitle: {
    lineHeight: '14px',
    fontWeight: 700,
    letterSpacing: '-0.7px',
    color: '#E9F0F9'
  },
  authPopoverMessage: {
    fontSize: '0.875rem',
    color: '#E9F0F9'
  },
  dot: {
    width: 3,
    height: 3,
    background: '#808893',
    borderRadius: '100%',
    marginLeft: 7,
    display: 'block',
    [`@media (max-width: ${BREAKPOINT_MOBILE_LARGE}px)`]: {
      display: 'none',
      width: 0,
      height: 0
    }
  },
  cardContainer: {
    boxSizing: 'border-box'
  },
  modalcardContainer: {
    width: '480px',
    borderRadius: '15px',
    background: 'white',
    position: 'relative'
  },
  container: {
    width: '800px',
    padding: '17px 25px',
    borderRadius: '15px',
    background: 'white',
    display: 'flex'
  },
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '.6rem',
    position: 'relative'
  },
  other: {
    position: 'relative'
  },
  userAccount: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  avatar: {
    width: '2.5rem',
    height: '2.5rem',
    backgroundColor: colors.profileImageBackground,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  userIcon: {
    width: 36,
    height: 36,
    borderRadius,
    backgroundColor: colors.profileImageBackground,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  name: {
    fontSize: '1rem',
    fontWeight: 900,
    color: colors.titleColor
  },
  attr: {
    color: '#686F79',
    fontSize: 13,
    marginLeft: 6
  },
  time: {
    fontSize: 14,
    color: '#686F79',
    fontWeight: 500
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    color: colors.titleColor,
    textTransform: 'capitalize',
    padding: '0 0 0 16px',
    marginTop: 20,
    rowGap: 10
  },
  tag: {
    fontSize: 12,
    fontWeight: 700,
    height: 24,
    padding: '0 10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid #2B3853`,
    borderRadius: 10,
    marginRight: 6,
    color: colors.darkGray
  },
  screen: {
    width: '561px',
    maxWidth: '100%',
    height: '100%',
    alignSelf: 'center',
    cursor: 'pointer',
    objectFit: 'contain'
  },
  favorite: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  modalfavorite: {
    marginTop: '10px',
    marginBottom: '10px',
    position: 'relative'
  },
  emoji: {
    width: '27px',
    height: '27px'
  },
  h5: {
    height: 40
  },
  '@keyframes vibration': {
    '0%': { transform: 'scale(0)' },
    '100%': { transform: 'scale(1)' }
  },
  buttonContainer: {
    display: 'flex',
    marginBottom: 'auto',
    marginLeft: '70px'
  },
  descTitle: {
    marginBottom: 10,
    // marginTop: 10,
    color: '#E2E4E9',
    fontWeight: 800,
    fontSize: 19,
    width: 641,
    overflowWrap: 'break-word',
    maxWidth: '100%',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  description: {
    color: '#E2E4E9',
    fontSize: 15,
    fontWeight: 500,
    margin: '14px 0 12px 0',
    width: 641,
    overflowWrap: 'break-word'
  },
  commentContainer: {
    // padding: '0 0 0 16px',
    backgroundColor: 'transparent',
    marginTop: 12,
    paddingBottom: 16,
    display: 'flex',
    flexDirection: 'column'
  },
  likeIcons: {
    marginRight: 14,
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  shareBtns: {
    marginLeft: 25,
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto',
    gridGap: 5
  },
  buttons: {
    display: 'flex'
  },
  mobileOnly: {
    display: 'none'
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    gap: '.2rem'
  },
  commentTxt: {
    color: '#E2E4E9',
    fontSize: 15,
    fontWeight: 800,

    '& + img': {
      width: '20px',
      height: 'auto',
      marginLeft: '9px'
    }
  },
  imgStyle: {
    width: '20px',
    height: '20px'
  },
  '@keyframes marquee': {
    ' 0%  ': { top: '2em' },
    '  100%': { top: '0em' }
  },

  icon: {
    transition: 'color 150ms',
    minWidth: 0,
    backgroundColor: colors.darkBlue50,
    marginRight: 6,
    textTransform: 'capitalize',
    display: 'flex',

    height: 46,
    borderRadius: 7,
    '&:hover': {
      backgroundColor: colors.darkBlueActive
    },

    '& svg, & img': {
      transition: 'inherit',
      marginRight: 10,
      marginLeft: 10
    },

    '& img': {
      width: 20,
      marginLeft: 5,
      marginRight: 10
    }
  },
  text1: {
    fontSize: 14,
    fontWeight: 500,
    color: '#ADB3C0'
  },
  imageBox: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.darkBlue50,
    borderRadius: '7px',
    padding: 0,
    '&:hover': {
      background: colors.darkBlue
    }
  },
  shareiconsbox: {
    marginTop: 30,
    width: '100%',
    '& p': {
      color: '#E2E4E9',
      fontWeight: 500,
      fontSize: 11,
      textTransform: 'capitalize',
      marginTop: -0.5
    }
  },
  share1: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 4,
    '& button': {
      width: '100%',
      transition: '0.3s',
      padding: '4px !important',
      borderRadius: 15,
      '&:hover': {
        background: '#293651 !important'
      }
    },
    '& .moreBtn': {
      background: '#293651',
      borderRadius: 100,
      width: '100%'
    }
  },
  share2: {
    display: 'flex',
    marginTop: 20,
    justifyContent: 'space-between',
    marginBottom: 26
  },
  mobilesharepanel: {
    background: '#161C22',
    display: 'flex',
    flexDirection: 'column',
    padding: 26,
    boxSizing: 'border-box',
    boxShadow: '0px -10px 31px rgba(0, 0, 0, 0.6)',
    borderRadius: '20px 20px 0px 0px',
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    '& >p': {
      color: '#E2E4E9',
      fontWeight: 800,
      fontSize: 20
    },

    '& #copymemetext': {
      color: '#E2E4E9',
      fontWeight: 600,
      fontSize: 14,
      fontFamily: 'inter',
      marginLeft: 7
    }
  },
  mobilesharedash: {
    width: 31,
    height: '4px !important',
    borderRadius: '100px',
    margin: '0px auto',
    background: 'rgba(128, 136, 147, 0.5)',
    border: 0
  },
  mobilesharecopybutton: {
    width: '100%',
    height: 44,
    background: '#2A3039',
    border: 0,
    borderRadius: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 14
  },
  nameLink: {
    margin: 0
  },
  disabled: {
    pointerEvents: 'none'
  },
  search: {
    width: '100%',
    height: 40,
    borderRadius: 100,
    marginLeft: 16,
    marginRight: 10,
    paddingLeft: 20,

    border: '1px solid #2B3853',
    '&::placeholder': {
      color: '#6C7B93',
      opacity: 1,
      marginLeft: 30
    }
  },
  input: {
    fontSize: 14,
    fontWeight: 500,
    color: 'white',
    '&::placeholder': {
      color: '#6C7B93',
      opacity: 1,
      display: 'none !important',
      marginLeft: 30
    }
  },
  postComment: {
    background: 'rgba(41, 54, 82, 0.5)',
    color: '#4F5D79',
    borderRadius: 100,
    fontWeight: 700
  },
  comment: {
    display: 'flex',
    overflow: 'hidden',
    gap: 10,
    marginBottom: 10,
    width: '100%',
    '& a': {
      color: 'white',
      textDecoration: 'underline'
    }
  },
  commentBox: {
    padding: 10,
    width: '100%',
    overflow: 'hidden',
    background: '#202B42',
    borderRadius: 15
  },
  commentUser: {
    color: '#8B89FF',
    fontSize: 14,
    fontWeight: 800,
    '& span': {
      color: '#8A98B4',
      fontSize: 12,
      fontWeight: 400
    }
  },
  commentMain: {
    color: '#E9F0F9',
    fontSize: 14,
    fontWeight: 600
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-start',
    color: '#8A98B4',
    fontSize: 14,
    fontWeight: 600,
    marginTop: 18,
    marginLeft: 16
  },
  commentRow: {
    display: 'flex',
    flexDirection: 'column-reverse',
    marginRight: 16,
    marginTop: 19,
    padding: '0 0 0 16px'
  },
  reactionContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10
  },
  shareItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    backgroundColor: 'red',

    '& img': {
      borderRadius: 100,
      width: 34,
      margin: 0,
      height: 34
    },
    '& p': {
      fontSize: 14,
      fontWeight: 700,
      color: '#E9F0F9'
    }
  },
  memeContainer: {
    padding: '20px 16px'
  },
  reactionContainerBox: {
    alignItems: 'center',
    gap: 15
  },
  contestReactionContainerBox: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    top: '-40px',
    padding: '16px',
    marginTop: 0,
    gap: 15,
    flexWrap: 'wrap'
  },
  '@media (max-width: 750px)': {
    cardContainer: {
      width: '100%'
    }
  },
  '@media (max-width: 357px)': {
    nameLink: {
      maxWidth: 60,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 900,
      color: '#FFFFFF'
    }
  },
  '@media (max-width: 550px)': {
    memeContainer: {
      padding: '10px 10px'
    },
    profileContainer: {
      marginBottom: 10
    },
    text1: {
      display: 'none'
    },
    shareBtns: {
      display: 'none',
      marginLeft: 0,
      marginTop: 10
    },
    mobileOnly: {
      display: 'grid'
    },
    screen: {
      borderRadius: 10
    },
    description: {
      width: '100%',
      overflowWrap: 'break-word'
    },
    descTitle: {
      width: '100%',
      overflowWrap: 'break-word'
    },
    contestReactionContainerBox: {
      padding: '0px 16px'
    }
  }
}))

export default usePublicationHeaderStyles
