export const redirectLinks = {
  about: "about",
  auth: "auth",
  confirmation: "confirmation",
  communities: "communities",
  profile: "profile",
  trending: "trending",
  allbest: "allbest",
  category: "category",
  homePage: "homepage",
  discover: "discover",
  notifications: "notifications",
  settings: "settings",
  allSuggests: "allsuggests",
  notFound: "404",
  profileFromSettings: "settings/profile",
  notificationsFromSettings: "settings/notifications",
  preferenceFromSettings: "settings/preference",
  privacyFromSettings: "settings/privacy",
  completeRegistration: "complete-registration",
  completeLogin: "complete-login",
  maintenance: "maintenance",
  business: "business",
  contact: "contact",
  faq: "faq",
  followers: "followers",
  allCategories: "allcategories",
  advertise: "",
  privacy: "privacy",
  agreement: "agreement",
  more: "more",
  premium: "premium",
  achievement: "achievement",
  categories: "categories",
  profileUsername: "profile/:username",
  meme: "meme",
  search: "search",
  contest: "contest",
  ongoingcontest: "contest/ongoing",
  upcomingcontest: "contest/upcoming",
  closedcontest: "contest/closed",
  submit: "contest/:id",
  referral: "referral",
  follow: "follow",
  news: "news",
  newsView: "news/:id",
  twitterLogin: "twitter-login",
};
