import { IconButton, InputBase } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { CloseOutlined } from '@material-ui/icons'
import { Box, Skeleton, Typography } from '@mui/material'
import SearchIcon from '@material-ui/icons/Search'
import CommonImageComponent from 'components/shared/Image'
import useWindowSize from 'hooks/useWindowSize'
import colors from 'theme/dark/colors'
import {
  BREAKPOINT_TABLET,
  BREAKPOINT_TABLET_MEDIUM
} from 'theme/shared/breakpoint'
import {
  useGetHasFaceTemplatesQuery,
  useGetTemplates
} from 'hooks/api-hooks/useTemplates'
import AtomLoading from 'components/atoms/AtomLoading'
import useInfiniteScroll from 'hooks/useInfiniteScroll/useInfiniteScroll'
import useMemeTemplateStyles from './style'
import { debounce } from 'utils/utils'

interface MemeSearchInputeProps {
  handleSearch: (value: string) => void
  resetSearch: (value?: any) => void
  searchDisabled: boolean
}

function MemeSearchInput({
  handleSearch,
  resetSearch,
  searchDisabled
}: MemeSearchInputeProps) {
  const classes = useMemeTemplateStyles()
  const [search, setSearch] = useState(false)
  const isMobile = useWindowSize().width < BREAKPOINT_TABLET
  const handleInputChange = (e: any) => {
    const value = e.target.value

    const escapedValue = value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

    escapedValue.length === 0 ? resetSearch() : handleSearch(escapedValue)
  }

  return (
    <form className={classes.searchForm} onSubmit={(e) => e.preventDefault()}>
      <InputBase
        fullWidth
        onChange={debounce(handleInputChange, 500) as any}
        className={classes.searchBar}
        disabled={searchDisabled}
        placeholder='Search memes...'
        style={{
          paddingLeft: isMobile ? '.5rem' : '1rem'
        }}
        onFocus={() => setSearch(true)}
        startAdornment={
          isMobile && (
            <SearchIcon
              style={{ color: 'white', width: 18, height: 18, marginRight: 10 }}
              fontSize='small'
            />
          )
        }
        endAdornment={
          search && (
            <IconButton
              onClick={(e) => {
                resetSearch()
                setSearch(false)
                const input = e?.currentTarget?.parentElement
                  ?.children?.[0] as any
                if (input) {
                  input.value = ''
                }
              }}
              style={{
                background: '#151E31',
                zIndex: 999,
                width: 10,
                height: 10,
                marginRight: 15
              }}
            >
              <CloseOutlined
                style={{ color: 'white', width: 15, height: 15 }}
                fontSize='small'
              />
            </IconButton>
          )
        }
      />
    </form>
  )
}
interface MemeTemplateProps {
  tab?: string
  handleImageSelection: (url: any) => Promise<void>
}

const MemeTemplate = (props: MemeTemplateProps) => {
  const { tab } = props

  if (tab === 'face_swap') {
    return <MemeTemplateFaceSwap {...props} />
  }

  return <MemeTemplateDefault {...props} />
}

const MemeTemplateFaceSwap = ({ handleImageSelection }: MemeTemplateProps) => {
  const classes = useMemeTemplateStyles()
  const searchDisabledRef = useRef(true)
  const { width } = useWindowSize()
  const isMobile = width <= BREAKPOINT_TABLET_MEDIUM
  const { current: searchDisabled } = searchDisabledRef

  const {
    isLoading,
    isFetching,
    data,
    fetchNextPage,
    searchTemplate,
    resetPage
  } = useGetHasFaceTemplatesQuery({})
  const { data: templates, hasMore } = data ?? { templates: [] }
  const hasNextPage = Boolean(hasMore)

  const [infiniteRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage,
    onLoadMore: () => fetchNextPage?.(),
    rootMargin: '20px'
  })

  useEffect(() => {
    searchDisabledRef.current = false
  }, [])

  const handleSearch = (value: string) => {
    searchTemplate(value)
  }

  const handleSearchReset = (e: any) => {
    resetPage()
  }

  return (
    <Box className={classes.memeTemplate}>
      <Box className={classes.searchWrapper}>
        <MemeSearchInput
          searchDisabled={searchDisabled}
          handleSearch={handleSearch}
          resetSearch={handleSearchReset}
        />
      </Box>

      <Box className={classes.templatesWrapper}>
        <Box className={classes.templateGridContainer}>
          {!isLoading ? (
            <>
              {templates?.map((template, index) => {
                return (
                  <div className={classes.templateGridItem} key={template?.id}>
                    <CommonImageComponent
                      src={template.templateImage.urlOptimised200x200}
                      fallbackSrc={template.templateImage.jpegUrl}
                      onClick={() =>
                        handleImageSelection(template.templateImage.urlOriginal)
                      }
                      alt={template.title}
                      width={'100%'}
                      lazyLoad={true}
                    />
                  </div>
                )
              })}
            </>
          ) : (
            <>
              {[...Array(isMobile ? 6 : 16)].map((_, i) => {
                return (
                  <div className={classes.templateGridItem} key={i}>
                    <Skeleton
                      variant='rectangular'
                      width={'100%'}
                      height={'100%'}
                      animation='wave'
                      style={{
                        background: isMobile
                          ? colors.memeTemplateMobileBackground
                          : colors.darkBlue50
                      }}
                    />
                  </div>
                )
              })}
            </>
          )}
        </Box>

        {!isLoading && (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
          >
            {hasNextPage && !isFetching && (
              <Box height='5rem' ref={infiniteRef} />
            )}
            {isFetching && (
              <Box display='flex' justifyContent='center'>
                <AtomLoading />
              </Box>
            )}
          </Box>
        )}
      </Box>
      {!isMobile && (
        <Box textAlign='center'>
          <Typography style={{ fontSize: '0.875em' }}>
            Scroll down to see more
          </Typography>
        </Box>
      )}
    </Box>
  )
}

function MemeTemplateDefault({ handleImageSelection }: MemeTemplateProps) {
  const classes = useMemeTemplateStyles()
  const searchDisabledRef = useRef(true)
  const { width } = useWindowSize()
  const isMobile = width <= BREAKPOINT_TABLET_MEDIUM
  const { current: searchDisabled } = searchDisabledRef

  const {
    isLoading,
    isFetching,
    data,
    fetchNextPage,
    searchTemplate,
    resetPage
  } = useGetTemplates({})
  const { data: templates, hasMore } = data ?? { templates: [] }
  const hasNextPage = Boolean(hasMore)

  const [infiniteRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage,
    onLoadMore: () => fetchNextPage?.(),
    rootMargin: '20px'
  })

  useEffect(() => {
    searchDisabledRef.current = false
  }, [])

  const handleSearch = (value: string) => {
    searchTemplate(value)
  }

  const handleSearchReset = (e: any) => {
    resetPage()
  }

  return (
    <Box className={classes.memeTemplate}>
      <Box className={classes.searchWrapper}>
        <MemeSearchInput
          searchDisabled={searchDisabled}
          handleSearch={handleSearch}
          resetSearch={handleSearchReset}
        />
      </Box>

      <Box className={classes.templatesWrapper}>
        <Box className={classes.templateGridContainer}>
          {!isLoading ? (
            <>
              {templates?.map((template, index) => {
                return (
                  <div className={classes.templateGridItem} key={template?.id}>
                    <CommonImageComponent
                      src={template.templateImage.urlOptimised200x200}
                      fallbackSrc={template.templateImage.jpegUrl}
                      onClick={() =>
                        handleImageSelection(template.templateImage.urlOriginal)
                      }
                      alt={template.title}
                      width={'100%'}
                      lazyLoad={true}
                    />
                  </div>
                )
              })}
            </>
          ) : (
            <>
              {[...Array(isMobile ? 6 : 16)].map((_, i) => {
                return (
                  <div className={classes.templateGridItem} key={i}>
                    <Skeleton
                      variant='rectangular'
                      width={'100%'}
                      height={'100%'}
                      animation='wave'
                      style={{
                        background: isMobile
                          ? colors.memeTemplateMobileBackground
                          : colors.darkBlue50
                      }}
                    />
                  </div>
                )
              })}
            </>
          )}
        </Box>

        {!isLoading && (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
          >
            {hasNextPage && !isFetching && (
              <Box height='5rem' ref={infiniteRef} />
            )}
            {isFetching && (
              <Box display='flex' justifyContent='center'>
                <AtomLoading />
              </Box>
            )}
          </Box>
        )}
      </Box>
      {!isMobile && (
        <Box textAlign='center'>
          <Typography style={{ fontSize: '0.875em' }}>
            Scroll down to see more
          </Typography>
        </Box>
      )}
    </Box>
  )
}

MemeTemplate.propTypes = {
  tab: PropTypes.oneOf(['default', 'face_swap']),
  classes: PropTypes.any,
  handleImageSelection: PropTypes.any
}

export default MemeTemplate
