import { ReactElement } from "react";
import { styled, Box } from "@mui/material";

import { BREAKPOINT_TABLET_MEDIUM } from "theme/shared/breakpoint";
import RightSidebar from "./RightSidebar";
import useWindowSize from "hooks/useWindowSize";

const Wrapper = styled(Box, {
  name: 'PageLayoutWrapper'
})<{ hasRightPanel?: boolean }>(({ hasRightPanel, theme }) => ({
  fontSize: '1vw',
  display: 'grid',
  gridTemplateColumns: hasRightPanel
    ? 'minmax(400px, 550px) minmax(300px, 350px)'
    : '1fr',

  [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
    gridTemplateColumns: '100%'
  }
}))

const MainContainer = styled(Box, {
  name: 'PageLayoutMainContainer'
})<{ marginTop?: any }>(({ marginTop = '1rem' }) => ({
  display: 'grid',
  gridTemplateColumns: '100%',
  gridAutoRows: 'max-content',
  marginTop
}))

const RightPanelContainer = styled(Box, {
  name: 'PageLayoutRightPanelContainer'
})(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'minmax(290px, 1fr)',

  [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
    display: 'none'
  }
}))

interface PageLayoutProps {
  children: any
  hasRightPanel?: boolean
  pageTitle?: ReactElement
  marginTop?: any
}

const PageTitleContainer = styled(Box)(({ theme }) => ({
  borderBottom: `0`,
  '& > div': {
    marginBottom: '1rem'
  },
  [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
    display: 'none'
  }
}))

const PageTitle = ({
  pageTitle
}: {
  pageTitle?: ReactElement
}): JSX.Element => {
  if (!pageTitle) return <></>
  return <PageTitleContainer>{pageTitle}</PageTitleContainer>
}

const PageLayout = ({
  children,
  hasRightPanel = true,
  pageTitle,
  marginTop
}: PageLayoutProps): JSX.Element => {
  // get device width use that to determine if it can also have right sidebar
  const { width } = useWindowSize()
  const canShowRightPanel = width > BREAKPOINT_TABLET_MEDIUM
  if (hasRightPanel && canShowRightPanel) {
    return (
      <Wrapper hasRightPanel>
        <MainContainer>
          {<PageTitle pageTitle={pageTitle} />}
          {children}
        </MainContainer>
        <RightPanelContainer>
          <RightSidebar />
        </RightPanelContainer>
      </Wrapper>
    )
  }
  return (
    <Wrapper>
      <MainContainer marginTop={marginTop}>
        {<PageTitle pageTitle={pageTitle} />}
        {children}
      </MainContainer>
    </Wrapper>
  )
}

export default PageLayout;
