import {
  styled,
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
} from "@mui/material";

interface TypographyProps extends MuiTypographyProps {
  component?: any;
  fontSize?: string | number;
  fontWeight?: string | number;
}

const baseProperties = {
  fontFamily: ["Inter", "Arial", "Helvetica", "sans-serif"].join(","),
  fontWeightLighter: 100,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  fontWeightBolder: 800,
  fontSizeXXS: "1rem",
  fontSizeXS: "1.2rem",
  fontSizeSM: "1.4rem",
  fontSizeMD: "1.6rem",
  fontSizeLG: "1.8rem",
  fontSizeXL: "2rem",
  fontSize2XL: "2.4rem",
  fontSize3XL: "3rem",
};

export const ellipsisProperty = {
  textOverflow: "ellipsis",
  overflow: "hidden",
  "white-space": "nowrap",
};

// Reference: https://mui.com/material-ui/api/typography/#css
const Typography = styled(MuiTypography, {
  name: "Typography",
})<TypographyProps>(({ variant = "body1", theme, ...props }) => {
  const restStyles: any = props;
  const baseStyles = {
    color: theme.palette.text.primary,
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightRegular,
  };

  if (variant === "subtitle1") {
    return {
      "&.MuiTypography-subtitle1": {
        ...baseStyles,
        fontSize: baseProperties.fontSizeMD,
        lineHeight: 1.625,
        color: theme.palette.grey[500],
        ...restStyles,
      },
    };
  }

  if (variant === "subtitle2") {
    return {
      "&.MuiTypography-subtitle2": {
        ...baseStyles,
        fontSize: baseProperties.fontSizeSM,
        lineHeight: 1.6,
        color: theme.palette.grey[500],
        ...restStyles,
      },
    };
  }

  if (variant === "h1") {
    return {
      "&.MuiTypography-h1": {
        ...baseStyles,
        fontSize: "4.8rem",
        lineHeight: 1.25,
        fontWeight: baseProperties.fontWeightBolder,
        ...restStyles,
      },
    };
  }

  if (variant === "h2") {
    return {
      "&.MuiTypography-h2": {
        ...baseStyles,
        fontSize: "3.6rem",
        lineHeight: 1.3,
        fontWeight: baseProperties.fontWeightBolder,
        ...restStyles,
      },
    };
  }

  if (variant === "h3") {
    return {
      "&.MuiTypography-h3": {
        ...baseStyles,
        fontSize: "3rem",
        lineHeight: 1.375,
        fontWeight: baseProperties.fontWeightBolder,
        ...restStyles,
      },
    };
  }

  if (variant === "h4") {
    return {
      "&.MuiTypography-h4": {
        ...baseStyles,
        fontSize: "2.4rem",
        lineHeight: 1.375,
        fontWeight: baseProperties.fontWeightBolder,
        ...restStyles,
      },
    };
  }

  if (variant === "h5") {
    return {
      "&.MuiTypography-h5": {
        ...baseStyles,
        fontSize: "2rem",
        lineHeight: 1.375,
        fontWeight: baseProperties.fontWeightBolder,
        ...restStyles,
      },
    };
  }

  if (variant === "h6") {
    return {
      "&.MuiTypography-h6": {
        ...baseStyles,
        fontSize: "1.6rem",
        lineHeight: 1.625,
        fontWeight: baseProperties.fontWeightBolder,
        ...restStyles,
      },
    };
  }

  if (variant === "body1") {
    return {
      "&.MuiTypography-body1": {
        ...baseStyles,
        fontSize: "1.125rem",
        lineHeight: 1.5,
        fontWeight: baseProperties.fontWeightRegular,
        wordBreak: "break-all",
        ...restStyles,
      },
    };
  }

  if (variant === "body2") {
    return {
      "&.MuiTypography-body2": {
        ...baseStyles,
        fontSize: "1rem",
        lineHeight: 1.6,
        fontWeight: baseProperties.fontWeightLight,
        wordBreak: "break-all",
        ...restStyles,
      },
    };
  }

  // button: {
  //   fontFamily: baseProperties.fontFamily,
  //   fontSize: baseProperties.fontSizeSM,
  //   fontWeight: baseProperties.fontWeightLight,
  //   lineHeight: 1.5,
  //   textTransform: "none",
  // },

  // caption: {
  //   fontFamily: baseProperties.fontFamily,
  //   fontSize: baseProperties.fontSizeXS,
  //   fontWeight: baseProperties.fontWeightLight,
  //   lineHeight: 1.25,
  //   ...baseColorProperties,
  // },

  // overline: {
  //   fontFamily: baseProperties.fontFamily,
  // },
  return baseStyles;
});

export default Typography;
