import { getEmptyPaginatedResponse, PaginatedResponse } from 'ymca/dtos/common.dto'
import { GetNotificationsDto } from 'ymca/dtos/notification.dto'
import { NotificationType, YMNotification } from 'ymca/models/notification.model'
import { BaseService, CommonArgs } from './base.service'
import { SelfService } from './self.service'

export class NotificationService extends BaseService {
  protected selfService: SelfService

  public constructor (
    common: CommonArgs,
    selfService: SelfService
  ) {
    super(common)
    this.selfService = selfService
  }

  public async getNotificationsByDto (
    req: GetNotificationsDto
  ): Promise<PaginatedResponse<YMNotification>> {
    const selfId = this.selfService.getId()
    if (selfId === '') {
      return getEmptyPaginatedResponse<YMNotification>()
    }
    const res = await this.common.jsonFetcher.fetchJSON<PaginatedResponse<YMNotification>>('GET', '/api/user/notification', req)
    const notifications = res?.data?.data
    if (!Array.isArray(notifications)) {
      const msg = `Failed to fetch notifications from API: ${JSON.stringify(req)}`
      console.error(msg)
      console.error(res)
      throw new Error(msg)
    }
    return res.data
  }

  public async getNotificationsByType (
    type: NotificationType,
    limit: number = 20,
    offset: number = 0
  ): Promise<PaginatedResponse<YMNotification>> {
    const req = new GetNotificationsDto()
    req.limit = limit
    req.offset = offset
    req.types = [type]
    const res = await this.getNotificationsByDto(req)
    return res
  }

  public async getAllNotifications (
    limit: number = 20,
    offset: number = 0
  ): Promise<PaginatedResponse<YMNotification>> {
    const req = new GetNotificationsDto()
    req.limit = limit
    req.offset = offset
    const res = await this.getNotificationsByDto(req)
    return res
  }

  public async getUnreadNotificationCount (): Promise<number> {
    const req = new GetNotificationsDto()
    req.ignoreRead = true
    req.limit = 0
    req.offset = 0
    const res = await this.getNotificationsByDto(req)
    const count = res.count
    return count
  }

  public async markNotificationRead (notificationId: string): Promise<boolean> {
    const res = await this.common.jsonFetcher.fetchJSON(
      'PUT', `/api/user/notification/${notificationId}/mark-read`
    )
    return res.isSuccess
  }

  public async markAllNotificationsRead (): Promise<boolean> {
    const res = await this.common.jsonFetcher.fetchJSON(
      'POST', '/api/user/notification/mark-all-read'
    )
    return res.isSuccess
  }
}
