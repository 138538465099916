import { ImageFormat } from 'ymca/models/image.model'
import { URLSearchParamsCapable } from 'ymca/jsonFetcher'
import { QuerySearchParamsDto } from './common.dto'

export class ListCategoriesDto extends QuerySearchParamsDto implements URLSearchParamsCapable {
  // id should be a comma separated list of UUIDs
  public ids: string[] = []

  // You can pass multiple category names in the query string separated by commas
  public name: string[] = []

  // You can pass multiple category slugs in the query string separated by commas
  public slug: string[] = []

  public toURLSearchParams (): URLSearchParams {
    const params = super.toURLSearchParams()
    if (this.ids.length > 0) {
      params.append('id', this.ids.join(','))
    }
    if (this.name.length > 0) {
      params.append('name', this.name.join(','))
    }
    if (this.slug.length > 0) {
      params.append('slug', this.slug.join(','))
    }
    return params
  }
}

export class CreateCategoryDto {
  public name: string = ''
  public description: string = ''
  public tags: string[] = [] // 1 to 5 tags
  sourceImageFormat: ImageFormat = 'png'
}

export class UpdateCategoryDto {
  public name: string = ''
  public description: string = ''
  public tags: string[] = []
}

export class DeleteCategoryByNameDto {
  public name: string = ''
}
