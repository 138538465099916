import { Box } from '@material-ui/core'
import { styled } from '@mui/material'

import { Post as PostModel } from 'ymca/models/post.model'

import PublicationComment from "../PublicationComment/PublicationComment";
import PublicationTag from "../PublicationTag";
import PublicationReaction from "../PublicationReaction";
import PublicationShare from "../PublicationShare";
import PublicationCommentCount from '../PublicationCommentCount'
import colors from 'theme/dark/colors'

export interface PublicationFooterProp {
  post: PostModel
  contestModal?: boolean
  showComment?: boolean
  onModalToggle?: Function | any
}

const PublicationFooterWrapper = styled('section', {
  name: 'PublicationFooterWrapper'
})(() => {
  return {
    gridArea: 'publicationFooter',

    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
  }
})

const FooterRightSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '.5rem'
}))

const PublicationFooter = ({
  post,
  contestModal,
  showComment,
  onModalToggle = () => {}
}: PublicationFooterProp): JSX.Element => {
  return (
    <PublicationFooterWrapper>
      <Box display='flex' justifyContent='space-between' px={0}>
        <PublicationReaction post={post} />
        {!contestModal && (
          <FooterRightSection>
            <PublicationCommentCount onModalToggle={onModalToggle} />
            <PublicationShare post={post} />
            {/*<PublicationBookmark post={post} />*/}
          </FooterRightSection>
        )}
      </Box>
      {showComment ? (
        <Box
          style={{
            borderBottom: `1px solid ${colors.border}`,
            margin: '0 -.6rem'
          }}
          pb='.5rem'
        >
          <Box px='.6rem'>
            <PublicationTag tags={post.tags} />
          </Box>
        </Box>
      ) : (
        <PublicationTag tags={post.tags} />
      )}

      {showComment && <PublicationComment post={post} />}
    </PublicationFooterWrapper>
  )
}
export default PublicationFooter
