import { makeStyles } from "@material-ui/core/styles";
import { BREAKPOINT_LAPTOP } from "theme/shared/breakpoint";

const useSidebarStyles = makeStyles({
  navLinkCta: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',

    [`@media (max-width: ${BREAKPOINT_LAPTOP}px)`]: {
      display: 'none'
    }
  }
})

export default useSidebarStyles;
