import { Box, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import colors from "theme/dark/colors";

const TagContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  color: '#DFE1E6',
  textTransform: 'capitalize',
  rowGap: 10,
  '@media (max-width: 750px)': {
    margin: 0,
    padding: 0
  }
})

const TagTypography = styled(Typography)({
  fontSize: 12,
  fontWeight: 700,
  height: 24,
  padding: "0 10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: `1px solid #2B3853`,
  borderRadius: 10,
  marginRight: 6,
  color: colors.darkGray,
});

export interface PublicationTagProp {
  tags: string[];
}

const PublicationTag = ({ tags }: PublicationTagProp): JSX.Element => {
  return (
    <TagContainer>
      {tags.slice(0, 4).map((item: any) => (
        <TagTypography key={item}>{item}</TagTypography>
      ))}

      {tags.length > 4 && <TagTypography>+{tags.length - 3}</TagTypography>}
    </TagContainer>
  );
};

export default PublicationTag;
