import { useState } from 'react'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'
import { Backdrop, Box, Button, Fade, Radio, Typography } from '@material-ui/core'
import colors from 'theme/dark/colors'

export interface ReportModalProps {
  open: boolean
  handleClose: () => void
}

const useStyles = makeStyles({
  paper: {
    position: 'absolute',
    width: '509px',
    backgroundColor: colors.darkBlue,
    boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.5)',
    boxSizing: 'border-box',
    borderRadius: '7px',
    overflowY: 'auto',
    '&:focus': {
      outline: 'none'
    },
    '&::-webkit-scrollbar': {
      backgroundColor: '#181B22 !important'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#1A1E28 !important',
      borderRadius: '35px'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  header: {
    height: '63px',
    backgroundColor: '#232A30',
    display: 'flex',
    padding: '20px 26px',
    boxSizing: 'border-box',
    alignItems: 'center'
  },
  headerText: {
    fontSize: '18px',
    fontWeight: 900,
    lineHeight: '23px'
  },
  content: {
    padding: 20,
    boxSizing: 'border-box'
  },
  description: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#ABB3BF',
    lineHeight: '21px',
    letterSpacing: '-0.3px'
  },
  options: {
    marginTop: '21px'
  },
  reportOption: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1px'
  },
  reportOptionText: {
    fontSize: '15px',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '-0.3px',
    alignSelf: 'center'
  },
  actionButtons: {
    display: 'flex',
    gap: '8px'
  },
  actionBox: {
    marginTop: '21px'
  },
  '@media (max-width: 550px)': {
    paper: {
      width: '100%'
    }
  }
})

const ReportModal = ({ open, handleClose }: ReportModalProps): JSX.Element => {
  const classes = useStyles()

  const [selectedValue, setSelectedValue] = useState<string>('harmful content')

  const handleChange = (event: any): void => {
    setSelectedValue(event.target.value)
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Box className={classes.paper} style={{ overflowY: 'auto' }}>
          <Box className={classes.header}>
            <Typography className={classes.headerText}>Report an issue</Typography>
          </Box>
          <Box className={classes.content}>
            <Typography className={classes.description}>
              Please let us know of the issue, and our team will take a close look at it!
            </Typography>

            <Box className={classes.options}>
              <Box className={classes.reportOption}>
                <Typography className={classes.reportOptionText}>Harmful Content</Typography>
                <Radio
                  checked={selectedValue === 'harmful content'}
                  onChange={handleChange}
                  value='harmful content'
                  name='radio-button-demo'
                  inputProps={{ 'aria-label': 'A' }}
                  style={{ color: 'var(--gradient-fall-back)' }}
                />
              </Box>

              <Box className={classes.reportOption}>
                <Typography className={classes.reportOptionText}>Scam or Spam</Typography>
                <Radio
                  checked={selectedValue === 'scam or spam'}
                  onChange={handleChange}
                  value='scam or spam'
                  name='radio-button-demo'
                  inputProps={{ 'aria-label': 'A' }}
                  style={{ color: 'var(--gradient-fall-back)' }}
                />
              </Box>
              <Box className={classes.reportOption}>
                <Typography className={classes.reportOptionText}>Pornographic Content</Typography>
                <Radio
                  checked={selectedValue === 'Pornographic Content'}
                  onChange={handleChange}
                  value='Pornographic Content'
                  name='radio-button-demo'
                  inputProps={{ 'aria-label': 'A' }}
                  style={{ color: 'var(--gradient-fall-back)' }}
                />
              </Box>
              <Box className={classes.reportOption}>
                <Typography className={classes.reportOptionText}>
                  Trademarking Infringement
                </Typography>
                <Radio
                  checked={selectedValue === 'Trademarking Infringement'}
                  onChange={handleChange}
                  value='Trademarking Infringement'
                  name='radio-button-demo'
                  inputProps={{ 'aria-label': 'A' }}
                  style={{ color: 'var(--gradient-fall-back)' }}
                />
              </Box>
              <Box className={classes.reportOption}>
                <Typography className={classes.reportOptionText}>
                  Self-harm or Suicidal Tendencies
                </Typography>
                <Radio
                  checked={selectedValue === 'Self-harm or Suicidal Tendencies'}
                  onChange={handleChange}
                  value='Self-harm or Suicidal Tendencies'
                  name='radio-button-demo'
                  inputProps={{ 'aria-label': 'A' }}
                  style={{ color: 'var(--gradient-fall-back)' }}
                />
              </Box>
              <Box className={classes.reportOption}>
                <Typography className={classes.reportOptionText}>Misinformation</Typography>
                <Radio
                  checked={selectedValue === 'Misinformation'}
                  onChange={handleChange}
                  value='Misinformation'
                  name='radio-button-demo'
                  inputProps={{ 'aria-label': 'A' }}
                  style={{ color: 'var(--gradient-fall-back)' }}
                />
              </Box>
            </Box>

            <Box className={classes.actionBox}>
              <Box className={classes.actionButtons}>
                <Button
                  variant='contained'
                  onClick={handleClose}
                  style={{
                    height: '44px',
                    width: '97px',
                    backgroundColor: '#232A30',
                    borderRadius: '7px',
                    fontWeight: '700',
                    fontSize: '14px',
                    color: '#ABB3BF',
                    boxShadow: 'none'
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  style={{
                    height: '44px',
                    width: '372px',
                    backgroundColor: 'var(--gradient-fall-back)',
                    borderRadius: '7px',
                    fontWeight: '700',
                    fontSize: '14px',
                    color: '#FFFFFF',
                    boxShadow: 'none'
                  }}
                  onClick={handleClose}
                >
                  Submit Report
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}

export default ReportModal
