import { createContext, useContext, useState } from 'react'

import useReaction, {
  ReactionCountType,
  ReactionEnum,
  ReactionType,
  ReactionTypes
} from 'hooks/useReaction'
import { capitalizeFirstLetter } from 'utils/formatString'
import { Post } from 'ymca/models/post.model'
import useBookmark from 'hooks/useBookmark'

const PublicationContext = createContext({
  reaction: {
    onLike: (reaction: ReactionType) => {},
    onUnLike: (reaction: ReactionType) => {},
    activeReaction: '' as ReactionType,
    reactionCountPayload: {} as ReactionCountType
  },
  totalComments: 0,
  updateTotalCommment: () => {},
  post: {},
  onDeletePublication: () => {},

  bookmark: {
    isBookmarked: false,
    handleBookmark: (post: Post) => {},
    isShowing: false,
    hideShowUpdate: () => {}
  }
})

const { Provider, Consumer } = PublicationContext

interface PublicationProviderProps {
  children: any
  post: Post
  onDeletePublication: () => void
}

const PublicationProvider = (props: PublicationProviderProps) => {
  const { children, post, onDeletePublication } = props

  const newPost = post as any

  const userActiveReaction: ReactionType =
    newPost.userReactionState?.toLowerCase()

  const reactionCountPayload: ReactionCountType = {
    [ReactionEnum.Like]: 0,
    [ReactionEnum.Dislike]: 0,
    [ReactionEnum.No_Reaction]: 0
  }

  ReactionTypes.forEach((reaction, i) => {
    const reactCount = newPost[`n${capitalizeFirstLetter(reaction)}s`]

    reactionCountPayload[reaction] = reactCount ?? 0
  })

  const {
    onUnLike,
    onLike,
    activeReaction,
    reactionCountPayload: reactCount
  } = useReaction({
    activeReaction: userActiveReaction,
    reactionCountPayload
  })

  const bookmarkProps = useBookmark({
    post
  })

  const { nComments } = post
  const [totalComments, setTotalComments] = useState(nComments ?? 0)

  const updateTotalCommment = () => {
    setTotalComments((prev) => prev + 1)
  }

  return (
    <Provider
      value={{
        post: newPost,
        reaction: {
          onLike,
          onUnLike,
          activeReaction,
          reactionCountPayload: reactCount
        },
        totalComments,
        updateTotalCommment,
        onDeletePublication,

        bookmark: bookmarkProps
      }}
    >
      {children}
    </Provider>
  )
}

export const usePublicationContext = () => useContext(PublicationContext)

export default PublicationProvider

export { Consumer }
