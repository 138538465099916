import { Navigate, Route, Routes } from "react-router-dom";
import CommunitiesPageWrapper from "components/layout/CommunitiesWrapper";
import CommunitiesExplorePage from "pages/CommunitiesExplorePage";
import CommunitiesJoinedPage from "pages/CommunitiesJoinedPage";
import CommunitiesManagingPage from "pages/CommunitiesManagingPage";
import CommunityDetailPage from "pages/CommunityDetailPage";
import CommunityMembersPage from "pages/CommunityMembersPage";
import { useAuth } from "context/auth/authContext";
import CommunityMemePage from "pages/CommunityMemePage";

const CommunityRoutes = (): JSX.Element => {
  const { isAuthenticated } = useAuth();
  return (
    <Routes>
      <Route index element={<Navigate to='explore' />} />
      <Route path='*' element={<CommunitiesPageWrapper />}>
        <Route path='explore' element={<CommunitiesExplorePage />} />
        {isAuthenticated && (
          <>
            <Route path='joined' element={<CommunitiesJoinedPage />} />
            <Route path='managing' element={<CommunitiesManagingPage />} />
          </>
        )}
      </Route>
      <Route path=':id'>
        <Route index element={<CommunityDetailPage />} />
        <Route path='members' element={<CommunityMembersPage />} />
        <Route path='meme/:memeId' element={<CommunityMemePage />} />
      </Route>
    </Routes>
  );
};

export default CommunityRoutes;
