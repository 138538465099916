import { Box, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'

import useCommunityMembersStyles from './style'
import PageTemplate from 'components/layout/PageTemplate'
import PageLayout from 'components/layout/PageLayout'
import Icon from 'components/atoms/Icons'

import {
  useGetCommunityBySlugQuery,
  useGetCommunityMembersQuery
} from 'hooks/api-hooks/useCommunity'
import AtomLoading from 'components/atoms/AtomLoading'
import CommunityMemberGridContainer from 'components/organisms/CommunityMemberGridContainer'
import { MetaTags } from 'components/shared'
import { Link } from 'react-router-dom'
import { redirectLinks } from 'constants/redirectLinks'
import CommunityMemberFilterRoleMenuDropdown from 'components/organisms/CommunityMemberFilterRoleMenuDropdown'
import {
  EmptyCommunityMembers,
  SearchCommunityForm
} from 'components/layout/CommunitiesWrapper'
import config from 'utils/config'
import { CommunityRole } from 'ymca/models/community-role.model'
import useSearchCommunity from 'hooks/useSearchCommunity'
import { PublicUser } from 'ymca/models/user.model'

const CommunityMembersPage = () => {
  const classes = useCommunityMembersStyles()
  const { id: slug } = useParams()

  const { experimentalCommunityv2Flag } = config

  const getCommunityQuery = useGetCommunityBySlugQuery({ slug: slug! })
  const query = useGetCommunityMembersQuery({
    id: getCommunityQuery?.data?.id
  })
  const data = getCommunityQuery.data

  const handleSearchByRight = (role: CommunityRole) => {
    query.searchRefetch({ role })
  }

  const handleOnSearch = (search: string) => {
    query.searchRefetch({ search })
  }

  const { searchFormRef } = useSearchCommunity({
    onSearch: handleOnSearch
  })

  const communityMembers: PublicUser[] = query?.data?.data ?? []

  return (
    <PageTemplate>
      <PageLayout marginTop={0}>
        <Box component='section' className={classes.wrapper}>
          <MetaTags
            show={window.location.href.includes(
              `/${redirectLinks.communities}/${slug}/members`
            )}
            title={`${slug} Community Members | YouMeme`}
            description='The World’s First Meme-to-Earn Social Network. Select a meme template, add text, images, stickers and save your meme. Your daily dose of meme is here.'
            url={`${process.env.REACT_APP_DEPLOYMENT_URL}/${redirectLinks.communities}/${slug}/members`}
            thumbnail={data?.avatar?.jpegUrl}
          />
          <Box component='section' className={classes.header}>
            <Box display='flex' alignItems='center'>
              <Link
                to={`/${redirectLinks.communities}/${slug}`}
                className={classes.goBackBtn}
              >
                <Icon icon='arrow-left' fontSize='2.5rem' />
              </Link>
              <Typography className={classes.headerTitle}>Members</Typography>
            </Box>
          </Box>
          <Box>
            {query.isLoading ? (
              <Box display='flex' justifyContent='center'>
                <AtomLoading />
              </Box>
            ) : (
              <Box p='1rem' display='flex' flexDirection='column' gap='1rem'>
                <Box display='flex' alignItems='center' gap='1rem'>
                  <CommunityMemberFilterRoleMenuDropdown
                    onSelect={handleSearchByRight}
                  />
                  <Box flex={1}>
                    <SearchCommunityForm
                      placeholder='Search Members'
                      ref={searchFormRef}
                    />
                  </Box>
                </Box>
                <>
                  {communityMembers?.length === 0 ? (
                    <EmptyCommunityMembers />
                  ) : (
                    <Box>
                      <CommunityMemberGridContainer
                        members={communityMembers!}
                        hasNextPage={query?.data?.hasMore}
                        isFetchingNextPage={query?.isFetching}
                        fetchNextPage={query.fetchNextPage}
                        listFilterRole={query?.role}
                        refetchQuery={query.refetch}
                        community={data!}
                      />
                    </Box>
                  )}
                </>
              </Box>
            )}
          </Box>
        </Box>
      </PageLayout>
    </PageTemplate>
  )
}

export default CommunityMembersPage
