import { makeStyles } from '@material-ui/core/styles'
import { SwitchButton } from 'components/shared/index'
import { Box, Divider, Typography } from '@material-ui/core'

const useStyles = makeStyles({
  itemtitle: {
    color: '#E2E4E9',
    fontWeight: 900,
    fontSize: '26px',
    lineHeight: '31.47px',
    width: '100%'
  },
  description: {
    color: '#808893',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    marginTop: '18px'
  },
  toggleSectionDescription: {
    color: '#6C7B93',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    marginTop: '3px'
  },
  subtitle: {
    fontWeight: 900,
    fontSize: '16px',
    lineHeight: '19.36px',
    color: '#E2E4E9'
  },
  toggleSectionSubtitle: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '19.36px',
    color: '#E2E4E9'
  },
  toggle: {
    '& MuiSwitch-switchBase .Mui-checked': {
      color: 'var(--gradient-fall-back)'
    },
    '& .Mui-checked + .MuiSwitch-track': {
      background: 'var(--gradient-fall-back)'
    }
  }
})

const NotificationSetting = (): JSX.Element => {
  const classes = useStyles()

  return (
    <Box style={{ padding: '45px 0 0 0' }}>
      <Box>
        <Typography className={classes.itemtitle}>Notifications</Typography>
        <Typography className={classes.description}>
          Manage your account. In the profile settings, you will be able to change your personal
          information, connect with various other platforms and terminate your account
        </Typography>
      </Box>

      <Divider style={{ margin: '25px 0 35px 0', backgroundColor: '#252A3A' }} />

      <Box>
        <Box style={{ display: 'flex', justifyContent: 'space-between', height: '46px' }}>
          <Box>
            <Typography className={classes.subtitle}>
              TOGGLE ON ALL PUSH NOTIFICATIONS ACTIVITY
            </Typography>
            <Typography className={classes.description} style={{ width: '100%' }}>
              Please customize your notification preferences. You can always switch on and off.
            </Typography>
          </Box>
          <Box
            style={{ alignSelf: 'center', display: 'flex', marginBottom: '10px', marginRight: 16 }}
          >
            <SwitchButton
              width={46}
              height={24}
              thumb={20}
              transform={21}
              trackColor='#28303A'
              checkColor='rgba(112, 117, 255, 0.2);'
            />
          </Box>
        </Box>
      </Box>

      <Divider style={{ margin: '25px 0 25px 0', backgroundColor: '#252A3A' }} />

      <Box>
        <Box style={{ display: 'flex', justifyContent: 'space-between', height: '46px' }}>
          <Box>
            <Typography className={classes.toggleSectionSubtitle}>Likes</Typography>
            <Typography className={classes.toggleSectionDescription} style={{ width: '100%' }}>
              When a user likes your post(meme)
            </Typography>
          </Box>
          <Box
            style={{ alignSelf: 'center', display: 'flex', marginBottom: '10px', marginRight: 16 }}
          >
            <SwitchButton
              width={46}
              height={24}
              thumb={20}
              transform={21}
              trackColor='#28303A'
              checkColor='rgba(112, 117, 255, 0.2);'
            />
          </Box>
        </Box>
      </Box>

      <Divider style={{ margin: '20px 0', backgroundColor: '#252A3A' }} />

      <Box>
        <Box style={{ display: 'flex', justifyContent: 'space-between', height: '46px' }}>
          <Box>
            <Typography className={classes.toggleSectionSubtitle}>Follows</Typography>
            <Typography className={classes.toggleSectionDescription} style={{ width: '100%' }}>
              When a user follows my account
            </Typography>
          </Box>
          <Box
            style={{ alignSelf: 'center', display: 'flex', marginBottom: '10px', marginRight: 16 }}
          >
            <SwitchButton
              width={46}
              height={24}
              thumb={20}
              transform={21}
              trackColor='#28303A'
              checkColor='rgba(112, 117, 255, 0.2);'
            />
          </Box>
        </Box>
      </Box>

      <Divider style={{ margin: '20px 0', backgroundColor: '#252A3A' }} />

      <Box>
        <Box style={{ display: 'flex', justifyContent: 'space-between', height: '46px' }}>
          <Box>
            <Typography className={classes.toggleSectionSubtitle}>Post a comment</Typography>
            <Typography className={classes.toggleSectionDescription} style={{ width: '100%' }}>
              When a user comments on my posts
            </Typography>
          </Box>
          <Box
            style={{ alignSelf: 'center', display: 'flex', marginBottom: '10px', marginRight: 16 }}
          >
            <SwitchButton
              width={46}
              height={24}
              thumb={20}
              transform={21}
              trackColor='#28303A'
              checkColor='rgba(112, 117, 255, 0.2);'
            />
          </Box>
        </Box>
      </Box>

      <Divider style={{ margin: '20px 0', backgroundColor: '#252A3A' }} />

      <Box>
        <Box style={{ display: 'flex', justifyContent: 'space-between', height: '46px' }}>
          <Box>
            <Typography className={classes.toggleSectionSubtitle}>Mentioning</Typography>
            <Typography className={classes.toggleSectionDescription} style={{ width: '100%' }}>
              When a user mentions me (@me)
            </Typography>
          </Box>
          <Box
            style={{ alignSelf: 'center', display: 'flex', marginBottom: '10px', marginRight: 16 }}
          >
            <SwitchButton
              width={46}
              height={24}
              thumb={20}
              transform={21}
              trackColor='#28303A'
              checkColor='rgba(var(--gradient-fall-back), 0.2);'
            />
          </Box>
        </Box>
      </Box>

      <Divider style={{ margin: '20px 0', backgroundColor: '#252A3A' }} />

      <Box>
        <Box style={{ display: 'flex', justifyContent: 'space-between', height: '46px' }}>
          <Box>
            <Typography className={classes.toggleSectionSubtitle}>Trending</Typography>
            <Typography className={classes.toggleSectionDescription} style={{ width: '100%' }}>
              When one of your posts is trending
            </Typography>
          </Box>
          <Box
            style={{ alignSelf: 'center', display: 'flex', marginBottom: '10px', marginRight: 16 }}
          >
            <SwitchButton
              width={46}
              height={24}
              thumb={20}
              transform={21}
              trackColor='#28303A'
              checkColor='rgba(112, 117, 255, 0.2);'
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default NotificationSetting
