import { useEffect, useState, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "context/auth/authContext";
import Publication from "components/memePublication/Publication";
import { Box, Button, Dialog, Typography } from "@material-ui/core";
import useWindowSize from "hooks/useWindowSize";
import { DefaultYMCA } from "ymca/ymca";
import { Spinner } from "components/loading/LoadingSpinner";
import usePublicationDetailStyles from "./style";
import { redirectLinks } from "constants/redirectLinks";
import { BREAKPOINT_TABLET_MEDIUM } from "theme/shared/breakpoint";
import config from "utils/config";

interface CommentPageProps {
  memeId: string;
  PageLayout?: any;
  disableEditMeme?: boolean;
  copyLinkBasePath?: string;
}

const PublicationDetail = ({
  memeId,
  PageLayout = ({ children }: { children: any }) => <>{children}</>,
  disableEditMeme = false,
  copyLinkBasePath = config.deploymentUrl,
}: CommentPageProps): JSX.Element => {
  const classes = usePublicationDetailStyles();
  const isMobile = useWindowSize().width <= BREAKPOINT_TABLET_MEDIUM;
  const { isAuthenticated } = useAuth();

  const id = memeId;
  const [postDetails, setPostDetails] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    async function getPost() {
      try {
        const res = await DefaultYMCA.postService.getPostById(id);
        if (!res) navigate(redirectLinks.notFound);
        setPostDetails(res);
      } catch (err) {
        navigate(redirectLinks.notFound);
      }
    }
    id && getPost();
  }, [id, navigate]);

  useLayoutEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const [showPage, setShowPage] = useState<boolean>(isMobile ? false : true);

  return (
    <>
      {!isAuthenticated && isMobile && !showPage ? (
        <Dialog
          open={true}
          classes={{ root: classes.root }}
          className={classes.modal}
          style={{
            background: "rgba(14, 20, 34, 0.6)",
            backdropFilter: "blur(5px)",
          }}
        >
          <Box className={classes.paper}>
            <Box style={{ padding: 30 }}>
              <Typography
                className={classes.content}
                variant={"body1"}
                variantMapping={{ body1: "h3" }}
              >
                Sign up to see the content
              </Typography>
            </Box>
            <Box display='flex' className={classes.btnbox}>
              <Button
                className={classes.backbtn}
                onClick={() => setShowPage(true)}
              >
                Later
              </Button>
              <Button
                className={classes.changebtn}
                onClick={() => navigate("/auth")}
              >
                OK
              </Button>
            </Box>
          </Box>
        </Dialog>
      ) : (
        <PageLayout>
          <Box className={classes.container}>
            {!postDetails && <Spinner width={60} height={60} />}
            {postDetails && (
              <Publication
                post={postDetails}
                fontSize='26px'
                onDeletePublication={() => navigate(-1)}
                showComment
                disableModalToggle
                disableEditMeme={disableEditMeme}
                copyLinkBasePath={copyLinkBasePath}
              />
            )}
          </Box>
        </PageLayout>
      )}
    </>
  );
};

export default PublicationDetail;
