import { styled } from "@mui/material";
import { forwardRef, Ref } from "react";

const StyledVideo = styled("video", {
  name: "Video",
})``;

interface VideoProps {
  autoPlay?: boolean;
  controls?: boolean;
  children: any;
  muted?: boolean;
}

const Video = forwardRef(
  (
    {
      controls = true,
      autoPlay = true,
      muted = true,
      children,
      ...props
    }: VideoProps,
    ref: Ref<HTMLVideoElement>
  ) => {
    return (
      <StyledVideo
        {...props}
        controls={controls}
        autoPlay={autoPlay}
        muted={muted}
        ref={ref}
        playsInline
      >
        {children}
      </StyledVideo>
    );
  }
);

export default Video;
