import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";

import Icon from "components/atoms/Icons";
import useMenu from "hooks/useMenu";
import useCommunityKebabMenuDropdown from "./style";
import { CommunityRole } from "ymca/models/community-role.model";
import { useState } from "react";

interface CommunityKebabMenuDropdownProps {
  onSelect: (role: CommunityRole) => void;
}

const CommunityMemberFilterRoleMenuDropdown = ({
  onSelect,
}: CommunityKebabMenuDropdownProps) => {
  const classes = useCommunityKebabMenuDropdown();
  const [selectedRole, setSelectedRole] = useState<CommunityRole>("member");

  const {
    isOpen: isKebabMenuOpen,
    open: openKebabMenu,
    anchorEl: kebabMenuAnchorEl,
    close: closeKebabMenu,
  } = useMenu();

  const handleMenuOpenDropdown = (e: any) => {
    openKebabMenu(e);
  };
  const handleMenuCloseDropdwon = (e: any) => {
    closeKebabMenu();
  };

  const handleRightClick = (role: CommunityRole) => {
    setSelectedRole(role);
    onSelect(role);
    closeKebabMenu();
  };

  const filterList: { role: CommunityRole; name: string }[] = [
    {
      role: "member",
      name: "Members",
    },
    {
      role: "admin",
      name: "Admin",
    },
    {
      role: "moderator",
      name: "Moderators",
    },
    {
      role: "banned",
      name: "Banned",
    },
  ];

  return (
    <>
      <Button
        className={classes.KebabMenuBtn}
        onClick={handleMenuOpenDropdown}
        aria-controls={
          isKebabMenuOpen ? "manage-community-kebab-menu" : undefined
        }
        aria-haspopup='true'
        aria-expanded={isKebabMenuOpen ? "true" : undefined}
        id='manage-community-kebab-menu'
      >
        Filter by Rights
        <Icon icon='menu-dropdown-down' fontSize='.75rem' />
      </Button>

      <Menu
        anchorEl={kebabMenuAnchorEl}
        id='manage-community-kebab-menu'
        aria-labelledby='manage-community-kebab-menu'
        open={isKebabMenuOpen}
        onClose={handleMenuCloseDropdwon}
        className={classes.filterByRightsMenu}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
      >
        <Box
          className={`${classes.filterByRightMenuItemHeader} ${classes.kebabMenuMenuBorderBottom}`}
        >
          <Typography className={classes.kebabMenuMenuCopyTitle}>
            Rights
          </Typography>
          <Button
            className={classes.collapseFilterByRightMenuBtn}
            onClick={closeKebabMenu}
          >
            <Icon icon='menu-dropdown-up' fontSize='.75rem' />
          </Button>
        </Box>
        {filterList.map((filter, index) => {
          return (
            <MenuItem
              key={filter.role}
              className={`${classes.filterByRightMenuItem} ${
                selectedRole === filter.role &&
                classes.filterByRightMenuActiveItem
              }`}
              onClick={() => handleRightClick(filter.role)}
            >
              <Typography className={classes.kebabMenuSecondaryMenuText}>
                {filter.name}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default CommunityMemberFilterRoleMenuDropdown;
