import moment from 'moment'
import { useState } from 'react'
import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data'
import { Link } from 'react-router-dom'
import { Image } from 'components/shared/index'
import { makeStyles } from '@material-ui/core/styles'
import MemeToolbar from 'components/memes/MemeToolbar'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { VisibleIcon } from 'components/shared/SvgIcons'
import { Avatar, Box, IconButton, InputBase, Popover, Typography } from '@material-ui/core'
import { imagesSvg } from 'constants/images'
import { Post as PostModel } from 'ymca/models/post.model'
import { DEFAULT_AVATAR_IMAGE } from 'ymca/services/image.service'
import colors from 'theme/dark/colors'

const useStyles = makeStyles({
  container: {
    background: '#0C0E13',
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    minHeight: '100vh',
    zIndex: 2
  },
  header: {
    backgroundColor: '#1A1C22',
    padding: 15,
    display: 'flex',
    alignItems: 'center'
  },
  text1: {
    fontSize: 20,
    fontWeight: 800,
    marginLeft: 17
  },
  cardContainer: {
    width: '100%',
    height: 'calc(100% - 210px)',
    boxShadow: '0px 20px 45px rgba(0, 0, 0, 0.8)',
    borderRadius: '10px',
    background: '#151820',
    position: 'relative'
  },
  userAccount: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '10px 10px 0 0',
    backgroundColor: '#191E26',
    padding: '15px 15px 10px'
  },
  profileContainer: {
    display: 'flex',
    marginBottom: 10
  },
  avatar: {
    width: '50px',
    height: '50px',
    borderRadius: '19px',
    border: '4px solid #884BFF',
    boxSizing: 'border-box',
    '& img': {
      borderRadius: '19px',
      backgroundColor: colors.profileImageBackground
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  profile: {
    marginLeft: '18px'
  },
  center: {
    display: 'flex',
    alignItems: 'center'
  },
  name: {
    fontSize: '18px',
    fontWeight: 900,
    color: '#DFE1E6',
    marginRight: 3
  },
  attr: {
    color: '#959BAA',
    fontSize: 13,
    marginLeft: 6
  },
  time: {
    fontSize: '12px',
    color: '#6C7B93',
    fontWeight: 500
  },
  descContainer: {
    backgroundColor: '#191E26',
    padding: '0 15px 15px'
  },
  descTitle: {
    marginBottom: 10,
    color: '#DFE1E6',
    fontWeight: 900,
    fontSize: 20
  },
  description: {
    color: '#DFE1E6',
    fontSize: 15,
    fontWeight: 500
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    color: '#DFE1E6',
    padding: 15,
    paddingBottom: 0
  },
  tag: {
    fontSize: 12,
    fontWeight: 700,
    height: 24,
    padding: '0 10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#363E4A',
    borderRadius: 5,
    marginRight: 6,
    color: '#DFE1E6'
  },
  imgContainer: {
    position: 'relative',
    padding: '13px 15px'
  },
  screen: {
    borderRadius: 10,
    width: '100%'
  },
  viewCnt: {
    display: 'flex',
    padding: 5,
    alignItems: 'center',
    borderRadius: 7,
    backgroundColor: 'rgba(18, 20, 28, .6)',
    position: 'absolute',
    top: 20,
    left: 20
  },
  viewTxt: {
    fontSize: 11,
    fontWeight: 900,
    marginLeft: 5,
    color: '#fff'
  },
  inputContainer: {
    width: '100%',
    backgroundColor: '#1A1C22',
    padding: 10,
    height: 65,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    boxSizing: 'border-box'
  },
  emoji: {
    position: 'absolute',
    right: 10,
    top: 0
  },
  postContainer: {
    width: '100%'
  },
  input: {
    backgroundColor: '#2C333C',
    borderRadius: 7,
    fontSize: 14,
    color: '#E2E4E9',
    height: 45,
    paddingLeft: 15,
    boxSizing: 'border-box',
    width: '100%'
  },
  toolBtn: {
    width: 45,
    height: 45,
    backgroundColor: '#292F35',
    borderRadius: 7,
    marginLeft: 6
  },
  toolContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%'
  }
})

interface PostProps {
  postData: PostModel
  handlePost: (value: boolean) => void
  saved?: boolean
}

const Post = ({ postData, handlePost, saved }: PostProps): JSX.Element => {
  const classes = useStyles()

  const [comment, setComment] = useState<string>('')
  const [anchorEl, setAnchorEl] = useState(null)

  const avatar = postData.user?.avatar?.jpegUrl ?? DEFAULT_AVATAR_IMAGE
  const currentDateTime: moment.Moment = moment(new Date())
  const publicationDateTime: moment.Moment = moment(postData.createdAt)
  const memeSrc: string = postData.meme.renderedImage.urlOptimised1000x1000
  const memeFallbackSrc: string = postData.meme.renderedImage.jpegUrl

  const addEmoji = (e: any): void => {
    const emoji = e.native
    setComment(comment + emoji)
    setAnchorEl(null)
  }

  const handleClick = (event: any): void => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.postContainer}>
        <Box className={classes.header}>
          <IconButton onClick={() => handlePost(false)}>
            <ArrowBackIcon style={{ color: '#ADB3C0' }} />
          </IconButton>
          <Typography
              className={classes.text1}
              variant={'body1'}
              variantMapping={{body1: 'h1'}}
          >
            Post
          </Typography>
        </Box>
        <Popover
          id='SpaceListPop'
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          disableRestoreFocus
        >
          <Picker
            data={data}
            onEmojiSelect={addEmoji}
            previewPosition='none'
            theme='dark'
            autoFocus='true'
            skinTonePosition='none'
          />
        </Popover>
        <Box className={classes.cardContainer}>
          <Box>
            <Box className={classes.userAccount}>
              <Box className={classes.profileContainer}>
                <Link to={`/profile/${postData?.user?.username}`}>
                  <Avatar alt='user' src={avatar} className={classes.avatar} />
                </Link>
                <Box className={classes.profile}>
                  <Box className={classes.center}>
                    <Typography className={classes.name} component='span'>
                      {postData.user.displayName}
                    </Typography>
                    <Image alt="check" src={`headerIcons/${imagesSvg.checkIcon}`} />
                    <Typography className={classes.attr} component='span'>
                      {publicationDateTime.isSame(currentDateTime, 'day')
                        ? `Today @ ${publicationDateTime.format('hh:mm A')}`
                        : publicationDateTime.isSame(currentDateTime.subtract(1, 'days'), 'day')
                          ? `Yesterday @ ${publicationDateTime.format('HH:mm a')}`
                          : publicationDateTime.format('MM DD YYYY HH:mm a')}{' '}
                      &#183;
                      {45} Views
                    </Typography>
                  </Box>
                  <Typography className={classes.time}>@{postData.user.displayName}</Typography>
                </Box>
              </Box>
            </Box>
            <Box className={classes.descContainer}>
              <Typography className={classes.descTitle}>
                When you don't know what to type
              </Typography>
              <Typography className={classes.description}>
                When you don’t know what to type, haha, this is some more random text, to fill spot
                up and make sure this is filled us with text and more.
              </Typography>
            </Box>
            <Box className={classes.imgContainer}>
              <Image
                className={classes.screen}
                alt='screen'
                src={memeSrc}
                fallbackSrc={memeFallbackSrc}
                crossOrigin={"anonymous"}
              />
              <Box className={classes.viewCnt}>
                <VisibleIcon fill='#fff' />
                <Typography className={classes.viewTxt}>1.5M</Typography>
              </Box>
            </Box>
            <Box className={classes.tagsContainer}>
              {postData.tags.slice(0, 4).map((item: any) => (
                <Typography key={item} className={classes.tag}>
                  {item}
                </Typography>
              ))}
              {postData.tags.length > 4 && (
                <Typography className={classes.tag}>+{postData.tags.length - 3}</Typography>
              )}
            </Box>
          </Box>
          <Box className={classes.toolContainer}>
            <MemeToolbar post data={postData} bookmark={saved} />
            <Box className={classes.inputContainer}>
              <InputBase
                classes={{ root: classes.input }}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder='Send a comment...'
                endAdornment={
                  <IconButton onClick={(e) => handleClick(e)}>
                    <Image alt="smiling icon" src={`reactionIcons/${imagesSvg.smilingIcon}`} />
                  </IconButton>
                }
              />
              <IconButton className={classes.toolBtn}>
                <Image alt="gavel icon" src={`reactionIcons/${imagesSvg.gavelIcon}`} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Post
