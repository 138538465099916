import { forwardRef, useRef } from 'react'
import { Box, styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { Post as PostModel } from 'ymca/models/post.model'
import useModal from 'hooks/useModal'
import useOnScreen from 'hooks/useOnScreen'
import PublicationModal from '../PublicationModal/PublicationModal'
import PublicationMain from '../PublicationMain'
import PublicationProvider from 'context/PublicationContext'
import BookmarkSnackbar from './components/snackbars/BookmarkSnackbar'

export interface PublicationProp {
  post: PostModel
  fontSize?: string
  setEvent?: any
  modalPage?: boolean
  contestModal?: boolean
  showComment?: boolean
  onDeletePublication?: () => void
  disableEditMeme?: boolean
  disableModalToggle?: boolean
  copyLinkBasePath?: string
  handleImageOnLoad?: VoidFunction
}

const Wrapper = styled(Box)({
  width: '100%'
})

const BoxWrapper = forwardRef((props: { children: any }, ref: any) => {
  const { children } = props
  if (ref) {
    return (
      <Box id='infinite-scroll-trigger-box' ref={ref}>
        {children}
      </Box>
    )
  }
  return children
})

const Publication = forwardRef(
  (props: PublicationProp, ref: React.Ref<unknown>): JSX.Element => {
    const navigate = useNavigate()
    const { copyLinkBasePath, disableModalToggle, disableEditMeme } = props

    const publicationRef = useRef<HTMLDivElement>(null)

    let [isVisible] = useOnScreen({
      ref: publicationRef,
      rootMargin: '2px',
      threshold: 0.5
    })

    const { post, onDeletePublication, showComment } = props

    const { isOpen, close, toggle } = useModal()

    const onModalClose = () => {
      navigate(-1)
      close()
    }

    const onToggleModal = () => {
      if (!disableModalToggle) {
        // TODO: replace with useHistory of React-router v5
        window.history.pushState(
          null,
          '',
          `${copyLinkBasePath}/meme/${post.id}`
        )
        toggle()
      }
    }


    return (
      <PublicationProvider
        post={post}
        onDeletePublication={onDeletePublication!}
      >
        <BoxWrapper ref={ref}>
          <Wrapper
            data-post-id={post.id}
            data-test-post-id={post.id}
            data-test-post-created={post.createdAt}
            ref={publicationRef}
          >
            <PublicationMain
              {...props}
              onModalToggle={onToggleModal}
              onScreenProps={{
                isOnScreen: isVisible,
                modal: isOpen
              }}
              play={isVisible && !isOpen}
              showComment={showComment}
            />
          </Wrapper>
        </BoxWrapper>

        <PublicationModal
          isOpen={isOpen}
          onClose={onModalClose}
          post={post}
          disableEditMeme={disableEditMeme}
          copyLinkBasePath={copyLinkBasePath}
        />
        <BookmarkSnackbar />
      </PublicationProvider>
    )
  }
)

export default Publication
