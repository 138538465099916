import { base64UrlToJSON } from 'ymca/ymcaHelpers'
import { UserRole } from './user.model'

export type YouMemeJWTIssuer = 'youmeme'

export type YouMemeJWTAudience =
  | 'youmeme-website'
  | 'youmeme-admin-panel'
  | 'youmeme-all'

export const VALID_YOUMEME_JWT_AUDIENCE: YouMemeJWTAudience[] = ['youmeme-admin-panel', 'youmeme-website', 'youmeme-all']

export type YouMemeJWTSubject = 'registration' | 'login' | 'session' | 'auth'

export const VALID_YOUMEME_JWT_SUBJECT: YouMemeJWTSubject[] = ['registration', 'login', 'session', 'auth']

export interface YouMemeJWTUserData {
  email: string
  id?: string
  username?: string
  displayName?: string
  role?: UserRole
  sessionId?: string
}

export class YouMemeJWT {
  // issuer name
  public iss: YouMemeJWTIssuer = 'youmeme'

  // audience for this token
  public aud: YouMemeJWTAudience = 'youmeme-all'

  // creation timestamp in seconds from unix epoch
  public iat: number = Math.floor(Date.now() / 1000)

  // expiration timestamp in seconds from unix epoch
  public exp: number = Math.floor(Date.now() / 1000 + 60 * 60 * 24 * 7) // 7 days

  // subject; the purpose of this token
  public sub: YouMemeJWTSubject = 'auth'

  // email of the user this token is issued to
  public email: string = ''

  // userId of the user this token is issued to (when logged in)
  public id: string = ''

  // the sessionId this session refers to
  // this is used to prevent multiple logins into the SAME session
  public sessionId: string = ''

  // username of the user this token is issued to (when logged in)
  public username: string = ''

  // display name of the user this token is issued to (when logged in)
  public displayName: string = ''

  // role of the user this token is issued to (when logged in)
  public role: UserRole = 'user'

  // get token expiry as seconds from now
  public expiresIn (): number {
    return this.exp - Math.floor(Date.now() / 1000)
  }

  public needsRefresh (): boolean {
    return this.expiresIn() < 60 // we are 2 minutes from expiry
  }

  // check if the token is expired
  public isExpired (): boolean {
    const tokenExpired = this.expiresIn() <= 0
    return tokenExpired
  }

  public static fromTokenString (tokenString: string): YouMemeJWT {
    // Manually Parse a YouMeme JWT Token

    // Split into parts
    const parts = tokenString.split('.')
    if (parts.length !== 3) {
      const msg = `Invalid JWT token: ${tokenString} (wrong number of parts)`
      throw new Error(msg)
    }

    // We are only interested in the payload
    const data = base64UrlToJSON(parts[1])
    if (data === null) {
      const msg = `Invalid JWT token: ${tokenString} (invalid payload)`
      throw new Error(msg)
    }

    // Create a new YouMemeJWT object to hold the token information
    const token = new YouMemeJWT()

    // Extract the sub
    const sub = data.sub as YouMemeJWTSubject
    if (!VALID_YOUMEME_JWT_SUBJECT.includes(sub)) {
      const msg = `Invalid subject: ${sub}`
      throw new Error(msg)
    }
    token.sub = sub

    // Extract the aud
    const aud = data.aud as YouMemeJWTAudience
    if (!VALID_YOUMEME_JWT_AUDIENCE.includes(aud)) {
      const msg = `Invalid audience: ${aud}`
      throw new Error(msg)
    }
    token.aud = aud

    // Extract the iss
    const iss = data.iss as YouMemeJWTIssuer
    if (iss !== 'youmeme') {
      const msg = `Invalid issuer: ${iss as string}`
      throw new Error(msg)
    }
    token.iss = iss

    // extract the sessionId
    const sessionId = data.sessionId as string
    if (sessionId === undefined) {
      const msg = `Invalid sessionId: ${sessionId as string}`
      throw new Error(msg)
    }
    token.sessionId = sessionId

    // Extract the email
    const email = data.email as string
    if (email === undefined) {
      const msg = `Invalid email: ${email as string}`
      throw new Error(msg)
    }
    token.email = email

    // Extract the id
    const id = data.id as string
    if (id === undefined && token.sub !== 'registration') {
      const msg = `Invalid id: ${id as string}`
      throw new Error(msg)
    }
    token.id = id

    // Extract the username
    const username = data.username as string
    if (username === undefined && token.sub !== 'registration') {
      const msg = `Invalid username: ${username as string}`
      throw new Error(msg)
    }

    // Extract the displayName
    const displayName = data.displayName as string
    if (displayName === undefined && token.sub !== 'registration') {
      const msg = `Invalid displayName: ${displayName as string}`
      throw new Error(msg)
    }
    token.displayName = displayName

    // Extract the role
    const role = data.role as UserRole
    if (role === undefined && token.sub !== 'registration') {
      const msg = `Invalid role: ${role as string}`
      throw new Error(msg)
    }
    token.role = role

    // Extract the exp
    const exp = data.exp as number
    if (typeof exp !== 'number') {
      const msg = `Invalid exp: ${exp as string}`
      throw new Error(msg)
    }
    token.exp = exp

    // Extract the iat
    const iat = data.iat as number
    if (typeof iat !== 'number') {
      const msg = `Invalid iat: ${iat as string}`
      throw new Error(msg)
    }
    token.iat = iat

    // Return the token
    return token
  }
}
