import { Link } from 'react-router-dom'
import { Image } from 'components/shared/index'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { imagesPng } from 'constants/images'
import { redirectLinks } from 'constants/redirectLinks'

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 50,
    '& img': {
      borderRadius: 10,
      width: '100%'
    }
  },
  textContainer: {
    marginTop: 80,
    textAlign: 'center'
  },
  code: {
    fontSize: 100,
    color: 'white',
    textShadow: '6px 4px #737373'
  },
  desc: {
    fontSize: 24,
    color: 'white',
    minWidth: '90%'
  },
  refreshLink: {
    fontSize: 20,
    color: 'green'
  }
})

const ServerNotRespond = (): JSX.Element => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Image src={`random/${imagesPng.unavailable}`} alt="unavailable" />
      <Box className={classes.textContainer}>
        <Typography className={classes.code}>503</Typography>
        <Typography className={classes.desc}>Sorry! Service temporary unavailable.</Typography>
        <Typography className={classes.refreshLink}>Click here to <Link to={`/${redirectLinks.homePage}`}>refresh</Link></Typography>
      </Box>
    </Box>
  )
}

export default ServerNotRespond
