import { makeStyles } from '@material-ui/styles'

import { getAssetsPath } from 'utils/path'
import { imagesSvg } from 'constants/images'
import colors from 'theme/dark/colors'
import {
  BREAKPOINT_MOBILE_LARGE,
  BREAKPOINT_TABLET_MEDIUM
} from 'theme/shared/breakpoint'

const useFileUploadStyles = makeStyles({
  root: {
    fontSize: '1rem',
    display: 'flex',
    gap: '1rem',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    height: '79%'
  },
  otherOptionsWrapper: {
    height: '100%',
    flexBasis: '40%',
    display: 'flex',
    flexDirection: 'column'
  },
  line: {
    display: 'none'
  },
  moreOptionBtn: {
    border: 'none',

    padding: '1rem',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '18px',
    letterSpacing: '-0.6px',
    background: '#120717',
    color: 'white',
    width: '100%',
    borderRadius: '6.25rem',
    boxShadow: '0px 4px 10px rgba(18, 7, 23, 0.5)',
    backdropFilter: 'blur(15px)',
    position: 'relative',

    display: 'none',
    [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
      display: 'block'
    }
  },
  onTemplateSelectLoader: {
    'backdrop-filter': 'blur(10px)',
    height: '100%',
    width: '102%',
    marginLeft: '-5px',
    zIndex: 10,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    '& p': {
      fontWeight: 600,
      color: colors.greyScale500,
      textShadow: '1px'
    }
  },
  additionalText: {},
  dragwrapper: {
    background: 'transparent',
    boxSizing: 'border-box',
    borderRadius: 5,
    flexBasis: '56.02%',
    height: '56.02%',
    fontSize: '1em',
    marginBottom: 12,
    border: '2px dashed rgba(138, 152, 180, 0.5)',
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    opacity: 0,
    animation: '$appear 200ms 500ms ease both',
    flexGrow: 1,
    cursor: 'pointer'
  },

  dragged: {
    backgroundColor: '#f0f8ff'
  },
  uploadBtn: {
    width: 209,
    height: 44,
    background: '#791EF0',
    borderRadius: 5,
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: 14,
    lineHeight: '17px',
    color: '#FFFFFF',
    marginTop: 175,
    marginBottom: 6
  },
  dragTitle: {
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '0.8135rem',
    lineHeight: '1rem',
    pointerEvents: 'none'
  },
  dragFormat: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.8135rem',
    lineHeight: '1rem',
    pointerEvents: 'none',
    marginTop: '10px'
  },
  dragTxtOne: {
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '1.25rem',
    lineHeight: '1.5125rem',
    pointerEvents: 'none',
    color: colors.gradientPurpleLight,
    marginTop: '0.6rem',
    cursor: 'pointer'
  },
  dragTxtTwo: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    color: '#BA98B4',
    pointerEvents: 'none'
  },
  tabBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '0.625rem'
  },
  tabItem: {
    padding: 10,
    height: '3rem',
    background: colors.darkBlue50,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden !important',
    boxSizing: 'border-box',
    textAlign: 'justify',
    cursor: 'pointer',
    transition: 'transform 300ms ease, opacity 200ms ease',
    position: 'relative',
    fontSize: '0.875em',
    animation:
      '$riseUp var(--delay) 200ms ease-in-out both running, gradient 10s ease infinite paused',

    '& > svg': {
      fontSize: '1.5em !important',
      opacity: 0,
      // transform: "translateX(-1em)",
      transition: 'opacity 300ms ease, transform 300ms ease-in'
    },

    '& > p': {
      transition: 'transform 400ms ease-out',
      transform: 'translateX(0em)',
      fontSize: '1em'
    },

    '&:hover': {
      background: colors.purpleGradient,
      backgroundSize: '400% 400%',
      animationPlayState: 'paused, running'
    },

    '&:hover svg': {
      transitionDuration: '100ms, 150ms',
      transitionTimingFunction: 'ease, ease-out',
      opacity: 1,
      transform: 'translateX(0)'
    },

    '&:hover p': {
      transitionDuration: '150ms'
      // transform: "translateX(1em)",
    }
  },
  delayPoint1Sec: {
    '--delay': '.1s'
  },
  delayPoint2Sec: {
    '--delay': '.2s'
  },
  delayPoint3Sec: {
    '--delay': '.3s'
  },
  delayPoint4Sec: {
    '--delay': '.4s'
  },

  '@keyframes riseUp': {
    from: {
      opacity: 0,
      transform: 'translateY(3em)'
    },

    to: {
      opacity: 1,
      transform: 'translateY(0)'
    }
  },

  memeCamFileUploadWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },

  memeCamButton: {
    padding: 10,
    height: '3rem',
    background: colors.darkBlueActive,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    textAlign: 'justify',
    cursor: 'pointer',
    transition: 'transform 300ms ease, opacity 200ms ease',
    position: 'relative',
    fontSize: '0.875em',
    animation:
      '$riseUp var(--delay) 200ms ease-in-out both running, gradient 10s ease infinite paused',
    '&:hover': {
      background: colors.purpleGradient,
      backgroundSize: '400% 400%',
      animationPlayState: 'paused, running'
    }
  },
  memeCamButtonTitle: {
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '1.5em',
    fontSize: '1.25rem'
  },

  memeCamGenerateWrapper: {
    display: 'flex',
    width: '100%',
    marginTop: '1.5rem'
  },
  memeCamGridWrapper: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
    gridAutoRows: '230px',
    padding: '0.5rem',
    gap: '.5rem',
    overflowY: 'scroll !important',
    '&::-webkit-scrollbar': {
      width: 6,
      borderRadius: 20,
      backgroundColor: '#0E1017',
      cursor: 'pointer'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colors.memeGenBlue50 + ' !important',
      borderRadius: 20,
      width: 6
    },

    [`@media (max-width: ${BREAKPOINT_MOBILE_LARGE}px)`]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
      gridAutoRows: '220px'
    }
  },

  memecamItemWrapper: {
    outline: `0px solid ${colors.gradientPurple}`,
    borderRadius: '.5rem',
    '&:hover': {
      outlineWidth: 3
    }
  },

  memeCamText: {
    marginBottom: '1.5rem',
    fontWeight: 800,
    fontSize: '1.1rem'
  },

  memeCaption: {
    position: 'absolute',
    fontFamily: 'Anton',
    fontSize: '1rem',
    color: 'white',
    textTransform: 'uppercase',
    textShadow:
      'rgb(0, 0, 0) -1px -1px 1px, rgb(0, 0, 0) 1px -1px 1px, rgb(0, 0, 0) -1px 1px 1px, rgb(0, 0, 0) -1px -1px 1px',
    textAlign: 'center',
    cursor: 'pointer'
  },
  memeCaptionTop: {
    top: 4,
    left: 0,
    width: '100%'
  },
  memeCaptionBottom: {
    bottom: 4,
    left: 0,
    width: '100%'
  },
  // Small text, centered vertically, pulled to the right
  attemptsInfo: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '0.5rem',
    fontSize: '0.75rem',
    color: colors.greyScale500,
    marginTop: '0.5rem',
    width: '100%'
  },

  tabTitle: {
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '1.5em',
    marginRight: '1.5em'
  },
  tabDesc: {
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#546277'
  },
  searchMemeWrapper: {
    display: 'flex',
    flexBasis: '73.11%',
    flexDirection: 'column',

    '& main': {
      width: '100%',
      height: '100%',

      '& > h5': {
        margin: '23px 0 12px 0',
        fontSize: '1em',
        lineHeight: '1.5em'
      },

      '& > div': {
        height: 'calc(100% - 60px)',
        paddingRight: '0.5em',
        paddingTop: '1em',
        paddingLeft: '.5em',
        placeItems: 'baseline',

        '&::-webkit-scrollbar': {
          width: 6,
          borderRadius: 20,
          backgroundColor: '#0E1017'
        },

        '&::-webkit-scrollbar-thumb': {
          background: colors.purpleGradient,
          borderRadius: 20
        },

        '& .template-wrapper': {
          height: '132px',
          position: 'relative',
          transition: 'outline-width 100ms ease',
          outline: `0px solid ${colors.gradientPurple}`,
          overflow: 'hidden !important',
          backdropFilter: 'brightness(110%)',
          borderRadius: 10,

          '&:hover': {
            outlineWidth: 3
          },

          '& img': {
            zIndex: 2,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: 'inherit',
            cursor: 'pointer',
            filter: 'brightness(110%)'
          }
        }
      }
    }
  },

  '@keyframes appear': {
    to: {
      opacity: 1
    }
  },

  searchForm: {
    width: 'calc(100% - 1.2em)',
    height: '3.143em',
    fontSize: '0.875em',
    borderRadius: 7,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '1em'
  },

  searchBar: {
    fontSize: '1em',
    height: '100%',
    borderRadius: 'inherit',
    background: colors.darkBlue50,
    paddingLeft: 'calc(1.18em + 24.5px)',
    color: '#919AA5',
    fontWeight: 600,
    backgroundPosition: '1.18em center',
    lineHeight: '1.714em',
    backgroundImage: `url(${getAssetsPath(
      `checkIcons/${imagesSvg.lookingGlass}`
    )}))`,
    backgroundRepeat: 'no-repeat',
    border: '0px solid var(--gradient-fall-back)',
    transition: 'border-width 100ms ease, filter 100ms',
    boxSizing: 'border-box',
    flexBasis: '80%',

    '& + div': {
      flexBasis: '20%',
      borderRadius: 'inherit',
      width: '100%',

      '& > button': {
        width: '100%',
        height: '100%',
        textAlign: 'center',
        background: colors.darkBlue50,
        padding: '.75em',
        color: 'white',
        border: 'none',
        borderRadius: 'inherit',
        backdropFilter: 'brightness(80%)',
        position: 'relative',
        zIndex: 1,
        margin: 0,
        transition: 'background 200ms, color 150ms',

        '& svg': {
          fontSize: '1.7em !important',
          position: 'absolute',
          zIndex: -1,
          opacity: 0,
          transform: 'translateY(100%)',
          transition: 'transform 200ms ease-in, opacity 150ms'
        },

        '&:disabled': {
          filter: 'brightness(40%)'
        }
      },

      '&:disabled': {
        filter: 'brightness(40%)'
      },

      '&:focus': {
        borderWidth: '5px',
        border: '5px solid var(--gradient-fall-back)',
        borderImage: 'var(--main-gradient) 5',
        color: '#fff'
      },

      '&:hover > button': {
        transitionDelay: '5ms',
        backdropFilter: 'none',
        color: 'transparent',
        animation: 'gradient 10s ease infinite',
        backgroundSize: '400% 400%',
        background: colors.purpleGradient,

        '& svg': {
          transitionTimingFunction: 'ease-out, ease',
          transitionDuration: '150ms, 150ms',
          opacity: 1,
          transform: 'translateY(0)'
        }
      }
    }
  },
  randomMobile: {
    display: 'none'
  },
  placeholders: {
    display: 'none'
  },
  btnsContainer: {
    marginTop: '18px',
    display: 'flex',
    gap: '0.7rem'
  },
  button: {
    background: colors.darkBlueActive,
    width: '147px',
    height: '46px',
    boxShadow: '0px 4px 8px rgba(14, 20, 34, 0.2)',
    borderRadius: '10px',
    color: '#8A98B4',
    letterSpacing: '-0.7px',
    lineHeight: '19px',
    fontSize: '15px',
    fontWeight: 600,
    position: 'relative',
    '&.selected': {
      background: colors.purpleGradient,
      color: colors.white,
      fontWeight: 700
    },
    '&.Mui-disabled': {
      color: colors.gray
    }
  },
  ButtonLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: '-0.6rem',
    top: '-0.6rem',
    fontSize: '0.7rem',
    borderRadius: '0.4rem',

    color: colors.skyBlue,
    background: colors.darkBlueActive,
    padding: '0.07rem 0.6rem',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    backdropFilter: 'blur(50px)'
  },
  '@media (max-width: 1160px)': {
    otherOptionsWrapper: {
      fontSize: '.8em'
    },
    tabTitle: {
      marginRight: '2em'
    },

    uploadBtn: {
      marginTop: 87,
      height: 40
    }
  },
  '@media (max-height: 700px)': {
    otherOptionsWrapper: {
      height: '88%',
      overflowY: 'scroll'
    }
  },
  memes: {
    padding: 'padding: 1rem 0 !important',
    '& img': {
      borderRadius: '0.938rem',
      width: '100%',
      height: 'auto',
      marginBottom: 10
    }
  },
  [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
    memeCamButtonTitle: {
      fontSize: '1rem'
    },
    memes: {
      '& img': {
        borderRadius: '0.938rem',
        width: '100%',
        height: 'auto',
        marginBottom: 10
      }
    },
    placeholders: {
      width: '100%',
      '& img': {
        borderRadius: 15,
        width: '100%',
        height: 'auto'
      }
    },
    additionalText: {
      fontSize: '18px',
      fontWeight: 800,
      marginBottom: '16px'
    },
    tabBox: {
      //height: 400,
      justifyContent: 'flex-end',
      padding: '2rem',
      background: 'rgba(18, 7, 23, 0.9)',
      backdropFilter: 'blur(15px)',
      borderRadius: '20px 20px 0px 0px',
      position: 'absolute',
      bottom: 0,
      width: '100%',
      gap: '10px'
    },
    tabItem: {
      height: '107px',
      background: 'linear-gradient(90deg, #DC01FF 0%, #7402FF 81.25%);',
      backdropFilter: 'blur(15px)',
      borderRadius: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '30px',
      '& img': {
        position: 'absolute',
        right: 0
      },
      '& span': {
        color: 'rgba(255, 255, 255, 0.7)',
        fontWeight: 800,
        fontSize: '11px'
      },
      '& i': {
        opacity: 1
      }
    },
    tabTitle: {
      fontWeight: 800,
      fontSize: '20px',
      lineHeight: '24px'
    },
    root: {
      flexDirection: 'column',
      justifyContent: 'unset',
      xpadding: '0px !important',
      '& > button': {
        display: 'unset'
      }
    },
    randomDesktop: {
      display: 'none'
    },
    randomMobile: {
      display: 'flex',
      borderRadius: '6.75rem !important',
      width: '44px !important',
      height: '44px !important',
      background: 'rgba(255, 255, 255, 0.3) !important',
      backdropFilter: 'blur(7.5px)',
      '&:focus': {
        background: 'rgba(255, 255, 255, 0.3) !important',
        backdropFilter: '0px'
      }
    },
    searchBar: {
      '& + div': {
        display: 'grid',
        placeItems: 'center',
        justifyContent: 'end',
        flexBasis: '0%',
        '& > button': {
          '& svg': {
            fontSize: 'unset',
            position: 'relative',
            zIndex: 'unset',
            opacity: 1,
            transform: 'none',
            width: '22px',
            height: '22px'
          }
        }
      },

      flexBasis: '100%',
      width: '100%',
      fontSize: '14px',
      borderRadius: '100px',
      background: 'rgba(255, 255, 255, 0.3)',
      backdropFilter: 'blur(7.5px)',
      paddingTop: '15px',
      paddingBottom: '15px',
      color: 'white',
      '& ::placeholder': {
        color: 'white',
        zIndex: 11,
        opacity: 1,
        fontWeight: 500
      },
      '& > input': {
        padding: '0'
      }
    },

    switchButton: {
      padding: '16px',
      fontFamily: 'Inter',
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '18px',
      letterSpacing: '-0.6px',
      background: '#120717',
      color: 'white',
      width: '100%',
      borderRadius: '6.25rem',
      marginBottom: '1.875rem !important',
      boxShadow: '0px 4px 10px rgba(18, 7, 23, 0.5)',
      backdropFilter: 'blur(15px)',
      position: 'relative'
    },
    otherOptionsWrapper: {
      fontSize: '2.5vmax',
      flexGrow: 1,
      width: '100%',
      position: 'absolute',
      zIndex: -1
    },

    searchForm: {
      width: '100%',
      gap: '8px',
      height: 'unset'
    },

    dragwrapper: {
      flexBasis: '41%'
    },
    searchMemeWrapper: {
      '& > main,  & .meme-grid-wrapper': {
        height: 'unset',
        paddingRight: '0 !important'
      }
    },
    btnsContainer: {
      width: '100%',
      height: '54px',
      background: 'rgba(255, 255, 255, 0.3)',
      borderRadius: '100px',
      gap: '0'
    },
    button: {
      width: '50%',
      height: '45px',
      margin: '4px',
      borderRadius: '100px',
      boxShadow: 'none',
      fontSize: '14px',
      color: '#E7B8FF',
      border: 'none',
      background: 'none',
      '&.selected': {
        background: colors.white,
        color: colors.black80,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        fontWeight: 700,
        '&.Mui-disabled': {
          background: colors.white,
          opacity: '0.5'
        }
      }
    },
    mostUsed: {
      display: 'none'
    }
  },
  '@media (max-width: 550px)': {
    dragwrapper: {
      display: 'none'
    },
    ButtonLabel: {
      right: '-0.3rem',
      top: '-1rem'
    }
  },
  '@media (max-width: 370px)': {
    searchForm: {
      flexDirection: 'row',
      height: '100px'
    },
    searchBar: {
      width: '100%'
    }
  }
})

export default useFileUploadStyles
