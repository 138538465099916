// FIXME / TODO: Use Zod to safely read env variables
// https://sergiodxa.com/articles/using-zod-to-safely-read-env-variables

export class YMCAConfig {
  public ethRpcEndpoint: string =
    process.env.REACT_APP_LENS_ENVIRONMENT === 'production'
      ? '	https://polygon-rpc.com/'
      : 'https://rpc-mumbai.maticvigil.com'
  public deploymentUrl: string = process.env.REACT_APP_DEPLOYMENT_URL ?? ''
  public lensEnvironment: string =
    process.env.REACT_APP_LENS_ENVIRONMENT ?? 'development'
  public lensProxy: string =
    process.env.REACT_APP_LENS_PROXY ?? 'http://localhost:4002'
  public apiUrl: string = process.env.REACT_APP_API_ENDPOINT ?? ''
  public disableYMCADebugger: boolean =
    process.env.REACT_APP_DISABLE_DEBUGGER === 'true'
  public oldestAllowedSessionStartTime: number = parseInt(
    process.env.REACT_APP_OLDEST_ALLOWED_SESSION_START_TIME ?? '0'
  )
  public lensAppId: string = process.env.REACT_APP_LENS_APP_ID ?? 'youmeme'
}
