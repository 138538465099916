import styled, { Keyframes, keyframes } from 'styled-components'

const rotationAnimation: Keyframes = keyframes`
  from {
      transform: translate(-50%, -50%) rotate(0)
    }

  to {
      transform: translate(-50%, -50%) rotate(1turn)
  }
`

const Loading = styled.div.attrs({
  className: 'loading-spinner'
})`
  --default-border-width: 8px;
  --default-loader-size: 60px;
  --default-border-background: var(--gradient-fall-back-h), var(--gradient-fall-back-s),
    var(--gradient-fall-back-l);

  width: var(--loader-size, var(--default-loader-size));
  height: var(--loader-size, var(--default-loader-size));

  border-radius: 50%;
  position: absolute;

  border: var(--border-width, var(--default-border-width)) solid
    hsla(var(--border-background, var(--default-border-background)), 0.3); /* Background color */

  border-top: var(--border-width, var(--default-border-width)) solid
    hsla(var(--border-background, var(--default-border-background)), 0.8); /* Animated color */

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ${rotationAnimation} 1s infinite ease-in-out;
`

export default Loading
