import App from 'App'
import './styles/index.css'
import { StrictMode } from 'react'
import { render } from 'react-dom'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter as Router } from 'react-router-dom'
import { registerSW, unregisterSW } from "./registerSW"
import config from "./utils/config";
import { Buffer } from 'buffer'

window.Buffer = window.Buffer || Buffer

render(
  <StrictMode>
    <Toaster gutter={8} position='top-center' toastOptions={{ className: 'toasts' }} />
    <Router>
      <App />
    </Router>
  </StrictMode>,
  document.getElementById('root'),
  () => {
    if (config.serviceWorkerEnabled) {
        console.log("Service worker enabled.")
        registerSW();
    } else {
        console.log("Service worker disabled. Unregistering any existing service worker.")
        unregisterSW();
    }
  }
)
