import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Toolbar,
  Typography
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { forwardRef } from 'react'

import { Spinner } from 'components/loading/LoadingSpinner'
import useWindowSize from 'hooks/useWindowSize'
import PublicationMain from '../PublicationMain'
import { Post } from 'ymca/models/post.model'
import {
  BREAKPOINT_LAPTOP,
  BREAKPOINT_LAPTOP_SMALL,
  BREAKPOINT_TABLET_LARGE
} from 'theme/shared/breakpoint'
import config from 'utils/config'
import DesktopMemeModal from './desktop-modal'

import usePublicationMobileModalStyles from './style'
import Icon from 'components/atoms/Icons'

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='right' ref={ref} {...props} />
})

export interface PublicationModalProp {
  post: Post
  isOpen: boolean
  onClose: () => void
  disableEditMeme?: boolean
  copyLinkBasePath?: string
}

const PublicationModal = (props: PublicationModalProp): JSX.Element => {
  const classes = usePublicationMobileModalStyles()
  const {
    isOpen,
    onClose,
    post,
    disableEditMeme = false,
    copyLinkBasePath = config.deploymentUrl
  } = props

  const { width } = useWindowSize()
  const isMobile = width <= BREAKPOINT_LAPTOP

  if (!isOpen) return <></>

  if (isMobile) {
    return (
      <Dialog
        open={isOpen}
        className={classes.dialog}
        onClose={onClose}
        aria-labelledby='comment-modal-mobile'
        aria-describedby='comment page modal mobile'
        fullScreen
        TransitionComponent={Transition}
      >
        <Box className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={onClose}
              aria-label='close-meme-comment-modal'
              className={classes.closeBtn}
            >
              <Icon icon='arrow-left' fontSize='2rem' />
            </IconButton>
            <Typography className={classes.toolbarTitle}>Comments</Typography>
          </Toolbar>
        </Box>
        <DialogContent className={classes.dialogContent}>
          {post ? (
            <PublicationMain
              play
              post={post}
              headingType='h2'
              fontSize='1rem'
              showComment
              disableEditMeme={disableEditMeme}
              copyLinkBasePath={copyLinkBasePath}
              modalPage
            />
          ) : (
            <Box className={classes.loadContainer}>
              <Spinner width={50} height={50} />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    )
  }
  return (
    <DesktopMemeModal
      onClose={onClose}
      isOpen={isOpen}
      disableEditMeme={disableEditMeme}
      copyLinkBasePath={copyLinkBasePath}
      post={post}
    />
  )
}

export default PublicationModal
