import useShow from "hooks/useShow";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

interface HeaderCreateMemeButtonWrapperProps {
  children: any;
}

const HeaderCreateMemeButtonWrapper = ({
  children,
}: HeaderCreateMemeButtonWrapperProps) => {
  const location = useLocation();
  const { isShowing: isCreateMemeShowing, toggle: toggleHeaderCreateMeme } =
    useShow(true);

  useEffect(() => {
    if (location?.pathname?.split?.("/")?.[1] === "communities") {
      if (isCreateMemeShowing) {
        toggleHeaderCreateMeme();
      }
    } else {
      if (!isCreateMemeShowing) {
        toggleHeaderCreateMeme();
      }
    }
  }, [location.pathname]);

  if (!isCreateMemeShowing) return null;

  return children;
};
export default HeaderCreateMemeButtonWrapper;
