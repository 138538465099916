export const imagesPng = {
  backdrop: 'BACKDROP.png',
  meme1: 'MEME1.png',
  emailSend: 'email_send.png',
  sponsor: 'sponsor.png',
  fire: 'noto_fire.png',
  userDiscover: 'userdiscoverimage.png',
  cover: 'cover.png',
  profile: 'profile.png',
  user1: 'user1.png',
  user2: 'user2.png',
  user3: 'user3.png',
  user4: 'user4.png',
  explorer: 'explorer.png',
  unavailable: '503.png',
  arrowDown: 'bottom.png',
  arrowUp: 'up.png',
  user: 'user.png',
  aboutBackground: 'aboutBackground.png',
  contestBanner: 'Banners-02.jpg',
  newContestBanner: 'contest-banner.png',
  ribbon: 'ribbon.png',
  contestcup: 'contestcup.png',
  smallDiamond: 'smallDiamond.png',
  largeDiamond: 'largeDiamond.png',
  mediumDiamond: 'mediumDiamond.png',
  megaPhone: 'megaphone.png'
}

export const imagesJpg = {
  noEvents: 'noevents.jpg',
  brettZiegler: 'Brett-Ziegler.jpg',
  banner: 'banner.jpg',
  wip: 'wip.jpg',
  eventNew: 'eventnew.jpg',
  sponsor: "sponsored.jpg",
  referralBanner:"referralBanner.jpg",
  smallDiamond:"smallDiamond.jpg",

}

export const imagesWebp = {
  login:'loginBanner.webp'
}

export const imagesSvg = {
  memeCreator: 'memeCreator.svg',
  animation: 'animation.svg',
  memeTeam: 'memeTeam.svg',
  arrowRight: 'arrowRight.svg',
  arrowLeft: 'arrowLeft.svg',
  lightning: 'lightning.svg',
  resend: 'resend.svg',
  gmailIcon: 'gmail-icon.svg',
  envelope: 'Envelope.svg',
  prevArrow: 'prev_arrow.svg',
  nextArrow: 'next_arrow.svg',
  profileIcon: 'profile.svg',
  calendarIcon: 'icon_calendar.svg',
  visaIcon: 'visa.svg',
  googleCalendar: 'google_calender.svg',
  eventIcon: 'event.svg',
  facebookConnect: 'facebookconnect.svg',
  twitterConnect: 'twitterconnect.svg',
  logoNotActual: 'logonotactual.svg',
  logo: 'youmeme-logo.svg',
  dogLogo: 'ym-dog-logo.svg',
  bellIcon: 'bell.svg',
  settingIcon: 'iconsettings.svg',
  sidebarsetting: 'settingsidebar.svg',
  nextIcon: 'next.svg',
  beforeIcon: 'before.svg',
  editText: 'edittext.svg',
  likeIcon: 'like.svg',
  superLikeIcon: 'superlike.svg',
  hotIcon: 'hot.svg',
  intriguingIcon: 'intriguing.svg',
  copyLink: 'copylink.svg',
  mobileTwitter: 'mobiletwitter.svg',
  mobileFacebook: 'mobilefacebook.svg',
  mobileWhatsapp: 'mobilewhatsapp.svg',
  mobileLinkedin: 'mobilelinkedin.svg',
  mobilePinterest: 'mobilepinterest.svg',
  mobileTelegram: 'mobiletelegram.svg',
  mobileReddit: 'mobilereddit.svg',
  mobileVk: 'mobilevk.svg',
  checkIcon: 'check.svg',
  noteIcon: 'note.svg',
  newLikeIcon: 'new_like.svg',
  user2Icon: 'user2.svg',
  emojiIcon: 'emoji.svg',
  twitterShare: 'twittershare.svg',
  fbIcon: 'fb.svg',
  redditIcon: 'reddit.svg',
  whatsappIcon: 'whatsapp.svg',
  linkedinIcon: 'linkedin.svg',
  pinterestIcon: 'pinterest.svg',
  telegramIcon: 'telegram.svg',
  vkIcon: 'vk.svg',
  verifiedNewIcon: 'verifiednew.svg',
  optionIcon: 'optionicon.svg',
  homeIcon: 'home.svg',
  giftIcon: 'dicover.svg',
  plusIcon: 'plus.svg',
  notificationIcon: 'notification.svg',
  arrowBackBlack: 'arrowbackblack.svg',
  chevronRightIcon: 'chevronright.svg',
  closeFollow: 'closefollow.svg',
  verifiedIcon: 'verified.svg',
  locationIcon: 'location.svg',
  invalidDataIcon: 'invalid_data_icon.svg',
  validDataIcon: 'valid_data_icon.svg',
  doubleTick: 'double_tick.svg',
  closeIcon: 'close.svg',
  mainLogo: 'main_logo.svg',
  smilingIcon: 'smiling.svg',
  gavelIcon: 'gavel.svg',
  illustrationIcon: 'illustration.svg',
  eventStar: 'eventstar.svg',
  linkIcon: 'linkicon.svg',
  resourceIcon: 'resourceicon.svg',
  twitterResourceLink: 'twitterresourcelink.svg',
  facebookShare: 'facebookshare.svg',
  redditShare: 'redditshare.svg',
  whatsappShare: 'whatsappshare.svg',
  inShare: 'inshare.svg',
  pinterestShare: 'pinterestshare.svg',
  telegramShare: 'telegramshare.svg',
  vkShare: 'vkshare.svg',
  maintenanceIcon: 'maintenance.svg',
  accountIcon: 'account.svg',
  minusCircle: 'minus-circle.svg',
  icon404: '404.svg',
  topIcon: 'arrow_up_icon.svg',
  homeNewIcon: 'homenew.svg',
  userNewIcon: 'usernew.svg',
  telescopeNewIcon: 'telescopenew.svg',
  categoryNewIcon: 'categorynew.svg',
  personIcon: 'person.svg',
  starIcon: 'star.svg',
  moreIcon: 'more.svg',
  eventsIcon: 'events.svg',
  settingsIcon: 'settings.svg',
  feedbackIcon: 'feedback.svg',
  logIcon: 'log.svg',
  inviteIcon: 'invite.svg',
  moonIcon: 'moon.svg',
  logoutIcon: 'logout.svg',
  closeBtnIcon: 'closeBtn.svg',
  twitterIcon: 'twittericon.svg',
  facebookIcon: 'facebookicon.svg',
  linkedinIconSvg: 'linkedinicon.svg',
  blobIcon: 'blob.svg',
  lookingGlass: 'looking_glass.svg',
  externalLInk: 'externalLink.svg',
  person: 'PROFILEIMAGE.svg',
  reddit: 'Reddit.svg',
  inshare: 'insta.svg',
  advance: 'advanced.svg',
  memecamImg: 'memeCamImg.svg',
  createMemeImg: 'createMemeImg.svg',
  faceSwapImg: 'faceSwapImg.svg',
  textToMemeImg: 'textToMemeImg.svg'
}

export const gifs = {
  spinner: 'spinner.gif',
  shiba: 'shiba2.gif',
  lovelyCat:'lovelyCat.gif'
}
