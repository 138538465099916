import { ExternalProvider } from "@ethersproject/providers";

export enum ChainId {
  MAINNET = 137,
  TESTNET = 80001,
}
export const NETWORK_CONFIG = {
  [ChainId.MAINNET]: {
    name: "Polygon Mainnet",
    nativeCurrency: "MATIC",
    symbol: "MATIC",
    decimals: 18,
    scanURL: "https://polygonscan.com",
    rpc: "https://polygon-rpc.com/",
  },
  [ChainId.TESTNET]: {
    name: "Polygon Testnet",
    nativeCurrency: "MATIC",
    symbol: "MATIC",
    decimals: 18,
    scanURL: "https://mumbai.polygonscan.com",
    rpc: "https://rpc-mumbai.maticvigil.com/"
  }
};

/**
 * Add/Switch a network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async (
  chainId: ChainId,
  externalProvider?: ExternalProvider
) => {
  const provider = externalProvider || window.ethereum;
  if (provider) {
    try {
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      });
      return true;
    } catch (switchError) {
      try {
        await provider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: `0x${chainId.toString(16)}`,
              chainName: NETWORK_CONFIG[chainId].name,
              nativeCurrency: {
                name: NETWORK_CONFIG[chainId].name,
                symbol: NETWORK_CONFIG[chainId].symbol,
                decimals: NETWORK_CONFIG[chainId].decimals,
              },
              rpcUrls: [NETWORK_CONFIG[chainId].rpc],
              blockExplorerUrls: [`${NETWORK_CONFIG[chainId].scanURL}/`],
            },
          ],
        });
        return true;
      } catch (error) {
        console.error("Failed to setup the network in Metamask:", error);
        return false;
      }
    }
  } else {
    console.error(
      "Can't setup the network on metamask because window.ethereum is undefined"
    );
    return false;
  }
};
