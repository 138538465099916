import { useEffect, useState } from "react";
import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import { styled } from "@mui/material";

import { ShareIcon } from "components/shared/SvgIcons";
import { SaveAlert, Image } from "components/shared/index";
import { imagesSvg } from "constants/images";
import { DefaultYMCA } from "ymca/ymca";
import { Post } from "ymca/models/post.model";
import Icon from "components/atoms/Icons";

const useStyles = makeStyles({
  reactContainer: {
    padding: "10px 0",
    display: "none",
  },
  reactWrapper: {
    width: "100%",
    borderRadius: 10,
    backgroundColor: "#2C333C",
    height: 54,
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    justifyContent: "space-between",
  },
  reactTxt: {
    fontSize: 12,
    fontWeight: 700,
    color: "#E5E7EC",
  },
  icon: {
    width: 48,
    height: 48,
    borderRadius: "100%",
  },
  center: {
    display: "flex",
    alignItems: "center",
  },
  alertBox: {
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    position: "fixed",
    bottom: 50,
    left: "50%",
    transform: "translate(-50%, 0)",
  },
  "@media (max-width: 550px)": {
    reactContainer: {
      display: "block",
    },
  },
});

const BookmarkIcon = styled(Icon)<{ bookmarked: boolean }>(
  ({ bookmarked, theme }) => ({
    color: bookmarked ? theme.palette.grey[500] : "transparent",
    "& > svg": {
      stroke: bookmarked ? "unset" : theme.palette.grey[500],
    },
  })
);

interface MemeToolbarProps {
  post: boolean;
  data: Post;
  bookmark?: boolean;
}

const MemeToolbar = ({
  post = false,
  data,
  bookmark = false,
}: MemeToolbarProps): JSX.Element => {
  const classes = useStyles();
  const [saved, setSaved] = useState<boolean>(bookmark);
  const [alert, setAlert] = useState<boolean>(false);

  const addBookmarks = async (post: Post): Promise<boolean> => {
    setSaved(true);
    setAlert(true);
    return await DefaultYMCA.bookmarkService.createBookmark(post);
  };

  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
    }, 3000);
  }, [alert]);

  return (
    <Box className={classes.reactContainer}>
      <Box
        className={classes.alertBox}
        style={{ display: alert ? "flex" : "none" }}
      >
        <SaveAlert />
      </Box>
      <Box className={classes.reactWrapper}>
        <Box>
          <IconButton
            className={classes.icon}
            onClick={async (): Promise<any> => {
              await addBookmarks(data);
            }}
          >
            <BookmarkIcon
              bookmarked={saved}
              icon='bookmark'
              fontSize='1.2rem'
            />
          </IconButton>
          <IconButton className={classes.icon}>
            <ShareIcon fill='#E1E3E8' />
          </IconButton>
        </Box>
        <Box className={classes.center}>
          <Box className={classes.center}>
            <Typography className={classes.reactTxt}>
              {data.nComments}
            </Typography>
            <IconButton className={classes.icon}>
              <Image
                alt='note reaction'
                src={`reactionIcons/${imagesSvg.noteIcon}`}
                fill='#E1E3E8'
              />
            </IconButton>
          </Box>
          <Box className={classes.center}>
            <Typography
              className={classes.reactTxt}
              style={{ color: post ? "#FE4773" : "#E1E3E8" }}
            >
              {data.nLikes}
            </Typography>
            <IconButton className={classes.icon}>
              <Image
                alt='like reaction'
                src={`reactionIcons/${imagesSvg.likeIcon}`}
                fill={post ? "#FE4773" : "#E1E3E8"}
                width='22px'
              />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MemeToolbar;
