import { Box } from "@mui/material";
import SpinnerIcon from "../SpinnerIcon";

const AtomLoading = () => {
  return (
    <Box display='flex' justifyContent='center'>
      <SpinnerIcon />
    </Box>
  );
};

export default AtomLoading;
