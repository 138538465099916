import Tilt from "react-parallax-tilt";
import { useNavigate } from "react-router-dom";
import { Image } from "components/shared/index";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Box, Button, Typography } from "@material-ui/core";
import { imagesSvg } from "constants/images";
import { DefaultYMCA } from "ymca/ymca";
import colors from "theme/dark/colors";
import {
  BREAKPOINT_LAPTOP,
  BREAKPOINT_LAPTOP_SMALL,
  BREAKPOINT_MOBILE_LARGE,
  BREAKPOINT_TABLET_MEDIUM,
} from "theme/shared/breakpoint";

const useStyles = makeStyles({
  container: {
    width: "100%",
    overflow: "auto",
  },
  itemsContainer: {
    marginTop: 15,
    padding: "0 1rem",
    position: "relative",
    width: 1032,

    [`@media (max-width: ${BREAKPOINT_LAPTOP_SMALL - 1}px)`]: {
      padding: 0,
      marginTop: "1rem",

      width: "calc(100vw - 250px)",
    },

    [`@media (max-width: ${BREAKPOINT_LAPTOP}px)`]: {
      padding: 0,
      marginTop: "1rem",

      width: "calc(100vw - 100px)",
    },
    [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
      width: "100vw",
    },
  },
  cateCard: {
    backgroundSize: " cover !important",
    backgroundPosition: "center !important",
    height: 150,
    borderRadius: 7,
    display: "flex",
    flexDirection: "column-reverse",
    justifyContent: "space-between",
    cursor: "pointer",
    width: "100%",
    position: "relative",
    "&:hover": {
      transitionDuration: "150ms",
      boxShadow: " -18px -7px 76px -20px rgba(100,177,222,0.75) inset ",
    },
  },
  title: {
    color: "#FFFFFF",
    fontSize: "0.6rem",
    fontWeight: 900,
    lineHeight: 24,
    letterSpacing: -0.2,
    textTransform: "lowercase",
    "&:first-letter": {
      textTransform: "capitalize",
    },
  },
  cnt: {
    backgroundColor: "rgba(18, 20, 28, .6)",
    display: "flex",
    borderRadius: 7,
    height: 24,
    padding: "0 5px",
    color: "#DFE1E6",
    alignItems: "center",
    width: "fit-content",
    marginTop: 5,
    marginLeft: 10,
  },
  text2: {
    fontSize: 11,
    fontWeight: 800,
    marginLeft: 5,
  },
  category: {
    color: "#DFE1E6",
    backgroundColor: "rgb(26, 30, 37, .6)",
    display: "flex",
    alignItems: "center",
    paddingLeft: "5px",
    borderRadius: "0 0 7px 7px",
    height: 24,
  },
  navigation: {
    position: "absolute",
    top: "50%",
    width: 38,
    height: 38,
    borderRadius: "50%",
    backgroundColor: colors.sliderBtn,
    border: `2px solid ${colors.sliderBtnBorder}`,
    boxShadow: `0px 0px 25px 0px ${colors.sliderBtnShadow}`,
    minWidth: 0,
    marginLeft: 3,
    "&:hover": {
      backgroundColor: colors.darkBlueActive,
    },
  },
  navbtns: {
    display: "flex",
    justifyContent: "flex-end",
    alignSelf: "center",
    zIndex: 2,
  },
  text4: {
    color: "#E2E4E9",
    fontSize: 20,
    fontWeight: 800,
  },
  prev: {
    left: 0,
  },
  next: {
    right: 0,
  },
  swiperContainer: {
    overflow: "hidden !important",
    borderRadius: 10,
  },

  "@media (max-width: 770px)": {
    cateCard: {
      width: "160px",
    },
  },
  "@media (max-width: 700px)": {
    title: {
      fontSize: "0.42rem",
    },
    cateCard: {
      height: 75,
      maxWidth: "100%",
    },
    navigation: {
      width: 30,
      height: 30,
      "& img": {
        width: "100%",
      },
    },
    next: {
      right: 10,
    },
    prev: {
      left: 10,
    },
  },
  "@media (max-width: 550px)": {
    navigation: {
      display: "none",
    },
  },
  "@media (max-width: 450px)": {
    text4: {
      fontSize: 20,
    },
  },
});

SwiperCore.use([Navigation, Pagination]);

interface TrendingProp {
  hideTrending?: boolean;
  text?: string;
  hideSlider?: boolean;
}

const Trendings = ({
  hideTrending,
  hideSlider,
  text,
}: TrendingProp): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const [trendes, setTrendes] = useState<any>();

  const { innerWidth: width } = window;

  const getTrandes = async (): Promise<any> => {
    const trandes = await DefaultYMCA.categoryService.getAllCategories();
    if (trandes && Array.isArray(trandes.data.data))
      setTrendes(trandes.data.data);
  };

  useEffect(() => {
    getTrandes();
  }, []);

  return (
    <Box className={classes.container} data-test-trending-wrapper>
      <Box className={classes.itemsContainer}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 16,
          }}
        >
          {hideTrending !== true && (
            <Box>
              <Typography className={classes.text4}>{text}</Typography>
            </Box>
          )}
          <Box className={classes.navbtns}>
            <Button
              className={classes.navigation}
              ref={prevRef}
              style={{ left: -0.5 }}
            >
              <Image
                alt='before icon'
                src={`moveIcons/${imagesSvg.beforeIcon}`}
              />
            </Button>
            <Button
              className={classes.navigation}
              ref={nextRef}
              style={{ right: -0.5 }}
            >
              <Image alt='next icon' src={`moveIcons/${imagesSvg.nextIcon}`} />
            </Button>
          </Box>
        </div>
        <Swiper
          slideToClickedSlide
          slidesPerView={width < 1024 ? 6 : 10}
          spaceBetween={10}
          slidesOffsetAfter={width / (width > 1000 ? 20 : 2.4)}
          observer
          className={classes.swiperContainer}
          navigation={{
            prevEl: prevRef.current ?? undefined,
            nextEl: nextRef.current ?? undefined,
          }}
          onInit={(swiper: any) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.update();
          }}
        >
          {!trendes &&
            Array.from({ length: 10 }, (_, k) => k + 1)?.map((_, index) => (
              <SwiperSlide key={index}>
                <Tilt
                  className='d'
                  tiltAxis='y'
                  glarePosition='all'
                  glareBorderRadius='0px'
                  glareMaxOpacity={0.5}
                  glareColor='lightblue'
                >
                  <div
                    className={`${classes.cateCard} d`}
                    style={{
                      background: "#202B42",
                    }}
                  ></div>
                </Tilt>
              </SwiperSlide>
            ))}
          {trendes?.map((item: any) => (
            <SwiperSlide key={item.id} data-test-trending-id={item.name}>
              <Tilt
                className='d'
                tiltAxis='y'
                glarePosition='all'
                glareBorderRadius='0px'
                glareMaxOpacity={0.5}
                glareColor='lightblue'
              >
                <div
                  className={`${classes.cateCard} d`}
                  style={{
                    backgroundImage: `url(${item?.image?.jpegUrl})`,
                    backgroundColor: "#35A6EB",
                  }}
                  onClick={() =>
                    navigate(`/categories/${item.name.toLowerCase()}`)
                  }
                >
                  <Box className={classes.category}>
                    <Typography className={classes.title}>
                      {item?.name}
                    </Typography>
                  </Box>
                </div>
              </Tilt>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};

export default Trendings;
