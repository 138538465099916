import { ImageDimensionInterface } from "./interfaces"
import { clauseConjunctions } from "./constants/constants"

import { DefaultYMCA } from "ymca/ymca"

// Encoding UTF8 ⇢ base64
export const b64EncodeUnicode = (str: string) => {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
    return String.fromCharCode(parseInt(p1, 16))
  }))
}

// Decoding base64 ⇢ UTF8
export const b64DecodeUnicode = (str: string) => {
  return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}

export async function loadImageFromUrl(url: string): Promise<HTMLImageElement> {
  const image = new Image()
  image.crossOrigin = 'anonymous'
  image.src = url
  return await new Promise<HTMLImageElement>((resolve, reject) => {
    image.onload = () => {
      resolve(image)
    }
    image.onerror = () => {
      reject(new Error('Could not load image'))
    }
  })
}

export function imageToBase64(image: HTMLImageElement): string {
  const canvas = document.createElement('canvas')
  canvas.width = image.width
  canvas.height = image.height
  const ctx = canvas.getContext('2d')
  if (ctx == null) throw new Error('No canvas context found')
  ctx.drawImage(image, 0, 0)
  const base64Image = canvas.toDataURL()
  canvas.remove()
  return base64Image
}

// Perform performance testing against other two methods (fetch and xhr) and use as default if difference is negligible or better.
// as this allows images to be fetched without bothering about CORS header.
export const getImageFromURL = async (url: string): Promise<string> => {
  const img = await loadImageFromUrl(url);
  img.remove();
  return imageToBase64(img);
}

export const getImageBase64FromSearchEngineURL = async (url: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const x = new XMLHttpRequest()
    x.open('GET', url)
    x.responseType = 'blob'
    x.onload = function () {
      const reader = new FileReader()
      reader.readAsDataURL(x.response)
      reader.onloadend = function () {
        if (reader.result) {
          const base64data = reader.result as string;
          resolve(base64data)
        }
      }
    }
    x.onerror = function () {
      reject(x)
    }
    x.send()
  })
}


export const getImageExtensionFromURL = async (imageURL: string): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      let imageFileURLElements = imageURL.split('/')
      let imageFileElements = imageFileURLElements[imageFileURLElements.length - 1].split('.')
      resolve(imageFileElements[imageFileElements.length - 1])
    }
    catch (err) {
      reject(err)
    }
  })
}

export const getImageDimensionsFromBase64Image = async (imageData: string): Promise<ImageDimensionInterface> => {
  return new Promise(async (resolve, reject) => {
    try {
      const img = new Image()
      img.src = imageData
      img.decode().then(() => {
        const { naturalWidth, naturalHeight } = img
        resolve({ width: naturalWidth, height: naturalHeight })
      },);
    }
    catch (err) {
      reject(err)
    }
  })
}

export const renderMemesPreview = async (imageURL: string, caption: string): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const imageBase64 = await getImageBase64FromSearchEngineURL(imageURL);
      const imageDimensions = await getImageDimensionsFromBase64Image(imageBase64);
      var offScreenCanvas = document.createElement('canvas') as HTMLCanvasElement;
      var offScreenCanvasCTX = offScreenCanvas.getContext('2d') as CanvasRenderingContext2D;
      offScreenCanvas.height = imageDimensions.height;
      offScreenCanvas.width = imageDimensions.width;
      let fontSizeUpdate = Math.round(imageDimensions.height / 12);
      if (fontSizeUpdate < 12) {
        fontSizeUpdate = 12;
      }
      var image = new Image();
      image.src = imageBase64;
      image.decode().then(() => {
        offScreenCanvasCTX.clearRect(0, 0, imageDimensions.width, imageDimensions.height);
        offScreenCanvasCTX.drawImage(image, 0, 0, imageDimensions.width, imageDimensions.height);
      })
      resolve('');
    }
    catch (err) {
      reject(err)
    }
  })
}

export const cleanWords = async (words: string[]): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    try {
      const cleanWords = words.map((word: string, index: number) => {
        let cleanWord = word.replace(/[^0-9a-zA-Z]/g, '');
        const cleanLowerCaseWord = cleanWord.toLowerCase();
        return cleanLowerCaseWord
      })
      resolve(cleanWords);
    }
    catch (err) {
      reject(err)
    }
  })
}
export const extractImageFromImage = async (baseImage: HTMLCanvasElement, posX: number, posY: number, width: number, height: number): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const offScreenCanvas = document.createElement('canvas') as HTMLCanvasElement;
      const offScreenCanvasCTX = offScreenCanvas.getContext('2d') as CanvasRenderingContext2D;
      offScreenCanvas.width = width;
      offScreenCanvas.height = height;
      offScreenCanvasCTX.drawImage(baseImage, posX, posY, width, height, 0, 0, offScreenCanvas.width, offScreenCanvas.height)
      resolve(offScreenCanvas.toDataURL());
    }
    catch (err) {
      reject(err)
    }
  })
}

export const replaceAlphaWithWhite = async (canvasElement: HTMLCanvasElement, canvasWidth: number, canvasHeight: number): Promise<HTMLCanvasElement> => {
  return new Promise(async (resolve, reject) => {
    try {
      const offScreenCanvasCTX = canvasElement.getContext('2d') as CanvasRenderingContext2D;
      let pixels = offScreenCanvasCTX.getImageData(0, 0, canvasWidth, canvasHeight);
      let pixelD = pixels.data;
      for (let i = 0; i < pixelD.length; i += 4) {
        if (pixelD[i + 3] === 0) {
          pixelD[i] = 255;
          pixelD[i + 1] = 255;
          pixelD[i + 2] = 255;
          pixelD[i + 3] = 255;
        }
      }
      offScreenCanvasCTX.putImageData(pixels, 0, 0)
      resolve(canvasElement);
    }
    catch (err) {
      reject(err)
    }
  })
}

export const segmentFaceFromImage = async (baseImage: string): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const offScreenCanvasFace = document.createElement('canvas') as HTMLCanvasElement;
      const offScreenCanvasCTXFace = offScreenCanvasFace.getContext('2d') as CanvasRenderingContext2D;
      const dummyFaceImage = new Image();
      dummyFaceImage.src = baseImage
      dummyFaceImage.decode().then(async () => {
        setTimeout(async () => {
          let { naturalWidth, naturalHeight } = dummyFaceImage
          offScreenCanvasFace.width = naturalWidth;
          offScreenCanvasFace.height = naturalHeight;
          offScreenCanvasCTXFace.drawImage(dummyFaceImage, 0, 0, naturalWidth, naturalHeight);
          const updatedCanvas = await replaceAlphaWithWhite(offScreenCanvasFace, naturalWidth, naturalHeight)
          const segmentedImage = await DefaultYMCA.selfieSegmentationService.run(updatedCanvas);
          resolve(segmentedImage);
        }, 300)
      })
    }
    catch (err) {
      reject(err)
    }
  })
}

export const splitPhrase = async (phrase: string): Promise<string[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const splitIndex = phrase.indexOf('|')
      if (splitIndex > 0) {
        const part1 = phrase.slice(0, splitIndex - 1);
        const part2 = phrase.slice(splitIndex + 2);
        resolve([part1, part2]);
      }
      else {
        const words = phrase.split(' ')
        const cleanedWords = await cleanWords(words);
        const index = cleanedWords.findIndex(word => clauseConjunctions.includes(word))
        if (index === -1) {
          resolve([phrase, ""]);
        } else {
          const part1 = words.slice(0, index + 1).join(' ');
          const part2 = words.slice(index + 1).join(' ');
          resolve([part1, part2]);
        }
      }
    }
    catch (err) {
      reject(err)
    }
  })
}