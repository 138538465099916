export default function Timer() {
  return (
    <svg
      width='17'
      height='20'
      viewBox='0 0 17 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.16536 2.2793C6.78566 2.2793 6.47786 1.97149 6.47786 1.5918C6.47786 1.2121 6.78566 0.904297 7.16536 0.904297H10.4063C10.786 0.904297 11.0938 1.2121 11.0938 1.5918C11.0938 1.97149 10.786 2.2793 10.4063 2.2793H7.16536Z'
        fill='#8B89FF'
      />
      <path
        d='M3.98483 2.69354C4.25332 2.96203 4.25332 3.39733 3.98483 3.66581L1.69317 5.95748C1.42468 6.22596 0.989381 6.22596 0.720895 5.95748C0.45241 5.68899 0.45241 5.25369 0.720895 4.98521L3.01256 2.69354C3.28105 2.42506 3.71635 2.42506 3.98483 2.69354Z'
        fill='#8B89FF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.9987 3.63801C4.69548 3.63801 1.20703 7.12646 1.20703 11.4297C1.20703 15.7329 4.69548 19.2213 8.9987 19.2213C13.3019 19.2213 16.7904 15.7329 16.7904 11.4297C16.7904 7.12646 13.3019 3.63801 8.9987 3.63801ZM13.5737 7.03956C13.7156 6.8571 13.6994 6.59752 13.536 6.43408C13.3725 6.27063 13.1129 6.25447 12.9305 6.39638L10.021 8.65927L8.11844 10.0183C7.77306 10.265 7.56809 10.6633 7.56809 11.0877C7.56809 11.8135 8.15649 12.4019 8.88232 12.4019C9.30676 12.4019 9.70506 12.197 9.95176 11.8516L11.3108 9.94899L13.5737 7.03956Z'
        fill='#8B89FF'
      />
    </svg>
  )
}
