import { Box } from '@mui/material'
import { Skeleton } from '@mui/material'
import { useRef } from 'react'

import useContestRewardsStyles from './style'
import { useGetContestRewards } from 'hooks/api-hooks/useContest'
import { useAuth } from 'context/auth/authContext'
import ContestCard from '../contest-card'
import { RewardsEmptyNonCurrently } from '../rewards-empty'
import useInfiniteScroll from 'hooks/useInfiniteScroll/useInfiniteScroll'
import AtomLoading from 'components/atoms/AtomLoading'

const ContestRewardList = (): JSX.Element => {
  const classes = useContestRewardsStyles()
  const { isAuthenticated, userInfo } = useAuth()

  const ref = useRef<HTMLDivElement | null>(null)

  // get list of rewards won from contest
  const query = useGetContestRewards({
    userId: userInfo?.userData?.id!,
    enabled: isAuthenticated
  })

  const { isLoading, data, isFetching, fetchNextPage } = query

  const { hasMore, data: events } = data ?? {}

  const eventLength = events?.length ?? 0

  const hasNextPage = Boolean(hasMore)
  const [infiniteRef] = useInfiniteScroll({
    loading: isFetching!,
    hasNextPage,
    onLoadMore: () => fetchNextPage?.(),
    rootMargin: '10px'
  })

  return (
    <Box className={classes.body}>
      <Box>
        {isLoading ? (
          <Box style={{ marginTop: 34 }}>
            <Skeleton
              style={{ height: 27, background: '#2B3853', width: 300 }}
            />

            <Box className={classes.cards} style={{}}>
              <Box
                className={classes.contestCard}
                style={{ height: 350 }}
              ></Box>
              <Box
                className={classes.contestCard}
                style={{ height: 350 }}
              ></Box>
              <Box
                className={classes.contestCard}
                style={{ height: 350 }}
              ></Box>
            </Box>
          </Box>
        ) : (
          <Box>
            {eventLength === 0 ? (
              <RewardsEmptyNonCurrently />
            ) : (
              <>
                <Box className={classes.cards}>
                  {events?.map((event, index) => {
                    const { eventMetadata } = event
                    if (!eventMetadata) return <></>
                    const { contestWinners } = eventMetadata
                    if (!contestWinners || contestWinners?.length === 0)
                      return <></>

                    return (
                      <ContestCard
                        tab={`You Won`}
                        type='reward'
                        event={event}
                        key={event.id}
                      />
                    )
                  })}
                </Box>
                {!isLoading && (
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    flexDirection='column'
                  >
                    {hasNextPage && !isFetching && <Box ref={infiniteRef} />}
                    {isFetching && (
                      <Box display='flex' justifyContent='center'>
                        <AtomLoading />
                      </Box>
                    )}
                  </Box>
                )}
              </>
            )}

            <div ref={ref}></div>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ContestRewardList
