// parseJSON tries to parse a string into a JSON
// it returns null if the string is an invalid JSON
// TODO: Get content-type from file
export function parseJSON (value: any): any {
  if (value === null || value === undefined || typeof value !== 'string') {
    return null
  }
  let res: any
  try {
    res = JSON.parse(value)
  } catch (err) {
    res = null
  }
  return res
}

export type ValueOf<T> = T[keyof T]

export function base64UrlToBase64 (str: string): string {
  return str.replace(/-/g, '+').replace(/_/g, '/')
}

export function base64ToBase64Url (str: string): string {
  return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}

export function base64ToString (str: string): string {
  if (globalThis.global !== undefined) {
    return Buffer.from(str, 'base64').toString()
  }
  return decodeURIComponent(atob(str).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''))
}

export function base64UrlToJSON (str: string): any {
  return parseJSON(base64ToString(base64UrlToBase64(str)))
}

/** This helper function takes a URL and returns File object.
 * Since Javascript can't access local files directly from browsers,
 * a network call is needed. The default `url` value is `/thumb.jpg`,
 * which is Youmeme's thumbnail image. This function should be used only
 * on development environment and for testing purposes.
 * @constructor
 * @param {string} url - The url of the image
*/
export async function getFileFromUrl (url: string = '/thumb.jpg'): Promise<File> {
  const blob = await fetch(url).then(async r => await r.blob())
  const filename = url.split('/').pop() ?? 'file'
  return new File([blob], filename, { type: blob.type })
}
