import {
  Box,
  FormLabel,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

import useCreateCommunityModalStyles from "../style";
import Icon from "components/atoms/Icons";
import FormTextField from "components/molecules/FormTextField";
import { CreateCommunityWizardFormProps } from "constants/community";

const StageOneWizard = ({
  formikFormProps,
}: CreateCommunityWizardFormProps) => {
  const classes = useCreateCommunityModalStyles();

  const { values, handleBlur, handleChange, errors, touched } = formikFormProps;

  return (
    <Box>
      <Box
        component='section'
        className={`${classes.dialogContentTopSection} ${classes.dialogContentTopSectionBorderBottom}`}
      >
        <Typography className={classes.dialogContentWizardStepIndicator}>
          Step 1
        </Typography>
        <Typography className={classes.dialogContentHeaderTitle}>
          Create a Community
        </Typography>
        <Box className={classes.dialogContentTopSectionSeparator} />
        <Box className={classes.dialogContentFormFieldContainer}>
          <FormTextField
            textFieldProps={{
              placeholder: "Community Name",
              id: "name",
              name: "name",
              fullWidth: true,
              onChange: handleChange,
              onBlur: handleBlur,
              value: values?.name,
            }}
            labelProps={{
              children: "Community Name",
              shrink: true,
              htmlFor: "name",
              required: true,
            }}
            errorProps={{
              error: errors.name,
              touched: touched.name,
            }}
          />
          <FormTextField
            textFieldProps={{
              placeholder: "Describe your community's purpose",
              id: "description",
              name: "description",
              fullWidth: true,
              multiline: true,
              rows: 3,
              onChange: handleChange,
              onBlur: handleBlur,
              value: values?.description,
            }}
            labelProps={{
              children: "Description",
              shrink: true,
              htmlFor: "description",
              required: true,
            }}
            errorProps={{
              error: errors.description,
              touched: touched.description,
            }}
          />
        </Box>
      </Box>
      <Box component='section' className={classes.dialogContentBottomSection}>
        <FormControl className={classes.dialogContentCommunityTypeSection}>
          <FormLabel
            id='community-type'
            className={classes.dialogContentCommunityType}
          >
            Community Type
          </FormLabel>
          <RadioGroup
            aria-labelledby='community-type'
            name='type'
            className={classes.radioGroup}
            value={values.isPrivate}
          >
            <FormControlLabel
              value={false}
              control={<Radio />}
              className={classes.radioField}
              label={
                <Box className={classes.radioFieldLabel}>
                  <Box className={classes.radioFieldLabelHeader}>
                    <Icon icon='publicLock' />
                    <Typography className={classes.radioFieldLabelTitle}>
                      Public
                    </Typography>
                  </Box>
                  <Typography className={classes.radioFieldLabelDescription}>
                    <span>
                      That means everyone can join this community without
                      rescrictions
                    </span>
                    <span>Everyone can join this community</span>
                  </Typography>
                </Box>
              }
              labelPlacement='start'
            />
            <FormControlLabel
              value={true}
              control={<Radio />}
              className={classes.radioField}
              label={
                <Box
                  style={{
                    position: "relative",
                  }}
                >
                  <Box className={classes.radioFieldLabel}>
                    <Box className={classes.radioFieldLabelHeader}>
                      <Icon icon='privateLock' />
                      <Typography
                        className={`${classes.radioFieldLabelTitle} ${classes.radioFieldLabelTitleDisabled}`}
                      >
                        Private
                      </Typography>
                      <Box className={classes.radioFieldComingSoon}>
                        <Typography
                          className={classes.radioFieldComingSoonText}
                        >
                          Coming Soon
                        </Typography>
                      </Box>
                    </Box>
                    <Typography
                      className={`${classes.radioFieldLabelDescription} ${classes.radioFieldLabelDescriptionDisabled}`}
                    >
                      <span>
                        This group will be available only to whitelisted memers
                      </span>
                      <span>Available only to whitelisted memers</span>
                    </Typography>
                  </Box>
                </Box>
              }
              labelPlacement='start'
              disabled
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

export default StageOneWizard;
