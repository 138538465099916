import {
  Button,
  Typography,
  Avatar,
  Dialog,
  DialogContent,
  Box,
  Snackbar,
  Alert
} from '@mui/material'
import useUpdateRoleCommunityModalStyles from './style'
import { useUpdateCommunityMemberRoleMutation } from 'hooks/api-hooks/useCommunity'
import { useQueryClient } from '@tanstack/react-query'
import useSnackbar from 'hooks/useSnackbar'
import AtomLoading from 'components/atoms/AtomLoading'
import { Community } from 'ymca/models/community.model'
import { CommunityRole } from 'ymca/models/community-role.model'
import { CommunityUserRoleDTO } from 'ymca/dtos/community.dto'
import { PublicUser } from 'ymca/models/user.model'

interface CommunityUpdateUserRoleModalProps {
  isOpen: boolean
  close: VoidFunction
  avatarSrc?: string
  community: Community
  member: PublicUser
  refetchQuery: Function
  updatingRole: CommunityRole
}
const CommunityUpdateUserRoleModal = ({
  isOpen,
  close,
  avatarSrc,
  community,
  refetchQuery,
  updatingRole,
  member
}: CommunityUpdateUserRoleModalProps) => {
  const classes = useUpdateRoleCommunityModalStyles()
  const queryClient = useQueryClient()

  const {
    isOpen: isUpdateRoleSnkbarOpen,
    open: showUpdatedRoleSnkbar,
    close: closeUpdatedSnkbar
  } = useSnackbar()

  const {
    isOpen: isUpdateRoleSnkbarErrOpen,
    open: openUpdateRoleSnkbarErr,
    close: closeUpdateRoleSnkbarErr
  } = useSnackbar()

  const mutation = useUpdateCommunityMemberRoleMutation({
    onError: (props: any) => {
      console.log('onError', { props })
      openUpdateRoleSnkbarErr()
    },
    onSuccess: (props: any) => {
      const queryKey = ['communities', { id: community.id }]
      queryClient.invalidateQueries(queryKey)
      showUpdatedRoleSnkbar()
      // close modal
      close()
      refetchQuery()
    }
  })

  const handleUpdateRole = () => {
    const communityUserRole = new CommunityUserRoleDTO()
    communityUserRole.communityId = community.id
    communityUserRole.userId = member.id
    communityUserRole.role = updatingRole
    mutation.mutate(communityUserRole)
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={close}
        aria-labelledby='leave-community'
        className={classes.dialog}
      >
        <DialogContent className={classes.dialogContent}>
          <Box display='flex' flexDirection='column' alignItems='flex-end'>
            <Avatar
              className={classes.dialogContentAvatar}
              src={community?.avatar?.jpegUrl}
            />
          </Box>

          <Box display='flex' flexDirection='column'>
            <Typography className={classes.dialogContentTitle}>
              Update Member Role?
            </Typography>
            <Typography className={classes.dialogContentInfo}>
              You are about to update the role of this member to{' '}
              <strong>
                <em>{updatingRole}</em>
              </strong>{' '}
              in {community.name} community.
            </Typography>
          </Box>
          <Box className={classes.dialogActions}>
            <Button
              className={classes.dialogContentActionsLeaveBtn}
              onClick={handleUpdateRole}
            >
              Update Member Role
              {mutation.isLoading && <AtomLoading />}
            </Button>
            <Button
              className={classes.dialogContentActionsCancelBtn}
              onClick={close}
              disabled={mutation.isLoading}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isUpdateRoleSnkbarOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={closeUpdatedSnkbar}
      >
        <Alert>Successfully updated member role</Alert>
      </Snackbar>
      <Snackbar
        open={isUpdateRoleSnkbarErrOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={closeUpdateRoleSnkbarErr}
      >
        <Alert severity='error'>Failed to update member role</Alert>
      </Snackbar>
    </>
  )
}

export default CommunityUpdateUserRoleModal
