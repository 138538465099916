import { QuerySearchParamsDto } from './common.dto'

export interface CreateStickerDto {
  title: string
  description: string
  tags: string[]
  isNsfw: boolean
  isPublic: boolean
  sortPosition: number
  image: string
}

export interface UpdateStickerDto {
  title?: string
  description?: string
  tags?: string[]
  isNsfw?: boolean
  isPublic?: boolean
  sortPosition?: number
  image?: string
}

export class StickerListDTO extends QuerySearchParamsDto {
  public id: string[] = []
  public search = ''
  public communityId?: string
  public tags: string[] = []
  public hasAllTags = false
  public skipNSFW = false

  public toURLSearchParams(): URLSearchParams {
    const params = super.toURLSearchParams()
    if (this.id.length > 0) {
      params.append('id', this.id.join(','))
    }
    if (this.search != null && this.search !== '') {
      params.append('search', this.search)
    }
    if (this.communityId != null && this.communityId !== '') {
      params.append('communityId', this.communityId)
    }
    if (this.tags.length > 0) {
      params.append('tags', this.tags.join(','))
    }
    if (this.hasAllTags) {
      params.append('hasAllTags', 'true')
    }
    if (this.skipNSFW) {
      params.append('skipNSFW', 'true')
    }
    return params
  }
}
