/**
 * InMemoryStorage is a simple in-memory storage implementation
 * that works in Node.js and in the browser, implementing an API
 * similar to LocalStorage and SessionStorage.
 */
export class InMemoryStorage implements Storage {
  private readonly store: Map<string, string> = new Map<string, string>()

  public get length (): number {
    return this.store.size
  }

  public clear (): void {
    this.store.clear()
  }

  public getItem (key: string): string | null {
    return this.store.get(key) ?? null
  }

  public key (index: number): string | null {
    return Array.from(this.store.keys())[index] ?? null
  }

  public removeItem (key: string): void {
    this.store.delete(key)
  }

  public setItem (key: string, value: string): void {
    this.store.set(key, value)
  }
}
