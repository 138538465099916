
export interface Config {
  corsProxy: string
  apiEndpoint: string
  disableYouMemeDebugger: string
  assetPath: string
  deploymentUrl: string
  oldestAllowedSessionStartTime: string
  googleIdentityClientID: string
  tfModels: string
  popupOffset: string
  activateReferralPage: string
  serviceWorkerEnabled: boolean
  captchaSiteKey: string
  aiMemeGeneratorEnabled: boolean
  aiFaceSwapEnabled: boolean
  aiMemecamEnabled: boolean
  lensFeedEnabled: boolean

  experimentalFlagPublicationFixedDimension: boolean
  experimentalCommunityFlag: boolean
  experimentalCommunityv2Flag: boolean
  showSurveyBanner: boolean
  experimentalYoumemeIOEnabledFlag: boolean // experimental
  imageMaxSizeInBytes: number
  disablePublicationFixedHeight: boolean
  disableLensButton: boolean
  googleFontsApiKey: string
}

const config: Config = {
  corsProxy:
    (process.env.REACT_APP_CORS_PROXY as string) ??
    'https://corsanywhere-2-s4915320.deta.app/',
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT as string,
  disableYouMemeDebugger: process.env
    .REACT_APP_DISABLE_YOUMEME_DEBUGGER as string,
  assetPath: process.env.REACT_APP_IMAGE_ASSET_PATH ?? '/assets/',
  tfModels: process.env.REACT_APP_TF_MODELS_CDN ?? '/assets/tf-models/',
  deploymentUrl: process.env.REACT_APP_DEPLOYMENT_URL as string,
  oldestAllowedSessionStartTime: process.env
    .REACT_APP_OLDEST_ALLOWED_SESSION_START_TIME as string,
  googleIdentityClientID: process.env
    .REACT_APP_GOOGLE_IDENTITY_CLIENTID as string,
  popupOffset: process.env.REACT_APP_WELCOME_POPUP_OFFSET as string,
  activateReferralPage: process.env.REACT_APP_ACTIVATE_REFERRAL_PAGE as string,
  serviceWorkerEnabled: process.env.REACT_APP_SERVICE_WORKER_ENABLED === 'true',
  captchaSiteKey: process.env?.REACT_APP_CAPTCHA_SITE_KEY as string,
  aiMemeGeneratorEnabled:
    process.env?.REACT_APP_AI_MEME_GENERATOR_ENABLE === 'true',
  aiFaceSwapEnabled: process.env?.REACT_APP_AI_FACE_SWAP_ENABLE === 'true',
  aiMemecamEnabled: process.env?.REACT_APP_AI_MEMECAM_ENABLE === 'true',
  lensFeedEnabled: process.env?.REACT_APP_LENS_FEED_ENABLED === 'true',
  experimentalFlagPublicationFixedDimension:
    process.env?.REACT_APP_EXPERIMENT_PUBLICATION_FIXED_DIMENSION === 'true',
  showSurveyBanner: process.env?.REACT_APP_SHOW_SURVEY_BANNER === 'true',
  experimentalCommunityFlag:
    process.env?.REACT_APP_COMMUNITY_EXPERIMENTAL_FLAG === 'true',
  experimentalYoumemeIOEnabledFlag:
    process.env?.REACT_APP_YOUMEME_IO_ENABLED === 'true',
  experimentalCommunityv2Flag:
    process.env?.REACT_APP_COMMUNITY_EXPERIMENTAL_V2_FLAG === 'true',
  disablePublicationFixedHeight:
    process.env?.REACT_APP_DISABLE_PUBLICATION_FIXED_HEIGHT === 'true',
  imageMaxSizeInBytes: parseInt(
    process.env?.REACT_APP_IMAGE_MAX_SIZE_IN_BYTES ?? '10485760'
  ),
  disableLensButton: process.env?.REACT_APP_DISABLE_LENS_BUTTON === 'true',
  googleFontsApiKey:
    process.env?.REACT_APP_GOOGLE_FONTS_API_KEY ??
    'AIzaSyB_GeunegMajGY5ISuzcSvk7mACxekTnXY'
}

export default config
