export type CommunityRole =
  | "member"
  | "superadmin"
  | "admin"
  | "moderator"
  | "banned"
  | "invited";

export const MANAGE_COMMUNITY_ROLES: CommunityRole[] = [
  "admin",
  "superadmin",
  "moderator",
];
export const ACTIVE_COMMUNITY_ROLES: CommunityRole[] = [
  ...MANAGE_COMMUNITY_ROLES,
  "member",
];
export const COMMUNITY_ROLES: CommunityRole[] = [
  ...ACTIVE_COMMUNITY_ROLES,
  "banned",
  "invited",
];
