import { makeStyles } from "@material-ui/core/styles";

const useCommunityMemberItemCardDStyles = makeStyles({
  wrapper: {},
  main: {},
  mainAdmin: {
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #2A3753",

    gap: ".75rem",
  },
  mainAdminTitle: {
    color: "#8A98B4",
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.01875rem",

    marginLeft: ".5rem",
  },

  mainAdminCard: {
    borderRadius: "0.9375rem",
    background: "#28344D",
    boxShadow: "0px 0px 20px 0px rgba(0, 7, 24, 0.10)",
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
    padding: "1rem",

    maxWidth: "14rem",
  },
  mainAdminCardAvatar: {
    width: "2.5rem",
    height: "2.5rem",
  },
  mainAdminCardContent: {},
  mainAdminCardContentTop: {
    display: "flex",
    alignItems: "center",
    gap: ".25rem",
  },
  mainAdminCardContentDisplayName: {
    color: "#E9F0F9",
    fontFamily: "Inter",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "1.1875rem",
    letterSpacing: "-0.0375rem",
  },
  mainAdminCardContentUsername: {
    color: "#8A98B4",
    fontFamily: "Inter",
    fontSize: "0.8125rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.025rem",
  },
  mainBody: {},
  header: {
    display: "flex",
    flexDirection: "column",
    gap: ".75rem",
    borderBottom: "1px solid #2A3753",

    padding: "0 1rem 1rem",
  },
  headerGoBackBtn: {
    "&.MuiIconButton-root": {
      padding: 0,

      position: "absolute",

      background: "#33405C",
    },
  },
  headerBanner: {
    height: "11.1875rem",

    borderRadius: "0.75rem 0.75rem 0 0",
    background: "blue",
    // background: url(<path-to-image>), lightgray 50% / cover no-repeat;
  },
  headerProfileActions: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerProfileActionsLeftSection: {
    display: "flex",
    justifyContent: "space-between",
    gap: ".25rem",
  },
  headerProfileActionsRightSection: {
    display: "flex",
    justifyContent: "space-between",
    gap: ".25rem",
  },
  headerProfileActionsAvatar: {
    marginLeft: ".5rem",
    marginTop: "-5.25rem",
    width: "6.875rem",
    height: "6.875rem",
    border: "3px solid #151E31",
  },
  headerProfileActionsSocials: {
    display: "flex",

    gap: ".5rem",
  },
  headerProfileActionsSocialsLinks: {
    height: "1rem",
    "&.disabled": {
      opacity: 0.4,
    },
  },
  headerProfileActionsLeaveBtn: {
    borderRadius: "6.25rem",
    boxShadow: " 0px 4px 8px 0px rgba(14, 20, 34, 0.20)",
    border: "1px solid rgba(167, 179, 203, 0.50)",

    "&.MuiButton-root": {
      display: "flex",
      gap: ".25rem",
      padding: ".5rem 1rem",
      minWidth: "6rem",
      height: "2.5rem",
    },

    color: "#FF7152",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "100%",
    letterSpacing: "-0.03125rem",
  },
  headerProfileActionsJoinBtn: {
    display: "flex",
    alignItems: "center",

    borderRadius: "6.25rem",
    background: "linear-gradient(65deg, #625FFF 0%, #A55FFF 100%)",
    boxShadow: " 0px 4px 8px 0px rgba(14, 20, 34, 0.20)",

    "&.MuiButton-root": {
      display: "flex",
      gap: ".25rem",
      padding: ".5rem 1rem",
      minWidth: "6rem",
      height: "2.5rem",
    },

    color: "#FFF",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "0.92188rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "1.1875rem",
    letterSpacing: "-0.04375rem",
  },
  headerContent: {
    paddingLeft: ".5rem",
    display: "flex",
    flexDirection: "column",
    gap: ".75rem",
  },
  headerContentTitle: {
    color: "#E9F0F9",
    fontFamily: "Inter",
    fontSize: "1.5rem",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "normal",
    letterSpacing: "-0.04375rem",
  },
  headerContentDescription: {
    color: "#8A98B4",
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.01875rem",
  },
  headerContentMembersSection: {
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
  },
  headerContentMembersCount: {
    color: "#817EFF",
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.01875rem",
  },
  headerContentDotSeparator: {
    width: "0.375rem",
    height: "0.375rem",
    borderRadius: "0.375rem",
    backgroundColor: "#8A98B4",
  },
  headerContentWebsite: {
    color: "#8A98B4",
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.01875rem",
  },
  headerAdmin: {},
  kebabMenuMenu: {
    "& .MuiPaper-root": {
      "border-radius": "0.75rem",
      background: "#33405C",
      "box-shadow": " 0px 4px 46px 0px rgba(14, 20, 34, 0.50)",
    },
    "& .MuiList-root": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  kebabMenuMenuBorderBottom: {
    borderBottom: "1px solid rgba(138, 152, 180, 0.30);",
  },
  kebabMenuMainMenuItem: {
    display: "flex",
    alignItems: "center",
    width: "100%",

    padding: ".5rem",
  },
  kebabMenuMainMenuItemContent: {
    display: "flex",
    alignItems: "center",
    "border-radius": "0.75rem",
    background: "#202A41",
    "box-shadow": "0px 4px 8px 0px rgba(14, 20, 34, 0.20)",

    padding: ".75rem 1rem",
    width: "100%",

    gap: ".5rem",
  },
  kebabMenuSecondaryMenuItem: {
    display: "flex",
    justifyContent: "flex-start",
    gap: ".75rem",
    padding: ".75rem 1.5rem",
    width: "100%",
  },
  kebabMenuMenuCopy: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",

    padding: ".5rem .5rem",

    gap: ".5rem",
  },
  kebabMenuMenuCopyTitle: {
    color: "#E9F0F9",
    "font-family": "Inter",
    "font-size": "0.875rem",
    "font-style": "normal",
    "font-weight": 700,
    "line-height": "normal",
    "letter-spacing": "-0.03125rem",

    paddingLeft: ".75rem",
  },
  kebabMenuMenuCopyLinkMessage: {
    color: "#8A98B4",
    "font-family": "Inter",
    "font-size": "0.8125rem",
    "font-style": "normal",
    "font-weight": 400,
    "line-height": "normal",
    "letter-spacing": "-0.03125rem",
  },
  kebabMenuSecondaryMenuText: {
    color: "#8A98B4",
    "font-family": "Inter",
    "font-size": "0.875rem",
    "font-style": "normal",
    "font-weight": 600,
    "line-height": "normal",
    "letter-spacing": "-0.03125rem",
  },
  kebabMenuMainMenuText: {
    color: "#E9F0F9",
    "font-family": "Inter",
    "font-size": "0.875rem",
    "font-style": "normal",
    "font-weight": 600,
    "line-height": "normal",
    "letter-spacing": "-0.03125rem",
  },
});

export default useCommunityMemberItemCardDStyles;
