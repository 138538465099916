import {
  CreatePostBodyDto,
  FindPostsDto,
  GetEventTopEntriesDTO,
  GetPostsByEventDTO,
  GetUserPostsByEventDTO
} from 'ymca/dtos/post.dto'
import {
  PaginatedGenerator,
  PaginatedResponse,
  SortOrder
} from 'ymca/dtos/common.dto'
import { Post, PostContentType, PostType } from 'ymca/models/post.model'
import { BaseService, CommonArgs } from './base.service'
import { APIResponse, ParamsCapableToPlainJSON } from 'ymca/jsonFetcher'
import { CategoryService } from './category.service'
import { getFileFromBase64Embed } from './image.service'
import { SelfService } from './self.service'
import { LensService } from './lens.service'
import { GetBookmarksDto } from 'ymca/dtos/bookmark.dto'
import { LensTombstoneService } from './lens-tombstone.service'

export const POST_CACHE_TTL_SECONDS = 60 // 1 minute
export const POST_PAGE_SIZE = 100 // you can set a smaller number to test!

export class PostService extends BaseService {
  protected selfService: SelfService
  protected categoryService: CategoryService
  protected lensService: LensService
  protected lensTombstoneService: LensTombstoneService

  public constructor(
    common: CommonArgs,
    selfService: SelfService,
    categoryService: CategoryService,
    lensService: LensService,
    lensTombstoneService: LensTombstoneService
  ) {
    super(common)
    this.selfService = selfService
    this.categoryService = categoryService
    this.lensService = lensService
    this.lensTombstoneService = lensTombstoneService
  }

  protected async setPostInCache(post: Post, skipCache = false): Promise<void> {
    skipCache = true
    await this.common.kvStore.set<Post>(
      `post:${post.id}`,
      post,
      POST_CACHE_TTL_SECONDS,
      skipCache
    )
    this.common.pubsub.publish(post.id, post)
  }

  protected async setPostsInCache(
    posts: Post[],
    skipCache = false
  ): Promise<void> {
    for (const post of posts) {
      await this.setPostInCache(post, skipCache)
    }
  }

  protected async getPostFromCache(
    postId: string,
    skipCache: boolean
  ): Promise<Post | null> {
    return await this.common.kvStore.get<Post>(`post:${postId}`, skipCache)
  }

  // getPostsWithDto accepts a FindPostsDto and uses it to fetch posts.
  // This adds the user object to each post via the UserService.
  protected async getPostsWithDto(
    req: FindPostsDto
  ): Promise<APIResponse<PaginatedResponse<Post>>> {
    const res = await this.common.jsonFetcher.fetchJSON<
      PaginatedResponse<Post>
    >('GET', '/api/post', req)
    const posts = res?.data?.data
    if (!Array.isArray(posts)) {
      const msg = `Failed to fetch posts from API: ${JSON.stringify(req)}`
      console.error(msg)
      console.error(res)
      throw new Error(msg)
    }
    await this.setPostsInCache(posts)
    return res
  }

  protected async getPostByIdFromAPI(postId: string): Promise<Post | null> {
    const req = new FindPostsDto()
    req.ids = [postId]
    const res = await this.getPostsWithDto(req)
    const posts = res?.data?.data
    if (!Array.isArray(posts) || posts.length === 0) {
      return null
    }
    return posts[0]
  }

  public async getPostById(postId: string): Promise<Post | null> {
    if (postId.startsWith('_')) {
      const parsedPostId = postId.substring(1)
      const post = await this.lensService.getPostById(parsedPostId)
      return post
    }
    const post = await this.getPostByIdFromAPI(postId)
    return post
  }

  public async getUserPosts(
    userId: string,
    limit: number = 10,
    offset: number = 0,
    order: SortOrder = 'DESC'
  ): Promise<APIResponse<PaginatedResponse<Post>>> {
    const req = new FindPostsDto()
    req.userIds = [userId]
    req.limit = limit
    req.offset = offset
    req.order = order
    const res = await this.getPostsWithDto(req)
    return res
  }

  public async getCategoryPosts(
    categoryId: string,
    limit: number = 10,
    offset: number = 0,
    order: SortOrder = 'DESC'
  ): Promise<APIResponse<PaginatedResponse<Post>>> {
    const req = new FindPostsDto()
    req.categoryIds = [categoryId]
    req.limit = limit
    req.offset = offset
    req.order = order
    req.type = 'public'
    const res = await this.getPostsWithDto(req)
    return res
  }

  public getCommunityPosts(dto: FindPostsDto): PaginatedGenerator<Post> {
    dto.type = 'community'
    const queryArgs = ParamsCapableToPlainJSON(dto)
    const res = this.common.jsonFetcher.fetchPaginatedJSON<Post>(
      '/api/post',
      queryArgs
    )
    return res
  }

  // getCategoryPostsByCategorySlug fetches posts for a cateogry by URL slug
  // if the category is not found, it returns null
  public async getCategoryPostsByCategorySlug(
    categorySlug: string,
    limit: number = 10,
    offset: number = 0,
    order: SortOrder = 'DESC'
  ): Promise<APIResponse<PaginatedResponse<Post>> | null> {
    const category = await this.categoryService.getCategoryBySlug(categorySlug)
    if (category === undefined || category === null) {
      return null
    }
    const res = await this.getCategoryPosts(category.id, limit, offset, order)
    return res
  }

  public async getWeeklyPopularCategoryPosts(
    categoryId: string,
    limit: number = 10,
    offset: number = 0,
    order: SortOrder = 'DESC'
  ): Promise<APIResponse<PaginatedResponse<Post>>> {
    const req = new FindPostsDto()
    req.categoryIds = [categoryId]
    req.limit = limit
    req.offset = offset
    req.order = order
    req.sort = 'popular'
    req.type = 'public'
    const tNow = new Date().getTime()
    const tWeekAgo = tNow - 7 * 24 * 60 * 60 * 1000
    req.startTime = tWeekAgo
    req.endTime = tNow
    const res = await this.getPostsWithDto(req)
    return res
  }

  public async getWeeklyPopularPosts(
    limit: number = 10,
    offset: number = 0,
    order: SortOrder = 'DESC'
  ): Promise<APIResponse<PaginatedResponse<Post>>> {
    const req = new FindPostsDto()
    req.limit = limit
    req.offset = offset
    req.order = order
    req.sort = 'popular'
    req.type = 'public'
    const tNow = new Date().getTime()
    const tWeekAgo = tNow - 7 * 24 * 60 * 60 * 1000
    req.startTime = tWeekAgo
    req.endTime = tNow
    const res = await this.getPostsWithDto(req)
    return res
  }

  public async getAllPosts(
    limit: number = 10,
    offset: number = 0,
    order: SortOrder = 'DESC'
  ): Promise<APIResponse<PaginatedResponse<Post>>> {
    const req = new FindPostsDto()
    req.limit = limit
    req.offset = offset
    req.order = order
    const res = await this.getPostsWithDto(req)
    return res
  }

  public async getAllPublicPosts(
    limit: number = 10,
    offset: number = 0,
    order: SortOrder = 'DESC'
  ): Promise<APIResponse<PaginatedResponse<Post>>> {
    const req = new FindPostsDto()
    req.limit = limit
    req.offset = offset
    req.order = order
    req.type = 'public'
    const res = await this.getPostsWithDto(req)
    return res
  }

  public async getAllNewsPosts(
    limit: number = 10,
    offset: number = 0,
    order: SortOrder = 'DESC',
    muteIds: string[] = []
  ): Promise<APIResponse<PaginatedResponse<Post>>> {
    const req = new FindPostsDto()
    req.limit = limit
    req.offset = offset
    req.order = order
    req.type = 'news'
    if (muteIds.length > 0) {
      req.muteIds = muteIds
    }
    const res = await this.getPostsWithDto(req)
    return res
  }

  public async getAllFollowingPosts(
    limit: number = 10,
    offset: number = 0,
    order: SortOrder = 'DESC'
  ): Promise<APIResponse<PaginatedResponse<Post>>> {
    const req = new FindPostsDto()
    req.limit = limit
    req.offset = offset
    req.order = order
    req.type = 'public'
    req.following = true
    const res = await this.getPostsWithDto(req)
    return res
  }

  public async getWeeklyPopularFollowingPosts(
    limit: number = 10,
    offset: number = 0,
    order: SortOrder = 'DESC'
  ): Promise<APIResponse<PaginatedResponse<Post>>> {
    const req = new FindPostsDto()
    req.limit = limit
    req.offset = offset
    req.order = order
    req.sort = 'popular'
    req.type = 'public'
    const tNow = new Date().getTime()
    const tWeekAgo = tNow - 7 * 24 * 60 * 60 * 1000
    req.startTime = tWeekAgo
    req.endTime = tNow
    req.following = true
    const res = await this.getPostsWithDto(req)
    return res
  }

  public async searchPosts(
    search: string,
    limit: number = 10,
    offset: number = 0,
    order: SortOrder = 'DESC'
  ): Promise<APIResponse<PaginatedResponse<Post>>> {
    const req = new FindPostsDto()
    req.search = search
    req.limit = limit
    req.offset = offset
    req.order = order
    req.type = 'public'
    const res = await this.getPostsWithDto(req)
    return res
  }

  public getPostsByEvent(dto: GetPostsByEventDTO): PaginatedGenerator<Post> {
    const queryArgs = ParamsCapableToPlainJSON(dto)

    const res = this.common.jsonFetcher.fetchPaginatedJSON<Post>(
      '/api/post',
      queryArgs
    )

    return res
  }

  public getEventTopEntries(
    dto: GetEventTopEntriesDTO
  ): PaginatedGenerator<Post> {
    const queryArgs = ParamsCapableToPlainJSON(dto)

    const res = this.common.jsonFetcher.fetchPaginatedJSON<Post>(
      '/api/post',
      queryArgs
    )

    return res
  }

  public getUserPostsByEvent(
    dto: GetUserPostsByEventDTO
  ): PaginatedGenerator<Post> {
    const queryArgs = ParamsCapableToPlainJSON(dto)
    const res = this.common.jsonFetcher.fetchPaginatedJSON<Post>(
      '/api/post',
      queryArgs
    )
    return res
  }

  public async getBookmarkedPostsByUser(
    userId: string,
    limit: number = 10,
    offset: number = 0
  ): Promise<PaginatedResponse<Post>> {
    const dto = new GetBookmarksDto()
    dto.limit = limit
    dto.offset = offset
    dto.userId = userId
    const res = await this.common.jsonFetcher.fetchJSON<
      PaginatedResponse<Post>
    >('GET', '/api/post/bookmarks', dto)
    if (res.isSuccess) {
      return res.data
    } else {
      console.error(res)
      throw new Error('Failed to get bookmarks')
    }
  }

  public async createPostWithBase64Data(
    type: PostType,
    contentType: PostContentType,
    title: string,
    description: string,
    tags: string[],
    categoryName: string,
    eventId: string,
    sourceData: string,
    overlayData: string,
    renderedData: string,
    thumbnailData: string
  ): Promise<APIResponse<Post>> {
    const dto = new CreatePostBodyDto()
    dto.type = type
    dto.contentType = contentType
    dto.title = title
    dto.description = description
    dto.tags = tags
    if (categoryName.length > 0) {
      dto.categoryName = categoryName
    }
    if (eventId.length > 0) {
      dto.eventId = eventId
    }
    if (sourceData.length > 0) {
      dto.source = getFileFromBase64Embed(sourceData)
    }
    if (overlayData.length > 0) {
      dto.overlay = getFileFromBase64Embed(overlayData)
    }
    if (renderedData.length > 0) {
      dto.rendered = getFileFromBase64Embed(renderedData)
    }
    if (thumbnailData.length > 0) {
      dto.thumbnail = getFileFromBase64Embed(thumbnailData)
    }
    const body = dto.toFormData()
    const res = await this.common.jsonFetcher.fetchJSON<Post>(
      'PUT',
      '/api/post',
      undefined,
      body,
      'application/x-www-form-urlencoded'
    )
    if (res.isCreated) {
      await this.selfService.incrementSelfPostCounter(
        res.data.type === 'competition'
      )
    }
    return res
  }

  // This is depreciated, somewhat
  public async createPostWFormData(body: FormData): Promise<APIResponse<Post>> {
    const res = await this.common.jsonFetcher.fetchJSON<Post>(
      'PUT',
      '/api/post',
      undefined,
      body,
      'application/x-www-form-urlencoded'
    )
    if (res.isCreated) {
      await this.selfService.incrementSelfPostCounter(
        res.data.type === 'competition'
      )
    }
    return res
  }

  public async deletePost(postToDelete: Post): Promise<boolean> {
    console.debug(postToDelete)
    if (postToDelete.id.startsWith('_')) {
      const lensPostId = postToDelete.id.substring(1)
      const selfProfileId = await this.lensService.getSelfProfileId()
      const postProfileId = postToDelete.fkUserId.slice(1)
      if (selfProfileId === postProfileId) {
        const res = await this.lensService.hidePostOrComment(lensPostId)
        return res
      } else {
        const res = await this.lensTombstoneService.softDeleteLensPost(
          lensPostId
        )
        return res
      }
    }
    const res = await this.common.jsonFetcher.fetchJSON(
      'DELETE',
      `/api/post/${postToDelete.id}`
    )
    if (res.isSuccess && postToDelete.fkUserId === this.selfService.getId()) {
      await this.selfService.decrementSelfPostCounter(
        postToDelete.type === 'competition'
      )
    }
    return res.isSuccess
  }
}
