import { useContext } from 'react'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Typography } from '@material-ui/core'
import { ProfileTabContext } from 'components/user/UserProfileTab'
import { DefaultYMCA } from 'ymca/ymca'
import { Post } from 'ymca/models/post.model'
import { usePublicationContext } from 'context/PublicationContext'

export interface DeletePostModalProps {
  open: boolean
  handleClose: () => void
  itemData: Post
  handleParentClose: () => void
  setEvent?: () => void
  setDelete?: () => void
}

const useStyles = makeStyles({
  paper: {
    position: 'absolute',
    width: '400px',
    overflow: 'auto !important',
    backgroundColor: '#151820',
    boxShadow: '0px 3px 10px #0000001A',
    boxSizing: 'border-box',
    borderRadius: '15px',
    padding: '40px 35px',
    '&:focus': {
      outline: 'none'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: '16px',
    textAlign: 'center',
    marginBottom: '10px'
  },
  backbtn: {
    height: '44px',
    width: '200px',
    borderRadius: '7px',
    fontWeight: 700,
    fontSize: '14px',
    color: '#FFFFFF',
    boxShadow: 'none',
    border: '2px solid red'
  },
  changebtn: {
    height: '44px',
    width: '200px',
    background: 'var(--main-gradient)',
    borderRadius: '7px',
    fontWeight: 700,
    fontSize: '14px',
    color: '#FFFFFF',
    boxShadow: 'none',
    marginLeft: '10px'
  },
  btnbox: {
    marginTop: '30px',
    justifyContent: 'space-between'
  }
})

const noop = () => {}

export const DeletePostModal = ({ open, handleClose, itemData, handleParentClose=noop, setEvent=noop, setDelete=noop }: DeletePostModalProps): JSX.Element => {
  const classes = useStyles()

  const {onDeletePublication} = usePublicationContext()

  const profileTabState = useContext(ProfileTabContext)

  const handleRemove = async (): Promise<void> => {
    switch (profileTabState) {
      // case 'posts':
      case 0:
        await DefaultYMCA.postService.deletePost(itemData)
        break
      // case 'bookmarks':
      case 1:
        await DefaultYMCA.bookmarkService.deleteBookmark(itemData)
        break
      default:
        await DefaultYMCA.postService.deletePost(itemData)
        break
    }
  }

  const onRemove = async () => {
    await handleRemove()
    handleClose()
    handleParentClose()
    setDelete()
    setEvent()
    onDeletePublication()
  }

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <Box className={classes.paper}>
        <Typography className={classes.title}>Delete the content?</Typography>
        <Box display='flex' className={classes.btnbox}>
          <Button
            onClick={() => {
              handleClose()
              handleParentClose()
            }}
            className={classes.backbtn}
          >
            No
          </Button>
          <Button
            onClick={onRemove}
            className={`${classes.changebtn} background-gradient-animation`}
          >
            Yes
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
