export const capitalizeFirstLetter = (str: string) => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};
export const getReadableCategory = (category: string): string => {
  // remove underscores
  const newCategory = category
    .split("_")
    .map((item) => capitalizeFirstLetter(item))
    .join(" ");

  return newCategory;
};

/**
 * @description Function converts a numeric value to a readable count
 * @example
 * 2500000 = 2.5m
 * 2500 = 2.5k
 */
export const formatReadableCount = (number: number) => {

    const absNum = Math.abs(number);
    const suffixes = ['', 'k', 'm', 'b', 't'];
    const suffixNum = Math.floor(Math.log10(absNum) / 3);
  
    if (suffixNum === 0 || absNum < 1000) {
      return number.toString();
    }
  
    let shortValue = number / Math.pow(10, suffixNum * 3);
  
    if (shortValue >= 1000 && shortValue % 1 !== 0 && number !== absNum) {
      return shortValue.toFixed(1) + suffixes[suffixNum] + "+";
    } else {
      return shortValue.toFixed(shortValue % 1 === 0 ? 0 : 1) + suffixes[suffixNum];
    }
}

/**
 * @description Function formats Community name to url readable slug
 * @example
 * Chuks First Community = chuks-first-community
 * Chuk's First Communinity = chuks-first-community
 * Chuk's_First_Communinity = chuks-first-community
 */
export const toUrlSlug = (string: string) => {
  return string;
  // return string
  //   .toLowerCase()
  //   .replace(/[^\w\s-]/g, '') // Remove non-word characters except spaces, underscores, and dashes
  //   .replace(/[\s_]+/g, '-') // Replace spaces and underscores with dashes
  //   .replace(/--+/g, '-') // Replace multiple dashes with a single dash
  //   .trim(); // Remove leading and trailing spaces (if any)
}
