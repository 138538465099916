import { JWTPayload } from '../types/runtimeSchema'
import { jwtRegex, USER_DATA_LOCALSTORAGE_KEY } from '../constants/variables'
import type { AnyObject, Primitives, User } from '../types/runtimeSchema'
import errorConfig from 'meme-generator/utils/config/errorConfig.json'
import imageConfig from 'meme-generator/utils/config/imageConfig.json'
import WebFont from 'webfontloader'
import { createApi } from 'unsplash-js'
import moment from 'moment'
import { getItemFromLocalStorage, setItemInLocalStorage } from 'services/localStorageService'

export const api = createApi({
  accessKey: '2j6awvc4FzagFErNkroZ3gyX5PSFUG1QOOrfCg45CgM'
})

export const capitalize = (str: string): string => {
  return str.replace(str[0], str[0].toLocaleUpperCase())
}

export const dumbPluralize = (str: string): string => {
  if (str.endsWith('s')) return str
  return str + 's'
}

export const generateRandomKey = (prefix: string = 'oooo'): string => {
  return `${prefix}__${new Date().getTime()}`
}

export const firstLetterToUpperCase = (str: string): any => {
  const capitalized = str.substr(0, 1).toUpperCase() + str.substr(1)
  return capitalized
}

export const sleep = async (ms: number): Promise<void> => {
  return await new Promise((resolve) => setTimeout(resolve, ms))
}

export const isGmail = (email: string): boolean => {
  return email?.endsWith('gmail.com')
}

export function deepCompare<T extends Record<Primitives, unknown>>(obj1: T, obj2: T): boolean {
  return Object.entries(obj2).every(([key, value]: [Primitives, unknown]) => {
    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
      return obj1?.[key] !== undefined && deepCompare<T>(obj1[key] as T, value as T)
    }

    return typeof obj1?.[key] === typeof value
  })
}

export const objConformsToUserSchema = (userObj: AnyObject): boolean => {
  // short-circuit schema validation
  return true
}

export const storeUserObjInLocalStorageWithUserSchema = (userObj: User): void | never => {
  try {
    const value = userObj
    setItemInLocalStorage(USER_DATA_LOCALSTORAGE_KEY, value, true)
  } catch (error) {
    console.error(error)
    throw new TypeError('Schemas do not match')
  }
}

export const getUserObjFromLocalStorageWithUserSchema = (): User | never => {
  const storageItem = getItemFromLocalStorage(USER_DATA_LOCALSTORAGE_KEY) as string
  const userObj = JSON.parse(storageItem ?? '')
  // TODO Once validations become more stringent we can add more here
  if (userObj === null) throw new Error('User object not stored in localstorage')
  return userObj
}

export const isJWT = (str: string): boolean => {
  return jwtRegex.test(str)
}

export interface TokenJSON {
  email: string
  type: string
  exp: number
  iat: number
}

const getJWTPayload = (token: string): string | never => {
  const jwtParts: string[] = token.split('.')
  const totalNumberOfJWTSegments: number = 3

  if (jwtParts.length !== totalNumberOfJWTSegments) {
    throw new Error('token received did not have 3 parts')
  }

  return jwtParts[1]
}

const convertJWTPayloadToString = (jwtPayload: string): string | never => {
  try {
    const jwtPayloadString = window.atob(jwtPayload)
    return jwtPayloadString
  } catch (err) {
    throw new Error('JWT payload might not be a valid base64 string')
  }
}

const convertJWTPayloadToObject = (stringPayload: string): JWTPayload | never => {
  try {
    const jsonPayload = JSON.parse(stringPayload)
    return jsonPayload as JWTPayload
  } catch (err) {
    throw new Error(`Looks like stringPayload ${stringPayload} might not be a valid string`)
  }
}

export const getParsedJWTPayload = (token: string): JWTPayload | never => {
  const payload = getJWTPayload(token)
  const stringPayload = convertJWTPayloadToString(payload)
  const payloadObject = convertJWTPayloadToObject(stringPayload)

  return payloadObject
}

function assertIsValidTokenPayload(unknownObject: any): asserts unknownObject is TokenJSON {
  if (!Object.prototype.hasOwnProperty.call(unknownObject, 'email')) {
    throw new Error('Token did not have "email" property')
  }
  if (typeof unknownObject?.email !== 'string') {
    throw new Error('Type of token field "email" is not a string.')
  }

  // NOTE: Access token from backend does not have type
  // if (!unknownObject.hasOwnProperty('type')) {
  //   throw new Error('Token did not have "type" property');
  // }
  // if (typeof unknownObject.type !== 'string') {
  //   throw new Error('Type of token field "type" is not a string.');
  // }

  if (!Object.prototype.hasOwnProperty.call(unknownObject, 'exp')) {
    throw new Error('Token did not have "exp" property')
  }
  if (typeof unknownObject.exp !== 'number') {
    throw new Error('Type of token field "exp" is not a number.')
  }

  if (!Object.prototype.hasOwnProperty.call(unknownObject, 'iat')) {
    throw new Error('Token did not have "iat" property')
  }
  if (typeof unknownObject.iat !== 'number') {
    throw new Error('Type of token field "iat" is not a number.')
  }
}

export const getTokenJSON = (token: string): TokenJSON => {
  const tokenPayloadInBase64 = getJWTPayload(token)
  const jwtPayloadString = convertJWTPayloadToString(tokenPayloadInBase64)
  const tokenPayloadObject = convertJWTPayloadToObject(jwtPayloadString)
  assertIsValidTokenPayload(tokenPayloadObject)
  return tokenPayloadObject
}

export const debounce = (fn: any, ms: any): (this: Window, ev: UIEvent) => any => {
  let timeoutId: any = null

  return (...args: any[]) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn.apply(debounce(fn, ms), args), ms)
  }
}

// NOTE: Commented in case we need it for some reason
// export function getTokenJSON(tokenString: string): any {
//   const jwtPayloadFromBase64String = tokenString.split('.')[1];
//   const jsonJWTPayload = JSON.parse(window.atob(jwtPayloadFromBase64String));
//   return jsonJWTPayload;
// }

// NOTE: These will be adopted as we migrate schemas
// const newRuntimeUserSchema = z.object({
//   username: z.string(),
//   displayName: z.string(),
//   avatar: z.string().or(z.undefined()),
//   backgroundImage: z.string().or(z.undefined()),
//   tokens: z.object({
//     bearer: z.string(),
//     sessionToken: z.string(),
//   }),
// });

// export function storeUserObjInLocalStorageWithNewSchema(userObj: User): void {
//   try {
//     const value = newRuntimeUserSchema.parse(userObj);
//     localStorage.setItem(NEW_USER_DATA_LOCALSTORAGE_KEY, JSON.stringify(value));
//   } catch (error) {
//     console.error(error);
//     throw new TypeError('Schemas do not match');
//   }
// }

// export function objConformsToNewSchema(userObj: unknown): boolean {
//   try {
//     newRuntimeUserSchema.parse(userObj);
//     return true;
//   } catch {
//     return false;
//   }
// }

export const getImageBase64FromUrl = async (url: string): Promise<string> => {
  return await new Promise((resolve, reject) => {
    const x = new XMLHttpRequest()
    x.open('GET', url)
    x.responseType = 'blob'
    x.onload = () => {
      const reader = new FileReader()
      reader.readAsDataURL(x.response)
      reader.onloadend = () => {
        const base64data = reader.result
        if (typeof base64data === 'string') {
          resolve(base64data)
        }
      }
    }
    x.onerror = () => {
      reject(x.responseText)
    }
    x.send()
  })
}

export const extractCanvasBase64FromTemplateBlob = async (file: any): Promise<string> => {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e: any) => {
      try {
        const svgData: any = e.target.result
        const parser = new DOMParser()
        const doc = parser.parseFromString(svgData, 'image/svg+xml')
        const allChildren = doc.children[0].children
        const svgChild = []
        for (let i = 0; i < allChildren.length - 1
          ; i++) {
          if (allChildren[i].tagName === 'svg') {
            svgChild.push(allChildren[i])
          }
        }
        let rectOffsetValue: number = 0

        for (let i = 0; i < svgChild[0].children.length; i++) {
          if (svgChild[0].children[i].tagName === 'svg') {
            rectOffsetValue = i;
          }
        }

        const rotateString: any =
          svgChild[0].children[rectOffsetValue].children[0].children[0].children[0].getAttribute(
            'transform'
          )
        let imageList: Element;
        if (rotateString !== null) {
          imageList = svgChild[0].children[rectOffsetValue].children[0].children[0].children[0].children[0]
        }
        else {
          imageList = svgChild[0].children[rectOffsetValue].children[0].children[0].children[0];
        }
        let initImage: any = ''
        initImage = imageList.getAttribute('href')
        resolve(initImage);
      }
      catch (err) {
        console.error(err)
        reject(err)
      }
    }
    reader.readAsText(file)
  })
}

export const handleFiles = async (file: any): Promise<any> => {
  const reader = new FileReader()
  return await new Promise((resolve, reject) => {
    reader.onload = (e: any) => {
      try {
        const svgData: any = e.target.result
        const parser = new DOMParser()
        const doc = parser.parseFromString(svgData, 'image/svg+xml')
        const allChildren = doc.children[0].children
        const defChild = []
        const svgChild = []
        const textChildren: any = []
        const layeredImagesChildren: any[] = []
        for (let i = 0; i < allChildren.length; i++) {
          if (allChildren[i].tagName === 'defs') {
            defChild.push(allChildren[i])
          } else if (allChildren[i].tagName === 'svg') {
            if (allChildren[i].getAttribute('y')) {
              let yAttribValue = allChildren[i].getAttribute('y')
              if (Number(yAttribValue) > 0) {
                continue;
              }
              svgChild.push(allChildren[i])
            }
          } else if (allChildren[i].tagName === 'g') {
            textChildren.push(allChildren[i])
          } else if (allChildren[i].tagName === 'image') {
            if (i < (allChildren.length - 2)) {
              layeredImagesChildren.push(allChildren[i])
            }
          }
        }
        const svgResolution = {
          width: parseInt(svgChild[0].getAttribute('width') ?? ''),
          height: parseInt(svgChild[0].getAttribute('height') ?? '')
        }
        const filterChildren = svgChild[0].children[0].children
        const filterSelected = filterChildren[0].id.substring(0, filterChildren[0].id.length - 1)
        const filterSelectedLength = Number(filterChildren[0].id.slice(-1))
        const tempContrastScale = Math.round(
          Number(filterChildren[filterSelectedLength].children[0].getAttribute('intercept')) * -400
        )
        const contrastScale = tempContrastScale === 0 ? 0 : tempContrastScale
        const brightnessScale = Math.round(
          (Number(filterChildren[filterSelectedLength + 1].children[0].getAttribute('slope')) - 1) *
          133.33
        )
        const saturationScale = Math.round(
          (Number(filterChildren[filterSelectedLength + 2].getAttribute('values')) - 1) * 100
        )
        const warmthScale = Math.round(
          (Number(filterChildren[filterSelectedLength + 3].children[0].getAttribute('slope')) - 1) *
          200
        )
        let rectOffsetValue: number = 0
        let spaceValue: number = 0
        let spaceTopEnable: number = 0
        let spaceBotEnable: number = 0
        let spaceColor: any = '#898989'
        let defImageResolution: { width: number, height: number } = { width: 0, height: 0 }
        for (let i = 0; i < svgChild[0].children.length; i++) {
          if (svgChild[0].children[i].tagName === 'rect') {
            if (svgChild[0].children[i].getAttribute('y') === '0') {
              spaceTopEnable = 1;
            }
            else {
              spaceBotEnable = 1;
            }

            spaceValue = Math.round(
              (parseInt(svgChild[0].children[i].getAttribute('height') ?? '') * 100) /
              defImageResolution.height
            )
            spaceColor = svgChild[0].children[2].getAttribute('fill')
          }
          else if (svgChild[0].children[i].tagName === 'svg') {
            defImageResolution.height = parseInt(svgChild[0].children[i].getAttribute('height')?.slice(0, -2)!);
            defImageResolution.width = parseInt(svgChild[0].children[i].getAttribute('width')?.slice(0, -2)!);
            rectOffsetValue = i;
          }
        }
        if (spaceTopEnable === 1 || spaceBotEnable === 1) {
          spaceColor = svgChild[0].children[rectOffsetValue - 1].getAttribute('fill');
          if (spaceTopEnable === 1) {
            spaceValue = Math.round(
              (parseInt(svgChild[0].children[rectOffsetValue].getAttribute('y') ?? '') * 100) /
              defImageResolution.height
            )
          }
          else if (spaceBotEnable === 1) {
            spaceValue = Math.round(
              (parseInt(svgChild[0].children[rectOffsetValue - 1].getAttribute('height')?.slice(0, -2)!) * 100) /
              defImageResolution.height
            )
          }
        }
        const scaleString: any =
          svgChild[0].children[rectOffsetValue].children[0].getAttribute('transform')
        const imgDimensionScale = parseFloat(
          scaleString.substring(scaleString.indexOf('scale') + 6, scaleString.indexOf('scale') + 9)
        )
        const flipString: any =
          svgChild[0].children[rectOffsetValue].children[0].children[0].getAttribute('transform')
        const flipH = parseFloat(
          flipString
            .substring(flipString.indexOf('scale') + 6, flipString.indexOf('translate') - 2)
            .split(',')[0]
        )
        const flipV = parseFloat(
          flipString
            .substring(flipString.indexOf('scale') + 6, flipString.indexOf('translate') - 2)
            .split(',')[1]
        )
        const rotateString: any =
          svgChild[0].children[rectOffsetValue].children[0].children[0].children[0].getAttribute(
            'transform'
          )

        let rotationScale: number = 0;
        let imageList: Element;
        if (rotateString !== null) {
          rotationScale = parseInt(
            rotateString.substring(7, rotateString.length - 1).split(' ')[0]
          )
          imageList = svgChild[0].children[rectOffsetValue].children[0].children[0].children[0].children[0]
        }
        else {
          imageList = svgChild[0].children[rectOffsetValue].children[0].children[0].children[0];
        }
        let imagePosition = { x: 0, y: 0 }
        let initImage: any = ''
        let imageResolution = { width: 0, height: 0 }
        let scaledImage = { data: '', height: 0 }
        imagePosition = {
          x: parseInt(imageList.getAttribute('x') ?? ''),
          y: parseInt(imageList.getAttribute('y') ?? '')
        }
        initImage = imageList.getAttribute('href')
        imageResolution = {
          width: parseInt(imageList.getAttribute('width') ?? ''),
          height: parseInt(imageList.getAttribute('height') ?? '')
        }
        const rotationScaleT: any[] = []
        const rectPosition: any[] = []
        const vAlign: any[] = []
        const hAlign: any[] = []
        const rectDimension: any[] = []
        const fontSize: any[] = []
        const fontFamily: any[] = []
        const fontWeight: any[] = []
        const fontStyle: any[] = []
        const shadowWidth: any[] = []
        const strokeValue: any[] = []
        const strokeType: any[] = []
        const letterSpace: any[] = []
        const strokeWidth: any[] = []
        const isUpperCase: any[] = []
        const colorValue: any[] = []
        const textValue: any[] = []
        const textRectWidth: any[] = []
        const textRectHeight: any[] = []
        const textPlaceholder: any[] = []
        for (let i = 0; i < textChildren.length; i++) {
          const rotationString: any = textChildren[i].getAttribute('transform')
          const rotationValue = Number(
            rotationString.substring(7, rotationString.length - 1).split(' ')[0]
          )
          rotationScaleT.push(rotationValue)
          const rectPositionValue = {
            x: Number(textChildren[i].getAttribute('data-posx')),
            y: Number(textChildren[i].getAttribute('data-posy'))
          }
          rectPosition.push(rectPositionValue)
          const hAlignValue = parseFloat(textChildren[i].getAttribute('data-halign') ?? '')
          const vAlignValue = parseFloat(textChildren[i].getAttribute('data-valign') ?? '')
          const strokeTypeValue = Number(textChildren[i].getAttribute('data-stroketype'))
          hAlign.push(hAlignValue)
          vAlign.push(vAlignValue)
          strokeType.push(strokeTypeValue)
          let textChildrenIndex = 0
          if (strokeTypeValue === 2) {
            textChildrenIndex = 1
          }
          textRectWidth.push(Number(textChildren[i].getAttribute('data-textminwidth')))
          textRectHeight.push(Number(textChildren[i].getAttribute('data-textminheight')))
          const rectposxplusdimensionby2 = Number(
            rotationString.substring(7, rotationString.length - 1).split(' ')[1]
          )
          const rectposyplusdimensionby2 = Number(
            rotationString.substring(7, rotationString.length - 1).split(' ')[2]
          )
          const rectDimensionValue = {
            width: (rectposxplusdimensionby2 - rectPositionValue.x) * 2,
            height: (rectposyplusdimensionby2 - rectPositionValue.y) * 2
          }
          rectDimension.push(rectDimensionValue)
          fontSize.push(
            parseInt(
              textChildren[i].children[textChildrenIndex]
                .getAttribute('style')
                .split(';')[0]
                .split(':')[1]
            )
          )
          fontFamily.push(
            textChildren[i].children[textChildrenIndex]
              .getAttribute('style')
              .split(';')[1]
              .split(':')[1]
              .replaceAll('"', '')
              .trim()
          )
          fontWeight.push(
            Number(
              textChildren[i].children[textChildrenIndex]
                .getAttribute('style')
                .split(';')[2]
                .split(':')[1]
            )
          )
          fontStyle.push(
            textChildren[i].children[textChildrenIndex]
              .getAttribute('style')
              .split(';')[3]
              .split(':')[1]
              .trim()
          )
          const shadowString: any = textChildren[i].children[textChildrenIndex]
            .getAttribute('style')
            .split(';')[4]
            .split(':')[1]
            .trim()
          shadowWidth.push(
            shadowString.substring(shadowString.indexOf('0px 0px ') + 8, shadowString.length)
          )
          letterSpace.push(
            textChildren[i].children[textChildrenIndex]
              .getAttribute('style')
              .split(';')[5]
              .split(':')[1]
              .trim()
          )
          strokeValue.push(
            textChildren[i].children[textChildrenIndex]
              .getAttribute('style')
              .split(';')[6]
              .split(':')[1]
              .trim()
          )
          strokeWidth.push(
            textChildren[i].children[textChildrenIndex]
              .getAttribute('style')
              .split(';')[7]
              .split(':')[1]
              .trim()
          )
          isUpperCase.push(
            textChildren[i].children[textChildrenIndex]
              .getAttribute('style')
              .split(';')[8]
              .split(':')[1]
              .trim()
          )
          colorValue.push(
            textChildren[i].children[textChildrenIndex + 1]
              .getAttribute('style')
              .split(';')[5]
              .split(':')[1]
              .trim()
          )
          const ntspan = textChildren[i].children[textChildrenIndex].children.length
          const tspanarray = []
          for (let j = 0; j < ntspan; j++) {
            tspanarray.push(textChildren[i].children[textChildrenIndex].children[j].innerHTML)
          }
          textValue.push(tspanarray)
          if (i === 0) {
            textPlaceholder.push('Top text...')
          } else if (i === 1) {
            textPlaceholder.push('Bottom text...')
          } else {
            textPlaceholder.push('Text...')
          }
        }
        WebFont.load({
          google: {
            families: fontFamily
          }
        })
        const layeredImagePosition: any[] = []
        const layeredImage: any[] = []
        const layeredImageResolution: any[] = []
        const rotationScaleL: any[] = []
        const scaledLayeredImage: any[] = []
        for (let i = 0; i < layeredImagesChildren.length; i++) {
          layeredImagePosition.push({
            x: Number(layeredImagesChildren[i].getAttribute('x')),
            y: Number(layeredImagesChildren[i].getAttribute('y'))
          })
          layeredImage.push(layeredImagesChildren[i].getAttribute('href'))
          layeredImageResolution.push({
            width: Number(layeredImagesChildren[i].getAttribute('width')),
            height: Number(layeredImagesChildren[i].getAttribute('height'))
          })
          const rotationString = layeredImagesChildren[i].getAttribute('transform')
          const rotationValue = Number(
            rotationString.substring(7, rotationString.length - 1).split(' ')[0]
          )
          rotationScaleL.push(rotationValue)
          const img = new Image()
          img.src = layeredImagesChildren[i].getAttribute('href')
          img.onload = () => {
            const imgWidth = img.width
            const imgHeight = img.height
            const offScreenCanvas = document.createElement('canvas')
            const offScreenCanvasCtx: any = offScreenCanvas.getContext('2d')
            const scaledWidth = 225
            const scaledHeight = (scaledWidth / imgWidth) * imgHeight
            offScreenCanvas.width = 225
            offScreenCanvas.height = scaledHeight
            const scaleValueWidth = scaledWidth / imgWidth
            const scaleValueHeight = scaledHeight / imgHeight
            const img2 = document.createElement('img')
            img2.src = layeredImagesChildren[i].getAttribute('href')
            img2.onload = () => {
              offScreenCanvasCtx.scale(scaleValueWidth, scaleValueHeight)
              offScreenCanvasCtx.drawImage(img, 0, 0)
              scaledLayeredImage.push({ data: offScreenCanvas.toDataURL(), height: scaledHeight })
            }
          }
        }
        const img: any = document.createElement('img')
        img.src = imageList.getAttribute('href')
        img.onload = () => {
          const offScreenCanvas = document.createElement('canvas')
          const offScreenCanvasCtx: any = offScreenCanvas.getContext('2d')
          offScreenCanvas.width = 225
          const newHeight =
            (225 / parseInt(imageList.getAttribute('width') ?? '')) *
            parseInt(imageList.getAttribute('height') ?? '')
          offScreenCanvas.height = newHeight
          offScreenCanvasCtx.scale(
            225 / parseInt(imageList.getAttribute('width') ?? ''),
            newHeight / parseInt(imageList.getAttribute('height') ?? '')
          )
          offScreenCanvasCtx.drawImage(img, 0, 0)
          scaledImage = { data: offScreenCanvas.toDataURL(), height: newHeight }
          const dataobj = {
            svgResolution: svgResolution,
            filterSelected: filterSelected,
            contrastScale: contrastScale,
            brightnessScale: brightnessScale,
            saturationScale: saturationScale,
            warmthScale: warmthScale,
            spaceValue: spaceValue,
            spaceTopEnable: spaceTopEnable,
            spaceBotEnable: spaceBotEnable,
            spaceColor: spaceColor,
            defImageResolution: defImageResolution,
            imgDimensionScale: imgDimensionScale,
            flipH: flipH,
            flipV: flipV,
            rotationScale: rotationScale,
            imagePosition: imagePosition,
            initImage: initImage,
            imageResolution: imageResolution,
            rotationScaleT: rotationScaleT,
            rectPosition: rectPosition,
            vAlign: vAlign,
            hAlign: hAlign,
            rectDimension: rectDimension,
            fontSize: fontSize,
            fontFamily: fontFamily,
            fontWeight: fontWeight,
            fontStyle: fontStyle,
            shadowWidth: shadowWidth,
            strokeValue: strokeValue,
            strokeType: strokeType,
            letterSpace: letterSpace,
            strokeWidth: strokeWidth,
            isUpperCase: isUpperCase,
            colorValue: colorValue,
            textValue: textValue,
            layeredImagePosition: layeredImagePosition,
            layeredImage: layeredImage,
            layeredImageResolution: layeredImageResolution,
            rotationScaleL: rotationScaleL,
            scaledLayeredImage: scaledLayeredImage,
            textRectWidth: textRectWidth,
            textRectHeight: textRectHeight,
            scaledImage: scaledImage,
            textPlaceholder: textPlaceholder
          }
          const a = {
            data: dataobj,
            type: 'svg'
          }
          resolve(a)
        }
      } catch (err) {
        console.log(err)
        console.log('Invalid SVG file')
      }
    }
    reader.readAsText(file)
  })
}

export const handleDownload = async (event: any, imageUrl: string): Promise<any> => {
  event.preventDefault()
  const response = await fetch(imageUrl)
  if (response.status === 201 || response.status === 200) {
    const blob = await response.blob()
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'youmeme-' + response.url.split('/').slice(-1)[0].split('.')[0]
    document.body.appendChild(link)
    link.click()
    link.remove()
    return { success: true }
  }
}

export const getRelativeDate = (dateString: any): any => {
  const date = new Date(dateString)

  return moment([
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  ]).fromNow()
}

export const deleteMultipleSpaces = (str: string): string => {
  return str?.trim()
}

// TODO: Add that logic in BE
export const displayNameFormatter = (value: string): string => {
  const part = value?.substring(1, value?.length)
  return value?.charAt(0).toUpperCase() + part?.toLowerCase()
}

const removeAt = (str: any, id: number): string => {
  return `${str.substr(0, id)}${str.substr(id + 1)}`
}

export const rightValueForUsername = (value: string): string => {
  if (value.startsWith('@')) {
    return removeAt(value, 0)
  }

  return value
}

export const isEmpty = (array: any): boolean => {
  return Array.isArray(array) && (array.length == 0 || array.every(isEmpty) || array.every((val) => val === ''))
}
