import { useState, forwardRef, Ref, useEffect } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { InputBase } from "@material-ui/core";

import PageTemplate from "../PageTemplate";
import PageLayout from "../PageLayout";
import useCommunitiesWrapperStyles from "./style";
import { SearchIcon } from "components/shared/SvgIcons";
import { useAuth } from "context/auth/authContext";

interface LinkTabProps {
  label: string;
  href: string;
  value: string;
  className?: any;
  disabled?: boolean;
}

function LinkTab({ href, ...props }: LinkTabProps) {
  const navigate = useNavigate();
  return (
    <Tab
      component='a'
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        navigate(href);
      }}
      href={href}
      {...props}
    />
  );
}

const CommunitiesPageWrapper = () => {
  const classes = useCommunitiesWrapperStyles();
  const location = useLocation();
  const page = location.pathname.split("/")[2];
  const [value, setValue] = useState<string>(page);
  const { isAuthenticated } = useAuth();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(page);
  }, [page]);

  return (
    <PageTemplate>
      <PageLayout>
        <Box className={classes.wrapper}>
          {isAuthenticated && (
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='communities-nav-tab'
              className={classes.tabContainer}
            >
              <LinkTab
                label='Explore'
                href='explore'
                value='explore'
                className={classes.tabItem}
              />
              {isAuthenticated && (
                <LinkTab
                  label='Joined'
                  href='joined'
                  value='joined'
                  className={classes.tabItem}
                />
              )}
              {isAuthenticated && (
                <LinkTab
                  label='Managing'
                  href='managing'
                  value='managing'
                  className={classes.tabItem}
                />
              )}
            </Tabs>
          )}
          <Outlet />
        </Box>
      </PageLayout>
    </PageTemplate>
  );
};

interface SearchCommunityFormProps {
  placeholder?: string;
}

export const SearchCommunityForm = forwardRef(
  (props: SearchCommunityFormProps, ref: Ref<HTMLInputElement>) => {
    const classes = useCommunitiesWrapperStyles();
    const { placeholder = "Search communities" } = props;
    return (
      <Box component='section' aria-label='search community'>
        <InputBase
          className={classes.search}
          fullWidth
          placeholder={placeholder}
          inputRef={ref}
          startAdornment={
            <SearchIcon
              fill='#8A98B4'
              style={{ width: "1rem", height: "1rem" }}
              fontSize='small'
            />
          }
        />
      </Box>
    );
  }
);

export const CommunitiesPageTitle = ({ title }: { title: string }) => {
  const classes = useCommunitiesWrapperStyles();

  return (
    <Box display='flex' justifyContent='space-between' alignItems='center'>
      <Typography className={classes.pageTitle}>{title}</Typography>
    </Box>
  );
};

export const CommunitiesOutletContainer = ({ children }: { children: any }) => {
  const classes = useCommunitiesWrapperStyles();
  return <Box className={classes.outletContainer}>{children}</Box>;
};

export const NoCommunitiesContainer = () => {
  return <EmptyCommunities />;
};

export const EmptyCommunities = () => {
  const classes = useCommunitiesWrapperStyles();
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      height='200px'
    >
      <Typography className={classes.emptyCommunityTitle}>
        No communities
      </Typography>
      <Typography className={classes.emptyCommunityDescription}>
        There are no available communities at this time. Check back later again.
      </Typography>
    </Box>
  );
};

export const EmptyCommunityMemes = () => {
  const classes = useCommunitiesWrapperStyles();
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      height='200px'
    >
      <Typography className={classes.emptyCommunityTitle}>No Memes</Typography>
      <Typography className={classes.emptyCommunityDescription}>
        There are no available community memes at this time. Check back later
        again.
      </Typography>
    </Box>
  );
};

export const EmptyCommunityMembers = () => {
  const classes = useCommunitiesWrapperStyles();
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      height='200px'
    >
      <Typography className={classes.emptyCommunityTitle}>
        No Members
      </Typography>
      <Typography className={classes.emptyCommunityDescription}>
        There are no available community members at this time
      </Typography>
    </Box>
  );
};
export const NoSearchCommunitiesFound = () => {};

export default CommunitiesPageWrapper;
