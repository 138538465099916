import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Trendings from './Trendings'

const useStyles = makeStyles({
  container: {
    marginTop: 32,
    paddingLeft: 10,
    display: 'none'
  },
  '@media (max-width: 550px)': {
    container: {
      display: 'block'
    }
  }
})

const TrendingPanel = (): JSX.Element => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Trendings/>
    </Box>
  )
}

export default TrendingPanel
