import { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, Box, Typography, Button, Fade } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { MetaMask } from "assets/MetaMask";
import { Rainbow } from "assets/Rainbow";
import { Coinbase } from "assets/Coinbase";
import { Argent } from "assets/Argent";
import { ArrowBack } from "assets/ArrowBack";
import { ArrowRight } from "assets/ArrowRight";
import { MetaMaskBadge } from "assets/MetaMaskBadge";
import { imagesSvg } from "constants/images";
import useWeb3 from "hooks/useWeb3";

const useStyles = makeStyles({
  center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  footerText: {
    fontSize: 13,
    fontWeight: 500,
  },
  footer: {
    display: "flex",
    alignItems: "center",
    padding: "0 34px",
    background:
      "linear-gradient(90deg, rgba(32, 42, 65, 0.9) 0%, rgba(32, 42, 65, 0) 100%)",
    filter: "drop-shadow(0px 0px 70px rgba(14, 20, 34, 0.4))",
    backdropFilter: "blur(15px)",
    borderRadius: " 0px 0px 18px 18px",
    height: 64,
  },
  selectListContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  createButton: {
    background: "#625FFF",
    width: 169,
    height: 46,
    borderRadius: 100,
    color: "white",
    fontWeight: 700,
    fontSize: 14,
    "&:hover": {
      background: "#625FFF",
    },
  },
  flex: {
    display: "flex",
  },
  questionSubtitle: {
    color: "#8C9AB6E5",
    fontSize: 13,
    fontWeight: 500,
  },
  questionBody: {
    color: "#E9F0F9",
    fontSize: 16,
    fontWeight: 700,
  },
  questionTitle: {
    color: "#E9F0F9",
    fontSize: 18,
    fontWeight: 800,
    marginBottom: 34,
  },
  rightContent: {
    padding: 34,
  },
  right: {
    width: "60%",
  },
  walletBox: {
    display: "flex",
    alignItems: "center",
  },
  iconBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 14,
    height: 52,
    transition: "all 0.5s",
    "& svg:nth-child(2)": {
      opacity: 0,
      transition: "all 0.5s",
      transform: "translateX(-20px)",
    },
    "&:hover": {
      background: "#293651",
      cursor: "pointer",
      borderRadius: 100,
      "& svg:nth-child(2)": {
        opacity: 1,
        transform: "translateX(0)",
      },
    },
  },
  walletName: {
    color: "#8A98B4E5",
    fontWeight: 600,
    marginLeft: 12,
  },
  walletList: {
    marginTop: 27,
  },
  suggested: {
    fontSize: 13,
    color: "#8A98B4E5",
    fontWeight: 500,
    marginLeft: 14,
  },
  leftContent: {
    padding: "34px",
  },
  title: {
    color: "white",
    fontWeight: 800,
    fontSize: 18,
  },
  titleBox: {
    height: 90,
    width: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #293651",
    padding: "0 48px",
  },
  left: {
    borderRight: "1px solid #293651",
    width: "40%",
  },
  paper: {
    width: "968px",
    mixBlendMode: "normal",
    boxSizing: "border-box",
    overflowY: "hidden",
    overflowX: "hidden",
    background: "rgba(32, 42, 65, 1)",
    boxShadow: " 0px 0px 70px rgba(0, 6, 18, 0.4)",
    //backdropFilter: "blur(10px)",
    borderRadius: "18px",
    overflow:"visible !important",
  },

  modal: {
    display: "flex",
    justifyContent: "center",
   alignItems: "center",
    position: "absolute",
    backdropFilter: "blur(10px)",
    transform: "scale(0)",
    animation: "$fade 100ms forwards",
  },
  "@keyframes fade": {
    to: {
      transform: "scale(1)",
    },
  },
  content: {
    boxSizing: "border-box",
    display: "flex",
    zIndex:9,
  },

  
  closeBtn: {
    position: "absolute",
    top: -20,
    right: 0,
    color: "white",
    background: "var(--gradient-fall-back)",
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.8)',
    height: '42px',
    width: '42px',
    "&:hover":{
      background: "var(--gradient-fall-back)",
    }
  },
  div: {
    content: " '' ",
    height: '63px',
    background: 'var(--main-gradient)',
    width: '900px',
    borderRadius: '10px',
    position: 'absolute',
    marginTop: '-6px',
    marginLeft:12,
    zIndex:-1
  },
  "@media (max-width: 1020px)": {
    paper: {
      width: 670,
    },
    div: {
      width: 620,
    },
    purpleHead: {
      left: 335,
    },
  },
  "@media (max-height: 630px)": {
    box: {
      marginTop: 10,
    },
  },
  "@media (max-width: 700px)": {
    paper: {
      width: "100%",
    },
    div: {
      width: "100%",
    },
    purpleHead: {
      left: "45%",
    },
  },
});

const LensModal = (props: {
  open: boolean;
  handleClose(event: any, reason?: any): void;
  connect(wallet: string): void;
}): JSX.Element => {
  const { open, handleClose } = props;
  const classes = useStyles();
  const { logout } = useWeb3();
  const [stage, setStage] = useState("question");
  useEffect(() => {
    if (stage === "question") logout();
    if (stage === "MetaMask") props.connect('metamask');
  }, [stage])
  useEffect(() => {
    if (!open) setStage("question");
  }, [open])
  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        handleClose(event, reason);
        setStage("question");
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
  <Box style={{position:"fixed"}}>
      <Box className={classes.div} />
        <Box className={classes.paper}>
       
          <IconButton className={classes.closeBtn} onClick={(event)=>{
                  handleClose(event);
                  setStage("question");
                  logout();
          }}>
            <Close />
          </IconButton>
          <Box className={classes.content}>
            <Box className={classes.left}>
              <Box className={classes.titleBox}>
                <h1 className={classes.title}>Connect Wallet</h1>
              </Box>
              <Box className={classes.leftContent}>
                <Typography className={classes.suggested}>
                  Suggested Wallets
                </Typography>
                <Box className={classes.walletList}>
                  <Box
                    className={classes.iconBox}
                    onClick={() => setStage("MetaMask")}
                  >
                    <Box className={classes.walletBox}>
                      <MetaMask style={{ fontSize: 30 }} />
                      <Typography className={classes.walletName}>
                        MetaMask
                      </Typography>
                    </Box>
                    <ArrowRight />
                  </Box>

                  <Box
                    className={classes.iconBox}
                    onClick={() => setStage("WalletConnect")}
                  >
                    <Box className={classes.walletBox}>
                      <img src="/assets/connectIcons/wallet_connect_icon.svg" />
                      <Typography className={classes.walletName}>
                        WalletConnect
                      </Typography>
                    </Box>
                    <ArrowRight />
                  </Box>

                  <Box
                    className={classes.iconBox}
                    onClick={() => setStage("Rainbow")}
                    style={{ pointerEvents: "none", opacity: 0.5 }}
                  >
                    <Box className={classes.walletBox}>
                      <Rainbow style={{ fontSize: 30 }} />
                      <Typography className={classes.walletName}>
                        Rainbow
                      </Typography>
                    </Box>
                    <ArrowRight />
                  </Box>

                  <Box
                    className={classes.iconBox}
                    style={{ pointerEvents: "none", opacity: 0.5 }}
                    onClick={() => setStage("Coinbase Wallet")}
                  >
                    <Box className={classes.walletBox}>
                      <Coinbase style={{ fontSize: 30 }} />
                      <Typography className={classes.walletName}>
                        Coinbase Wallet
                      </Typography>
                    </Box>
                    <ArrowRight />
                  </Box>

                  <Box
                    className={classes.iconBox}
                    style={{ pointerEvents: "none", opacity: 0.5 }}
                    onClick={() => setStage("Argent")}
                  >
                    <Box className={classes.walletBox}>
                      <Argent style={{ fontSize: 30 }} />
                      <Typography className={classes.walletName}>
                        Argent
                      </Typography>
                    </Box>
                    <ArrowRight />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.right}>
              <>
                {stage === "question" && (
                  <Fade in={stage === "question"}>
                    <Box className={classes.rightContent}>
                      <Typography className={classes.questionTitle}>
                      New in Crypto? Create a wallet
                      </Typography>
                        <img
                        src="/assets/gifs/ez.gif"
                        style={{ width: "100%" , height:364.29,objectFit:'cover',borderRadius:15 , marginBottom:15}}
                      />
                      <Typography className={classes.questionBody}>
                        The place where you keep your digital assets safe!
                      </Typography>
                      <Typography className={classes.questionSubtitle}>
                        Wallets are used to send, receive, store and display
                        digital assets like Ethereum and NFTs.
                      </Typography>
                      <Box className={classes.flex} style={{ marginTop: 20 }}>
                        <Button
                          className={classes.createButton}
                          onClick={() => setStage("select")}
                        >
                          Create Wallet
                        </Button>
                        <Button
                          style={{
                            background: "transparent",
                            color: "#817EFF",
                            marginLeft: 20,
                          }}
                          href="https://youmemeworld.medium.com/how-to-set-up-a-metamask-wallet-ca24bf53ca8f"
                          target="_blank"
                        >
                          Learn More
                        </Button>
                      </Box>
                    </Box>
                  </Fade>
                )}
                {stage === "select" && (
                  <Fade in={stage === "select"}>
                    <Box
                      className={classes.rightContent}
                      style={{ padding: 0, position: "relative" }}
                    >
                      <IconButton
                        style={{
                          background: "#293651",
                          position: "absolute",
                          left: -20,
                          top: 27,
                          width: 36,
                          height: 36,
                        }}
                        onClick={() => setStage("question")}
                      >
                        <ArrowBack />
                      </IconButton>
                      <Box style={{ padding: "34px" }}>
                        <Typography className={classes.questionTitle}>
                        Create a wallet
                        </Typography>
                        <Box className={classes.selectListContainer}>
                          <Box
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <MetaMask style={{ fontSize: 46 }} />
                            <Box style={{ marginLeft: 16 }}>
                              <Typography style={{ fontWeight: 800 }}>
                                MetaMask
                              </Typography>
                              <Typography
                                style={{
                                  color: "#8A98B4E5",
                                  fontSize: 13,
                                  fontWeight: 500,
                                }}
                              >
                                Mobile Wallet and Extension
                              </Typography>
                            </Box>
                          </Box>
                          <Button
                            style={{
                              fontWeight: 700,
                              background: "#293651",
                              color: "white",
                              borderRadius: 100,
                              width: 88,
                              height: 46,
                            }}
                            onClick={() => window.open('https://metamask.io')}
                          >
                            GET
                          </Button>
                        </Box>

                        {/* <Box className={classes.selectListContainer}>
                          <Box
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Phantom style={{ fontSize: 46 }} />
                            <Box style={{ marginLeft: 16 }}>
                              <Typography style={{ fontWeight: 800 }}>
                                WalletConnect
                              </Typography>
                              <Typography
                                style={{
                                  color: "#8A98B4E5",
                                  fontSize: 13,
                                  fontWeight: 500,
                                }}
                              >
                                Mobile Wallet
                              </Typography>
                            </Box>
                          </Box>
                          <Button
                            style={{
                              fontWeight: 700,
                              background: "#293651",
                              color: "white",
                              borderRadius: 100,
                              width: 88,
                              height: 46,
                            }}
                            onClick={() => window.open('https://www.walletconnect.com')}
                          >
                            GET
                          </Button>
                        </Box> */}

                        <Box
                          style={{ pointerEvents: "none", opacity: 0.5 }}
                          className={classes.selectListContainer}
                        >
                          <Box
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Rainbow style={{ fontSize: 46 }} />
                            <Box style={{ marginLeft: 16 }}>
                              <Typography style={{ fontWeight: 800 }}>
                                Rainbow
                              </Typography>
                              <Typography
                                style={{
                                  color: "#8A98B4E5",
                                  fontSize: 13,
                                  fontWeight: 500,
                                }}
                              >
                                Mobile Wallet and Extension
                              </Typography>
                            </Box>
                          </Box>
                          <Button
                            style={{
                              fontWeight: 700,
                              background: "#293651",
                              color: "white",
                              borderRadius: 100,
                              width: 88,
                              height: 46,
                            }}
                          >
                            GET
                          </Button>
                        </Box>

                        <Box
                          style={{ pointerEvents: "none", opacity: 0.5 }}
                          className={classes.selectListContainer}
                        >
                          <Box
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Coinbase style={{ fontSize: 46 }} />
                            <Box style={{ marginLeft: 16 }}>
                              <Typography style={{ fontWeight: 800 }}>
                                Coinbase Wallet
                              </Typography>
                              <Typography
                                style={{
                                  color: "#8A98B4E5",
                                  fontSize: 13,
                                  fontWeight: 500,
                                }}
                              >
                                Mobile Wallet
                              </Typography>
                            </Box>
                          </Box>
                          <Button
                            style={{
                              fontWeight: 700,
                              background: "#293651",
                              color: "white",
                              borderRadius: 100,
                              width: 88,
                              height: 46,
                            }}
                          >
                            GET
                          </Button>
                        </Box>

                        <Box
                          style={{ pointerEvents: "none", opacity: 0.5 }}
                          className={classes.selectListContainer}
                        >
                          <Box
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Argent style={{ fontSize: 46 }} />
                            <Box style={{ marginLeft: 16 }}>
                              <Typography style={{ fontWeight: 800 }}>
                                Argent
                              </Typography>
                              <Typography
                                style={{
                                  color: "#8A98B4E5",
                                  fontSize: 13,
                                  fontWeight: 500,
                                }}
                              >
                                Mobile Wallet and Extension
                              </Typography>
                            </Box>
                          </Box>
                          <Button
                            style={{
                              fontWeight: 700,
                              background: "#293651",
                              color: "white",
                              borderRadius: 100,
                              width: 88,
                              height: 46,
                            }}
                          >
                            GET
                          </Button>
                        </Box>
                      </Box>
                      <Box
                        style={{
                          borderTop: "1px solid #293651",
                          padding: "34px 34px 79px 34px",
                        }}
                      >
                        <Typography className={classes.questionBody}>
                          Not what you were looking for?
                        </Typography>
                        <Typography className={classes.questionSubtitle}>
                          Wallets are used to send, receive, store, and display
                          digital assets like Ethereum and NFTs.
                        </Typography>
                      </Box>
                    </Box>
                  </Fade>
                )}

                {stage !== "question" && stage !== "select" && (
                  <Fade in={stage !== "question" && stage !== "select"}>
                    <Box
                      className={classes.rightContent}
                      style={{ position: "relative", height: "100%" }}
                    >
                      <IconButton
                        style={{
                          background: "#293651",
                          position: "absolute",
                          left: -20,
                          top: 27,
                          width: 36,
                          height: 36,
                        }}
                        onClick={() => setStage("question")}
                      >
                        <ArrowBack />
                      </IconButton>
                      <Typography className={classes.questionTitle}>
                        Connect with {stage}
                      </Typography>
                      <Box className={classes.center}>
                        {stage === "MetaMask" && (
                          <>
                            {" "}
                            <MetaMaskBadge />
                            <Typography
                              className={classes.questionTitle}
                              style={{ marginTop: 26, textAlign: "center" }}
                            >
                              Opening {stage}...
                            </Typography>
                            <Typography
                              className={classes.questionSubtitle}
                              style={{ textAlign: "center", marginTop: -30 }}
                            >
                              Confirm connection in the extension
                            </Typography>
                          </>
                        )}
                        {stage === "WalletConnect" && (
                          <>
                            {" "}
                            {/* <img src="/assets/connectIcons/walletconnectscan.png" /> */}
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                                width: "100%",
                                marginTop: 35,
                                paddingBottom: 22,
                              }}
                            >
                              <Typography className={classes.questionSubtitle}>
                                Need the official WalletConnect modal?
                              </Typography>
                              <Button
                                onClick={() => props.connect('walletconnect')}
                                style={{
                                  fontWeight: 700,
                                  background: "#293651",
                                  color: "white",
                                  fontSize: 14,
                                  borderRadius: 100,
                                  width: 88,
                                  height: 46,
                                }}
                              >
                                Open
                              </Button>
                            </Box>
                          </>
                        )}

                        {stage === "Rainbow" && (
                          <>
                            {" "}
                            <img src="/assets/connectIcons/rainbowscan.png" />
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                marginTop: 35,
                                paddingBottom: 22,
                              }}
                            >
                              <Typography className={classes.questionSubtitle}>
                                Need the official WalletConnect modal?
                              </Typography>
                              <Button
                                style={{
                                  fontWeight: 700,
                                  background: "#293651",
                                  color: "white",
                                  fontSize: 14,
                                  borderRadius: 100,
                                  width: 88,
                                  height: 46,
                                }}
                              >
                                Open
                              </Button>
                            </Box>
                          </>
                        )}

                        {stage === "Coinbase Wallet" && (
                          <>
                            {" "}
                            <img src="/assets/connectIcons/coinwalletscan.png" />
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                marginTop: 35,
                                paddingBottom: 22,
                              }}
                            >
                              <Typography className={classes.questionSubtitle}>
                                Need the official WalletConnect modal?
                              </Typography>
                              <Button
                                style={{
                                  fontWeight: 700,
                                  background: "#293651",
                                  color: "white",
                                  fontSize: 14,
                                  borderRadius: 100,
                                  width: 88,
                                  height: 46,
                                }}
                              >
                                Open
                              </Button>
                            </Box>
                          </>
                        )}

                        {stage === "Argent" && (
                          <>
                            {" "}
                            <img src="/assets/connectIcons/argentscan.png" />
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                marginTop: 35,
                                paddingBottom: 22,
                              }}
                            >
                              <Typography className={classes.questionSubtitle}>
                                Need the official WalletConnect modal?
                              </Typography>
                              <Button
                                style={{
                                  fontWeight: 700,
                                  background: "#293651",
                                  color: "white",
                                  fontSize: 14,
                                  borderRadius: 100,
                                  width: 88,
                                  height: 46,
                                }}
                              >
                                Open
                              </Button>
                            </Box>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Fade>
                )}
              </>
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Typography className={classes.footerText}>
              By continuing, you agree to YouMeme’s{" "}
              <span style={{ color: "#817EFF" }}>Terms of Service</span> and our
              <span style={{ color: "#817EFF" }}> Privacy Policy.</span>
            </Typography>
          </Box>
        </Box>
        </Box>
    </Modal>
  );
};

export default LensModal;
