import {
  Button,
  Typography,
  Avatar,
  Dialog,
  DialogContent,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import useLeaveCommunityModalStyles from "./style";
import Icon from "components/atoms/Icons";
import { useLeaveCommunityMutation } from "hooks/api-hooks/useCommunity";
import { useQueryClient } from "@tanstack/react-query";
import useSnackbar from "hooks/useSnackbar";
import AtomLoading from "components/atoms/AtomLoading";
import { Community } from "ymca/models/community.model";

interface ICommunityLeaveModal {
  isOpen: boolean;
  close: VoidFunction;
  avatarSrc?: string;
  community: Community;
  refetchQuery: Function;
}
const CommunityLeaveModal = ({
  isOpen,
  close,
  avatarSrc,
  community,
  refetchQuery,
}: ICommunityLeaveModal) => {
  const classes = useLeaveCommunityModalStyles();
  const queryClient = useQueryClient();

  const {
    isOpen: isLeaveSnkbarOpen,
    open: showLeaveSnkbar,
    close: closeLeaveSnkbar,
  } = useSnackbar();

  const {
    isOpen: isLeaveSnkbarErrOpen,
    open: openLeaveSnkbarErr,
    close: closeLeaveSnkbarErr,
  } = useSnackbar();

  const mutation = useLeaveCommunityMutation({
    onError: (props: any) => {
      console.log("onError", { props });
      openLeaveSnkbarErr();
    },
    onSuccess: (props: any) => {
      queryClient.invalidateQueries(["communities", { id: community.id }]);
      refetchQuery();
      showLeaveSnkbar();
      // close modal
      close();
    },
  });

  const handleLeave = () => {
    mutation.mutate({
      id: community.id,
    });
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={close}
        aria-labelledby='leave-community'
        className={classes.dialog}
      >
        <DialogContent className={classes.dialogContent}>
          <Box display='flex' flexDirection='column' alignItems='flex-end'>
            <Icon icon='emoji-crying' fontSize='7.25rem' />

            <Avatar className={classes.dialogContentAvatar} src={avatarSrc} />
          </Box>

          <Box display='flex' flexDirection='column'>
            <Typography className={classes.dialogContentTitle}>
              Leave {community.name}?
            </Typography>
            <Typography className={classes.dialogContentInfo}>
              You are about to leave this community. Joining it in the future
              would be possible.
            </Typography>
          </Box>
          <Box className={classes.dialogActions}>
            <Button
              className={classes.dialogContentActionsLeaveBtn}
              onClick={handleLeave}
            >
              Leave Community
              {mutation.isLoading && <AtomLoading />}
            </Button>
            <Button
              className={classes.dialogContentActionsCancelBtn}
              onClick={close}
              disabled={mutation.isLoading}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isLeaveSnkbarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={closeLeaveSnkbar}
      >
        <Alert>Successfully left community</Alert>
      </Snackbar>
      <Snackbar
        open={isLeaveSnkbarErrOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={closeLeaveSnkbarErr}
      >
        <Alert severity='error'>Failed to leave community</Alert>
      </Snackbar>
    </>
  );
};

export default CommunityLeaveModal;
