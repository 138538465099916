import { PaginatedResponse } from 'ymca/dtos/common.dto'
import { GetFollowDto } from 'ymca/dtos/follow.dto'
import { APIResponse } from 'ymca/jsonFetcher'
import { FollowRelationshipType } from 'ymca/models/follow.model'
import { BaseService, CommonArgs } from './base.service'
import { UserIdDto } from '../dtos/user.dto'
import { SelfService } from './self.service'

export const FOLLOW_CACHE_TTL_SECONDS = 300 // 5 minutes
export const FOLLOW_PAGE_SIZE = 100 // you can set a smaller number to test!

export class FollowService extends BaseService {
  protected selfService: SelfService

  public constructor(common: CommonArgs, selfService: SelfService) {
    super(common)
    this.selfService = selfService
  }

  protected getFollowKey(fromId: string, toId: string): string {
    return `follow-${fromId}-${toId}`
  }

  /**
   * It returns a key to listen to change in follow events against
   * a user.
   * @param toId userId whose follow event you need to monitor
   * @returns a key that can be used to subscribe to follow events
   */
  public getFollowKeyFromSelf(toId: string): string {
    return this.getFollowKey(this.selfService.getId(), toId)
  }

  protected async cacheFollow(
    fromId: string,
    toId: string,
    isFollowed: boolean,
    skipCache: boolean
  ): Promise<void> {
    const followKey = this.getFollowKey(fromId, toId)
    await this.common.kvStore.set<boolean>(
      followKey,
      isFollowed,
      FOLLOW_CACHE_TTL_SECONDS,
      skipCache
    )
    this.common.pubsub.publish(followKey, isFollowed)
  }

  protected async getFollowFromCache(
    fromId: string,
    toId: string,
    skipCache = false
  ): Promise<boolean | null> {
    const cachedFollow = await this.common.kvStore.get<boolean>(
      `follow-${fromId}-${toId}`,
      skipCache
    )
    return cachedFollow
  }

  public async getFollowByDto(
    dto: GetFollowDto,
    skipCache = false
  ): Promise<APIResponse<PaginatedResponse<string>>> {
    const res = await this.common.jsonFetcher.fetchJSON<
      PaginatedResponse<string>
    >('GET', '/api/follow', dto)
    const follows = res?.data?.data
    if (!Array.isArray(follows) || !res.isSuccess) {
      const msg = `Failed to fetch follows from API: ${JSON.stringify(dto)}`
      console.error(msg)
      console.error(res)
      throw new Error(msg)
    }
    // Cache the results
    skipCache = true
    for (const userId of follows) {
      if (dto.relationshipType === 'following') {
        await this.cacheFollow(dto.subjectUserId, userId, true, skipCache)
      } else {
        await this.cacheFollow(userId, dto.subjectUserId, true, skipCache)
      }
    }
    // Also cache the negative results, if present
    for (const userId of dto.testUserIds) {
      if (dto.relationshipType === 'following') {
        await this.cacheFollow(
          dto.subjectUserId,
          userId,
          follows.includes(userId),
          skipCache
        )
      } else {
        await this.cacheFollow(
          userId,
          dto.subjectUserId,
          follows.includes(userId),
          skipCache
        )
      }
    }
    return res
  }

  public async getFollowByUserId(
    relationshipType: FollowRelationshipType,
    userId: string,
    limit: number = 10,
    offset: number = 0,
    testUserIds: string[] = [],
    skipCache = false
  ): Promise<APIResponse<PaginatedResponse<string>>> {
    const dto = new GetFollowDto()
    dto.subjectUserId = userId
    dto.relationshipType = relationshipType
    dto.limit = limit
    dto.offset = offset
    dto.testUserIds = testUserIds
    const res = await this.getFollowByDto(dto, skipCache)
    return res
  }

  public async doIFollow(userId: string, skipCache = false): Promise<boolean> {
    const selfId = this.selfService.getId()
    if (selfId !== '' && selfId !== undefined && selfId !== null) {
      return false
    }
    const cachedFollow = await this.getFollowFromCache(
      selfId,
      userId,
      skipCache
    )
    if (cachedFollow !== null) {
      return cachedFollow
    }
    const res = await this.getFollowByUserId(
      'following',
      selfId,
      1,
      0,
      [userId],
      skipCache
    )
    const follows = res?.data?.data
    if (!Array.isArray(follows) || !res.isSuccess) {
      const msg = `Failed to fetch follows from API: ${JSON.stringify(res)}`
      console.error(msg)
      console.error(res)
      throw new Error(msg)
    }
    return follows.includes(userId)
  }

  public async createFollow(
    userId: string,
    skipCache = false
  ): Promise<boolean> {
    const dto = new UserIdDto()
    dto.userId = userId
    const res = await this.common.jsonFetcher.fetchJSON<undefined>(
      'PUT',
      '/api/follow',
      dto
    )
    if (res.isSuccess) {
      skipCache = true // disable cache
      await this.cacheFollow(this.selfService.getId(), userId, true, skipCache)
    }
    return res.isCreated
  }

  public async createFollowUser(userId: string): Promise<any> {
    const dto = new UserIdDto()
    dto.userId = userId
    const res = await this.common.jsonFetcher.fetchJSON<undefined>(
      'PUT',
      '/api/follow',
      dto
    )
    return res
  }

  public async deleteFollow(
    userId: string,
    skipCache = false
  ): Promise<boolean> {
    const dto = new UserIdDto()
    dto.userId = userId
    const res = await this.common.jsonFetcher.fetchJSON<undefined>(
      'DELETE',
      '/api/follow',
      dto
    )
    if (res.isSuccess) {
      skipCache = true // disable cache
      await this.cacheFollow(this.selfService.getId(), userId, false, skipCache)
    }
    return res.isCreated
  }

  public async unFollowUser(userId: string): Promise<any> {
    const dto = new UserIdDto()
    dto.userId = userId
    const res = await this.common.jsonFetcher.fetchJSON<undefined>(
      'DELETE',
      '/api/follow',
      dto
    )
    return res
  }
}
