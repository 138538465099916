import useShow from '../useShow'

interface IUseModal {
  isOpen: boolean
  close: () => void
  open: () => void
  toggle: () => void
}

const useModal = (): IUseModal => {
  const { isShowing: isOpen, show, hide, toggle } = useShow()

  const open = () => {
    return show()
  }
  const toggleModal = () => {
    return toggle()
  }

  return {
    isOpen,
    close: hide,
    open,
    toggle: toggleModal
  }
}

export default useModal
