import { useReducer } from "react";

export enum ReactionEnum {
  Like = 'like',
  Dislike = 'dislike',
  No_Reaction = ''
}

export type ReactionType =
  | ReactionEnum.Like
  | ReactionEnum.Dislike
  | ReactionEnum.No_Reaction

export const ReactionTypes = [ReactionEnum.Like, ReactionEnum.Dislike]

export interface ReactionCountType {
  like: number
  dislike: number
  '': number
}

enum ReactActionEnum {
  like = 'like',
  unlike = 'unlike'
}
type ReactActionType = ReactActionEnum.like | ReactActionEnum.unlike

interface ReactActionPayload {
  reaction: ReactionType
}

interface IReactReducerAction {
  type: ReactActionType
  payload: ReactActionPayload
}

interface Reaction {
  activeReaction: ReactionType
  reactionCountPayload: ReactionCountType
}

const initialState: Reaction = {
  activeReaction: ReactionEnum.No_Reaction,
  reactionCountPayload: {
    [ReactionEnum.Like]: 0,
    [ReactionEnum.Dislike]: 0,
    [ReactionEnum.No_Reaction]: 0
  }
}

const reducer = (
  state: Reaction = initialState,
  action: IReactReducerAction
) => {
  const { type, payload } = action
  const { reaction } = payload
  const { activeReaction, reactionCountPayload } = state

  // get active reaction count
  const activeReactionTotalCount = reactionCountPayload[activeReaction] ?? 0 // 0 | 1
  const newReactionCount = reactionCountPayload[reaction] ?? 0

  switch (type) {
    case ReactActionEnum.like:
      return {
        ...state,
        activeReaction: reaction,
        reactionCountPayload: {
          ...reactionCountPayload,
          [activeReaction]:
            activeReactionTotalCount === 0 ? 0 : activeReactionTotalCount - 1, // reduce the current active reaction by 1
          [reaction]: newReactionCount + 1 // add 1 to the reaction sent via the payload
        }
      }
    case ReactActionEnum.unlike:
      return {
        ...state,
        activeReaction: ReactionEnum.No_Reaction,
        reactionCountPayload: {
          ...reactionCountPayload,
          [activeReaction]:
            activeReactionTotalCount === 0 ? 0 : activeReactionTotalCount - 1 // since its unlike we just reduce the current and do nothing else
        }
      }
    default:
      return state
  }
}

export interface IUseReaction {
  activeReaction: ReactionType
  reactionCountPayload: ReactionCountType
  onLike: (reaction: ReactionType) => void
  onUnLike: (reaction: ReactionType) => void
}

const useReaction = (initialState: {
  activeReaction: ReactionType
  reactionCountPayload: ReactionCountType
}): IUseReaction => {
  const [{ activeReaction, reactionCountPayload }, dispatch] = useReducer(
    reducer,
    initialState
  )

  const onLike = (reaction: ReactionType) => {
    return dispatch({
      type: ReactActionEnum.like,
      payload: {
        reaction
      }
    })
  }

  const onUnLike = (reaction: ReactionType) => {
    return dispatch({
      type: ReactActionEnum.unlike,
      payload: {
        reaction
      }
    })
  }

  return {
    activeReaction,
    reactionCountPayload,
    onLike,
    onUnLike
  }
}

export default useReaction;
