import React, { useEffect, useState } from "react";
import { completeLogin } from 'services/auth/auth'
import { isJWT } from 'utils/utils'
import { useNavigate } from 'react-router-dom'
import { User } from 'types/runtimeSchema'
import { makeStyles } from '@material-ui/core/styles'
import { useAuth } from 'context/auth/authContext'
import { redirectLinks } from 'constants/redirectLinks'
import config from 'utils/config'
import toast from 'react-hot-toast'
import { Interface } from "readline";

const useStyles = makeStyles({
  googleBtnWrapper: {
    display: 'block',
    height: '44px',
    margin: '8px 0',
    maxWidth: '480px',
    position: 'relative',
  },
  extLoginContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  "@media (max-width: 770px)": {
    googleBtnWrapper: {
      maxWidth: '280px',
    }
  }
})

export interface ExternalLoginPropsInterface {
  buttonWidth?: string
  disableOnePrompt?: string
  onLogin?: (email: string) => void
}

const ExternalLogin = ({ buttonWidth, disableOnePrompt, onLogin }: ExternalLoginPropsInterface): JSX.Element => {
  const classes = useStyles()
  const { authService } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    window.google?.accounts.id.initialize({
      client_id: config.googleIdentityClientID,
      callback: handleCredentialResponse,
    })

    window.google?.accounts.id.renderButton(
      document.getElementById('googleContainer'),
      {
        size: 'large',
        width: buttonWidth ? buttonWidth : '280px',
        text: 'continue_with'
      }
    )
    if (disableOnePrompt === undefined) {
      window.google?.accounts.id.prompt();
    }

    return () => {
      window.google?.accounts.id.cancel();
    }
  }, [])

  const handleCredentialResponse = async (response: CredentialResponse) => {
    const jwtResponse = response?.credential;
    const currentURL = new URL(window.location.href)
    const referrerId = currentURL.searchParams.get('r') ?? undefined

    if (isJWT(jwtResponse)) {
      authService.state.context.loginSystemRef?.send({ type: 'EXTERNAL_TOKEN' })
      try {
        toast.promise<User>(
          completeLogin(jwtResponse, 'google', referrerId),
          {
            loading: 'Logging you in...',
            success: ({ userData }) => `Welcome ${userData.displayName}`,
            error: 'Looks like an issue occurred while trying to log you in :('
          },
          {
            style: {
              minWidth: '280px'
            }
          }
        ).then((userInfoWithToken: User) => {
          const timerId: NodeJS.Timeout = setTimeout(() => {
            authService.state.context.loginSystemRef?.send({ type: 'COMPLETE_AUTHENTICATION', userInfoWithToken })
            if(onLogin){
              const registeredEmail = userInfoWithToken.userData.email ?? '';
              onLogin(registeredEmail)
            }
            clearTimeout(timerId)
          }, 800)
        }, (err) => console.error(err))
      }
      catch (error) {
        console.error(error)
        const timerId: NodeJS.Timeout = setTimeout(() => {
          navigate(`/${redirectLinks.auth}`)
          authService.state.context.loginSystemRef?.send({ type: 'RETRY_AUTHENTICATION' })
          clearTimeout(timerId)
        }, 800)
      }
    }
  }

  return (
    <div className={classes.extLoginContainer}>
      <div className={classes.googleBtnWrapper} style={{ width: buttonWidth ? buttonWidth : '280px' }}>
        <div id="googleContainer" >
        </div>
      </div>
    </div>
  )
}

export interface CredentialResponse {
  credential: string,
  select_by: string
}

declare global {
  interface Window {
    google: any
  }
}

export default ExternalLogin