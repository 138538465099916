// For getting base64 data for file upload
const getImageBase64DataUri = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        resolve(reader.result as string)
      }
      reader.onerror = function (error) {
        reject(error)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export default getImageBase64DataUri;
