import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import useCommunityMembersStyles from "./style";
import PageTemplate from "components/layout/PageTemplate";
import PageLayout from "components/layout/PageLayout";
import Icon from "components/atoms/Icons";

import PublicationDetail from "components/organisms/PublicationDetail";
import { Link } from "react-router-dom";
import config from "utils/config";
import { redirectLinks } from "constants/redirectLinks";

const CommunityMemePage = () => {
  const classes = useCommunityMembersStyles();
  const { id: slug, memeId } = useParams();

  // const getCommunityQuery = useGetCommunityBySlugQuery({ slug: slug! });

  const copyLinkBasePath = `${config.deploymentUrl}/${redirectLinks.communities}/${slug}`;

  return (
    <PageTemplate>
      <PageLayout marginTop={0}>
        <Box component='section' className={classes.wrapper}>
          <Box component='section' className={classes.header}>
            <Box display='flex' alignItems='center'>
              <Link
                to={`/${redirectLinks.communities}/${slug}`}
                className={classes.goBackBtn}
              >
                <Icon icon='arrow-left' fontSize='2.5rem' />
              </Link>
              <Typography className={classes.headerTitle}>
                Community Meme
              </Typography>
            </Box>
          </Box>
          <Box>
            <PublicationDetail
              memeId={memeId!}
              disableEditMeme
              copyLinkBasePath={copyLinkBasePath}
            />
          </Box>
        </Box>
      </PageLayout>
    </PageTemplate>
  );
};

export default CommunityMemePage;
