import { Box, Button, InputBase, Typography,useMediaQuery  } from '@material-ui/core'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Popover from '@material-ui/core/Popover'
import { withStyles } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles'
import { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import ReactLoading from 'react-loading'
import { sendImageToMemeEditor } from '.'
import { api, debounce } from '../../utils/utils'
import SearchIcon from '@material-ui/icons/Search'
import colors from 'theme/dark/colors'

const useStyles = makeStyles({
  instruction: {
    display: 'grid',
    placeItems: 'center',
    width: '100%',
    height: 200,
    color: 'white',
    fontSize: '1em',
    textAlign: 'center',
    '& p': {
      color: 'white !important',
      width: '100%'
    }
  },
  searchImgWrapper: {
    borderRadius: 5,
    width: 612,
    paddingLeft: 15,
    paddingTop: 10
  },
  searchInput: {
    width: '100%',
    height: 45,
    borderRadius: 5,
    backgroundColor: colors.memeGenBlue50,
    fontSize: 14,
    padding: '0 30px',
    color: 'white',
    '& input': {
      '&::placeholder': {
        color: '#546277',
        opacity: 1
      }
    }
  },
  searchItems: {
    marginTop: 25,
    height: 308,
    position: 'relative'
  },
  searchSelectionDiv: {
    height: '100%',
    padding: 0,
    paddingRight: 10,
    marginRight: -10,
    position: 'relative',
    marginTop: 15,
    gridGap: '10px',

    '&::-webkit-scrollbar': {
      width: 6,
      borderRadius: 20,
      backgroundColor: '#0E1017'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#1D212E',
      borderRadius: 20
    }
  },
  selectBox: {
    width: 362,
    height: 378,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingRight: 15
  },
  selectedTxt: {
    fontSize: 18,
    color: '#C2C9D4',
    margin: '12px 0'
  },
  selectedDesc: {
    color: '#546277',
    fontSize: 14,
    maxWidth: 290,
    alignItems: 'center',
    marginBottom: 6,
    display: 'flex'
  },
  lineRes: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  selectedImgWrapper: {
    marginTop: 23
  },
  selectedImg: {
    width: '100%',
    height: 170,
    borderRadius: 5,
    objectFit: 'cover'
  },
  selectBtn: {
    color: 'white',
    background: 'var(--gradient-fall-back)',
    borderRadius: 5,
    height: 44,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 600,
    justifyContent: 'center',
    userSelect: 'none'
  },
  disableBtn: {
    pointerEvents: 'none',
    opacity: 0.6
  },
  descSection: {
    position: 'relative',
    display: 'inline-block',
    '&:hover': {
      '& $tooltip': {
        visibility: 'visible'
      }
    }
  },
  tooltip: {
    visibility: 'hidden',
    backgroundColor: '#252A3A',
    color: '#C2C9D4',
    borderRadius: 5,
    position: 'absolute',
    zIndex: 1,
    bottom: '120%',
    left: '20%',
    marginLeft: -60,
    padding: 20,
    '&::after': {
      position: 'absolute',
      content: '""',
      top: '100%',
      left: '50%',
      marginLeft: -10,
      borderWidth: 10,
      borderStyle: 'solid',
      borderColor: '#252A3A transparent transparent transparent'
    }
  },
  mobileSearch: {
    marginRight: 6
  },
  goBtn: {
    width: 70,
    height: 40,
    backgroundColor: '#1A1E28',
    color: '#546277',
    fontWeight: 'bold',
    fontSize: 14,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: '#1A1E28',
      opacity: 0.8
    }
  },
  nextBtn: {
    height: 50,
    width: 50,
    borderRadius: '50%',
    background: 'var(--main-gradient)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 0,
    bottom: 0,
    position: 'absolute',
    color: 'white',
    zIndex: 1,
    cursor: 'pointer'
  },
  mobileOnly: {
    display: 'none'
  },
  '@media (max-width: 1160px)': {
    selectBox: {
      paddingRight: 0,
      width: '30vw'
    },
    searchImgWrapper: {
      paddingLeft: 0,
      width: '60vw'
    }
  },
  '@media (max-width: 760px)': {
    searchInput: {
      flexBasis: '100%',
      width: '100%',
      borderRadius: '6.25rem',
      background: 'rgba(255, 255, 255, 0.3)',
      backdropFilter: 'blur(7.5px)',
      color: 'white',
      '& ::placeholder': {
        color: 'white !important',
        zIndex: 11,
        opacity: 1,
        fontWeight: 500
      }
    },
    selectBox: {
      display: 'none'
    },
    searchImgWrapper: {
      width: '100%',
      paddingTop: 0
    },
    searchItems: {
      height: 'calc(100vh - 166px)',
      marginTop: '3rem',
      paddingBottom: '2rem',
      '& > div:first-of-type': {
        bottom: '2em'
      }
    },
    desktopOnly: {
      display: 'none'
    },
    mobileOnly: {
      display: 'flex'
    },
    mobileSearchWrapper: {
      flexDirection: 'row',
      gap: '8px',

      '& button:last-of-type': {
        flexGrow: 1
      }
    },
    mobileOnlyWrapper: {
      marginTop: 0
    },
    nextBtn: {
      bottom: '60px !important',
      '& > svg': {
        width: '16px',
        height: '16px'
      }
    }
    // searchInput: {
    //   height: 40
    // }
  }
})

const CORS_API_URL = 'https://cors-anywhere-01061993.herokuapp.com/'

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#884BFF'),
    width: 150,
    '@media (max-width: 760px)': {
      background: '#120717',
      boxShadow: '0px 4px 10px rgb(18 7 23 / 50%)',
      borderRadius: '6.25rem',
      fontSize: '14px'
    },
    backgroundColor: 'var(--gradient-fall-back)',
    '&:hover': {
      backgroundColor: 'var(--gradient-fall-back)'
    }
  }
}))(Button)

const SearchImage = (props) => {
  const classes = useStyles()
  const [data, setSearchPhotos] = useState(null)
  const [selectedImage, setSelectedImage] = useState(null)
  const [searchLoading, setSearchLoading] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const { isOpen, navigate } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorRef, setAnchorRef] = useState('')
  const [selectedSearchEngine, setSelectedSearchEngine] = useState(0)
  const alert = useAlert()
  const isMobile = useMediaQuery('(max-width: 760px)')
  const handleMouseEnterOnImage = (elementID) => {
    document.getElementById(elementID + 'credit').style.visibility = 'visible'
  }

  const handleMouseLeaveOnImage = (elementID) => {
    document.getElementById(elementID + 'credit').style.visibility = 'hidden'
  }

  const handleClick = (event, aRef) => {
    setAnchorEl(event.currentTarget)
    setAnchorRef(aRef)
  }

  // Close Popover Element
  const handleClose = () => {
    setAnchorEl(null)
  }

  const debounceSearch = debounce((value, engineID) => {
    if (value.length >= 3) {
      setSearchLoading(true)
      if (engineID === 0) {
        api.search
          .getPhotos({ query: value, orientation: 'landscape', perPage: 30 })
          .then((result) => {
            setSearchPhotos(result)
            setSearchLoading(false)
          })
          .catch(() => {
            setSearchLoading(false)
          })
      } else {
        fetch(
          CORS_API_URL +
            'https://api.pexels.com/v1//search?query=' +
            value +
            '&per_page=30&orientation=landscape, square',
          {
            headers: {
              Authorization:
                '563492ad6f91700001000001721909444d82458a94cfb3231aa7626d'
            }
          }
        )
          .then((resp) => {
            return resp.json()
          })
          .then((photos) => {
            setSearchPhotos(photos)
            setSearchLoading(false)
          })
          .catch(() => {
            setSearchLoading(false)
          })
      }
    }
  }, 600)

  const handleSearchSelection = (selectionID) => {
    setSelectedSearchEngine(selectionID)
    handleClose()
    const searchText = document.getElementById('search-input').value
    if (searchText !== '') {
      debounceSearch(searchText, selectionID)
    } else {
      if (selectedSearchEngine !== selectionID) {
        setSearchLoading(true)
        if (selectionID === 0) {
          api.photos
            .getRandom({ count: 12, orientation: 'landscape' })
            .then((result) => {
              setSearchPhotos(result)
              setSearchLoading(false)
            })
            .catch(() => {
              setSearchLoading(false)
            })
        } else if (selectionID === 1) {
          fetch(
            CORS_API_URL +
              'https://api.pexels.com/v1/curated?per_page=12&orientation=landscape,square',
            {
              headers: {
                Authorization:
                  '563492ad6f91700001000001721909444d82458a94cfb3231aa7626d'
              }
            }
          )
            .then((resp) => {
              return resp.json()
            })
            .then((photos) => {
              setSearchPhotos(photos)
              setSearchLoading(false)
            })
            .catch(() => {
              setSearchLoading(false)
            })
        }
      }
    }
  }

  const processURL = async (url) => {
    if (!url) return
    setLoading(true)
    try {
      const imageData = await sendImageToMemeEditor(url)
      navigate(imageData)
    } catch (error) {
      console.error(error)
      showError('Failed to load image')
    } finally {
      setLoading(false)
    }
  }

  const handleImageDownload = (imagelink, downloadUrl) => {
    api.photos
      .trackDownload({ downloadLocation: downloadUrl })
      .then((result) => {
        processURL(imagelink)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const showError = (msg) => {
    alert.error(msg)
  }

  useEffect(() => {
    api.photos
      .getRandom({
        count: 12,
        orientation: 'landscape'
      })
      .then((res) => {
        setSearchPhotos(res)
      })
      .catch(() => {})
  }, [])

  if (!isOpen) return null
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      marginTop='30px'
      className={classes.mobileOnlyWrapper}
    >
      {isLoading && (
        <div className='loaderSearch'>
          <div className='loadingContainer' />
          <ReactLoading className='loadingIcon' type='balls' color='black' />
        </div>
      )}
      <Box className={classes.searchImgWrapper}>
        <Box display='flex' style={{ gap: '1em' }}>
          <InputBase
            id='search-input'
            type='url'
            placeholder="Search 'Funny', 'Troll', maybe something else?"
            onChange={(event) =>
              debounceSearch(event.target.value, selectedSearchEngine)
            }
            className={`${classes.searchInput} ${classes.desktopOnly}`}
          />
          <ColorButton
            className={classes.desktopOnly}
            title='Change search engine'
            onClick={(e) => handleClick(e, 'SpaceListPop')}
          >
            {selectedSearchEngine === 0 ? 'Unsplash' : 'Pexels'}
            {anchorEl ? (
              <BsChevronUp
                style={{ strokeWidth: '1.5px', marginLeft: '7px' }}
              />
            ) : (
              <BsChevronDown
                style={{ strokeWidth: '1.5px', marginLeft: '7px' }}
              />
            )}
          </ColorButton>
        </Box>
        <Box
          className={`${classes.searchWrapper} ${classes.mobileOnly} ${classes.mobileSearchWrapper}`}
        >
          <InputBase
            type='url'
            placeholder='Search...'
            startAdornment={
              isMobile && (
                <SearchIcon
                  style={{
                    color: 'white',
                    width: 15,
                    height: 15,
                    marginRight: 10
                  }}
                  fontSize='small'
                />
              )
            }
            onChange={(e) =>
              debounceSearch(e.target.value, selectedSearchEngine)
            }
            className={`${classes.searchInput} ${classes.mobileSearch}`}
          />
          <ColorButton
            className={classes.mobileOnly}
            title='Change search engine'
            onClick={(e) => handleClick(e, 'SpaceListPop')}
          >
            {selectedSearchEngine === 0 ? 'Unsplash' : 'Pexels'}
            {anchorEl ? (
              <BsChevronUp
                style={{ strokeWidth: '1.5px', marginLeft: '7px' }}
              />
            ) : (
              <BsChevronDown
                style={{ strokeWidth: '1.5px', marginLeft: '7px' }}
              />
            )}
          </ColorButton>

          <Button className={`${classes.goBtn} ${classes.desktopOnly}`}>
            Go
          </Button>
        </Box>
        <Popover
          id='SpaceListPop'
          open={Boolean(anchorEl) && anchorRef === 'SpaceListPop'}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <ButtonGroup
            orientation='vertical'
            variant='outlined'
            size='small'
            className='downloadOptionButtonGroup'
          >
            <Button
              onClick={() => {
                handleSearchSelection(0)
                setSelectedImage(null)
              }}
              title='Set search engine to Unsplash'
            >
              Unsplash
            </Button>
            <Button
              onClick={() => {
                handleSearchSelection(1)
                setSelectedImage(null)
              }}
              title='Set search engine to Pexels'
            >
              Pexels
            </Button>
          </ButtonGroup>
        </Popover>
        <Box className={classes.searchItems}>
          <Box className={`${classes.searchSelectionDiv} grid-display`}>
            {searchLoading && (
              <div className='backdropDiv'>
                <ReactLoading
                  className='loadingIcon'
                  type='bubbles'
                  color='#292929'
                  height={64}
                  width={64}
                />
              </div>
            )}
            {data !== null &&
              data.response !== undefined &&
              data.response.results !== undefined &&
              data.response.results.map((photo) => (
                <div
                  key={photo.id}
                  id={photo.id}
                  className='imageListDiv'
                  onMouseEnter={() => handleMouseEnterOnImage(photo.id)}
                  onMouseLeave={() => handleMouseLeaveOnImage(photo.id)}
                  onClick={() => {
                    !isMobile
                      ? setSelectedImage(photo)
                      : selectedSearchEngine === 0
                      ? handleImageDownload(
                          photo.urls.regular,
                          photo.links.download_location
                        )
                      : processURL(photo.src.large)
                  }}
                >
                  <>
                    <img
                      className='img'
                      alt='credit'
                      src={photo.urls.thumb}
                      crossOrigin={'anonymous'}
                    />
                    <p id={photo.id + 'credit'} className='credit'>
                      {photo.user.name}
                    </p>
                  </>
                </div>
              ))}
            {data !== null &&
              data.response !== undefined &&
              data.response.results === undefined &&
              data.response.map((photo) => (
                <div
                  key={photo.id}
                  id={photo.id}
                  className='imageListDiv'
                  onMouseEnter={() => handleMouseEnterOnImage(photo.id)}
                  onMouseLeave={() => handleMouseLeaveOnImage(photo.id)}
                  onClick={() => {
                    !isMobile
                      ? setSelectedImage(photo)
                      : selectedSearchEngine === 0
                      ? handleImageDownload(
                          photo.urls.regular,
                          photo.links.download_location
                        )
                      : processURL(photo.src.large)
                  }}
                >
                  <>
                    <img
                      className='img'
                      alt='credit'
                      src={photo.urls.thumb}
                      crossOrigin={'anonymous'}
                    />
                    <p id={photo.id + 'credit'} className='credit'>
                      {photo.user.name}
                    </p>
                  </>
                </div>
              ))}
            {data !== null &&
              data.photos !== undefined &&
              data.photos.map((imageData) => (
                <div
                  key={imageData.id}
                  id={imageData.id}
                  className='imageListDiv'
                  onMouseEnter={() => handleMouseEnterOnImage(imageData.id)}
                  onMouseLeave={() => handleMouseLeaveOnImage(imageData.id)}
                  onClick={() => {
                    !isMobile
                      ? setSelectedImage(imageData)
                      : selectedSearchEngine === 0
                      ? handleImageDownload(
                          imageData.urls.regular,
                          imageData.links.download_location
                        )
                      : processURL(imageData.src.large)
                  }}
                >
                  <>
                    <img
                      className='img'
                      alt='credit'
                      src={imageData.src.small}
                      crossOrigin={'anonymous'}
                    />
                    <p id={imageData.id + 'credit'} className='credit'>
                      {imageData.photographer}
                    </p>
                  </>
                </div>
              ))}
          </Box>
        </Box>
      </Box>
      <Box className={classes.selectBox}>
        {selectedSearchEngine === 0 ? (
          <>
            <Box>
              {selectedImage ? (
                <>
                  <Box>
                    <Typography className={classes.selectedTxt}>
                      You selected:
                    </Typography>
                    <Box className={classes.descSection}>
                      <Typography
                        className={`${classes.selectedDesc} ${classes.lineRes}`}
                      >
                        {selectedImage.description}
                      </Typography>
                      <Typography
                        className={`${classes.selectedDesc} ${classes.tooltip} arrow`}
                      >
                        {selectedImage.description}
                      </Typography>
                    </Box>
                    <Box className={classes.selectedDesc}>
                      by:&nbsp;
                      <Typography
                        className={classes.selectedDesc}
                        style={{
                          color: 'var(--gradient-fall-back)',
                          marginBottom: 0
                        }}
                      >
                        {selectedImage?.user?.name}
                      </Typography>
                    </Box>
                    <Box className={classes.selectedImgWrapper}>
                      <img
                        src={selectedImage?.urls?.thumb}
                        className={classes.selectedImg}
                        alt={selectedImage.description}
                      />
                    </Box>
                  </Box>
                </>
              ) : (
                <p className={classes.instruction}>
                  Select a picture and click below
                </p>
              )}
            </Box>
            <Box
              className={
                selectedImage
                  ? classes.selectBtn
                  : `${classes.selectBtn} ${classes.disableBtn}`
              }
              onClick={() => {
                handleImageDownload(
                  selectedImage.urls.regular,
                  selectedImage.links.download_location
                )
              }}
            >
              Looks good
            </Box>{' '}
          </>
        ) : (
          <>
            <Box>
              {selectedImage && (
                <>
                  <Box>
                    <Typography className={classes.selectedTxt}>
                      You selected:
                    </Typography>
                    <Box className={classes.descSection}>
                      <Typography
                        className={`${classes.selectedDesc} ${classes.lineRes}`}
                      />
                      <Typography
                        className={`${classes.selectedDesc} ${classes.tooltip} arrow`}
                      />
                    </Box>
                    <Box className={classes.selectedDesc}>
                      by:&nbsp;
                      <Typography
                        className={classes.selectedDesc}
                        style={{
                          color: 'var(--gradient-fall-back)',
                          marginBottom: 0
                        }}
                      >
                        {selectedImage.photographer}
                      </Typography>
                    </Box>
                    <Box className={classes.selectedImgWrapper}>
                      <img
                        src={selectedImage?.src?.small}
                        alt='selected'
                        className={classes.selectedImg}
                      />
                    </Box>
                  </Box>
                </>
              )}
            </Box>
            <Box
              className={
                selectedImage
                  ? classes.selectBtn
                  : `${classes.selectBtn} ${classes.disableBtn}`
              }
              onClick={() => {
                processURL(selectedImage.src.large)
              }}
            >
              Looks good
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}

export default SearchImage
