import { Route, Routes } from "react-router-dom";

import { profileRoutes } from "utils/routes";

const ProfileRoutes = (): JSX.Element => {
  return (
    <Routes>
      {profileRoutes.map(({ element: Element, ...routeProps }, index) => (
        <Route key={index.toString()} element={<Element />} {...routeProps} />
      ))}
    </Routes>
  );
};

export default ProfileRoutes;
