import { Divider as MuiDivider, styled } from "@mui/material";
import colors from "theme/dark/colors";

const StyledDivider = styled(MuiDivider)`
  background-color: ${colors.border};
  margin: 1rem 0;
`;

const Divider = () => {
  return <StyledDivider />;
};

export default Divider;
