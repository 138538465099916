import config from "./config";

export const getAssetsPath = (path: string): string => {
  if (
    path.startsWith("https://") ||
    path.startsWith("http://") ||
    path.startsWith("data:image/") ||
    path.startsWith("data:video/") ||
    path.startsWith("data:audio/") ||
    path.startsWith("/assets/")
  ) {
    return path;
  }
  const assetsPath = config.assetPath;
  return assetsPath?.concat(path);
};
