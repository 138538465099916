import { makeStyles } from "@material-ui/core/styles";
import colors from "theme/dark/colors";
import { BREAKPOINT_MOBILE_MEDIUM } from "theme/shared/breakpoint";

const useDeleteCommunityModalStyles = makeStyles({
  dialog: {
    "backdrop-filter": "blur(15px)",
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "64rem",
      width: "64rem",
    },
  },
  appBar: {
    display: "none",
    background:
      "linear-gradient(90deg, rgba(32, 42, 65, 0.90) 0%, rgba(32, 42, 65, 0.00) 100%)",
    boxShadow: "0px 0px 70px 0px rgba(14, 20, 34, 0.40)",

    [`@media (max-width: ${BREAKPOINT_MOBILE_MEDIUM}px)`]: {
      display: "block",
    },
  },
  dialogContent: {
    backgroundColor: "#212C41",
    gap: "1.5rem",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  dialogContentAvatar: {
    width: " 2.875rem",
    height: "2.875rem",

    marginTop: "-3.5rem",
    marginRight: "-.5rem",

    border: `.3rem solid ${colors.secondary900}`,
  },

  dialogContentTitle: {
    color: "#E9F0F9",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "1.25rem",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "normal",
    letterSpacing: "-0.03125rem",
  },
  dialogContentInfo: {
    color: "#A7B3CB",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "1.1875rem",
    letterSpacing: "-0.03125rem",
  },
  dialogActions: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "repeat(auto-fit, minmax(240px, 1fr))",
    gap: ".5rem",
  },
  dialogContentActionsDeleteBtn: {
    padding: ".75rem 0",
    borderRadius: "6.25rem",
    background: "linear-gradient(65deg, #E7312D 0%, #D252FF 100%)",
    boxShadow: "0px 4px 8px 0px rgba(14, 20, 34, 0.20)",

    // width: "100%",

    color: "#FFF",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "0.9375rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "1.1875rem",
    letterSpacing: "-0.04375rem",
  },
  dialogContentActionsCancelBtn: {
    padding: ".75rem 0",
    borderRadius: "6.25rem",
    background: "#28344D",
    boxShadow: "0px 4px 8px 0px rgba(14, 20, 34, 0.20)",

    // width: "100%",

    color: "#FFF",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "0.9375rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "1.1875rem",
    letterSpacing: "-0.04375rem",
  },
});

export default useDeleteCommunityModalStyles;
