import { useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CreateIcon from "@material-ui/icons/Create";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import CloudDownload from "@material-ui/icons/CloudDownload";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { getImageBase64FromUrl, handleDownload, handleFiles } from 'utils/utils'
import ReportModal from "components/modals/ReportModal";
import { DeletePostModal } from "components/shared/index";
import PublishMemeModal from "./PublishMemeModal";
import { useAuth } from "context/auth/authContext";
import { useFeedContext } from "context/feed/feedContext";
import { Post } from "ymca/models/post.model";
import colors from "theme/dark/colors";
import { checkIsPostContentPlayable } from "utils/postContent";
import { DefaultYMCA } from "ymca/ymca";
import { usePublicationContext } from "context/PublicationContext";
import { checkResolution } from 'utils/create-meme'
import { FileTypeMap } from 'constants/meme'

const useStyles = makeStyles({
  dropdown: {
    position: "absolute",
    right: 0,
    zIndex: 1,
    backgroundColor: colors.darkBlue,
    boxShadow: "0px 4px 60px #000000",
    borderRadius: "7px",
    top: "35px",
    padding: 8,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    width: "188px",
    transform: "translateY(100px)",
    animation: "$fade 100ms forwards",
  },
  "@keyframes fade": {
    to: {
      transform: "translateY(0px)",
    },
  },
  flagbtn: {
    width: "20px",
    height: "40px",
    objectFit: "contain",
    cursor: "pointer",
    paddingLeft: "6px",
  },
  othergroup: {
    minWidth: "0px",
    borderRadius: "5px",
    marginBottom: 2,
    width: "100%",
    height: "40px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: "#E2E4E9",
    "&:hover": {
      backgroundColor: "#35A6EB",
      color: "#FFFFFF",
    },
  },
  othergroupmobile: {
    transition: "all 0.3s",
    "&:hover": {
      transform: "translateY(-5px)",
      color: "#FFFFFF",
    },
  },
  disabled: {
    "&:hover": {
      backgroundColor: "transparent",
      color: "#FFFFFF",
    },
  },
  popbtn: {
    background: "#efefef",
    borderRadius: "16px",
    "&:focus": {
      background: "#FF4154",
      color: "white",
    },
  },
  redcolor: {
    background: "#FF4154",
    width: "100%",
    "&:hover": {
      background: "#FF4154",
    },
  },
  flagpopup: {
    position: "absolute",
    width: "163px",
    height: "146px",
    left: "60px",
    bottom: 0,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "5px",
  },
  text: {
    fontSize: "12px",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "5px",
  },
  title: {
    fontSize: "12px",
  },
  actionText: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "left!important" as any,
    padding: "8px 12px",
  },
  text1: {
    fontSize: 15,
    fontWeight: 800,
  },
  text2: {
    color: "#808893",
    fontSize: 13,
  },
  txtContainer: {
    marginTop: 10,
  },
  alertBox: {
    zIndex: 1,
    width: "160px",
    height: "25px",
    bottom: "40px",
    background: "white",
    display: "flex",
    justifyContent: "center",
    gap: 3,
    alignItems: "center",
    borderRadius: "45px",
    position: "absolute",
    boxShadow: "0px 3px 6px #00000029",
    cursor: "pointer",
    border: "solid 1px lightgrey",
  },
  btns: {
    display: "flex",
    padding: 6,
    backgroundColor: colors.darkBlue,
    borderRadius: 7,
  },
  toolBtn: {
    width: 44,
    height: 44,
    color: "#E2E4E9",
    minWidth: 0,
  },
  deleteBtn: {
    color: "#FE4773",
    backgroundColor: "rgba(254, 71, 115, .1)",
  },
  profiledropdown: {
    position: "absolute",
    right: 0,
    zIndex: 1,
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: 15,
    minWidth: 54,
    top: 35,
    width: "fit-content",
  },
});

interface PublicationOtherMenuProp {
  itemData: Post;
  menuClose: () => void;
  onProfile?: boolean;
  imageUrl?: any;
  setEvent?: () => void;
  setCopy?: () => void;
  setDelete?: () => void;
  unfollow?: () => void;
  follow?: () => void;
  disableEditMeme?: boolean;
}

const PublicationOtherMenu = ({
  itemData,
  menuClose,
  onProfile = false,
  imageUrl,
  setEvent,
  setCopy = () => {},
  setDelete,
  unfollow,
  follow,
  disableEditMeme,
}: PublicationOtherMenuProp): JSX.Element => {
  const classes = useStyles();
  const { userInfo, isAuthenticated, isAdmin } = useAuth();

  const { source } = useFeedContext();
  const isOwner: boolean = userInfo
    ? userInfo?.userData?.id === itemData.user.id
    : false;

  const [isUserFollowed, setIsUserFollowed] = useState<boolean>(
    itemData.user.isUserFollowed
  );

  const [modalopen, setModalOpen] = useState<boolean>(false);
  const [imageData, setImgData] = useState(null);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openPublishMeme, setOpenPublishMeme] = useState<boolean>(false);

  const { bookmark } = usePublicationContext();
  const { isBookmarked, handleBookmark } = bookmark;

  const isLoading: boolean = !!imageData;

  const { contentType } = itemData;
  const isContentNotPlayable = !checkIsPostContentPlayable(contentType);

  const memefyUrl: string =
    itemData?.meme?.overlayImage?.urlOriginal ??
    itemData?.meme?.renderedImage.urlOriginal;

  const handlePublishModal = async (): Promise<void> => {
    const urlData = await getImageBase64FromUrl(memefyUrl);
    const res = await checkResolution(urlData, FileTypeMap.image)
    setOpenPublishMeme(true);
    if (res !== undefined) {
      const blob = await fetch(urlData).then(async (res) => await res.blob());
      await handleFiles(blob).then((a: any) => {
        setImgData(a);
      });
    }
  };

  const handleOnBanUser = () => {
    const lensUserIdToBan = itemData.fkUserId.slice(1);
    const lensUsernameToBan = itemData.user.username;
    // alert to confirm @TODO: FIXME use a proper modal here
    const adminConfirmsBan = window.confirm(
      `Are you sure you want to ban lens userId ${lensUserIdToBan} (${lensUsernameToBan})?`
    );
    if (adminConfirmsBan) {
      // ban user
      DefaultYMCA.lensTombstoneService.banLensUser(lensUserIdToBan).then(() => {
        // @TODO: FIXME do this in a cleaner way. do we just reload, or do we
        // remove all the user's posts from view?
        alert(
          "User banned. Page will be reloaded now, to remove all their posts from it"
        );
        window.location.reload();
      });
    }
  };

  const canEdit =
    source === "youmeme" && isContentNotPlayable && isOwner && !disableEditMeme;

  const canUseTemplate =
    source === "youmeme" && isContentNotPlayable && !isOwner;

  return (
    <>
      {onProfile ? (
        <Box
          className={classes.profiledropdown}
          style={{ left: isOwner ? -110 : -70 }}
        >
          <Box className={classes.btns}>
            {
              <Button
                className={`${classes.toolBtn}  ${classes.othergroupmobile}`}
                onClick={async () => {
                  await handlePublishModal();
                }}
              >
                <CreateIcon />
              </Button>
            }
            <Button
              className={`${classes.toolBtn}  ${classes.othergroupmobile}`}
              onClick={async (e: any) =>
                await handleDownload(e, itemData?.meme?.renderedImage?.jpegUrl)
              }
            >
              <CloudDownload />
            </Button>
            {itemData.isDeletableByUser && (
              <Button
                className={`${classes.toolBtn} ${classes.deleteBtn} ${classes.othergroupmobile}`}
                onClick={() => setOpenDelete(true)}
              >
                <DeleteOutlinedIcon />
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        <Box className={classes.dropdown}>
          {itemData.isDeletableByUser && (
            <Box
              className={classes.othergroup}
              onClick={() => {
                setOpenDelete(true);
              }}
            >
              <Typography
                className={classes.actionText}
                style={{ color: "#ff9999" }}
              >
                Delete
              </Typography>
            </Box>
          )}

          {isAdmin && itemData.id.startsWith("_") && (
            <Box className={classes.othergroup} onClick={handleOnBanUser}>
              <Typography
                className={classes.actionText}
                style={{ color: "#ff9999" }}
              >
                Ban Lens User
              </Typography>
            </Box>
          )}

          {canEdit && (
            <Box
              className={classes.othergroup}
              onClick={async () => {
                await handlePublishModal();
              }}
            >
              <Typography className={classes.actionText}>Edit</Typography>
            </Box>
          )}

          {canUseTemplate && (
            <Box
              className={classes.othergroup}
              onClick={async () => {
                await handlePublishModal();
              }}
            >
              <Typography className={classes.actionText}>
                Use Template
              </Typography>
            </Box>
          )}

          <Box
            className={classes.othergroup}
            onClick={async (e) => {
              await handleDownload(e, itemData?.meme?.renderedImage?.jpegUrl);
              menuClose();
            }}
          >
            <Typography className={classes.actionText}>Download</Typography>
          </Box>

          <Box className={classes.othergroup}>
            <CopyToClipboard text={imageUrl} onCopy={setCopy}>
              <Typography className={classes.actionText}>Copy Link</Typography>
            </CopyToClipboard>
          </Box>
          {!isOwner && isAuthenticated && source === "youmeme" && (
            <Box
              className={classes.othergroup}
              onClick={async () => {
                if (isUserFollowed) {
                  if (unfollow) unfollow();
                  setIsUserFollowed(false);
                } else {
                  if (follow) follow();
                  setIsUserFollowed(true);
                }
              }}
            >
              <Typography className={classes.actionText}>
                {isUserFollowed ? "Followed" : "Follow"}
              </Typography>
            </Box>
          )}
          
          {isAuthenticated && (
            <Box
              className={`${classes.othergroup} ${classes.disabled}`}
              onClick={() => {}}
            >
              <Typography
                className={classes.actionText}
                style={{ color: "gray" }}
              >
                Report
              </Typography>
            </Box>
          )}
          {isAuthenticated && (
            <Box
              className={`${classes.othergroup}`}
              onClick={() => handleBookmark(itemData)}
            >
              <Typography
                className={classes.actionText}
              >
                {isBookmarked ? "Bookmarked" : "Bookmark"}
              </Typography>
            </Box>
          )}
          {!isOwner && isAuthenticated && source === "youmeme" && (
            <Box className={`${classes.othergroup} ${classes.disabled}`}>
              <Typography
                className={classes.actionText}
                style={{ color: "#ff9999" }}
              >
                Block Memer
              </Typography>
            </Box>
          )}
          <ReportModal
            open={modalopen}
            handleClose={() => {
              setModalOpen(false);
              menuClose();
            }}
          />
        </Box>
      )}
      <DeletePostModal
        open={openDelete}
        itemData={itemData}
        handleClose={() => setOpenDelete(false)}
        handleParentClose={menuClose}
        setEvent={setEvent}
        setDelete={setDelete}
      />
      {isLoading && (
        <PublishMemeModal
          open={openPublishMeme}
          imageData={imageData}
          flag={isOwner}
          onClose={() => setOpenPublishMeme(false)}
          onParentClose={menuClose}
        />
      )}
    </>
  );
};

export default PublicationOtherMenu;
