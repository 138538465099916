import { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Switch,
  Typography,
} from "@material-ui/core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import ReactGA from "react-ga4";

import { ExpandMoreIcon } from "components/shared/SvgIcons";
import { useAuth } from "context/auth/authContext";
import { redirectLinks } from "constants/redirectLinks";
import config from "utils/config";
import { DefaultYMCA } from "ymca/ymca";
import { DEFAULT_AVATAR_IMAGE } from "ymca/services/image.service";
import { PublicUser, UserProfilePageModel } from "ymca/models/user.model";
import { useFeedContext } from "context/feed/feedContext";
import useSidebarMobileStyles from "./style";
import Icon from "components/atoms/Icons";
import packageJson from "../../../../package.json";
import DiamondRewardCard from "../DiamondRewardCard";

const LensSwitch = withStyles({
  switchBase: {
    color: "#707070",
    "&$checked": {
      color: "#52d869",
    },
    "&$checked + $track": {
      backgroundColor: "#52d869",
    },
  },
  checked: {},
  track: {},
})(Switch);

interface SidebarMobileProp {
  open: boolean;
  handleSidebar: Function;
}

const SidebarMobile = ({
  open,
  handleSidebar,
}: SidebarMobileProp): JSX.Element => {
  const classes = useSidebarMobileStyles();
  const navigate = useNavigate();
  const { logout, isAuthenticated, userInfo } = useAuth();
  const userData = userInfo?.userData as PublicUser;

  const { source, setSource, disableToggle } = useFeedContext();

  const handleLensToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSidebar(false);
    setSource(event.target.checked ? "lens" : "youmeme");

    ReactGA.event({
      category: "Lens Toggle",
      action: `Switched to ${event.target.checked ? "lens" : "youmeme"} Feed`,
    });
  };

  const [profileData, setProfileData] = useState<UserProfilePageModel>(
    userData as UserProfilePageModel
  );

  const username = userData?.username;

  // TODO: Ensure getProfileService is cached...
  // use React Query
  const getProfileService = async (): Promise<void> => {
    try {
      if (username) {
        if (username[0] !== "_") {
          const data = await DefaultYMCA.userService.getUserProfile(
            username,
            true,
            false
          );
          if (data) {
            setProfileData(data);
          }
        } else {
          const data = await DefaultYMCA.userService.getUserProfile(
            username,
            true,
            true
          );
          if (data) {
            setProfileData(data);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProfileService();
  }, []);

  const handleLogout = async (): Promise<any> => {
    try {
      logout();
    } catch {
      console.log("Failed to log out");
    }
  };

  return (
    <Drawer
      className={`${classes.drawer}`}
      onClose={() => handleSidebar(false)}
      open={open}
      anchor='left'
    >
      <IconButton
        className={classes.drawerCloseBtn}
        onClick={() => handleSidebar(false)}
      >
        <Icon icon='arrow-left' fontSize='1.5rem' />
      </IconButton>
      <Box className={classes.profile}>
        <Avatar
          src={userData?.avatar?.urlOptimised200x200 ?? DEFAULT_AVATAR_IMAGE}
          className={classes.avatar}
          alt='avatar'
        />
        <Box display='flex' flexDirection='column'>
          <Box display='flex' gap='.5rem' alignItems='center'>
            <Typography className={classes.displayName}>
              {userData?.displayName ?? "Memer"}
            </Typography>
            <Icon icon='verified' />
          </Box>

          {username && (
            <Typography className={classes.username}>@{username}</Typography>
          )}
        </Box>
        <Box component='section' className={classes.followSection}>
          <Typography>
            <span>{profileData?.followers ?? 0}</span> Follower
          </Typography>
          <Typography>
            <span>{profileData?.followings ?? 0}</span> Following
          </Typography>
          <Typography>
            <span>{profileData?.nPosts ?? 0}</span> Posts
          </Typography>
        </Box>
      </Box>
      <DiamondRewardCard onRewardClick={() => handleSidebar(false)} />
      <Box className={classes.navSection}>
        <List className={classes.navList}>
          {config.lensFeedEnabled && (
            <ListItem className={classes.navItem}>
              <Box className={classes.navItemLeft}>
                <Icon icon='swap-to-lens' />
                <Typography className={classes.navTitle}>
                  Swap to Lens
                </Typography>
              </Box>
              <LensSwitch
                value={source}
                checked={source === "lens"}
                onChange={handleLensToggle}
                inputProps={{ "aria-label": "Toggle Lens Feed" }}
                disabled={disableToggle}
              />
            </ListItem>
          )}
          <ListItem
            button
            disabled={!isAuthenticated}
            className={classes.navItem}
            onClick={() => {
              navigate(`/${redirectLinks.settings}`);
              handleSidebar(false);
            }}
          >
            <Box className={classes.navItemLeft}>
              <Icon icon='setting' />
              <Typography className={classes.navTitle}>Settings</Typography>
            </Box>
          </ListItem>

          <ListItem
            button
            className={classes.navItem}
            disabled={config.activateReferralPage !== "true" && true}
            onClick={() => {
              navigate(`/${redirectLinks.referral}`);
              handleSidebar(false);
            }}
          >
            <Box className={classes.navItemLeft}>
              <Icon icon='referral-reward' />
              <Typography className={classes.navTitle}>Refer & Earn</Typography>
            </Box>
          </ListItem>
          <ListItem
            className={classes.navItem}
            onClick={() => {
              handleSidebar(false);
            }}
          >
            <Link
              to='//forms.gle/nDXG9q8FQjhYwkSk7'
              target='_blank'
              className={classes.navItemLeft}
            >
              <Icon icon='give-feedback' />
              <Typography className={classes.navTitle}>
                Give Feedback
              </Typography>
            </Link>
          </ListItem>
          <ListItem
            button
            className={classes.navItem}
            disabled={config.activateReferralPage !== "true" && true}
          >
            <Accordion className={classes.navItemAccordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon fill='#8A98B4' />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Box className={classes.navItemLeft}>
                  <Icon icon='sidebar-additional' />
                  <Typography className={classes.navTitle}>
                    Additional
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <List className={classes.navList}>
                  {config.experimentalYoumemeIOEnabledFlag && (
                    <ListItem
                      button
                      className={classes.navItem}
                      onClick={() => {
                        navigate(`/about`);
                        handleSidebar(false);
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography className={classes.accordionItem}>
                            About
                          </Typography>
                        }
                        style={{ marginLeft: 0 }}
                      />
                    </ListItem>
                  )}

                  <ListItem
                    button
                    className={classes.navItem}
                    onClick={() => {
                      navigate(`/contact`);
                      handleSidebar(false);
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography className={classes.accordionItem}>
                          Contact
                        </Typography>
                      }
                      style={{ marginLeft: 0 }}
                    />
                  </ListItem>

                  <ListItem
                    button
                    className={classes.navItem}
                    onClick={() => {
                      navigate(`/news`);
                      handleSidebar(false);
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography className={classes.accordionItem}>
                          News
                        </Typography>
                      }
                      style={{ marginLeft: 0 }}
                    />
                  </ListItem>

                  <ListItem
                    button
                    className={classes.navItem}
                    onClick={() => {
                      navigate(`/faq`);
                      handleSidebar(false);
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography className={classes.accordionItem}>
                          FAQ
                        </Typography>
                      }
                      style={{ marginLeft: 0 }}
                    />
                  </ListItem>

                  <ListItem
                    button
                    className={classes.navItem}
                    onClick={() => {
                      navigate(`/privacy`);
                      handleSidebar(false);
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography className={classes.accordionItem}>
                          Privacy Policy
                        </Typography>
                      }
                      style={{ marginLeft: 0 }}
                    />
                  </ListItem>

                  <ListItem
                    button
                    className={classes.navItem}
                    onClick={() => {
                      navigate(`/agreement`);
                      handleSidebar(false);
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography className={classes.accordionItem}>
                          Terms Of Service
                        </Typography>
                      }
                      style={{ marginLeft: 0 }}
                    />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
          </ListItem>

          {isAuthenticated && (
            <ListItem
              button
              className={`${classes.navItem} ${classes.navItemTopBorder}`}
              onClick={async (): Promise<void> => {
                await handleLogout();
                handleSidebar(false);
              }}
            >
              <Box className={classes.navItemLeft}>
                <Icon icon='logout' />
                <Typography className={classes.navTitle}>Log Out</Typography>
              </Box>
            </ListItem>
          )}
        </List>
      </Box>
      <Box
        flex={1}
        justifyContent='flex-end'
        display='flex'
        flexDirection='column'
        pr='1rem'
      >
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography className={classes.footertext}>
            v{packageJson.version}
          </Typography>
          <Box display='flex' gap='.5rem'>
            <Link
              to='//twitter.com/YouMemeWorld'
              onClick={() => {
                handleSidebar(false);
              }}
              target='_blank'
              className={classes.footerIconBtn}
            >
              <Icon icon='social-twitter' />
            </Link>
            <Link
              to='//youtube.com/channel/UCV-fixR4aIvO9857MpYPHrw'
              onClick={() => {
                handleSidebar(false);
              }}
              target='_blank'
              className={classes.footerIconBtn}
            >
              <Icon icon='social-youtube' />
            </Link>
            <Link
              to='//t.me/YouMemeNews'
              onClick={() => {
                handleSidebar(false);
              }}
              className={classes.footerIconBtn}
              target='_blank'
            >
              <Icon icon='social-telegram' />
            </Link>
            <Link
              to='//discord.gg/AwcRnkfAn5'
              onClick={() => {
                handleSidebar(false);
              }}
              target='_blank'
              className={classes.footerIconBtn}
            >
              <Icon icon='social-discord' />
            </Link>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SidebarMobile;
