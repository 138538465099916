import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { sendEmail } from 'services/auth/auth'
import Loading from 'components/loading/Loading'
import Input, { InputContainer } from 'components/shared/Input'
import {
  AuthenticationForm,
  ButtonGroupBase,
  HeaderTextContainer,
  performActionWithButtonAnimation,
} from './common'
import { redirectLinks } from 'constants/redirectLinks'
import { useAuth } from 'context/auth/authContext'
import ExternalLogin from './externalLogin'
import { LensLogin } from './lensLogin'
import { Image } from 'components/shared'

const LoginFormContainer = styled(AuthenticationForm)`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 0.875rem;

  & ${InputContainer} {
    width: 100%;
    input {
      font-size: 0.9375rem;
      line-height: 1.5rem;
      font-weight: 300;
      padding: 1.125rem 1.875rem;
      border-radius: 100px;
      background-color: #e1e9f1;
      display: flex;
      align-items: center;
    }
  }
`;

const ContinueWithGoogleButton = styled("div")({
  "& picture": {
    display: "flex",
    alignItems: "center",
  },
});

const LogoContainer = styled("div")({
  width: "100%",
  paddingLeft: 0,
  '& > img': {
    height: '22px'
  }
})

const CentralContentContainer = styled("div")({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  marginTop: 'auto',
  marginBottom: 'auto',
  width: '100%'
})

const TermsContainer = styled('p')({
  marginTop: '1.125rem',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '0.75rem',
  lineHeight: '1.3125rem',
  letterSpacing: '-0.01875rem',
  color: '#8C9AB6E5',
  width: '100%',
  '& > span': {
    color: '#817EFF'
  }
})

const ButtonGroupContainer = styled(ButtonGroupBase)`
  ${LoginFormContainer} & {
    width: 100%;
    justify-content: center;
    align-items: center;

    display: flex;
    flex-direction: column;

    p {
      font-weight: 600;
      font-size: 0.89em;
      text-align: center;
      color: var(--gray-text);
    }

    @media (max-width: 950px) {
      margin-top: 10px;
    }
  }
`;

const EmailLoginButton = styled.button`
  font-size: 1rem;
`;

const disableSubmitButtonsWhileSubmitting = (
  formElem: any,
  disable: boolean = true
): void => {
  const buttonsNodeList = formElem.querySelectorAll("button[type='submit']");
  if (buttonsNodeList === null) return;

  Array.from(buttonsNodeList).forEach(
    (button: any) => (button.disabled = disable)
  );
};

const genericButtonHandler = (e: React.SyntheticEvent): void => {
  document
    .querySelector("button[type='submit'].clicked")
    ?.classList.remove("clicked");
  e.currentTarget.classList.toggle("clicked");
};

export const LoginForm = (): JSX.Element => {
  const { authService } = useAuth();
  const navigate = useNavigate();
  const [emailValid, setEmailValid] = useState<boolean>(true);
  const [currentExternalLoginComponentState, setExternalLoginComponent] =
    useState<boolean>(false);
  useEffect(() => {
    if (authService.state?.value === "unauthenticated") {
      setExternalLoginComponent(true);
    }
    return () => {
      setExternalLoginComponent(false);
    };
  }, [authService.state?.value]);

  const handleSubmit = async (e: any): Promise<any> => {
    e.preventDefault();
    if (emailValid === false) {
      toast.error("Enter a valid email");
    } else {
      const formElement = e.currentTarget;
      const initiatingButton = formElement.querySelector(
        "button[type='submit'].clicked"
      );

      const formData = new FormData(formElement);
      const userEmail: string = Object.fromEntries(formData.entries())
        .userEmail as string;
      const currentURL = new URL(window.location.href);
      const referrer: string | undefined =
        currentURL.searchParams.get("r") ?? undefined;

      const navigateToConfirmationPage = (): void => {
        initiatingButton.disabled = true;
        setTimeout(
          () =>
            navigate(redirectLinks.confirmation, {
              state: { userEmail, referrer },
            }),
          1000
        );
      };

      const mainCallback = async (): Promise<void> => {
        await sendEmail(userEmail, referrer);
      };

      disableSubmitButtonsWhileSubmitting(formElement);

      const enableButton = (): void =>
        disableSubmitButtonsWhileSubmitting(formElement, false);

      await toast.promise(
        performActionWithButtonAnimation(
          initiatingButton,
          mainCallback,
          navigateToConfirmationPage,
          enableButton
        ),
        {
          loading: "Sending email",
          success: `Email sent to ${userEmail}, check your 𝘴𝘱𝘢𝘮, 𝘶𝘱𝘥𝘢𝘵𝘦 or 𝘱𝘳𝘰𝘮𝘰𝘵𝘪𝘰𝘯 folder in your inbox!`,
          error:
            initiatingButton?.getAttribute("data-error-message") ??
            "An error occurred sending this email",
        }
      );
    }
  };

  return (
    <div className='auth-container flex-center'>
      <LogoContainer>
        <img src='/assets/headerIcons/youmeme-logo.svg' alt='YouMeme' />
      </LogoContainer>
      <CentralContentContainer>
        <HeaderTextContainer>
          <h3 className='header-text'>Let’s Memefy Reality!</h3>

          <h6 className='regular-text' style={{ alignSelf: "center" }}>
            {
              "A Social Network for People who enjoy Creating, Browsing and Sharing Memes. Log In or Register now :)"
            }
          </h6>
        </HeaderTextContainer>
        <LoginFormContainer onSubmit={handleSubmit}>
          <Input
            name='userEmail'
            required
            placeholder='stani@lens.xyz'
            type='email'
            id='email'
            next={(isValid) => {
              setEmailValid(isValid);
            }}
          />
          <ButtonGroupContainer>
            <EmailLoginButton
              onClick={genericButtonHandler}
              data-error-message='Oops! something went wrong. Maybe you do not have an account'
              type='submit'
              className='button-style button-press-effect loginBtn'
            >
              <Loading />
              Continue
            </EmailLoginButton>
          </ButtonGroupContainer>
        </LoginFormContainer>
        <div className='line-separator'></div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          {currentExternalLoginComponentState && <ExternalLogin />}
          <ContinueWithGoogleButton
            className='customGoogle'
            style={{ position: "absolute", zIndex: 2 }}
          >
            <Image src='/assets/shareIcons/googleIcon.svg' alt='Google' />
            <p style={{ marginLeft: "10px" }}>Continue with Google</p>
          </ContinueWithGoogleButton>
        </div>
        <LensLogin />
        <TermsContainer>
          By continuing, you agree to YouMeme's <span>Terms of Service</span>{" "}
          and our <span>Privacy Policy</span>.
        </TermsContainer>
      </CentralContentContainer>
    </div>
  );
};


