import styled from 'styled-components'
import { flexContainer } from 'hooks/styles'
import { InputContainer } from "components/shared/Input";

export const YouMemeLogoText = styled.h3`
  font-size: 1.75em;
  padding-left: 5px;
  line-height: 2.36;
  font-weight: bold;

  @media (max-width: 350px) {
    margin-bottom: 0;
  }

  .for-registration {
    @media (min-width: 800px) {
      margin-top: 0.7em;
    }
  }

  @media (max-height: 550px) {
    margin-top: 10px;
    margin-bottom: 0px;
  }
`;

export const HeaderTextContainer = styled.hgroup`
  margin-bottom: 66px;
  padding-left: 0px;
  .header-text {
    font-size: min(2.6vw, 2.375rem);
    line-height: 1.25;
    @media (max-width: 550px) {
      font-size: 30px;
    }
    margin-bottom: 30px;
  }

  .regular-text {
    font-weight: 500;
    font-size: 1em;
    line-height: 1.765;
    color: var(--gray-text);
    @media (max-width: 550px) {
      font-size: 13px;
    }
  }
`;

export const AuthenticationForm = styled.form`
  ${flexContainer("column")}

  ${InputContainer} {
    height: 100%;

    &.error > input {
      border-color: var(--error-red);
    }

    input {
      width: 100%;
      height: 100%;
    }

    span {
      font-size: 0.8em;
      line-height: 1.846;
      margin-left: 18px;
      color: var(--error-red);
    }
  }

  & input,
  & textarea,
  & select {
    width: 100%;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    background: transparent;
    color: var(--placeholder-color, white);
    border: 2px solid var(--input-border-color);

    border-radius: var(--default-border-radius);
    transition-property: border-color, background, color;
    transition-duration: 150ms;

    &:not(select):focus-within {
      background: var(--auth-gray);
      border-color: transparent;
      transition: inherit;
      color: initial;
    }
  }
`;

export const AsideText = styled.h5`
  color: var(--gray-text);
  font-weight: var(--text-weight, 500);
  font-size: 0.7rem;

  ${HeaderTextContainer} & {
    margin-bottom: var(--bottom-spacing, 20px);

    span {
      margin-left: 5px;
      font-weight: inherit;
    }
  }
`;

export const ButtonGroupBase = styled.div`
  display: grid;
  font-size: 0.9375em;
  line-height: 1.6;
`

export const clickedButtonAnimation = [
  { transform: 'scale(1)', opacity: 1 },
  { transform: 'scale(0.9)', opacity: 0.5, color: 'transparent' }
]

export const loadingSpinnerAnimation = [{ opacity: 0 }, { opacity: 1 }]

export const clickedButtonAnimationConfig = {
  duration: 200,
  fill: 'forwards',
  easing: 'ease'
}

export const performActionWithButtonAnimation = async (
  buttonRef: any,
  mainCallback: any,
  successCallback?: any,
  errorCallback?: any
): Promise<any> => {
  const loadingSpinner = buttonRef.querySelector('.loading-spinner')
  buttonRef.disabled = true

  const buttonAnimation = buttonRef.animate(clickedButtonAnimation, clickedButtonAnimationConfig)
  const loadingAnimation = loadingSpinner.animate(
    loadingSpinnerAnimation,
    clickedButtonAnimationConfig
  )

  try {
    const data = await mainCallback?.()

    buttonAnimation.reverse()
    loadingAnimation.reverse()
    buttonRef.disabled = false

    successCallback?.(buttonRef)
    return data
  } catch (error) {
    console.log(error)
    buttonAnimation.cancel()
    loadingAnimation.cancel()

    buttonRef.disabled = false
    errorCallback?.(buttonRef)

    throw error
  }
}
