import '@tensorflow/tfjs-backend-cpu';
import '@tensorflow/tfjs-backend-webgl';
import * as cocoSsd from '@tensorflow-models/coco-ssd';
import {DetectedObject, ModelConfig} from '@tensorflow-models/coco-ssd';

export class ObjectDetectorService {
    public model: cocoSsd.ObjectDetection | null = null;
    public cocoSsdConfig: ModelConfig = { base: 'lite_mobilenet_v2' };

    public get modelLoaded(): boolean {
      return this.model !== null;
    }

    public async loadModel(): Promise<void> {
        if (this.modelLoaded) return;
        this.model = await cocoSsd.load(this.cocoSsdConfig);
    }

    public async detectPeople(
      image: HTMLImageElement,
      threshold?: number,
      nFaces?: number,
    ): Promise<DetectedObject[]> {
    if (this.model === null) throw new Error('Model not loaded');
    if (image.width === 0 || image.height === 0) return [];
    if (!threshold) threshold = 0.5;
    if (!nFaces) nFaces = 5;
    const detections: DetectedObject[] | undefined = await this.model.detect(
        image,
        undefined,
        threshold,
    );
    if (detections === undefined) return [];
    const people = detections.filter(detection => detection.class === "person");
    const sortedByScore = people.sort((a, b) => b.score - a.score);
    const maxReturn = sortedByScore.slice(0, nFaces);
    return maxReturn;
  }
}

const objectDetectorService = new ObjectDetectorService();
export default objectDetectorService;
