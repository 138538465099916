export const Rainbow=(props:any)=>(
<svg {...props} width="1em" height="1em" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="46" height="46" rx="23" fill="#BABDE6" fill-opacity="0.3"/>
<path d="M13.9932 10.8193L37.8157 27.0853L31.9241 35.7946L8.10156 19.4006L13.9932 10.8193Z" fill="url(#paint0_linear_10795_4494)" fill-opacity="0.3"/>
<path d="M37.8155 27.0851C35.7662 30.0309 28.85 28.8782 22.318 24.2674C15.6579 19.7847 12.0717 13.765 13.9929 10.8192C16.0422 7.87342 22.9584 9.02612 29.4904 13.6369C36.1504 17.9916 39.7366 24.1393 37.8155 27.0851Z" fill="url(#paint1_linear_10795_4494)" fill-opacity="0.3"/>
<path d="M31.9228 35.6663C29.8735 38.6121 22.9573 37.4594 16.4253 32.8486C9.89334 28.2378 6.17907 22.3462 8.22832 19.2723C10.2776 16.3265 17.1938 17.4792 23.7258 22.0901C30.2578 26.7009 33.844 32.7205 31.9228 35.6663Z" fill="url(#paint2_linear_10795_4494)" fill-opacity="0.3"/>
<path d="M37.8112 27.0851L31.9196 35.7944C29.8703 38.7402 22.9541 37.4594 16.4221 32.9767C15.1413 32.0801 13.9886 31.1836 12.8359 30.159C13.7325 30.0309 14.8852 29.5186 16.0379 28.2378C18.0871 26.0605 19.1118 25.5481 20.0083 25.6762C20.9049 25.6762 21.9295 26.5728 23.5945 28.7501C25.2595 30.9274 27.5649 31.5678 28.9738 30.4151C29.1019 30.287 29.2299 30.287 29.358 30.159C30.5107 29.2624 30.895 28.8782 33.0723 24.7797C33.5846 23.755 35.3777 22.09 37.8112 22.8585C38.4516 24.5235 38.4516 25.9324 37.8112 27.0851Z" fill="#0E0F23" fill-opacity="0.3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M36.9214 26.5729C35.1283 29.1344 28.8525 27.8536 22.8328 23.6271C16.685 19.4005 13.0988 14.0212 14.8919 11.4596C16.685 8.89808 22.9609 10.1789 28.9805 14.4054C35.0002 18.632 38.5864 24.0113 36.9214 26.5729ZM31.2859 22.7305C30.3894 24.0113 27.3155 23.3709 24.2416 21.3217C21.2959 19.2724 19.5028 16.5828 20.3993 15.302C21.2959 14.0212 24.3697 14.6616 27.4436 16.7108C30.3894 18.7601 32.1825 21.4497 31.2859 22.7305Z" fill="white" fill-opacity="0.3"/>
<path d="M13.4811 13.7651C13.4811 13.637 13.353 13.509 13.2249 13.637C13.0968 13.7651 12.9688 13.7651 12.9688 13.8932C13.0968 14.2774 13.2249 14.5336 13.2249 14.7897C13.2249 14.9178 13.353 15.0459 13.4811 14.9178C13.6091 14.9178 13.7372 14.7897 13.6091 14.6617C13.6091 14.4055 13.6091 14.1493 13.4811 13.7651Z" fill="white" fill-opacity="0.3"/>
<path d="M14.1186 15.8144C14.1186 15.6864 13.9905 15.5583 13.8625 15.6864C13.7344 15.8144 13.7344 15.8144 13.7344 15.9425C15.1432 19.1445 18.089 22.6026 21.9314 25.1641C22.0595 25.2922 22.1875 25.1641 22.3156 25.1641C22.4437 25.0361 22.3156 24.908 22.3156 24.7799C18.3452 22.2183 15.5275 18.8883 14.1186 15.8144Z" fill="white" fill-opacity="0.3"/>
<path d="M29.6155 28.3662C29.4875 28.3662 29.3594 28.3662 29.3594 28.4943C29.3594 28.6224 29.3594 28.7504 29.4875 28.7504C29.8717 28.8785 30.384 29.0066 30.7682 29.1347C30.8963 29.1347 31.0244 29.1347 31.0244 29.0066C31.0244 28.8785 31.0244 28.7504 30.8963 28.7504C30.5121 28.6224 29.9998 28.4943 29.6155 28.3662Z" fill="white" fill-opacity="0.3"/>
<path d="M31.9202 28.8784C31.7921 28.8784 31.6641 29.0065 31.6641 29.1346C31.6641 29.2627 31.7921 29.3907 31.9202 29.3907C32.9448 29.5188 34.0975 29.6469 34.9941 29.5188C35.1222 29.5188 35.2503 29.3907 35.2503 29.2627C35.2503 29.1346 35.1222 29.0065 34.9941 29.0065C33.9695 29.1346 32.9448 29.0065 31.9202 28.8784Z" fill="white" fill-opacity="0.3"/>
<defs>
<linearGradient id="paint0_linear_10795_4494" x1="18.6606" y1="8.50663" x2="23.3849" y2="24.7293" gradientUnits="userSpaceOnUse">
<stop stop-color="#03B8FF"/>
<stop offset="1" stop-color="#FA52A0"/>
</linearGradient>
<linearGradient id="paint1_linear_10795_4494" x1="22.5508" y1="7.3734" x2="27.2753" y2="23.5962" gradientUnits="userSpaceOnUse">
<stop stop-color="#03B8FF"/>
<stop offset="1" stop-color="#FA52A0"/>
</linearGradient>
<linearGradient id="paint2_linear_10795_4494" x1="14.7808" y1="9.63587" x2="19.5052" y2="25.8586" gradientUnits="userSpaceOnUse">
<stop stop-color="#03B8FF"/>
<stop offset="1" stop-color="#FA52A0"/>
</linearGradient>
</defs>
</svg>


)