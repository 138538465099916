import { makeStyles } from '@material-ui/core/styles'
import colors from 'theme/dark/colors'

const useShareDropDownStyles = makeStyles((theme: any) => ({
  shareMenu: {
    '& .MuiPaper-root': {
      'border-radius': '0.75rem',
      background: colors.backgroundPrimary,
      boxShadow: '0px -10px 31px rgba(0, 0, 0, 0.6)',
      padding: '.5rem'
    },
    '& .MuiList-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '.5rem'
    },
    display: 'block'
  },

  shareItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    width: '100%',
    borderRadius: '10px',
    padding: '.25rem !important',

    '&:hover': {
      backgroundColor: `${colors.secondary500} !important`
    },

    '& img': {
      borderRadius: 100,
      width: 30,
      margin: 0,
      height: 30
    },
    '& p': {
      fontSize: 14,
      fontWeight: 700,
      color: '#E9F0F9'
    },

    '& .showMoreBtn': {
      // '&:hover': {}
    }
  },

  mobilesharecopybutton: {
    width: '100%',
    height: 44,
    background: '#2A3039',
    color: '#E9F0F9',
    border: 0,
    borderRadius: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 14
  },
  imgStyle: {
    width: '20px',
    height: '20px'
  }
}))

export default useShareDropDownStyles
