import type { FaceswapInput, FaceswapDetectorOutput } from 'ymca/models/faceswap.model'
import { BaseService } from './base.service'

export class FaceswapService extends BaseService {
  public async swap(input: FaceswapInput): Promise<Blob> {
    const response = await this.common.jsonFetcher.fetchBlob(
      'POST',
      '/api/faceswap',
      undefined,
      input,
    );
    return response;
  }

  public async getFaces(input: Blob | File): Promise<string[]> {
    const response = await this.common.jsonFetcher.fetchJSON<FaceswapDetectorOutput>(
      'POST',
      '/api/faceswap/get-faces',
      undefined,
      input,
      'image/jpeg',
    );
    return response.data.faces;
  }
}
