import { Alert, Box, Popover, Snackbar, Typography } from "@mui/material";
import { Avatar, Button } from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { Link, useNavigate } from "react-router-dom";

import useCommunitiesItemCardStyles from "./style";
import Icon from "components/atoms/Icons";
import { Community } from "ymca/models/community.model";
import { formatReadableCount } from "utils/formatString";
import AtomLoading from "components/atoms/AtomLoading";
import useSnackbar from "hooks/useSnackbar";
import { useState } from "react";
import { useJoinCommunityMutation } from "hooks/api-hooks/useCommunity";
import { COMMUNITY_ROLES } from "ymca/models/community-role.model";
import usePopover from "hooks/usePopover";
import { useAuth } from "context/auth/authContext";
import BackgroundImage from 'components/molecules/BackgroundImage'
import getImageResolutions, {
  getImageUrlSourceType
} from 'utils/getImageResolutions'

interface CommunityItemCardProps {
  community: Community
  joinCommunity?: boolean
  manageCommunity?: boolean
}

const CommunityItemCard = ({
  community,
  joinCommunity,
  manageCommunity
}: CommunityItemCardProps) => {
  const classes = useCommunitiesItemCardStyles()
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth()
  const [hasJoined, setHasJoined] = useState(
    COMMUNITY_ROLES.includes(community.userRoleInCommunity!)
  )
  const {
    open: openAuthPopover,
    isOpen: isAuthPopoverOpen,
    close: closeAuthPopover,
    anchorEl: authPopoverAnchorEl
  } = usePopover()

  const {
    isOpen: isJoinSnkbarOpen,
    close: closeJoinSnkBar,
    open: openJoinSnkBar
  } = useSnackbar()

  const {
    isOpen: isJoinSnkbarErrOpen,
    close: closeJoinSnkbarErr,
    open: openJoinSnkbarErr
  } = useSnackbar()

  // make an API call to join community
  const mutation = useJoinCommunityMutation({
    onSuccess: (data: any) => {
      openJoinSnkBar()
      setHasJoined(true)
    },
    onError: () => {
      openJoinSnkbarErr()
    },
    data: {
      id: community.id
    }
  })

  const handleJoinCommunity = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (!isAuthenticated) {
      openAuthPopover(event)
    } else {
      mutation.mutate({ id: community.id })
    }
  }

  const { x3, x4, fallback } = getImageResolutions(community.background!)

  return (
    <>
      <Link to={`../${community.slug}`} className={classes.card}>
        <Box component='section' className={classes.cardHeader}>
          <BackgroundImage
            src={fallback}
            pictureSources={[
              {
                srcSet: `${x4} 1x, ${x3} 2x`,
                type: getImageUrlSourceType(x3)
              }
            ]}
            loading='lazy'
            className={classes.cardHeaderBanner}
          />
        </Box>
        <Box component='section' className={classes.cardBody}>
          <Typography className={classes.cardBodyName}>
            {community.name}
          </Typography>

          <Typography className={classes.cardBodyDescription}>
            {community.description}
          </Typography>
        </Box>
        <Box
          display='flex'
          flex={1}
          justifyContent='flex-end'
          gap='1rem'
          flexDirection='column'
        >
          <MemberAvatarsSection totalMembers={community.nMembers} />
          {(manageCommunity || joinCommunity) && (
            <Box component='section' className={classes.cardFooter}>
              {joinCommunity && (
                <>
                  {!hasJoined ? (
                    <>
                      <Button
                        className={classes.cardFooterJoinButton}
                        onClick={handleJoinCommunity}
                        aria-describedby={community.slug}
                      >
                        Join community
                        {mutation.isLoading && <AtomLoading />}
                      </Button>
                      <Popover
                        id={community.id}
                        open={isAuthPopoverOpen}
                        onClose={closeAuthPopover}
                        anchorEl={authPopoverAnchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center'
                        }}
                        disableRestoreFocus
                      >
                        <Box className={classes.popoverContainer}>
                          <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => navigate('/auth')}
                          >
                            Sign In to continue
                          </Button>
                        </Box>
                      </Popover>
                    </>
                  ) : (
                    <JoinedCommunitySection community={community} />
                  )}
                </>
              )}
              {manageCommunity && (
                <Button className={classes.cardFooterManageButton}>
                  <Icon icon='manage-community' />
                  Manage community
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Link>
      <Snackbar
        open={isJoinSnkbarOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={closeJoinSnkBar}
      >
        <Alert>Joined community Successfully</Alert>
      </Snackbar>
      <Snackbar
        open={isJoinSnkbarErrOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={closeJoinSnkbarErr}
      >
        <Alert severity='error'>Failed to join community</Alert>
      </Snackbar>
    </>
  )
}

const JoinedCommunitySection = ({ community }: { community: Community }) => {
  const classes = useCommunitiesItemCardStyles();
  return (
    <Link
      to={`../${community.slug}`}
      className={classes.cardFooteVisitCommunityLink}
    >
      Visit Community
    </Link>
  );
};

const MemberAvatarsSection = ({ totalMembers }: { totalMembers: number }) => {
  const classes = useCommunitiesItemCardStyles();
  const totalAvatarsToShow = totalMembers > 4 ? 4 : totalMembers;

  return (
    <Box
      component='section'
      px='.5rem'
      className={classes.cardBodyMembersSection}
    >
      {totalMembers > 0 && (
        <AvatarGroup>
          {Array.from({ length: totalAvatarsToShow }).map((item, i) => {
            const derivedAlt = ["M", "E", "M", "E"];

            return (
              <Avatar
                alt={derivedAlt[i]}
                src='/static/images/avatar/1.jpg'
                className={classes.cardBodyMemberAvatar}
                key={i.toString()}
              />
            );
          })}
        </AvatarGroup>
      )}
      <Typography className={classes.cardBodyMembersCount}>
        {formatReadableCount(totalMembers)} member{totalMembers > 1 && "s"}
      </Typography>
    </Box>
  );
};

export default CommunityItemCard;
