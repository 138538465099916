import { useInterpret, useSelector } from '@xstate/react'
import { createContext, useContext, useEffect, ReactElement } from 'react'
import { USER_DATA_LOCALSTORAGE_KEY } from 'constants/variables'
import {
  ComponentWithChildrenPropsOnly,
  User as UserRuntimeSchema,
} from "../../types/runtimeSchema";
import authenticationProviderMachine, {
  AuthManagerContext,
} from "../../machines/AuthenticationMachine";
import type { InterpreterFrom } from "xstate";
import { User } from "ymca/models/user.model";

interface AuthContextInterfaceUserInfo {
  token: string;
  sessionToken: string;
  userData: User;
}

export interface AuthContextInterface {
  authService: InterpreterFrom<typeof authenticationProviderMachine>;
  userInfo: AuthContextInterfaceUserInfo | null;
  isAuthenticated: boolean | undefined;
  logout: () => void;
  isAdmin: boolean;
}

const AuthContext = createContext<AuthContextInterface | null>(null);

export const useAuth = (): AuthContextInterface | never => {
  const authService = useContext<AuthContextInterface | null>(AuthContext);

  if (authService == null) {
    throw new Error(
      "Seems like you are trying to use the `useAuth` hook outside the `AuthContextProvider` component"
    );
  }

  return authService;
};

const selectContext = (state: { context: AuthManagerContext }): any =>
  state.context.authState;
const compareContext = (
  prevContext: AuthManagerContext["authState"],
  nextContext: AuthManagerContext["authState"]
): any => {
  return prevContext.isAuthenticated === nextContext.isAuthenticated;
};

export const AuthProvider = ({
  children,
}: ComponentWithChildrenPropsOnly): ReactElement => {
  const authService = useInterpret(authenticationProviderMachine);
  const selectedContext = useSelector(
    authService,
    selectContext,
    compareContext
  );

  const userInfo = selectedContext.userInfo as AuthContextInterfaceUserInfo;
  const userData = userInfo?.userData as User;
  const isAdmin = userData?.role === "admin" || userData?.role === "superadmin";

  const contextValue: AuthContextInterface = {
    authService: authService as any,
    userInfo,
    isAuthenticated: selectedContext.isAuthenticated,
    logout: () => {
      authService.send({ type: "LOG_OUT" });
    },
    isAdmin,
  };

  const handleLogoutFromOtherTabs = (e: StorageEvent): void => {
    if (e.key !== USER_DATA_LOCALSTORAGE_KEY) return;
    // 👇 This means the user's data was deleted from a browser storage
    if (e.newValue === null) {
      authService.send({ type: "LOG_OUT" });
    } else if (e.oldValue === null) {
      //   ☝️ This means the user's data was added for the first time into browser storage
      authService.send({
        type: "SUCCESSFUL_AUTH",
        userInfoWithToken: JSON.parse(e.newValue!),
      });
    }
  };

  useEffect(() => {
    window.addEventListener("storage", handleLogoutFromOtherTabs);

    const subscription = authService.subscribe((state: any) => {
      console.log({ state });
      console.log(`Current authService state is: ${state.value}`);
    });
    return () => {
      subscription.unsubscribe();
      window.removeEventListener("storage", handleLogoutFromOtherTabs);
    };
  }, [authService]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
