import { BaseModel } from './base.model'
import { CommunityRole } from './community-role.model'
import { Image } from './image.model'

export type UserRole = 'superadmin' | 'admin' | 'analyst' | 'moderator' | 'user' | 'event' | 'news'

export const USER_ROLES: UserRole[] = ['superadmin', 'admin', 'analyst', 'moderator', 'user', 'event', 'news']

// LoginSession encapsulates a single session the user is active in
// This is stored inside the activeSessions field of the User object
// as an array of UserSession objects
// If a UserSession object is inactive for longer than 30 days, it is
// removed from the array.
// If the size of the array is greater than 10, the oldest session is
// removed from the array.
export interface LoginSession {
  sessionId: string
  userId: string
  initialUserAgent: string
  initialIp: string
  currentUserAgent: string
  currentIp: string
  createdAt: number
  lastActive: number
  aud: string
}

export const USERNAME_REGEX = /^[a-z0-9](?!.*[_]{2})([a-z0-9_]{1,13})[a-z0-9]$/

export const MAX_USERNAME_LENGTH = 15

// UserThumbnail is used to render a thumbnail of a user on a post/comment
export interface UserThumbnail extends BaseModel {
  id: string
  isUserFollowed: boolean
  username: string
  displayName: string
  avatar: Image
}

// UserThumbnailWithBackground is used to render a thumbnail of a user in a
// follower / following list where a banner / background image is needed
export interface UserThumbnailWithBackground extends UserThumbnail {
  background: Image
}

// PublicUser has all the public profile information available for a user
export interface PublicUser extends UserThumbnailWithBackground {
  country: string
  urlWebsite: string
  biography: string
  hobbies: string[]
  nPosts: number
  nCompetitionPosts: number
  role: UserRole
  isVerified: boolean
  createdAt: Date
  updatedAt: Date
  followingList: string[]
  lensProfileId?: string
  communityRole?: CommunityRole
  isCommunityMembershipHidden?: boolean
}

// User has all the information available for a user that they can access
// themselves. Admins and moderators might also have access to this data.
export interface User extends PublicUser {
  ethAddress: string | null | undefined
  rewardEthAddress: string | null | undefined
  email: string
  activeSessions: LoginSession[]
  categoryInterestedIn: string[]
  ymdBalance: number
  referredBy: string
  isAmbassador: boolean
}

export interface UserProfilePageModel extends User {
  followers: number
  followings?: number
}