import Badge from '@material-ui/core/Badge'
import Avatar from '@material-ui/core/Avatar'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { borderRadius } from 'constants/variables'
import colors from 'theme/dark/colors'

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 24,
    height: 24,
    border: `2px solid ${theme.palette.background.paper}`,
    '&:hover': {
      opacity: '0.7'
    }
  }
}))(Avatar)

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: 'fit-content'
  },
  avatar: {
    overflow: 'visible !important' as any,
    width: '130px',
    height: '130px',
    '& img': {
      borderRadius,
      backgroundColor: colors.profileImageBackground,
      border: `solid 10px ${colors.darkBlue50}`,
      boxSizing: 'border-box'
    }
  },
  badge: {
    width: '16px'
  }
})

export interface BadgeAvatarsProps {
  badge?: boolean
  avatarUrl?: string
  upload?: () => void
}

const BadgeAvatars = ({
  badge,
  avatarUrl,
  upload
}: BadgeAvatarsProps): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {badge === true
        ? (
          <Badge
            overlap='circular'
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            badgeContent={
              <SmallAvatar alt='Remy Sharp' onClick={upload}>
                <PhotoCameraIcon className={classes.badge} />
              </SmallAvatar>
          }
          >
            <Avatar className={classes.avatar} src={avatarUrl} />
          </Badge>
          )
        : (
          <Avatar
            alt='Travis Howard'
            className={classes.avatar}
            src={avatarUrl}
          />
          )}
    </div>
  )
}

export default BadgeAvatars
