import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { Navigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { isGmail } from 'utils/utils'
import Loading from 'components/loading/Loading'
import { Timer, Image } from 'components/shared/index'
import {
  AsideText,
  ButtonGroupBase, HeaderTextContainer,
  performActionWithButtonAnimation
} from './common'
import { sendEmail } from 'services/auth/auth'
import { imagesSvg } from 'constants/images'
import { redirectLinks } from 'constants/redirectLinks'

const ButtonGroupContainer = styled(ButtonGroupBase)`
  &.confirmation-buttons {
    margin-top: 30px;
    align-self: center;
    width:100%;
    grid-column-gap: 20px;
    grid-template-columns: 2fr 1fr;

    & button {
      width:100%;
      padding: 1em;
      display: flex;
      justify-content: center;
      align-items: center;

      :disabled {
        svg {
          opacity: 0;
        }
      }
    }

    svg {
      width: 1.5em;
      height: 1.5em;
      padding: 2px;
      margin-right: 12px;
      transition: opacity 150ms;
    }

    a {
      color: inherit;
    }
.spacer{
  visibility:hidden;
}
    .timer {
      color: white;
      // margin-left: 12px;
      transition: color 12ms 150ms;

      .complete {
        color: transparent;
      }
    }

    @media (max-width: 1530px) {
      grid-template-columns: unset;
      grid-template-rows: 1fr 1fr;
      grid-row-gap: 15px;

      & > button:only-child {
        padding: 1.2em;
      }
    }
  }
`


const MAX_DURATION: number = 120 // In seconds

export interface ResendButtonProps {
  userEmail: string
  referrer?: string
}

const ResendButton = ({ userEmail, referrer }: ResendButtonProps): JSX.Element => {
  const buttonRef = useRef<any>(null)
  const [duration, setDuration] = useState<number>(0)

  useEffect(() => {
    if (duration === 0) {
      buttonRef.current.disabled = false
    } else buttonRef.current.disabled = true
  }, [duration])

  const enableResend = (): void => {
    setDuration(0)
  }

  const disabledResend = (): void => {
    setDuration(MAX_DURATION)
  }

  const handleEmailResend = async (e: any): Promise<void> => {
    const resendEmailCallback = async (): Promise<void> => await sendEmail(userEmail, referrer)
    try {
      await toast.promise(
        performActionWithButtonAnimation(e.currentTarget, resendEmailCallback, disabledResend, null),
        {
          loading: 'Resending Emailcomponents.',
          success: 'Email sent again. Check your inbox!',
          error: 'We already processed your email. Please wait or try again later.'
        }
      )
    } catch (error: any) {
      setDuration(error.response.data.throttleDuration)
    }

  }

  return (
    <button
      ref={buttonRef}
      onClick={handleEmailResend}
      className='button-style button-press-effect loginBtn'
    >
      <Loading />
      <Image alt="Resend" src={`checkIcons/${imagesSvg.resend}`} className='button-icon' width='25px' height='25px' />
      Resend Link  {duration > 0 && <>{'in'}<span className='spacer'>''</span><Timer duration={duration} callback={enableResend} /></>}
    </button>
  )
}

const ConfirmationDisplay = (): JSX.Element => {
  const location: any = useLocation()


  // Since we depend on the userEmail here if it not available we go back to auth
  if (!(location.state.userEmail || location?.state?.authType)) return <Navigate to={`/${redirectLinks.auth}`} />
  const { userEmail, referrer } = location.state

  return (
    <div className='auth-container flex-center'>
      <div style={{ width: "100%", paddingLeft: "5px" }}>
        <img
          width={100}
          src='/assets/headerIcons/youmeme-logo.svg'
          alt='YouMeme'
        />
      </div>

      <HeaderTextContainer>
        <AsideText>
          Beep... boop... Success!{" "}
          <a href='/auth' style={{ color: "#817EFF" }}>
            Cancel
          </a>
        </AsideText>

        <h3 className='header-text'>Check your email!</h3>

        <h6 className='regular-text'>
          We just sent an email to -{" "}
          <span style={{ color: "#817EFF" }}>{userEmail}</span>, please check
          your 𝘴𝘱𝘢𝘮, 𝘶𝘱𝘥𝘢𝘵𝘦 or 𝘱𝘳𝘰𝘮𝘰𝘵𝘪𝘰𝘯 folder in your inbox!
        </h6>
      </HeaderTextContainer>

      <ButtonGroupContainer className='confirmation-buttons'>
        <ResendButton userEmail={userEmail} referrer={referrer} />
        {isGmail(userEmail) && (
          <a
            style={{ display: "contents" }}
            href='https://mail.google.com'
            target='_blank'
            rel='noreferrer'
          >
            <button className='alt-button-style alt-button-press-effect'>
              <Image
                alt='Open Gmail'
                src={`checkIcons/${imagesSvg.gmailIcon}`}
                className='button-icon'
                width='30px'
                height='30px'
              />
              Open Gmail
            </button>
          </a>
        )}
      </ButtonGroupContainer>
    </div>
  );
}

export default ConfirmationDisplay
