import { BaseModel } from './base.model'

export type ImagePurpose =
  | 'category'
  | 'avatar'
  | 'meme'
  | 'event'
  | 'background'
  | 'template'
  | 'sticker'

export const USER_IMAGE_PURPOSES: UserImagePurpose[] = ['avatar', 'background']
export type UserImagePurpose = 'avatar' | 'background'

export const IMAGE_PURPOSES: ImagePurpose[] = [
  'category',
  'avatar',
  'meme',
  'event',
  'background',
  'template',
  'sticker'
]

export type MimeType =
  | 'image/png'
  | 'image/jpeg'
  | 'image/svg+xml'
  | 'image/webp'
  | 'image/gif'
  | 'image/avif'
  | 'application/octet-stream'
  | 'video/mp4'
  | 'video/webm'
  | 'application/json'
  | 'application/x-www-form-urlencoded'

export type ImageFormat =
  | 'png'
  | 'jpeg'
  | 'svg'
  | 'webp'
  | 'avif'
  | 'gif'
  | 'mp4'
  | 'webm'
  | 'json'
  | 'bin'

export const IMAGE_FORMATS = [
  'png',
  'jpeg',
  'svg',
  'webp',
  'avif',
  'gif',
  'mp4',
  'webm',
  'json',
  'bin'
] as ImageFormat[]

export const GIF_IMAGE_FORMATS = ['gif', 'mp4', 'webm'] as ImageFormat[]

export const VIDEO_IMAGE_FORMATS = ['mp4', 'webm'] as ImageFormat[]

export const EDITABLE_MEME_IMAGE_FORMATS = ['svg'] as ImageFormat[]

export const MEME_SOURCE_IMAGE_FORMATS = [
  'png',
  'jpeg',
  'svg',
  'webp',
  'avif'
] as ImageFormat[]

export const SCALAR_IMAGE_FORMATS = ['png', 'jpeg', 'webp', 'avif'] as ImageFormat[]

export type SharpCompatibleImageFormat = 'png' | 'jpeg' | 'webp' | 'avif' | 'gif'

export const SHARP_COMPATBILE_IMAGE_FORMATS = [
  'png',
  'jpeg',
  'webp',
  'avif',
  'gif'
] as ImageFormat[]

export const formatToMimetype: Map<ImageFormat, MimeType> = new Map()
formatToMimetype.set('png', 'image/png')
formatToMimetype.set('jpeg', 'image/jpeg')
formatToMimetype.set('svg', 'image/svg+xml')
formatToMimetype.set('webp', 'image/webp')
formatToMimetype.set('avif', 'image/avif')
formatToMimetype.set('gif', 'image/gif')
formatToMimetype.set('mp4', 'video/mp4')
formatToMimetype.set('webm', 'video/webm')
formatToMimetype.set('json', 'application/json')
formatToMimetype.set('bin', 'application/octet-stream')

export const mimetypeToFormat: Map<MimeType, ImageFormat> = new Map()
mimetypeToFormat.set('image/png', 'png')
mimetypeToFormat.set('image/jpeg', 'jpeg')
mimetypeToFormat.set('image/svg+xml', 'svg')
mimetypeToFormat.set('image/webp', 'webp')
mimetypeToFormat.set('image/avif', 'avif')
mimetypeToFormat.set('image/gif', 'gif')
mimetypeToFormat.set('video/mp4', 'mp4')
mimetypeToFormat.set('video/webm', 'webm')
mimetypeToFormat.set('application/json', 'json')
mimetypeToFormat.set('application/octet-stream', 'bin')

export const UNKNOWN_MIMETYPE: MimeType = 'application/octet-stream'

export function getMimeTypeFromExt (ext: ImageFormat): MimeType {
  return formatToMimetype.get(ext) ?? UNKNOWN_MIMETYPE
}

export function getMimeTypeFromPath (path: string): MimeType {
  const ext = path.split('.').pop() ?? ''
  return getMimeTypeFromExt(ext as ImageFormat)
}

export interface Image extends BaseModel {
  id: string
  urlImage: string // being depreciated
  typeImage: ImagePurpose
  formatImage: ImageFormat
  isVerified: boolean
  // Note: Each of these images represents a specific size
  // Not all images will have all of these sizes
  // Only originalImage would always be present
  urlOriginal: string
  urlOptimised200x200: string
  urlOptimised500x500: string
  urlOptimised1000x1000: string
  jpegUrl: string
}