import { lazy } from "react";
import { LoginForm } from "components/auth/Login";

import { Navigate, Route, Routes } from "react-router-dom";

import Confirmation from "../../components/auth/Confirmation";

import { redirectLinks } from "constants/redirectLinks";

const RegistrationForm = lazy(
  async () => await import("components/auth/Registration")
);
const LoginVerification = lazy(
  async () => await import("components/auth/LoginVerification")
);

const AuthRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route index element={<LoginForm />} />

      <Route path={redirectLinks.confirmation} element={<Confirmation />} />

      <Route
        path={redirectLinks.completeRegistration}
        element={<RegistrationForm />}
      />

      <Route
        path={redirectLinks.completeLogin}
        element={<LoginVerification />}
      />

      <Route
        path='*'
        element={<Navigate to={`/${redirectLinks.notFound}`} />}
      />
    </Routes>
  );
};

export default AuthRoutes;
