import useOnScreen from 'hooks/useOnScreen'
import { Box, Fade, IconButton } from '@material-ui/core'
import { Image } from 'components/shared/index'
import { makeStyles } from '@material-ui/core/styles'
import { imagesSvg } from 'constants/images'
import colors from "theme/dark/colors";

const useStyles = makeStyles({
  container: {
    zIndex: 2,
    position: "fixed",
    top: "10vh",
    right: 0,
    width: "100%",
    display: "grid",
    placeItems: "center",
    "@media only screen and (max-width:540px), (max-height: 540px)": {
      top: "calc(15vh + 30px)",
    },
  },
  toTop: {
    height: 30,
    backgroundColor: colors.primary500,
    color: "black",
    borderRadius: 50,
    display: "flex",
    alignItems: "center",
    "& span": {
      fontSize: "1rem",
      color: "white",
    },
    "&:hover": {
      cursor: "pointer",
      transition: "0.3s",
      color: "#0c0e14",
      backgroundColor: colors.primary500,
    },

    "& picture": {
      display: "flex",
    },
  },
  topSvg: {
    width: "1rem",
    height: "auto",
    marginRight: 10,
  },
});

export interface ScrollTopProps {
  toEl: React.MutableRefObject<HTMLDivElement>;
}

const ScrollTop = ({ toEl }: ScrollTopProps): JSX.Element => {
  const classes = useStyles();

  const [isVisible] = useOnScreen({
    ref: toEl,
    rootMargin: "0px",
    threshold: 0,
  });

  const handleClick = (): void => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Fade in={!isVisible}>
        <Box className={classes.container}>
          <IconButton onClick={handleClick} className={classes.toTop}>
            <Image
              alt='back to top icon'
              src={`moveIcons/${imagesSvg.topIcon}`}
              className={classes.topSvg}
            />
            <span>Back to top</span>
          </IconButton>
        </Box>
      </Fade>
    </>
  );
};

export default ScrollTop
