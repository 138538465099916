import {useEffect, useState} from 'react';
import {useAuth} from 'context/auth/authContext';
import {DefaultYMCA} from 'ymca/ymca';
import { ThrottlePurpose } from 'ymca/dtos/throttle.dto';
import { ttlSecondsToTimeLeft } from 'meme-generator/utils/datetime';
import { Typography } from '@material-ui/core';

type RemainingAttemptsAIProps = {
  rateLimitPurpose: ThrottlePurpose;
  className: string;
  count: number;
}

export default function RemainingAttemptsAI({ rateLimitPurpose, className, count }: RemainingAttemptsAIProps) {
  const { isAuthenticated, userInfo } = useAuth();
  const [throttleLimit, setThrottleLimit] = useState<number>(0);
  const [throttleTTL, setThrottleTTL] = useState<number>(0);
  const [throttleCount, setThrottleCount] = useState<number>(0);
  const throttleInfoText =
    throttleCount >= throttleLimit
      ? `${ttlSecondsToTimeLeft(throttleTTL)}.`
      : `You've used ${throttleCount}/${throttleLimit} attempts.`;
  
  useEffect(() => {
    async function updateThrottleInfo() {
      if (!isAuthenticated) {
        return;
      }
      const throttleInfo = await DefaultYMCA.throttleService.getRateLimitInfo(
        rateLimitPurpose,
        userInfo?.userData.id ?? '',
      )
      setThrottleLimit(throttleInfo.limit);
      setThrottleTTL(throttleInfo.ttl);
      setThrottleCount(throttleInfo.count);
    }
    updateThrottleInfo();
  }, [isAuthenticated, userInfo?.userData.id, rateLimitPurpose, count]);

  return (
    <Typography
      component='span'
      className={className}
    >
      {throttleInfoText}
    </Typography>
  )
}
