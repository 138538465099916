import { makeStyles } from "@material-ui/core/styles";

const useFormTextFieldStyles = makeStyles({
  inputLabel: {
    color: "#E9F0F9",
    "font-family": "Inter",
    "font-size": "0.875rem",
    "font-style": "normal",
    "font-weight": 700,
    "line-height": "100%",
    "letter-spacing": "-0.01875rem",
  },
  inputLabelHint: {
    color: "#8A98B4",
    "font-family": "Inter",
    "font-size": "0.875rem",
    "font-style": "normal",
    "font-weight": 500,
    "line-height": "100%",
    "letter-spacing": "-0.01875rem",
  },
});

export default useFormTextFieldStyles;
