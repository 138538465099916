import { useAuth } from 'context/auth/authContext'
import { lazy } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

const ContestListPage = lazy((): any => import('../../pages/ContestListPage'))

const SubmissionDetailPage = lazy(
  (): any => import('../../pages/ContestSubmitPage')
)

const ContestRoutes = (): JSX.Element => {
  const { isAuthenticated } = useAuth()

  return (
    <Routes>
      <Route index element={<Navigate to='ongoing' />} />
      <Route path='ongoing' element={<ContestListPage />} />
      <Route path='upcoming' element={<ContestListPage />} />
      <Route path='closed' element={<ContestListPage />} />
      {isAuthenticated && (
        <Route path='rewards' element={<ContestListPage />} />
      )}

      <Route path=':id/:tab' element={<SubmissionDetailPage />} />
    </Routes>
  )
}


export default ContestRoutes
