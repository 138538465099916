import { makeStyles } from '@material-ui/core/styles'
import colors from 'theme/dark/colors'

const usePublicationMobileModalStyles = makeStyles({
  dialog: {
    'backdrop-filter': 'blur(15px)'
  },
  dialogContent: {
    backgroundColor: colors.backgroundPrimary,
    '&.MuiDialogContent-root': {
      padding: '0'
    }
  },
  appBar: {
    background: colors.backgroundPrimary,
    boxShadow: '0px 0px 70px 0px rgba(14, 20, 34, 0.40)',
    borderBottom: `1px solid ${colors.border}`
  },
  toolbarTitle: {
    color: '#E9F0F9',
    'font-family': 'Inter',
    'font-size': '1.125rem',
    'font-style': 'normal',
    'font-weight': '700',
    'line-height': 'normal',
    'letter-spacing': '-0.05rem'
  },
  loadContainer: {
    height: '100%',
    display: 'grid',
    placeItems: 'center'
  },
  closeBtn: {
    '&.MuiIconButton-root': {
      padding: '0'
    }
  }
})

export default usePublicationMobileModalStyles
