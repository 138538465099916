import { makeStyles } from "@material-ui/core/styles";
import { borderRadius } from "constants/variables";
import colors from "theme/dark/colors";

const useSidebarMobileStyles = makeStyles({
  drawer: {
    '& .MuiDrawer-paper': {
      background: 'rgba(32, 43, 66, 1)',
      padding: '0 .5rem',

      display: 'flex',
      flexDirection: 'column',
      gap: '.5rem',

      transition: '350ms'
    }
  },
  drawerContainer: {},
  drawerCloseBtn: {
    '&.MuiIconButton-root': {
      'border-radius': '6.25rem',
      backgroundColor: '#151E31',
      boxShadow: '-4px 0px 14px 0px rgba(0, 0, 0, 0.25)',

      width: '3rem',
      height: '3rem',
      flexShrink: 0,

      position: 'fixed',

      top: '2rem',
      left: '13.5rem',
      '&:hover': {
        backgroundColor: '#151E31'
      }
    }
  },

  followSection: {
    display: 'flex',
    gap: '.5rem',

    '& .MuiTypography-root': {
      color: '#8A98B4',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      letterSpacing: '-0.025rem',

      '& span': {
        color: '#E9F0F9'
      }
    }
  },
  navList: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem'
  },
  navSection: {
    borderRadius: '0.9375rem',
    background: '#151E31'
  },
  navTitle: {
    color: '#E9F0F9',
    fontSize: '.8rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '1.1875rem',
    letterSpacing: '-0.0375rem'
  },
  navItem: {
    '&.MuiListItem-root': {
      padding: '0 1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '1rem'
    }
  },
  navItemTopBorder: {
    '&.MuiListItem-root': {
      borderTop: '1px solid #2A3753',
      paddingTop: '.5rem'
    }
  },
  navItemLeft: {
    display: 'flex',
    gap: '1rem',
    padding: '.5rem',
    justifyContent: 'center'
  },
  navItemAccordion: {
    '&.MuiPaper-root': {
      backgroundColor: 'transparent',
      'box-shadow': 'unset',
      'background-image': 'unset',
      width: '100%',

      '& .MuiAccordionSummary-root': {
        display: 'flex',
        'min-height': 'unset',

        '& .MuiAccordionSummary-content': {
          margin: 'unset'
        }
      }
    }
  },
  accordionItem: {
    color: '#E9F0F9',
    fontSize: '.8rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.1875rem',
    letterSpacing: '-0.05rem'
  },

  lensToggle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',
    marginLeft: '-.2rem'
  },
  profile: {
    padding: '1rem .25rem 0',
    boxSizing: 'border-box',

    display: 'flex',
    flexDirection: 'column',

    gap: '.75rem'
  },
  avatar: {
    width: '3rem',
    height: '3rem',
    borderRadius: borderRadius,
    backgroundColor: colors.profileImageBackground
  },
  displayName: {
    fontSize: '1rem',
    fontWeight: 800,
    color: '#E9F0F9',
    fontStyle: 'normal',
    lineHeight: '1.18rem',
    letterSpacing: '-0.0375rem'
  },
  username: {
    color: '#8A98B4',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '-0.025rem'
  },
  footertext: {
    fontSize: '0.875rem',
    fontWeight: 500,
    padding: '1rem',

    color: '#8A98B4',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.025rem'
  },
  footerIconBtn: {
    '&.MuiIconButton-root': {
      padding: 'unset'
    }
  }
})

export default useSidebarMobileStyles;
