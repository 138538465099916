import type { MemegenResponse } from 'ymca/models/memegen.model'
import type { MimeType } from 'ymca/models/image.model'
import type { APIResponse } from 'ymca/jsonFetcher'
import { BaseService } from './base.service'
import languageDetector from '../../services/ai/language-detector.service'

export class MemegenService extends BaseService {
  public languageDetector = languageDetector

  public async generateAIMeme (text: string, count = 1): Promise<APIResponse<MemegenResponse>> {
    if (text === '' || text === undefined || text === null) {
      throw Error('Text cannot be null-ish or empty')
    }
    if (count < 1 || count > 4) {
      throw Error('Count must be between 1 and 4')
    }
    const isEnglish = await this.languageDetector.isInputEnglish(text);
    const res = await this.common.jsonFetcher.fetchJSON<MemegenResponse>(
      'POST', '/api/generate', undefined, { text, isEnglish, count }
    )
    return res
  }

  /**
   * Generates a caption for an image, used for regenerate button
   * @param text
   * @param count
   */
  public async generateCaption (text: string, count = 1): Promise<APIResponse<string[]>> {
    if (text === '' || text === undefined || text === null) {
      throw Error('Text cannot be null-ish or empty')
    }
    if (count < 1 || count > 10) {
      throw Error('Count must be between 1 and 10')
    }
    const skipCache = true;
    const isEnglish = await this.languageDetector.isInputEnglish(text)
    const res = await this.common.jsonFetcher.fetchJSON<string[]>(
      'POST', '/api/generate/caption', undefined, { text, isEnglish, count, skipCache }
    )
    return res
  }

  /**
   * Generates an image, used for regenerate button
   * @param text
   * @param count
   */
  public async generateImage (text: string, count = 1): Promise<APIResponse<string[]>> {
    if (text === '' || text === undefined || text === null) {
      throw Error('Text cannot be null-ish or empty')
    }
    if (count < 1 || count > 4) {
      throw Error('Count must be between 1 and 4')
    }
    const skipCache = true;
    const isEnglish = await this.languageDetector.isInputEnglish(text)
    const res = await this.common.jsonFetcher.fetchJSON<string[]>(
      'POST', '/api/generate/image', undefined, { text, isEnglish, count, skipCache }
    )
    return res
  }

  public async generateMemeFromImage(image: string, count: number = 4): Promise<MemegenResponse> {
    if (image === null) {
      throw Error('Image cannot be null-ish or empty')
    }
    if (count < 1 || count > 5) {
      throw Error('Count must be between 1 and 4')
    }
    const res = await this.common.jsonFetcher.fetchJSON<MemegenResponse>(
      'POST', '/api/generate/memecam', undefined, { image, count }
    )
    return res.data;
  }

  public async generateMemeFromImageCaption(imageCaption: string, count: number = 4): Promise<MemegenResponse> {
    if (imageCaption === '') {
      throw Error('Caption cannot be null-ish or empty')
    }
    if (count < 1 || count > 5) {
      throw Error('Count must be between 1 and 4')
    }
    const res = await this.common.jsonFetcher.fetchJSON<MemegenResponse>(
      'POST', '/api/generate/memecam-with-caption', undefined, { imageCaption, count }
    )
    return res.data;
  }
}
