import React, { FormEvent, useEffect, useState } from 'react'
import {
  Menu,
  Snackbar,
  TextField,
  Button,
  InputAdornment
} from '@mui/material'
import { Box, IconButton } from '@material-ui/core'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

import colors from 'theme/dark/colors'
import { Image } from 'components/shared/index'
import Alert from 'components/atoms/Alert'
import { imagesSvg } from 'constants/images'
import { DEFAULT_AVATAR_IMAGE } from 'ymca/services/image.service'
import { DefaultYMCA } from 'ymca/ymca'
import useWindowSize from 'hooks/useWindowSize'
import { useLensService } from 'hooks/useLensServices/useLensServices'
import { useAuth } from 'context/auth/authContext'
import { useFeedContext } from 'context/feed/feedContext'
import { User } from 'ymca/models/user.model'
import { Post as PostModel } from 'ymca/models/post.model'
import useAddCommentFormStyles from './styles'
import { BREAKPOINT_MOBILE_LARGE } from 'theme/shared/breakpoint'
import useMenu from 'hooks/useMenu'
import Typography from 'components/atoms/Typography'

export interface PublicationCommentProp {
  post: PostModel
  contestModal?: boolean
  addComment: (postId: string, userComment: string) => Promise<void>
}

const PublicationAddComment = ({
  post,
  contestModal,
  addComment
}: PublicationCommentProp): JSX.Element => {
  const classes = useAddCommentFormStyles()

  const { isAuthenticated } = useAuth()
  const { isAuthenticatedOnLens, isLensProfileManagerEnabled } =
    useLensService()
  const { source } = useFeedContext()

  const isMobile: boolean = useWindowSize().width <= BREAKPOINT_MOBILE_LARGE

  const [userComment, setUserComment] = useState<any>('')
  const [, setActionToPerform] = useState('')

  const [userData, setSelfData] = useState<User | null>(null)
  const [error, setError] = useState<string | null>(null)

  const {
    isOpen: isEmojiPickerOpen,
    close: closeEmojiPicker,
    open: openEmojiPicker,
    anchorEl: emojiPickerAnchorEl
  } = useMenu()

  useEffect(() => {
    const getSelfData = async () => {
      const self = await DefaultYMCA.selfService.getSelf()
      setSelfData(self)
    }
    getSelfData()
  }, [])

  const addEmoji = (e: any): any => {
    const emoji = e.native
    setUserComment((prev: any) => (prev += emoji))
  }

  const handleAddComment = async (
    e: FormEvent,
    postId: string,
    userComment: string
  ): Promise<void> => {
    e.preventDefault()
    if (source === 'lens') {
      const isLensAuth = await isAuthenticatedOnLens()
      const isLensProfileManager = await isLensProfileManagerEnabled()
      if (!isLensAuth) {
        setError('Please login through Lens to comment on this post.')
        setUserComment('')
        return
      } else if (!isLensProfileManager) {
        setError('Please enable lens profile manager to comment on this post.')
        setUserComment('')
        return
      }
    }
    addComment(postId, userComment)
      .then(() => {
        setUserComment('')
      })
      .catch((error) => {
        setError(`Unable to add comment, ${error.message}`)
      })
  }

  if (contestModal) return <></>

  return (
    <>
      <Snackbar
        open={error !== null}
        autoHideDuration={4000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity='error' variant='filled' sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>

      <Box className={classes.form_wrapper}>
        <Box
          component='form'
          onSubmit={async (e) => {
            handleAddComment(e, post.id, userComment)
          }}
          className={classes.form}
        >
          {isAuthenticated && (
            <Image
              alt='user'
              src={
                userData?.avatar?.urlOptimised200x200 ?? DEFAULT_AVATAR_IMAGE
              }
              className={classes.userIcon}
            />
          )}
          <Box className={classes.comment_section}>
            <TextField
              id='outlined-multiline-flexible'
              placeholder='Comment...'
              multiline
              maxRows={10}
              value={userComment}
              onClick={(e) => {
                if (!isAuthenticated) {
                  setActionToPerform('comment')
                }
              }}
              onChange={(e) => setUserComment(e.target.value.trimStart())}
              className={classes.text_area_section}
              variant='standard'
            />
            <Box className={classes.action_btn_section}>
              {!isMobile && (
                <IconButton
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    if (isAuthenticated) {
                      openEmojiPicker(e)
                    }
                  }}
                  aria-describedby='reaction-emoji-picker'
                  className={classes.reactionBtn}
                >
                  <Image
                    alt='smiling icon'
                    src={`reactionIcons/${imagesSvg.smilingIcon}`}
                  />
                </IconButton>
              )}
              <Button
                className={classes.postComment}
                disabled={!userComment || !isAuthenticated}
                style={{
                  background: userComment
                    ? colors.primary500
                    : 'rgba(41, 54, 82, 0.5)',
                  color: !userComment ? '#4F5D79' : '#FFFFFF'
                }}
                type='submit'
              >
                Post
              </Button>
            </Box>
          </Box>

          <Menu
            open={isEmojiPickerOpen}
            anchorEl={emojiPickerAnchorEl}
            onClose={closeEmojiPicker}
            className={classes.emojiMenu}
            id='reaction-emoji-picker'
            aria-labelledby='reaction-emoji-popup'
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <Picker
              data={data}
              onEmojiSelect={addEmoji}
              previewPosition='none'
              theme='dark'
              autoFocus='true'
              skinTonePosition='none'
              emojiStyle='google'
            />
          </Menu>
        </Box>
        {userComment && !isAuthenticated && (
          <Typography variant='body1' color={colors.error500} fontSize='1rem'>
            Sign in to post comments
          </Typography>
        )}
      </Box>
    </>
  )
}

export default PublicationAddComment
