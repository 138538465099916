import { QuerySearchParamsDto } from './common.dto'
import { URLSearchParamsCapable } from '../jsonFetcher'

export class CreateCommentDto {
  public comment: string = ''
  public postId: string = ''
}

export class DeleteCommentDto implements URLSearchParamsCapable {
  public commentId: string = ''

  public toURLSearchParams (): URLSearchParams {
    const params = new URLSearchParams()
    params.append('id', this.commentId)
    return params
  }
}

export class GetCommentsDto extends QuerySearchParamsDto {
  public postId: string = ''
  public userId?: string

  public toURLSearchParams(): URLSearchParams {
    const params = super.toURLSearchParams()
    if (typeof this.postId === 'string') {
      params.append('postId', this.postId)
    }
    if (typeof this.userId === 'string') {
      params.append('userId', this.userId)
    }
    return params
  }
}
