import { useEffect, useRef } from "react";

interface UseSearchCommunityProps {
  onSearch: Function;
}

interface IUseSearchCommunity {
  searchFormRef: any;
}

const useSearchCommunity = ({
  onSearch,
}: UseSearchCommunityProps): IUseSearchCommunity => {
  const searchFormRef = useRef<HTMLInputElement | null>(null);
  // const [searchValue, setSearchValue] = useState('')
  const handleEnterEvent = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      const value = searchFormRef.current?.value;
      if (value || value === "") {
        onSearch(value);
      }
    }
  };

  useEffect(() => {
    searchFormRef.current?.addEventListener("keydown", handleEnterEvent);
  }, [searchFormRef.current]);

  return { searchFormRef };
};

export default useSearchCommunity;
