import { makeStyles } from '@material-ui/core/styles'
import colors from 'theme/dark/colors'

const useMemeDesktopModalStyles = makeStyles({
  dialog: {
    'backdrop-filter': 'blur(15px)'
  },
  dialogContent: {
    backgroundColor: '#212C41',
    '&.MuiDialogContent-root': {
      padding: '0'
    }
  },
  publication_grid_wrapper: {
    gridTemplateAreas: `
      "publicationContent publcationHeader"
      "publicationContent publicationContentTitle"
      "publicationContent publicationContentDescription"
      "publicationContent publicationFooter"
    `
  },
  closeBtn: {
    position: 'absolute',
    top: 4,
    left: 20,
    zIndex: 3000,
    backgroundColor: 'rgb(21,30,49,0.5)',
    '&.MuiIconButton-root:hover': {
      backgroundColor: 'rgb(21,30,49,0.4)'
    }
  },
  loadContainer: {
    height: '100%',
    display: 'grid',
    placeItems: 'center'
  }
})

export default useMemeDesktopModalStyles
