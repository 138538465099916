import { Box, Button, Menu, MenuItem, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import Icon from 'components/atoms/Icons'
import useMenu from 'hooks/useMenu'
import useProfileMenuDropdownStyles from './style'
import useModal from 'hooks/useModal'

import { redirectLinks } from 'constants/redirectLinks'
import { useAuth } from 'context/auth/authContext'
import { PublicUser } from 'ymca/models/user.model'
import { DEFAULT_AVATAR_IMAGE } from 'ymca/services/image.service'

import packageJson from '../../../../package.json'
import UserProfileSetting from 'components/user/UserProfileSetting'
import useWindowSize from 'hooks/useWindowSize'
import { BREAKPOINT_LAPTOP } from 'theme/shared/breakpoint'
import Avatar from 'components/molecules/Avatar'
import getImageResolutions, {
  getImageUrlSourceType
} from 'utils/getImageResolutions'

interface ProfileMenuDropdownProps {}

const ProfileMenuDropdown = (props: ProfileMenuDropdownProps) => {
  const classes = useProfileMenuDropdownStyles()
  const navigate = useNavigate()
  const { userInfo, logout, isAuthenticated } = useAuth()
  const { displayName, avatar, username } = userInfo?.userData as PublicUser
  const { width } = useWindowSize()
  const isMobile = width <= BREAKPOINT_LAPTOP

  const {
    isOpen: isProfileMenuOpen,
    open: openProfileMenu,
    anchorEl: profileMenuAnchorEl,
    close: closeProfileMenu
  } = useMenu()

  const {
    isOpen: isSettingModalOpen,
    close: closeSettingModal,
    open: openSettingModal
  } = useModal()

  const handleMenuOpenDropdown = (e: any) => {
    openProfileMenu(e)
  }
  const handleMenuCloseDropdwon = (e: any) => {
    closeProfileMenu()
  }

  const goToSettings = () => {
    isMobile ? navigate(`/${redirectLinks.settings}`) : openSettingModal()
    closeProfileMenu()
  }

  const handleLogout = async (): Promise<any> => {
    try {
      logout()
    } catch {
      console.log('Failed to log out')
    }
  }

  const { x2, x3, fallback } = getImageResolutions(avatar)

  return (
    <>
      <Button
        className={classes.profileMenuBtn}
        onClick={handleMenuOpenDropdown}
        aria-controls={isProfileMenuOpen ? 'profile-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={isProfileMenuOpen ? 'true' : undefined}
        id='profile-menu'
      >
        <Avatar
          alt='account'
          src={fallback ?? DEFAULT_AVATAR_IMAGE}
          className={classes.avatar}
          pictureSources={[
            {
              srcSet: `${x2} 1x, ${x3} 2x`,
              type: getImageUrlSourceType(x3)
            }
          ]}
        />
      </Button>

      <Menu
        anchorEl={profileMenuAnchorEl}
        id='profile-menu'
        aria-labelledby='profile-menu'
        open={isProfileMenuOpen}
        onClose={handleMenuCloseDropdwon}
        className={classes.profileMenu}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom'
        }}
      >
        <Box className={classes.profileContainer}>
          <Avatar
            alt='account'
            src={fallback ?? DEFAULT_AVATAR_IMAGE}
            className={classes.avatar}
            pictureSources={[
              {
                srcSet: `${x2} 1x, ${x3} 2x`,
                type: getImageUrlSourceType(x3)
              }
            ]}
          />

          <Box display='flex' flexDirection='column'>
            <Typography className={classes.displayName}>
              {displayName ?? 'Memer'}
            </Typography>
            <Link
              to={`/profile/${username}`}
              className={classes.profileLink}
              onClick={closeProfileMenu}
            >
              View Profile
            </Link>
          </Box>
        </Box>
        <Box className={classes.menuList}>
          <MenuItem className={classes.menuItem} onClick={goToSettings}>
            <Box className={classes.navItemLeft}>
              <Icon icon='setting' />
              <Typography className={classes.navTitle}>Settings</Typography>
            </Box>
          </MenuItem>

          <MenuItem
            className={classes.menuItem}
            // disabled={config.activateReferralPage !== "true" && true}
            onClick={() => {
              navigate(`/${redirectLinks.referral}`)
              closeProfileMenu()
            }}
          >
            <Box className={classes.navItemLeft}>
              <Icon icon='referral-reward' />
              <Typography className={classes.navTitle}>Refer & earn</Typography>
            </Box>
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={closeProfileMenu}>
            <Link
              to='//forms.gle/nDXG9q8FQjhYwkSk7'
              target='_blank'
              className={classes.navItemLeft}
            >
              <Icon icon='give-feedback' />
              <Typography className={classes.navTitle}>
                Give Feedback
              </Typography>
            </Link>
          </MenuItem>
          {isAuthenticated && (
            <MenuItem
              className={`${classes.menuItem} ${classes.menuItemTopBorder}`}
              onClick={async () => {
                await handleLogout()
                closeProfileMenu()
              }}
            >
              <Box className={classes.navItemLeft}>
                <Icon icon='logout' />
                <Typography className={classes.navTitle}>Log Out</Typography>
              </Box>
            </MenuItem>
          )}
        </Box>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          px='.5rem'
        >
          <Typography className={classes.footertext}>
            v{packageJson.version}
          </Typography>
          <Box display='flex' gap='.25rem'>
            <Link
              to='//twitter.com/YouMemeWorld'
              onClick={closeProfileMenu}
              target='_blank'
              className={classes.footerIconBtn}
            >
              <Icon icon='social-twitter' />
            </Link>
            <Link
              to='//youtube.com/channel/UCV-fixR4aIvO9857MpYPHrw'
              onClick={closeProfileMenu}
              target='_blank'
              className={classes.footerIconBtn}
            >
              <Icon icon='social-youtube' />
            </Link>
            <Link
              to='//t.me/YouMemeNews'
              onClick={closeProfileMenu}
              className={classes.footerIconBtn}
              target='_blank'
            >
              <Icon icon='social-telegram' />
            </Link>
            <Link
              to='//discord.gg/AwcRnkfAn5'
              onClick={closeProfileMenu}
              target='_blank'
              className={classes.footerIconBtn}
            >
              <Icon icon='social-discord' />
            </Link>
          </Box>
        </Box>
      </Menu>
      <UserProfileSetting
        open={isSettingModalOpen}
        handleClose={closeSettingModal}
      />
    </>
  )
}

export default ProfileMenuDropdown
