import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import Post from "components/pages/Post";
import { Post as PostModel } from "ymca/models/post.model";
import { styled } from "@mui/material";

const useStyles = makeStyles((theme: any) => ({
  description: {
    color: "#E2E4E9",
    fontSize: 15,
    fontWeight: 500,
    margin: "14px 0 12px 0",
    width: 641,
    overflowWrap: "break-word",
  },
  "@media (max-width: 550px)": {
    description: {
      width: "100%",
      overflowWrap: "break-word",
    },
  },
}));

const PublicationContentDescriptionWrapper = styled("section", {
  name: "PublicationContentDescriptionWrapper",
})(() => {
  return {
    gridArea: "publicationContentDescription",
  };
});

export interface PublicationContentProp {
  post: PostModel;
}

const PublicationContentDescription = ({
  post,
}: PublicationContentProp): JSX.Element => {
  const classes = useStyles();

  const [saved, setSaved] = useState<boolean>(false);
  const [navPost, setPost] = useState<boolean>(false);

  useEffect(() => {
    setSaved(post.isBookmarkedByUser);
  }, [post]);
  if (!post.description) return <></>;
  return (
    <PublicationContentDescriptionWrapper>
      {post.description && (
        <Typography className={classes.description}>
          {post.description}
        </Typography>
      )}
      {navPost && <Post postData={post} handlePost={setPost} saved={saved} />}
    </PublicationContentDescriptionWrapper>
  );
};

export default PublicationContentDescription;
