import { FilesetResolver, LanguageDetector } from '@mediapipe/tasks-text';

export class LanguageDetectorService {
    protected detector: LanguageDetector | undefined;

    public async loadModel() {
        const wasmLoader = await FilesetResolver.forTextTasks(
        'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-text@latest/wasm',
        );
        this.detector = await LanguageDetector.createFromOptions(wasmLoader, {
            baseOptions: {
                modelAssetPath: `https://storage.googleapis.com/mediapipe-models/language_detector/language_detector/float32/1/language_detector.tflite`,
            },
        });
    }

    public async isInputEnglish(input: string): Promise<boolean> {
        if (!this.detector) {
            await this.loadModel();
        }
        const result = this.detector?.detect(input);
        return result?.languages[0].languageCode === 'en' ?? false;
    }
}

export default new LanguageDetectorService();
