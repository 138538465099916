import {
  CommunitiesOutletContainer,
  CommunitiesPageTitle,
  SearchCommunityForm,
} from "components/layout/CommunitiesWrapper";
import useSearchCommunity from "hooks/useSearchCommunity";
import CommunitiesGridContainer from "components/organisms/CommunitiesGridContainer";
import { useGetCommunitiesQuery } from "hooks/api-hooks/useCommunity";
import { MetaTags } from "components/shared";
import { redirectLinks } from "constants/redirectLinks";

const CommunitiesExplorePage = () => {
  const query = useGetCommunitiesQuery({
    viewType: "explore",
  });

  const handleOnSearch = (searchValue: string) => {
    query.searchRefetch(searchValue);
  };

  const { searchFormRef } = useSearchCommunity({
    onSearch: handleOnSearch,
  });

  return (
    <CommunitiesOutletContainer aria-label='explore communities'>
      <MetaTags
        show={window.location.href.includes(
          `/${redirectLinks.communities}/explore`
        )}
        title='Explore Communities | YouMeme'
        description='The World’s First Meme-to-Earn Social Network. Select a meme template, add text, images, stickers and save your meme. Your daily dose of meme is here.'
        url={`${process.env.REACT_APP_DEPLOYMENT_URL}/${redirectLinks.communities}/explore`}
      />
      <SearchCommunityForm ref={searchFormRef} />
      <CommunitiesPageTitle title='Explore Communities' />
      <CommunitiesGridContainer
        isLoading={query.status === "loading"}
        communities={query.data?.data!}
        hasNextPage={query.data?.hasMore}
        fetchNextPage={query.fetchNextPage}
        isFetchingNextPage={query.isFetching}
        joinCommunity
      />
    </CommunitiesOutletContainer>
  );
};

export default CommunitiesExplorePage;
