import { GenericPaginatedSearchParamsDto, PaginatedGenerator } from './dtos/common.dto'
import { MimeType } from './models/image.model'

export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD' | 'OPTIONS'

export type BodyAllowedMethod = 'POST' | 'PUT' | 'PATCH'

export const BODY_ALLOWED_METHODS = ['POST', 'PUT', 'PATCH']

export const METHOD_HAS_BODY = ['PUT', 'POST', 'GET', 'PATCH']

export class APIResponse<T> {
  public data: T
  public status: number
  public isSuccess: boolean
  public isCreated: boolean
  public isBadRequest: boolean
  public is400Error: boolean
  public isNotFound: boolean
  public isNotAcceptable: boolean
  public isNoPermission: boolean
  public isGatewayIssues: boolean
  public isInternalError: boolean

  constructor (
    data: T,
    status: number
  ) {
    this.data = data
    this.status = status
    this.isSuccess = status >= 200 && status < 300
    this.isCreated = status === 201
    this.isBadRequest = status === 400 || status === 409
    this.is400Error = status >= 400 && status < 500
    this.isNoPermission = status === 401 || status === 403
    this.isNotFound = status === 404
    this.isNotAcceptable = status === 406
    this.isInternalError = status === 500
    this.isGatewayIssues = status > 500
  }
}

export function isBodyAllowedMethod (
  method: HTTPMethod
): boolean {
  return BODY_ALLOWED_METHODS.includes(method)
}

export function isMethodWithResponseBody (
  method: HTTPMethod
): boolean {
  return METHOD_HAS_BODY.includes(method)
}

export class URLSearchParamsCapable {
  public toURLSearchParams (): URLSearchParams {
    return new URLSearchParams()
  }
}

export function ParamsCapableToPlainJSON (
  params: URLSearchParamsCapable
): Record<string,string> {
  if (params == null) {
    params = new GenericPaginatedSearchParamsDto({})
  }
  const urlParams = params.toURLSearchParams()
  const queryArgs: Record<string,string> = {}
  urlParams.forEach((value, key) => {
    queryArgs[key] = value
  })
  return queryArgs
}

// JSONFetcher is an abstract class that exposes the ability to
// fetch data from an API as an APIResponse Class.
export abstract class JSONFetcher {
  public abstract fetchJSON<T> (
    method: HTTPMethod,
    path: string,
    query?: URLSearchParamsCapable,
    body?: any | FormData,
    mimetype?: MimeType,
    skipAuth?: boolean,
    headers?: Record<string, string>
  ): Promise<APIResponse<T>>

  public abstract fetchPaginatedJSON<T> (
    path: string,
    query: Record<string, string>,
  ): PaginatedGenerator<T>

  public abstract fetchBlob(
    method: HTTPMethod,
    path: string,
    query?: URLSearchParamsCapable,
    body?: any | FormData,
    mimetype?: MimeType,
    skipAuth?: boolean
  ): Promise<Blob>
}
