import { QuerySearchParamsDto } from './common.dto'
import { PostContentType, PostType } from '../models/post.model'
import { URLSearchParamsCapable } from 'ymca/jsonFetcher'

export class FindPostsDto
  extends QuerySearchParamsDto
  implements URLSearchParamsCapable
{
  // You can pass multiple postIds in the query string separated by commas
  public ids: string[] = [];

  // You can pass multiple postIds in the query string separated by commas
  public muteIds: string[] = [];

  // You can pass multiple categoryIds in the query string separated by commas
  public categoryIds: string[] = [];

  // you can pass multiple categoryIds in the query string separated by commas
  public muteCategoryIds: string[] = [];

  // if set to true, this only fetches posts from users that the requester
  // is following. For this to work, the requester must be logged in.
  public following?: boolean;

  // you can pass multiple userIds in the query string separated by commas
  public userIds: string[] = [];

  // you can pass multiple userIds in the query string separated by commas
  public muteUserIds: string[] = [];

  public startTime?: number; // defaults to new Date(0).getTime()

  public endTime?: number; // defaults to new Date().getTime()

  // you can pass multiple tags in the query string separated by commas
  public tags: string[] = [];

  // you can pass multiple tags in the query string separated by commas
  public muteTags: string[] = [];

  // if set to true, ALL the tags need to be present in the posts searched for
  public hasAllTags?: boolean;

  // if set to true, show only sponsored posts
  public onlySponsored?: boolean;

  // case-sensitive search for the title & description
  public search?: string;

  public sort?: PostSort;

  public type?: PostType;

  // You can pass multiple eventIds in the query string separated by commas
  public eventIds: string[] = [];

  // You can pass multiple communityIds in the query string separated by commas
  public communityId?: string;

  public toURLSearchParams(): URLSearchParams {
    const params = super.toURLSearchParams();
    if (this.ids.length > 0) {
      params.append("id", this.ids.join(","));
    }
    if (this.muteIds.length > 0) {
      params.append("muteId", this.muteIds.join(","));
    }
    if (this.categoryIds.length > 0) {
      params.append("categoryId", this.categoryIds.join(","));
    }
    if (this.muteCategoryIds.length > 0) {
      params.append("muteCategoryId", this.muteCategoryIds.join(","));
    }
    if (this.following !== undefined) {
      params.append("following", "true");
    }
    if (this.userIds.length > 0) {
      params.append("userId", this.userIds.join(","));
    }
    if (this.muteUserIds.length > 0) {
      params.append("muteUserId", this.muteUserIds.join(","));
    }
    if (this.startTime !== undefined) {
      params.append("startTime", this.startTime.toString());
    }
    if (this.endTime !== undefined) {
      params.append("endTime", this.endTime.toString());
    }
    if (this.tags.length > 0) {
      params.append("tags", this.tags.join(","));
    }
    if (this.muteTags.length > 0) {
      params.append("muteTags", this.muteTags.join(","));
    }
    if (this.hasAllTags !== undefined) {
      params.append("hasAllTags", this.hasAllTags.toString());
    }
    if (this.onlySponsored !== undefined) {
      params.append("onlySponsored", this.onlySponsored.toString());
    }
    if (this.search !== undefined) {
      params.append("search", this.search);
    }
    if (this.sort !== undefined) {
      params.append("sort", this.sort);
    }
    if (this.type !== undefined) {
      params.append("type", this.type);
    }
    if (this.eventIds.length > 0) {
      params.append("eventId", this.eventIds.join(","));
    }
    if (this.communityId !== undefined) {
      params.append("communityId", this.communityId);
    }
    return params;
  }
}

export class PostIdDto implements URLSearchParamsCapable {
  public postId: string = ''

  public toURLSearchParams (): URLSearchParams {
    const params = new URLSearchParams()
    params.append('postId', this.postId)
    return params
  }
}

export type PostSort = 'popular' | 'latest'

export class CreatePostBodyDto {
  public title: string = ''
  public description: string = ''
  public tags: string[] = []
  public categoryName: string = ''
  public isPublished = true
  public type: PostType = 'public'
  public contentType: PostContentType = 'meme'
  public eventId: string = ''
  public source: File = new File([''], '')
  public overlay: File = new File([''], '')
  public rendered: File = new File([''], '')
  public thumbnail: File = new File([''], '')

  public toFormData (): FormData {
    const formData = new FormData()
    formData.append('title', this.title)
    formData.append('description', this.description)
    formData.append('tags', this.tags.join(','))
    if ((this.categoryName !== undefined) && (this.categoryName !== '') && (this.categoryName !== null)) {
      formData.append('category', this.categoryName ?? '')
    }
    formData.append('isPublished', this.isPublished.toString())
    formData.append('type', this.type)
    formData.append('contentType', this.contentType)
    if ((this.eventId !== undefined) && (this.eventId !== '') && (this.eventId !== null)) {
      formData.append('eventId', this.eventId)
    }
    formData.append('source', this.source)
    formData.append('overlay', this.overlay)
    formData.append('rendered', this.rendered)
    formData.append('thumbnail', this.thumbnail)
    return formData
  }
}

export class GetPostsByEventDTO extends QuerySearchParamsDto {
  public eventId: string = ''

  public toURLSearchParams(): URLSearchParams {
    const params = super.toURLSearchParams()

    if (this.eventId !== '') {
      params.append('eventId', this.eventId)
    }

    return params
  }
}

export class GetUserPostsByEventDTO extends QuerySearchParamsDto {
  public eventId: string = ''
  public userId: string = ''

  public toURLSearchParams(): URLSearchParams {
    const params = super.toURLSearchParams()

    if (this.eventId !== '') {
      params.append('eventId', this.eventId)
    }

    if (this.userId !== '') {
      params.append('userId', this.userId)
    }

    return params
  }
}

export class GetEventTopEntriesDTO extends QuerySearchParamsDto {
  public postIds: string[] = []

  public toURLSearchParams(): URLSearchParams {
    const params = super.toURLSearchParams()
    if (this.postIds.length > 0) {
      params.append('id', this.postIds.join(','))
    }
    return params
  }
}
