import { LensTombstoneItem } from 'ymca/dtos/lens.dto'
import { BaseService } from './base.service'

export class LensTombstoneService extends BaseService {
  protected async fetchTombstoneData (type: 'banned-user' | 'deleted-post'): Promise<LensTombstoneItem[]> {
    const fetcher = this.common.jsonFetcher.fetchPaginatedJSON<LensTombstoneItem>(`/api/lens/${type}`, { offset: '0', limit: '100' })
    const items: LensTombstoneItem[] = []
    for await (const page of fetcher) {
      items.push(...page.data)
    }
    return items
  }

  protected async setTombstonedUserIds (): Promise<void> {
    const users = await this.fetchTombstoneData('banned-user')
    const userIds = users.map(u => `_${u.id}`)
    this.common.bannedLensUsers = new Set(userIds)
    console.debug(`Loaded ${userIds.length} banned lens users`)
  }

  protected async setTombstonedPostIds (): Promise<void> {
    const posts = await this.fetchTombstoneData('deleted-post')
    const postIds = posts.map(p => `_${p.id}`)
    this.common.bannedLensPosts = new Set(postIds)
    console.debug(`Loaded ${postIds.length} banned lens posts`)
  }

  public async banLensUser (userId: string): Promise<void> {
    const res = await this.common.jsonFetcher.fetchJSON<any>('PUT', `/api/lens/banned-user/${userId}`)
    if (!res.isSuccess) {
      console.error(res)
      throw new Error(`Failed to ban lens user ${userId}`)
    }
  }

  public async unbanLensUser (userId: string): Promise<void> {
    const res = await this.common.jsonFetcher.fetchJSON<any>('DELETE', `/api/lens/banned-user/${userId}`)
    if (!res.isSuccess) {
      console.error(res)
      throw new Error(`Failed to unban lens user ${userId}`)
    }
  }

  public async softDeleteLensPost (postId: string): Promise<boolean> {
    const res = await this.common.jsonFetcher.fetchJSON<any>('PUT', `/api/lens/deleted-post/${postId}`)
    if (!res.isSuccess) {
      console.error(res)
      throw new Error(`Failed to soft delete lens post ${postId}`)
    }
    return res.isSuccess
  }

  public async undoSoftDeleteLensPost (postId: string): Promise<void> {
    const res = await this.common.jsonFetcher.fetchJSON<any>('DELETE', `/api/lens/deleted-post/${postId}`)
    if (!res.isSuccess) {
      console.error(res)
      throw new Error(`Failed to undo soft delete lens post ${postId}`)
    }
  }

  public async setTombstoneIds (): Promise<void> {
    await Promise.all([this.setTombstonedPostIds(), this.setTombstonedUserIds()])
  }
}
