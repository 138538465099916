import { Box, Dialog, DialogContent, IconButton, Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { forwardRef } from 'react'
import CloseIcon from '@material-ui/icons/Close'

import { Spinner } from 'components/loading/LoadingSpinner'
import PublicationMain from '../../PublicationMain'
import config from 'utils/config'
import useMemeDesktopModalStyles from './style'
import { PublicationModalProp } from '../PublicationModal'

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

const DesktopMemeModal = (props: PublicationModalProp): JSX.Element => {
  const classes = useMemeDesktopModalStyles()
  const {
    isOpen,
    onClose,
    post,
    disableEditMeme = false,
    copyLinkBasePath = config.deploymentUrl
  } = props

  if (!isOpen) return <></>
  return (
    <Dialog
      open={isOpen}
      className={classes.dialog}
      onClose={onClose}
      aria-labelledby='comment-modal'
      aria-describedby='comment page modal'
      fullScreen
      TransitionComponent={Transition}
    >
      <IconButton
        edge='start'
        color='inherit'
        onClick={onClose}
        aria-label='close-meme-comment-modal'
        className={classes.closeBtn}
      >
        <CloseIcon fontSize='large' />
      </IconButton>
      <DialogContent className={classes.dialogContent}>
        {post ? (
          <PublicationMain
            modal
            post={post}
            headingType={'h2'}
            fontSize='1rem'
            showComment
            disableEditMeme={disableEditMeme}
            copyLinkBasePath={copyLinkBasePath}
          />
        ) : (
          <Box className={classes.loadContainer}>
            <Spinner width={50} height={50} />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default DesktopMemeModal
