import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { createContext, useState, SetStateAction, ReactElement } from "react";
import ServerNotRespond from "components/shared/ServerNotRespond";
import Sidebar from "components/sidebar/Sidebar";
import MobileFooter from "components/mobile/MobileFooter";
import { ComponentWithChildrenPropsOnly } from "../../types/runtimeSchema";
import { Outlet } from "react-router-dom";
import { useUserSelf } from "hooks/useUserSelf";
import colors from "theme/dark/colors";
import Header from "./Header";
import SidebarMobile from "components/organisms/SidebarMobile";

const useStyles = makeStyles({
  pageWrapper: {
    display: 'flex',
    position: 'relative',
    maxWidth: 1260,
    margin: 'auto',
    background: colors.darkBlue,
  },
  container: {
    background: colors.darkBlue,
    width: '100%',
    paddingBottom: '3rem'
  },
  memesContainer: {
    borderRadius: 10,
    width: 'calc(100% - 235px)',
    paddingTop: 16,
    boxSizing: 'border-box'
  },
  emptySection: {
    width: 250
  },
  '@media (max-width: 1380px)': {
    emptySection: {
      width: 220
    },
    memesContainer: {
      width: 'calc(100% - 220px)'
    }
  },
  '@media (max-width: 1200px)': {
    memesContainer: {
      width: 'calc(100% - 90px)'
    },
    emptySection: {
      width: 65
    }
  },
  '@media (max-width: 700px)': {
    memesContainer: {
      width: '100%',
      paddingLeft: 52
    }
  },
  '@media (max-width: 550px)': {
    memesContainer: {
      margin: 0,
      borderRadius: 0,
      padding: 0,
      paddingBottom: 5
    },
    emptySection: {
      display: 'none'
    },
  }
})

const DashboardTemplate = (): JSX.Element => {
  const classes = useStyles()

  // add a custom hook that changes when server doesn't respond
  const [isServer] = useState<boolean>(true)

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const [opensidebar, setOpenSidebar] = useState<boolean>(false)

  const { selfData } = useUserSelf();

  const onHandleSidebar = (e: any): void => {
    setOpenSidebar(e)
  }
  return (
    <DashboardUpdateProvider>
      <Box className={classes.container} id='scrollableDiv'>
        <Header
          handleDrawerOpen={setDrawerOpen}
          drawerOpen={drawerOpen}
          handleSidebar={onHandleSidebar}
          openSidebar={opensidebar}
        />
        <Box className={classes.pageWrapper}>
          <Sidebar />
          {opensidebar && (
            <SidebarMobile open={opensidebar} handleSidebar={onHandleSidebar} />
          )}
          <Box className={classes.emptySection} />
          {isServer ? (
            <Box className={classes.memesContainer}>
              <Outlet />
            </Box>
          ) : (
            <ServerNotRespond />
          )}
        </Box>
        <MobileFooter />
      </Box>
    </DashboardUpdateProvider>
  );
}

export const DashboardTemplateContext = createContext<[boolean, React.Dispatch<SetStateAction<boolean>>]>([false, () => {}])

export const DashboardUpdateProvider = ({
  children,
}: ComponentWithChildrenPropsOnly): ReactElement => {
  return (
    <DashboardTemplateContext.Provider value={useState<boolean>(false)}>
      {/* 
      The above line passes the useState tuple [state, setState] with initial value as false which then can be used in any child component as 
      [state] = useContext(DashboardTemplateContext) to get the current value of state as a hook
      [_, setState] = useContext(DashboardTemplateContext)to update the context value and its corresponding hooks if called anywhere
    */}
      {children}
    </DashboardTemplateContext.Provider>
  );
};

export default DashboardTemplate
