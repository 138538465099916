import useShow from "../useShow";

export interface UseDrawerResult {
  isOpen: boolean
  close: () => void
  open: (e?: React.MouseEvent<HTMLElement>) => void
}

const useDrawer = (): UseDrawerResult => {
  const { isShowing: isOpen, show, hide } = useShow()

  const open = (event?: React.MouseEvent<HTMLElement>) => {
    return show()
  }
  const close = () => {
    return hide()
  }

  return {
    isOpen,
    close,
    open
  }
}

export default useDrawer;
