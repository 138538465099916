import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import colors from 'theme/dark/colors'

const useStyles = makeStyles({
  dialogActions: {
    textAlign: 'center',
    borderBottomRightRadius: '5px',
    borderBottomLeftRadius: '5px',
    backgroundColor: colors.darkBlue,
    color: colors.white,
    border: 0
  },
  dialogTitle: {
    textAlign: 'center',
    backgroundColor: colors.darkBlue50,
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
    color: colors.white
  },
  dialogContentText: {
    fontSize: '15px',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '-0.3px',
    alignSelf: 'center',
    color: colors.white
  },
  dialogMessage: {
    fontSize: '18px',
    fontWeight: 900,
    lineHeight: '23px'
  },
  dialogContent: {
    textAlign: 'center',
    backgroundColor: colors.darkBlue,
    border: 0
  },
  closeBtn: {
    height: '44px',
    width: '97px',
    borderRadius: '7px',
    fontWeight: 700,
    fontSize: '14px',
    color: colors.white,
    boxShadow: 'none',
    border: '2px solid red'
  },
  understoodBtn: {
    height: '44px',
    width: '97px',
    backgroundColor: colors.primary500,
    borderRadius: '7px',
    fontWeight: 700,
    fontSize: '14px',
    color: colors.white,
    boxShadow: 'none'
  }
})

export interface AlertDialogProps {
  dialogContentText?: string
  open: boolean
  handleAlert: (value: boolean) => void
  handleMeme: (value: boolean) => void
}

const AlertDialog = ({
  dialogContentText,
  open,
  handleAlert,
  handleMeme
}: AlertDialogProps): JSX.Element => {
  const classes = useStyles()

  const handleClose = (value: any): void => {
    handleMeme(value)
    handleAlert(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleAlert(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        PaperProps={{
          style: { borderRadius: 5, border: 0, backgroundColor: '#232A30' }
        }}
      >
        <DialogTitle id='alert-dialog-title' className={classes.dialogTitle}>
          <Typography className={classes.dialogMessage}>
            Work will be lost
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText
            id='alert-dialog-description'
            className={classes.dialogContentText}
          >
            {dialogContentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={() => handleClose(false)}
            color='primary'
            className={classes.closeBtn}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleClose(true)}
            color='primary'
            className={classes.understoodBtn}
          >
            Understood
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AlertDialog
