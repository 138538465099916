import ColorThemeOptions from "theme/shared/color.interface";

const colors: ColorThemeOptions = {
  // primary
  primary100: '',
  primary200: '',
  primary300: '',
  primary400: '#7075ff',
  primary500: '#625FFF',
  primary600: '',
  primary700: '',
  primary800: '',
  primary900: '',

  // secondary
  secondary100: '',
  secondary200: '',
  secondary300: '',
  secondary400: '',
  secondary500: '#33405C', // rgba(51, 64, 92, 1)
  secondary600: '#202B42',
  secondary700: '#27324A',
  secondary800: '#28344C',
  secondary900: '#28344D',

  // tertiary
  tertiary100: '',
  tertiary200: '',
  tertiary300: '',
  tertiary400: '',
  tertiary500: '',
  tertiary600: '',
  tertiary700: '',
  tertiary800: '',
  tertiary900: '',

  // info
  info100: '',
  info200: '',
  info300: '',
  info400: '',
  info500: '',
  info600: '',
  info700: '',
  info800: '',
  info900: '',

  // warning
  warning100: '',
  warning200: '',
  warning300: '',
  warning400: '',
  warning500: '',
  warning600: '',
  warning700: '',
  warning800: '',
  warning900: '',

  // error
  error100: '',
  error200: '',
  error300: '',
  error400: '',
  error500: '#d32f2f',
  error600: '',
  error700: '',
  error800: '',
  error900: '',

  // accent
  accent100: '',
  accent200: '',
  accent300: '',
  accent400: '',
  accent500: '',
  accent600: '',
  accent700: '',
  accent800: '',
  accent900: '',

  // grey
  greyScale100: '#27324A',
  greyScale200: '',
  greyScale300: '#ADB3C0',
  greyScale400: '',
  greyScale500: '#8A98B4',
  greyScale600: '',
  greyScale700: '#808080',
  greyScale800: '',
  greyScale900: '#101520',

  // text
  textPrimary: '#FFFFFF',

  // divider
  border: 'rgba(42, 55, 83, .7)',
  // border: '#2A3753',

  // background
  // backgroundPrimary: '#202A41',
  backgroundPrimary: '#151E31',

  // lens
  lensBrandColorLight: '#00501f',
  lensBrandColor: '#ABFE2C',
  lensBrandColorDark: '#008c5b',

  background: '#FBFBFB',
  titleColor: '#E9F0F9',
  purpleDark: '#412078',
  mainBlack: '#1A0030',
  skyBlue: '#5CE2FF',
  darkBlue: '#151E31',
  darkBlue50: '#202B42',
  darkBlueActive: '#1D273C',
  sliderBtn: '#2A354ECC',
  sliderBtnShadow: '#00000080',
  sliderBtnBorder: '#8A98B41A',
  loaderBackground: '#8A98B480',
  black80: '#12161A',
  black60: '#1D232B',
  mainPurple: '#A0158A',
  lightPurple: '#E867D3',
  secondaryPurple: '#625FFF',
  loadingPurple: '#7402FF',
  mainInput: '#AAAAAA',
  mainInputBorder: '#DFDFDF',
  mainInputBackground: '#F5F5F5',
  mainGreen: '#20AC00',
  disabled: '#AAAAAA',
  linearMainPurple: 'linear-gradient(270deg, #A701A7, #412078 100%)',
  linearMainBlue:
    'linear-gradient(285.87deg, #7075ff 3.44%, #35a6eb 71.68%, #01ffff 125.89%)',

  white: '#FFFFFF',
  mainGrey: '#808080',
  mainGreyOpaque: '#121A2B',
  dialogColor: '#151820',
  blueBtn: '#35A6EB',
  black: '#000000',
  gray: '#E1E3E8',
  darkGray: '#8895AE',
  colorGray: '#ADB3C0',
  profileImageBackground: '#FFFFFF',
  memeGenBlue: '#1C263A',
  memeGenBlue50: '#2B374F',
  searchBg: '#33405C',
  purpleGradient: 'linear-gradient(87.46deg, #625FFF 2.13%, #A55FFF 163.86%)',
  gradientPurple: '#625FFF',
  gradientPurpleLight: '#B4A4FF',
  pink: '#DC01FF',
  memeTemplateMobileBackground: `linear-gradient(90deg, #DC01FF 0%, #7402FF 81.25%)', height: 400, borderRadius: "0.938rem`,

  // Publication
  publicationTypographyColor: '#E2E4E9',

  // border
  primaryBorder: '#2B3853',

  // Absolute Content Background Color
  absoluteContentBackground: 'rgba(40, 52, 77, 0.7)'
}

export default colors;
