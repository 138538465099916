import { lazy } from "react";
import { redirectLinks } from "constants/redirectLinks";
import { Navigate } from 'react-router-dom'

export const protectedSubRoutesWithDashboardLayout: any[] = [
  {
    index: true,
    element: lazy((): any => import('../pages/HomePageLegacy'))
    // element: lazy((): any => import("../pages/HomePage")),
  },
  {
    path: redirectLinks.trending,
    element: lazy((): any => import('../components/pages/TrendingCategories'))
  },
  {
    path: `${redirectLinks.categories}/:categoryName`,
    element: lazy(
      (): any => import('../components/category/CategoryForTrending')
    )
  },
  {
    path: redirectLinks.followers,
    element: lazy((): any => import('../components/pages/Followers'))
  },
  {
    path: redirectLinks.profileFromSettings,
    element: lazy(
      (): any => import('../components/mobile/MobileGeneralSettings')
    )
  },
  {
    path: redirectLinks.notificationsFromSettings,
    element: lazy(
      (): any => import('../components/mobile/MobileNotificationSettings')
    )
  },
  {
    path: redirectLinks.preferenceFromSettings,
    element: lazy(
      (): any => import('../components/mobile/MobilePreferenceSettings')
    )
  },
  {
    path: redirectLinks.privacyFromSettings,
    element: lazy(
      (): any => import('../components/mobile/MobilePrivacySettings')
    )
  },
  {
    path: redirectLinks.settings,
    element: lazy(
      (): any => import('../components/modals/settings/MobileSettings')
    )
  },
  {
    path: redirectLinks.notifications,
    element: lazy(
      (): any => import('../components/notification/MobileNotification')
    )
  },
  {
    path: redirectLinks.search,
    element: lazy((): any => import('../components/mobile/Search'))
  }
]

export const profileRoutes: any[] = [
  {
    path: '/',
    index: true,
    element: lazy((): any => import('../components/pages/OthersProfilePage'))
  },
  {
    path: '/edit',
    element: lazy((): any => import('../components/pages/MobileEditProfile'))
  },

  {
    path: '/saved',
    element: lazy((): any => import('../components/pages/OthersProfilePage'))
  },
  {
    path: `/${redirectLinks.follow}`,
    element: lazy((): any => import('../components/mobile/Followers'))
  }
]

export const contestRoutes: {
  path: string
  index?: boolean
  protectedRoute?: boolean
  element: any
  routes?: {
    path?: string
    index?: boolean
    protectedRoute?: boolean
    element?: any
    redirectTo?: string
  }[]
}[] = [
  {
    path: 'ongoing',
    index: true,
    element: lazy((): any => import('../pages/ContestListPage'))
  },
  {
    path: 'upcoming',
    element: lazy((): any => import('../pages/ContestListPage'))
  },
  {
    path: 'closed',
    element: lazy((): any => import('../pages/ContestListPage'))
  },
  {
    path: 'rewards',
    element: lazy((): any => import('../pages/ContestListPage')),
    protectedRoute: true
  }
  // {
  //   path: ':id/*',
  //   element: lazy((): any => import('../pages/ContestSubmitPage'))
  // }
]

export const newsRoutes: any[] = [
  {
    path: "/",
    index: true,
    element: lazy((): any => import("../pages/NewsPage")),
  },
  {
    path: ":id",
    index: false,
    element: lazy((): any => import("../components/pages/NewsView")),
  },
];

export const appInfoRoutes: any[] = [
  {
    path: "info",
    index: true,
    element: lazy((): any => import("../pages/AppInfoPage")),
  },
];

export const otherProtectedRoutes: any[] = [
  {
    path: redirectLinks.discover,
    element: lazy((): any => import('../components/layout/Discover'))
  },
  {
    path: redirectLinks.twitterLogin,
    element: lazy((): any => import('../components/auth/TwitterLogin'))
  }
]
