import { Box, Typography, Button } from "@material-ui/core";
import { useState } from 'react'
import Lottie from 'lottie-react'
import ReactLoading from 'react-loading'
import { Alert } from '@mui/material'

import FileUploadAnimation from '../../assets/animations/fileUpload.json'
import useWindowSize from 'hooks/useWindowSize'
import { BREAKPOINT_TABLET_MEDIUM } from 'theme/shared/breakpoint'
import { DefaultYMCA } from '../../ymca/ymca'
import {
  getImageBase64FromSearchEngineURL,
  getImageExtensionFromURL
} from 'meme-generator/utils/services'
import colors from 'theme/dark/colors'

import PictureByCamera from 'meme-generator/imageUpload/PictureByCamera'
import type { ThrottlePurpose } from '../../ymca/dtos/throttle.dto'
import { resizeFileToDataUri } from '../../utils/imageCompression'
import useMemeCamStyles from './memecam-style'
import RemainingAttemptsAI from './RemainingAttemptsAI'
import { processFile } from 'utils/create-meme'
import useAlert from 'hooks/useAlert'

interface MemeCamProps {
  dragListener: (event: any) => void
  processImageWCaptions: (
    image: string,
    caption: string[],
    type: string
  ) => void
}

export default function MemeCam({ processImageWCaptions }: MemeCamProps) {
  const classes = useMemeCamStyles()
  const { isOpen, open: showError, close: closeError, message } = useAlert()
  const [filesDragged, setFilesDragged] = useState(false)
  const [image, setImage] = useState<string>('')
  const [resizedImage, setResizedImage] = useState<string>('')
  const [captions, setCaptions] = useState<string[][]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [secondLoading, setSecondLoading] = useState<boolean>(false)
  const [openCamera, setOpenCamera] = useState<boolean>(false)
  const [throttleApiCount, setThrottleApiCount] = useState<number>(0)
  const isMobile = useWindowSize().width <= BREAKPOINT_TABLET_MEDIUM

  const rateLimitPurpose: ThrottlePurpose = 'image-to-meme-generator'

  const openFileChooser = () => {
    const imgInput = document.getElementById('imageInput') as HTMLImageElement
    imgInput.click()
  }

  const dragListener = async (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault()
    setFilesDragged(false)
    const files = event.dataTransfer.files
    if (files.length === 1) {
      await handleProcessFile(files[0])
    }
  }

  const splitCaptions = (inputString: string) => {
    const parts = inputString.split('|')

    const part1 = parts[0]
    const part2 = parts[1]

    return [part1, part2]
  }

  const handleProcessFile = async (file: File) => {
    try {
      closeError()
      // Show some kind of toast here if file is falsy
      if (!file) return
      let inputElement = document.getElementById(
        'imageInput'
      ) as HTMLInputElement
      inputElement.value = ''

      const processFileRes = await processFile({ file })

      setImage(processFileRes.data)

      const imgData = await resizeFileToDataUri(file, 300, 300)
      setResizedImage(imgData)
      setLoading(true)
      await generateMemecamCaption(imgData)
    } catch (error: any) {
      showError({
        message: error?.message
      })
      console.log(error)
    }
  }

  const generateMemecamCaption = async (imgData: string) => {
    try {
      const { originalText, generatedCaptions } =
        await DefaultYMCA.memegenService.generateMemeFromImage(imgData)
      const splittedCaptions = generatedCaptions.map((caption) =>
        splitCaptions(caption)
      )
      if (generatedCaptions.length > 0) {
        setCaptions(splittedCaptions)
        setLoading(false)
        setSecondLoading(true)
      }
      const nextGeneratedMemes =
        await DefaultYMCA.memegenService.generateMemeFromImageCaption(
          originalText,
          4
        )
      if (nextGeneratedMemes.generatedCaptions.length > 0) {
        setSecondLoading(false)
        const nextSplittedCaptions = nextGeneratedMemes.generatedCaptions.map(
          (caption) => splitCaptions(caption)
        )
        const newCaptions = [...splittedCaptions, ...nextSplittedCaptions]
        setCaptions(newCaptions)
        setThrottleApiCount(throttleApiCount + 1)
      }
    } catch (e) {
      setLoading(false)
      setSecondLoading(false)
      console.error(e)
    }
  }

  const getImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      console.error('No image given')
      return
    }
    await handleProcessFile(event.target.files[0])
  }

  const processGenereratedImages = async (
    imageURL: string,
    captionValue: string[]
  ) => {
    const base64Image = await getImageBase64FromSearchEngineURL(imageURL)
    const imageExtension = await getImageExtensionFromURL(imageURL)
    processImageWCaptions(base64Image, captionValue, imageExtension)
  }

  const handleCameraGenerate = async () => {
    setLoading(true)
    await generateMemecamCaption(image)
    setOpenCamera(false)
  }

  const handleReset = () => {
    setImage('')
    setCaptions([])
    setFilesDragged(false)
  }

  const handleRegenerate = async () => {
    setLoading(true)
    await generateMemecamCaption(resizedImage)
  }

  return (
    <>
      {!openCamera && (
        <Box className={`${classes.root} index-pop-up`}>
          <Box className={classes.memeCamFileUploadWrapper}>
            <Typography className={classes.memeCamText}>
              Upload a picture and let AI do its magic
            </Typography>
            {isOpen && (
              <Alert
                severity='error'
                variant='standard'
                className={classes.alert}
                onClose={closeError}
              >
                {message}
              </Alert>
            )}
            {image === '' ? (
              <Box
                style={{
                  alignItems: 'center',
                  display: 'flex'
                }}
                className={
                  filesDragged
                    ? `${classes.dragwrapper} ${classes.dragged}`
                    : classes.dragwrapper
                }
                onDrop={dragListener}
                onDragLeave={() => setFilesDragged(false)}
                onDragEnter={() => setFilesDragged(true)}
                onDragOver={(e) => {
                  e.preventDefault()
                }}
                onClick={openFileChooser}
              >
                <input
                  type='file'
                  accept='image/jpeg,image/jpg,image/png,image/webp'
                  id='imageInput'
                  onChange={getImage}
                  style={{ display: 'none' }}
                />
                <Typography className={`${classes.dragTitle} text-title`}>
                  Drop a selfie or
                </Typography>
                <Typography className={classes.dragTxtOne}>
                  browse image
                </Typography>
                <Typography className={classes.dragFormat}>
                  Supported: JPEG, PNG, WebP
                </Typography>
                <Lottie
                  animationData={FileUploadAnimation}
                  loop={true}
                  style={{ maxWidth: '5.8rem', maxHeight: '5.8rem' }}
                />
              </Box>
            ) : loading ? (
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                height={'100%'}
              >
                <ReactLoading type='balls' color={colors.loaderBackground} />
              </Box>
            ) : (
              <Box
                display={'flex'}
                justifyContent={'center'}
                height={isMobile ? '85%' : '70%'}
                maxWidth='100%'
              >
                <Box className={classes.memeCamGridWrapper}>
                  {captions.length > 0 &&
                    captions.map((caption, i) => (
                      <Box
                        key={i}
                        position='relative'
                        style={{
                          height: '100%',
                          width: '100%',
                          cursor: 'pointer'
                        }}
                        onClick={() => processGenereratedImages(image, caption)}
                      >
                        <span
                          className={
                            classes.memeCaption + ' ' + classes.memeCaptionTop
                          }
                        >
                          {caption[0]}
                        </span>
                        <img
                          src={image}
                          alt=''
                          className={classes.memecamItemWrapper}
                          style={{
                            height: '100%',
                            objectFit: 'cover',
                            width: '100%'
                          }}
                        />
                        <span
                          className={
                            classes.memeCaption +
                            ' ' +
                            classes.memeCaptionBottom
                          }
                        >
                          {caption[1]}
                        </span>
                      </Box>
                    ))}
                  {secondLoading && (
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <ReactLoading
                        type='balls'
                        color={colors.loaderBackground}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            )}
            <Box className={classes.memeCamFooter}>
              {image !== '' ? (
                <Box className={classes.memeCamFooterBottonGenerateWrapper}>
                  <Button
                    onClick={handleReset}
                    className={classes.button}
                    style={{
                      gridArea: 'resetBtn',
                      background: isMobile
                        ? colors.white
                        : colors.darkBlueActive,
                      color: isMobile ? colors.black80 : colors.greyScale500
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    onClick={handleRegenerate}
                    className={classes.button}
                    style={{
                      gridArea: 'regenerateBtn',
                      background: isMobile
                        ? colors.white
                        : colors.darkBlueActive,
                      color: isMobile ? colors.black80 : colors.greyScale500
                    }}
                  >
                    Regenerate
                  </Button>
                  <RemainingAttemptsAI
                    rateLimitPurpose={rateLimitPurpose}
                    className={classes.attemptsInfo}
                    count={throttleApiCount}
                  />
                </Box>
              ) : (
                <Box
                  className={classes.memeCamButton}
                  onClick={() => setOpenCamera(true)}
                >
                  <Typography className={classes.memeCamButtonTitle}>
                    Camera
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}

      {openCamera && (
        <>
          <PictureByCamera
            isOpen={openCamera}
            navigate={(value: any) => {
              const image: string = value.data
              setImage(image)
            }}
          />
          <Box
            display='flex'
            justifyContent={isMobile ? 'flex-start' : 'flex-end'}
            marginTop={'-1.5rem'}
          >
            <Button
              disabled={image === '' || loading}
              onClick={handleCameraGenerate}
              className={classes.button + ' selected'}
            >
              {loading ? (
                <ReactLoading type='balls' color={colors.loaderBackground} />
              ) : (
                'Generate'
              )}
            </Button>
          </Box>
        </>
      )}
    </>
  )
}
