import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Typography } from '@material-ui/core'

const useStyles = makeStyles({
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    // padding: '6px 0 6px 10px',
    // margin: '0 0 0 10px',
    flexDirection: 'column'
  },
  itemtitle: {
    color: '#E2E4E9',
    fontWeight: 900,
    fontSize: '26px',
    lineHeight: '31.47px',
    width: '100%'
  },
  description: {
    color: '#808893',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    marginTop: '18px'
  },
  itemimage: {
    minWidth: '30px'
  },
  subtitle: {
    fontWeight: 900,
    fontSize: '16px',
    lineHeight: '19.36px',
    color: '#E2E4E9'
  },
  disableButton: {
    background: 'var(--main-gradient)',
    display: 'flex',
    height: '46px',
    width: '128px',
    borderRadius: '5px',
    marginTop: '30px',
    justifyContent: 'space-between',
    '&:hover': {
      opacity: '0.8'
    }
  },
  disableButtonText: {
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '16.94px',
    paddingLeft: '8.32px'
  },
  showBlockedUsers: {
    color: 'var(--gradient-fall-back)'
  },
  requestDataButton: {
    background: 'transparent',
    display: 'flex',
    height: '40px',
    width: '132px',
    borderRadius: '5px',
    marginTop: '30px',
    border: ' 2px solid var(--gradient-fall-back)',
    justifyContent: 'space-between',
    '&:hover': {
      background: '#1C1F26'
    }
  },
  requestDataButtonText: {
    color: 'var(--gradient-fall-back)',
    fontSize: '13px',
    fontWeight: 700,
    lineHeight: '16.94px',
    paddingLeft: '8.32px'
  }
})

const PrivacySetting = (): JSX.Element => {
  const classes = useStyles()

  return (
    <Box style={{ padding: '45px 0 0 0' }}>
      <Box className={classes.item}>
        <Typography className={classes.itemtitle}>Privacy & Security</Typography>
        <Typography className={classes.description}>
          Manage your account. In the profile settings, you will be able to change your personal
          information, connect with various other platforms and terminate your account
        </Typography>
        <Divider style={{ margin: '25px 0', backgroundColor: '#2B363E' }} />
        <Box>
          <Box style={{ display: 'flex' }}>
            <Typography className={classes.subtitle} style={{ marginTop: '10px' }}>
              TWO FACTOR AUTHENTICATION
            </Typography>
          </Box>
          <Typography className={classes.description} style={{ width: '687px' }}>
            Protect your account from potential intruders, and uninvited guests. 2FA will provide
            you that extra leyer or security. Next time you login to your account, you will be asked
            to provide both your password and an authentication code that you will receive from your
            phone.
          </Typography>
          <Button
            variant='contained'
            disabled
            style={{ background: 'grey' }}
            className={classes.disableButton}
          >
            <Typography className={classes.disableButtonText}>Enable 2FA</Typography>
          </Button>
        </Box>

        <Divider style={{ margin: '35px 0', backgroundColor: '#2B363E' }} />
        <Box>
          <Typography className={classes.subtitle}>BLOCKED MEMERS</Typography>
          <Typography className={classes.description}>
            Here is the list with all the users that you have blocked so far. We suggest you think
            twice before unblocking. We want our community to feel safe all the time.
          </Typography>
          <Typography
            className={`${classes.description} ${classes.showBlockedUsers}`}
            style={{ color: 'grey' }}
          >
            Show blocked users
          </Typography>
        </Box>

        <Divider style={{ margin: '35px 0', backgroundColor: '#2B363E' }} />

        <Box>
          <Typography className={classes.subtitle}>DOWNLOAD YOUR DATA</Typography>
          <Typography className={classes.description}>
            After submiting, you will be receiving an email with the downloadable archive.
          </Typography>
          <Button
            variant='contained'
            className={classes.requestDataButton}
            style={{ background: 'grey', borderColor: 'grey' }}
            disabled
          >
            <Typography className={classes.requestDataButtonText} style={{ color: '#FFFFFF' }}>
              Request Data
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default PrivacySetting
