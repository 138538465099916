import { EventMetadata, EventType } from 'ymca/models/event.model'
import { QuerySearchParamsDto } from './common.dto'

export type EventTense = 'past' | 'present' | 'presentfuture' | 'future'

export type EventSort = 'startingDate' | 'endingDate' | 'createdAt'
export const EVENT_SORTS: EventSort[] = [
  'startingDate',
  'endingDate',
  'createdAt'
]

export class GetEventsDto extends QuerySearchParamsDto {
  public expired?: boolean
  public tense?: EventTense
  public type: EventType[] = []
  public communityId?: string
  public eventIds: string[] = []
  public ownerId?: string
  public winnerId?: string
  public sort?: EventSort

  public toURLSearchParams(): URLSearchParams {
    const params = super.toURLSearchParams()
    if (this.sort !== undefined) {
      params.append('sort', this.sort)
    }
    if (this.expired !== undefined) {
      params.append('expired', this.expired.toString())
    }
    if (this.tense !== undefined) {
      params.append('tense', this.tense)
    }
    if (this.type.length > 0) {
      params.append('type', this.type.join(','))
    }
    if (this.communityId !== undefined) {
      params.append('communityId', this.communityId)
    }
    if (this.eventIds.length > 0) {
      params.append('eventId', this.eventIds.join(','))
    }
    if (this.ownerId !== undefined) {
      params.append('ownerId', this.ownerId)
    }
    if (this.winnerId !== undefined) {
      params.append('winnerId', this.winnerId)
    }
    return params
  }
}

export interface CreateEventDto {
  eventImage: File
  name: string
  urlWebsite: string
  description: string
  type: EventType
  startingDate: Date
  endingDate: Date
  userId?: string
  communityId: string
  eventMetadata: EventMetadata
}

export function createEventDTOAsFormData(dto: CreateEventDto): FormData {
  const formData = new FormData()
  formData.append('eventImage', dto.eventImage)
  formData.append('name', dto.name)
  formData.append('urlWebsite', dto.urlWebsite)
  formData.append('description', dto.description)
  formData.append('type', dto.type)
  formData.append('startingDate', dto.startingDate.toISOString())
  formData.append('endingDate', dto.endingDate.toISOString())
  if (dto.userId !== undefined) {
    formData.append('userId', dto.userId)
  }
  if (dto.communityId !== undefined) {
    formData.append('communityId', dto.communityId)
  }
  if (dto.eventMetadata !== undefined) {
    formData.append('eventMetadata', JSON.stringify(dto.eventMetadata))
  }
  return formData
}
export interface UpdateEventDto {
  name?: string
  urlWebsite?: string
  description?: string
  startingDate?: Date
  endingDate?: Date
  eventMetadata?: EventMetadata
  userId?: string
}

export interface WinningPost {
  postId: string
  rank: number
}

export interface UpdateWinnersDto {
  winners: WinningPost[]
}
