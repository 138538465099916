import { Box, Button, Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import Select from 'react-select'
import { SwitchButton } from 'components/shared/index'

const useStyles = makeStyles({
  itemtitle: {
    color: '#E2E4E9',
    fontWeight: 900,
    fontSize: '26px',
    lineHeight: '31.47px',
    width: '100%'
  },
  description: {
    color: '#808893',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    marginTop: '18px'
  },
  subtitle: {
    fontWeight: 900,
    fontSize: '16px',
    lineHeight: '19.36px',
    color: '#E2E4E9'
  },
  matureSelect: {
    display: 'flex',
    marginTop: '30px'
  },
  matureSelectButton: {
    marginRight: '6px',
    backgroundColor: '#1A1E28',
    height: '50px',
    '&:hover': {
      backgroundColor: '#1A1E28'
    }
  },
  matureSelectButtonText: {
    color: '#6C7B93',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px'
  }
})

const options = [
  { value: 'English(US)', label: 'English(US)' },
  { value: 'French', label: 'French' },
  { value: 'Chinese(simplified)', label: 'Chinese(simplified)' }
]

const customStyles = {
  control: (base: any) => ({
    ...base,
    border: 'none',
    height: '40px',
    width: '192px',
    background: '#252A3A',
    marginTop: '30px'
  }),
  singleValue: (base: any) => ({
    ...base,
    color: '#6C7B93',
    fontSize: 14,
    fontWeight: 700
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none'
  }),
  menu: (base: any) => ({
    ...base,
    width: 192
  }),
  menuList: (base: any) => ({
    ...base,
    fontSize: 14
  })
}

const PreferSetting = (): JSX.Element => {
  const classes: any = useStyles()

  const [menu, selectMenu] = useState<string>('shows')

  return (
    <Box style={{ padding: '45px 0 0 0' }}>
      <Box className={classes.ite}>
        <Typography className={classes.itemtitle}>Preferences</Typography>
        <Typography className={classes.description}>
          Manage your account. In the profile settings, you will be able to change your personal
          information, connect with various other platforms and terminate your account
        </Typography>
        <Divider style={{ margin: '25px 0 35px 0', backgroundColor: '#252A3A' }} />
        <Box className={classes.language}>
          <Typography className={classes.subtitle}>LANGUAGE</Typography>

          <Typography className={classes.description} style={{ width: '688px' }}>
            Choose your prefered language. Your choice will determin how the UI and and the content
            of YouMeme will be displayed to you. Don’t see your language? Don’t worry, more are
            coming very soon!
          </Typography>
          <Select
            className={classes.languageButton}
            styles={customStyles}
            defaultValue={options[0]}
            options={options}
            isDisabled
          />
        </Box>

        <Divider style={{ margin: '35px 0', backgroundColor: '#252A3A' }} />

        <Box>
          <Typography className={classes.subtitle}>MATURE CONTENT (NSFW)</Typography>
          <Typography className={classes.description} style={{ width: '689px' }}>
            Some content might be a bit extreme and explicit for individuals that are under 18 years
            of age. Choose a setting and decide if you would like to view such content.
          </Typography>
          <Box className={classes.matureSelect}>
            <Button
              variant='contained'
              style={{
                backgroundColor: menu === 'show' ? '#884BFF' : ''
              }}
              className={classes.matureSelectButton}
              onClick={() => selectMenu('show')}
              disabled
            >
              <Typography
                className={classes.matureSelectButtonText}
                style={{
                  color: menu === 'show' ? '#FFFFFF' : ''
                }}
              >
                Show
              </Typography>
            </Button>
            <Button
              variant='contained'
              className={classes.matureSelectButton}
              onClick={() => selectMenu('dontshow')}
              style={{
                backgroundColor: menu === 'dontshow' ? '#884BFF' : ''
              }}
              disabled
            >
              <Typography
                className={classes.matureSelectButtonText}
                style={{
                  color: menu === 'dontshow' ? '#FFFFFF' : ''
                }}
              >
                Don’t Show
              </Typography>
            </Button>
            <Button
              variant='contained'
              className={classes.matureSelectButton}
              onClick={() => selectMenu('blur')}
              style={{
                backgroundColor: menu === 'blur' ? '#884BFF' : ''
              }}
              disabled
            >
              <Typography
                className={classes.matureSelectButtonText}
                style={{
                  color: menu === 'blur' ? '#FFFFFF' : ''
                }}
              >
                Blurred
              </Typography>
            </Button>
          </Box>
        </Box>

        <Divider style={{ margin: '35px 0', backgroundColor: '#252A3A' }} />

        <Box className={classes.dataShare}>
          <Typography className={classes.subtitle}>DATA SHARING</Typography>
          <Box style={{ display: 'flex' }}>
            <Typography className={classes.description}>
              Use data to improve our platform. This allows us to receive impactful information for
              analytical purposes. Your data is never going to be shared externally.
            </Typography>
            <SwitchButton
              width={46}
              height={25}
              thumb={20}
              transform={21}
              trackColor='#28303A'
              checkColor='rgba(112, 117, 255, 0.2);'
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default PreferSetting
