import {
  FormControl,
  InputLabel,
  InputBaseProps,
  InputLabelProps,
  TextFieldProps,
} from "@mui/material";
import TextField from "components/atoms/TextField";
import useFormTextFieldStyles from "./style";

interface FormTextFieldProps {
  labelProps: InputLabelProps;
  textFieldProps: TextFieldProps;
  errorProps?: {
    error: string;
    touched: boolean;
  };
}

const FormTextField = ({
  labelProps,
  textFieldProps,
  errorProps,
}: FormTextFieldProps) => {
  const classes = useFormTextFieldStyles();

  const { children, required } = labelProps;
  const { error, touched } = errorProps ?? { error: "", touched: false };

  const showError: boolean = touched && Boolean(error);

  return (
    <FormControl variant='standard'>
      <InputLabel {...labelProps} error={showError}>
        {children}{" "}
        {!required && (
          <span className={classes.inputLabelHint}>(optional)</span>
        )}
      </InputLabel>
      <TextField {...textFieldProps} error={showError} helperText={error} />
    </FormControl>
  );
};

export default FormTextField;
