import { useAuth } from 'context/auth/authContext'
import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useMemo, useState } from 'react'
import { DefaultYMCA } from 'ymca/ymca'
import { PublicUser } from 'ymca/models/user.model'
import colors from 'theme/dark/colors'
import Typography from "components/atoms/Typography";
import RightSidebarMemers from 'components/memes/RightSidebarMemers'

const useStyles = makeStyles(() => ({
  mainBox: {
    opacity: 1,
    boxSizing: 'border-box',
    '& > *': {
      boxSizing: 'border-box'
    },

    borderRadius: '8px',
    background: colors.secondary900,
    padding: '1rem'
  },
  textContainer: {
    color: '#E9F0F9',
    'font-family': 'Inter',
    'font-size': '1.125rem',
    'font-style': 'normal',
    'font-weight': 800,
    'line-height': 'normal',
    'letter-spacing': '-0.0625rem'
  },
  dataContainer: {
    borderRadius: '0 0 10px 10px',
    // padding: '0 1.5rem',
    paddingBottom: 0,

    display: 'flex',
    flexDirection: 'column',

    gap: '0.5rem'
  },
  seeMoreBtn: {
    borderRadius: '6.25rem',
    border: '1.5px solid #1C263B',
    background: colors.secondary900,
    boxShadow: ' 2px 0px 2px 0px #1C263B',

    padding: '.25rem 0',

    // Typography
    color: '#E9F0F9',
    textAlign: 'center',
    'font-family': 'Inter',
    'font-size': '0.875rem',
    'font-style': 'normal',
    'font-weight': 700,
    'line-height': '1.5rem',
    'letter-spacing': '-0.01875rem'
  },
  button: {
    color: '#E2E4E9',
    fontSize: 14,
    fontWeight: 700,
    marginTop: '0rem',
    background: colors.darkBlue50,
    width: '100%',
    height: 46,
    borderRadius: 100,
    '&:hover': {
      background: colors.darkBlueActive
    }
  }
}))

const DiscoverMemer = (): JSX.Element => {
  const classes = useStyles()

  const { isAuthenticated } = useAuth()

  const step: number = 5

  const [limit, setLimit] = useState(5)
  const [suggest, setSuggest] = useState<PublicUser[]>([])
  const [fetchStatus, setFetchStatus] = useState<number>(0)

  const isMore: boolean | undefined = useMemo(() => {
    if (suggest) {
      return limit < suggest.length
    }
  }, [suggest, limit])

  const fetchSuggests = async (): Promise<number> => {
    return new Promise(async (resolve, reject) => {
      const data = await DefaultYMCA.userService.getFollowRecommendations(15, 0)
      if (data) {
        setSuggest(data.data)
        resolve(1)
      } else {
        reject(0)
      }
    })
  }

  useEffect(() => {
    if (isAuthenticated === true) {
      Promise.all([fetchSuggests()]).then((values) => {
        if (values[0] === 1) {
          setFetchStatus(1)
        }
      })
    }
  }, [isAuthenticated])

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.dataContainer}>
        <Typography className={classes.textContainer} fontWeight={800}>
          Discover Memers
        </Typography>
        {fetchStatus === 1
          ? suggest
              ?.slice(0, limit)
              .map((item) => (
                <RightSidebarMemers
                  key={item.id}
                  data={item}
                  data-test-memer={item.id}
                />
              ))
          : null}

        <Button
          className={classes.seeMoreBtn}
          onClick={() => {
            setLimit((prevLimit) => (isMore === true ? prevLimit + step : step))
          }}
        >
          Show {isMore === true ? 'More' : 'Less'}
        </Button>
      </Box>
    </Box>
  )
}

export default DiscoverMemer
