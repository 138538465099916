import { useWeb3React } from "@web3-react/core";
import { DefaultYMCA } from "ymca/ymca"

export function useLensService() {
  const context = useWeb3React()
  const { library } = context

  const isAuthenticatedOnLens = async () => {
    let isAuthenticatedOnLens: boolean
    try {
      isAuthenticatedOnLens = await DefaultYMCA.lensService.isAuthenticated()
    } catch (error) {
      isAuthenticatedOnLens = false
    }
    return isAuthenticatedOnLens
  }

  const isLensProfileManagerEnabled = async () => {
    let isLensProfileManagerEnabled: boolean
    try {
      isLensProfileManagerEnabled =
        await DefaultYMCA.lensService.isSelfLensProfileManagerEnabled()
    } catch (error) {
      isLensProfileManagerEnabled = false
    }
    return isLensProfileManagerEnabled
  }

  const toggleIsLensProfileManagerEnabled = async (
    state: boolean
  ): Promise<boolean> => {
    try {
      const data = await DefaultYMCA.lensService.setSelfLensProfileManager(
        state
      )
      const signature = await library
        .getSigner()
        ._signTypedData(
          data.typedData.domain,
          data.typedData.types,
          data.typedData.value
        )
      const broadcastResult = await DefaultYMCA.lensService.broadcastResult({
        id: data.id,
        signature: signature
      })
      if (broadcastResult && broadcastResult.txHash) {
        let receipt = await library.waitForTransaction(broadcastResult.txHash)
        if (receipt) return true
        else return false
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  return {
    isAuthenticatedOnLens,
    isLensProfileManagerEnabled,
    toggleIsLensProfileManagerEnabled
  }
}
