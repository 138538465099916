import { makeStyles } from "@material-ui/core/styles";
import colors from "theme/dark/colors";

const useCommunitiesItemCardStyles = makeStyles({
  card: {
    padding: '1rem',
    display: 'flex',
    gap: '1.25rem',
    flexDirection: 'column',
    borderRadius: '.75rem',
    backgroundColor: colors.secondary900
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
    padding: '0 .5rem'
  },
  cardBodyName: {
    color: '#E9F0F9',
    fontFamily: 'Inter',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: '1.1875rem',
    letterSpacing: '-0.0625rem'
  },
  cardBodyDescription: {
    color: '#8A98B4',
    fontFamily: 'Inter',
    fontSize: '0.8125rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.125rem',
    letterSpacing: '-0.00813rem',

    'word-break': 'break-word',

    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    maxWidth: '20rem'
  },
  cardBodyMembersSection: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem'
  },
  cardBodyMemberAvatar: {
    width: '2rem',
    height: '2rem',
    fontSize: '1rem',
    '&.MuiAvatarGroup-avatar': {
      border: `2px solid ${colors.secondary900}`
    }
  },
  cardBodyMembersCount: {
    color: '#8A98B4',
    fontFamily: 'Inter',
    fontSize: '0.92188rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '-0.01875rem'
  },
  cardFooter: {
    padding: '0 .5rem'
  },
  cardFooterJoinButton: {
    borderRadius: '0.625rem',
    background: 'linear-gradient(65deg, #625FFF 0%, #A55FFF 100%)',
    boxShadow: '0px 4px 8px 0px rgba(14, 20, 34, 0.20)',
    width: '100%',

    display: 'flex',
    alignItems: 'center',

    fontFamily: 'Inter',
    fontSize: '0.92188rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '1.1875rem',
    letterSpacing: '-0.04375rem',
    height: '2.875rem'
  },
  cardFooteVisitCommunityLink: {
    borderRadius: '0.625rem',
    background: colors.secondary500,
    border: `1px solid ${colors.primary500}`,
    boxShadow: '0px 4px 8px 0px rgba(14, 20, 34, 0.20)',
    width: '100%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    color: colors.textPrimary,
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '0.92188rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '1.1875rem',
    letterSpacing: '-0.04375rem',
    height: '2.875rem'
  },
  cardFooterManageButton: {
    borderRadius: '0.625rem',
    background: '#202A41',
    width: '100%',
    fontFamily: 'Inter',
    fontSize: '0.92188rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '1.1875rem',
    letterSpacing: '-0.04375rem',
    height: '2.875rem',

    '& .MuiButton-label': {
      display: 'flex',
      gap: '.5rem'
    }
  },

  cardHeader: {},
  cardHeaderBanner: {
    height: '6.25rem',
    backgroundColor: colors.secondary900,

    '& img': {
      borderRadius: '0.75rem'
    }
  },
  cardHeaderAvatar: {
    width: '3.125rem',
    height: '3.125rem',
    border: `.3rem solid ${colors.secondary900}`,
    marginTop: '-2rem',
    marginLeft: '.5rem',
    marginBottom: '-.5rem'
  },

  popoverContainer: {
    padding: '1.5rem',
    backgroundColor: colors.backgroundPrimary
  }
})

export default useCommunitiesItemCardStyles;
