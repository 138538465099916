import {
  CommunityUserRoleDTO,
  CreateCommunityDTO,
  GetCommunitiesDTO,
  GetCommunityDTO,
  GetJoinedCommunitiesDTO,
  ListCommunityMembersDTO,
  UpdateCommunityDTO
} from 'ymca/dtos/community.dto'
import { BaseService, CommonArgs } from './base.service'
import { SelfService } from './self.service'
import { Community } from 'ymca/models/community.model'
import {
  GenericQueryParamsDto,
  PaginatedGenerator,
  PaginatedResponse
} from 'ymca/dtos/common.dto'
import { APIResponse, ParamsCapableToPlainJSON } from 'ymca/jsonFetcher'
import { PublicUser } from 'ymca/models/user.model'
import removeEmptyObjectValues from 'utils/removeEmptyObjectValue'

export class CommunityService extends BaseService {
  protected selfService: SelfService

  constructor(common: CommonArgs, selfService: SelfService) {
    super(common)
    this.selfService = selfService
  }
  // TODO add methods here

  public async createCommunity(
    dto: CreateCommunityDTO
  ): Promise<APIResponse<Community>> {
    const { socialLinks } = dto
    // clean up social links
    removeEmptyObjectValues(socialLinks as Record<string, string>)

    const res = await this.common.jsonFetcher.fetchJSON<Community>(
      'POST',
      '/api/community',
      undefined,
      dto
    )
    return res
  }

  public getCommunities(dto: GetCommunitiesDTO): PaginatedGenerator<Community> {
    const queryArgs = ParamsCapableToPlainJSON(dto)
    const res = this.common.jsonFetcher.fetchPaginatedJSON<Community>(
      '/api/community',
      queryArgs
    )
    return res
  }

  public async getCommunity(
    dto: GetCommunityDTO
  ): Promise<APIResponse<PaginatedResponse<Community>>> {
    const res = await this.common.jsonFetcher.fetchJSON<
      PaginatedResponse<Community>
    >('GET', '/api/community', dto)
    return res
  }

  public async updateCommunity(
    id: string,
    dto: UpdateCommunityDTO
  ): Promise<APIResponse<Community>> {
    const { socialLinks } = dto
    // clean up social links
    removeEmptyObjectValues(socialLinks as Record<string, string>)
    const res = await this.common.jsonFetcher.fetchJSON<Community>(
      'PATCH',
      `/api/community/${id}`,
      undefined,
      dto
    )
    return res
  }

  public async deleteCommunity(id: string): Promise<any> {
    const res = await this.common.jsonFetcher.fetchJSON<void>(
      'DELETE',
      `/api/community/${id}`
    )
    if (res.isSuccess) {
      return res
    }
    console.error('Failed to delete community')
    console.error(res)
    throw new Error('Failed to delete community')
  }

  public listCommunityMemberships(
    dto: ListCommunityMembersDTO
  ): PaginatedGenerator<PublicUser> {
    const queryArgs = ParamsCapableToPlainJSON(dto)
    const res = this.common.jsonFetcher.fetchPaginatedJSON<PublicUser>(
      '/api/community/members',
      queryArgs
    )
    return res
  }

  /**
   * @Depreciated don't use this anymore
   */
  public listJoinedCommunities(
    dto: GetJoinedCommunitiesDTO
  ): PaginatedGenerator<Community> {
    const queryArgs = ParamsCapableToPlainJSON(dto)
    const res = this.common.jsonFetcher.fetchPaginatedJSON<Community>(
      '/api/community/joined',
      queryArgs
    )
    return res
  }

  public async joinCommunity(id: string): Promise<any> {
    const res = await this.common.jsonFetcher.fetchJSON<any>(
      'POST',
      `/api/community/join`,
      new GenericQueryParamsDto({ id })
    )
    return res
  }

  public async leaveCommunity(id: string): Promise<any> {
    const res = await this.common.jsonFetcher.fetchJSON<void>(
      'POST',
      `/api/community/leave`,
      new GenericQueryParamsDto({ id })
    )
    return res
  }

  public async updateCommunityMemberRole(
    dto: CommunityUserRoleDTO
  ): Promise<any> {
    const res = await this.common.jsonFetcher.fetchJSON<void>(
      'POST',
      `/api/community/update-user-role`,
      undefined,
      dto
    )
    return res
  }
}
