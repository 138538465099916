import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import toast from "react-hot-toast";

import useShow, { IUseShow } from "hooks/useShow";
import { useAuth } from "context/auth/authContext";
import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
} from "services/localStorageService";
import { UNPUBLISHED_MEME_KEY } from "constants/variables";
import { useFeedContext } from "context/feed/feedContext";
import { dataURLtoFile } from "meme-generator/ImageGifEdit/util";
import { DefaultYMCA } from "ymca/ymca";
import { APIResponse } from "ymca/jsonFetcher";
import { Post, PostType } from 'ymca/models/post.model'

interface AppContextInterface {
  contest: IUseShow;
  setPendingData: (newData: Record<string, any>) => void;
}

const AppContext = createContext<AppContextInterface>({
  setPendingData: (newData: Record<string, any>) => {},
  contest: {
    hide: () => {},
    show: () => {},
    toggle: () => {},
    isShowing: false,
  },
});

const { Provider, Consumer } = AppContext;

interface AppProviderProps {
  children: ReactNode;
}

const AppProvider = ({ children }: AppProviderProps) => {
  const contestProps = useShow();
  const [pendingData, setPendingData] = useState<Record<string, any> | null>(
    null
  );
  const { isAuthenticated, userInfo } = useAuth();
  const { source: feed } = useFeedContext();

  const publishMeme = async (pendingPublicationData: any) => {
    const {
      eventId,
      category,
      overlay,
      rendered,
      thumbnail,
      tags,
      title,
      type,
      description,
      contentType,
    } = pendingPublicationData;
    let { source } = pendingPublicationData;
    const data = new FormData();
    data.append("title", title);
    data.append("isPublished", "true");
    data.append("tags", tags);
    data.append("sourceImageFormat", "png");
    if (typeof source !== "string") {
      let toStringPendingPublicationData = source;
      source = JSON.stringify(toStringPendingPublicationData);
    }
    source && data.append("source", dataURLtoFile(source));
    overlay && data.append("overlay", dataURLtoFile(overlay));
    rendered && data.append("rendered", dataURLtoFile(rendered));
    thumbnail && data.append("thumbnail", dataURLtoFile(thumbnail));
    eventId
      ? data.append("eventId", eventId)
      : data.append("category", category);
    eventId && data.append("type", type);
    data.append("description", description);
    contentType && data.append("contentType", contentType);
    try {
      if (feed === "youmeme") {
        toast
          .promise(
            DefaultYMCA.postService.createPostWFormData(data),
            {
              loading: "Publishing your meme...",
              success: () =>
                eventId
                  ? `Thanks for your submission You are in! See more memes.`
                  : `Published`,
              error:
                "Looks like there was an issue while publishing your meme :(",
            },
            {
              style: {
                minWidth: "280px",
              },
            }
          )
          .then((res: APIResponse<Post>) => {
            if (res.isCreated) {
              contestProps.show();
            } else {
              console.error("Status: " + res.status);
            }
          })
          .catch((err: any) => {
            console.error(err);
          });
      } else if (feed === "lens") {
        toast
          .promise(
            DefaultYMCA.lensService.createPostWithBase64Data(
              pendingPublicationData.type as PostType,
              pendingPublicationData.title,
              '',
              '',
              JSON.parse(pendingPublicationData.tags),
              pendingPublicationData.rendered,
              ''
            ),
            {
              loading: 'Publishing your meme on Lens...',
              success: () => `Published`,
              error:
                'Looks like there was an issue while publishing your meme :('
            },
            {
              style: {
                minWidth: '280px'
              }
            }
          )
          .then((res: string) => {
            if (res) {
              contestProps.show()
            }
          })
          .catch((err: any) => {
            console.error(err)
          })
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      console.log("in isAuthenticated");
      let pendingPublicationData = getItemFromLocalStorage(
        UNPUBLISHED_MEME_KEY,
        true
      ) as any;
      if (!pendingPublicationData) {
        pendingPublicationData = pendingData;
      }
      if (pendingPublicationData !== undefined) {
        const expiry = Number(pendingPublicationData?.expiry);
        if (feed === "youmeme") {
          const userEmail = pendingPublicationData?.email;

          console.log("userEmail", userEmail);
          console.log("userInfo?.userData.email", userInfo?.userData.email);

          if (userEmail !== "") {
            removeItemFromLocalStorage(UNPUBLISHED_MEME_KEY);
          }
          if (
            !(userEmail !== userInfo?.userData.email || expiry < Date.now())
          ) {
            publishMeme(pendingPublicationData);
          }
        } else if (feed === "lens") {
          const userEthAddress = pendingPublicationData?.ethAddress;
          if (userEthAddress !== "") {
            removeItemFromLocalStorage(UNPUBLISHED_MEME_KEY);
          }
          if (
            !(
              userEthAddress !== userInfo?.userData.ethAddress ||
              expiry < Date.now()
            )
          ) {
            publishMeme(pendingPublicationData);
          }
        }
      }
    }
  }, [isAuthenticated, pendingData]);

  return (
    <Provider
      value={{
        contest: contestProps,
        setPendingData,
      }}
    >
      {children}
    </Provider>
  );
};

export const useAppContext = () => useContext(AppContext);

export default AppProvider;

export { Consumer };
