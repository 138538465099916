import {
  Alert,
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
  Typography,
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Icon from "components/atoms/Icons";
import useMenu from "hooks/useMenu";
import useCommunityKebabMenuDropdown from "./style";
import useDrawer from "hooks/useDrawer";
import useModal from "hooks/useModal";
import CommunityDeleteModal from "../CommunityDeleteModal";
import useSnackbar from "hooks/useSnackbar";
import { Community } from "ymca/models/community.model";
import { useNavigate } from "react-router-dom";

interface CommunityKebabMenuDropdownProps {
  community: Community;
}

const CommunityKebabMenuDropdown = ({
  community,
}: CommunityKebabMenuDropdownProps) => {
  const classes = useCommunityKebabMenuDropdown();
  const navigate = useNavigate();
  const windowLocation = window.location;
  const { origin, pathname } = windowLocation;

  const {
    isOpen: isKebabMenuOpen,
    open: openKebabMenu,
    anchorEl: kebabMenuAnchorEl,
    close: closeKebabMenu,
  } = useMenu();

  const {
    isOpen: isDrawerOpen,
    open: openDrawer,
    close: closeDrawer,
  } = useDrawer();

  const {
    isOpen: isDeleteOpen,
    open: openDelete,
    close: closeDelete,
  } = useModal();

  const {
    isOpen: isCpSnkbarOpen,
    close: closeCpSnkbar,
    open: openCpSnkbar,
  } = useSnackbar();

  const handleMenuOpenDropdown = (e: any) => {
    openKebabMenu(e);
    openDrawer(e);
  };
  const handleMenuCloseDropdwon = (e: any) => {
    closeKebabMenu();
    closeDrawer();
  };

  const handleDeleteMenuClick = (e: any) => {
    openDelete();
  };

  const handleCommunityLinkCp = (messageCopied: string) => {
    console.log("messageCopied", messageCopied);
    openCpSnkbar();
  };

  const navigateToMembers = () => {
    navigate("members");
  };

  const DropDownList = [
    // {
    //   title: "Members List",
    //   icon: (
    //     <Icon icon='community-members-list' color='#8A98B4' fontSize='1.5rem' />
    //   ),
    //   onClick: navigateToMembers,
    // },
    {
      title: "Make Announcement",
      icon: (
        <Icon
          icon='community-make-announcement'
          color='#8A98B4'
          fontSize='1.5rem'
        />
      ),
      disabled: true,
      onClick: () => {},
    },
    {
      title: "Delete Community",
      disabled: true,
      icon: <Icon icon='community-delete' color='#8A98B4' fontSize='1.5rem' />,
      onClick: handleDeleteMenuClick,
    },
  ];

  return (
    <>
      <Button
        className={classes.KebabMenuBtn}
        onClick={handleMenuOpenDropdown}
        aria-controls={
          isKebabMenuOpen ? "manage-community-kebab-menu" : undefined
        }
        aria-haspopup='true'
        aria-expanded={isKebabMenuOpen ? "true" : undefined}
        id='manage-community-kebab-menu'
      >
        <Icon icon='kebab-menu' />
      </Button>

      <Menu
        anchorEl={kebabMenuAnchorEl}
        id='manage-community-kebab-menu'
        aria-labelledby='manage-community-kebab-menu'
        open={isKebabMenuOpen}
        onClose={handleMenuCloseDropdwon}
        className={classes.kebabMenuMenu}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem
          className={`${classes.kebabMenuMenuCopy} ${classes.kebabMenuMenuBorderBottom}`}
        >
          <Typography className={classes.kebabMenuMenuCopyTitle}>
            Copy Community Link
          </Typography>
          <Box className={classes.kebabMenuMainMenuItemContent}>
            <Typography className={classes.kebabMenuMenuCopyLinkMessage}>
              {pathname}
            </Typography>
            <CopyToClipboard
              text={`${origin}${pathname}`}
              onCopy={() => handleCommunityLinkCp(`${origin}${pathname}`)}
            >
              <Icon icon='copy-link' />
            </CopyToClipboard>
          </Box>
        </MenuItem>
        {/* <MenuItem
          className={`${classes.kebabMenuSecondaryMenuItem}`}
          href='members'
          onClick={navigateToMembers}
        >
          <Icon icon='community-members-list' color='#8A98B4' />

          <Typography className={classes.kebabMenuSecondaryMenuText}>
            Members List
          </Typography>
        </MenuItem> */}
        <MenuItem className={`${classes.kebabMenuMainMenuItem}`} disabled>
          <Box className={`${classes.kebabMenuMainMenuItemContent}`}>
            <Icon icon='community-make-announcement' />

            <Typography className={classes.kebabMenuMainMenuText}>
              Make Announcement
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          className={`${classes.kebabMenuSecondaryMenuItem}`}
          onClick={handleDeleteMenuClick}
          disabled
        >
          <Icon icon='community-delete' color='#8A98B4' />

          <Typography className={classes.kebabMenuSecondaryMenuText}>
            Delete Community
          </Typography>
        </MenuItem>
      </Menu>
      <Drawer
        anchor='bottom'
        open={isDrawerOpen}
        onClose={handleMenuCloseDropdwon}
        className={classes.kebabMenuDropdownDrawer}
      >
        <Typography className={classes.kebabMenuDropdownDrawerTitle}>
          Community options
        </Typography>
        <List>
          {DropDownList.map((menu) => (
            <ListItem
              key={menu.title}
              disablePadding
              className={classes.kebabMenuDropdownDrawerListItemWrapper}
              onClick={menu.onClick}
            >
              <ListItemButton disabled={menu.disabled}>
                <ListItemText
                  primary={menu.title}
                  className={classes.kebabMenuDropdownDrawerListItemText}
                />
                <ListItemIcon>{menu.icon}</ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      {community.id && (
        <CommunityDeleteModal
          isOpen={isDeleteOpen}
          close={closeDelete}
          avatarSrc={community.avatar?.urlOptimised500x500}
          communityId={community.id}
        />
      )}
      <Snackbar
        open={isCpSnkbarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={closeCpSnkbar}
      >
        <Alert>Link Copied</Alert>
      </Snackbar>
    </>
  );
};

export default CommunityKebabMenuDropdown;
