import { styled } from "@mui/material";
import Icons from "./icons";

interface IconWrapperProps {
  fontSize?: any;
  color?: string;
  disabled?: boolean;
  variant?: "filled" | "outlined";
}

const IconWrapper = styled("div", { name: "IconWrapper" })<IconWrapperProps>(
  ({ fontSize = "1rem", color = "inherit", disabled, theme, variant }) => ({
    fontSize,
    color: disabled ? theme.palette.action.disabled : color,
    width: "1em",
    height: "1em",
    display: "flex",
    alignItems: "center",

    "& > svg": {
      width: "100%",
      height: "100%",
      fill: variant === "outlined" ? "none" : "currentcolor",
      stroke: variant === "outlined" ? "currentcolor" : "none",
      "pointer-events": "none",
    },
  })
);

export type IconType =
  | 'arrow-left'
  | 'arrow-right'
  | 'bookmark'
  | 'categories'
  | 'comment'
  | 'communities'
  | 'communities-secondary'
  | 'community-delete'
  | 'community-make-announcement'
  | 'community-members-list'
  | 'competition'
  | 'copy-link'
  | 'events-no-reward-current'
  | 'menu-dropdown-up'
  | 'menu-dropdown-down'
  | 'emoji-crying'
  | 'give-feedback'
  | 'home'
  | 'illustration-gift-box'
  | 'illustration-upload-file'
  | 'kebab-menu'
  | 'logout'
  | 'manage-community'
  | 'notification'
  | 'plus'
  | 'privateLock'
  | 'publicLock'
  | 'reaction-like'
  | 'reaction-dislike'
  | 'referral-reward'
  | 'search'
  | 'search-footer'
  | 'setting'
  | 'share'
  | 'sidebar-additional'
  | 'social-facebook'
  | 'social-instagram'
  | 'social-twitter'
  | 'social-telegram'
  | 'social-website'
  | 'social-discord'
  | 'social-youtube'
  | 'swap-to-lens'
  | 'trending'
  | 'users'
  | 'verified'

export interface IconProps extends IconWrapperProps {
  icon: IconType;
}

const Icon = ({ icon, ...props }: IconProps): JSX.Element => {
  const iconSvg = Icons[icon];
  return <IconWrapper {...props}>{iconSvg}</IconWrapper>;
};

export default Icon;
