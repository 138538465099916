/**
 * Takes a file and resizes it (using canvas) to the given width and height.
 * Optionally keeps the aspect ratio by fitting the image into the given width and height.
 * @param file
 * @param width
 * @param height
 * @param keepRatio
 */
export async function resizeFileToDataUri(
    file: File,
    width: number,
    height: number,
    keepRatio: boolean = true,
): Promise<string> {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
            const image = new Image();
            image.onload = () => {
                const canvas = document.createElement('canvas');
                let scale = 1;
                if (keepRatio) {
                    // Scale by the smaller side.
                    scale = Math.min(width / image.width, height / image.height);
                }
                const scaledWidth = image.width * scale;
                const scaledHeight = image.height * scale;
                canvas.width = scaledWidth;
                canvas.height = scaledHeight;
                const ctx = canvas.getContext('2d')!;
                ctx.drawImage(image, 0, 0, scaledWidth, scaledHeight);
                resolve(canvas.toDataURL('image/jpeg', 0.8));
            };
            image.onerror = reject;
            image.src = fileReader.result as string;
        };
        fileReader.onerror = reject;
        fileReader.readAsDataURL(file);
    });
}
