import { Box, Fab } from "@mui/material";
import useCommunitiesGridContainerStyles from "./style";
import AtomLoading from "components/atoms/AtomLoading";
import { Community } from "ymca/models/community.model";
import CommunityItemCard from "../CommunityItemCard";
import useInfiniteScroll from "hooks/useInfiniteScroll/useInfiniteScroll";
import { EmptyCommunities } from "components/layout/CommunitiesWrapper";
import { useAuth } from "context/auth/authContext";
import useModal from "hooks/useModal";
import Icon from "components/atoms/Icons";
import CreateCommunityModal from "../CreateCommunityModal";
import { canCreateCommunity } from "utils/community";

interface CommunitiesProps {
  communities: any[];
  isLoading?: boolean;
  joinCommunity?: boolean;
  manageCommunity?: boolean;
  hasNextPage?: boolean;
  fetchNextPage?: VoidFunction;
  isFetchingNextPage?: boolean;
}

const CommunitiesGridContainer = ({
  communities,
  joinCommunity,
  manageCommunity,
  isLoading,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
}: CommunitiesProps) => {
  const classes = useCommunitiesGridContainerStyles();
  const { isAuthenticated, userInfo } = useAuth();

  // create community modal
  const { isOpen, close, open } = useModal();

  const [infiniteRef] = useInfiniteScroll({
    loading: isFetchingNextPage!,
    hasNextPage: hasNextPage!,
    onLoadMore: () => fetchNextPage?.(),
    rootMargin: "50px",
  });

  const showCreateCommunityBtn = canCreateCommunity(userInfo?.userData?.role);

  return (
    <>
      {isLoading ? (
        <Box display='flex' justifyContent='center'>
          <AtomLoading />
        </Box>
      ) : (
        <>
          {!communities || communities.length === 0 ? (
            <Box display='flex' justifyContent='center'>
              <EmptyCommunities />
            </Box>
          ) : (
            <Box component='section' className={classes.container}>
              {communities?.map((community: Community, i) => {
                return (
                  <CommunityItemCard
                    key={community.id}
                    community={community}
                    joinCommunity={joinCommunity}
                    manageCommunity={manageCommunity}
                  />
                );
              })}
            </Box>
          )}
        </>
      )}

      {!isLoading && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
        >
          {hasNextPage && !isFetchingNextPage && <Box ref={infiniteRef} />}
          {isFetchingNextPage && (
            <Box display='flex' justifyContent='center'>
              <AtomLoading />
            </Box>
          )}
        </Box>
      )}
      {isAuthenticated && showCreateCommunityBtn && (
        <Box
          style={{ position: "relative" }}
          display='flex'
          justifyContent='flex-end'
        >
          <Fab
            className={classes.pageTitleCreateFabBtn}
            size='large'
            color='primary'
            aria-label='create-communities'
            onClick={open}
          >
            <Icon icon='plus' fontSize='2.5rem' />
          </Fab>
        </Box>
      )}
      <CreateCommunityModal isOpen={isOpen} close={close} />
    </>
  );
};

export default CommunitiesGridContainer;
