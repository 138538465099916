import { CategoryService } from './services/category.service'
import { CommentService } from './services/comment.service'
import { EventService } from './services/event.service'
import { FollowService } from './services/follow.service'
import { ImageService } from './services/image.service'
import { SelfieSegmentationService } from '../services/ai'
import { NotificationService } from './services/notification.service'
import { PostService } from './services/post.service'
import { ReactionService } from './services/reaction.service'
import { UserService } from './services/user.service'
import { HTTPMethod, isBodyAllowedMethod, URLSearchParamsCapable, JSONFetcher, APIResponse, isMethodWithResponseBody } from './jsonFetcher'
import { YMCAConfig } from './ymcaConfig'
import { KVStore } from './storage/kvStore'
import { DefaultLocalStorage, DefaultStorage } from './storage/defaultKVStore'
import { SelfService } from './services/self.service'
import { BookmarkService } from './services/bookmark.service'
import { getFileFromUrl } from './ymcaHelpers'
import { LensService } from './services/lens.service'
import { MemegenService } from './services/memegen.service'
import { MimeType } from './models/image.model'
import { PubSub } from './storage/pubsub'
import { WebStorageKVStore } from './storage/webStorageKVStore'
import { CommonArgs } from './services/base.service'
import { MemeTemplateService } from './services/meme-template.service'
import { InquiryService } from './services/inquiry.service'
import { GenericPaginatedSearchParamsDto, PaginatedResponse } from './dtos/common.dto'
import { StickerService } from './services/sticker.service'
import { LensTombstoneService } from './services/lens-tombstone.service'
import { CommunityService } from './services/community.service'
import { FaceswapService } from './services/faceswap.service';
import {ThrottleService} from './services/throttle.service';
import { WalletService } from './services/wallet.service';
import { TwitterService } from 'ymca/services/twitter.service'

export class YMCA implements JSONFetcher {
  public config: YMCAConfig = new YMCAConfig()
  public kvStore: KVStore = new WebStorageKVStore(
    DefaultStorage,
    process.env.REACT_APP_DISABLE_YMCA_CACHE === 'true'
  )
  public localStorage: Storage = DefaultLocalStorage
  public pubsub = new PubSub()
  public bookmarkService: BookmarkService
  public categoryService: CategoryService
  public commentService: CommentService
  public communityService: CommunityService
  public eventService: EventService
  public faceswapService: FaceswapService
  public followService: FollowService
  public imageService: ImageService
  public inquiryService: InquiryService
  public selfieSegmentationService: SelfieSegmentationService
  public lensService: LensService
  public lensTombstoneService: LensTombstoneService
  public memegenService: MemegenService
  public notificationService: NotificationService
  public postService: PostService
  public reactionService: ReactionService
  public selfService: SelfService
  public memeTemplateService: MemeTemplateService
  public stickerService: StickerService
  public userService: UserService
  public throttleService: ThrottleService
  public walletService: WalletService
  public twitterService: TwitterService

  public constructor(config?: YMCAConfig, kvStore?: KVStore) {
    if (config !== undefined && config !== null) {
      this.config = config
    }
    if (kvStore !== undefined && kvStore !== null) {
      this.kvStore = kvStore
    }
    const args: CommonArgs = {
      bannedLensUsers: new Set<string>(),
      bannedLensPosts: new Set<string>(),
      jsonFetcher: this,
      localStorage: this.localStorage,
      kvStore: this.kvStore,
      pubsub: this.pubsub,
      config: this.config
    }
    this.walletService = new WalletService(args)
    this.inquiryService = new InquiryService(args)
    this.imageService = new ImageService(args)
    this.selfieSegmentationService = new SelfieSegmentationService()
    this.stickerService = new StickerService(args)
    this.memeTemplateService = new MemeTemplateService(args)
    this.memegenService = new MemegenService(args)
    this.throttleService = new ThrottleService(args)
    this.selfService = new SelfService(args)
    this.lensTombstoneService = new LensTombstoneService(args)
    this.lensService = new LensService(
      args,
      this.selfService,
      this.lensTombstoneService
    )
    this.notificationService = new NotificationService(args, this.selfService)
    this.followService = new FollowService(args, this.selfService)
    this.userService = new UserService(args, this.selfService)
    this.categoryService = new CategoryService(args)
    this.communityService = new CommunityService(args, this.selfService)
    this.eventService = new EventService(args)
    this.faceswapService = new FaceswapService(args)
    this.bookmarkService = new BookmarkService(args, this.selfService)
    this.reactionService = new ReactionService(
      args,
      this.selfService,
      this.lensService
    )
    this.commentService = new CommentService(
      args,
      this.selfService,
      this.lensService
    )
    this.postService = new PostService(
      args,
      this.selfService,
      this.categoryService,
      this.lensService,
      this.lensTombstoneService
    )
    this.twitterService = new TwitterService(args);
  }

  public getFileFromUrl = getFileFromUrl

  // This wraps the business logic to correctly fetch the bearer token
  public async getBearerToken(): Promise<string> {
    const token = await this.selfService.getBearerToken()
    return token
  }

  protected getRequestURL(
    path: string,
    query?: URLSearchParamsCapable
  ): string {
    let url = `${this.config.apiUrl}${path}`
    if (query === undefined || query === null) {
      return url
    }
    const params = query.toURLSearchParams()
    const paramsString = params.toString()
    if (paramsString === '') {
      return url
    }
    url += `?${paramsString}`
    return url
  }

  public async fetch(
    method: HTTPMethod,
    path: string,
    query?: URLSearchParamsCapable,
    body: any | FormData | File = {},
    contentType: MimeType = 'application/json',
    skipAuth: boolean = false,
    headers?: Record<string, string>,
  ): Promise<Response> {
    // Get the bearer token first
    const token = skipAuth ? '' : await this.getBearerToken()
    const url = this.getRequestURL(path, query)

    // if (body instanceof File) contentType = 'multipart/form-data'
    // Default options are marked with *
    const allHeaders = {
      ...(!(body instanceof FormData) && { 'Content-Type': contentType }),
      // If the token isn't available, we still try the request anyhow
      // This ensures that public APIs are still accessible
      ...(token.length > 0 && { Authorization: `Bearer ${token}` }),
      'X-YMCA-Client': 'frontend-ymca',
      ...(headers ?? {})
    }
    if (body instanceof FormData) delete allHeaders['Content-Type']
    const computedBody = isBodyAllowedMethod(method)
      ? contentType === 'application/json'
        ? JSON.stringify(body)
        : body
      : null
    const response = await fetch(url, {
      method, // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: allHeaders,
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: computedBody // body data type must match "Content-Type" header
    })
    return response
  }

  /**
   * fetchJSON is a wrapper around the fetch API that adds the bearer token to
   * the request and parses the response body as JSON
   * @param method HTTP method
   * @param path API path
   * @param query query parameters
   * @param body request body - either as JSON or as FormData
   * @param contentType defaults to `application/json`
   * @param skipAuth skips the authorization header
   * @returns T
   */
  public async fetchJSON<T>(
    method: HTTPMethod,
    path: string,
    query?: URLSearchParamsCapable,
    body: any | FormData | File = {},
    contentType: MimeType = 'application/json',
    skipAuth: boolean = false,
    headers?: Record<string, string>,
  ): Promise<APIResponse<T>> {
    const response = await this.fetch(
      method,
      path,
      query,
      body,
      contentType,
      skipAuth,
      headers
    )
    const status = response.status
    const methodHasBody = isMethodWithResponseBody(method)
    const responseContentType = response.headers.get('content-type') ?? ''
    const isJSONResponse = responseContentType.includes('application/json')
    const dataJSON =
      methodHasBody && isJSONResponse ? ((await response.json()) as T) : null
    const apiRes = new APIResponse<T>(dataJSON as T, status)
    return apiRes
  }

  public async *fetchPaginatedJSON<T>(
    path: string,
    query: Record<string, string> = { offset: '0', limit: '20' }
  ): AsyncGenerator<PaginatedResponse<T>, void, unknown> {
    let hasNext = true
    while (hasNext) {
      const queryDto = new GenericPaginatedSearchParamsDto(query)
      const res = await this.fetchJSON<PaginatedResponse<T>>(
        'GET',
        path,
        queryDto
      )
      if (res.isSuccess) {
        yield res.data
      } else {
        console.error(res)
        throw new Error('Error while fetching paginated data')
      }
      query = res.data.query
      query.offset = (parseInt(query.offset) + parseInt(query.limit)).toString()
      hasNext = res.data.data.length > 0
    }
  }

  public async fetchBlob(
    method: HTTPMethod,
    path: string,
    query?: URLSearchParamsCapable,
    body: any | FormData | File = {},
    contentType: MimeType = 'application/json',
    skipAuth: boolean = false
  ): Promise<Blob> {
    const response = await this.fetch(
      method,
      path,
      query,
      body,
      contentType,
      skipAuth
    )
    const blob = await response.blob()
    return blob
  }
}

export const DefaultYMCA = new YMCA()

if (!DefaultYMCA.config.disableYMCADebugger && globalThis.window !== undefined) {
  window.ymca = DefaultYMCA
}

declare global {
  interface Window {
    ymca: JSONFetcher | null
  }
}
