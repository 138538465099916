import { QuerySearchParamsDto } from './common.dto'

export class MemeTemplateListDto extends QuerySearchParamsDto {
  public search = ''
  public communityId?: string
  public tags: string[] = []
  public hasAllTags = false
  public id: string[] = []
  public skipNSFW = false
  public onlyFaces = false

  public toURLSearchParams(): URLSearchParams {
    const params = super.toURLSearchParams()
    if (this.search !== '') {
      params.append('search', this.search)
    }
    if (this.communityId !== undefined) {
      params.append('communityId', this.communityId)
    }
    if (this.tags.length > 0) {
      params.append('tags', this.tags.join(','))
    }
    if (this.hasAllTags) {
      params.append('hasAllTags', 'true')
    }
    if (this.id.length > 0) {
      params.append('id', this.id.join(','))
    }
    if (this.skipNSFW) {
      params.append('skipNSFW', 'true')
    }
    if (this.onlyFaces) {
      params.append('onlyFaces', 'true')
    }
    return params
  }
}

export interface MemeTemplateCreateDto {
  title: string
  description: string
  tags: string[]
  isNsfw: boolean
  isPublic: boolean
  sortPosition: number
  templateImage: string
  communityId?: string
}

export interface MemeTemplateUpdateDto {
  title?: string
  description?: string
  tags?: string[]
  isNsfw?: boolean
  isPublic?: boolean
  sortPosition?: number
  templateImage?: string
}
