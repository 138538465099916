import { useEffect, useRef, useState } from "react";
import { Box } from "@material-ui/core";
import { styled } from "@mui/material";

import {
  checkIsPostContentCanPlayInHTMLVideo,
  checkIsPostContentPlayable,
} from "utils/postContent";

import Image from 'components/atoms/Image'
import ImagePicture from 'components/molecules/ImagePicture'
import { IonScreenProps } from '../PublicationMain'
import { Post } from 'ymca/models/post.model'
import colors from 'theme/dark/colors'

import VideoPlayer from 'components/molecules/VideoPlayer'
import {
  BREAKPOINT_LAPTOP_LARGE,
  BREAKPOINT_MOBILE_LARGE,
  BREAKPOINT_MOBILE_MEDIUM
} from 'theme/shared/breakpoint'
import config from 'utils/config'
import getImageResolutions, {
  getImageUrlSourceType
} from 'utils/getImageResolutions'

interface PublicationContentWrapperProps {
  contest?: boolean
  skeleton?: boolean
  disableFixedHeight?: boolean
  modal?: boolean
}

const PublicationContentWrapper = styled('section', {
  name: 'PublicationContentWrapper'
})<PublicationContentWrapperProps>(
  ({ contest, skeleton, disableFixedHeight, modal }) => {
    let backgroundColor = skeleton
      ? colors.darkBlue50
      : contest
      ? 'transparent'
      : colors.darkBlue50

    if (modal || disableFixedHeight) {
      backgroundColor = 'transparent'
    }

    let hoverBackgroundColor = skeleton ? colors.darkBlue50 : colors.darkBlue

    if (disableFixedHeight || modal) {
      hoverBackgroundColor = 'transparent'
    }
    return {
      gridArea: 'publicationContent',

      display: 'flex',
      justifyContent: 'center',
      borderRadius: '8px',
      backgroundColor: backgroundColor,
      padding: disableFixedHeight ? 'unset' : '0 1rem',
      '&:hover': {
        background: hoverBackgroundColor,
        cursor: 'pointer'
      },

      [`@media (max-width: ${BREAKPOINT_MOBILE_LARGE}px)`]: {
        borderRadius: 0,
        margin: '0 -.6rem' // made it minus so as to break the top parent padding
      }
    }
  }
)

interface PublicationContentContainerProps {
  disableFixedHeight?: boolean
  modal?: boolean
}

const mobileImageHeight = '20rem'
const PublicationContentContainer = styled('div', {
  name: 'PublicationContentContainer'
})<PublicationContentContainerProps>(({ disableFixedHeight, modal }) => {
  if (disableFixedHeight || modal) {
    return {
      minWidth: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
  return {
    minHeight: '30rem', // 480px
    height: '30rem', // 480px
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [`@media (max-width: ${BREAKPOINT_MOBILE_LARGE}px)`]: {
      minHeight: mobileImageHeight, // 320px
      height: mobileImageHeight // 320px
    }
  }
})

const PublicationImageContainer = styled(Box, {
  name: 'PublicationImageContainer'
})({
  position: 'relative',
  height: '100%',
  width: '100%'
})

interface PublicationImageProps {
  disableFixedHeight?: boolean
}

const PublicationImage = styled(ImagePicture, {
  name: 'PublicationImage'
})<PublicationImageProps>(({ disableFixedHeight }) => {
  if (disableFixedHeight) {
    return {
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        display: 'block'
      }
    }
  }
  return {
    '& img': {
      width: '100%',
      height: '100%',
      minHeight: mobileImageHeight,
      minWidth: '100%',
      objectFit: 'contain',
      display: 'block'
    }
  }
})

const PublicationVideo = styled(VideoPlayer, {
  name: 'PublicationVideo'
})(() => {
  return {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    display: 'block'
  }
})

const VideoPlayerIcon = styled(Image)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3rem;
  height: 3rem;
`

export interface PublicationContentProp {
  post: Post
  modal?: boolean
  play?: boolean
  onScreenProps?: IonScreenProps
  fontSize?: string
  setEvent?: any
  modalPage?: boolean
  contestModal?: boolean
  onToggle?: () => void
  handleImageOnLoad?: VoidFunction
}

const PublicationContent = ({
  post,
  contestModal,
  onToggle,
  modal,
  onScreenProps,
  play,
  handleImageOnLoad,
  modalPage
}: PublicationContentProp): JSX.Element => {
  const { disablePublicationFixedHeight } = config
  const { meme, contentType, title } = post
  const { renderedImage, thumbnailImage } = meme ?? {}
  const { isOnScreen, modal: onScreenModal } = onScreenProps ?? {}

  const [videoAlreadyLoaded, setVideoAlreadyLoaded] = useState<boolean>(false)
  const [imageAlreadyLoaded, setImageAlreadyLoaded] = useState<boolean>(false)
  const [skeletonBackground, setSkeletonBackground] = useState<boolean>(true)
  const [muted, setMuted] = useState<boolean>(true)

  const videoRef = useRef<HTMLVideoElement>(null)

  const isPlayable = checkIsPostContentPlayable(contentType)

  const { x1, x2, x3, x4, fallback } = getImageResolutions(
    isPlayable ? thumbnailImage! : renderedImage!
  )

  const canPlayInHTMLVideo = checkIsPostContentCanPlayInHTMLVideo(contentType)

  const contentURL = renderedImage?.urlOptimised1000x1000
  const gifImage = renderedImage?.jpegUrl

  useEffect(() => {
    const videoElement = videoRef.current

    if (videoElement) {
      // reduce the volume
      videoElement.volume = 0.5

      if (isPlayable) {
        setVideoAlreadyLoaded(true)
      }
    }
  }, [videoRef.current])

  useEffect(() => {
    if (videoAlreadyLoaded) {
      const videoElement = videoRef.current

      if (videoElement) {
        if (!isOnScreen) {
          videoElement.pause()
        } else {
          if (onScreenModal) {
            videoElement.pause()
          } else {
            videoElement.play()
          }
        }
      }
    }
    if (isOnScreen) {
      setImageAlreadyLoaded(true)
    }
  }, [isOnScreen, videoAlreadyLoaded, onScreenModal])

  useEffect(() => {
    if (videoAlreadyLoaded) {
      if (modal) {
        setMuted(false)
        videoRef.current?.play()
      }
    }
  }, [modal, videoAlreadyLoaded])

  const showLowQuality =
    typeof isOnScreen === 'boolean' && !isOnScreen && !imageAlreadyLoaded

  const showVideo = isPlayable && canPlayInHTMLVideo
  const showGif = isPlayable && !canPlayInHTMLVideo
  const showImage = !showVideo && !showGif

  const pictureSources = []
  if (modal || modalPage) {
    pictureSources.push({
      srcSet: `${x4} 1x`,
      type: getImageUrlSourceType(x1)
    })
  } else {
    pictureSources.push({
      srcSet: !showLowQuality ? `${x4} 1x` : `${x1} 1x`,
      // srcSet: !showLowQuality ? `${x3} 1x, ${x2} 2x` : `${x1} 1x`,
      type: getImageUrlSourceType(x1)
    })
  }

  return (
    <PublicationContentWrapper
      onClick={onToggle}
      contest={contestModal}
      skeleton={skeletonBackground}
      disableFixedHeight={disablePublicationFixedHeight}
      modal={modal}
    >
      <PublicationContentContainer
        disableFixedHeight={disablePublicationFixedHeight}
        modal={modal}
      >
        <PublicationImageContainer>
          {modal || (play && isPlayable) || videoAlreadyLoaded ? (
            <>
              {showVideo && (
                <PublicationVideo
                  {...{
                    muted
                  }}
                  src={contentURL}
                  ref={videoRef}
                />
              )}
              {showGif && (
                <PublicationImage
                  alt={`${title} image`}
                  src={gifImage}
                  onLoad={handleImageOnLoad}
                  disableFixedHeight={disablePublicationFixedHeight}
                />
              )}
              {showImage && (
                <PublicationImage
                  disableFixedHeight={disablePublicationFixedHeight}
                  alt={`${title} image`}
                  src={fallback}
                  // srcSet={srcSet}
                  onLoad={() => {
                    handleImageOnLoad?.()
                    setSkeletonBackground(false)
                  }}
                  pictureSources={pictureSources}
                />
              )}
            </>
          ) : (
            <>
              <PublicationImage
                disableFixedHeight={disablePublicationFixedHeight}
                alt={`${title} image`}
                src={fallback}
                // srcSet={srcSet}
                onLoad={() => {
                  handleImageOnLoad?.()
                  setSkeletonBackground(false)
                }}
                pictureSources={pictureSources}
              />
              {isPlayable && (
                <VideoPlayerIcon
                  alt='video player icon'
                  src='meme/video-player-icon.png'
                />
              )}
            </>
          )}
        </PublicationImageContainer>
      </PublicationContentContainer>
    </PublicationContentWrapper>
  )
}

export default PublicationContent;
