import {Box, Button, InputBase, makeStyles, Typography} from '@material-ui/core';
import {SearchIcon} from 'components/shared/SvgIcons';
import colors from 'theme/dark/colors';
import React, {useState} from 'react';
import {debounce} from 'utils/utils';
import {DefaultYMCA} from 'ymca/ymca'
import CommonImageComponent from 'components/shared/Image';
import {GeneratedMeme} from 'ymca/models/memegen.model';
import {getImageBase64FromSearchEngineURL, getImageExtensionFromURL, splitPhrase} from 'meme-generator/utils/services';
import {useNavigate} from 'react-router-dom';
import ReactLoading from 'react-loading'
import useWindowSize from 'hooks/useWindowSize';
import {useAuth} from 'context/auth/authContext';
import type {AlertColor} from '@mui/material/Alert';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import EditIcon from '@material-ui/icons/Edit';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import { ThrottlePurpose } from 'ymca/dtos/throttle.dto';
import RemainingAttemptsAI from './RemainingAttemptsAI';

interface TextToMemeProps {
  btnStyle?: any;
  processImageWCaptions: (
    image: string,
    caption: string[],
    type: string
  ) => void;
}

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
  },
  searchWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  loadingIcon: {
    position: "absolute",
    top: "calc(50% + 32px)",
    left: "calc(50% - 32px)",
    zIndex: 1,
  },
  searchBar: {
    fontSize: "16px",
    height: "46px",
    width: "85%",
    marginRight: "5px",
    borderRadius: "10px",
    background: colors.darkBlueActive,
    paddingLeft: "25px !important",
    color: "#8A98B4",
    fontWeight: 500,
    backgroundPosition: "1.18em center",
    lineHeight: "19px",
    border: "0px solid var(--gradient-fall-back)",
  },
  // Small text, centered vertically, pulled to the right
  attemptsInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    fontSize: '0.75rem',
    color: colors.greyScale500,

    justifyContent: 'flex-end',
    marginTop: '0.5rem',
    width: '100%',
    gridArea: 'attemptCountInfo'
  },

  authBtn: {
    height: 40,
    width: 135,
    borderRadius: "6.25rem",
    fontSize: 14,
    fontWeight: 700,
    color: "#FFFFFF",
    margin: "auto 15px",
    minWidth: 0,
    padding: 0,
    transition: "0.4s",
    textShadow: "0px 0px 5px rgba(0,0,0,0.1)",
    position: "relative",
    backgroundColor: colors.blueBtn,
    "&:hover": {
      opacity: 0.5,
      backgroundColor: colors.blueBtn,
    },
  },
  memesWrapper: {
    height: "438px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  memesAlertInside: {
    height: "438px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  memeCaption: {
    position: "absolute",
    fontFamily: "Anton",
    fontSize: "1rem",
    color: "white",
    textTransform: "uppercase",
    textShadow:
      "rgb(0, 0, 0) -1px -1px 1px, rgb(0, 0, 0) 1px -1px 1px, rgb(0, 0, 0) -1px 1px 1px, rgb(0, 0, 0) -1px -1px 1px",
    textAlign: "center",
    cursor: "pointer",
  },
  memeCaptionTop: {
    top: 0,
    left: 0,
    width: "100%",
  },
  memeCaptionBottom: {
    bottom: 0,
    left: 0,
    width: "100%",
  },
  nothingTitle: {
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    textFillColor: "transparent",
    "-webkit-text-fill-color": "transparent",
    backgroundImage:
      " linear-gradient(88.06deg, #9C9AFF 1.64%, #F9B0FF 52.49%, #FFD1CE 98.36%)",
    textShadow: "0px 4px 8px rgba(14, 20, 34, 0.2)",
    fontSize: "20px",
    fontWeight: 700,
    letterSpacing: "-1.6px",
  },
  nothingText: {
    textAlign: "center",
    color: "#8A98B4",
    fontSize: "15px",
    fontWeight: 500,
    letterSpacong: "-1px",
    marginTop: "12px",
  },
  memesContainer: {
    overflowY: "auto",
    maxHeight: "435px",
    marginTop: "28px",
    "&::-webkit-scrollbar": {
      width: 6,
      borderRadius: 20,
      backgroundColor: "#0E1017",
    },

    "&::-webkit-scrollbar-thumb": {
      background: colors.purpleGradient,
      borderRadius: 20,
    },
  },
  mobileMemesContainer: {
    marginTop: "28px",
  },
  mobileGenerateBtn: {
    background: "linear-gradient(90deg, #CF01FF 0%, #6202FF 100%)",
    height: "54px",
    boxShadow: "0px 4px 10px rgba(18, 7, 23, 0.5)",
    borderRadius: "67px",
    color: "#ffffff",
    letterSpacing: "-0.7px",
    lineHeight: "19px",
    fontSize: "16px",
    fontWeight: 700,
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  memeGridWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    
    height: "75%",
    placeItems: "baseline",
    padding:"1rem 0.5rem",
    gridGap: "12px",
    overflowY: "scroll !important" as any,
    "&::-webkit-scrollbar": {
      width: 6,
      borderRadius: 20,
      backgroundColor: "#0E1017",
    },

    "&::-webkit-scrollbar-thumb": {
      background: colors.purpleGradient,
      borderRadius: 20,
    },
  },
  "@media (max-height: 700px)": {
    memeGridWrapper: {
      height: "75%",
      overflowY: "scroll",
    },
  },
  templateWrapper: {
    height: "220px",
    width: "238px",
    position: "relative",
    transition: "outline-width 100ms ease",
    outline: `0px solid ${colors.gradientPurple}`,
    overflow: "hidden !important",
    borderRadius: 10,
    "&:hover": {
      outlineWidth: 3,
    },

    "& img": {
      width: "100%",
      height: "100%",
      borderRadius: "inherit",
      zIndex: 2,
      cursor: "pointer",
    },
  },
  regenButton: {
    width: '15rem',
    height: '3rem',
    marginTop: ".7rem",
    background: colors.darkBlueActive,
    boxShadow: '0px 4px 8px rgba(14, 20, 34, 0.2)',
    borderRadius: '10px',
    color: '#8A98B4',
    letterSpacing: '-0.7px',
    lineHeight: '19px',
    fontSize: '14px',
    fontWeight: 600,
    position: 'relative',
    '&.selected': {
      background: colors.purpleGradient,
      color: colors.white,   
    },
  },
  regenBtnsContainer:{
    display:"flex",
    gap:"0.7rem",
  },
  buttonsContainer:{  
    display:"flex",
    flexDirection:"column"
  },
  "@media (max-width: 760px)": {
    buttonsContainer:{
      backgroundColor: '#09000b',
      position: 'fixed',
      padding: '.5rem 1rem',
      bottom: 0,
      width: '100%',
      left: 0,
      zIndex:3
     
    },
    container: {
    width:"98%",
    },
    regenBtnsContainer:{
      width:"100%",
      justifyContent:"space-between"
    },
    regenButton:{
      width:"50%",
      background:colors.white,
      color:colors.black80,
      '&.selected': {
        background:colors.white,
        opacity:0.8
      }
    },
    searchWrapper: {
      alignItems: "center",
      gap: "1.4rem",
      marginTop: "1.4rem",
      flexDirection: "column",
      "& > div": {
        width: "100%",
      },
      "& > button": {
        width: "100%",
        background: colors.black80,
        color: colors.white,
      },
    },
    searchBar: {
      background: "rgba(255, 255, 255, 0.2)",
      color: colors.white,
      borderRadius: "100px",
      "& > svg": {
        fill: "#E7B8FF",
      },
    },
    templateWrapper: {
      width: "42vw",
      height: "42vw",
    },
    memeGridWrapper: {
      gridGap:".5rem",
      padding:"1rem 0.3rem",
    },
  },
});

type MemegenSnackbarProps = {
  open: boolean;
  status: number;
  onClose: () => void;
};

function MemegenSnackbar({
  open,
  status,
  onClose,
}: MemegenSnackbarProps): JSX.Element {
  let severity: AlertColor = "error";
  let message = "";
  if (status >= 300) {
    message = "Cannot generate meme, please try again later";
  }
  if (status === 406) {
    message = "Sorry, but we have trouble generating your meme captions";
    severity = "warning";
  }
  if (status === 429) {
    message = "You've reached your rate limit, please try again later";
    severity = "warning";
  }
  // const open = status >= 300;
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default function TextToMeme({
  btnStyle,
  processImageWCaptions,
}: TextToMemeProps) {
  const classes = useStyles();
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [captionLoading, setCaptionLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [memes, setMemes] = useState<GeneratedMeme[] | null>(null);
  const [apiStatus, setApiStatus] = useState<number>(0);
  const [throttleApiCount, setThrottleApiCount] = useState<number>(0);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [modifiedCaption, setModifiedCaptions] = useState<string[][]>([]);
  const isMobile = useWindowSize().width < 760;
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const rateLimitPurpose: ThrottlePurpose = 'meme-generator'

  const handleInputChange = (value: string) => {
    setText(value);
  };

  const onSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showErrorSnackbar = (status: number): void => {
    if (status < 300) {
      // Hide snackbar for status less than 300
      setApiStatus(0);
      setSnackbarOpen(false);
      return;
    }
    // Some HTTP error occurred, show the snackbar
    setApiStatus(status);
    setSnackbarOpen(true);
  };

  const extractCaptions = async (
    memes: GeneratedMeme[]
  ): Promise<string[][]> => {
    const captions: string[][] = [];
    for (const meme of memes) {
      let dividedPhrase = ["", ""];
      if (meme.caption !== null) {
        dividedPhrase = await splitPhrase(meme.caption);
      }
      captions.push(dividedPhrase);
    }
    return captions;
  };

  const generateMemes = async () => {
    try {
      setLoading(true);
      setMemes(null);
      const response = await DefaultYMCA.memegenService.generateAIMeme(text);
      if (response.data.memes.length === 0) {
        setMemes(null);
        showErrorSnackbar(500); // Cannot generate memes
        setLoading(false);
        return;
      }
      setMemes(response.data.memes);
      const hasCaptions = response.data.generatedCaptions.length > 0;
      let captions: string[][];
      if (hasCaptions) {
        captions = await extractCaptions(response.data.memes);
      } else {
        const originalPrompt = response.data.originalSearchString;
        captions = [[originalPrompt, ""]]; // Use the original prompt as caption

        // Using 406 Not Acceptable code to signify that caption generation
        // has failed, most likely due to user's prompt
        showErrorSnackbar(406);
      }
      setModifiedCaptions(captions);
      setLoading(false);
      setThrottleApiCount(throttleApiCount + 1);
    } catch {
      setLoading(false);
      // When any error occurs, show an error
      showErrorSnackbar(500);
    }
  };

  const regenerateCaptions = async () => {
    try {
      const memeCount = memes?.length || 1;
      setCaptionLoading(true);
      const response = await DefaultYMCA.memegenService.generateCaption(text, memeCount);
      if (response.data.length === 0) {
        showErrorSnackbar(500); // Cannot generate memes
        setCaptionLoading(false);
        return;
      }
      const responseLength = response.data.length;
      const hasCaptions = responseLength > 0;
      let captions: string[][] = [];
      if (!hasCaptions) {
        // Using 406 Not Acceptable code to signify that caption generation
        // has failed, most likely due to user's prompt
        showErrorSnackbar(406);
        return;
      }
      for (const caption of response.data) {
        captions.push(await splitPhrase(caption));
      }
      // If the number of regenerated captions is more than the number of memes,
      // slice to be equal to memeCount
      // Else if the number of regenerated captions is less than the number of memes,
      // only change the captions that are available
      const updatedCaptions: string[][] = [];
      if (responseLength > memeCount) {
        captions = captions.slice(0, memeCount);
        updatedCaptions.push(...captions);
      } else {
        for (let i = 0; i < responseLength; i++) {
          modifiedCaption[i] = captions[i];
        }
        updatedCaptions.push(...modifiedCaption.slice(0, responseLength));
      }
      setModifiedCaptions(captions);
      setCaptionLoading(false);
      setThrottleApiCount(throttleApiCount + 1);
    } catch {
      setCaptionLoading(false);
      // When any error occurs, show an error
      showErrorSnackbar(500);
    }
  };

  const regenerateImages = async () => {
    try {
      setImageLoading(true);
      const response = await DefaultYMCA.memegenService.generateImage(text, 1);
      if (response.data.length === 0) {
        showErrorSnackbar(500); // Cannot generate memes
        setImageLoading(false);
        return;
      }
      const hasImages = response.data.length > 0;
      if (!hasImages) {
        setImageLoading(false);
        showErrorSnackbar(406);
        return;
      }
      const newImage = response.data[0] ?? '';
      const newMemes = memes?.map(meme => {
        return { ...meme, image: newImage };
      }) ?? [];
      setMemes(newMemes);
      setImageLoading(false);
      setThrottleApiCount(throttleApiCount + 1);
    } catch (err) {
      setImageLoading(false);
      // When any error occurs, show an error
      console.debug(err);
      showErrorSnackbar(500);
    }
  };

  const processGenereratedImages = async (
    imageURL: string,
    captionValue: string[]
  ) => {
    const base64Image = await getImageBase64FromSearchEngineURL(imageURL);
    const imageExtension = await getImageExtensionFromURL(imageURL);
    processImageWCaptions(base64Image, captionValue, imageExtension);
  };

  return (
    <>
      <MemegenSnackbar
        open={snackbarOpen}
        status={apiStatus}
        onClose={onSnackbarClose}
      />
      <Box className={classes.container}>
        <Box className={classes.searchWrapper}>
          <InputBase
            onChange={(e) => debounce(handleInputChange(e.target.value), 500)}
            className={`${classes.searchBar}`}
            disabled={false}
            placeholder={
              !isMobile
                ? "Write something and turn your idea into a meme"
                : "Write something..."
            }
            style={{ paddingLeft: "calc(1.18em + 24.5px)" }}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === "Enter") {
                generateMemes();
              }
            }}
            startAdornment={
              <SearchIcon
                fill={"rgba(138, 152, 180, 0.5)"}
                style={{ width: 22, height: 22, marginRight: 10 }}
              />
            }
            endAdornment={
              <span
                style={{
                  marginRight: 10,
                  color: "rgba(255, 255, 255, 0.5)",
                  fontSize: "14px",
                }}
              >
                {text.length}/200
              </span>
            }
          />
          <Button
            disabled={text.length < 5 || loading || !isAuthenticated}
            onClick={() => generateMemes()}
            className={btnStyle + " selected"}
          >
            {loading ? "Generating" : "Generate"}
          </Button>
        </Box>
        {isAuthenticated ? (
          <>
          
            {!(memes === null) || memes === undefined ? (
              <>
              <Box className={classes.buttonsContainer}>
              <Box className={classes.regenBtnsContainer}> 
                <LoadingButton
                  className={classes.regenButton}
                  startIcon={<EditIcon />}
                  onClick={regenerateCaptions}
                  loading={captionLoading}
                  disabled={memes.length === 0 || imageLoading}
                >
                  Regenerate text
                </LoadingButton>
                <LoadingButton
                  className={classes.regenButton}
                  startIcon={<ImageOutlinedIcon />}
                  onClick={regenerateImages}
                  loading={imageLoading}
                  disabled={memes.length === 0 || captionLoading}
                >
                  Regenerate image
                </LoadingButton>
                {!isMobile && (
                  <RemainingAttemptsAI
                    rateLimitPurpose={rateLimitPurpose}
                    className={classes.attemptsInfo}
                    count={throttleApiCount}
                  />
                )}
              </Box>
              {isMobile && (
                  <RemainingAttemptsAI
                    rateLimitPurpose={rateLimitPurpose}
                    className={classes.attemptsInfo}
                    count={throttleApiCount}
                  />
                )}
              </Box>
                {memes.length > 0 ? (
                  <div className={classes.memeGridWrapper}>
                    {memes.map((meme: GeneratedMeme, index: number) => (
                      <div
                        className={classes.templateWrapper}
                        key={index}
                        onClick={() => {
                          processGenereratedImages(
                            meme.image,
                            modifiedCaption[index]
                          );
                        }}
                      >
                        {modifiedCaption.length === memes.length && (
                          <span
                            className={
                              classes.memeCaption + " " + classes.memeCaptionTop
                            }
                          >
                            {modifiedCaption[index][0]}
                          </span>
                        )}
                        <CommonImageComponent
                          src={meme.image}
                          alt={"AImeme" + index}
                          width={"100%"}
                          lazyLoad={true}
                        />
                        {modifiedCaption.length === memes.length && (
                          <span
                            className={
                              classes.memeCaption +
                              " " +
                              classes.memeCaptionBottom
                            }
                          >
                            {modifiedCaption[index][1]}
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <Box className={classes.memesWrapper}>
                    <Typography className={classes.nothingTitle}>
                      {"Cannot generate related meme :("}
                    </Typography>
                    <Typography className={classes.nothingText}>
                      Try using different sets of words or be more specific.
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <Box className={classes.memesWrapper}>
                {loading && (
                  <ReactLoading
                    className={classes.loadingIcon}
                    type='balls'
                    color={colors.loaderBackground}
                  />
                )}
              </Box>
            )}
          </>
        ) : (
          <Box className={classes.memesAlertInside}>
            <Typography>Please</Typography>
            <Button
              className={classes.authBtn}
              onClick={() => navigate("/auth")}
            >
              <span>Sign Up/Log In</span>
            </Button>
            <Typography>to use this feature.</Typography>
          </Box>
        )}
      </Box>
    </>
  );
}
