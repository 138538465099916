import { PaginatedGenerator, PaginatedResponse, SortOrder } from 'ymca/dtos/common.dto'
import { Comment } from 'ymca/models/comment.model'
import { APIResponse, ParamsCapableToPlainJSON } from 'ymca/jsonFetcher'
import { BaseService, CommonArgs } from './base.service'
import {
  CreateCommentDto,
  DeleteCommentDto,
  GetCommentsDto
} from 'ymca/dtos/comment.dto'
import { SelfService } from './self.service'
import { LensService } from './lens.service'
import { v4 } from 'uuid'
import { UserThumbnail } from 'ymca/models/user.model'

export class CommentService extends BaseService {
  protected selfService: SelfService
  protected lensService: LensService

  public constructor(
    common: CommonArgs,
    selfService: SelfService,
    lensService: LensService
  ) {
    super(common)
    this.selfService = selfService
    this.lensService = lensService
  }

  public getPostCommentKey(postId: string): string {
    return `post-comments-${postId}`
  }

  protected async cacheComment(
    comment: Comment,
    skipCache: boolean
  ): Promise<void> {
    const key = `comment-${comment.id}`
    await this.common.kvStore.set<Comment>(key, comment, 0, skipCache)
    this.common.pubsub.publish(comment.id, comment)

    // NOTE:
    // Listeners of this would be notified everytime a comment is
    // fetched for a post. They would need to check if their
    // comment list already has the comment.
    const postCommentKey = this.getPostCommentKey(comment.fkPostId)
    this.common.pubsub.publish(postCommentKey, comment)
  }

  public async getCommentsByDto(
    dto: GetCommentsDto
  ): Promise<APIResponse<PaginatedResponse<Comment>>> {
    const res = await this.common.jsonFetcher.fetchJSON<
      PaginatedResponse<Comment>
    >('GET', '/api/comment', dto)
    return res
  }

  public getPaginatedGeneratorCommentsByDto(
    dto: GetCommentsDto
  ): PaginatedGenerator<Comment> {
    const queryArgs = ParamsCapableToPlainJSON(dto)
    const res = this.common.jsonFetcher.fetchPaginatedJSON<Comment>(
      '/api/comment',
      queryArgs
    )
    return res
  }

  public async getPostComments(
    postId: string,
    fromUserId: string | undefined,
    _noop: any,
    limit: number = 3,
    offset: number = 0,
    order: SortOrder = 'DESC'
  ): Promise<PaginatedResponse<Comment>> {
    const req = new GetCommentsDto()
    req.limit = limit
    req.offset = offset
    req.order = order
    req.postId = postId
    if (fromUserId != null && fromUserId !== '') {
      req.userId = fromUserId
    }
    const res = await this.getCommentsByDto(req)
    return res.data
  }

  public async getPostCommentsGenerator(
    postId: string,
    limit: number = 3,
    offset: number = 0,
    order: SortOrder = 'DESC'
  ): Promise<
    AsyncGenerator<PaginatedResponse<Comment>> | PaginatedGenerator<Comment>
  > {
    if (postId.startsWith('_')) {
      postId = postId.slice(1)
      const lensComments = await this.lensService.getCommentsForPost(postId)
      return lensComments
    }
    const commentGenerator =
      await this.common.jsonFetcher.fetchPaginatedJSON<Comment>(
        '/api/comment',
        { limit: limit.toString(), offset: offset.toString(), order, postId }
      )
    return commentGenerator
  }

  public async createComment(
    postId: string,
    comment: string
  ): Promise<APIResponse<Comment>> {
    try {
    } catch (error) {}
    // TODO: Handle failures
    if (postId.startsWith('_')) {
      postId = postId.slice(1)
      // create the comment
      await this.lensService.createComment(postId, comment)
      // assemble a response object
      const newComment: Comment = {
        id: v4(),
        fkPostId: postId,
        fkUserId: this.selfService.getId(),
        comment,
        user: (await this.selfService.getSelf()) as UserThumbnail,
        createdAt: new Date(),
        updatedAt: new Date(),
        isUserDeletable: true
      }
      const res = new APIResponse<Comment>(newComment, 201)
      return res
    }
    const dto = new CreateCommentDto()
    dto.comment = comment
    dto.postId = postId
    const res = await this.common.jsonFetcher.fetchJSON<Comment>(
      'POST',
      '/api/comment',
      undefined,
      dto
    )
    const { isSuccess, data } = res

    if (!isSuccess) {
      const { message } = (data as any) ?? {}
      throw new Error(message)
    }

    const newComment: Comment = {
      id: res.data.id,
      fkPostId: res.data.fkPostId,
      fkUserId: res.data.fkUserId,
      comment: res.data.comment,
      user: (await this.selfService.getSelf()) as UserThumbnail,
      createdAt: res.data.createdAt,
      updatedAt: res.data.updatedAt,
      isUserDeletable: true
    }
    const response = new APIResponse<Comment>(newComment, 201)
    return response
  }

  public async deleteComment(commentId: string): Promise<boolean> {
    if (commentId.startsWith('_')) {
      commentId = commentId.slice(1)
      const res = await this.lensService.hidePostOrComment(commentId)
      return res
    }
    const dto = new DeleteCommentDto()
    dto.commentId = commentId
    const res = await this.common.jsonFetcher.fetchJSON(
      'DELETE',
      '/api/comment',
      dto
    )
    return res.isSuccess
  }
}
