import { MemeTemplate } from 'ymca/models/meme-template.model'
import { BaseService } from './base.service'
import {
  MemeTemplateCreateDto,
  MemeTemplateListDto,
  MemeTemplateUpdateDto
} from 'ymca/dtos/meme-template.dto'
import { PaginatedGenerator } from 'ymca/dtos/common.dto'

export const MEME_TEMPLATE_CACHE_TTL_SECONDS = 60 * 60 // 1 hour

export class MemeTemplateService extends BaseService {
  protected fetchTemplatesByDto(
    dto: MemeTemplateListDto
  ): PaginatedGenerator<MemeTemplate> {
    const urlSearchParams = dto.toURLSearchParams()
    const queryObject = Object.fromEntries(urlSearchParams.entries())
    const res = this.common.jsonFetcher.fetchPaginatedJSON<MemeTemplate>(
      '/api/meme-template',
      queryObject
    )
    return res
  }

  /**
   * Fetches meme templates from the server, optionally filtered by search string
   * @param search the search string to use (optional)
   * @returns a generator that yields meme templates
   */
  public fetchPublicTemplates(
    search: string = ''
  ): PaginatedGenerator<MemeTemplate> {
    const dto = new MemeTemplateListDto()
    dto.limit = 100
    dto.offset = 0
    if (search.length > 0) {
      dto.search = search
    }

    const fetchData = this.fetchTemplatesByDto(dto)
    return fetchData
  }

  /**
   * Fetches meme templates from the server configured specifically
   * for a community. User needs to be a member to see these templates.
   * @param communityId the community to fetch templates for
   * @returns a generator that yields meme templates
   */
  public fetchCommunityTemplates(
    communityId: string
  ): PaginatedGenerator<MemeTemplate> {
    const dto = new MemeTemplateListDto()
    dto.limit = 100
    dto.offset = 0
    dto.communityId = communityId
    return this.fetchTemplatesByDto(dto)
  }

  /**
   * Fetches meme templates from the server for a tag
   * @param tag the tag to fetch templates for
   * @returns a generator that yields meme templates
   */
  public fetchTemplatesByTag(tag: string): PaginatedGenerator<MemeTemplate> {
    const dto = new MemeTemplateListDto()
    dto.limit = 100
    dto.offset = 0
    dto.tags = [tag]
    return this.fetchTemplatesByDto(dto)
  }

  /**
   * @depreciated please use fetchTemplates instead. this method is for
   * compatibility with the old meme template service, until we remove it.
   * @param search the search string to use
   * @returns a promise that resolves to an array of meme templates
   */
  public async getTemplates(search: string = ''): Promise<MemeTemplate[]> {
    const templates: MemeTemplate[][] = []
    const gen = await this.fetchPublicTemplates(search)
    for await (const results of gen) {
      templates.push(results.data)
    }
    return templates.flat()
  }

  getTemplatesByDTO(
    dto: MemeTemplateListDto
  ): PaginatedGenerator<MemeTemplate> {
    const urlSearchParams = dto.toURLSearchParams()
    const queryObject = Object.fromEntries(urlSearchParams.entries())
    const res = this.common.jsonFetcher.fetchPaginatedJSON<MemeTemplate>(
      '/api/meme-template',
      queryObject
    )
    return res
  }

  public async createMemeTemplate(
    dto: MemeTemplateCreateDto
  ): Promise<MemeTemplate> {
    const res = await this.common.jsonFetcher.fetchJSON<MemeTemplate>(
      'POST',
      '/api/meme-template',
      undefined,
      dto
    )
    if (res.status !== 201) {
      console.error('Failed to create meme template', res)
      throw new Error('Failed to create meme template')
    }
    return res.data
  }

  public async updateMemeTemplate(
    id: string,
    dto: MemeTemplateUpdateDto
  ): Promise<MemeTemplate> {
    const res = await this.common.jsonFetcher.fetchJSON<MemeTemplate>(
      'PATCH',
      `/api/meme-template/${id}`,
      undefined,
      dto
    )
    if (res.status !== 200) {
      console.error('Failed to update meme template', res)
      throw new Error('Failed to update meme template')
    }
    return res.data
  }
}
