import { useEffect, useState } from 'react'
import { debounce } from '../utils/utils'

export interface WindowSizeProps {
  width: number
  height: number
  isMobile?: boolean
}

const useWindowSize = (): WindowSizeProps => {
  const [windowSize, setWindowSize] = useState<WindowSizeProps>({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const handleResize = (): void => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
      isMobile: window.innerWidth < 550
    })
  }

  useEffect(() => {
    window.addEventListener('resize', debounce(handleResize, 200))
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

export default useWindowSize
