import { Ref, forwardRef, useMemo } from "react";
import { getAssetsPath } from "utils/path";
import { LazyLoadImage } from "react-lazy-load-image-component";

export interface ImageProps {
  src: string
  fallbackSrc?: string
  className?: string
  alt?: string
  width?: string
  height?: string
  wrapperStyles?: React.CSSProperties
  fill?: string
  lazyLoad?: boolean
  afterLoad?: () => void
  beforeLoad?: () => void
  onClick?: (event: React.MouseEvent<HTMLImageElement>) => void
  crossOrigin?: 'anonymous' | 'use-credentials' | ''
  onLoad?: VoidFunction
}

const Image = forwardRef(
  (
    {
      src,
      fallbackSrc,
      className,
      alt,
      width,
      height,
      wrapperStyles,
      lazyLoad,
      afterLoad,
      beforeLoad,
      onClick,
      crossOrigin = '',
      onLoad = () => {}
    }: ImageProps,
    ref?: Ref<HTMLImageElement>
  ): JSX.Element => {
    const imageComponent = useMemo(() => {
      const isAvif = src.endsWith('.avif')
      const isWebp = src.endsWith('.webp')
      const isJpeg = src.endsWith('.jpeg')
      const isPng = src.endsWith('.png')
      let contentType = ''
      if (isAvif) contentType = 'image/avif'
      if (isWebp) contentType = 'image/webp'
      if (isJpeg) contentType = 'image/jpeg'
      if (isPng) contentType = 'image/png'
      if (lazyLoad === true) {
        return (
          <picture style={wrapperStyles}>
            <source srcSet={getAssetsPath(src)} type={contentType} />
            <LazyLoadImage
              src={getAssetsPath(fallbackSrc ?? src)}
              className={className}
              alt={alt}
              onClick={(event: React.MouseEvent<HTMLImageElement>) => {
                if (onClick) {
                  onClick(event)
                }
              }}
              width={width}
              height={height}
              afterLoad={() => {
                if (afterLoad) {
                  afterLoad()
                }
              }}
              beforeLoad={() => {
                if (beforeLoad) {
                  beforeLoad()
                }
              }}
              crossOrigin={crossOrigin}
              srcSet=''
              onLoad={onLoad}
            />
          </picture>
        )
      } else {
        return (
          <picture style={wrapperStyles}>
            <source srcSet={getAssetsPath(src)} type={contentType} />
            <img
              src={getAssetsPath(fallbackSrc ?? src)}
              className={className}
              alt={alt}
              srcSet=''
              onClick={(event: React.MouseEvent<HTMLImageElement>) => {
                if (onClick) {
                  onClick(event)
                }
              }}
              width={width}
              height={height}
              ref={ref}
              crossOrigin={crossOrigin}
              onLoad={onLoad}
            />
          </picture>
        )
      }
    }, [src, alt, width, height])
    return imageComponent
  }
)

export default Image;
