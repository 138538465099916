export enum MemeEditorEnums {
  CREATE_MEME = 'CREATE_MEME',
  TEXT_TO_MEME = 'TEXT_TO_MEME',
  SWAP_FACES = 'SWAP_FACES',
  MEME_CAM = 'MEME_CAM'
}
export type MemeEditorTypes =
  | MemeEditorEnums.CREATE_MEME
  | MemeEditorEnums.TEXT_TO_MEME
  | MemeEditorEnums.SWAP_FACES
  | MemeEditorEnums.MEME_CAM

export enum PostContentTypeEnums {
  gif = 'gif',
  meme = 'meme',
  article = 'article',
  video = 'video'
}

export type PostContentType =
  | PostContentTypeEnums.gif
  | PostContentTypeEnums.article
  | PostContentTypeEnums.meme
  | PostContentTypeEnums.video

export enum SourceImageEnums {
  GIF = 'GIF',
  JPEG = 'JPEG',
  JPG = 'JPG',
  PNG = 'PNG',
  SVG = 'SVG',
  SVG_XML = 'SVG+XML',
  WEBP = 'WEBP'
}
export type SourceImageType =
  | SourceImageEnums.GIF
  | SourceImageEnums.JPEG
  | SourceImageEnums.JPG
  | SourceImageEnums.PNG
  | SourceImageEnums.SVG
  | SourceImageEnums.SVG_XML
  | SourceImageEnums.WEBP

export type FileType = 'video' | 'image' | 'gif'

interface FileTypeMapInterface {
  gif: FileType
  image: FileType
  video: FileType
}
export const FileTypeMap: FileTypeMapInterface = {
  image: 'image',
  video: 'video',
  gif: 'gif'
}

export const FileTypes: FileType[] = [
  FileTypeMap.image,
  FileTypeMap.gif,
  FileTypeMap.video
]

export type VideoFileTypeExtensionType = 'mp4'
// type CreateMemeFileImageExtentionType = "png";
interface IVideoFileTypeExtensionMap {
  mp4: VideoFileTypeExtensionType
}

export const VideoFileTypeExtensionMap: IVideoFileTypeExtensionMap = {
  mp4: 'mp4'
}

export const VideoFileTypeExtensions: VideoFileTypeExtensionType[] = [
  VideoFileTypeExtensionMap.mp4
]

export enum ImageFileTypeExtensionEnum {
  gif = 'gif',
  jpeg = 'jpeg',
  jpg = 'jpg',
  png = 'png',
  svg = 'svg',
  svg_xml = 'svg+xml',
  webp = 'webp'
}
export type ImageFileTypeExtensionType =
  | ImageFileTypeExtensionEnum.gif
  | ImageFileTypeExtensionEnum.jpeg
  | ImageFileTypeExtensionEnum.jpg
  | ImageFileTypeExtensionEnum.png
  | ImageFileTypeExtensionEnum.svg
  | ImageFileTypeExtensionEnum.svg_xml
  | ImageFileTypeExtensionEnum.webp

export const ImageFileTypeExtensions: ImageFileTypeExtensionType[] = [
  ImageFileTypeExtensionEnum.gif,
  ImageFileTypeExtensionEnum.jpeg,
  ImageFileTypeExtensionEnum.jpg,
  ImageFileTypeExtensionEnum.png,
  ImageFileTypeExtensionEnum.svg,
  ImageFileTypeExtensionEnum.svg_xml,
  ImageFileTypeExtensionEnum.webp
]

interface CreateMemeFileErrorMapInteface {
  allowedVideoExtTypeMessage: string
}
export const FileErrorMap: CreateMemeFileErrorMapInteface = {
  allowedVideoExtTypeMessage:
    'Format not accepted. Please use a MP4, AVI, MKV, MPEG or MOV format'
}

export type PublishMemeModalPath = 'contest' | 'community'

export const MEME_FILE_UPLOAD_DIMENSION_MIN_WIDTH = 200
export const MEME_FILE_UPLOAD_DIMENSION_MIN_HEIGHT = 200
export const MEME_FILE_UPLOAD_DIMENSION_ERROR_IMAGE = `Please upload an image with min dimensions ${MEME_FILE_UPLOAD_DIMENSION_MIN_WIDTH}x${MEME_FILE_UPLOAD_DIMENSION_MIN_HEIGHT}px`
export const MEME_FILE_UPLOAD_DIMENSION_ERROR_VIDEO = `Please upload an video with min dimensions ${MEME_FILE_UPLOAD_DIMENSION_MIN_WIDTH}x${MEME_FILE_UPLOAD_DIMENSION_MIN_HEIGHT}px`
export const MEME_FILE_UPLOAD_ASPECT_RATIO_MINIMUM = 0.5 // 9/16 = 0.5625
export const MEME_FILE_UPLOAD_ASPECT_RATIO_MAXIMUM = 2.1 // 16/8 = 2
// export const MEME_FILE_UPLOAD_ASPECT_RATIO_MAXIMUM = 1.8 // 16/9 = 0.1778
export const MEME_FILE_UPLOAD_ASPECT_RATIO_ERROR_IMAGE = `Please upload an image with an acceptable aspect ratio. Allowed aspect ratio between ${MEME_FILE_UPLOAD_ASPECT_RATIO_MINIMUM} - ${MEME_FILE_UPLOAD_ASPECT_RATIO_MAXIMUM}`
export const MEME_FILE_UPLOAD_ASPECT_RATIO_ERROR_VIDEO = `Please upload an video with an acceptable aspect ratio. Allowed aspect ratio between ${MEME_FILE_UPLOAD_ASPECT_RATIO_MINIMUM} - ${MEME_FILE_UPLOAD_ASPECT_RATIO_MAXIMUM}`

export const MEME_FILE_UPLOAD_FORMAT_ERROR_MESSAGE_IMAGE = `Format not accepted. Please use a SVG, PNG, WEBP, GIF, JPG or JPEG format`
export const MEME_FILE_UPLOAD_FORMAT_ERROR_MESSAGE_VIDEO = `Format not accepted. Please use a MP4, AVI, MKV, MPEG or MOV format`
export const MEME_FILE_UPLOAD_SIZE_ERROR_MESSAGE_IMAGE = `Size exceeding 10MB, please upload a smaller picture`
export const MEME_FILE_UPLOAD_SIZE_ERROR_MESSAGE_VIDEO = `Size exceeding 10MB, please upload a smaller video`