import { Route, Routes } from "react-router-dom";

import { newsRoutes } from "utils/routes";

const NewsRoutes = (): JSX.Element => {
  return (
    <Routes>
      {newsRoutes.map(({ element: Element, ...routeProps }, index) => (
        <Route key={index.toString()} element={<Element />} {...routeProps} />
      ))}
    </Routes>
  );
};

export default NewsRoutes;
