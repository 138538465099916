import { Box, Typography } from '@material-ui/core'
import { createContext, useReducer } from 'react'
import { Link } from 'react-router-dom'

import { DEFAULT_AVATAR_IMAGE } from 'ymca/services/image.service'
import { Event } from 'ymca/models/event.model'
import { useCountdown } from 'hooks/useCountdown'

import Timer from 'assets/Timer'
import useContestCardStyles from './style'

interface ContextState {
  ongoing: null | number
  upcoming: null | number
  closed: null | number
  getOngoing: (payload: any) => void
  getUpcoming: (payload: any) => void
  getClosed: (payload: any) => void
}
const initState: ContextState = {
  ongoing: null,
  upcoming: null,
  closed: null,
  getOngoing: (payload: any) => {
    return
  },
  getUpcoming: (payload: any) => {
    return
  },
  getClosed: (payload: any) => {
    return
  }
}
const CountContext = createContext<ContextState>(initState)
function reducer(state: ContextState, action: { type: string; payload: any }) {
  switch (action.type) {
    case 'set_value': {
      return { ...state, ongoing: action.payload.ongoing }
    }
    case 'set_upcoming': {
      return { ...state, upcoming: action.payload.upcoming }
    }
    case 'set_closed': {
      return { ...state, closed: action.payload.closed }
    }
    default:
      return state
  }
}
export function CountContextState({ children }: { children: any }) {
  const [state, dispatch] = useReducer(reducer, initState)
  function getOngoing(payload: any) {
    dispatch({ type: 'set_value', payload })
  }
  function getUpcoming(payload: any) {
    dispatch({ type: 'set_upcoming', payload })
  }
  function getClosed(payload: any) {
    dispatch({ type: 'set_closed', payload })
  }
  return (
    <CountContext.Provider
      value={{
        ongoing: state.ongoing,
        upcoming: state.upcoming,
        closed: state.closed,
        getOngoing,
        getUpcoming,
        getClosed
      }}
    >
      {children}
    </CountContext.Provider>
  )
}

const ContestCard = (props: {
  tab: string
  type: 'upcoming' | 'ongoing' | 'completed' | 'reward'
  event: Event
}) => {
  const classes = useContestCardStyles()
  const { event } = props
  const [days, hours, minutes] = useCountdown(
    props.type === 'ongoing' ? event.endingDate : event.startingDate
  )

  let linkRoute = `/contest/${event.id}`

  if (props.type === 'reward') {
    linkRoute = `${linkRoute}/top-entry`
  } else {
    linkRoute = `${linkRoute}/submissions`
  }

  const { eventMetadata } = event ?? {}
  const { contestDetail } = eventMetadata ?? {}
  const { cashTotal = 0, cashUnit, rules } = contestDetail ?? {}

  return (
    <Link className={classes.contestCard} to={linkRoute}>
      <Box>
        <Box
          className={classes.bannerBox}
          style={{
            backgroundImage: `url(${event?.image?.urlOriginal})`
          }}
        >
          {props.type !== 'completed' && props.type !== 'reward' && (
            <Box
              style={{ left: 3, right: 'auto' }}
              className={classes.timerBox}
            >
              <Timer />
              <Typography className={classes.timer}>
                {`${days}d : ${hours}h : ${minutes}m`}
              </Typography>
            </Box>
          )}
          {props.type === 'ongoing' ? (
            <>
              {Number(cashTotal) > 0 && cashUnit && (
                <>
                  <Box className={classes.timerBox}>
                    <Typography className={classes.timer}>
                      {`${cashTotal} ${cashUnit} Prize Pool`}
                    </Typography>
                  </Box>
                </>
              )}
            </>
          ) : (
            props.type === 'upcoming' && (
              <Box className={classes.timerBox}>
                <Timer />
                <Typography className={classes.timer}>
                  {days === '00' && hours === '00' && minutes === '00'
                    ? 'closed'
                    : `${days}d : ${hours}h : ${minutes}m`}
                </Typography>
              </Box>
            )
          )}
          <Box
            className={classes.userThumbnailBox}
            style={{
              backgroundImage: `url(${
                event?.user?.avatar
                  ? event?.user?.avatar?.jpegUrl
                  : DEFAULT_AVATAR_IMAGE
              })`
            }}
          ></Box>
          {/* <img alt="avatar" src={event.user?.avatar ? event.user?.avatar?.urlOptimised200x200 : DEFAULT_AVATAR_IMAGE} crossOrigin={"anonymous"} /> */}
        </Box>
        <Box className={classes.contentBox}>
          <Typography className={classes.contestTitle}>
            {event?.name}
          </Typography>
          <Typography className={classes.contentSubtitle}>
            {rules || event?.description}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.contentInfo}>
        <Link
          style={{
            color: '#FFFFFF',
            background:
              props.type === 'completed'
                ? '#202A41'
                : 'linear-gradient(90deg, #625FFF 0%, #FA00FF 238.69%)'
          }}
          className={classes.contestLink}
          to={linkRoute}
        >
          {props.tab}
        </Link>
      </Box>
    </Link>
  )
}

export default ContestCard
