import { useEffect } from "react";
import {
  connectorChainLocalStorageKey,
  connectorLocalStorageKey,
} from "../config";
import { ConnectorNames } from "../utils/web3/web3React";
import useWeb3 from "./useWeb3";


const _activeChainListener = async () =>
  new Promise<void>((resolve) =>
    Object.defineProperty(window, "ActiveChain", {
      get() {
        return this.network;
      },
      set(network) {
        this.network = network;

        resolve();
      },
    })
  );

const useEagerConnect = () => {
  const { login } = useWeb3();

  useEffect(() => {
    const connectorId = localStorage.getItem(
      connectorLocalStorageKey
    ) as ConnectorNames;
    const chainId = localStorage.getItem(connectorChainLocalStorageKey);

    if (connectorId && chainId) {
      const isConnectorInjected = connectorId === ConnectorNames.Injected;
      const activeChainDefined = Reflect.has(window, "ActiveChain");

      if (isConnectorInjected && !activeChainDefined) {
        _activeChainListener().then(() => login(connectorId, Number(chainId)));
        return;
      }

      login(connectorId, Number(chainId));
    }
  }, [login]);
};

export default useEagerConnect;
