export const randomSearchCheatCode: string = "$random";

export const USER_DATA_LOCALSTORAGE_KEY: string = "user";

export const COMING_FROM_SESSION_STORAGE_KEY: string = "from";

export const UNPUBLISHED_MEME_KEY: string = "unpublished_meme";

export const emptyUsername: string = "Username is empty";

export const wrongFormat: string = "Wrong format";

export const emptyDisplayName: string = "Display name is empty";

export const placeImg: string = "http://placeimg.com/640/480";

export const USERNAME_CHAR_LIMIT: number = 15;

export const ETH_ADDRESS_CHAR_LIMIT: number = 42;

const jwtRegexFunc = (): string | RegExp => {
  return /^([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_\-\+\/=]*)/;
};

export const inputRegex: RegExp = /^\w+$/;

export const ethAddressRegex = /^(0x)?[0-9a-f]{40}$/i;

export const jwtRegex: RegExp = new RegExp(jwtRegexFunc());

export const removeSpacesRegex: RegExp = /\s+/g;

export const removeLineBreaks: RegExp = /\n\s*\n/g;

export const siteRegex: RegExp =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

export const validURLRegex: RegExp = new RegExp(
  "^(https?:\\/\\/)?" +
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
    "((\\d{1,3}\\.){3}\\d{1,3}))" +
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
    "(\\?[;&a-z\\d%_.~+=-]*)?" +
    "(\\#[-a-z\\d_]*)?$",
  "i"
);

export const borderRadius: string = "50%";
export const headerContentHeight: number = 3.75; // rems
