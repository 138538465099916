import PaletteThemeOptions from "theme/shared/palette.interface";
import colors from "./colors";

const palettes: PaletteThemeOptions = {
  primary: {
    main: colors.primary500,
  },
  secondary: {
    main: colors.secondary500,
  },
  error: {
    main: colors.error500,
  },
  background: {
    default: colors.backgroundPrimary,
  },
  grey: {
    300: colors.greyScale300,
    500: colors.greyScale500,
    700: colors.greyScale700,
  },
  text: {
    primary: colors.textPrimary,
    secondary: colors.greyScale500,
  },
  divider: colors.border,

  mode: "dark",
};
export default palettes;
