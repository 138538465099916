import {
  Button,
  Typography,
  Avatar,
  Dialog,
  DialogContent,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import Icon from "components/atoms/Icons";
import useDeleteCommunityModalStyles from "./style";
import { DefaultYMCA } from "ymca/ymca";
import useSnackbar from "hooks/useSnackbar";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDeleteCommunityMutation } from "hooks/api-hooks/useCommunity";
import { redirectLinks } from "constants/redirectLinks";

interface ICommunityDeleteModal {
  isOpen: boolean;
  close: VoidFunction;
  avatarSrc?: string;
  communityId: string;
}
const CommunityDeleteModal = ({
  isOpen,
  close,
  avatarSrc,
  communityId,
}: ICommunityDeleteModal) => {
  const classes = useDeleteCommunityModalStyles();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    isOpen: isCreateSnkBarOpen,
    open: showCreateSnkBar,
    close: closeCreateSnkbar,
  } = useSnackbar();

  const mutation = useDeleteCommunityMutation({
    onError: (props: any) => {
      console.log("onError", { props });
    },
    onSuccess: (props: any) => {
      // invalidate the client query cache
      queryClient.invalidateQueries(["communities", { id: communityId }]);

      showCreateSnkBar();

      // redirect to managing page
      navigate(`/${redirectLinks.communities}/managed`);

      // close modal
      close();
    },
  });

  const handleDelete = () => {
    mutation.mutate({
      id: communityId,
    });
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={close}
        aria-labelledby='leave-community'
        className={classes.dialog}
      >
        <DialogContent className={classes.dialogContent}>
          <Box display='flex' flexDirection='column' alignItems='flex-end'>
            <Icon icon='community-delete' fontSize='7.25rem' color='#E7312D' />

            <Avatar className={classes.dialogContentAvatar} src={avatarSrc} />
          </Box>

          <Box display='flex' flexDirection='column'>
            <Typography className={classes.dialogContentTitle}>
              Delete Spiderverse?
            </Typography>
            <Typography className={classes.dialogContentInfo}>
              You are about to delete your community. This action is non
              revertible.
            </Typography>
          </Box>
          <Box className={classes.dialogActions}>
            <Button
              className={classes.dialogContentActionsDeleteBtn}
              onClick={handleDelete}
            >
              Delete Community
            </Button>
            <Button
              className={classes.dialogContentActionsCancelBtn}
              onClick={close}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isCreateSnkBarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={closeCreateSnkbar}
      >
        <Alert>Community Deleted Successfully</Alert>
      </Snackbar>
    </>
  );
};

export default CommunityDeleteModal;
