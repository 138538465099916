import { Box, Button, Divider, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import RedditIcon from '@material-ui/icons/Reddit'
import { useState } from 'react'
import { Image } from 'components/shared/index'
import { getUserFromLocalStorage } from 'services/localStorageService'
import { USERNAME_CHAR_LIMIT } from 'constants/variables'
import { imagesSvg } from 'constants/images'
import { DefaultYMCA } from 'ymca/ymca'
import colors from 'theme/dark/colors'

const useStyles = makeStyles({
  item: {
    display: 'flex',
    // padding: '6px 0 6px 10px',
    // margin: '0 0 0 10px'
  },
  content: {
    marginTop: '35px'
  },
  itemtitle: {
    color: '#E2E4E9',
    width: '33%',
    fontWeight: 900,
    fontSize: '26px',
    lineHeight: '31.47px'
  },
  subtitle: {
    fontWeight: 900,
    fontSize: '16px',
    lineHeight: '19.36px',
    color: '#E2E4E9'
  },
  passwordsubtitle: {
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: '19.36px',
    color: '#C2C9D4'
  },
  itemcontent: {
    font: 'normal normal normal 12px/31px Gotham Rounded',
    lineHeight: '27px',
    color: '#808080',
    width: '60%'
  },
  itemimage: {
    minWidth: '30px'
  },
  dbcolor: {
    color: '#1A0030',
    fontWeight: 'bold'
  },
  description: {
    color: '#808893',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    marginTop: '18px'
  },
  form: {
    marginTop: '35px',
    flexGrow: 1
  },
  formLabel: {
    color: '#808893',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16.94px'
  },
  mainForm: {
    marginTop: '10px',
    width: '100%',
    position: 'relative',
    '& input': {
      boxSizing: 'border-box',
      width: '100%',
      backgroundColor: colors.darkBlue50,
      border: '0',
      height: '40px',
      borderRadius: '5px',
      padding: '12px 15px',
      color: '#808893',
      fontWeight: '700',
      fontSize: '13px',
      lineHeight: '15.73px',
      '&:focus': {
        color: 'white'
      }
    },
    '& input::placeholder': {
      color: '#6C7B93'
    }
  },
  memerTag: {
    backgroundColor: 'red'
  },
  tor: {
    display: 'flex',
    justifyContent: 'center',
    '& input::placeholder': {
      color: '#2F3948'
    }
  },
  memerTagButton: {
    position: 'absolute',
    right: '3px',
    height: '30px',
    width: '30px',
    boxSizing: 'border-box',
    padding: '12.99px 9.5px',
    backgroundColor: '#181B22',
    justifyContent: 'center',
    color: '#6C7B93',
    '&:hover': {
      backgroundColor: '#252A3A'
    }
  },
  emailForm: {
    backgroundColor: '#252A3A',
    borderRadius: '5px'
  },
  emailFormButton: {
    width: '65px',
    height: '34px',
    backgroundColor: '#1D232B',
    position: 'absolute',
    right: '3px'
  },
  showEmailButton: {
    fontSize: '12px',
    fontWeight: 700,
    color: '#808893'
  },
  changePasswordButtonContainer: {
    marginTop: '30px'
  },
  changePassword: {
    background: 'linear-gradient(to right, rgba(53, 166, 235, 1),rgba(112, 117, 255, 1))',
    padding: '12px 20px',
    borderRadius: '7px',
    height: '46px',
    width: '172px',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: '#884BFF'
    }
  },
  changePasswordText: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16.94px',
    color: '#FFFFFF'
  },
  socailMediaIntegrationContent: {
    marginTop: '18px'
  },
  connectTitle: {
    fontWeight: 900,
    fontSize: '14px',
    color: '#E2E4E9',
    lineHeight: '16.94px',
    textTransform: 'capitalize'
  },
  connectSubTitle: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#808893',
    marginTop: '12px',
    lineHeight: '16.94px'
  },
  connectPasswordButton: {
    backgroundColor: colors.darkBlue50,
    display: 'flex',
    height: 44,
    width: 199,
    borderRadius: '5px',
    alignItems: 'center',
    padding: '0px 16px',
    border: 'none',
    filter: 'grayscale(1)',
    pointerEvents: 'none',
    '&:hover': {
      backgroundColor: colors.darkBlue50
    }
  },
  connectPasswordButtonText: {
    color: '#E2E4E9',
    fontSize: '13px',
    fontWeight: 700,
    lineHeight: '16.94px',
    marginLeft: 6
  },
  connectBox: {
    margin: '23px 0'
  },
  dangerZoneTitle: {
    color: '#E74C4A',
    fontWeight: 800,
    fontSize: '15px',
    lineHeight: '19.36px'
  },
  dangerZoneSubTitle: {
    color: '#808893',
    fontWeight: 500,
    fontSize: '14px',
    marginTop: '18px'
  },
  dangerButton: {
    marginTop: '30px',
    backgroundColor: '#E74C4A',
    borderRadius: '5px',
    width: '176px',
    height: '40px',
    '&:hover': { backgroundColor: '#E74C4A' }
  },
  dangerButtonText: {
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16.94px'
  },
  connectFacebookButton: {
    backgroundColor: colors.darkBlue50,
    border: '0',
    padding: '12px 15px',
    display: 'flex',
    alignItems: 'center',
    height: 44,
    width: '100%',
    borderRadius: '5px',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: colors.darkBlue50
    }
  },
  connectItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  connectAccount: {
    borderRadius: 5,
    padding: 10,
    backgroundColor: colors.darkBlue50,
    margin: '5px 0'
  },
  accountData: {
    display: 'flex',
    alignItems: 'center'
  },
  nameTxt: {
    color: '#C2C9D4',
    marginLeft: 10
  },
  socialName: {
    color: '#C2C9D4',
    fontSize: 14,
    marginLeft: 10
  },
  closeBtn: {
    fontSize: 24,
    color: '#C2C9D4'
  },
  inputContainer: {
    margin: '20px 0',
    color: 'white',
    width: '100%'
  },
  pwdChangeContainer: {
    width: '100%',
    alignItems: 'flex-start'
  },
  errMsg: {
    color: 'red'
  }
})

const GeneralSetting = (): JSX.Element => {
  const classes = useStyles()

  const localData: any = getUserFromLocalStorage(true)

  const [showEmail, setShowEmail] = useState<boolean>(false)
  const [connectSocial, setSocial] = useState<{
    fb: boolean
    reddit: boolean
    twitter: boolean
  }>({
    fb: true,
    reddit: true,
    twitter: true
  })
  const [username, setUsername] = useState<string>(localData.userData.username)
  const [displayName, setDisplayName] = useState<string>(localData.userData.displayName)
  const [email, setEmail] = useState<string>(localData.userData.email ?? '*')

  const update = async (): Promise<void> => {
    await DefaultYMCA.selfService.updateSelf({
      displayName,
      username,
    })
  }

  return (
    <Box style={{ padding: '45px 0' }}>
      <Box className={classes.item} style={{ zIndex: -99999, flexDirection: 'column' }}>
        <Typography className={classes.itemtitle}>Profile Settings</Typography>
        <Typography className={classes.description}>
          Manage your account. In the profile settings, you will be able to change your personal
          information, connect with various other platforms and terminate your account
        </Typography>
        <Divider style={{ marginTop: '25px', backgroundColor: '#2B363E' }} />
        <Box className={classes.content}>
          <Box>
            <Typography className={classes.subtitle}>PERSONAL INFORMATION</Typography>
          </Box>
          <Box className={classes.form}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography className={classes.formLabel}>Display Name</Typography>
                <form className={classes.mainForm} noValidate autoComplete='off'>
                  <input
                    type='text'
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                    onBlur={update}
                    maxLength={USERNAME_CHAR_LIMIT}
                    readOnly
                  />
                </form>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.formLabel}>Username</Typography>
                <form className={classes.mainForm} noValidate autoComplete='off'>
                  <input
                    type='text'
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onBlur={update}
                    maxLength={USERNAME_CHAR_LIMIT}
                    readOnly
                  />
                </form>
              </Grid>

              <Grid item xs={12} style={{ marginTop: '19px' }}>
                <Typography className={classes.formLabel}>Email</Typography>
                <form
                  className={`${classes.mainForm} ${classes.emailForm}`}
                  noValidate
                  autoComplete='off'
                  style={{ width: '100%' }}
                >
                  <input
                    type='text'
                    placeholder={
                      showEmail
                        ? email
                        : email[0].concat('************@gmail.com')
                    }
                    disabled
                  />
                  <Button
                    className={classes.emailFormButton}
                    disableRipple
                    onClick={() => setShowEmail((prev) => !prev)}
                  >
                    <Typography className={classes.showEmailButton}>
                      {showEmail ? 'hide' : 'show'}
                    </Typography>
                  </Button>
                </form>
              </Grid>
            </Grid>
          </Box>

          <Divider style={{ margin: '35px 0', backgroundColor: colors.border }} />

          <Typography className={classes.subtitle}>SOCIAL MEDIA INTEGRATION</Typography>
          <Box className={classes.socailMediaIntegrationContent}>
            <Typography className={classes.description}>
              You have the option logging into YouMeme using your other social media accounts, that
              way everything is easier. Below, you can choose the one that fits you better!
            </Typography>
            <Box className={classes.content}>
              <Box className={classes.connectBox}>
                <Box className={classes.connectItem}>
                  <Box>
                    <Typography className={classes.connectTitle}>Connect with Reddit</Typography>
                    <Typography className={classes.connectSubTitle}>
                      Connect to YouMeme using your Reddit account
                    </Typography>
                  </Box>
                  <Box>
                    <Button variant='contained' className={classes.connectPasswordButton}>
                      <RedditIcon style={{ color: '#FF4500' }} />
                      <Typography className={classes.connectPasswordButtonText}>
                        <Typography
                          className={classes.connectPasswordButtonText}
                          style={{ color: 'gray' }}
                        >
                          {connectSocial.reddit ? 'Connect ' : 'Disconnect '}
                          Reddit
                        </Typography>
                      </Typography>
                    </Button>
                  </Box>
                </Box>
                {connectSocial.reddit && (
                  <Box
                    className={`${classes.connectItem} ${classes.connectAccount}`}
                    style={{ display: 'none' }}
                  >
                    <Box className={classes.accountData}>
                      <RedditIcon style={{ color: '#FF4500' }} />
                      <Box>
                        <Typography className={classes.nameTxt}>Ming Wang</Typography>
                        <Typography className={classes.socialName}>Reddit</Typography>
                      </Box>
                    </Box>
                    <Button
                      className={classes.closeBtn}
                      onClick={() => setSocial({ ...connectSocial, reddit: false })}
                    >
                      &times;
                    </Button>
                  </Box>
                )}
              </Box>
              <Divider style={{ margin: '20px 0', backgroundColor: '#2B363E' }} />
              <Box className={classes.connectBox}>
                <Box className={classes.connectItem}>
                  <Box>
                    <Typography className={classes.connectTitle}>Connect with Facebook</Typography>
                    <Typography className={classes.connectSubTitle}>
                      Connect to YouMeme using your Facebook account
                    </Typography>
                  </Box>
                  <Box>
                    <Button variant='contained' className={classes.connectPasswordButton}>
                      <Image src={`shareIcons/${imagesSvg.facebookConnect}`} alt='facebook' />
                      <Typography className={classes.connectPasswordButtonText}>
                        <Typography
                          className={classes.connectPasswordButtonText}
                          style={{ color: 'gray' }}
                        >
                          Connect Facebook
                        </Typography>
                      </Typography>
                    </Button>
                  </Box>
                </Box>

                <Box />
                {connectSocial.fb && (
                  <Box
                    className={`${classes.connectItem} ${classes.connectAccount}`}
                    style={{ display: 'none' }}
                  >
                    <Box className={classes.accountData}>
                      <Image alt="facebook connect" src={`shareIcons/${imagesSvg.facebookConnect}`} />
                      <Box>
                        <Typography className={classes.socialName}>Facebook</Typography>
                      </Box>
                    </Box>
                    <Button
                      className={classes.closeBtn}
                      onClick={() => setSocial({ ...connectSocial, fb: false })}
                    >
                      &times;
                    </Button>
                  </Box>
                )}
              </Box>
              <Divider style={{ margin: '20px 0', backgroundColor: colors.border }} />
              <Box className={classes.connectBox}>
                <Box className={classes.connectItem}>
                  <Box>
                    <Typography className={classes.connectTitle}>Connect with Twitter</Typography>
                    <Typography className={classes.connectSubTitle}>
                      Connect to YouMeme using your Twitter account
                    </Typography>
                  </Box>
                  <Box>
                    <Button variant='contained' className={classes.connectPasswordButton}>
                      <Image src={`connectIcons/${imagesSvg.twitterConnect}`} alt='twitter connect icon' />
                      <Typography
                        className={classes.connectPasswordButtonText}
                        style={{ color: 'gray' }}
                      >
                        {!connectSocial.twitter ? 'Disconnect' : 'Connect'} Twitter
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Divider
                style={{
                  margin: '35px 0',
                  backgroundColor: colors.border,
                  width: '100%'
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default GeneralSetting
