const rpcUrls: Record<number, string> = {
  137: "https://polygon-rpc.com/",
  80001: "https://rpc-mumbai.maticvigil.com/",
};

export const getRpcUrls = () => {
  return rpcUrls;
};

export const getRpcUrl = (chainId: number): string | undefined => {
  return rpcUrls[chainId];
};
