import { makeStyles } from "@material-ui/core/styles";
import { BREAKPOINT_TABLET_MEDIUM } from "theme/shared/breakpoint";

const useCommunityKebabMenuDropdown = makeStyles({
  KebabMenuBtn: {
    borderRadius: "6.25rem",
    background: "#33405C",
    boxShadow: " 0px 4px 8px 0px rgba(14, 20, 34, 0.20)",

    "&.MuiButton-root": {
      padding: "0",
      minWidth: "0",
      width: "2.5rem",
      maxWidth: "2.5rem",
      height: "2.5rem",
    },
  },
  kebabMenuMenu: {
    "& .MuiPaper-root": {
      "border-radius": "0.75rem",
      background: "#33405C",
      "box-shadow": " 0px 4px 46px 0px rgba(14, 20, 34, 0.50)",
    },
    "& .MuiList-root": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    display: "block",

    [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
      display: "none",
    },
  },
  kebabMenuMenuBorderBottom: {
    borderBottom: "1px solid rgba(138, 152, 180, 0.30);",
  },
  kebabMenuMainMenuItem: {
    display: "flex",
    alignItems: "center",
    width: "100%",

    padding: ".5rem",
  },
  kebabMenuMainMenuItemContent: {
    display: "flex",
    alignItems: "center",
    "border-radius": "0.75rem",
    background: "#202A41",
    "box-shadow": "0px 4px 8px 0px rgba(14, 20, 34, 0.20)",

    padding: ".75rem 1rem",
    width: "100%",

    gap: ".5rem",
  },
  kebabMenuSecondaryMenuItem: {
    display: "flex",
    justifyContent: "flex-start",
    gap: ".75rem",
    padding: ".75rem 1.5rem",
    width: "100%",
  },
  kebabMenuMenuCopy: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",

    padding: ".5rem .5rem",

    gap: ".5rem",
  },
  kebabMenuMenuCopyTitle: {
    color: "#E9F0F9",
    "font-family": "Inter",
    "font-size": "0.875rem",
    "font-style": "normal",
    "font-weight": 700,
    "line-height": "normal",
    "letter-spacing": "-0.03125rem",

    paddingLeft: ".75rem",
  },
  kebabMenuMenuCopyLinkMessage: {
    color: "#8A98B4",
    "font-family": "Inter",
    "font-size": "0.8125rem",
    "font-style": "normal",
    "font-weight": 400,
    "line-height": "normal",
    "letter-spacing": "-0.03125rem",

    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 180,
  },
  kebabMenuSecondaryMenuText: {
    color: "#8A98B4",
    "font-family": "Inter",
    "font-size": "0.875rem",
    "font-style": "normal",
    "font-weight": 600,
    "line-height": "normal",
    "letter-spacing": "-0.03125rem",
  },
  kebabMenuMainMenuText: {
    color: "#E9F0F9",
    "font-family": "Inter",
    "font-size": "0.875rem",
    "font-style": "normal",
    "font-weight": 600,
    "line-height": "normal",
    "letter-spacing": "-0.03125rem",
  },

  kebabMenuDropdownDrawer: {
    display: "none",

    "& .MuiPaper-root": {
      borderRadius: "0.9375rem",
      background: "rgba(32, 43, 66, 0.94)",
      boxShadow: "0px -13px 24px 0px rgba(0, 0, 0, 0.50)",

      padding: "1rem 2rem",
    },

    [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
      display: "block",
    },
  },
  kebabMenuDropdownDrawerTitle: {
    color: "#E9F0F9",
    fontFamily: "Inter",
    fontSize: "1.125rem",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "normal",
    letterSpacing: "-0.03125rem",
  },

  kebabMenuDropdownDrawerListItemText: {
    "& .MuiTypography-body1": {
      color: "#E9F0F9",
      fontFamily: "Inter",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      letterSpacing: "-0.03125rem",
    },
  },

  kebabMenuDropdownDrawerListItemWrapper: {
    // padding: ".rem 0",

    "& .MuiButtonBase-root": {
      padding: ".5rem 0",
    },
  },
});

export default useCommunityKebabMenuDropdown;
