import type { ReactElement } from 'react'
import type { ComponentWithChildrenPropsOnly } from 'types/runtimeSchema'
import Loading from 'components/loading/Loading'
import { useAuth } from 'context/auth/authContext'

const FallbackLoader = ({ children }: ComponentWithChildrenPropsOnly): ReactElement => {
  const { isAuthenticated } = useAuth()
  return (
    (isAuthenticated === undefined) ? <Loading /> : children
  )
}
export default FallbackLoader;