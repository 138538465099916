import { useQuery } from "@tanstack/react-query";
import usePrevious from "hooks/usePrevious";
import { useRef, useState } from "react";
import { MemeTemplateListDto } from "ymca/dtos/meme-template.dto";
import { DefaultYMCA } from "ymca/ymca";

interface IUseGetTemplates {}

export const useGetTemplates = (props: IUseGetTemplates) => {
  const generatorRef = useRef<any>()
  const previousData = useRef<any[]>([])
  const hasNextPageRef = useRef<any>(true)
  const totalItemReturnRef = useRef<any>(0)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState<string | undefined>(undefined)
  const previousPage = usePrevious(page)

  const resetPage = () => {
    generatorRef.current = null
    previousData.current = []
    hasNextPageRef.current = true
    totalItemReturnRef.current = 0
    setPage(0)
    setSearch(undefined)
  }

  const getData = async (props: any) => {
    if (page !== previousPage) {
      let generator = undefined
      if (!generatorRef.current) {
        let dto = new MemeTemplateListDto()

        if (search) {
          dto.search = search
        }
        generator = await DefaultYMCA.memeTemplateService.getTemplatesByDTO(dto)
        generatorRef.current = await generator
      }

      const data = await generatorRef.current.next()
      const { value } = data

      let newData = value?.data

      if (!newData || !Array.isArray(newData)) {
        newData = []
      }

      totalItemReturnRef.current += newData.length
      if (value?.count > totalItemReturnRef.current) {
        hasNextPageRef.current = true
      } else {
        hasNextPageRef.current = false
      }

      if (previousData.current.length > 0) {
        previousData.current = [...previousData.current, ...newData]
      } else {
        previousData.current = newData
      }
    }

    return {
      data: previousData.current,
      hasMore: hasNextPageRef.current
    }
  }
  const queryKey: any[] = ['templates', page]

  if (search) {
    queryKey.push(search)
  }

  const query = useQuery({
    queryKey,
    queryFn: getData,
    keepPreviousData: true
  })

  return {
    ...query,
    fetchNextPage: () => {
      setPage((prev) => ++prev)
    },
    searchTemplate: (search: string | undefined) => {
      resetPage()
      setSearch(search)
    },
    resetPage
  }
}

export const useGetHasFaceTemplatesQuery = (props: IUseGetTemplates) => {
  const generatorRef = useRef<any>()
  const previousData = useRef<any[]>([])
  const hasNextPageRef = useRef<any>(true)
  const totalItemReturnRef = useRef<any>(0)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState<string | undefined>(undefined)
  const previousPage = usePrevious(page)

  const resetPage = () => {
    generatorRef.current = null
    previousData.current = []
    hasNextPageRef.current = true
    totalItemReturnRef.current = 0
    setPage(0)
    setSearch(undefined)
  }

  const getData = async (props: any) => {
    if (page !== previousPage) {
      let generator = undefined
      if (!generatorRef.current) {
        let dto = new MemeTemplateListDto()
        dto.onlyFaces = true
        if (search) {
          dto.search = search
        }

        generator = await DefaultYMCA.memeTemplateService.getTemplatesByDTO(dto)
        generatorRef.current = await generator
      }

      const data = await generatorRef.current.next()
      const { value } = data

      let newData = value?.data

      if (!newData || !Array.isArray(newData)) {
        newData = []
      }

      totalItemReturnRef.current += newData.length
      if (value?.count > totalItemReturnRef.current) {
        hasNextPageRef.current = true
      } else {
        hasNextPageRef.current = false
      }

      if (previousData.current.length > 0) {
        previousData.current = [...previousData.current, ...newData]
      } else {
        previousData.current = newData
      }
    }

    return {
      data: previousData.current,
      hasMore: hasNextPageRef.current
    }
  }
  const queryKey: any[] = ['templates', page, 'onlyFaces']

  if (search) {
    queryKey.push(search)
  }

  const query = useQuery({
    queryKey,
    queryFn: getData,
    keepPreviousData: true
  })

  return {
    ...query,
    fetchNextPage: () => {
      setPage((prev) => ++prev)
    },
    searchTemplate: (search: string | undefined) => {
      resetPage()
      setSearch(search)
    },
    resetPage
  }
}
