import { Post as PostModel } from "ymca/models/post.model";
import { styled } from "@mui/material";
//import Typography from "components/atoms/Typography";
import {Typography} from "@mui/material";
import { BREAKPOINT_MOBILE_LARGE } from "theme/shared/breakpoint";
import isTitleAutoGenerated from 'utils/isTitleAutoGenerated'

const StyledTypography = styled(Typography, {
  name: 'PublicationTitleTypography'
})`
  &.MuiTypography-body1 {
    font-weight: 800;
    font-size: 1rem!important;
    overflow-wrap: anywhere;
    max-width: 100%;

    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media (max-width: ${BREAKPOINT_MOBILE_LARGE}px) {
      font-size: .9rem
    },
  }
`

const PublicationContentTitleWrapper = styled('section', {
  name: 'PublicationContentTitleWrapper'
})(() => {
  return {
    gridArea: 'publicationContentTitle'
  }
})

export type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export interface PublicationContentProp {
  post: PostModel
  headingType?: HeadingType
}

/**
 * PublicationContentTitle, accepts a post object and renders the title of the post.
 * The headingType prop is used to render the title in the DOM, for SEO purposes.
 * For example, on the homepage, since there are many posts, the heading
 * cannot be h1, so it has to be specified as h2 or h3.
 * On the post page, the headingType is h1.
 *
 * @param post - post object
 * @param headingType - heading type to render in the DOM, for SEO purposes.
 * @constructor
 */
const PublicationContentTitle = ({
  post,
  headingType
}: PublicationContentProp): JSX.Element => {
  if (!post.title) return <></>
  if (!headingType) headingType = 'h2'

  // validate the post content
  // check if title starts & end w/ -#-
  const startAndEndWithHyphenHashHyphen = isTitleAutoGenerated(post.title)
  if (startAndEndWithHyphenHashHyphen) {
    return (
      <div style={{ position: 'absolute', left: '-9999px' }}>
        <StyledTypography
          variant={'body1'}
          variantMapping={{ body1: headingType }}
        >
          {post.title}
        </StyledTypography>
      </div>
    )
  }

  return (
    <PublicationContentTitleWrapper>
      <StyledTypography
        variant={'body1'}
        variantMapping={{ body1: headingType }}
      >
        {/* -#- Meme News & Editor: Latest Templates & Collection! |
        uuid:677f67f2-9256-41b6-a4ee-09a1e96deba7 -#- -#- Meme News & Editor:
        Latest Templates & Collection! |
        uuid:677f67f2-9256-41b6-a4ee-09a1e96deba7 -#- -#- Meme News & Editor:
        Latest Templates & Collection! |
        uuid:677f67f2-9256-41b6-a4ee-09a1e96deba7 -#- */}
        {post.title}
      </StyledTypography>
    </PublicationContentTitleWrapper>
  )
}

export default PublicationContentTitle;
