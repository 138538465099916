import Switch from '@material-ui/core/Switch'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    width: (props) => props.width,
    height: (props) => props.height,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    padding: 2,
    color: (props) => props.thumbColor ?? '#6E7989',
    '&$checked': {
      transform: (props) => `translateX(${props.transform ?? 0}px)`,
      color: (props) => props.thumbColor ?? 'var(--gradient-fall-back)',
      '& + $track': {
        opacity: 1,
        backgroundColor: (props) => props.checkColor ?? '#252A32'
      }
    }
  },
  thumb: {
    width: (props: SwitchButtonProps) => props.thumb,
    height: (props) => props.thumb,
    boxShadow: 'none'
  },
  track: {
    borderRadius: 100,
    opacity: 1,
    backgroundColor: (props) => props.trackColor ?? '#252A32'
  },
  checked: {
    transform: (props: SwitchButtonProps) => `translateX(${props.transform ?? 0}px)`
  }
})

interface SwitchButtonProps {
  width?: number
  height?: number
  thumb?: number
  transform?: number
  trackColor?: string
  checkColor?: string
  thumbColor?: string
}

const SwitchButton = (props: SwitchButtonProps): JSX.Element => {
  const classes = useStyles(props)

  return (
    <Switch
      disabled
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
    />
  )
}

export default SwitchButton
