import { makeStyles } from "@material-ui/core/styles";
import colors from "theme/dark/colors";

const useAuthPopoverStyles = makeStyles({
  popoverContainer: {
    padding: "1.5rem",
    backgroundColor: colors.backgroundPrimary,
  },
});

export default useAuthPopoverStyles;
