import { useEffect, useState } from "react";
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { styled } from "@mui/material";
import { useAuth } from "context/auth/authContext";
import {
  connectorChainLocalStorageKey,
  connectorLocalStorageKey,
} from "config";
import { ConnectorNames, connectorsByName } from "utils/web3/web3React";
import { DefaultYMCA } from "ymca/ymca";
import { TOKEN_SOURCE_LENS } from "ymca/dtos/auth.dto";
import { completeLogin } from "services/auth/auth";
import { User } from "types/runtimeSchema";
import { redirectLinks } from 'constants/redirectLinks'
import useWeb3 from "hooks/useWeb3";
import useWindowSize from "hooks/useWindowSize";
import LensModal from "components/modals/LensModal";
import LensMobileModal from "components/modals/LensMobileModal";
import { Image } from "components/shared";
import Typography from "components/atoms/Typography";
import { setupNetwork } from "utils/web3/wallet";

export interface LensLoginPropsInterface {
  onLogin?: (ethAddress: string) => void;
}

const LensLoginWrapper = styled("div")({
  marginTop: "14px",
  opacity: 1,

  "& picture": {
    display: "flex",
    alignItems: "center",
  },
});

const LensLoginTypography = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-body1": {
    fontSize: "1rem",
    fontWeight: 700,
    marginLeft: "10px",
  },
}));

export const LensLogin = ({ onLogin }: LensLoginPropsInterface): JSX.Element => {
  const navigate = useNavigate()
  const { authService } = useAuth()
  const { login, logout } = useWeb3()
  const { active, account, library } = useWeb3React()
  const currentURL = new URL(window.location.href)
  const referrerId = currentURL.searchParams.get('r') ?? undefined
  const [openWalletModal, setOpenWalletModal] = useState(false)
  const connectChainId = DefaultYMCA.lensService.lensChainId
  const isMobile = useWindowSize().width <= 760

  const handleLensLogin = async (wallet: string) => {
    localStorage.setItem(
      connectorLocalStorageKey,
      wallet === "metamask" ? ConnectorNames.Injected : ConnectorNames.WalletConnect
    );
    localStorage.setItem(
      connectorChainLocalStorageKey,
      connectChainId.toString()
    );
    await login(wallet === "metamask" ? ConnectorNames.Injected : ConnectorNames.WalletConnect, connectChainId);
    const connector = connectorsByName[ConnectorNames.Injected];
    const provider = await connector.getProvider();
    await setupNetwork(connectChainId, provider);
  }

  useEffect(() => {
    if (account && active) {
      DefaultYMCA.lensService
        .authenticateWithSigner(library.getSigner(), account)
        .then(async (response) => {
          authService.state.context.loginSystemRef?.send({
            type: 'EXTERNAL_TOKEN'
          })
          try {
            const userInfoWithToken = await toast.promise<User>(
              completeLogin(response, TOKEN_SOURCE_LENS, referrerId),
              {
                loading: 'Logging you in...',
                success: ({ userData }) => `Welcome ${userData.displayName}`,
                error: (error) => {
                  if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                  ) {
                    return error.response.data.message
                  } else {
                    return error.message
                  }
                }
              },
              {
                style: {
                  minWidth: '280px'
                }
              }
            )
            const timerId: NodeJS.Timeout = setTimeout(() => {
              authService.state.context.loginSystemRef?.send({
                type: 'COMPLETE_AUTHENTICATION',
                userInfoWithToken
              })
              if (onLogin) {
                const registeredEthAddress =
                  userInfoWithToken.userData.ethAddress ?? ''
                onLogin(registeredEthAddress)
              }
              clearTimeout(timerId)
              setOpenWalletModal(false)
            }, 800)
          } catch (error: any) {
            setOpenWalletModal(false)
            logout()
            const timerId: NodeJS.Timeout = setTimeout(() => {
              navigate(`/${redirectLinks.auth}`)
              authService.state.context.loginSystemRef?.send({
                type: 'RETRY_AUTHENTICATION'
              })
              clearTimeout(timerId)
            }, 800)
          }
        })
        .catch((error) => {
          toast.error(error.message)
          setOpenWalletModal(false)
          logout()
        })
    } else {
      console.error("No account found");
    }
  }, [active, account])

  const handleLensModalClose = (event: any, reason?: any) => {
    if (reason && reason === 'backdropClick') return;
    setOpenWalletModal(false);
  }

  return (
    <>
      <LensLoginWrapper
        className="customLens"
        onClick={() => setOpenWalletModal(true)}
      >
        <Image src="/assets/shareIcons/lensprotocol.svg" alt='Lens Protocol ' />
        <LensLoginTypography>
          Continue with Lens
        </LensLoginTypography>
      </LensLoginWrapper>
      {
        isMobile
          ?
          <LensMobileModal
            open={openWalletModal}
            handleClose={(event, reason) => { handleLensModalClose(event, reason) }}
            connect={(wallet) => handleLensLogin(wallet)}
          />
          :
          <LensModal
            open={openWalletModal}
            handleClose={(event, reason) => { handleLensModalClose(event, reason) }}
            connect={(wallet) => handleLensLogin(wallet)}
          />
      }
    </>
  )
}
