import { useState } from "react";
import { Box, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import UnfollowModal from "components/modals/UnfollowModal";
import { Verified } from "assets/Verified";
import { DefaultYMCA } from "ymca/ymca";
import { PublicUser } from "ymca/models/user.model";
import { DEFAULT_AVATAR_IMAGE } from "ymca/services/image.service";
import { capitalizeFirstLetter } from 'utils/formatString'
import { Snackbar, Typography } from '@mui/material'
import Alert from 'components/atoms/Alert'
import useRightSidebarMemersStyles from './style'
import Avatar from 'components/molecules/Avatar'
import getImageResolutions, {
  getImageUrlSourceType
} from 'utils/getImageResolutions'

export interface MemersProps {
  data: PublicUser
}

type FollowButtonState = 'Follow' | 'Unfollow' | 'Followed'

const RightSidebarMemers = ({ data }: MemersProps): JSX.Element => {
  const classes = useRightSidebarMemersStyles()
  const [followState, setFollowState] = useState<boolean>(false) // the same as data.isUserFollowed
  const [buttonText, setButtonText] = useState<FollowButtonState>('Follow') // can also be "Unfollow" or "Followed" depending on context
  const [snackbarOpen, setSnackbar] = useState<boolean>(false)
  const [openUnfollowModal, setOpenUnfollowModal] = useState(false)

  const handleCreateFollow = (): void => {
    DefaultYMCA.followService.createFollow(data.id).then((res) => {
      if (res) {
        setFollowState(true)
        setButtonText('Followed')
      } else {
        setSnackbar(true)
      }
    })
  }

  const handleRemoveFollow = (): void => {
    DefaultYMCA.followService.deleteFollow(data.id).then((res) => {
      if (res) {
        setOpenUnfollowModal(false)
        setFollowState(false)
        setButtonText('Follow')
      } else {
        setSnackbar(true)
      }
    })
  }

  const handleClose = () => setOpenUnfollowModal(false)

  const handleSnackBarClose = () => {
    setSnackbar(false)
  }

  let fallback = DEFAULT_AVATAR_IMAGE
  let pictureSources
  if (data?.avatar) {
    const {
      x2,
      x3,
      fallback: fallBackImage
    } = getImageResolutions(data?.avatar)

    fallback = fallBackImage
    pictureSources = [
      {
        srcSet: `${x2} 1x, ${x3} 2x`,
        type: getImageUrlSourceType(x3)
      }
    ]
  }

  return (
    <Box className={classes.container} data-test-memer={data.id}>
      <Link to={`/profile/${data.username}`} className={classes.dataContainer}>
        <Box className={classes.imageBox}>
          <Avatar
            src={fallback ?? DEFAULT_AVATAR_IMAGE}
            pictureSources={pictureSources}
            alt='popular'
          />
        </Box>
        <Box className={classes.memecontent}>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '.25rem'
            }}
          >
            <Typography
              className={classes.popularName}
              data-test-memer-display-name={data.id}
            >
              {capitalizeFirstLetter(data.displayName)}
            </Typography>
            <Verified />
          </Box>
          <Typography
            variant='body2'
            className={classes.popularDetails}
            data-test-memer-user-name={data.id}
          >
            @{data.username}
          </Typography>{' '}
        </Box>
      </Link>
      <Button
        className={
          buttonText === 'Followed'
            ? classes.followingBtn
            : buttonText === 'Unfollow'
            ? classes.unfollowButton
            : classes.followBtn
        }
        onMouseOver={(): void => {
          if (followState) {
            setButtonText('Unfollow')
          }
        }}
        onMouseLeave={(): void => {
          if (followState) {
            setButtonText('Followed')
          }
        }}
        onClick={async () => {
          if (followState) {
            setOpenUnfollowModal(true)
          } else {
            await handleCreateFollow()
          }
        }}
        data-test-memer-follow-button={data.id}
      >
        {buttonText}
      </Button>

      <UnfollowModal
        open={openUnfollowModal}
        handleClose={handleClose}
        displayName={data.displayName}
        handleRemoveFollow={handleRemoveFollow}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      >
        <Alert severity='error'>
          Requested action could not be fulfilled, please try again
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default RightSidebarMemers;
