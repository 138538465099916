import type { TwitterVerifierToken, TwitterOAuthV1Token } from 'ymca/models/twitter.model';
import { BaseService } from './base.service';
import { GetTwitterAuthUrlDto, VerifyTwitterAuthDto } from 'ymca/dtos/twitter.dto';
import config from 'utils/config';

export class TwitterService extends BaseService {
  public async getAuthUrl(callbackUrl?: string): Promise<TwitterVerifierToken | undefined> {
    const defaultCallbackUrl = `${config.deploymentUrl}/twitter-login`;
    const dto = new GetTwitterAuthUrlDto(callbackUrl ?? defaultCallbackUrl);
    const response = await this.common.jsonFetcher.fetchJSON<TwitterVerifierToken>(
      'GET',
      '/api/twitter/auth-url',
      dto,
    );
    if (!response.data) return;

    // TODO: This step should be done in the React component later when we have one
    localStorage.setItem('oauthTokenSecret', response.data.oauth_token_secret);

    return response.data;
  }

  public async verifyTwitterAuth(
    oauthToken: string, 
    oauthTokenSecret: string, 
    oauthVerifier: string
  ): Promise<TwitterOAuthV1Token | undefined> {
    if (!oauthToken || !oauthTokenSecret) return;
    const dto: VerifyTwitterAuthDto = { oauth_token: oauthToken, oauth_token_secret: oauthTokenSecret, oauth_verifier: oauthVerifier };
    const response = await this.common.jsonFetcher.fetchJSON<TwitterOAuthV1Token>(
      'POST',
      '/api/twitter/verify',
      undefined,
      dto,
    );
    if (!response.data) return;
    return response.data;
  }

  public async getTwitterUser(): Promise<void> {
    const twitterAuth = localStorage.getItem('twitterAccessToken');
    if (!twitterAuth) return;
    const response = await this.common.jsonFetcher.fetchJSON<{ user: string }>(
      'GET',
      '/api/twitter/get-self',
      undefined,
      undefined,
      undefined,
      false,
      { 'x-twitter-auth': twitterAuth },
    );
    console.debug(response.data);
  }
}
