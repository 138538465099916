import { URLSearchParamsCapable } from 'ymca/jsonFetcher'

export class GenericQueryParamsDto implements URLSearchParamsCapable {
  public query: Record<string, string>
  public constructor (
    query: Record<string, string>
  ) {
    this.query = query
  }

  public toURLSearchParams (): URLSearchParams {
    const params = new URLSearchParams()
    for (const [key, value] of Object.entries(this.query)) {
      params.append(key, value)
    }
    return params
  }
}

export class GenericPaginatedSearchParamsDto extends GenericQueryParamsDto {
  public constructor (
    query: Record<string, string>
  ) {
    if (query.limit === undefined) {
      query.limit = '20'
    }
    if (query.offset === undefined) {
      query.offset = '0'
    }
    super(query)
  }
}

export class QuerySearchParamsDto implements URLSearchParamsCapable {
  public limit?: number
  public offset?: number
  public order?: SortOrder

  public toURLSearchParams (): URLSearchParams {
    const params = new URLSearchParams()
    if (this.limit !== undefined) {
      params.append('limit', this.limit.toString())
    }
    if (this.offset !== undefined) {
      params.append('offset', this.offset.toString())
    }
    if (this.order !== undefined) {
      params.append('order', this.order)
    }
    return params
  }
}
/**
 * DataAndCount represents a list of entries
 * from a page of records, alongside the
 * total number of records in the database.
 */
export interface DataAndCount<T> {
  data: T[]
  count: number
}

// PaginatedResponse is how all sorts of list-style responses are returned
export interface PaginatedResponse<T> {
  path: string
  query: Record<string, string>
  count: number
  data: T[]
}

export function getEmptyPaginatedResponse<T> (): PaginatedResponse<T> {
  return {
    path: '',
    query: {},
    count: 0,
    data: []
  }
}

export type SortOrder = 'ASC' | 'DESC'

// This is a subset of the PaginatedResponse interface with only the relevant bits
// It is meant for use within a PaginatedGenerator
export interface PaginatedGeneratorResponse<T> {
  count: number
  data: T[]
}

export type PaginatedGenerator<T> = AsyncGenerator<PaginatedGeneratorResponse<T>, void, unknown>

// This takes a PaginatedGenerator and a transformer function to
// return a new PaginatedGenerator that yields the transformed data
export function runPaginatedGeneratorTransformer<A, B> (generator: PaginatedGenerator<A>, transformer: (a: A) => B): PaginatedGenerator<B> {
  return (async function * () {
    for await (const response of generator) {
      yield {
        count: response.count,
        data: response.data.map(transformer)
      }
    }
  })()
}

// This takes a PaginatedGenerator and a filter function to
// return a new PaginatedGenerator that yields the filter data
export function runPaginatedGeneratorFilter<T> (generator: PaginatedGenerator<T>, filter: (t: T) => boolean): PaginatedGenerator<T> {
  return (async function * () {
    for await (const response of generator) {
      yield {
        count: response.count,
        data: response.data.filter(filter)
      }
    }
  })()
}
