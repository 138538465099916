// isMemeTemplate checks if this image file's name ends with
// a .template.svg extension.
export const isMemeTemplate = (url: string) => {
  const parts = url.split('.')
  const extension = parts[parts.length - 1]
  // FIXME check for .template.svg; not just .svg
  if (extension.toLowerCase() === 'svg') {
    return true
  } else {
    return false
  }
}

export default isMemeTemplate
