import React, { lazy, Suspense } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";

import {
  otherProtectedRoutes,
  protectedSubRoutesWithDashboardLayout,
} from "utils/routes";
import { generateRandomKey } from "utils/utils";
import { redirectLinks } from "constants/redirectLinks";
import AppTemplate from "components/layout/AppTemplate";
import AuthTemplate from "components/layout/AuthTemplate";
import AuthRoutes from "./AuthRoutes";
import ContestRoutes from "./ContestRoutes";
import FallbackLoader from "components/main/FallbackLoader";
import Loading from "components/loading/Loading";
import NewsRoutes from "./NewsRoutes";
import ProfileRoutes from "./ProfileRoutes";
import useWindowSize from "hooks/useWindowSize";
import AppInfoRoutes from "./AppInfoRoutes";
import CommunityRoutes from "./CommunityRoutes";
import config from "utils/config";
import { BREAKPOINT_TABLET_MEDIUM } from "theme/shared/breakpoint";

const Faq = lazy(async () => await import("components/pages/FaqPage"));
const Privacy = lazy(
  async () => await import("components/pages/PrivacyPolicy")
);
const TermsOfService = lazy(
  async () => await import("components/pages/TermsOfService")
);
const NotFound = lazy(async () => await import("components/shared/Page404"));
const ContactUs = lazy(async () => await import("components/pages/ContactUs"));
const BusinessPage = lazy(
  async () => await import("components/pages/BusinessPage")
);
const MaintenancePage = lazy(
  async () => await import("components/pages/Maintenance")
);
const Referral = lazy(async () => await import("components/pages/Referral"));
const CommentPage = lazy(
  async () => await import("components/pages/CommentPage")
);

const AboutRoutes = lazy(() => import("./AboutRoutes"));

const AppRoutes = (): JSX.Element => {
  const location = useLocation();
  const state = location.state as { background?: Location };

  const isMobile = useWindowSize().width <= BREAKPOINT_TABLET_MEDIUM;
  const { experimentalCommunityFlag } = config;

  return (
    <Suspense fallback={<Loading />}>
      <FallbackLoader>
        <>
          <Routes location={state?.background || location}>
            <Route
              path={`${redirectLinks.about}/*`}
              element={
                <Suspense fallback={<Loading />}>
                  <AboutRoutes />
                </Suspense>
              }
            />
            <Route
              path={`${redirectLinks.profileUsername}/*`}
              element={
                <AppTemplate haveHeader={!isMobile}>
                  <Suspense fallback={<Loading />}>
                    <ProfileRoutes />
                  </Suspense>
                </AppTemplate>
              }
            />
            <Route
              path={`${redirectLinks.contest}/*`}
              element={
                <AppTemplate haveSidebar={false}>
                  <Suspense fallback={<Loading />}>
                    <ContestRoutes />
                  </Suspense>
                </AppTemplate>
              }
            />
            {experimentalCommunityFlag && (
              <Route
                path={`${redirectLinks.communities}/*`}
                element={
                  <AppTemplate haveHeader={!isMobile}>
                    <Suspense fallback={<Loading />}>
                      <CommunityRoutes />
                    </Suspense>
                  </AppTemplate>
                }
              />
            )}
            <Route
              path={`${redirectLinks.news}/*`}
              element={
                <AppTemplate haveSidebar={false}>
                  <Suspense fallback={<Loading />}>
                    <NewsRoutes />
                  </Suspense>
                </AppTemplate>
              }
            />
            <Route
              path={`${redirectLinks.referral}`}
              element={
                <AppTemplate haveSidebar={false} haveHeader={!isMobile}>
                  <Suspense fallback={<Loading />}>
                    <Referral />
                  </Suspense>
                </AppTemplate>
              }
            />
            <Route
              path='--/app/*'
              element={
                <AppTemplate haveSidebar={false} haveHeader={!isMobile}>
                  <Suspense fallback={<Loading />}>
                    <AppInfoRoutes />
                  </Suspense>
                </AppTemplate>
              }
            />
            <Route
              path='/*'
              element={
                <AppTemplate>
                  <Suspense fallback={<Loading />}>
                    <Routes>
                      <Route
                        path={`/${redirectLinks.homePage}`}
                        element={<Navigate to='/' />}
                      />
                      <Route
                        path={redirectLinks.allCategories}
                        element={<Navigate to='/' />}
                      />

                      <Route path='/meme/:id' element={<CommentPage />} />
                      {protectedSubRoutesWithDashboardLayout.map(
                        ({ element: Element, ...routeProps }) => (
                          <Route
                            key={generateRandomKey(routeProps.path)}
                            element={<Element />}
                            {...routeProps}
                          />
                        )
                      )}
                    </Routes>
                  </Suspense>
                </AppTemplate>
              }
            />

            {otherProtectedRoutes.map(({ element: Element, ...routeProps }) => (
              <Route
                key={generateRandomKey(routeProps.path)}
                {...routeProps}
                element={<Element />}
              />
            ))}
            <Route
              path={redirectLinks.maintenance}
              element={<MaintenancePage />}
            />
            <Route path={redirectLinks.business} element={<BusinessPage />} />
            <Route path={redirectLinks.privacy} element={<Privacy />} />
            <Route
              path={redirectLinks.agreement}
              element={<TermsOfService />}
            />
            <Route path={redirectLinks.contact} element={<ContactUs />} />
            <Route path={redirectLinks.faq} element={<Faq />} />
            <Route
              path={`${redirectLinks.auth}/*`}
              element={
                <AuthTemplate>
                  <AuthRoutes />
                </AuthTemplate>
              }
            />
            <Route path={redirectLinks.notFound} element={<NotFound />} />
            <Route path={"*"} element={<NotFound />} />
          </Routes>
        </>
      </FallbackLoader>
    </Suspense>
  );
};

export default AppRoutes;
