export const dataURLtoFile = (dataurl: string, filename: string = "temp") => {
  let arr = dataurl?.split(",");
  let mimestring = arr[0];
  let mime = mimestring.match(/:(.*?);/)![1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
