import { StickerListDTO } from 'ymca/dtos/sticker.dto'
import { BaseService } from './base.service'
import { Sticker } from 'ymca/models/sticker.model'
import { PaginatedGenerator } from 'ymca/dtos/common.dto'
import { ParamsCapableToPlainJSON } from 'ymca/jsonFetcher'

export class StickerService extends BaseService {
  public getStickersByDTO(dto: StickerListDTO): PaginatedGenerator<Sticker> {
    const urlSearchParams = dto.toURLSearchParams()
    const queryObject = Object.fromEntries(urlSearchParams.entries())
    const res = this.common.jsonFetcher.fetchPaginatedJSON<Sticker>(
      '/api/sticker',
      queryObject
    )
    return res
  }

  public async getDefaultStickers(skipCache = false): Promise<Sticker[]> {
    if (!skipCache) {
      const cachedStickers = await this.common.kvStore.get<Sticker[]>(
        'defaultstickers',
        skipCache
      )
      if (cachedStickers != null) {
        return cachedStickers
      }
    }
    const dto = new StickerListDTO()
    dto.offset = 0
    dto.limit = 100
    dto.tags = ['youmeme']
    const res = this.getStickersByDTO(dto)
    const { value } = await res.next()
    const stickers = value?.data ?? []
    if (!skipCache && stickers.length > 0) {
      await this.common.kvStore.set('defaultstickers', stickers, 300)
    }
    return stickers
  }

  public getPaginatedDefaultStickers(): PaginatedGenerator<Sticker> {
    const dto = new StickerListDTO()
    dto.offset = 0
    dto.limit = 100
    dto.tags = ['youmeme']

    const queryArgs = ParamsCapableToPlainJSON(dto)
    const res = this.common.jsonFetcher.fetchPaginatedJSON<Sticker>(
      '/api/sticker',
      queryArgs
    )
    return res
  }

  public async getCommunityStickers(communityId: string): Promise<Sticker[]> {
    const dto = new StickerListDTO()
    dto.offset = 0
    dto.limit = 100
    dto.communityId = communityId
    const res = this.getStickersByDTO(dto)
    const { value } = await res.next()
    return value?.data ?? []
  }
}
