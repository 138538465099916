import { useEffect, useState, RefObject } from "react";

const useOnScreen = (options: {
  ref: RefObject<HTMLElement> | null;
  rootMargin: string;
  threshold: number;
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const callbackFunction = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    if (options.ref) {
      const observer = new IntersectionObserver(callbackFunction, options);
      if (options.ref.current) observer.observe(options.ref.current);

      return () => {
        if (options.ref?.current) observer.unobserve(options.ref.current);
      };
    }
  }, [options]);

  return [isVisible];
};

export default useOnScreen;
