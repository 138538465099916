import { ReactElement, useEffect, useState } from 'react'
import { Link, useMatch, useNavigate } from 'react-router-dom'
import { InputBase, Popover } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { Box, Badge, Button, IconButton, styled, Dialog } from '@mui/material'
import { CloseOutlined } from '@material-ui/icons'
import { Switch } from '@mui/material'
import ReactGA from 'react-ga4'

import { useAuth } from 'context/auth/authContext'
import { Image } from 'components/shared/index'
import useWindowSize from 'hooks/useWindowSize'
import PublishMemeModal from 'components/memePublication/PublishMemeModal'
import UserProfileSetting from 'components/user/UserProfileSetting'
import { ExpandMoreIcon, SearchIcon } from 'components/shared/SvgIcons'
import { imagesSvg } from 'constants/images'
import { redirectLinks } from 'constants/redirectLinks'
import { headerContentHeight } from 'constants/variables'

import SearchResultModal from 'components/modals/SearchResultModal'
import useDebounce from 'hooks/useDebounce'

import { DefaultYMCA } from 'ymca/ymca'
import { DEFAULT_AVATAR_IMAGE } from 'ymca/services/image.service'
import { useUserSelf } from 'hooks/useUserSelf'
import colors from 'theme/dark/colors'
import Alert from 'components/atoms/Alert'
import { useFeedContext } from 'context/feed/feedContext'
import Icon from 'components/atoms/Icons'

import {
  BREAKPOINT_LAPTOP,
  BREAKPOINT_MOBILE_LARGE,
  BREAKPOINT_MOBILE_MEDIUM,
  BREAKPOINT_MOBILE_SMALL,
  BREAKPOINT_TABLET_MEDIUM
} from 'theme/shared/breakpoint'
import Typography from 'components/atoms/Typography'
import config from 'utils/config'
import { useLensService } from 'hooks/useLensServices/useLensServices'
import useHeaderStyles from './style'
import NotificationMenu from 'components/notification/NotificationMenu'
import HeaderCreateMemeButtonWrapper from 'components/organisms/HeaderCreateMemeButtonWrapper'
import ProfileMenuDropdown from 'components/organisms/ProfileMenuDropdown'
import Avatar from 'components/molecules/Avatar'
import getImageResolutions, {
  getImageUrlSourceType
} from 'utils/getImageResolutions'
import { PublicUser } from 'ymca/models/user.model'

const Wrapper = styled(Box, {
  name: 'Header'
})(({ theme }) => ({
  backgroundColor: colors.darkBlue,
  position: 'sticky',
  top: 0,

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  boxSizing: 'border-box',
  boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.5)',
  zIndex: theme.zIndex.appBar,

  width: '100%'
}))

const AppTopBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  margin: 'auto',
  maxWidth: 1260,
  '& a': {
    '&:hover': {
      textDecoration: 'none'
    }
  },
  padding: '.5rem',
  height: `${headerContentHeight}rem`,
  width: '100%',

  [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: '.3125rem .625rem',
    maxWidth: '100%'
  }
}))

const StyledAlert = styled(Alert, {
  name: 'EthAlertSection'
})(({ theme }) => ({
  width: '100%',
  '&.MuiAlert-root': {
    height: `${headerContentHeight}rem`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '.4rem',
    borderRadius: 0,

    [`@media (max-width: ${BREAKPOINT_MOBILE_SMALL}px)`]: {
      padding: '.375rem'
    }
  },

  '& .MuiAlert-message': {
    fontSize: '.75rem',
    [`@media (max-width: 1041px)`]: {
      fontSize: '.7rem'
    },
    [`@media (max-width: ${BREAKPOINT_MOBILE_SMALL}px)`]: {
      fontSize: '.625rem'
    }
  },

  '& .MuiAlert-action': {
    marginLeft: 0
  },

  '&.MuiAlert-filledInfo': {
    color: theme.palette.text.primary
  }
}))

const AuthButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-root': {
    height: '2.5rem',
    width: '11rem',
    minWidth: 0,
    padding: '0 .25rem',
    borderRadius: '5rem',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    border: '1px solid rgba(98, 95, 255, 0.5)',
    transition: '0.4s',
    textShadow: '0px 0px 5px rgba(0,0,0,0.1)',

    '&.MuiButton-outlinedPrimary': {
      'border-width': '2px'
    },

    '& .MuiTypography-body1': {
      fontWeight: 800,
      fontSize: '1rem'
    },

    [`@media (max-width: ${BREAKPOINT_MOBILE_LARGE}px)`]: {
      height: 'unset',
      width: '100%',
      padding: '.375rem .625rem',
      backgroundColor: '#625FFF',
      '& .MuiTypography-body1': {
        fontSize: '.875rem'
      }
    },

    [`@media (max-width: ${BREAKPOINT_MOBILE_MEDIUM}px)`]: {
      '& .MuiTypography-body1': {
        fontSize: '.75rem'
      }
    },

    [`@media (max-width: ${BREAKPOINT_MOBILE_SMALL}px)`]: {
      '& .MuiTypography-body1': {
        fontSize: '.625rem'
      }
    },

    '&:hover': {
      opacity: 0.5
    }
  }
}))

const MobileCreateButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-root': {
    height: '1.4rem',
    width: '4.75rem',
    minWidth: 0,
    padding: '0 .4rem',
    borderRadius: '5rem',
    fontWeight: 700,
    display: 'flex',
    color: '#ffffff',
    alignItems: 'center',
    background: 'rgba(98, 95, 255, 1)',
    transition: '0.4s',
    textShadow: '0px 0px 5px rgba(0,0,0,0.1)',

    '& .MuiTypography-body1': {
      fontWeight: 800,
      fontSize: '1rem'
    }
  }
}))

const LogoImage = styled(Image)({
  width: '100%',
  height: '100%',
  objectFit: 'contain'
})

const AppTopBarLeftSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '.4rem',

  width: '100%',
  [`@media (max-width: ${BREAKPOINT_MOBILE_LARGE}px)`]: {
    width: 'auto'
  },

  [`@media (max-width: ${BREAKPOINT_MOBILE_SMALL}px)`]: {
    gap: '.2rem'
  }
})

const MenuBarIconButton = styled(IconButton)({
  '&.MuiIconButton-root': {
    padding: 0,
    display: 'none',
    '&.MuiIconButton-edgeStart': {
      margin: 0
    },
    [`@media (max-width: ${BREAKPOINT_LAPTOP}px)`]: {
      display: 'flex',
      alignItems: 'center'
    },

    '& .MuiSvgIcon-root': {
      fontSize: '2rem',

      [`@media (max-width: ${BREAKPOINT_MOBILE_SMALL}px)`]: {
        fontSize: '1.5rem'
      }
    }
  }
})

const MobileCreateDialog = styled(Dialog)({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0)'
  },
  '& .MuiDialog-paper': {
    width: '100%',
    position: 'absolute',
    top: 55,
    left: 0,
    margin: 0,
    background: 'rgba(21, 30, 49, 0.8)',
    backdropFilter: 'blur(12px)',
    borderRadius: 0
  }
})

const MobileCreateItem = styled(Box)({
  fontSize: '.9rem',
  fontWeight: 700,
  color: '#ffffff',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  padding: '1.5rem',
  cursor: 'pointer',
  '& img': {
    marginBottom: '-.3rem'
  },
  '&:hover': {
    color: colors.primary500,
    background: 'rgba(255,255,255,.1)'
  }
})
interface HeaderProps {
  drawerOpen: boolean
  handleDrawerOpen: any
  handleSidebar: Function
  openSidebar?: boolean
  appToolbar?: ReactElement
  onPublishMemeSuccess?: VoidFunction
  onDismissEthAlertHeader?: VoidFunction
}

const Header = ({
  drawerOpen,
  handleDrawerOpen,
  handleSidebar,
  appToolbar,
  onPublishMemeSuccess,
  onDismissEthAlertHeader = () => {}
}: HeaderProps): JSX.Element => {
  const classes = useHeaderStyles()
  const navigate = useNavigate()

  const { userInfo, isAuthenticated } = useAuth()
  const { avatar } = (userInfo?.userData as PublicUser) ?? {}

  const { selfData: selfUser, showEthAlert } = useUserSelf()
  const { source: feed, setSource } = useFeedContext()
  const [displayName, setDisplayname] = useState('')
  const debouncedValue = useDebounce<string>(displayName, 700)
  const isContestPage = useMatch('/contest/*')
  const [uploadTab, setUploadTab] = useState<number>(0)
  const [openCreatePopup, setOpenCreatePopup] = useState<boolean>(false)
  const [isOpenMeme, setOpenMeme] = useState<boolean>(false)
  const [openSearch, setOpenSearch] = useState<boolean>(false)
  const [openNoti, setOpenNoti] = useState<boolean>(false)
  const [openNoticationAnchorEl, setOpenNoticationAnchorEl] =
    useState<Element | null>(null)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [searchEl, setsearchEl] = useState<{
    element: Element
    type: string
  } | null>(null)
  const [unreadCount, setUnreadCount] = useState<number>(0)

  const [showAlert, setShowAlert] = useState<boolean>(false)

  const { width } = useWindowSize()
  const isMobile: boolean = width <= BREAKPOINT_TABLET_MEDIUM
  const { isAuthenticatedOnLens: isAuthenticatedOnLensPromise } =
    useLensService()
  const [isAuthenticatedOnLens, setIsAuthenticatedOnLens] =
    useState<boolean>(false)
  const showSurveyBanner = config.showSurveyBanner

  useEffect(() => {
    setShowAlert(Boolean(showEthAlert))
  }, [showEthAlert])

  const { x2, x3, fallback } = getImageResolutions(avatar)

  const handleModalClose = (): void => {
    setModalOpen(false)
  }

  const handleDraw = (): void => {
    if (width <= BREAKPOINT_LAPTOP) {
      handleSidebar(true)
    } else {
      handleDrawerOpen(!drawerOpen)
    }
  }

  const handleOnDismissAlert = () => {
    setShowAlert(false)
    onDismissEthAlertHeader()
  }

  useEffect(() => {
    const handleScroll = () => {
      setOpenCreatePopup(false)
    }

    if (openCreatePopup) {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [openCreatePopup])

  useEffect(() => {
    const handleEsc = (e: any): void => {
      if (e.keyCode === 27) setOpenSearch(false)
    }
    window.addEventListener('keydown', handleEsc)

    let pathURL = process.env.PUBLIC_URL
    const images = [
      'assets/moveIcons/' + imagesSvg.beforeIcon,
      'assets/moveIcons/' + imagesSvg.nextIcon,
      'assets/moveIcons/' + imagesSvg.topIcon
    ]
    for (let i = 0; i < images.length; i++) {
      const img = new window.Image()
      img.src = pathURL + images[i]
    }

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  useEffect(() => {
    ;(async function getUnread() {
      const unread =
        await DefaultYMCA.notificationService.getUnreadNotificationCount()
      setUnreadCount(unread)
      setIsAuthenticatedOnLens(await isAuthenticatedOnLensPromise())
    })()
  }, [])

  const handleMobileCreate = (tab: number) => {
    setUploadTab(tab)
    setOpenMeme(true)
    setOpenCreatePopup(false)
  }

  const handleOpenNotification = (e: any) => {
    setOpenNoticationAnchorEl(e.currentTarget)
  }

  return (
    <Wrapper>
      {showSurveyBanner && (
        <StyledAlert severity='info' variant='filled'>
          We value your feedback! Help us improve by taking a quick survey.{' '}
          <Link
            to='https://docs.google.com/forms/d/e/1FAIpQLSfbPZSrMEMdGx-iO7USM72oBQfJoZM6lwj9frgWYArQPv5ZwA/viewform'
            target='__blank'
          >
            <span
              style={{
                color: 'white',
                textDecoration: 'underline'
              }}
            >
              Click here to share your thoughts and opinions
            </span>
          </Link>
        </StyledAlert>
      )}

      {showAlert && (
        <StyledAlert
          severity='error'
          variant='filled'
          onClose={handleOnDismissAlert}
        >
          You participated in our meme contest(s). To get a chance to win, add
          an EVM address to earn rewards.{' '}
          <Link
            to={
              isMobile
                ? `/profile/${selfUser?.username}/edit`
                : `/profile/${selfUser?.username}`
            }
            state={
              isMobile
                ? { user: selfUser }
                : { showEditModal: true, user: selfUser }
            }
          >
            <span
              style={{
                color: 'white',
                textDecoration: 'underline'
              }}
            >
              Check out your profile -&gt; Edit Profile -&gt; Non-custodial EVM
              Address -&gt; Save.
            </span>
          </Link>
        </StyledAlert>
      )}
      <AppTopBar>
        <AppTopBarLeftSection>
          <MenuBarIconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDraw}
            edge='start'
          >
            <MenuIcon />
          </MenuBarIconButton>

          <Box
            className={classes.siteLogo}
            width={isContestPage ? '7.7rem' : isMobile ? '1rem' : '10rem'}
            onClick={() => {
              navigate(`/${redirectLinks.homePage}`)
            }}
          >
            <LogoImage
              alt='logo'
              src={`headerIcons/${
                isMobile ? imagesSvg.dogLogo : imagesSvg.logo
              }`}
            />
          </Box>
          {isMobile && (
            <MobileCreateButton onClick={() => setOpenCreatePopup(true)}>
              Create <ExpandMoreIcon fill='#ffffff' />
            </MobileCreateButton>
          )}

          {isMobile && openCreatePopup && (
            <MobileCreateDialog
              open={openCreatePopup}
              onClose={() => setOpenCreatePopup(false)}
            >
              <MobileCreateItem onClick={() => handleMobileCreate(0)}>
                <Image src={`headerIcons/${imagesSvg.createMemeImg}`} /> Create
                Meme
              </MobileCreateItem>
              <MobileCreateItem onClick={() => handleMobileCreate(1)}>
                <Image src={`headerIcons/${imagesSvg.textToMemeImg}`} />
                Text-To-Meme
              </MobileCreateItem>
              <MobileCreateItem onClick={() => handleMobileCreate(2)}>
                <Image src={`headerIcons/${imagesSvg.faceSwapImg}`} />
                SwapFaces
              </MobileCreateItem>
              <MobileCreateItem onClick={() => handleMobileCreate(3)}>
                <Image src={`headerIcons/${imagesSvg.memecamImg}`} />
                MemeCam
              </MobileCreateItem>
            </MobileCreateDialog>
          )}
          {!isContestPage && (
            <InputBase
              classes={{
                root: classes.search,
                input: classes.input
              }}
              style={{
                paddingLeft: openSearch ? 12 : 0
              }}
              placeholder='Search'
              onFocus={() => {
                if (openNoti) {
                  setOpenNoti(false)
                }
                if (!isAuthenticated) return
                setOpenSearch(true)
              }}
              onClick={(e) => {
                if (!isAuthenticated) {
                  setsearchEl({
                    element: e.currentTarget,
                    type: 'authPopover'
                  })
                }
              }}
              startAdornment={
                !openSearch && (
                  <IconButton
                    className={classes.searchBtn}
                    disabled={!isAuthenticated}
                  >
                    <SearchIcon
                      fill='#fff'
                      style={{ width: 15, height: 15 }}
                      fontSize='small'
                    />
                  </IconButton>
                )
              }
              endAdornment={
                openSearch && (
                  <IconButton
                    onClick={() => {
                      setOpenSearch(false)
                      setDisplayname('')
                    }}
                    style={{
                      background: '#151E31',
                      zIndex: 999,
                      width: 10,
                      height: 10,
                      marginRight: 15
                    }}
                    disabled={!isAuthenticated}
                  >
                    <CloseOutlined
                      style={{ color: 'white', width: 15, height: 15 }}
                      fontSize='small'
                    />
                  </IconButton>
                )
              }
              disabled={!isAuthenticated}
              value={displayName}
              onChange={(e) => setDisplayname(e.target.value)}
            />
          )}
        </AppTopBarLeftSection>
        {!isContestPage && (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '.3rem'
            }}
            className={classes.mobileOnly}
          >
            {isAuthenticated ? (
              <>
                <IconButton
                  className={`${classes.iconBtn} ${classes.transparent} `}
                  onClick={() =>
                    navigate(`/${redirectLinks.notifications}`, {
                      state: { unreadCount }
                    })
                  }
                >
                  <Badge
                    badgeContent={unreadCount}
                    max={99}
                    className={classes.notificationBadge}
                  >
                    <Icon
                      icon='notification'
                      {...{ className: 'iconNotification' }}
                    />
                  </Badge>
                </IconButton>
                <IconButton
                  className={`${classes.iconBtn} ${classes.transparent}`}
                  onClick={() => navigate(`/profile/${selfUser?.username}`)}
                  disabled={!isAuthenticated}
                >
                  <Avatar
                    alt='account'
                    className={classes.mobile_avatar}
                    src={fallback ?? DEFAULT_AVATAR_IMAGE}
                    pictureSources={[
                      {
                        srcSet: `${x2} 1x, ${x3} 2x`,
                        type: getImageUrlSourceType(x3)
                      }
                    ]}
                  />
                </IconButton>
              </>
            ) : (
              <AuthButton
                onClick={() => navigate('/auth')}
                color='primary'
                variant='contained'
              >
                <Typography>Sign Up/Log In</Typography>
              </AuthButton>
            )}
          </Box>
        )}

        {!isContestPage && (
          <Box className={classes.desktopOnly}>
            <HeaderCreateMemeButtonWrapper>
              <Box display='flex' alignItems='center'>
                <div
                  onClick={() => {
                    feed === 'lens' ? setSource('youmeme') : setSource('lens')
                  }}
                  className={classes.switchWrapper}
                >
                  <Switch
                    checked={feed === 'lens'}
                    title={
                      feed === 'lens' ? 'Switch to YouMeme' : 'Switch to Lens'
                    }
                    className={classes.iosSwitch}
                  />
                  <text className={feed === 'youmeme' ? 'active' : ''}>
                    YouMeme
                  </text>
                  <text className={feed === 'lens' ? 'active' : ''}>Lens</text>
                </div>
                <Button
                  className={
                    classes.button + (feed === 'lens' ? ' lens' : ' youmeme')
                  }
                  onClick={() => setOpenMeme(true)}
                  disabled={
                    !!isContestPage ||
                    (feed === 'lens' &&
                      !isAuthenticatedOnLens &&
                      isAuthenticated) ||
                    (feed === 'lens' && config.disableLensButton)
                  }
                >
                  <span>Create</span>
                </Button>
              </Box>
            </HeaderCreateMemeButtonWrapper>
            {isAuthenticated ? (
              <>
                <IconButton
                  className={`${classes.iconBtn} ${classes.transparent} ${classes.desktopNotificationIcon}`}
                  onClick={(e) => handleOpenNotification(e)}
                  data-test-notification-button
                >
                  <Badge
                    badgeContent={unreadCount}
                    max={99}
                    sx={{
                      '& .MuiBadge-badge': {
                        backgroundColor: colors.primary500,
                        color: 'white'
                      }
                    }}
                    style={{ pointerEvents: 'none' }}
                  >
                    <Icon icon='notification' fontSize='1.8rem' />
                  </Badge>
                </IconButton>
              </>
            ) : (
              <AuthButton
                onClick={() => navigate('/auth')}
                color='primary'
                variant='outlined'
              >
                <Typography>Sign Up/Log In</Typography>
              </AuthButton>
            )}

            {isAuthenticated && (
              <>
                <ProfileMenuDropdown />
                {!(
                  openNoticationAnchorEl === null ||
                  openNoticationAnchorEl?.children.length === 0
                ) && (
                  <NotificationMenu
                    anchorEl={openNoticationAnchorEl}
                    setAnchorEl={setOpenNoticationAnchorEl}
                    unreadCount={unreadCount}
                    setUnreadCount={setUnreadCount}
                  />
                )}
                {modalOpen && (
                  <UserProfileSetting
                    open={modalOpen}
                    handleClose={handleModalClose}
                  />
                )}
                {openSearch && (
                  <SearchResultModal
                    displayName={debouncedValue}
                    visible={openSearch}
                    close={() => {
                      setOpenSearch(false)
                      setDisplayname('')
                    }}
                  />
                )}
              </>
            )}
          </Box>
        )}
      </AppTopBar>
      <PublishMemeModal
        open={isOpenMeme}
        tab={uploadTab}
        onClose={() => setOpenMeme(false)}
        onSuccess={onPublishMemeSuccess}
      />
      {appToolbar}
      <Popover
        classes={{ paper: classes.authPopoverWrapper }}
        open={Boolean(searchEl?.element) && searchEl?.type === 'authPopover'}
        anchorEl={searchEl?.element}
        onClose={() => {
          setsearchEl(null)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        disablePortal
      >
        <Box className={classes.authPopoverContentWrapper}>
          <Typography className={classes.authPopoverTitle}>
            Log in or sign up to look for memes
          </Typography>
        </Box>
        <Box className={classes.authPopoverContentWrapper}>
          <Button variant='contained' onClick={() => navigate('/auth')}>
            Sign up/Log in
          </Button>
        </Box>
      </Popover>
    </Wrapper>
  )
}
export default Header
