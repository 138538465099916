import { Route, Routes } from "react-router-dom";

import { appInfoRoutes } from "utils/routes";

const AppInfoRoutes = (): JSX.Element => {
  return (
    <Routes>
      {appInfoRoutes.map(({ element: Element, ...routeProps }, index) => (
        <Route key={index.toString()} element={<Element />} {...routeProps} />
      ))}
    </Routes>
  );
};

export default AppInfoRoutes;
