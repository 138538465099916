import React, { Ref, forwardRef } from 'react'

import ImagePicture, { ImagePictureProps } from '../ImagePicture'
import useAvatarStyles from './style'

interface AvatarProps extends ImagePictureProps {}

/**
 * @example
 * <Avatar
    src={fallback ?? DEFAULT_AVATAR_IMAGE}
    pictureSources={[
      {
        srcSet: `${x2} 1x, ${x3} 2x`,
        type: image/avif
      }
    ]}
    loading='lazy'
    alt='user icon'
    className={classes.userAvatar}
  />
 */

const Avatar = forwardRef(
  (props: AvatarProps, ref?: Ref<HTMLImageElement>): JSX.Element => {
    const classes = useAvatarStyles()
    return (
      <ImagePicture
        {...props}
        ref={ref}
        className={`${classes.avatar_picture_wrapper} ${props.className}`}
      />
    )
  }
)

export default Avatar
