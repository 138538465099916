import { useAuth } from 'context/auth/authContext'
import { Box } from '@material-ui/core'
import { EmptyItem } from 'components/shared/EmptyItem'
import MobileTabs from 'components/mobile/Tabs'
import { makeStyles } from '@material-ui/core/styles'
import UserPostItem from 'components/user/UserPostItem'
import InfiniteScroll from 'react-infinite-scroll-component'
import { createContext, useEffect, useState } from 'react'
import AlertText from 'components/shared/AlertText'
import { Eventlist } from 'components/events/Eventlist'
import { Skeleton, Typography } from '@mui/material'
import { Post } from 'ymca/models/post.model'

export interface UserProfileTabProps {
  postUserID: string
  lensPostUserId?: string
  userPosts: { data: Post[]; count: number }
  saved: Post[]
  setEvent: any
  events: any
  upcoming: any
  live: any
  lensPosts: { data: Post[]; count: number }
  next(val: any): void
  hasMoreLensPosts: boolean
  getUserLensPosts: () => void
}

const useStyles = makeStyles({
  container: {
    padding: '0px 16px'
  },
  alertBox: {
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    bottom: 50,
    left: '50%',
    transform: 'translate(-50%, 0)'
  },
  scrollContainer: {
    height: 730,
    '&::-webkit-scrollbar': {
      width: 5
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--gradient-fall-back)',
      borderRadius: 10
    },
    overflow: 'auto !important'
  },
  cardsContainer: {
    marginTop: 26,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    gap: '1rem'
  },
  emtyItemContainer: {
    display: 'flex',
    justifyContent: 'center',
    color: '#808893',
    marginTop: '1rem'
  },
  text2: {
    color: '#808893',
    fontSize: 13
  }
})

export const ProfileTabContext = createContext(0)

const tabResolver: number[] = [0, 1, 2, 3]

const UserProfileTab = ({
  postUserID,
  userPosts,
  saved,
  setEvent,
  events,
  upcoming,
  live,
  next,
  lensPosts,
  hasMoreLensPosts,
  getUserLensPosts,
  lensPostUserId
}: UserProfileTabProps): JSX.Element => {
  const classes = useStyles()
  const { isAuthenticated } = useAuth()
  const [value, setValue] = useState<number>(0)
  const [show, setShow] = useState<boolean>(false)

  const items = [
    `${userPosts.count > 1 ? 'Posts' : 'Post'}`,
    'Saved',
    'Events',
    'Lens'
  ]

  useEffect(() => {
    setTimeout(() => {
      setShow(false)
    }, 2000)
  }, [show])

  const getNeccessaryComponent = (
    isAuthenticated: boolean | undefined,
    itemName: string
  ): JSX.Element => {
    if (isAuthenticated === true) {
      return (
        <EmptyItem
          itemName={itemName}
          isAuthenticated={isAuthenticated}
          className={classes.emtyItemContainer}
        />
      )
    }

    return (
      <EmptyItem
        itemName='You will see only after registration'
        isAuthenticated={isAuthenticated}
        className={classes.emtyItemContainer}
      />
    )
  }

  function fetchMoreData() {
    // nothing
  }

  return (
    <Box className={classes.container}>
      <MobileTabs
        setValue={(e) => {
          setValue(e)
          next(e)
        }}
        value={value}
        items={items}
        tab={3}
      />
      <AlertText
        show={show}
        text={value === 1 ? 'Post unsaved' : 'Meme has been removed.'}
      />
      <ProfileTabContext.Provider value={tabResolver[value]}>
        {value === 0 &&
          (userPosts ? (
            <Box id='scrollableDiv'>
              <InfiniteScroll
                dataLength={userPosts.data.length}
                next={fetchMoreData}
                hasMore={userPosts.data.length <= userPosts.count}
                scrollableTarget='scrollableDiv'
                className={classes.cardsContainer}
                loader={true}
              >
                {userPosts.data.map((item, index) => (
                  <UserPostItem
                    itemData={item}
                    postUserID={postUserID}
                    key={index}
                    setEvent={setEvent}
                    setDeleted={() => setShow(!show)}
                  />
                ))}
              </InfiniteScroll>
            </Box>
          ) : (
            <Box
              style={{
                marginTop: 50,
                display: 'grid',
                gridTemplateColumns: 'repeat(2 , 1fr)',
                gap: 10
              }}
            >
              <Skeleton
                animation='wave'
                variant='rectangular'
                width={'100%'}
                height={245}
                style={{ borderRadius: 7 }}
              />
              <Skeleton
                animation='wave'
                variant='rectangular'
                width={'100%'}
                height={245}
                style={{ borderRadius: 7 }}
              />
              <Skeleton
                animation='wave'
                variant='rectangular'
                width={'100%'}
                height={245}
                style={{ borderRadius: 7 }}
              />
              <Skeleton
                animation='wave'
                variant='rectangular'
                width={'100%'}
                height={245}
                style={{ borderRadius: 7 }}
              />
              <Skeleton
                animation='wave'
                variant='rectangular'
                width={'100%'}
                height={245}
                style={{ borderRadius: 7 }}
              />
              <Skeleton
                animation='wave'
                variant='rectangular'
                width={'100%'}
                height={245}
                style={{ borderRadius: 7 }}
              />
            </Box>
          ))}

        {value === 1 &&
          (saved?.length > 0 ? (
            <Box className={classes.cardsContainer}>
              {saved?.map((item, index) => (
                <UserPostItem
                  itemData={item}
                  postUserID={postUserID}
                  key={index}
                  setEvent={setEvent}
                  setDeleted={() => setShow(!show)}
                />
              ))}
            </Box>
          ) : (
            getNeccessaryComponent(isAuthenticated, 'saved memes')
          ))}
        {value === 2 && (
          <>
            <Eventlist
              events={events}
              getNeccessaryComponent={() =>
                getNeccessaryComponent(isAuthenticated, 'events')
              }
              upcoming={upcoming}
              live={live}
            />
          </>
        )}
        {value === 3 &&
          (lensPosts && lensPosts.data.length > 0 ? (
            <Box id='scrollableDiv'>
              <InfiniteScroll
                dataLength={lensPosts.data.length}
                next={fetchMoreData}
                hasMore={hasMoreLensPosts}
                scrollableTarget='scrollableDiv'
                className={classes.cardsContainer}
                loader={true}
              >
                {lensPosts.data.map((item, index) => (
                  <UserPostItem
                    itemData={item}
                    postUserID={postUserID}
                    lensPostUserId={lensPostUserId}
                    key={index}
                    setEvent={setEvent}
                    setDeleted={() => setShow(!show)}
                    isLensPost={true}
                  />
                ))}
              </InfiniteScroll>
            </Box>
          ) : (
            <Box
              style={{
                marginTop: 50,
                display: 'grid',
                gap: 10,
                justifyContent: 'center'
              }}
            >
              <Typography variant='body1' className={classes.text2}>
                User has not made any lens posts!
              </Typography>
            </Box>
          ))}
      </ProfileTabContext.Provider>
    </Box>
  )
}

export default UserProfileTab
