import { makeStyles } from "@material-ui/core/styles";
import { borderRadius } from "constants/variables";
import colors from "theme/dark/colors";
import { ellipsisProperty } from "components/atoms/Typography";

const baseFollowBtnStyles = {
  borderRadius: "6.25rem",
  border: "1.5px solid #1C263B",
  boxShadow: " 2px 0px 2px 0px #1C263B",

  display: "flex",
  alignItems: "center",

  // Typography
  color: "#E9F0F9",
  "text-align": "center",
  fontFamily: "Inter",
  fontSize: "0.875rem",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "1.5rem",
  letterSpacing: "-0.01875rem",
};

const useRightSidebarMemersStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    padding: ".25rem 0rem",
    borderRadius: 100,
    boxSizing: "border-box",
    transition: "all 0.3s",

    "&:hover": {
      backgroundColor: colors.darkBlue50,
      padding: ".25rem",
    },
  },
  dataContainer: {
    display: "flex",
    alignItems: "center",
    gap: ".6rem",
  },
  imageBox: {
    width: "2.25rem",
    height: "2.25rem",
    "& img": {
      width: "100%",
      height: "100%",
      borderRadius,
      backgroundColor: colors.profileImageBackground,
    },
  },
  popularName: {
    maxWidth: "6rem",
    color: "#E9F0F9",
    "font-family": "Inter",
    "font-size": "0.925rem",
    "font-style": "normal",
    "font-weight": 700,
    "line-height": "normal",
    "letter-spacing": "-0.01875rem",

    ...ellipsisProperty,
  },
  popularDetails: {
    color: "#8A98B4",
    "font-family": "Inter",
    "font-size": "0.8125rem",
    "font-style": "normal",
    "font-weight": 400,
    "line-height": "normal",
    "letter-spacing": "-0.01875rem",
  },
  memecontent: {
    display: "flex",
    // alignItems: "center",
    flexDirection: "column",
    gap: "0.15rem",
  },
  followBtn: {
    ...baseFollowBtnStyles,
    color: colors.titleColor,
    backgroundColor: colors.primary500,
    "&:hover": {
      backgroundColor: colors.primary500,
    },
  },
  followingBtn: {
    ...baseFollowBtnStyles,
    background: "transparent",
    mixBlendMode: "normal",
    borderColor: colors.primary500,
    color: colors.primary500,
  },
  unfollowButton: {
    ...baseFollowBtnStyles,
    background: "transparent",
    mixBlendMode: "normal",
    borderColor: "#FF0061",
    color: "#FF0061",
  },
  memerProfile: {
    position: "absolute",
    left: -55,
    zIndex: 9999999999,
  },
  unfollowBox: {
    borderRadius: "15px",
    backgroundColor: colors.darkBlue,
    mixBlendmode: "normal",
    backdropFilter: "blur(10px)",
    padding: 24,
    display: "grid",
    placeItems: "center",
    width: 400,
    "& #desc": {
      textAlign: "center",
    },
  },
  title: {
    fontWeight: 700,
    marginBottom: 0,
  },
  changebtn: {
    height: "44px",
    width: "100%",
    marginTop: 20,
    backgroundColor: "#FF5178",
    borderRadius: "7px",
    fontWeight: 600,
    fontSize: "14px",
    color: "#FFFFFF",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#FF5178",
    },
  },
  cancel: {
    height: "44px",
    width: "100%",
    marginTop: 20,
    backgroundColor: "#FFFFFF",
    borderRadius: "7px",
    fontWeight: 600,
    fontSize: "14px",
    color: "#000000",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  buttons: {
    display: "flex",
    width: "100%",
    gap: 15,
  },
  "@media (max-width: 1280px)": {
    container: {
      height: "auto",
      alignItems: "center",
    },
  },
  "@media (max-width: 550px)": {
    dataContainer: {
      flexDirection: "row",
    },
  },
});

export default useRightSidebarMemersStyles;
