import { NotificationType } from 'ymca/models/notification.model'
import { URLSearchParamsCapable } from 'ymca/jsonFetcher'
import { QuerySearchParamsDto } from './common.dto'

export class GetNotificationsDto extends QuerySearchParamsDto implements URLSearchParamsCapable {
  public ignoreRead?: boolean
  public types: NotificationType[] = []

  public toURLSearchParams (): URLSearchParams {
    const params = super.toURLSearchParams()
    if (this.ignoreRead !== undefined) {
      params.append('ignoreRead', this.ignoreRead.toString())
    }
    if (this.types.length > 0) {
      params.append('types', this.types.join(','))
    }
    return params
  }
}
