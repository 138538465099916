import type { ThrottlePurpose, RateLimitInfoResponse } from '../dtos/throttle.dto';
import { GetRateLimitInfoDto } from '../dtos/throttle.dto';
import { BaseService } from './base.service';

export class ThrottleService extends BaseService {
  public async getRateLimitInfo(purpose: ThrottlePurpose, key: string): Promise<RateLimitInfoResponse> {
    const dto = new GetRateLimitInfoDto(purpose, key);
    const response = await this.common.jsonFetcher.fetchJSON<RateLimitInfoResponse>(
      'GET',
      `/api/throttle`,
        dto,
    );
    return response.data;
  }

  public async getRemainingAttempts(purpose: ThrottlePurpose, key: string): Promise<number> {
    const info = await this.getRateLimitInfo(purpose, key);
    return info.limit - info.count;
  }
}
