import { PaginatedGenerator, PaginatedResponse } from 'ymca/dtos/common.dto'
import {
  FindUsersDto,
  GetUserProfileQueryDto,
  UpdateUserDto,
  GetFollowersDto
} from 'ymca/dtos/user.dto'
import { PublicUser, User, UserProfilePageModel } from 'ymca/models/user.model'
import { APIResponse, ParamsCapableToPlainJSON } from 'ymca/jsonFetcher'
import { BaseService, CommonArgs } from './base.service'
import { SelfService } from './self.service'

export const USER_CACHE_TTL_SECONDS = 60 // 5 minute
export const USER_PAGE_SIZE = 100 // you can set a smaller number to test!

export class UserService extends BaseService {
  protected selfService: SelfService

  constructor(common: CommonArgs, selfService: SelfService) {
    super(common)
    this.selfService = selfService
  }

  // getUsersByDto accepts a FindUsersDto and uses it to fetch users
  public async getUsersByDto(
    req: FindUsersDto
  ): Promise<APIResponse<PaginatedResponse<PublicUser>>> {
    const res = await this.common.jsonFetcher.fetchJSON<
      PaginatedResponse<PublicUser>
    >('GET', '/api/user', req)
    const users = res?.data?.data
    if (!Array.isArray(users)) {
      const msg = `Failed to fetch users from API: ${JSON.stringify(req)}`
      console.error(msg)
      console.error(res)
      throw new Error(msg)
    }
    await this.setUsersInCache(res.data.data)
    return res
  }

  protected async getUserFromCache(
    id: string,
    skipCache = false
  ): Promise<PublicUser | null> {
    const cachedUser = await this.common.kvStore.get<PublicUser>(
      `userid-${id}`,
      skipCache
    )
    return cachedUser
  }

  protected async getUserFromCacheByUsername(
    username: string,
    skipCache = false
  ): Promise<PublicUser | null> {
    const cachedUser = await this.common.kvStore.get<PublicUser>(
      `username-${username}`,
      skipCache
    )
    return cachedUser
  }

  protected async getUserFromCacheByLensId(
    lensProfileId: string,
    skipCache = false
  ): Promise<PublicUser | null> {
    const cachedUser = await this.common.kvStore.get<PublicUser>(
      `lensprofileid-${lensProfileId}`,
      skipCache
    )
    return cachedUser
  }

  protected async setUsersInCache(users: PublicUser[]): Promise<void> {
    for (const user of users) {
      await this.setUserInCache(user)
    }
  }

  protected async setUserInCache(user: PublicUser): Promise<void> {
    const skipCache = true
    await this.common.kvStore.set(
      `userid-${user.id}`,
      user,
      USER_CACHE_TTL_SECONDS,
      skipCache
    )
    await this.common.kvStore.set(
      `username-${user.username}`,
      user,
      USER_CACHE_TTL_SECONDS,
      skipCache
    )
    if (user.lensProfileId != null)
      await this.common.kvStore.set(
        `lensprofileid-${user.lensProfileId}`,
        user,
        USER_CACHE_TTL_SECONDS,
        skipCache
      )
    this.common.pubsub.publish(user.id, user)
  }

  // getUserByIdFromAPI accepts a userId and returns a user
  protected async getUserByIdFromAPI(id: string): Promise<PublicUser | null> {
    const req = new FindUsersDto()
    req.ids = [id]
    req.limit = 1
    const res = await this.getUsersByDto(req)
    const users = res.data.data
    if (users.length !== 1) {
      return null
    }
    return users[0]
  }

  // getUserByUsernameFromAPI accepts a username and returns a user
  public async getUserByUsernameFromAPI(
    username: string
  ): Promise<PublicUser | null> {
    const req = new FindUsersDto()
    req.username = username
    req.isUsernameExact = true
    req.limit = 1
    const res = await this.getUsersByDto(req)
    const users = res.data.data
    if (users.length !== 1) {
      return null
    }
    await this.setUserInCache(users[0])
    return users[0]
  }

  public async getUserByLensIdByAPI(
    lensProfileId: string
  ): Promise<PublicUser | null> {
    const req = new FindUsersDto()
    req.lensProfileId = lensProfileId
    req.limit = 1
    const res = await this.getUsersByDto(req)
    const users = res.data.data
    if (users.length !== 1) {
      return null
    }
    await this.setUserInCache(users[0])
    return users[0]
  }

  public async searchUsers(
    search: string,
    limit = 10,
    offset = 0
  ): Promise<APIResponse<PaginatedResponse<PublicUser>>> {
    const req = new FindUsersDto()
    req.search = search
    req.limit = limit
    req.offset = offset
    req.sort = 'popular'
    const res = await this.getUsersByDto(req)
    return res
  }

  // getUserById accepts a userId and returns a user from cache if possible
  public async getUserById(
    id: string,
    skipCache = false
  ): Promise<PublicUser | null> {
    const cachedUser = await this.getUserFromCache(id, skipCache)
    if (cachedUser !== null) {
      return cachedUser
    }
    const user = await this.getUserByIdFromAPI(id)
    return user
  }

  // getUserByLensId accepts a lensProfileId and returns a user from cache if possible
  public async getUserByLensId(
    lensProfileId: string,
    skipCache = false
  ): Promise<PublicUser | null> {
    const lensId =
      lensProfileId[0] === '_' ? lensProfileId.slice(1) : lensProfileId
    const cachedUser = await this.getUserFromCacheByLensId(lensId, skipCache)
    if (cachedUser !== null) {
      return cachedUser
    }
    const user = await this.getUserByLensIdByAPI(lensId)
    return user
  }

  /**
   * getFollowList fetches either a follower or a following list for a userId
   * @param listType either follower or following
   * @param userId the userId to get the list for
   * @param limit number of items per page
   * @param offset offset
   * @returns PaginatedResponse<PublicUser>
   */
  protected async getFollowList(
    listType: 'follower' | 'following',
    userId: string,
    limit: number = 10,
    offset: number = 0
  ): Promise<PaginatedResponse<PublicUser>> {
    const dto = new GetFollowersDto()
    dto.userId = userId
    dto.limit = limit
    dto.offset = offset
    const res = await this.common.jsonFetcher.fetchJSON<
      PaginatedResponse<PublicUser>
    >('GET', `/api/user/${listType}-users`, dto)
    if (res.isSuccess) {
      await this.setUsersInCache(res.data.data)
      return res.data
    } else {
      console.error(res)
      throw new Error(`Failed to get ${listType} list for user ${userId}`)
    }
  }

  /**
   * @deprecated Use getUserFollowers
   */
  public async getFollowerList(
    userId: string,
    limit: number = 10,
    offset: number = 0
  ): Promise<PaginatedResponse<PublicUser>> {
    return await this.getFollowList('follower', userId, limit, offset)
  }

  public getFollowersList(
    dto: GetFollowersDto
  ): PaginatedGenerator<PublicUser> {
    const queryArgs = ParamsCapableToPlainJSON(dto)
    const res = this.common.jsonFetcher.fetchPaginatedJSON<PublicUser>(
      `/api/user/follower-users`,
      queryArgs
    )
    return res
  }

  public async getFollowingList(
    userId: string,
    limit: number = 10,
    offset: number = 0,
    skipCache = false
  ): Promise<PaginatedResponse<PublicUser>> {
    return await this.getFollowList('following', userId, limit, offset)
  }

  public getFollowingsList(
    dto: GetFollowersDto
  ): PaginatedGenerator<PublicUser> {
    const queryArgs = ParamsCapableToPlainJSON(dto)
    const res = this.common.jsonFetcher.fetchPaginatedJSON<PublicUser>(
      `/api/user/following-users`,
      queryArgs
    )
    return res
  }

  public async getUserProfile(
    identifier: string,
    details: boolean,
    lens = false
  ): Promise<UserProfilePageModel | null> {
    const params = new GetUserProfileQueryDto()
    params.details = details
    if (lens) {
      params.lensprofileid =
        identifier[0] === '_' ? identifier.slice(1) : identifier
    } else {
      params.username = identifier
    }
    const res = await this.common.jsonFetcher.fetchJSON<UserProfilePageModel>(
      'GET',
      '/api/user/profile',
      params
    )
    if (!res.isSuccess) {
      console.error(
        `Failed to fetch user profile for ${identifier} with status ${res.status} - `,
        res.data
      )
      return null
    }
    const user = res.data
    await this.setUserInCache(user)
    return user
  }

  public async getFollowRecommendations(
    limit: number = 6,
    offset: number = 0
  ): Promise<PaginatedResponse<PublicUser>> {
    const req = new FindUsersDto()
    req.limit = limit
    req.offset = offset
    req.sort = 'popular'
    req.ignoreFollowed = true
    const res = await this.getUsersByDto(req)
    return res.data
  }

  public async updateUser(
    userId: string,
    data: UpdateUserDto
  ): Promise<boolean> {
    const res = await this.common.jsonFetcher.fetchJSON<{ data: User }>(
      'PUT',
      `/api/user/${userId}/profile`,
      undefined,
      data
    )
    // in case of success, update user in self cache
    if (res.isSuccess) {
      const newUser = res.data.data
      await this.selfService.getSelf()
      await this.setUserInCache(newUser)
    }
    return res.isSuccess
  }
}
