import { ListCategoriesDto } from 'ymca/dtos/category.dto'
import { PaginatedResponse } from 'ymca/dtos/common.dto'
import { Category } from 'ymca/models/category.model'
import { APIResponse } from 'ymca/jsonFetcher'
import { BaseService } from './base.service'

export const CATEGORY_CACHE_TTL_SECONDS = 300 // 5 minutes
export const CATEGORY_PAGE_SIZE = 100 // you can set a smaller number to test!

export function getCategorySlugFromName (name: string): string {
  return name.toLowerCase().replace(/\W+/g, '-')
}

export class CategoryService extends BaseService {
  protected async getCategoryFromCacheById (
    id: string,
    skipCache: boolean
  ): Promise<Category | null> {
    const cachedCategory = await this.common.kvStore.get<Category>(`categoryid-${id}`, skipCache)
    return cachedCategory
  }

  protected async getCategoryFromCacheBySlug (
    slug: string,
    skipCache: boolean
  ): Promise<Category | null> {
    const cachedCategory = await this.common.kvStore.get<Category>(`categoryslug-${slug}`, skipCache)
    return cachedCategory
  }

  protected async setCategoryInCache (category: Category, skipCache: boolean): Promise<void> {
    skipCache = true
    await this.common.kvStore.set(`categoryid-${category.id}`, category, CATEGORY_CACHE_TTL_SECONDS, skipCache)
    await this.common.kvStore.set(`categoryslug-${getCategorySlugFromName(category.name)}`, category, CATEGORY_CACHE_TTL_SECONDS)
    this.common.pubsub.publish(category.id, category)
  }

  protected async getCategoriesByDto (
    req: ListCategoriesDto
  ): Promise<APIResponse<PaginatedResponse<Category>>> {
    const res = await this.common.jsonFetcher.fetchJSON<PaginatedResponse<Category>>('GET', '/api/category', req)
    const categories = res?.data?.data
    if (!Array.isArray(categories)) {
      const msg = `Failed to fetch categories from API: ${JSON.stringify(req)}`
      console.error(msg)
      console.error(res)
      throw new Error(msg)
    }
    for (const category of categories) {
      await this.setCategoryInCache(category, false)
    }
    return res
  }

  public async getAllCategories (
    limit: number = 20,
    offset: number = 0
  ): Promise<APIResponse<PaginatedResponse<Category>>> {
    const req = new ListCategoriesDto()
    req.limit = limit
    req.offset = offset
    const res = await this.getCategoriesByDto(req)
    return res
  }

  public async getTrendingCategoryNames (
    limit: number = 20,
    offset: number = 0
  ): Promise<string[]> {
    const res = await this.getAllCategories(limit, offset)
    const categories = res?.data?.data
    const categoryNames = categories.map((category) => category.name)
    return categoryNames
  }

  protected async getCategoriesByIdsFromAPIImpl (
    ids: string[]
  ): Promise<Category[]> {
    const req = new ListCategoriesDto()
    req.ids = ids
    req.limit = ids.length
    const res = await this.getCategoriesByDto(req)
    const categories = res?.data?.data
    if (categories.length !== ids.length) {
      const msg = `fetchCategoriesByIdsImpl: expected ${ids.length} categories, got ${categories.length}`
      console.error(msg)
    }
    return categories
  }

  protected async getCategoriesByIdsFromAPI (
    ids: string[]
  ): Promise<Category[]> {
    const allCategories: Category[] = []
    // fetch CATEGORY_PAGE_SIZE userIds at a time
    for (let i = 0; i < ids.length; i += CATEGORY_PAGE_SIZE) {
      const thisPage = ids.slice(i, i + CATEGORY_PAGE_SIZE)
      const thisPageCategories = await this.getCategoriesByIdsFromAPIImpl(thisPage)
      allCategories.push(...thisPageCategories)
    }
    return allCategories
  }

  public async getCategoriesByIds (
    ids: string[],
    skipCache = false
  ): Promise<Map<string, Category>> {
    const res = new Map<string, Category>()
    const uncachedIds: string[] = []
    for (const id of ids) {
      const cachedCategory = await this.getCategoryFromCacheById(id, skipCache)
      if (cachedCategory !== null) {
        res.set(id, cachedCategory)
      } else {
        uncachedIds.push(id)
      }
    }
    console.log(`Found ${res.size} category ids in cache, fetching ${uncachedIds.length} uncached`)
    const uncachedCategories = await this.getCategoriesByIdsFromAPI(uncachedIds)
    for (const category of uncachedCategories) {
      res.set(category.id, category)
    }
    return res
  }

  protected async getCategoryBySlugFromAPI (
    slug: string
  ): Promise<Category | null> {
    const req = new ListCategoriesDto()
    req.slug = [slug]
    req.limit = 1
    const res = await this.getCategoriesByDto(req)
    const categories = res?.data?.data
    if (categories.length !== 1) {
      return null
    }
    return categories[0]
  }

  public async getCategoryBySlug (
    slug: string
  ): Promise<Category | null> {
    const cachedCategory = await this.getCategoryFromCacheBySlug(slug, false)
    if (cachedCategory !== null) {
      return cachedCategory
    }
    const category = await this.getCategoryBySlugFromAPI(slug)
    if (category !== null) {
      await this.setCategoryInCache(category, false)
    }
    return category
  }
}
