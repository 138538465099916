import { BaseService, CommonArgs } from './base.service'
import { GetReactionsDto } from 'ymca/dtos/reaction.dto'
import { PostIdDto } from '../dtos/post.dto'
import { Reaction, ReactionType } from 'ymca/models/reaction.model'
import { SelfService } from './self.service'
import { getEmptyPaginatedResponse, PaginatedResponse } from 'ymca/dtos/common.dto'
import { CreateReactionDto } from '../dtos/reaction.dto'
import { LensService } from './lens.service'

export const REACTION_CACHE_TTL_SECONDS = 60 // 1 minute
export const REACTION_PAGE_SIZE = 100 // you can set a smaller number to test!

export class ReactionService extends BaseService {
  protected selfService: SelfService
  protected lensService: LensService

  public constructor (
    common: CommonArgs,
    selfService: SelfService,
    lensService: LensService
  ) {
    super(common)
    this.selfService = selfService
    this.lensService = lensService
  }

  protected async getReactionsByDto (dto: GetReactionsDto): Promise<PaginatedResponse<Reaction>> {
    const selfId = this.selfService.getId()
    if (selfId === '') {
      return getEmptyPaginatedResponse<Reaction>()
    }
    const res = await this.common.jsonFetcher.fetchJSON<PaginatedResponse<Reaction>>('GET', '/api/reaction', dto)
    if (!res.isSuccess) {
      const msg = `Failed to get reactions: ${JSON.stringify(res)}`
      console.error(msg)
      throw new Error(msg)
    }
    for (const reaction of res.data.data) {
      await this.setReactionInCache(reaction)
    }
    return res.data
  }

  protected getReactionStateKey (userId: string, postId: string): string {
    return `reaction:${userId}:${postId}`
  }

  public getSelfReactionStateKey (postId: string): string {
    return this.getReactionStateKey(this.selfService.getId(), postId)
  }

  protected async setReactionInCache (reaction: Reaction): Promise<void> {
    const skipCache = true
    const key = this.getReactionStateKey(reaction.fkUserId, reaction.fkPostId)
    await this.common.kvStore.set(key, reaction.reactionType, REACTION_CACHE_TTL_SECONDS, skipCache)
    this.common.pubsub.publish(key, reaction.reactionType)
  }

  protected async getReactionFromCache (userId: string, postId: string, skipCache: boolean): Promise<ReactionType | null> {
    const key = this.getReactionStateKey(userId, postId)
    const reactionType = await this.common.kvStore.get<ReactionType>(key, skipCache)
    return reactionType
  }

  /**
   * getReactionsForPostIds returns a list of reactions for the given postIds.
   * It returns an array of reactions; so the caller would need to convert it
   * to an appropriate lookup structure.
   * @param postIds list of postIds to check reactions for
   * @returns map of postId to reaction type
   */
  protected async getReactionsForPostIds (postIds: string[]): Promise<Reaction[]> {
    const res: Reaction[] = []
    for (let i = 0; i < postIds.length; i += REACTION_PAGE_SIZE) {
      const page = postIds.slice(i, i + REACTION_PAGE_SIZE)
      const dto = new GetReactionsDto()
      dto.postIds = page
      dto.limit = REACTION_PAGE_SIZE
      dto.offset = i
      const reactions = await this.getReactionsByDto(dto)
      for (const reaction of reactions.data) {
        res.push(reaction)
      }
    }
    return res
  }

  /**
   * getPostIdReactionMap returns a map of postId to reaction type for
   * the given postIds. It will first check the cache for the reactions,
   * then fetch the missing ones from the server.
   * @param postIds list of postIds to check reactions for
   * @returns map of postId to reaction type
   */
  public async getPostIdReactionMap (postIds: string[], skipCache = false): Promise<Map<string, ReactionType>> {
    const reactions = new Map<string, ReactionType>()
    const missingInCache: string[] = []
    const userId = this.selfService.getId()
    if (userId === '') {
      return reactions
    }
    for (const postId of postIds) {
      const reactionInCache = await this.getReactionFromCache(userId, postId, skipCache)
      if (reactionInCache !== null) {
        reactions.set(postId, reactionInCache)
      } else {
        missingInCache.push(postId)
      }
    }
    if (missingInCache.length === 0) {
      return reactions
    }
    const newReactionList = await this.getReactionsForPostIds(missingInCache)
    const newReactions = new Map<string, ReactionType>()
    for (const reaction of newReactionList) {
      newReactions.set(reaction.fkPostId, reaction.reactionType)
    }
    for (const postId of missingInCache) {
      const reactionType = newReactions.get(postId) ?? ''

      reactions.set(postId, reactionType)
    }
    return reactions
  }

  public async createReaction (postId: string, reactionType: ReactionType): Promise<boolean> {
    if (postId.startsWith('_')) {
      const lensId = postId.slice(1)
      const res = await this.lensService.createReaction(lensId, true)
      return res
    }
    const dto = new CreateReactionDto()
    dto.postId = postId
    dto.reactionType = reactionType
    const res = await this.common.jsonFetcher.fetchJSON('POST', '/api/reaction', dto)
    if (res.isSuccess) {
      // cache this reaction for current user Id
      const selfId = this.selfService.getId()
      if (selfId !== '') {
        await this.setReactionInCache({
          fkUserId: selfId,
          fkPostId: postId,
          reactionType
        })
      }
    }
    return res.isSuccess
  }

  public async deleteReaction (postId: string): Promise<boolean> {
    if (postId.startsWith('_')) {
      const lensId = postId.slice(1)
      const res = await this.lensService.deleteReaction(lensId, true)
      return res
    }
    const dto = new PostIdDto()
    dto.postId = postId
    const res = await this.common.jsonFetcher.fetchJSON('DELETE', '/api/reaction', dto)
    if (res.isSuccess) {
      // cache this reaction for current user Id
      const selfId = this.selfService.getId()
      if (selfId !== '') {
        await this.setReactionInCache({
          fkUserId: selfId,
          fkPostId: postId,
          reactionType: ''
        })
      }
    }
    return res.isSuccess
  }
}
