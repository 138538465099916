import { useState } from 'react'
import { Button } from '@material-ui/core'
import { Menu } from '@mui/material'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  VKShareButton,
  WhatsappShareButton
} from 'react-share'

import { imagesSvg } from 'constants/images'
import Typography from 'components/atoms/Typography'
import { Post as PostModel } from 'ymca/models/post.model'
import useShareDropDownStyles from './style'
import { UseMenuResult } from 'hooks/useMenu'
import Image from 'components/atoms/Image'

interface ShareDropDownProps {
  post: PostModel
  onCopy: () => void
  onClose: () => void
  emailProps: {
    body: string
    subject: string
  }
  shareSocialMenuProps: UseMenuResult
}
const ShareDropDown = ({
  post,
  onCopy,
  emailProps,
  onClose,
  shareSocialMenuProps
}: ShareDropDownProps) => {
  const classes = useShareDropDownStyles()

  const [showMoreSocial, setShowMoreSocial] = useState<boolean>(false)
  const { isOpen, anchorEl } = shareSocialMenuProps
  const { body, subject } = emailProps

  return (
    <Menu
      anchorEl={anchorEl}
      id='share-social-menu'
      aria-labelledby='share-to-social-menu'
      open={isOpen}
      onClose={onClose}
      className={classes.shareMenu}
      transformOrigin={{
        horizontal: 'center',
        vertical: 'top'
      }}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
    >
      <TwitterShareButton
        windowWidth={750}
        windowHeight={600}
        className={classes.shareItem}
        title={post.title}
        url={post.seo.url}
        via={'YouMemeWorld'}
      >
        <Image
          alt='twitter icon'
          src={`shareIcons/${imagesSvg.mobileTwitter}`}
        />
        <Typography>Share on Twitter</Typography>
      </TwitterShareButton>
      <WhatsappShareButton
        windowWidth={750}
        windowHeight={600}
        title={post.title}
        url={post.seo.url}
        className={classes.shareItem}
      >
        <Image
          alt='whatsapp icon'
          src={`shareIcons/${imagesSvg.mobileWhatsapp}`}
        />
        <Typography>Share on Whatsapp</Typography>
      </WhatsappShareButton>

      <FacebookShareButton
        windowWidth={750}
        windowHeight={600}
        quote={`${post.title} via @YouMemeWorld`}
        url={post.seo.url}
        className={classes.shareItem}
      >
        <Image
          alt='facebook icon'
          src={`shareIcons/${imagesSvg.mobileFacebook}`}
        />
        <Typography>Share on Facebook</Typography>
      </FacebookShareButton>
      <RedditShareButton
        windowWidth={750}
        windowHeight={600}
        title={post.title}
        url={post.seo.url}
        className={classes.shareItem}
      >
        <Image alt='reddit icon' src={`shareIcons/${imagesSvg.reddit}`} />
        <Typography>Share on Reddit</Typography>
      </RedditShareButton>
      <LinkedinShareButton
        windowWidth={750}
        windowHeight={600}
        title={post.title}
        summary={post.description}
        source={'https://www.linkedin.com/company/youmeme/'}
        url={post.seo.url}
        className={classes.shareItem}
      >
        <Image
          alt='linkedin icon'
          src={`shareIcons/${imagesSvg.mobileLinkedin}`}
        />

        <Typography>Share on LinkedIn</Typography>
      </LinkedinShareButton>
      {showMoreSocial && (
        <>
          <TelegramShareButton
            windowWidth={750}
            windowHeight={600}
            title={post.title}
            url={post.seo.url}
            className={classes.shareItem}
          >
            <Image
              alt='telegram icon'
              src={`shareIcons/${imagesSvg.mobileTelegram}`}
            />
            <Typography>Share on Telegram</Typography>
          </TelegramShareButton>

          <PinterestShareButton
            windowWidth={750}
            windowHeight={600}
            url={post.seo.url}
            media={post.meme.renderedImage.jpegUrl}
            description={post.title}
            className={classes.shareItem}
          >
            <Image
              alt='pinterest icon'
              src={`shareIcons/${imagesSvg.mobilePinterest}`}
            />
            <Typography>Share on Pinterest</Typography>
          </PinterestShareButton>

          <VKShareButton
            windowWidth={750}
            windowHeight={600}
            title={post.title}
            image={post.seo.image}
            url={post.seo.url}
            className={classes.shareItem}
          >
            <Image alt='vk icon' src={`shareIcons/${imagesSvg.mobileVk}`} />
            <Typography>Share on VKontakte</Typography>
          </VKShareButton>
          <EmailShareButton
            subject={subject}
            className={classes.shareItem}
            body={body}
            url={post.seo.url}
          >
            <Image
              src={`shareIcons/${imagesSvg.envelope}`}
              alt='share email icon'
            />
            <Typography>Share via email</Typography>
          </EmailShareButton>
          <CopyToClipboard text={post.seo.url} onCopy={onCopy}>
            <button className={classes.mobilesharecopybutton}>
              <>
                <Image
                  src={`reactionIcons/${imagesSvg.copyLink}`}
                  alt='copy link icon'
                  className={classes.imgStyle}
                />
                <span id='copymemetext'>Copy Link</span>
              </>
            </button>
          </CopyToClipboard>
        </>
      )}
      <Button
        onClick={() => setShowMoreSocial(!showMoreSocial)}
        className={`${classes.shareItem} showMoreBtn`}
      >
        <svg
          width='28'
          height='28'
          viewBox='0 0 28 28'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M19.3725 12.3731C19.763 11.9826 19.763 11.3494 19.3725 10.9589C18.9819 10.5684 18.3488 10.5684 17.9583 10.9589L19.3725 12.3731ZM13.9987 16.3327L13.2916 17.0398C13.4791 17.2273 13.7335 17.3327 13.9987 17.3327C14.2639 17.3327 14.5183 17.2273 14.7058 17.0398L13.9987 16.3327ZM10.0391 10.9589C9.64861 10.5684 9.01545 10.5684 8.62492 10.9589C8.2344 11.3494 8.2344 11.9826 8.62492 12.3731L10.0391 10.9589ZM17.9583 10.9589L13.2916 15.6256L14.7058 17.0398L19.3725 12.3731L17.9583 10.9589ZM14.7058 15.6256L10.0391 10.9589L8.62492 12.3731L13.2916 17.0398L14.7058 15.6256Z'
            fill='#8A98B4'
          />
        </svg>
      </Button>
    </Menu>
  )
}

export default ShareDropDown
