const globals = {
  html: {
    scrollBehavior: "smooth",

    // fontSize: "100%" /* 100% of 16px = 16px */,

    // [`@media (max-width: ${BREAKPOINT_MOBILE_LARGE}px)`]: {
    //   fontSize: "87.5%" /* 87.5% of 16px = 14px */,
    // },
    // [`@media (max-width: ${BREAKPOINT_MOBILE_MEDIUM}px)`]: {
    //   fontSize: "75%" /* 75% of 16px = 12px */,
    // },
    // [`@media (max-width: ${BREAKPOINT_MOBILE_SMALL}px)`]: {
    //   fontSize: "62.5%" /* 62.5% of 16px = 10px */,
    // },

    /* Track */
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "1rem",
      backgroundColor: "transparent",
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "transparent",
    },

    // /* Firefox Scrollbar styling */
    scrollbarColor: "transparent transparent",
    scrollbarWidth: "none !important",

    /* IE Scrollbar styling */
    scrollbarFaceColor: "transparent",
    scrollbarShadowColor: "transparent",
    scrollbarHighlightColor: "transparent",

  },

  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },

  "a, a:link, a:visited": {
    textDecoration: "none",
  },
};

export default globals;
