import { Button, Modal, Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { useRef, useState } from 'react'
import CameraIcon from '@material-ui/icons/Camera'
import { Snackbar, Box } from '@mui/material'

import { sendImageToMemeEditor } from '..'
import { RadarIcon } from 'meme-generator/components/shared/icons/RadarIcon'
import { AddLinkIcon } from 'meme-generator/components/shared/icons/AddLinkIcon'
import { FileTypeMap } from 'constants/meme'
import { extractCanvasBase64FromTemplateBlob } from 'utils/utils'
import config from 'utils/config'
import useWindowSize from 'hooks/useWindowSize'
import TextToMeme from 'meme-generator/AI/TextToMeme'
import Lottie from 'lottie-react'
import FileUploadAnimation from '../../../assets/animations/fileUpload.json'
import useFileUploadStyles from './style'
import { BREAKPOINT_TABLET_MEDIUM } from 'theme/shared/breakpoint'
import languageDetector from '../../../services/ai/language-detector.service'
import MemeTemplate from '../MemeTemplate'
import AtomLoading from 'components/atoms/AtomLoading'
import FaceSwap from 'meme-generator/AI/FaceSwap'
import { useAuth } from '../../../context/auth/authContext'
import objectDetectorService from '../../../services/ai/object-detector.service'
import MemeCam from 'meme-generator/AI/MemeCam'
import usePopover from 'hooks/usePopover'
import AuthPopover from 'components/organisms/AuthPopover'
import isMemeTemplate from 'utils/isMemeTemplate'
import { checkResolution, processFile } from 'utils/create-meme'

interface FileUploadProps {
  isOpen: boolean
  navigate: (props: any) => VoidFunction
  handleTab: (tab: number) => VoidFunction
  type?: number
}
const FileUpload = (props: FileUploadProps) => {
  const { isOpen, navigate, handleTab, type } = props

  const classes = useFileUploadStyles()

  const otherComponentsRef = useRef()
  const [open, setOpen] = useState(false)
  const [filesDragged, setFilesDragged] = useState(false)
  const [loadingImage, setLoadingImage] = useState(false)
  const [selectedTab, _setSelectedTab] = useState<number>(type || 0)
  const selectedTabRef = useRef(selectedTab)

  const [err, setErr] = useState<boolean>(false)
  const [msg, setMsg] = useState<string>('')
  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const isMobile = useWindowSize().width <= BREAKPOINT_TABLET_MEDIUM
  const { isAuthenticated } = useAuth()

  const AiLabel = () => {
    return (
      <div className={classes.ButtonLabel}>
        <span>AI</span>
      </div>
    )
  }

  const {
    anchorEl: authPopoverAnchorEl,
    isOpen: isAuthPopoverOpen,
    open: openAuthPopover,
    close: closeAuthPopover
  } = usePopover()

  if (!isOpen) return null

  const setSelectedTab = (value: number) => {
    _setSelectedTab(value)
    selectedTabRef.current = value
  }

  const showError = (msg: string) => {
    setErr(true)
    setMsg(msg)
    setTimeout(() => setErr(false), 5000)
  }

  const handleOnTextToMemeClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!isAuthenticated) {
      openAuthPopover(e)
      return
    }
    setSelectedTab(1)
    languageDetector.loadModel().then(() => {
      console.debug('Language detector model loaded')
    })
  }

  const handleOnSwapFaceClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!isAuthenticated) {
      openAuthPopover(e)
      return
    }
    setSelectedTab(2)
    setSelectedImage(null)
    objectDetectorService.loadModel()
  }

  const handleMemecamClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!isAuthenticated) {
      openAuthPopover(e)
      return
    }
    setSelectedTab(3)
    setSelectedImage(null)
  }

  const handleSwitch = () => {
    setOpen(true)
  }

  // For choose file
  const openFileChooser = (e: any) => {
    document?.getElementById?.('imgInput')?.click()
  }
  const getImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    await handleProcessFile(event.target?.files?.[0])
  }

  const handleProcessFile = async (file: any) => {
    try {
      if (file) {
        let input = document.getElementById('imgInput') as HTMLInputElement
        if (input) {
          input.value = ''
        }
        const processfileRes = await processFile({ file })
        if (selectedTabRef.current === 0) {
          const a = processfileRes
          setOpen(false)
          navigate(a)
        } else if (selectedTabRef.current === 2) {
          setSelectedImage(processfileRes.data)
        }
      }
    } catch (error: any) {
      showError(error.message)
    }
  }

  const pushImageToEditor = async (imgData: any) => {
    if (imgData) {
      try {
        const res = await checkResolution(imgData, FileTypeMap.image)
        const a = {
          data: imgData,
          imageResolution: res.data,
          type: 'notSvg',
          imageType: 'PNG',
          fileType: FileTypeMap.image
        }
        setOpen(false)
        navigate(a)
      } catch (error) {
        console.log(err)
      }
    }
  }

  const dragListener = (event: any) => {
    event.preventDefault()
    setFilesDragged(false)
    const files = event.dataTransfer.files
    if (files.length === 1) {
      handleProcessFile(files[0])
    } else {
      showError('Invalid Drop')
    }
  }

  const handleAIMemeGeneration = async (
    image: any,
    captionString: any,
    type: any
  ) => {
    try {
      if (!image) {
        throw new Error('Image not supplied properly, please try again!')
      }
      const resolutionRes = await checkResolution(image, FileTypeMap.image)
      const a = {
        data: image,
        imageResolution: resolutionRes.data,
        type: 'notSvg',
        imageType: type.toUpperCase(),
        fileType: FileTypeMap.image,
        caption: captionString
      }
      setOpen(false)
      navigate(a)
    } catch (error: any) {
      showError(error.message)
      console.log(error)
    }
  }

  const processSuggestedTemplateSelection = async (url: string) => {
    if (!url) return

    try {
      if (selectedTabRef.current === 0) {
        setLoadingImage(true)
        const imageData = await sendImageToMemeEditor(url)
        setOpen(false)
        setLoadingImage(false)
        navigate(imageData)
      } else if (selectedTabRef.current === 2) {
        const isTemplate = isMemeTemplate(url)
        setSelectedImage(null)
        let urlData = ''
        if (isTemplate) {
          try {
            const blob = await fetch(url).then(async (res) => await res.blob())
            urlData = await extractCanvasBase64FromTemplateBlob(blob)
          } catch (err) {
            console.error(`Error processing SVG template ${url}`, err)
          }
        }
        setSelectedImage(urlData)
      }
    } catch (error) {
      showError('Failed to load image')
      setLoadingImage(false)
    }
  }

  return (
    <>
      <Box className={classes.btnsContainer}>
        <Button
          className={`${classes.button} ${selectedTab === 0 ? 'selected' : ''}`}
          onClick={() => {
            setSelectedTab(0)
            setSelectedImage(null)
          }}
          style={
            !config.aiMemeGeneratorEnabled && isMobile ? { width: '100%' } : {}
          }
        >
          Create Meme
        </Button>
        {config.aiMemeGeneratorEnabled && (
          <Button
            // disabled={!isAuthenticated}
            className={`${classes.button} ${
              selectedTab === 1 ? 'selected' : ''
            }`}
            onClick={handleOnTextToMemeClick}
          >
            Text-to-Meme
            <AiLabel />
          </Button>
        )}
        {config.aiFaceSwapEnabled && (
          <Button
            // disabled={!isAuthenticated}
            className={`${classes.button} ${
              selectedTab === 2 ? 'selected' : ''
            }`}
            onClick={handleOnSwapFaceClick}
          >
            Swap Faces
            <AiLabel />
          </Button>
        )}
        {config.aiMemecamEnabled && (
          <Button
            className={`${classes.button} ${
              selectedTab === 3 ? 'selected' : ''
            }`}
            onClick={handleMemecamClick}
          >
            {isMobile ? 'Meme Cam' : 'MemeCam'}
            <AiLabel />
          </Button>
        )}
      </Box>
      {(selectedTab === 0 || selectedTab === 2) && (
        <>
          {selectedImage === null ? (
            <Box className={classes.root} position='relative'>
              {loadingImage && (
                <Box
                  position='absolute'
                  className={classes.onTemplateSelectLoader}
                >
                  <AtomLoading />

                  <Typography component='p'>
                    Loading Image to Editor. Do not close!
                  </Typography>
                </Box>
              )}
              <button
                className={`${classes.moreOptionBtn} ${classes.tabTitle}`}
                onClick={handleSwitch}
              >
                More Options
              </button>
              <MemeTemplate
                classes={classes}
                handleImageSelection={(url: any) =>
                  processSuggestedTemplateSelection(url)
                }
                tab={selectedTab === 2 ? 'face_swap' : 'default'}
              />

              <Box
                ref={otherComponentsRef}
                className={classes.otherOptionsWrapper}
              >
                <Box
                  className={
                    filesDragged
                      ? `${classes.dragwrapper} ${classes.dragged}`
                      : classes.dragwrapper
                  }
                  onDrop={dragListener}
                  onDragLeave={() => setFilesDragged(false)}
                  onDragEnter={() => setFilesDragged(true)}
                  onDragOver={(e) => {
                    e.preventDefault()
                  }}
                  onClick={openFileChooser}
                >
                  <Box
                    style={{
                      zIndex: 999,
                      position: 'absolute',
                      background: 'red',
                      width: '100%',
                      height: '100%',
                      display: err === true ? 'flex' : 'none',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 5
                    }}
                  >
                    <Alert severity='error'>{err === true && msg}</Alert>
                  </Box>
                  <input
                    type='file'
                    accept='image/*, video/*'
                    id='imgInput'
                    onChange={getImage}
                    style={{ display: 'none' }}
                  />
                  <Typography className={`${classes.dragTitle} text-title`}>
                    Drop a selfie or
                  </Typography>
                  <Typography className={classes.dragTxtOne}>
                    {'Upload Image' + (selectedTab === 0 ? '/Video' : '')}
                  </Typography>
                  <Lottie animationData={FileUploadAnimation} loop={true} />
                </Box>
                <Box className={classes.tabBox}>
                  <Box
                    className={`${classes.tabItem} ${classes.delayPoint2Sec}`}
                    onClick={() => handleTab(1)}
                  >
                    <RadarIcon htmlColor='#fff' />
                    <Typography className={classes.tabTitle}>Search</Typography>
                  </Box>
                  <Box
                    className={`${classes.tabItem} ${classes.delayPoint3Sec}`}
                    onClick={() => handleTab(2)}
                  >
                    <CameraIcon htmlColor='#fff' />
                    <Typography className={classes.tabTitle}>Camera</Typography>
                  </Box>
                  <Box
                    className={`${classes.tabItem} ${classes.delayPoint4Sec}`}
                    onClick={() => handleTab(3)}
                  >
                    <AddLinkIcon htmlColor='#fff' />
                    <Typography className={classes.tabTitle}>
                      Url address
                    </Typography>
                  </Box>
                </Box>
                {isMobile && (
                  <Modal
                    onClose={() => setOpen(false)}
                    open={open}
                    style={{
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                      background: ' rgba(18, 7, 23, 0.6)'
                    }}
                  >
                    <Box className={classes.tabBox}>
                      <Typography className={classes.additionalText}>
                        More Options
                      </Typography>
                      <Box
                        className={`${classes.tabItem} ${classes.delayPoint1Sec}`}
                        onClick={openFileChooser}
                      >
                        <div className='instruction'>
                          <Typography className={classes.tabTitle}>
                            Upload
                          </Typography>
                          <span>Upload image/video</span>
                        </div>
                        <img
                          src='/assets/random/test.png'
                          alt='background pattern'
                        />
                        <img
                          src='/assets/gifs/cloud.gif'
                          alt='upload file'
                          style={{
                            width: '79px',
                            height: '79px',
                            marginRight: '1rem'
                          }}
                        />
                      </Box>

                      <Box
                        className={`${classes.tabItem} ${classes.delayPoint2Sec}`}
                        onClick={() => handleTab(1)}
                      >
                        <div className='instruction'>
                          <Typography className={classes.tabTitle}>
                            Search
                          </Typography>
                          <span>Search from a library</span>
                        </div>
                        <img
                          src='/assets/random/test.png'
                          alt='background pattern'
                        />
                        <img
                          src='/assets/gifs/search.gif'
                          alt='search file'
                          style={{
                            width: '79px',
                            height: '79px',
                            marginRight: '1rem'
                          }}
                        />
                      </Box>
                      <Box
                        className={`${classes.tabItem} ${classes.delayPoint3Sec}`}
                        onClick={() => handleTab(2)}
                      >
                        <div className='instruction'>
                          <Typography className={classes.tabTitle}>
                            Camera
                          </Typography>
                          <span>Show yourself</span>
                        </div>
                        <img
                          src='/assets/gifs/camera.gif'
                          alt='camera file'
                          style={{
                            width: '79px',
                            height: '79px',
                            marginRight: '1rem'
                          }}
                        />
                        <img
                          src='/assets/random/test.png'
                          alt='background pattern'
                        />
                      </Box>
                      <Box
                        className={`${classes.tabItem} ${classes.delayPoint4Sec}`}
                        onClick={() => handleTab(3)}
                      >
                        <div className='instruction'>
                          <Typography className={classes.tabTitle}>
                            Url address
                          </Typography>
                          <span>Copy an image link</span>
                        </div>
                        <img
                          src='/assets/gifs/paste.gif'
                          alt='url address upload'
                          style={{
                            width: '79px',
                            height: '79px',
                            marginRight: '1rem'
                          }}
                        />
                        <img
                          src='/assets/random/test.png'
                          alt='background pattern'
                        />
                      </Box>
                      <Snackbar
                        open={err}
                        autoHideDuration={5000}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center'
                        }}
                      >
                        <Alert severity='error'>{msg}</Alert>
                      </Snackbar>
                    </Box>
                  </Modal>
                )}
              </Box>
            </Box>
          ) : (
            <Box className={classes.root} position='relative'>
              <FaceSwap
                selectedImage={selectedImage}
                setSelectedImage={(value) => setSelectedImage(value)}
                pushImageToEditor={(image) => pushImageToEditor(image)}
              />
            </Box>
          )}
        </>
      )}
      {selectedTab === 1 && (
        <Box style={{ height: isMobile ? '70%' : '77%' }}>
          <TextToMeme
            btnStyle={classes.button}
            processImageWCaptions={(image, caption, type) =>
              handleAIMemeGeneration(image, caption, type)
            }
          />
        </Box>
      )}
      {selectedTab === 3 && (
        <MemeCam
          dragListener={dragListener}
          processImageWCaptions={(image, caption, type) =>
            handleAIMemeGeneration(image, caption, type)
          }
        />
      )}
      <AuthPopover
        isOpen={isAuthPopoverOpen}
        id='text_to_meme_face_swap_auth_popover'
        onClose={closeAuthPopover}
        anchorEl={authPopoverAnchorEl}
      />
    </>
  )
}

export default FileUpload
