import { makeStyles } from "@material-ui/core/styles";

const useDiamondRewardCardStyles = makeStyles({
  diamondReward: {
    backgroundColor: "#202A41",
    width: "100%",

    display: "flex",
    gap: ".25rem",
    flexDirection: "column",

    borderRadius: "0.5rem",
    border: "1px solid #2A3753",

    paddingBottom: ".25rem",
  },
  diamondRewardTop: {
    display: "flex",
    gap: ".25rem",

    height: "6rem",
    flexShrink: 0,
    flexDirection: "column",
    justifyContent: "flex-end",

    padding: "0 1rem",

    borderRadius: "0.5rem",
    background:
      "linear-gradient(205deg, rgba(32, 42, 65, 0.00) 3%, #202A41 65%), url(assets/sidebar/diamond-reward.png), lightgray 0px -19.112px / 100.565% 129.631% no-repeat",
  },
  diamondRewardMyDiamond: {
    color: "#8A98B4",
    fontSize: "0.8125rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.03125rem",
  },
  diamondRewardValue: {
    color: "#E9F0F9",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "normal",
    letterSpacing: "-0.0625rem",
  },
  diamondRewardBtn: {
    width: "100%",
    height: "2.5rem",
    flexShrink: 0,

    "border-radius": "6.25rem",
    border: " 1.5px solid #1C263B",
    background: "#28344D",
    boxShadow: "0px 2px 0px 0px #1C263B",

    color: "#E9F0F9",
    textAlign: "center",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "1.5rem",
    letterSpacing: "-0.01875rem",
  },
});

export default useDiamondRewardCardStyles;
