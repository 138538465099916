import { BaseService } from './base.service'

export interface InquiryResponse {
  subject: string
  message: string
  status: boolean
  error: any
}

export class InquiryService extends BaseService {
  public async sendMessage (email: string, subject: string, message: string): Promise<InquiryResponse> {
    const dto = {
      email,
      subject,
      message
    }
    const res = await this.common.jsonFetcher.fetchJSON('POST', '/api/inquiry', undefined, dto, 'application/json', true)
    if (res.isSuccess) {
      return {
        subject,
        message,
        status: true,
        error: null
      }
    } else {
      return {
        subject,
        message,
        status: false,
        error: res
      }
    }
  }
}
