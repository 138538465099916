import { TextField as TextFieldDefault } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

const TextField = styled(TextFieldDefault)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-root": {
    borderRadius: "0.9375rem",
    position: "relative",
    border: "1px solid #404C67",
    "box-shadow": "0px 4px 8px 0px rgba(14, 20, 34, 0.20)",
    padding: ".75rem 1rem",

    color: "#8A98B4",
    "font-family": "Inter",
    "font-size": "0.8rem",
    "font-style": "normal",
    "font-weight": 500,
    "line-height": "1.375rem",
    "letter-spacing": "-0.03125rem",

    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default TextField;
