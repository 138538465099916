import { imagesJpg, imagesPng } from 'constants/images'
import { Image, ImageFormat, MimeType, mimetypeToFormat, IMAGE_FORMATS, IMAGE_PURPOSES } from 'ymca/models/image.model'
import { BaseService, CommonArgs } from './base.service'

/**
 * These images will be used if the image is not found
 */
export const DEFAULT_AVATAR_IMAGE: string = `/assets/profileIcons/${imagesPng.profile}`
export const DEFAULT_BACKGROUND_IMAGE: string = `/assets/profileIcons/${imagesJpg.banner}`
export const DEFAULT_EVENT_IMAGE: string = `/assets/profileIcons/${imagesJpg.eventNew}`

export class ImageService extends BaseService {
  protected preferredImageFormat: ImageFormat = 'jpeg'

  public constructor (
    common: CommonArgs,
    preferredImageFormat?: ImageFormat
  ) {
    super(common)
    if (preferredImageFormat !== undefined && preferredImageFormat !== null) {
      this.preferredImageFormat = preferredImageFormat
    }
  }

  public async chooseBestImageFormat (): Promise<ImageFormat> {
    const fmt = await getBestImageFormat()
    this.preferredImageFormat = fmt
    return fmt
  }

  public getPreferredImageFormat (): ImageFormat {
    return this.preferredImageFormat
  }

  public setPreferredImageFormat (
    preferredImageFormat: ImageFormat
  ): void {
    this.preferredImageFormat = preferredImageFormat
  }

  public isImageValid (
    image: Image
  ): boolean {
    return image !== null &&
      image !== undefined &&
      typeof image === 'object' &&
      typeof image.id === 'string' &&
      typeof image.formatImage === 'string' &&
      IMAGE_FORMATS.includes(image.formatImage) &&
      typeof image.typeImage === 'string' &&
      IMAGE_PURPOSES.includes(image.typeImage) &&
      typeof image.isVerified === 'boolean'
  }
}
// Find the best supported image format from the browser
export async function getBestImageFormat (): Promise<ImageFormat> {
  const fallbackImageFormat = 'jpeg'
  if (typeof window.createImageBitmap !== 'function') return fallbackImageFormat

  return await fetch('data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=')
    .then(async (r) => await r.blob())
    .then(async (b) => await createImageBitmap(b))
    .then(
      () => 'avif',
      () => fallbackImageFormat
    )
}

export function getFileFromBase64Embed (dataURL: string): File {
  const parts = dataURL.split(';base64,')
  const contentType = parts[0].split(':')[1] as MimeType
  const raw = atob(parts[1])
  const rawLength = raw.length
  const uInt8Array = new Uint8Array(rawLength)
  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i)
  }
  const fileType = mimetypeToFormat.get(contentType) as ImageFormat
  const fileName = `image.${fileType}`
  const res = new File([uInt8Array], fileName, { type: contentType })
  console.debug(`Created file from base64 embed: ${res.name} (${res.type}) (${res.size} bytes)`)
  return res
}

// Function to fetch a File object from a given URL
export async function fileFromLink(url: string): Promise<File> {
  const response = await fetch(url)
  if (!response.ok) {
    throw new Error(`Failed to fetch file from ${url}`)
  }

  const blob = await response.blob()
  const file = new File([blob], url.split('/').pop() || 'unknown', {
    type: blob.type
  });

  return file
}

// Function to fetch a DataURL from a File object using async/await
export async function dataUrlFromFile(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result as string)
      } else {
        reject(new Error('Failed to read blob as DataURL'))
      }
    }
    reader.onerror = () => {
      reject(new Error('An error occurred while reading the blob as DataURL'))
    }
    reader.readAsDataURL(file)
  })
}

// Function to fetch a DataURL from a given URL using fileFromLink as an intermediary
export async function dataUrlFromLink(url: string): Promise<string> {
  const file = await fileFromLink(url)
  return await dataUrlFromFile(file)
}
