import { useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Image, RoundedButton } from 'components/shared/index'
import { imagesSvg } from 'constants/images'
import { placeImg } from 'constants/variables'
import colors from 'theme/dark/colors'

const useStyles = makeStyles({
  title: {
    color: '#000000',
    opacity: 1,
    fontSize: '16px',
    fontWeight: 'bold'
  },
  profileContainer: {
    display: 'flex',
    padding: '5px 20px',
    alignItems: 'center',
    height: 'auto',
    width: '100%',
    border: '1px solid #AAAAAA',
    borderRadius: '10px',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: '#EBEBEB'
    }
  },
  imageBox: {
    width: '60px',
    marginRight: '12px',
    display: 'flex',
    marginBottom: 'auto',
    '& img': {
      height: '50px',
      width: '50px',
      borderRadius: '25px'
    }
  },
  profileName: {
    color: '#000000',
    fontSize: '12px'
  },
  nameContainer: {
    padding: '0 10px',
    width: 'calc(100% - 115px)',
    boxSizing: 'border-box'
  },
  usernameContainer: {
    display: 'flex',
    '& img': {
      width: '13px',
      height: '13px',
      paddingTop: '2px',
      marginBottom: 'auto',
      marginRight: 'auto'
    }
  },
  profileUsername: {
    color: colors.mainGrey,
    marginRight: '5px',
    fontSize: '11px'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px'
  },
  minusIcon: {
    color: 'red',
    alignItems: 'center',
    display: 'flex',
    paddingLeft: '10px',
    cursor: 'pointer'
  }
})

const FollowAllItem = (): JSX.Element => {
  const classes = useStyles()

  const [followState, setFollowState] = useState<string>('Follow')

  const handleFollowState = (): void => {
    if (followState === 'Follow') {
      setFollowState('Following')
    } else {
      setFollowState('Follow')
    }
  }

  const handleMouseEnter = (): void => {
    if (followState === 'Following') setFollowState('Unfollow')
  }

  const handleMouseLeave = (): void => {
    if (followState === 'Unfollow') setFollowState('Following')
  }

  return (
    <Box className={classes.profileContainer}>
      <Box className={classes.imageBox}>
        <img src={placeImg} alt='logo' />
      </Box>

      <Box className={classes.nameContainer}>
        <Typography className={classes.profileName}>
          Jonathan Anderson
        </Typography>
        <Box className={classes.usernameContainer}>
          <Typography className={classes.profileUsername}>
            @jonathan_anderson
          </Typography>
          <Image src={`chekIcons/${imagesSvg.verifiedIcon}`} alt='logo' />
        </Box>
      </Box>
      <Box
        className={classes.buttonContainer}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <RoundedButton
          title={followState}
          color={followState === 'Follow' ? colors.mainPurple : colors.white}
          background={
            followState === 'Follow'
              ? colors.background
              : followState === 'Unfollow'
                ? '#FF4154'
                : colors.linearMainPurple
          }
          borderColor={colors.mainPurple}
          hoverColor={
            followState === 'Unfollow' ? '#FF4154' : colors.linearMainPurple
          }
          width='90px'
          padding={followState === 'Follow' ? '4px 0px' : '4px 1px'}
          fontSize='12px'
          handleSubmit={handleFollowState}
        />
      </Box>
      <Box className={classes.minusIcon}>
        <Image src={`reactionIcons/${imagesSvg.minusCircle}`} alt='minus' />
      </Box>
    </Box>
  )
}

export default FollowAllItem
