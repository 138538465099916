import { URLSearchParamsCapable } from 'ymca/jsonFetcher'
import { Image, ImagePurpose } from 'ymca/models/image.model'
import { QuerySearchParamsDto } from './common.dto'

export interface UpdateUserDto {
  username?: string
  displayName?: string
  country?: string
  urlWebsite?: string
  biography?: string
  hobbies?: string[]
  rewardEthAddress?: string
}

export class UploadProfileImageDto implements URLSearchParamsCapable {
  public purpose: ImagePurpose = 'avatar'

  public toURLSearchParams (): URLSearchParams {
    const params = new URLSearchParams()
    params.append('purpose', this.purpose)

    return params
  }
}

export interface ProfileImageUploadResponseDto {
  message: string
  image: Image
}

export type DeleteProfileImagePurpose = 'avatar' | 'background'

export class DeleteProfileImageDto implements URLSearchParamsCapable {
  public purpose: DeleteProfileImagePurpose = 'avatar'

  public toURLSearchParams (): URLSearchParams {
    const params = new URLSearchParams()
    params.append('purpose', this.purpose)

    return params
  }
}

export class GetFollowersDto extends QuerySearchParamsDto implements URLSearchParamsCapable {
  public userId: string = ''
  public toURLSearchParams (): URLSearchParams {
    const params = super.toURLSearchParams()
    params.append('userId', this.userId)
    return params
  }
}

export class GetUserProfileQueryDto implements URLSearchParamsCapable {
  public userid?: string
  public username?: string
  public lensprofileid?: string
  public details = false

  public toURLSearchParams (): URLSearchParams {
    const params = new URLSearchParams()
    if (this.userid !== undefined && this.userid !== null && this.userid !== '') {
      params.append('userid', this.userid)
    }
    if (this.username !== undefined && this.username !== null && this.username !== '') {
      params.append('username', this.username)
    }
    if (this.lensprofileid !== undefined && this.lensprofileid !== null && this.lensprofileid !== '') {
      params.append('lensprofileid', this.lensprofileid)
    }
    if (this.details) {
      params.append('details', 'true')
    }
    return params
  }
}

export class FindUsersDto extends QuerySearchParamsDto implements URLSearchParamsCapable {
  // You can search for a username/displayName
  public search?: string

  // you can pass multiple userIds in the query string separated by commas
  public ids: string[] = []

  // you can pass multiple userIds in the query string separated by commas
  public excludeUserIds: string[] = []

  // you can search by username (case sensitive for now)
  public username?: string

  // You can make the username search exact (by default it isn't)
  public isUsernameExact?: boolean

  // you can search by displayName (case sensitive for now)
  public displayName?: string

  // you can search by country (case sensitive for now)
  public country?: string

  // you can search by urlWebsite (case sensitive for now)
  public urlWebsite?: string

  // you can search by biography (case sensitive for now)
  public biography?: string

  // you filter down to a list of roles (comma separated).
  public role: string[] = []

  // you can search by email (only if you are admin/moderator)
  public email?: string

  // you can search by lensProfileId
  public lensProfileId?: string

  public joinedAfter?: number // defaults to new Date(0).getTime()

  public setJoinedAfter (date: Date): void {
    this.joinedAfter = date.getTime()
  }

  public joinedBefore?: number // defaults to new Date().getTime()

  public setJoinedBefore (date: Date): void {
    this.joinedBefore = date.getTime()
  }

  // you can search only for verified users
  public onlyVerified?: boolean

  // You can search only for Ambassador users
  public onlyAmbassadors?: boolean

  // you can search only for banned users (if you are admin/moderator)
  public onlyBanned?: boolean

  // you can search only for users that aren't followed (you are considered to be following yourself)
  public ignoreFollowed?: boolean

  // can be either latest or popular
  public sort: 'popular' | 'latest' = 'popular'

  public toURLSearchParams (): URLSearchParams {
    const params = super.toURLSearchParams()
    if (this.search !== undefined) {
      params.append('search', this.search)
    }
    if (this.ids.length > 0) {
      params.append('id', this.ids.join(','))
    }
    if (this.excludeUserIds.length > 0) {
      params.append('excludeUserId', this.excludeUserIds.join(','))
    }
    if (this.username !== undefined) {
      params.append('username', this.username)
    }
    if (this.isUsernameExact !== undefined) {
      params.append('isUsernameExact', this.isUsernameExact.toString())
    }
    if (this.displayName !== undefined) {
      params.append('displayName', this.displayName)
    }
    if (this.country !== undefined) {
      params.append('country', this.country)
    }
    if (this.urlWebsite !== undefined) {
      params.append('urlWebsite', this.urlWebsite)
    }
    if (this.biography !== undefined) {
      params.append('biography', this.biography)
    }
    if (this.role.length > 0) {
      params.append('role', this.role.join(','))
    }
    if (this.email !== undefined) {
      params.append('email', this.email)
    }
    if (this.lensProfileId !== undefined) {
      params.append('lensProfileId', this.lensProfileId)
    }
    if (this.joinedAfter !== undefined) {
      params.append('joinedAfter', this.joinedAfter.toString())
    }
    if (this.joinedBefore !== undefined) {
      params.append('joinedBefore', this.joinedBefore.toString())
    }
    if (this.onlyAmbassadors !== undefined) {
      params.append('onlyAmbassadors', this.onlyAmbassadors.toString())
    }
    if (this.onlyVerified !== undefined) {
      params.append('onlyVerified', this.onlyVerified.toString())
    }
    if (this.onlyBanned !== undefined) {
      params.append('onlyBanned', this.onlyBanned.toString())
    }
    if (this.ignoreFollowed !== undefined) {
      params.append('ignoreFollowed', this.ignoreFollowed.toString())
    }
    params.append('sort', this.sort)
    return params
  }
}

export class UserIdDto implements URLSearchParamsCapable {
  public userId: string = ''

  public toURLSearchParams (): URLSearchParams {
    const params = new URLSearchParams()
    params.append('userId', this.userId)
    return params
  }
}
