import { useState } from 'react'
import errorConfig from '../utils/config/errorConfig.json'
import imageConfig from '../utils/config/imageConfig.json'
import toast from 'react-hot-toast'
import FileUpload from './FileUpload'
import ImageByUrl from './ImageByUrl'
import './imageUpload.css'
import Layout from './Layout'
import PictureByCamera from './PictureByCamera'
import SearchImage from './SearchImage'
import { handleFiles } from '../../utils/utils'
import useWindowSize from 'hooks/useWindowSize'
import { getImageBase64FromSearchEngineURL } from 'meme-generator/utils/services'
import { imagesSvg } from 'constants/images'
import { BREAKPOINT_TABLET_MEDIUM } from 'theme/shared/breakpoint'
import isMemeTemplate from 'utils/isMemeTemplate'
import { checkResolution } from 'utils/create-meme'
import { FileTypeMap } from 'constants/meme'

export async function sendImageToMemeEditor(url) {
  try {
    const urlData = await getImageBase64FromSearchEngineURL(url)
    if (!urlData) return

    const isTemplate = isMemeTemplate(url)
    let processedSvg = null
    if (isTemplate) {
      try {
        const blob = await fetch(urlData).then(async (res) => await res.blob())
        processedSvg = await handleFiles(blob)
      } catch (err) {
        console.error(`Error processing SVG template ${url}`, err)
      }
    }

    const imageResolution = await checkResolution(urlData, FileTypeMap.image)

    const notSvgRes = {
      data: urlData,
      imageResolution,
      type: 'notSvg'
    }

    return isTemplate ? processedSvg : notSvgRes
  } catch (error) {
    throw error
  }
}

const ImageUpload = ({ navigateToPage, onClose, hide = false, uploadType = 0 }) => {
  const isMobile = useWindowSize().width <= BREAKPOINT_TABLET_MEDIUM
  const txts = [
    {
      title: 'Create',
      desc: 'Create Memes, GIFs and decorate them with captions, filters, stickers, and more.',
      logo: `headerIcons/${imagesSvg.logo}`
    },
    {
      title: !isMobile
        ? 'Browse through Unsplash for your image'
        : 'Find images',
      desc: !isMobile
        ? 'offers a huge range of images. Select one and see you in the next step!'
        : ''
    },
    {
      title: 'Use a camera to take a picture',
      desc: "Don't be shy! This is what memes are all about. Use your phone or webcam and take a picture of yourself. Make sure you give us access to your device in order to use this feature!"
    },
    {
      title: !isMobile ? 'Use a URL address' : 'URL address ',
      desc: !isMobile
        ? 'You have the option to copy and paste the image’s Url address in the textbox below. We have to let you know though, that, you can only use images that you own an '
        : ''
    }
  ]

  const [tabValue, setTab] = useState(0)
  return (
    <Layout
      hide={hide}
      txt={txts[tabValue]}
      navigate={navigateToPage}
      onClose={onClose}
      handleTab={setTab}
      flag={tabValue}
    >
      {tabValue === 0 && (
        <FileUpload
          isOpen={tabValue === 0}
          navigate={navigateToPage}
          handleTab={setTab}
          type={uploadType}
        />
      )}
      {tabValue === 1 && (
        <SearchImage
          isOpen={tabValue === 1}
          navigate={navigateToPage}
          handleTab={setTab}
        />
      )}
      {tabValue === 2 && (
        <PictureByCamera
          isOpen={tabValue === 2}
          navigate={navigateToPage}
        />
      )}
      {tabValue === 3 && (
        <ImageByUrl
          isOpen={tabValue === 3}
          navigate={navigateToPage}
          handleTab={setTab}
        />
      )}
    </Layout>
  )
}
export default ImageUpload
