import { useAuth } from "context/auth/authContext";
import { useEffect, useState } from "react";
import { User } from "ymca/models/user.model";
import { SELF_DATA_KEY } from 'ymca/services/self.service';
import { DefaultYMCA } from "ymca/ymca";

export interface UserSelf {
  selfData: User | null;
  showEthAlert: Boolean;
}

export const useUserSelf = () : UserSelf => {
  
  const { isAuthenticated } = useAuth();

  const [showEthAlert, setShowEthAlert] = useState<Boolean>(false);
  const [selfData, setSelfData] = useState<User | null>(null);

  useEffect(() => {
    DefaultYMCA.selfService.getSelf().then((data) => {
      if (data) {
        setSelfData(data);
        DefaultYMCA.pubsub.subscribe(SELF_DATA_KEY, setSelfData);
      }
    });
    return () => DefaultYMCA.pubsub.unsubscribe(SELF_DATA_KEY, setSelfData);
  }, []);

  useEffect(() => {
    if (isAuthenticated && !selfData?.rewardEthAddress && !!selfData?.nCompetitionPosts) {
      setShowEthAlert(true);
    } else {
      setShowEthAlert(false);
    }
  }, [selfData, isAuthenticated]);

  return { selfData, showEthAlert };
}