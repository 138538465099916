import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export interface WarningMessageProps {
  width?: any
  right: any
  children: any
}

const useStyles = makeStyles({
  warningContent: () => ({
    display: 'block'
  }),
  tooltip: {
    position: 'absolute',
    display: 'inline-block',
    '& $warningContent': {
      width: '100%',
      background: '#ff0061',
      border: '1px solid #FB9AA4',
      padding: '10px',
      position: 'absolute',
      borderRadius: '6px',
      textAlign: 'left',
      boxSizing: 'border-box',
      zIndex: '-1',
      '&::after': {
        content: "''",
        position: 'absolute',
        top: '-6px',
        right: (props: { right: string }) => props.right !== '' ? props.right : '6%',
        background: '#ff0061',
        borderColor: '#FB9AA4',
        borderLeft: 'solid 1px',
        borderTop: 'solid 1px',
        width: '10px',
        height: '10px',
        transform: 'rotate(45deg)'
      }
    }
  },
  tooltiptext: {
    font: 'normal normal normal Gotham Rounded',
    color: '#ffffff',
    fontSize: '0.8em',
    fontWeight: 700
  }
})

const WarningMessage = ({ width, children }: WarningMessageProps): JSX.Element => {
  const classes = useStyles(width)

  return (
    <Box className={classes.tooltip}>
      <Box className={classes.warningContent}>
        <Box className={classes.tooltiptext}>{children}</Box>
      </Box>
    </Box>
  )
}

export default WarningMessage
