import { makeStyles } from "@material-ui/core/styles";

const useCommunityMemberItemCardDStyles = makeStyles({
  mainAdminCard: {
    borderRadius: '0.9375rem',
    background: '#28344D',
    boxShadow: '0px 0px 20px 0px rgba(0, 7, 24, 0.10)',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '.5rem',
    alignItems: 'center',
    padding: '1rem'
  },
  mainAdminCardLinkSection: {
    display: 'flex',
    gap: '.5rem',
    alignItems: 'center',
    flex: 1
  },
  mainAdminCardAvatar: {
    width: '2.5rem',
    height: '2.5rem'
  },
  mainAdminCardContent: {},
  mainAdminCardContentTop: {
    display: 'flex',
    alignItems: 'center',
    gap: '.25rem'
  },
  mainAdminCardContentDisplayName: {
    color: '#E9F0F9',
    fontFamily: 'Inter',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: '1.1875rem',
    letterSpacing: '-0.0375rem',

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 100
  },
  mainAdminCardContentUsername: {
    color: '#8A98B4',
    fontFamily: 'Inter',
    fontSize: '0.8125rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '-0.025rem',

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 120
  }
})

export default useCommunityMemberItemCardDStyles;
