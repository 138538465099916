import moment from "moment";
import { Link } from "react-router-dom";
import { Box, Button, styled } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRef, useState, useEffect } from "react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";

import { Image } from "components/shared/index";
import DiscoverMemer from "components/main/DiscoverMemer";
import { useAuth } from "context/auth/authContext";
import { imagesJpg, imagesSvg } from "constants/images";
import { DefaultYMCA } from "ymca/ymca";
import { Event } from "ymca/models/event.model";
import colors from 'theme/dark/colors'
import Typography from 'components/atoms/Typography'
import {
  BREAKPOINT_MOBILE_LARGE,
  BREAKPOINT_LAPTOP,
  BREAKPOINT_LAPTOP_SMALL
} from 'theme/shared/breakpoint'
import RightSidebarTopCommunities from 'components/organisms/RightSidebarTopCommunities'
import DiamondRewardCard from 'components/organisms/DiamondRewardCard'
import BackgroundImage from 'components/molecules/BackgroundImage'
import { getImageUrlSourceType } from 'utils/getImageResolutions'

SwiperCore.use([Navigation, Pagination, Autoplay])

const useStyles = makeStyles({
  upcomingContainer: {
    width: '100%'
  },
  navigation: {
    width: 32,
    height: 32,
    backgroundColor: colors.sliderBtn,
    border: `2px solid ${colors.sliderBtnBorder}`,
    boxShadow: `0px 0px 25px 0px ${colors.sliderBtnShadow}`,
    minWidth: 0,
    '& picture': {
      display: 'flex'
    },
    '&:hover': {
      backgroundColor: colors.darkBlueActive
    }
  },
  navbtnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  navbtns: {
    display: 'flex',
    justifyContent: '',
    gap: '1.25rem'
  },
  swiperContainer: {
    width: '100%',
    overflow: 'hidden !important'
  },
  cateCard: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5,
    marginTop: '.5rem',
    overflow: 'hidden !important',
    position: 'relative',
    '& img': {
      margin: 'auto',
      borderRadius: 5,
      objectFit: 'cover',
      width: 323,
      filter: 'brightness(110%)'
    }
  },
  imgStyle: {
    width: '100%',
    height: 105,
    cursor: 'pointer',
    borderRadius: 5,
    filter: 'brightness(110%)'
  },

  sponsorTitle: {
    position: 'absolute',
    background: 'rgba(23, 31, 39, 0.8)',
    bottom: 0,
    left: 0,
    height: 36,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 12,
    '& span': {
      fontWeight: 600,
      color: colors.titleColor,
      fontSize: 12
    },
    zIndex: 20
  },
  spotItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 10,
    paddingTop: 10,
    margin: 'auto',
    width: '100%',
    '& img': {
      borderRadius: 5,
      maxHeight: 160,
      width: '100%'
    }
  },
  author: {
    color: 'var(--gradient-fall-back)',
    fontSize: 13,
    fontWeight: 600
  },
  desc: {
    color: '#E2E4E9',
    fontSize: 14,
    fontWeight: 700
  },
  views: {
    color: '#808893',
    fontSize: 13,
    fontWeight: 400,
    marginTop: 3
  },
  btnContainer: {
    width: '100%',
    margin: 'auto'
  },
  button: {
    color: '#E2E4E9',
    fontSize: 14,
    fontWeight: 700,
    marginTop: 20,
    background: colors.darkBlue50,
    width: '100%',
    borderRadius: 100,
    height: 46,
    '&:hover': {
      background: colors.darkBlueActive
    }
  },
  '@media (max-width: 1280px)': {
    spotItem: {
      '& img': {
        width: '100%',
        marginRight: 0,
        height: 120
      }
    },
    author: {
      marginTop: 10
    }
  }
})

const Wrapper = styled(Box)(({ theme }) => ({
  borderLeft: `1px solid ${colors.border}`,
  padding: '1rem 1rem 1rem 2rem',

  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',

  [`@media (max-width: ${BREAKPOINT_LAPTOP_SMALL}px)`]: {
    paddingLeft: '1.5rem'
  },
  [`@media (max-width: ${BREAKPOINT_LAPTOP}px)`]: {
    paddingLeft: '1rem'
  },

  [`@media (max-width: ${BREAKPOINT_MOBILE_LARGE}px)`]: {
    display: 'none'
  }
}))

const Sections = styled(Box)({
  marginTop: 0,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',

  padding: '1rem',

  borderRadius: '8px',
  background: colors.secondary900
})

const CompetitionsSection = styled(Sections)({})
const UpcomingEventsSection = styled(Box)({
  padding: '0 1rem',
  position: 'relative'
})
const SponsoredSection = styled(Sections)({})

const ContentItem = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 5,
  position: 'relative',
  gap: '.5rem',
  '& picture': {
    display: 'flex'
  },
  '& img': {
    borderRadius: 5,
    objectFit: 'cover',
    width: '100%',
    height: 105,
    filter: 'brightness(110%)'
  }
})

const UpcomingEventContent = styled(ContentItem)({})
const SponsoredContent = styled(ContentItem)({})
const CompetitionContent = styled(ContentItem)({})

const AbsolutContentItem = styled(Box)({
  position: 'absolute',
  background: colors.absoluteContentBackground
})
const ContentItemAbsoluteHeader = styled(AbsolutContentItem)({
  top: 2,
  right: 2,
  height: '1.25rem',
  display: 'flex',
  alignItems: 'center',
  padding: '.75rem',
  borderRadius: '2rem',
  zIndex: 20
})
const ContentItemAbsoluteFooter = styled(AbsolutContentItem)({
  bottom: 0,
  left: 0,
  height: 36,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '1rem',
  zIndex: 20
})

const ContentItemEventAbsoluteHeader = styled(ContentItemAbsoluteHeader)({
  height: '.25rem'
})

const ContentItemEventAbsoluteFooter = styled(ContentItemAbsoluteFooter)({
  height: '1rem',
  padding: '.5rem 1rem .5rem 1rem'
})

const eventLimit: number = 50

const RightSidebar = (): JSX.Element => {
  const classes = useStyles()
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  const { isAuthenticated } = useAuth()

  const [events, setEvents] = useState<Event[]>([])
  const [liveCompetitions, setLiveCompetitions] = useState<unknown[]>([])
  const [upcomingCompetitions, setUpcomingCompetitions] = useState<unknown[]>(
    []
  )

  const getAllEvents = async (): Promise<void> => {
    const res = await DefaultYMCA.eventService.getEventsForSidebar(
      eventLimit,
      0
    )
    setEvents(res.data?.data)
  }

  const getLiveCompetition = async (): Promise<void> => {
    const res = await DefaultYMCA.eventService.getActiveMemeContests(3, 0)
    setLiveCompetitions(res.data?.data)
  }

  const getUpcomingCompetition = async (): Promise<void> => {
    const res = await DefaultYMCA.eventService.getFutureMemeContests(3, 0)
    setUpcomingCompetitions(res.data?.data)
  }

  useEffect(() => {
    getAllEvents()
    getLiveCompetition()
    getUpcomingCompetition()
  }, [])

  return (
    <Wrapper>
      <DiamondRewardCard />
      {liveCompetitions.length > 0 && (
        <CompetitionsSection>
          <Typography fontWeight={800} color={colors.titleColor}>
            Live Contests
          </Typography>
          <CompetitionContent>
            {liveCompetitions.map((item: any, index: number) => (
              <Link
                to={`/contest/${item.id}/submissions`}
                key={index}
                data-test-spotlight={item.id}
              >
                <ContentItem>
                  {Number(item.eventMetadata.contestDetail?.cashTotal) > 0 &&
                    item.eventMetadata?.contestDetail?.cashUnit &&
                    item.eventMetadata?.contestDetail?.cashUnit !== '' && (
                      <ContentItemAbsoluteHeader>
                        <Typography fontSize='.7rem'>
                          {`${item.eventMetadata.contestDetail.cashTotal} ${item.eventMetadata.contestDetail.cashUnit} Prize Pool`}
                        </Typography>
                      </ContentItemAbsoluteHeader>
                    )}
                  <ContentItemAbsoluteFooter>
                    <Typography fontSize='.8rem' fontWeight={800}>
                      {item.name}
                    </Typography>
                  </ContentItemAbsoluteFooter>
                  <BackgroundImage
                    src={item?.image?.urlOriginal}
                    pictureSources={[
                      {
                        srcSet: `${item?.image?.urlOriginal} 1x`,
                        type: getImageUrlSourceType(item?.image?.urlOriginal)
                      }
                    ]}
                    alt='sponsor'
                  />
                </ContentItem>
              </Link>
            ))}
          </CompetitionContent>
        </CompetitionsSection>
      )}
      {upcomingCompetitions.length > 0 && (
        <CompetitionsSection>
          <Typography fontWeight={800} color={colors.titleColor}>
            Upcoming Contests
          </Typography>
          <CompetitionContent>
            {upcomingCompetitions.map((item: any, index: number) => (
              <Link
                to={`/contest/${item.id}/submissions`}
                key={index}
                data-test-spotlight={item.id}
              >
                <ContentItem>
                  <ContentItemAbsoluteHeader>
                    <Typography fontSize='.7rem'>
                      {moment(item.startingDate).format('DD MMM YY, hh:mma')}
                    </Typography>
                  </ContentItemAbsoluteHeader>
                  <ContentItemAbsoluteFooter>
                    <Typography fontSize='.8rem' fontWeight={800}>
                      {item.name}
                    </Typography>
                  </ContentItemAbsoluteFooter>
                  <Image
                    src={item?.image?.urlOriginal}
                    fallbackSrc={item?.image?.jpegUrl}
                    className={classes.imgStyle}
                    alt='sponsor'
                  />
                </ContentItem>
              </Link>
            ))}
          </CompetitionContent>
        </CompetitionsSection>
      )}

      <RightSidebarTopCommunities />

      {isAuthenticated && <DiscoverMemer />}

      {/*events.length > 0 && (
        <UpcomingEventsSection data-test-upcoming-wrapper>
          <Box className={classes.navbtnContainer}>
            <Typography fontWeight={800} color={colors.titleColor}>
              Upcoming Events
            </Typography>
            <Box className={classes.navbtns}>
              <Button
                className={classes.navigation}
                ref={prevRef}
                style={{ left: '.5rem' }}
              >
                <Image
                  src={`moveIcons/${imagesSvg.beforeIcon}`}
                  alt='previous'
                />
              </Button>
              <Button
                className={classes.navigation}
                ref={nextRef}
                style={{ right: '.5rem' }}
              >
                <Image src={`moveIcons/${imagesSvg.nextIcon}`} alt='next' />
              </Button>
            </Box>
          </Box>
          <Swiper
            slideToClickedSlide
            slidesPerView={1}
            autoplay={{ delay: 3000 }}
            observer
            className={classes.swiperContainer}
            loop
            navigation={{
              prevEl: prevRef.current ?? undefined,
              nextEl: nextRef.current ?? undefined
            }}
            onInit={(swiper: any) => {
              swiper.params.navigation.prevEl = prevRef.current
              swiper.params.navigation.nextEl = nextRef.current
              swiper.navigation.update()
            }}
          >
            {events.length > 0 ? (
              events.map((item, index) => (
                <SwiperSlide key={index}>
                  <a
                    className={classes.cateCard}
                    key={index}
                    href={`${item.urlWebsite ?? ''}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    data-test-upcoming-id={item.id}
                    data-test-upcoming-date={item.endingDate}
                  >
                    <UpcomingEventContent>
                      <ContentItemEventAbsoluteHeader>
                        <Typography fontSize='.8rem'>
                          {moment(item.startingDate).format(
                            'DD MMM YY, hh:mma'
                          )}
                        </Typography>
                      </ContentItemEventAbsoluteHeader>
                      <ContentItemEventAbsoluteFooter>
                        <Typography fontSize='.8rem'>{item.name}</Typography>
                      </ContentItemEventAbsoluteFooter>
                      <Image
                        src={item.image.urlOriginal}
                        fallbackSrc={item.image.jpegUrl}
                        className={classes.imgStyle}
                        alt='no events'
                      />
                    </UpcomingEventContent>
                  </a>
                </SwiperSlide>
              ))
            ) : (
              <UpcomingEventContent>
                <ContentItemAbsoluteHeader>
                  <Typography fontSize='.7rem'>
                    June 29th - 30th, 2023
                  </Typography>
                </ContentItemAbsoluteHeader>
                <ContentItemAbsoluteFooter>
                  <Typography fontSize='.8rem'>Polkadot Event</Typography>
                </ContentItemAbsoluteFooter>
                <Image
                  src={`events/${imagesJpg.eventNew}`}
                  className={classes.imgStyle}
                  alt='no events'
                />
              </UpcomingEventContent>
            )}
          </Swiper>
        </UpcomingEventsSection>
      )*/}
      <SponsoredSection data-test-sponsored>
        <Typography fontWeight={800} color={colors.titleColor}>
          Sponsored
        </Typography>
        <SponsoredContent>
          <Box className={classes.sponsorTitle}>
            <span>El Salvador makes Dogecoin legal tender</span>
          </Box>
          <Image src={`events/${imagesJpg.sponsor}`} alt='sponsor' />
        </SponsoredContent>
      </SponsoredSection>
    </Wrapper>
  )
}

export default RightSidebar;
