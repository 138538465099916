import { useEffect, useMemo, useState } from "react";
import { Box, ClickAwayListener, Typography } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import { Snackbar, styled, IconButton } from '@mui/material'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { TelegramShareButton, TwitterShareButton } from 'react-share'

import { useAuth } from 'context/auth/authContext'
import { MoreIcon } from 'components/shared/SvgIcons'
import useWindowSize from 'hooks/useWindowSize'
import { Post as PostModel } from 'ymca/models/post.model'
import { Image } from 'components/shared/index'
import { imagesSvg } from 'constants/images'
import { DEFAULT_AVATAR_IMAGE } from 'ymca/services/image.service'
import { DefaultYMCA } from 'ymca/ymca'
import { PublicUser } from 'ymca/models/user.model'
import MemerProfile from 'components/memes/MemerProfile'
import PublicationOtherMenu from 'components/memePublication/PublicationOtherMenu'
import useShow from 'hooks/useShow'
import { useFeedContext } from 'context/feed/feedContext'
import Avatar from 'components/molecules/Avatar'
import getImageResolutions, {
  getImageUrlSourceType
} from 'utils/getImageResolutions'
import usePublicationHeaderStyles from './style'

const PublicationHeaderWrapper = styled(`section`, {
  name: 'PublicationHeaderWrapper'
})(() => {
  return {
    gridArea: 'publcationHeader'
  }
})

const VerifiedCheckImage = styled(Image)({
  display: 'flex',
  alignItems: 'center'
})

const PublicationMenuIconButton = styled(IconButton)({
  '&.MuiIconButton-root': {
    padding: 0
  }
})

export interface PublicationHeaderProp {
  post: PostModel
  modal?: boolean
  fontSize?: string
  setEvent?: any
  modalPage?: boolean
  contestModal?: boolean
  disableEditMeme?: boolean
  copyLinkBasePath?: string
}

const PublicationHeader = ({
  post,
  modal,
  setEvent,
  modalPage,
  contestModal,
  copyLinkBasePath,
  ...rest
}: PublicationHeaderProp): JSX.Element => {
  const classes = usePublicationHeaderStyles()

  const { isAuthenticated } = useAuth()
  const { source } = useFeedContext()

  const isMobile: boolean = useWindowSize().width < 550

  const [mouseOverProfile, setMouseOverProfile] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)

  const {
    isShowing: isShowCopy,
    hide: hideCopy,
    show: showCopy
  } = useShow(false)

  const [, setFollowState] = useState<'Follow' | 'Following' | 'Unfollow'>(
    'Follow'
  )

  const [deleteAlert, setDeleteAlert] = useState<boolean>(false)

  const publicationDateTime: moment.Moment = useMemo(
    () => moment(post.createdAt),
    [post.createdAt]
  )

  const memeImageSrc: string = `${copyLinkBasePath}/meme/${post?.id}`

  const { x2, x3, fallback } = getImageResolutions(post.user?.avatar)

  const username: string = post.user.username
  const lensProfileId: string | undefined = post.user.id

  const [, setUserAvatar] = useState(
    post.user?.avatar?.jpegUrl ?? DEFAULT_AVATAR_IMAGE
  )

  const handleClick = (): void => setOpen((prev) => !prev)
  const handleClickAway = (): void => setOpen(false)

  useEffect(() => {
    const avatarSetter = (data: PublicUser) => {
      setUserAvatar(data.avatar?.jpegUrl ?? DEFAULT_AVATAR_IMAGE)
    }
    DefaultYMCA.pubsub.subscribe(post.fkUserId, avatarSetter)
    return () => {
      DefaultYMCA.pubsub.unsubscribe(post.fkUserId, avatarSetter)
    }
  }, [post, post?.id, isAuthenticated])

  useEffect(() => {
    setTimeout(() => {
      setDeleteAlert(false)
    }, 2000)
  }, [deleteAlert])

  const [onProfile, setOnProfile] = useState(false)

  return (
    <PublicationHeaderWrapper>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isShowCopy}
        autoHideDuration={2000}
        onClose={hideCopy}
        message='Link has been copied to clipboard.'
      />
      <Box className={classes.userAccount}>
        <Box className={classes.profileContainer}>
          <Link
            to={
              source === 'youmeme'
                ? `/profile/${username}`
                : `/profile/${lensProfileId}`
            }
          >
            <Avatar
              alt='user'
              src={fallback ?? DEFAULT_AVATAR_IMAGE}
              pictureSources={[
                {
                  srcSet: `${x2} 1x, ${x3} 2x`,
                  type: getImageUrlSourceType(x3)
                }
              ]}
              className={classes.avatar}
              onMouseOver={() => {
                !modalPage && !contestModal && setMouseOverProfile(true)
              }}
              onMouseOut={() => {
                setTimeout(() => {
                  setMouseOverProfile(false)
                }, 500)
              }}
            />
          </Link>
          {isAuthenticated && (mouseOverProfile || onProfile) && !isMobile && (
            <Fade in={mouseOverProfile || onProfile}>
              <Box className={classes.memerProfile}>
                <MemerProfile
                  data={post.user}
                  leave={() => {
                    setTimeout(() => {
                      setOnProfile(false)
                    }, 500)
                  }}
                  enter={() => {
                    setOnProfile(true)
                  }}
                  handleFollow={async () => {
                    if (post.user.isUserFollowed) {
                      await DefaultYMCA.followService.deleteFollow(post.user.id)
                      post.user.isUserFollowed = false
                      setFollowState('Follow')
                    } else {
                      await DefaultYMCA.followService.createFollow(post.user.id)
                      post.user.isUserFollowed = true
                      setFollowState('Following')
                    }
                  }}
                />
              </Box>
            </Fade>
          )}
          <Box>
            <Box className={classes.center}>
              <Link
                to={
                  source === 'youmeme'
                    ? `/profile/${username}`
                    : `/profile/${lensProfileId}`
                }
                className={classes.nameLink}
              >
                <Typography
                  className={classes.name}
                  style={{ fontSize: isMobile ? 14 : '' }}
                  component='span'
                >
                  {post.user.displayName}
                </Typography>
              </Link>

              <VerifiedCheckImage
                alt='check'
                src={`headerIcons/${imagesSvg.checkIcon}`}
              />

              <Box className={classes.dot} />
              {!contestModal ? (
                <Typography
                  className={classes.attr}
                  style={{ fontSize: isMobile ? 12 : '' }}
                  component='span'
                >
                  {publicationDateTime
                    .startOf(post.createdAt as 'date')
                    .fromNow()}
                </Typography>
              ) : (
                !isMobile && (
                  <Typography
                    className={classes.attr}
                    style={{ fontSize: isMobile ? 12 : '' }}
                    component='span'
                  >
                    {publicationDateTime
                      .startOf(post.createdAt as 'date')
                      .fromNow()}
                  </Typography>
                )
              )}
            </Box>

            <Typography
              className={classes.time}
              style={{ fontSize: isMobile ? 12 : '' }}
            >
              @{username}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.buttons}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box className={classes.other}>
              {!contestModal ? (
                <PublicationMenuIconButton onClick={handleClick}>
                  <MoreIcon fill='#5D6E87' />
                </PublicationMenuIconButton>
              ) : (
                <Box display={'flex'} style={{ gap: 9 }}>
                  <TwitterShareButton
                    windowWidth={750}
                    windowHeight={600}
                    className={classes.shareItem}
                    title={post.title}
                    url={memeImageSrc}
                    via={'YouMemeWorld'}
                  >
                    <Image src={`shareIcons/greyTwitter.svg`} />
                  </TwitterShareButton>
                  <TelegramShareButton
                    windowWidth={750}
                    windowHeight={600}
                    title={post.title}
                    url={memeImageSrc}
                    className={classes.shareItem}
                  >
                    <Image src={`shareIcons/greyTelegram.svg`} />
                  </TelegramShareButton>
                </Box>
              )}
              {open && (
                <PublicationOtherMenu
                  itemData={post}
                  menuClose={() => setOpen(false)}
                  imageUrl={memeImageSrc}
                  setEvent={setEvent}
                  setCopy={showCopy}
                  setDelete={() => setDeleteAlert(true)}
                  unfollow={async () => {
                    await DefaultYMCA.followService.deleteFollow(post.user?.id)
                    post.user.isUserFollowed = false
                    setFollowState('Follow')
                  }}
                  follow={async () => {
                    await DefaultYMCA.followService.createFollow(post.user?.id)
                    post.user.isUserFollowed = true
                    setFollowState('Following')
                  }}
                  {...rest}
                />
              )}
            </Box>
          </ClickAwayListener>
        </Box>
      </Box>
    </PublicationHeaderWrapper>
  )
}

export default PublicationHeader;
