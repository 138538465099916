import { makeStyles } from '@material-ui/core/styles'

const useBackgroundImageStyles = makeStyles({
  background_picture_wrapper: {
    width: '100%',
    position: 'relative',
    '& img': {
      width: '100%'
    }
  }
})

export default useBackgroundImageStyles
