import { useLayoutEffect, useRef } from 'react'

export const useFLIP = (elemRef: any, animCallback: any, deps = []): void => {
  const currentElemPosition = useRef()
  const { current: element = null } = elemRef

  if (element !== null) currentElemPosition.current = element.getBoundingClientRect()

  useLayoutEffect(() => {
    if (element === null) return
    // We will use the FLIP technique & WAAPI to animate some things
    // https://css-tricks.com/animating-layouts-with-the-flip-technique/

    // F (first) - Get initial (current) position
    // Should default to the final position if no initial position is present
    const { current: elemCurrentPosition = element.getBoundingClientRect() } = currentElemPosition

    // L (last) - Get position after action occurs
    const elemFinalPosition = element.getBoundingClientRect()
    console.log({ elemFinalPosition, elemCurrentPosition })

    // I (invert) - determine the changes in position (deltas)
    const deltaX = elemCurrentPosition.left - elemFinalPosition.left
    const deltaY = elemCurrentPosition.top - elemFinalPosition.top
    const deltaW = elemCurrentPosition.width / elemFinalPosition.width
    const deltaH = elemCurrentPosition.height / elemFinalPosition.height

    const frameId = requestAnimationFrame(() => {
      // P (play) - On new paint set the element back to it's original position then to final position using keyframes
      animCallback(deltaX, deltaY, deltaW, deltaH)
    })

    return () => {
      cancelAnimationFrame(frameId)
    }
  })
}
