import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { Spinner } from 'components/loading/LoadingSpinner';
import { DefaultYMCA } from 'ymca/ymca';
import { Post } from 'ymca/models/post.model';
import useOnScreen from 'hooks/useOnScreen';

const useStyles = makeStyles({
  container: {
    width: '826px',
    height: "fit-content",
    borderRadius: 10,
    background: '#202B42;',
    overflowY: 'auto !important' as any,
    overflowX: 'hidden !important' as any,
    marginTop: 56,
    marginRight: 150,
    padding: 16,
    '&:focus': {
      outline: 'none'
    },
    '&::-webkit-scrollbar': {
      width: 6,
      borderRadius: 10,
      backgroundColor: '#0E1017'
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'var(--main-gradient)',
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10
    }
  },
  memerTitleBox: {
    background: 'rgba(21, 30, 49, 0.5)',
    height: 42,
    display: 'flex',
    alignItems: 'center',
    padding: 24,
    '& p': {
      color: '#8895AE',
      fontWeight: 600,
      fontSize: 14
    }
  },
  userBox: {
    marginTop: 14,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    '& button': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%'
    },
    '& img': {
      width: 40,
      height: 40,
      borderRadius: 17
    }
  },
  userInfoBox: {
    marginLeft: 12,
    '& h1': {
      fontWeight: 800,
      fontSize: 16,
      color: '#E9F0F9'
    },
    '& p': {
      color: '#8895AE',
      fontWeight: 500,
      fontSize: 13,
      marginTop: -5
    }
  },
  seeAll: {
    height: 46,
    background: '#6E6BFF',
    width: '100%',
    borderRadius: 8,
    marginTop: 24,
    color: '#FFFFFF',
    fontWeight: 700,
    '&:hover': {
      background: '#6E6BFF'
    }
  },
  memes: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3 , 1fr)',
    gap: 14,
    '& div': {
      backgroundColor: 'rgba(21, 30, 49, 0.5)',
      borderRadius: 8,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repat',
      position: 'relative'
    },
    '& div button': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%'
    }
  },
  '@media (min-width: 550px) and (max-width:1200px)': {
    container: {
      marginRight: 0,
    }
  }
});

function SearchResultModal(props: {
  visible: boolean;
  close(): void;
  displayName: string;
}) {
  const classes = useStyles();
  const { visible, close, displayName } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchUserData, setSearchUserData] = useState<Post[]>([]);
  const [placeholderPosts, setPlaceholderPosts] = useState<Post[]>([]);
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement | null>(null)
  const offset = useRef<number>(0);
  const totalCount = useRef<number>(1); // has to be 1 more than offset to trigger first search call

  const [isVisible] = useOnScreen({
    ref,
    rootMargin: '0px',
    threshold: 1.0,
  })


  async function searchByName() {
    // stop if we have reached the end of the list
    if(offset.current >= totalCount.current) return
    offset.current === 0 && setIsLoading(true);
    setError('');
    try {
      const res = await DefaultYMCA.postService.searchPosts(displayName, 10, offset.current);
      setSearchUserData((prev: any) => [...prev, ...res.data.data]);
      offset.current = offset.current + 10;

    } catch (error) {
      setError('unable to fetch memes');
    } finally {
      setIsLoading(false);
    }
  }


  useEffect(() => {
    async function getPlaceholder() {
      const placeholder = await DefaultYMCA.postService.getAllPublicPosts(10, 0)
      setPlaceholderPosts(placeholder.data.data)
    }
    if (visible) {
      getPlaceholder();
    }
  }, [visible]);


  useEffect(() => {
    if (displayName.length > 0) {
      offset.current = 0
      setSearchUserData([]);
      searchByName();
    }
    else {
      setSearchUserData([]);
      offset.current = 0
    }
  }, [displayName]);


  useEffect(() => {
    if (isVisible) {
      searchByName()
    }
  }, [isVisible])

  return (
    <div style={{
      position: 'fixed',
      right: 0,
      top: 0,
      width: '100vw',
      height: '100vh',
      background: 'rgba(0 , 0, 0, 0.5)',
      display: visible ? 'flex' : 'none',
      justifyContent: 'center',
    }}
      onClick={close}
    >
      <Box className={classes.container} style={{ overflowX: 'hidden', height: displayName.length > 0 ? searchUserData?.length > 9 ? 562 : 'fit-content' : placeholderPosts?.length > 9 ? 562 : 'fit-content' }}
        data-test-search-result-modal
      >
        <Box>
          <Box id="scrollableDiv">
            {displayName.length > 0
              ? isLoading
                ? (
                  <Box>
                    <Spinner width={50} height={50} />
                  </Box>
                )
                : error
                  ? (
                    <Box>{error}</Box>
                  )
                  : searchUserData.length > 0
                    ? (
                      <>
                        <Box
                          className={classes.memes}
                        >
                          {searchUserData.map((item) => (
                            <Box
                              style={{
                                height: 150,
                                backgroundSize: 'cover',
                                backgroundImage: `url(${item.meme?.renderedImage?.jpegUrl})`,
                              }}
                              key={item.id}
                              data-test-search-result-item={item.id}
                            >
                              <Button
                        onClick={() => navigate(`/meme/${item.id}`)}
                      ></Button>
                            </Box>
                          ))}

                        </Box>
                        <div ref={ref}></div>
                      </>
                    )
                    : searchUserData.length === 0 && displayName && isLoading === false
                      ? (
                        <Typography style={{ textAlign: 'center' }}>
                          😥 Could not find publication
                        </Typography>
                      )
                      : null
              : <Box
                className={classes.memes}
              >
                {placeholderPosts.map((item) => {
                  return (
                    <Box
                      style={{
                        height: 150,
                        backgroundSize: 'cover',
                        backgroundImage: `url(${item.meme?.renderedImage?.jpegUrl})`,
                      }}
                      data-test-search-result-item={item.id}
                    >
                      <Button
                        onClick={() => navigate(`/meme/${item.id}`)}
                      ></Button>
                    </Box>
                  );
                })}
              </Box>
            }
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default SearchResultModal;
