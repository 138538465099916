import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, IconButton } from "@material-ui/core";
import { styled } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

import {
  BREAKPOINT_MOBILE_LARGE,
  BREAKPOINT_MOBILE_MEDIUM,
  BREAKPOINT_MOBILE_SMALL,
  BREAKPOINT_TABLET_MEDIUM,
} from "theme/shared/breakpoint";
import { useAuth } from "context/auth/authContext";
import { redirectLinks } from "constants/redirectLinks";
import colors from "theme/dark/colors";
import PublishMemeModal from "components/memePublication/PublishMemeModal";
import { useFeedContext } from "context/feed/feedContext";
import { useLensService } from "hooks/useLensServices/useLensServices";
import config from "utils/config";
import Icon from "components/atoms/Icons";

const CreateMemeIcon = styled(IconButton)<{ feed: "youmeme" | "lens" }>(
  ({ feed }) => {
    const bgColor = feed !== "lens" ? "white" : "black";
    return {
      "&.MuiIconButton-root": {
        [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
          padding: 0,

          fontSize: "2.25rem",

          "& svg": {
            width: "2em",
            height: "2em",
          },

          "& .MuiIconButton-label": {
            width: "2em",
            height: "2em",
            marginTop: "-.8em",
            marginBottom: 0,
            border: `1px solid ${bgColor}`,
            background: bgColor,
            borderRadius: "100%",

            display: "flex",
            flexDirection: "column",
            gap: 6,
          },
        },
        [`@media (max-width: ${BREAKPOINT_MOBILE_LARGE}px)`]: {
          padding: 0,

          fontSize: "1.875rem",

          "& svg": {
            width: "2em",
            height: "2em",
          },

          "& .MuiIconButton-label": {
            width: "2em",
            height: "2em",
            marginTop: "-.8em",
            marginBottom: 0,
            border: `1px solid ${bgColor}`,
            background: bgColor,
            borderRadius: "100%",

            display: "flex",
            flexDirection: "column",
            gap: 6,
          },
        },
        [`@media (max-width: ${BREAKPOINT_MOBILE_MEDIUM}px)`]: {
          fontSize: "1.6rem",
        },
        [`@media (max-width: ${BREAKPOINT_MOBILE_SMALL}px)`]: {
          fontSize: "1.4rem",
        },
      },
    };
  }
);

const activeStateColor = "#FFFFFF";
const inActiveStateColor = "#8A98B4";

const useStyles = makeStyles({
  toolbar: {
    position: "fixed",
    bottom: -1,
    left: 0,
    width: "100%",
    alignItems: "center",
    borderTop: `1px solid ${colors.border}`,
    backgroundColor: colors.darkBlue,
    color: "#E2E4E9",
    justifyContent: "space-evenly",
    zIndex: 999,
  },
  mobileOnly: {
    display: "none",
    [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
      display: "flex",
    },
  },
  toolBtn: {
    flex: 1,

    "&.active-tab": {
      color: activeStateColor,
    },
    "&.inactive-tab": {
      color: inActiveStateColor,
    },

    [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
      padding: ".1rem",

      fontSize: "1.6rem",

      "& svg": {
        width: "1em",
        height: "1em",
      },
      "& small": {
        fontSize: "1rem",
        textTransform: "capitalize",
        color: "inherit",
      },
      "& .MuiIconButton-label": {
        display: "flex",
        flexDirection: "column",
        gap: ".25rem",
        height: 60,
      },
    },
    [`@media (max-width: ${BREAKPOINT_MOBILE_LARGE}px)`]: {
      fontSize: "1.4rem",
      "& svg": {
        width: "1em",
        height: "1em",
      },
      "& small": {
        fontSize: ".8rem",
      },
      "& .MuiIconButton-label": {
        gap: ".2rem",
        height: 50,
      },
    },
    [`@media (max-width: ${BREAKPOINT_MOBILE_MEDIUM}px)`]: {
      "& small": {
        fontSize: ".7rem",
      },
    },
    [`@media (max-width: ${BREAKPOINT_MOBILE_SMALL}px)`]: {
      fontSize: "1.2rem",

      "& small": {
        fontSize: ".6rem",
      },
    },
  },
  avatar: {},
  toolBtnImg: {},
  "@media (max-width: 550px)": {
    toolBtnImg: {
      border: "1px solid var(--gradient-fall-back)",
      borderRadius: "100%",
      display: "grid",
      placeItems: "center",
      "& img": {
        width: 23,
        height: 23,
      },
    },
    disabled: {
      color: "gray",
      "& p": {
        color: "gray",
      },
      "&:hover": {
        color: "gray",
      },
    },
    avatar: {
      "& img": {
        borderRadius: 10,
        backgroundColor: colors.profileImageBackground,
      },
    },
  },
});

const MobileFooter = (): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const pathName = useLocation().pathname;
  const { isAuthenticated } = useAuth();

  const { experimentalCommunityFlag } = config;

  const [isOpenMeme, setOpenMeme] = useState<boolean>(false);
  const { isAuthenticatedOnLens: isAuthenticatedOnLensPromise } =
    useLensService();
  const [isAuthenticatedOnLens, setIsAuthenticatedOnLens] =
    useState<boolean>(false);

  const { source, disableToggle: lensCreateMemeDisabled } = useFeedContext();

  const disableCreateBtn =
    lensCreateMemeDisabled ||
    (source === "lens" && !isAuthenticatedOnLens && isAuthenticated)
    || (source === "lens" && config.disableLensButton)

  const createButtonColor =
    source === undefined
      ? colors.greyScale100
      : source !== "lens"
      ? colors.primary500
      : colors.lensBrandColor;

  useEffect(() => {
    (async function checkAuthenticationOnLens() {
      setIsAuthenticatedOnLens(await isAuthenticatedOnLensPromise());
    })();
  }, []);

  return (
    <Box className={`${classes.toolbar} ${classes.mobileOnly}`}>
      <IconButton
        className={`${classes.toolBtn} ${
          pathName?.split?.("/")?.[1] === "" ||
          pathName?.split?.("/")?.[1] === redirectLinks.homePage
            ? "active-tab"
            : "inactive-tab"
        }`}
        onClick={() => navigate("/")}
      >
        <Icon
          icon='home'
          fontSize='inherit'
          variant={pathName === "/" ? "filled" : "outlined"}
          color='inherit'
        />
        <small>Home</small>
      </IconButton>

      <IconButton
        disabled={!isAuthenticated}
        onClick={() => navigate("/search")}
        className={`${classes.toolBtn} ${
          pathName?.split?.("/")?.[1] === redirectLinks.search
            ? "active-tab"
            : "inactive-tab"
        }`}
      >
        <Icon
          icon='search-footer'
          fontSize='inherit'
          variant={pathName === "/search" ? "filled" : "outlined"}
          color='inherit'
        />
        <small>search</small>
      </IconButton>
      <CreateMemeIcon
        onClick={() => setOpenMeme(true)}
        disabled={disableCreateBtn}
        feed={source ? source : "youmeme"}
      >
        <svg
          width='1em'
          height='1em'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          opacity='1'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M16 2.25C8.40625 2.25 2.25 8.40625 2.25 16C2.25 23.5937 8.40625 29.75 16 29.75C23.5937 29.75 29.75 23.5937 29.75 16C29.75 8.40625 23.5937 2.25 16 2.25ZM17.25 21C17.25 21.3315 17.1183 21.6495 16.8839 21.8839C16.6495 22.1183 16.3315 22.25 16 22.25C15.6685 22.25 15.3505 22.1183 15.1161 21.8839C14.8817 21.6495 14.75 21.3315 14.75 21V17.25H11C10.6685 17.25 10.3505 17.1183 10.1161 16.8839C9.8817 16.6495 9.75 16.3315 9.75 16C9.75 15.6685 9.8817 15.3505 10.1161 15.1161C10.3505 14.8817 10.6685 14.75 11 14.75H14.75V11C14.75 10.6685 14.8817 10.3505 15.1161 10.1161C15.3505 9.88169 15.6685 9.75 16 9.75C16.3315 9.75 16.6495 9.88169 16.8839 10.1161C17.1183 10.3505 17.25 10.6685 17.25 11V14.75H21C21.3315 14.75 21.6495 14.8817 21.8839 15.1161C22.1183 15.3505 22.25 15.6685 22.25 16C22.25 16.3315 22.1183 16.6495 21.8839 16.8839C21.6495 17.1183 21.3315 17.25 21 17.25H17.25V21Z'
            fill={createButtonColor}
          />
          <circle cx='16' cy='16' r='15.5' stroke={createButtonColor} />
        </svg>
      </CreateMemeIcon>

      {!experimentalCommunityFlag ? (
        <IconButton
          className={classes.toolBtn}
          onClick={() => navigate(`/${redirectLinks.discover}`)}
          disabled={!isAuthenticated}
        >
          {pathName === "/discover" ? (
            <svg
              width='1em'
              height='1em'
              viewBox='0 0 16 16'
              xmlns='http://www.w3.org/2000/svg'
              fill='#fff'
            >
              <path d='M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z' />
              <path
                fillRule='evenodd'
                d='M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z'
              />
              <path d='M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z' />
            </svg>
          ) : (
            <svg
              width='1em'
              height='1em'
              viewBox='0 0 16 16'
              xmlns='http://www.w3.org/2000/svg'
              fill='#fff'
            >
              <path d='M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z' />
            </svg>
          )}
          <small>Follow</small>
        </IconButton>
      ) : (
        <IconButton
          onClick={() => navigate(`/${redirectLinks.communities}`)}
          disabled={!isAuthenticated}
          className={`${classes.toolBtn} ${
            pathName?.split?.("/")?.[1] === redirectLinks.communities
              ? "active-tab"
              : "inactive-tab"
          }`}
        >
          <Icon
            icon='communities-secondary'
            fontSize='inherit'
            variant={
              pathName?.split?.("/")?.[1] === redirectLinks.communities
                ? "filled"
                : "outlined"
            }
            color='inherit'
          />
          <small>Communities</small>
        </IconButton>
      )}

      <IconButton
        onClick={() => navigate(`/${redirectLinks.ongoingcontest}`)}
        disabled={process.env.REACT_APP_DISABLE_CONTEST === "true"}
        className={`${classes.toolBtn} ${
          pathName?.split?.("/")?.[1] === redirectLinks.contest
            ? "active-tab"
            : "inactive-tab"
        }`}
      >
        <Icon
          icon='competition'
          fontSize='inherit'
          variant={
            pathName?.split?.("/")?.[1] === redirectLinks.contest
              ? "filled"
              : "outlined"
          }
          color='inherit'
        />
        <small>contest</small>
      </IconButton>

      <PublishMemeModal open={isOpenMeme} onClose={() => setOpenMeme(false)} />
    </Box>
  );
};

export default MobileFooter;
