import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  container: {
    backgroundColor: '#7075FF',
    height: '64px',
    maxWidth: '4860px',
    minWidth: '200px',
    borderRadius: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    padding: '0 16px'
  },
  savedMessage: {
    fontWeight: 700,
    fontSize: '16px',
    color: '#FFFFFF',
    lineHeight: '24px'
  },
  '@media (max-width: 750px)': {
    savedMessage: {
      fontWeight: 700,
      fontSize: '10px',
      color: '#FFFFFF',
      lineHeight: '24px'
    },
    container: {
      marginBottom: 30,
      paddingRight: 5
    }
  },
  '@media (max-width: 550px)': {
    savedMessage: {
      fontWeight: 700,
      fontSize: '10px',
      color: '#FFFFFF',
      lineHeight: '24px'
    },
    container: {
      marginBottom: 30,
      paddingRight: 5
    }
  }
})

const SaveAlert = (): JSX.Element => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Typography className={classes.savedMessage}>
        Nice, You just saved this meme to your collection!
      </Typography>
    </Box>
  )
}

export default SaveAlert
