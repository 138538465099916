// BaseService is a class that is extended by all services, which
// provides an instance of YMCABase inside it.
import { JSONFetcher } from 'ymca/jsonFetcher'
import { KVStore } from 'ymca/storage/kvStore'
import { PubSub } from 'ymca/storage/pubsub'
import { YMCAConfig } from 'ymca/ymcaConfig'

// BaseService is an abstract class that is extended by all services.
// It provides a reference to the JSONFetcher instance for API calls,
// and a reference to a KVStore instance for data storage.
export abstract class BaseService {
  public common: CommonArgs

  public constructor (common: CommonArgs) {
    this.common = common
  }
}

export interface CommonArgs {
  bannedLensUsers: Set<string>
  bannedLensPosts: Set<string>
  jsonFetcher: JSONFetcher
  localStorage: Storage
  kvStore: KVStore
  pubsub: PubSub
  config: YMCAConfig
}
