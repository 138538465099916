import { makeStyles } from "@material-ui/core/styles";
import colors from "theme/dark/colors";
import { BREAKPOINT_TABLET_MEDIUM } from "theme/shared/breakpoint";

const useProfileMenuDropdownStyles = makeStyles({
  avatar: {
    width: '2.5rem',
    height: '2.5rem',
    backgroundColor: colors.profileImageBackground,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  profileMenuBtn: {
    borderRadius: '6.25rem',
    background: '#33405C',
    boxShadow: ' 0px 4px 8px 0px rgba(14, 20, 34, 0.20)',

    '&.MuiButton-root': {
      padding: '0',
      minWidth: '0',
      width: '2.5rem',
      maxWidth: '2.5rem',
      height: '2.5rem'
    }
  },
  profileMenu: {
    '& .MuiPaper-root': {
      'border-radius': '0.75rem',
      background: '#33405C',
      'box-shadow': ' 0px 4px 46px 0px rgba(14, 20, 34, 0.50)',
      width: '14rem'
    },
    '& .MuiList-root': {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      padding: '.5rem'
    },

    display: 'block',

    [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
      display: 'none'
    }
  },
  profileContainer: {
    display: 'flex',
    gap: '.5rem',
    alignItems: 'center',
    paddingLeft: '.35rem'
  },
  profileLink: {
    color: '#8A98B4',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '-0.0375rem'
  },
  displayName: {
    color: '#E9F0F9',
    fontSize: '1rem',
    fontWeight: 800,
    fontStyle: 'normal',
    lineHeight: '1.1875rem',
    letterSpacing: '-0.0375rem'
  },
  kebabMenuDropdownDrawer: {
    display: 'none',

    '& .MuiPaper-root': {
      borderRadius: '0.9375rem',
      background: 'rgba(32, 43, 66, 0.94)',
      boxShadow: '0px -13px 24px 0px rgba(0, 0, 0, 0.50)',

      padding: '1rem 2rem'
    },

    [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
      display: 'block'
    }
  },
  kebabMenuDropdownDrawerTitle: {
    color: '#E9F0F9',
    fontFamily: 'Inter',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: 'normal',
    letterSpacing: '-0.03125rem'
  },

  kebabMenuDropdownDrawerListItemText: {
    '& .MuiTypography-body1': {
      color: '#E9F0F9',
      fontFamily: 'Inter',
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      letterSpacing: '-0.03125rem'
    }
  },
  menuList: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0.9375rem',
    background: '#202A41',
    width: '100%'
  },
  navTitle: {
    color: '#E9F0F9',
    fontSize: '.8rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '1.1875rem',
    letterSpacing: '-0.0375rem'
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      padding: '.4rem .5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '1rem'
    }
  },
  menuItemTopBorder: {
    '&.MuiMenuItem-root': {
      borderTop: '1px solid #2A3753',
      paddingTop: '.25rem'
      // paddingBottom: ".25rem",
    }
  },
  navItemLeft: {
    display: 'flex',
    gap: '1rem',
    padding: '.5rem',
    justifyContent: 'center'
  },

  footertext: {
    fontSize: '0.875rem',
    fontWeight: 500,
    color: '#8A98B4',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.025rem'
  },
  footerIconBtn: {
    '&.MuiIconButton-root': {
      padding: 'unset'
    }
  }
})

export default useProfileMenuDropdownStyles
