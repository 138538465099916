import {
  FileType,
  FileTypeMap,
  FileTypes,
  ImageFileTypeExtensionType,
  ImageFileTypeExtensions,
  MEME_FILE_UPLOAD_ASPECT_RATIO_ERROR_IMAGE,
  MEME_FILE_UPLOAD_ASPECT_RATIO_ERROR_VIDEO,
  MEME_FILE_UPLOAD_ASPECT_RATIO_MAXIMUM,
  MEME_FILE_UPLOAD_ASPECT_RATIO_MINIMUM,
  MEME_FILE_UPLOAD_DIMENSION_ERROR_IMAGE,
  MEME_FILE_UPLOAD_DIMENSION_ERROR_VIDEO,
  MEME_FILE_UPLOAD_DIMENSION_MIN_HEIGHT,
  MEME_FILE_UPLOAD_DIMENSION_MIN_WIDTH,
  MEME_FILE_UPLOAD_FORMAT_ERROR_MESSAGE_IMAGE,
  MEME_FILE_UPLOAD_FORMAT_ERROR_MESSAGE_VIDEO,
  MEME_FILE_UPLOAD_SIZE_ERROR_MESSAGE_IMAGE,
  MEME_FILE_UPLOAD_SIZE_ERROR_MESSAGE_VIDEO,
  VideoFileTypeExtensionType,
  VideoFileTypeExtensions
} from 'constants/meme'
import getFileMetadata from 'utils/getFileMetadata'
import config from 'utils/config'
import getImageBase64DataUri from 'utils/getImageBase64DataUri'

interface ImageDimension {
  width: number
  height: number
}

interface CheckResolutionResult {
  data: ImageDimension
}

// For checking the resolution of the uploaded image
export const checkResolution = (
  base64DataUri: string,
  filetype: any
): Promise<CheckResolutionResult> => {
  return new Promise((resolve, reject) => {
    try {
      if (filetype === FileTypeMap.image || filetype === FileTypeMap.gif) {
        const img = new Image()
        img.src = base64DataUri
        img.onload = () => {
          const { naturalWidth, naturalHeight } = img
          if (
            naturalHeight >= MEME_FILE_UPLOAD_DIMENSION_MIN_HEIGHT &&
            naturalWidth >= MEME_FILE_UPLOAD_DIMENSION_MIN_WIDTH
          ) {
            // check aspect ratio
            const aspectRatioNumber = naturalWidth / naturalHeight
            if (
              aspectRatioNumber >= MEME_FILE_UPLOAD_ASPECT_RATIO_MINIMUM &&
              aspectRatioNumber <= MEME_FILE_UPLOAD_ASPECT_RATIO_MAXIMUM
            ) {
              resolve({ data: { width: naturalWidth, height: naturalHeight } })
            } else {
              reject(MEME_FILE_UPLOAD_ASPECT_RATIO_ERROR_IMAGE)
            }
          } else {
            reject(MEME_FILE_UPLOAD_DIMENSION_ERROR_IMAGE)
          }
        }
        img.onerror = (e) => {
          reject('Failed to load image')
        }
      } else if (filetype === FileTypeMap.video) {
        try {
          const video = document.createElement('video')
          video.src = base64DataUri

          video.onloadeddata = () => {
            const { videoWidth: width, videoHeight: height } = video
            if (
              height >= MEME_FILE_UPLOAD_DIMENSION_MIN_HEIGHT &&
              width >= MEME_FILE_UPLOAD_DIMENSION_MIN_WIDTH
            ) {
              // check aspect ratio
              const aspectRatioNumber = width / height
              if (
                aspectRatioNumber >= MEME_FILE_UPLOAD_ASPECT_RATIO_MINIMUM &&
                aspectRatioNumber <= MEME_FILE_UPLOAD_ASPECT_RATIO_MAXIMUM
              ) {
                resolve({ data: { width, height } })
              } else {
                reject(MEME_FILE_UPLOAD_ASPECT_RATIO_ERROR_VIDEO)
              }
            } else {
              reject(MEME_FILE_UPLOAD_DIMENSION_ERROR_VIDEO)
            }
          }
        } catch (error) {
          reject('Failed to load video')
        }
      } else {
        reject('Unknown File type provided')
      }
    } catch (error: any) {
      reject(error)
    }
  })
}

interface ProcessFileOptions {
  file: File
  ignoreResolutionCheck?: boolean
}

interface ProcessFileResults {
  data: string
  imageResolution: ImageDimension | null
  type: 'notSvg'
  imageType: string
  fileExtentionType: ImageFileTypeExtensionType | VideoFileTypeExtensionType
  fileType: FileType
  ignoreResolutionCheck: boolean
}

export const processFile = async (
  options: ProcessFileOptions
): Promise<ProcessFileResults> => {
  const { file, ignoreResolutionCheck } = options
  if (!file) {
    throw new Error('File not provided')
  }
  try {
    const { type, extension, size: fileSize } = getFileMetadata(file)
    console.log('extension', extension)

    if (!FileTypes.includes(type)) {
      throw new Error('Invalid Filetype')
    }

    if (type === FileTypeMap.image || type === FileTypeMap.gif) {
      // check if is Image
      if (
        !ImageFileTypeExtensions.includes(
          extension as ImageFileTypeExtensionType
        )
      ) {
        throw new Error(MEME_FILE_UPLOAD_FORMAT_ERROR_MESSAGE_IMAGE)
      }
    } else if (type === FileTypeMap.video) {
      // check video
      if (
        !VideoFileTypeExtensions.includes(
          extension as VideoFileTypeExtensionType
        )
      ) {
        throw new Error(MEME_FILE_UPLOAD_FORMAT_ERROR_MESSAGE_VIDEO)
      }
    }

    if (fileSize > config.imageMaxSizeInBytes) {
      if (type === FileTypeMap.video) {
        throw new Error(MEME_FILE_UPLOAD_SIZE_ERROR_MESSAGE_VIDEO)
      }
      throw new Error(MEME_FILE_UPLOAD_SIZE_ERROR_MESSAGE_IMAGE)
    }

    try {
      const base64DataUri = await getImageBase64DataUri(file)
      let res: any = {
        data: null
      }
      if (!ignoreResolutionCheck) {
        res = await checkResolution(base64DataUri, type)
      }
      return {
        data: base64DataUri,
        imageResolution: res.data,
        type: 'notSvg',
        imageType: extension.toUpperCase(), // TODO: remove and replace w/ fileExtentionType
        fileExtentionType: extension,
        fileType: type,
        ignoreResolutionCheck: Boolean(ignoreResolutionCheck)
      }
    } catch (error: any) {
      throw new Error(error)
    }
  } catch (error) {
    throw error
  }
}
