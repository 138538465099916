import axios from 'axios'
import { clearLocalStorage, getUserFromLocalStorage } from 'services/localStorageService'
import { getParsedJWTPayload } from 'utils/utils'
import config from 'utils/config'

const oldestAllowedSessionStartTime = parseInt(config.oldestAllowedSessionStartTime)

export const youMemeAxiosInstance = axios.create({
  baseURL: `${config.apiEndpoint}/api`
})

const instance = axios.create({
  baseURL: config.apiEndpoint,
  headers: {},
  responseType: 'json'
})

instance.interceptors.request.use(
  (config) => {
    const userData: string = getUserFromLocalStorage() as string
    const localData = userData !== '' ? JSON.parse(userData) as { token: string } : null
    if (
      config.headers != null &&
      localData !== null &&
      localData.token !== null
    ) {
      config.headers.Authorization = `Bearer ${localData.token}`

      const tokenObject = getParsedJWTPayload(localData.token)
      if (tokenObject.iat < oldestAllowedSessionStartTime) {
        clearLocalStorage()
        window.location.href = '/'
      }
    }
    return config
  },
  (error) => {
    void Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error: any) => {
    if (error.response !== null && error.response.status === 401) {
      clearLocalStorage()
      window.location.href = '/'
    }
  }
)

export default instance
