import { makeStyles } from '@material-ui/core/styles'

const useRewardEmptyStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '.5rem'
  },
  header: {
    color: '#E9F0F9',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '1.4rem',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '1.875rem',
    letterSpacing: '-0.08125rem'
  },
  description: {
    color: '#E9F0F9',
    fontFamily: 'Inter',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.875rem',
    letterSpacing: '-0.08125rem'
  },
  browseLink: {
    'border-radius': '6.25rem',
    background: 'linear-gradient(87deg, #625FFF 2.13%, #A55FFF 163.86%)',
    boxShadow: '0px 4px 8px 0px rgba(14, 20, 34, 0.20)',
    padding: '.75rem 2rem',

    // typography
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '0.9375rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '1.1875rem',
    letterSpacing: '-0.04375rem'
  }
})

export default useRewardEmptyStyles
