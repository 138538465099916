
import { ReactionType } from 'ymca/models/reaction.model'
import { QuerySearchParamsDto } from './common.dto'
import { URLSearchParamsCapable } from '../jsonFetcher'

export class CreateReactionDto implements URLSearchParamsCapable {
  public postId: string = ''
  public reactionType: ReactionType = 'Like'

  public toURLSearchParams (): URLSearchParams {
    const params = new URLSearchParams()
    params.append('postId', this.postId)
    params.append('reactionType', this.reactionType)
    return params
  }
}

export class UserReactionDto {
  postId: string = ''
  public reactionType: ReactionType = 'Like'
}

export class GetReactionsDto extends QuerySearchParamsDto {
  public postIds: string[] = []

  public toURLSearchParams (): URLSearchParams {
    const params = super.toURLSearchParams()
    if (this.postIds.length > 0) {
      params.append('postIds', this.postIds.join(','))
    }
    return params
  }
}
