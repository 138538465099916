import {
  PaginatedGenerator,
  PaginatedResponse,
  SortOrder
} from 'ymca/dtos/common.dto'
import {
  CreateEventDto,
  EventSort,
  EventTense,
  GetEventsDto,
  UpdateEventDto,
  UpdateWinnersDto,
  createEventDTOAsFormData
} from 'ymca/dtos/event.dto'
import { APIResponse, ParamsCapableToPlainJSON } from 'ymca/jsonFetcher'
import { Event, EventType } from 'ymca/models/event.model'
import { BaseService } from './base.service'
import { Post } from '../models/post.model'
import { MimeType } from '../models/image.model'

export const EVENTCACHE_TTL_SECONDS = 60 // 1 minute
export const EVENT_PAGE_SIZE = 100 // you can set a smaller number to test!

export class EventService extends BaseService {
  // getEventsWithDTO accepts a FindEventsDto and uses it to fetch events.
  public async getEventsWithDTO(
    req: GetEventsDto
  ): Promise<APIResponse<PaginatedResponse<Event>>> {
    const res = await this.common.jsonFetcher.fetchJSON<
      PaginatedResponse<Event>
    >('GET', '/api/event', req)
    const events = res?.data?.data
    if (!Array.isArray(events)) {
      const msg = `Failed to fetch events from API: ${JSON.stringify(req)}`
      console.error(msg)
      console.error(res)
      throw new Error(msg)
    }
    // const eventUserIds: Set<string> = new Set<string>()
    // for (const event of events) {
    //   eventUserIds.add(event.fkUserId)
    // }
    // const eventUsers = await this.userService.getUsersByIds(Array.from(eventUserIds))
    // await this.imageService.chooseBestImageFormat()
    for (const event of events) {
      // await this.imageService.setImageURLs(event.image)
      // const user = eventUsers.get(event.fkUserId)
      // if (user !== undefined) {
      //   event.user = user
      // }
      await this.setEventInCache(event, true)
    }
    return res
  }

  protected getEventCacheKey(eventId: string): string {
    return `event-${eventId}`
  }

  protected async setEventInCache(
    event: Event,
    skipCache = false
  ): Promise<void> {
    const key = this.getEventCacheKey(event.id)
    skipCache = true
    await this.common.kvStore.set(key, event, EVENTCACHE_TTL_SECONDS, skipCache)
    this.common.pubsub.publish(event.id, event)
  }

  protected async getEventFromCache(
    eventId: string,
    skipCache: boolean
  ): Promise<Event | null> {
    const key = this.getEventCacheKey(eventId)
    const event = await this.common.kvStore.get<Event>(key, skipCache)
    return event
  }

  public async getEventsByIds(
    eventIds: string[],
    skipCache = false
  ): Promise<Map<string, Event>> {
    const eventMap = new Map<string, Event>()
    const uncachedEventIds: string[] = []
    for (const eventId of eventIds) {
      const event = await this.getEventFromCache(eventId, skipCache)
      if (event !== null) {
        eventMap.set(eventId, event)
      } else {
        uncachedEventIds.push(eventId)
      }
    }
    if (uncachedEventIds.length > 0) {
      const events = await this.getEventsByIdFromAPI(uncachedEventIds)
      for (const event of events) {
        eventMap.set(event.id, event)
      }
    }
    return eventMap
  }

  /**
   * getAllEvents is a helper around getEventsWithDTO that fetches all events
   * by simplified parameters
   * Ideally you should use one of the other methods that calls getAllEvents
   * @param limit defaults to 10
   * @param offset defaults to 0
   * @param tense can be past, present, presentfuture, or future
   * @param type include all types if undefined; otherwise just the speciifed type (e.g. 'competition')
   * @param ownerId if provided, only show events by this person
   * @param winnerId if provided, only show events with this winner
   * @param order defaults to 'ASC' (oldest first)
   * @returns list of events
   */
  public async getAllEvents(
    limit: number = 10,
    offset: number = 0,
    tense?: EventTense,
    type?: EventType,
    ownerId?: string,
    winnerId?: string,
    order: SortOrder = 'ASC',
    sort: EventSort = 'startingDate'
  ): Promise<APIResponse<PaginatedResponse<Event>>> {
    const req = new GetEventsDto()
    req.limit = limit
    req.offset = offset
    req.order = order
    if (type !== undefined) {
      req.type = [type]
    }
    req.tense = tense
    req.ownerId = ownerId
    req.winnerId = winnerId
    req.sort = sort
    const res = await this.getEventsWithDTO(req)
    return res
  }

  /**
   * Fetch events won by a user. Combine with SelfService
   * to get events won by the current user.
   * @param userId the user who won the event
   * @param limit if undefined, defaults to 10
   * @param offset if undefined, defaults to 0
   * @param order if undefined, defaults to 'ASC'
   * @returns a list of events won by the user
   */
  public getEventsWonByUserId(dto: GetEventsDto): PaginatedGenerator<Event> {
    const queryArgs = ParamsCapableToPlainJSON(dto)
    const res = this.common.jsonFetcher.fetchPaginatedJSON<Event>(
      '/api/event',
      queryArgs
    )

    return res
  }

  protected async getEventsByIdFromAPI(eventIds: string[]): Promise<Event[]> {
    const results: Event[] = []
    for (let i = 0; i < eventIds.length; i += EVENT_PAGE_SIZE) {
      const req = new GetEventsDto()
      req.eventIds = eventIds.slice(i, i + EVENT_PAGE_SIZE)
      const res = await this.getEventsWithDTO(req)
      const events = res?.data?.data
      if (!Array.isArray(events)) {
        const msg = `Failed to fetch events from API: ${JSON.stringify(req)}`
        console.error(msg)
        console.error(res)
        throw new Error(msg)
      }
      results.push(...events)
    }
    return results
  }

  public async getEventById(eventId: string): Promise<Event | null> {
    const dto = new GetEventsDto()
    dto.eventIds = [eventId]
    const res = await this.getEventsWithDTO(dto)
    const events = res?.data?.data
    if (!Array.isArray(events) || events.length !== 1) {
      return null
    }
    const event = events[0]
    return event
  }

  // getEventsByUserId returns all events by a given user, including expired ones,
  // with the most recent first
  // If you pass the optional tense parameter, you can restrict the results to
  // specific tenses (e.g. 'past', 'present', 'future', 'presentfuture')
  public async getEventsByUserId(
    userId: string,
    limit: number = 10,
    offset: number = 0,
    tense?: EventTense,
    type?: EventType
  ): Promise<APIResponse<PaginatedResponse<Event>>> {
    const req = new GetEventsDto()
    req.limit = limit
    req.offset = offset
    req.order = 'DESC'
    if (type !== undefined) {
      req.type = [type]
    }
    req.tense = tense
    req.ownerId = userId
    req.sort = 'createdAt'
    const res = await this.getEventsWithDTO(req)
    return res
  }

  public async getPastEventsByUserId(
    userId: string,
    limit: number = 10,
    offset: number = 0
  ): Promise<APIResponse<PaginatedResponse<Event>>> {
    return await this.getEventsByUserId(userId, limit, offset, 'past')
  }

  public async getActiveEventsByUserId(
    userId: string,
    limit: number = 10,
    offset: number = 0
  ): Promise<APIResponse<PaginatedResponse<Event>>> {
    return await this.getEventsByUserId(userId, limit, offset, 'present')
  }

  public async getFutureEventsByUserId(
    userId: string,
    limit: number = 10,
    offset: number = 0
  ): Promise<APIResponse<PaginatedResponse<Event>>> {
    return await this.getEventsByUserId(userId, limit, offset, 'future')
  }

  public async getActiveAndFutureMemeContests(
    limit: number = 10,
    offset: number = 0
  ): Promise<APIResponse<PaginatedResponse<Event>>> {
    const req = new GetEventsDto()
    req.limit = limit
    req.offset = offset
    req.order = 'ASC'
    req.type = ['competition']
    req.tense = 'presentfuture'
    req.sort = 'createdAt'
    const res = await this.getEventsWithDTO(req)
    return res
  }

  public async getPastMemeContests(
    limit: number = 10,
    offset: number = 0
  ): Promise<APIResponse<PaginatedResponse<Event>>> {
    const req = new GetEventsDto()
    req.limit = limit
    req.offset = offset
    req.order = 'DESC'
    req.type = ['competition']
    req.tense = 'past'
    req.sort = 'createdAt'
    const res = await this.getEventsWithDTO(req)
    return res
  }

  public async getActiveMemeContests(
    limit: number = 10,
    offset: number = 0
  ): Promise<APIResponse<PaginatedResponse<Event>>> {
    const req = new GetEventsDto()
    req.limit = limit
    req.offset = offset
    req.order = 'ASC'
    req.type = ['competition']
    req.tense = 'present'
    req.sort = 'createdAt'
    const res = await this.getEventsWithDTO(req)
    return res
  }

  public async getFutureMemeContests(
    limit: number = 10,
    offset: number = 0
  ): Promise<APIResponse<PaginatedResponse<Event>>> {
    const req = new GetEventsDto()
    req.limit = limit
    req.offset = offset
    req.order = 'ASC'
    req.type = ['competition']
    req.tense = 'future'
    req.sort = 'createdAt'
    const res = await this.getEventsWithDTO(req)
    return res
  }

  public async getEventsForSidebar(
    limit: number = 10,
    offset: number = 0
  ): Promise<APIResponse<PaginatedResponse<Event>>> {
    const req = new GetEventsDto()
    req.limit = limit
    req.offset = offset
    req.order = 'ASC'
    req.type = ['competition']
    req.tense = 'presentfuture'
    req.sort = 'createdAt'
    const res = await this.getEventsWithDTO(req)
    return res
  }

  public async createEvent(dto: CreateEventDto): Promise<Event> {
    const formData = createEventDTOAsFormData(dto)
    const res = await this.common.jsonFetcher.fetchJSON<Event>(
      'POST',
      '/api/event',
      undefined,
      formData,
      'application/x-www-form-urlencoded'
    )
    if (!res.isSuccess) {
      console.error(res)
      throw new Error(`Failed to create event: ${res}`)
    }
    return res.data
  }

  public async updateEvent(
    eventId: string,
    updates: UpdateEventDto
  ): Promise<Event> {
    const res = await this.common.jsonFetcher.fetchJSON<Event>(
      'PATCH',
      `/api/event/${eventId}`,
      undefined,
      updates
    )
    if (!res.isSuccess) {
      console.error(res)
      throw new Error(`Failed to update event: ${res}`)
    }
    return res.data
  }

  public async updateEventImage(eventId: string, file: File): Promise<Event> {
    const res = await this.common.jsonFetcher.fetchJSON<Event>(
      'PUT',
      `/api/event/${eventId}/image`,
      undefined,
      file,
      file.type as MimeType
    )
    if (!res.isSuccess) {
      console.error(res)
      throw new Error(`Failed to update event: ${res}`)
    }
    return res.data
  }

  public async deleteEvent(eventId: string): Promise<APIResponse<Event>> {
    const res = await this.common.jsonFetcher.fetchJSON<Event>(
      'DELETE',
      `/api/event/${eventId}`
    )
    if (!res.isSuccess) {
      console.error(res)
      throw new Error(`Failed to update event: ${res}`)
    }
    return res
  }

  public async addWinningPost(
    eventId: string,
    dto: UpdateWinnersDto
  ): Promise<APIResponse<null>> {
    const res = await this.common.jsonFetcher.fetchJSON<null>(
      'PUT',
      `/api/event/${eventId}/winner`,
      undefined,
      dto
    )
    if (!res.isSuccess) {
      console.error(res)
      throw new Error(`Failed to update event: ${res}`)
    }
    return res
  }

  public async deleteWinningPost(
    eventId: string,
    postId: string
  ): Promise<APIResponse<Post>> {
    const res = await this.common.jsonFetcher.fetchJSON<Post>(
      'DELETE',
      `/api/event/${eventId}/winner/${postId}`
    )
    if (!res.isSuccess) {
      console.error(res)
      throw new Error(`Failed to update event: ${res}`)
    }
    return res
  }
}
