export function ttlSecondsToTimeLeft(seconds: number) {
  if (seconds <= 0) {
    return ''
  }
  // Returns a human-readable string of the form "1d 2h 3m"
  const days = Math.floor(seconds / 86400)
  const hours = Math.floor((seconds % 86400) / 3600)
  const minutes = Math.floor(((seconds % 86400) % 3600) / 60)
  const daysStr = days > 0 ? `${days}d ` : ''
  const hoursStr = hours > 0 ? `${hours}h ` : ''
  const minutesStr = minutes > 0 ? `${minutes}m` : ''
  return 'You can retry in ' + daysStr + hoursStr + minutesStr
}
