import { ThemeProvider as Mui4ThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider as Mui5ThemeProvider } from "@mui/material/styles";
import { BreakPointThemeOptions } from "./breakpoint";
import ColorThemeOptions from "./color.interface";
import PaletteThemeOptions from "./palette.interface";
import TypographyThemeOptions from "./typography.interface";
import { ZIndexThemeOptions } from "./zIndex";
import ComponentThemeOptions from "./component.interface";

interface ThemeOptions {
  palette: PaletteThemeOptions;
  typography: TypographyThemeOptions;
  colors: ColorThemeOptions;
  breakpoints: BreakPointThemeOptions;
  zIndex: ZIndexThemeOptions;
  components: ComponentThemeOptions;
}
export default ThemeOptions;

export const ThemeProviderMui4 = Mui4ThemeProvider;
export const ThemeProvider = Mui5ThemeProvider;
