import { getItemFromLocalStorage, removeItemFromLocalStorage } from 'services/localStorageService'
import { ActorRefFrom, assign, createMachine, sendParent, spawn } from 'xstate'
import { stop } from 'xstate/lib/actions'
import {  persistUserSession, rotateUserTokens, verifyAuthenticationToken } from '../services/auth/auth'
import { USER_DATA_LOCALSTORAGE_KEY } from '../constants/variables'
import { User, TokensInterface } from '../types/runtimeSchema'
import { getUserObjFromLocalStorageWithUserSchema, objConformsToUserSchema, storeUserObjInLocalStorageWithUserSchema } from '../utils/utils'
import { DefaultYMCA } from 'ymca/ymca'

interface AuthStates {
  isAuthenticated: boolean | undefined
  userInfo: User | null
}

interface LoginSystemContext {
  authToken: string | null
  userInfo: AuthStates['userInfo']
}

export type LoginSystemEvents =
  | { type: 'VERIFY_TOKEN', token: string }
  | { type: 'EXTERNAL_TOKEN' }
  | { type: 'onDone' }
  | { type: 'onError' }
  | { type: 'COMPLETE_AUTHENTICATION', userInfoWithToken: User }
  | { type: 'RETRY_AUTHENTICATION' }

const defaultLoginSystemContext: LoginSystemContext = {
  authToken: null,
  userInfo: null
}

export const loginSystemMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QBsD2UCWA7AtLAnrAC5gC2OpAhgMYAW2YAdNhkRpcgMQBqAogEoBJAGIBNAPoAVAPIBpXgDlEoAA6pYrDKizKQAD0QBWQwCZGANgDMADgDs1kwEZHABhfnTAGhD5EOE4bmjLa2ACyO4S6W5nYAnCEAvgneaJi4BMRkFDT0WEwsbBycvAAakgIKAIIAMlJyirpqGmzaugYIOOaOjC6hvX1dlrGhodah3r4dfbGM1paWhrEmLvZhJtZJKejYeIQk5FR0DIwAbmAAThgAZvjYUJwQ2vlYJ6gA1kypOxn72Ud5pwu11uWCgCGwr2olBaWAA2i4ALqNdSaVpIfSIEzmFyMQy2FwmWLmULGUKWRy2SwTTGhRjOQwODw2SzLImbEBfdJ7LKHXJMM6XG53TgXc6oc6MFTIaFXcWkRic3aZA45Y4C4F3cEvVBQmHwpHopqonTo9qOJyWRixeIskwBdbGczUjqOayGRjzJyGULxcLxczmdmKn481UAlSUc6FZDiSgAVyItDAWDYuq0WE4AGFpABZAAK1V45XElQAqpIABKKSSCTOVGvSJSGlEwtp+VyWuaU12WfErAO2Z0mBZ0ky2czLaLm81B7Zc5V-PmSyPR2MJpMpjBp7ScfhF-gSMuV6u1+uCRvI5rptsIMedvHzMK2BkOp0+Py9y19Z-Pxy2+axLOaRKr8vLHBGUbsDG8aJsmqbQumPACCIEgyPITaqC216mogjiGDiYRTtaf74aEVjOnhthWnMZERBEDLWASQHfNyKr-EwVyUBgyBxucYCcHoxDQkwlBXCQ5wABSWG4LgAJScMGrGLscnHcbxYCXsaN6Md0th-h4DgWsMjjOv4YzUZ+JjhB25LMfOoFhvxe4AMpFpprY4QgJHWIwZGBCSbjmOOjixKZZE+eSzhdAZDguMMSTJCAWCoBAcC6IpC5gQCBRQe52GgO0E44m61gBi4hiuBE4SmdEPm9CywyxIxthLGMdkgaG7GAoKIJQHlaIFUYTgWDE7i2Os0k2KF74dFRNjhA4hgLCME7tSGbFLhBq4wRu8EeZhV4DRiHQMow6zOCMURBaEdpvpMrp0nhjI9hVFKWGtSlZSJ65wVuwkQP1JqDR00m+XipjlY4HjYu4d2IEMMwuK4oTjSsT1solGUOV1qk8XxgM3kSMyWCjf56cMQzWKZARUYsdp2nFczLBsmNzh1G0MATnk4OaVFduSXZuOOQWmcYZiRVDjE3ViFIJQkQA */
  createMachine<LoginSystemContext, LoginSystemEvents>(
    {
      predictableActionArguments: true,
      context: defaultLoginSystemContext,
      id: "login-system-machine",
      initial: "initial",
      states: {
        initial: {
          on: {
            VERIFY_TOKEN: {
              actions: "storeAuthTokenInContext",
              target: "verifying",
            },
            EXTERNAL_TOKEN: {
              target: "partial_authentication",
            },
          },
        },
        verifying: {
          invoke: {
            src: "verifyAccessToken",
            onDone: [
              {
                target: "partial_authentication",
              },
            ],
            onError: [
              {
                target: "failure",
              },
            ],
          },
        },
        partial_authentication: {
          on: {
            COMPLETE_AUTHENTICATION: {
              actions: "storeUserCredentialsInContext",
              target: "authenticated",
            },
            RETRY_AUTHENTICATION: {
              actions: "resetContext",
              target: "initial",
            },
            VERIFY_TOKEN: {
              actions: "storeAuthTokenInContext",
              target: "verifying",
            },
          },
        },
        authenticated: {
          entry: "notifyParentOfAuthSuccess",
        },
        failure: {
          entry: "resetContext",
          after: {
            "3000": {
              target: "initial",
            },
          },
        },
      },
    },
    {
      actions: {
        resetContext: assign(defaultLoginSystemContext),

        storeAuthTokenInContext: assign({
          authToken: (_, event) => {
            if (event.type !== 'VERIFY_TOKEN') return null
            return event.token
          }
        }),

        storeUserCredentialsInContext: assign({
          userInfo: (_, event) => {
            if (event.type !== 'COMPLETE_AUTHENTICATION') return null
            if (!objConformsToUserSchema(event.userInfoWithToken)) {
              throw new TypeError('schema not valid')
            }

            return event.userInfoWithToken
          }
        }),

        notifyParentOfAuthSuccess: sendParent((context) => ({
          type: 'SUCCESSFUL_AUTH',
          userInfoWithToken: context.userInfo
        }))
      },

      services: {
        verifyAccessToken: async (context) => await verifyAuthenticationToken(context.authToken as string)
      }
    }
  )

export interface AuthManagerContext {
  loginSystemRef: ActorRefFrom<typeof loginSystemMachine> | null
  authState: AuthStates
}

type AuthManagerEvents =
  | { type: 'always' }
  | { type: 'SUCCESSFUL_AUTH', userInfoWithToken: User }
  | { type: 'onDone', data?: TokensInterface }
  | { type: 'INIT_AUTH' }
  | { type: 'onError' }
  | { type: 'LOG_OUT' }
  | { type: 'UNSUCCESSFUL_AUTH' }

const defaultAuthManagerContext: AuthManagerContext = {
  loginSystemRef: null,
  authState: {
    isAuthenticated: false,
    userInfo: null
  }
}

const hasLoggedInBefore = (): boolean => {
  // FIXME: We will need the rest of the app to adhere to the runtime schema so this can work correctly
  // return objConformsToUserSchema(
  //   JSON.parse(localStorage.getItem(USER_DATA_LOCALSTORAGE_KEY)),
  // );
  return !!getItemFromLocalStorage(USER_DATA_LOCALSTORAGE_KEY, true)!
}

const authenticationProviderMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QEMCuAXAFgWgLbIDtkYAnAOlSI0zAPQEsBjZdSAYgGUBVAYR4FEOHAGJcAMgH0AglwAqACUSgADgHtY9BqoJKQAD0QAWAKyGyARmMAGAOxXzVgGzmbjw1cMAaEAE9EAJn8AZjJrR39HKwBOKKt-QxsADgBfZO80LDxCYjBySgyaOiYWdl01DS0dJH0jYzJ-YyigoPMTGMNzQO8-BBsoxLInQ0TzSKDjY3NLVPTqLKJSMgA3XPoAMx96Aig2CG0wMi2l1QBrA4L5nPIVknXN7YQj1WZKgG0rAF0y9U16bV0DAgpo4BqMgolEuEYv4osZuohHDYzOZElFXKNIR1nDMQBd8AtcstVhstjtciRVORlAAbFhrSm4Mh47KLG53UmPAjHF5-AjvL7Vcq-f7VQGOSJkQxRRxRFFRQwmFz+eEIQz+GxkWFBGxJbXjSY45kE8gFWgMF7sMQAeQA4hIrXJvhVeQDENhDCF-IlGokvQrdYkVTZzGRHC1tX1rJi+oa5virkzqGbiqwIGw9LB0CUmWtWCQABQOKxWACUbCNCdNRQtECdwqqoEBnSCjjIqIiiL6EJ1cN8iCm-jbiQ6fWlMu9zVSaRABFUEDgugri3ySerJVrgp+lVdCGwwMl6vCUqCESjyr7CEmUVCw9aHpct8Mscy8dZxPuUDr29FiBsgQsrSJMWPajFYvY9I41g3q4wTFi2QQes+OCvoSVbmuuX4uj+QIoiGJ7BO4QHmDEiRBCq-geEOHQTA0Yawq4SGXKQmEio2bqtFYB4wcep5WL6KrYJxLaQuqSIovYfGGI4U7JEAA */
  createMachine<AuthManagerContext, AuthManagerEvents>(
    {
      predictableActionArguments: true,
      context: defaultAuthManagerContext,
      id: "auth-manager",
      initial: "unauthenticated",
      states: {
        unauthenticated: {
          entry: "initializeLoginSystemActor",
          always: {
            actions: "storeUserDataInContextFromLocalStorage",
            cond: "hasSession",
            target: "verifying",
          },
          on: {
            SUCCESSFUL_AUTH: {
              actions: "storeUserCredentialsInContext",
              cond: "eventDataIsValid",
              target: "authenticated",
            },
          },
        },
        verifying: {
          invoke: {
            src: "persistSessionIfValid",
            onDone: [
              {
                actions: "updateUserTokensInContextIfNeeded",
                target: "authenticated",
              },
            ],
            onError: [
              {
                actions: [
                  "resetContext",
                  "resetLocalStorage",
                  "stopLoginSystemActor",
                  "destroyLoginSystemActorRef",
                ],
                target: "unauthenticated",
              },
            ],
          },
        },
        authenticated: {
          entry: [
            "storeCredentialsInLocalStorage",
            "setAuthToTrue",
            "stopLoginSystemActor",
          ],
          after: {
            "1000": {
              actions: "destroyLoginSystemActorRef",
            },
          },
          on: {
            LOG_OUT: {
              actions: ["destroyUserSession", "resetContext"],
              target: "unauthenticated",
            },
          },
        },
      },
    },
    {
      actions: {
        stopLoginSystemActor: stop('loginSystem'),

        setAuthToTrue: assign({
          authState: (context) => ({
            ...context.authState,
            isAuthenticated: true
          })
        }),

        resetContext: assign(defaultAuthManagerContext),

        resetLocalStorage: (_) => {
          console.log('Cleaning up local storage')
          removeItemFromLocalStorage(USER_DATA_LOCALSTORAGE_KEY)
        },

        storeUserDataInContextFromLocalStorage: assign({
          authState: (_) => ({
            ...defaultAuthManagerContext.authState,
            userInfo: getUserObjFromLocalStorageWithUserSchema()
          })
        }),

        destroyUserSession: async () => {
          await DefaultYMCA.selfService.logout()
        },

        initializeLoginSystemActor: assign({
          loginSystemRef: (context) => {
            const currentActorRef = context.loginSystemRef
            console.log(`Login system actor was ${(currentActorRef != null) ? 'reused' : 'spawned'}`)
            return currentActorRef ?? spawn(loginSystemMachine, 'loginSystem')
          }
        }),

        destroyLoginSystemActorRef: assign({
          loginSystemRef: (_) => {
            console.log('Login system actor destroyed')
            return null
          }
        }),

        verificationHasFailed: () => {
          removeItemFromLocalStorage(USER_DATA_LOCALSTORAGE_KEY)
        },

        storeCredentialsInLocalStorage: (context) => {
          storeUserObjInLocalStorageWithUserSchema(context.authState.userInfo as User)
        },

        updateUserTokensInContextIfNeeded: assign({
          authState: (context, event) => {
            if (event.type !== 'onDone') return context.authState
            if ((event?.data) == null) return context.authState
            return { ...context.authState, userInfo: rotateUserTokens(event.data) as User }
          }
        }),

        storeUserCredentialsInContext: assign({
          authState: (ctx, event) => {
            if (event.type !== 'SUCCESSFUL_AUTH') return defaultAuthManagerContext.authState
            if (!objConformsToUserSchema(event.userInfoWithToken)) {
              throw new TypeError('Schema issues with user credentials')
            }

            return {
              ...ctx.authState,
              userInfo: event.userInfoWithToken
            }
          }
        })
      },

      services: {
        persistSessionIfValid: async () => await persistUserSession()
      },

      guards: {
        eventDataIsValid: (_, event) => {
          if (event.type !== 'SUCCESSFUL_AUTH') return false
          const passedDataConformsToSchema = objConformsToUserSchema(event.userInfoWithToken)

          console.log('Transitioning to authenticated')
          return passedDataConformsToSchema
        },

        hasSession: () => {
          const hasBeenAuthedBefore = hasLoggedInBefore()
          console.log(`has logged in before ${hasBeenAuthedBefore}`)
          return hasBeenAuthedBefore
        }
      }
    }
  )

export default authenticationProviderMachine
