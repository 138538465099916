import { QuerySearchParamsDto } from './common.dto';

export type ThrottlePurpose = 'meme-generator' | 'joke-generator' | 'image-generator' | 'image-to-meme-generator' | 'faceswap' | 'faceswap-get-faces';

export class GetRateLimitInfoDto extends QuerySearchParamsDto {
    public purpose: ThrottlePurpose;
    public key: string;

    public constructor(purpose: ThrottlePurpose, key: string) {
        super();
        this.purpose = purpose;
        this.key = key;
    }

    public toURLSearchParams(): URLSearchParams {
        const params = super.toURLSearchParams();
        params.append('purpose', this.purpose);
        params.append('key', this.key);
        return params;
    }
}

export type RateLimitInfoResponse = {
    ttl: number;
    count: number;
    limit: number;
}
