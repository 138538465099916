import { createTheme } from "@mui/material/styles";
import { createTheme as mui4CreateTheme } from "@material-ui/core/styles";
import breakpoints from "theme/shared/breakpoint";
import ThemeOptions from "theme/shared/theme";
import zIndex from "theme/shared/zIndex";
import colors from "./colors";
import palettes from "./palettes";
import typography from "./typography";
import globals from "theme/shared/global";

const darkTheme: ThemeOptions = {
  palette: palettes,
  typography,
  colors,
  breakpoints,
  zIndex,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals,
      },
    },
  },
};

export const themev4 = mui4CreateTheme(darkTheme as any);
const theme = createTheme(darkTheme as any);

export default theme;
