import { useNavigate } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { Box, Button } from "@mui/material";

import { Image } from "components/shared/index";
import { useAuth } from "context/auth/authContext";

import useDiamondRewardCardStyles from "./style";
import { redirectLinks } from "constants/redirectLinks";

interface DiamondRewardCardProps {
  onRewardClick?: VoidFunction;
}

const DiamondRewardCard = (props: DiamondRewardCardProps): JSX.Element => {
  const classes = useDiamondRewardCardStyles();
  const navigate = useNavigate();
  const { userInfo } = useAuth();

  const userData = userInfo?.userData;

  return (
    <Box className={classes.diamondReward}>
      <Box className={classes.diamondRewardTop}>
        <Typography className={classes.diamondRewardMyDiamond}>
          My Diamond
        </Typography>
        <Box display='flex' gap='0.25rem' alignItems='center'>
          <Image src='/assets/sidebar/reward-diamond.png' />
          <Typography className={classes.diamondRewardValue}>
            {userData?.ymdBalance ?? 0}
          </Typography>
        </Box>
      </Box>
      <Box px='.5rem'>
        <Button
          onClick={() => {
            props?.onRewardClick?.();
            navigate(`/${redirectLinks.referral}`);
          }}
          className={classes.diamondRewardBtn}
        >
          Rewards
        </Button>
      </Box>
    </Box>
  );
};

export default DiamondRewardCard;
