import { useState, useEffect, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Box,
  Menu,
  Typography,
  Button,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import { Image } from "components/shared/index";
import { imagesSvg } from "constants/images";
import { Spinner } from "components/loading/LoadingSpinner";
import { borderRadius } from "constants/variables";
import { DefaultYMCA } from "ymca/ymca";
import { YMNotification } from "ymca/models/notification.model";
import { DEFAULT_AVATAR_IMAGE } from "ymca/services/image.service";

import colors from "theme/dark/colors";

interface NotificationProps {
  open?: boolean;
  setOpen?: (value: boolean) => void;
  unreadCount?: number;
  setUnreadCount: (value: number) => void;
  anchorEl?: any;
  setAnchorEl?: any;
}

const StyledMenu = withStyles({
  paper: {
    borderRadius: "7px",
    boxShadow: "0px 3px 6px #00000029",
    marginTop: "11px",
    boxSizing: "border-box",
    background: `${colors.darkBlue} 0% 0% no-repeat padding-box !important`,
    padding: 6,
    border: `1px solid ${colors.border}`,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    PaperProps={{
      style: {
        left: "0%",
      },
    }}
    {...props}
  />
));

const useStyles = makeStyles({
  menucontainer: {
    display: "grid",
    gridRowGap: 2,
    width: 500,
    maxHeight: 550,
    overflow: "auto !important",
    "&::-webkit-scrollbar": {
      width: 7,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#625FFF",
      borderRadius: 100,
    },
  },
  header: {
    width: "100%",
    height: 50,
    borderRadius: "7px 7px 0 0",
    backgroundColor: "transparent",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
    paddingLeft: 22,
    paddingRight: 22,
  },
  text1: {
    fontSize: 19,
    fontWeight: 800,
    color: "#E9F0F9",
  },
  buttons: {
    display: "flex",
  },
  button: {
    height: 40,
    width: 40,
    borderRadius: 5,
    color: "#B5BCC8",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  show: {
    backgroundColor: "#14161E",
  },
  tabs: {
    height: 50,
    width: "100%",
    display: "flex",
  },
  tab: {
    width: "50%",
    cursor: "pointer",
    fontSize: 14,
    color: "#959BAA",
  },
  active: {
    color: "var(--gradient-fall-back)",
    borderBottom: "2px solid var(--gradient-fall-back)",
  },
  body: {
    boxSizing: "border-box",
    marginTop: 25,
    paddingRight: 0,
    display: "flex",
    flexDirection: "column",
  },
  text2: {
    color: "#959BAA",
    fontSize: 12,
    marginBottom: 20,
  },
  items: {
    width: "100%",
    overflow: "auto !important",
    "&::-webkit-scrollbar": {
      width: 7,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#2A3039",
      borderRadius: 100,
    },
  },
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    marginBottom: 25,
    cursor: "pointer",
    paddingLeft: 22,
    paddingRight: 22,
    transition: "0.5s",
    boxSizing: "border-box",
    "& img": {
      width: 50,
      height: 50,
      borderRadius,
    },
    "&:hover": {
      background: colors.darkBlueActive,
      padding: "0 22px",
    },
  },
  description: {
    fontSize: 14,
    color: "#E9F0F9",
    fontWeight: 500,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
  },
  bold: {
    fontWeight: 800,
    fontSize: 16,
  },
  meme: {
    color: "var(--gradient-fall-back)npm ",
  },
  content: {
    color: "#B5BCC8",
    fontFamily: "InterItalic",
    fontSize: 13,
  },
  time: {
    fontSize: 12,
    color: "#7F8594",
  },
  texts: {
    marginLeft: 13,
    width: 288,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
  },
  dot: {
    width: 12,
    height: 12,
    background: "#6E6BFF",
    borderRadius: 100,
    marginLeft: 16,
  },
  memeBox: {
    display: "flex",
    alignItems: "center",
    "& img": {
      width: "70px !important",
      height: "56px !important",
      borderRadius: 8,
    },
  },
  seeAll: {
    height: 46,
    background: "#6E6BFF",
    flex: 1,
    borderRadius: 8,
    color: "#FFFFFF",
    fontWeight: 700,
    "&:hover": {
      background: "#6E6BFF",
    },
  },
  markAll: {
    height: 46,
    background: "#2B3853",
    borderRadius: 8,
    marginLeft: 16,
    "&:hover": {
      background: "#2B3853",
    },
  },
  notifButton: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    "&:hover": {
      background: "transparent",
    },
  },
  reactBox: {
    width: 24,
    height: 24,
    borderRadius: 100,
    boxShadow: "0px 0px 14px rgba(0, 0, 0, 0.8)",
    position: "absolute",
    bottom: 0,
    right: 0,
    display: "grid",
    placeItems: "center",
    "& img": {
      width: 11,
      height: 9,
    },
  },
  errorBox: {
    width: "100%",
    height: "100%",
    display: "grid",
    placeItems: "center",
  },
  comment: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const NotificationMenu = ({
  open,
  setOpen,
  unreadCount,
  setUnreadCount,
  anchorEl,
  setAnchorEl,
}: NotificationProps): JSX.Element => {
  const classes = useStyles();

  const [data, setData] = useState<YMNotification[]>([]);
  const offset = useRef(0);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(0);
  const [isFetchingNext, setIsFetchingNext] = useState(false);
  const [loading, setLoading] = useState(false);
  async function notification() {
    try {
      offset.current === 0 ? setLoading(true) : setIsFetchingNext(true);
      const res = await DefaultYMCA.notificationService.getAllNotifications(
        5,
        offset.current
      );
      setData((prev: any) => [...prev, ...res.data]);
      setTotal(res?.count);
      offset.current += 5;
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
      setIsFetchingNext(false);
    }
  }

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  async function markAsRead(id: string) {
    await DefaultYMCA.notificationService.markNotificationRead(id);
    setAnchorEl(null);
    let newly = await DefaultYMCA.notificationService.getAllNotifications(5, 0);
    setData(newly?.data);
    offset.current = 5;
  }

  async function markAllAsRead() {
    console.log("markallasread");
    await DefaultYMCA.notificationService.markAllNotificationsRead();
    setAnchorEl(null);
    setUnreadCount(0);
  }

  useEffect(() => {
    notification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <StyledMenu
      id='notification-menu'
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <Box className={classes.menucontainer}>
        <Box className={classes.header}>
          <Typography className={classes.text1}>Notifications</Typography>
        </Box>
        {loading ? (
          <Box>
            <Spinner width={50} height={50} />
          </Box>
        ) : error ? (
          <Box className={classes.errorBox}>
            <Typography className={classes.text1}>{error}</Typography>
          </Box>
        ) : data?.length > 0 ? (
          <Box className={classes.body}>
            <Box className={classes.items}>
              {data?.map((item, index) => (
                <Link
                  key={`notification_${index}`}
                  className={classes.item}
                  to={
                    item.type === "comment" || item.type === "reaction"
                      ? `/meme/${item?.metadata?.post?.id}`
                      : `/profile/${item?.metadata?.user?.username}`
                  }
                  onClick={() => markAsRead(item.id)}
                >
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <Box style={{ position: "relative" }}>
                      <Image
                        src={
                          item?.fromUser?.avatar?.jpegUrl ??
                          DEFAULT_AVATAR_IMAGE
                        }
                        alt='notification avatar'
                      />
                      <Box
                        className={classes.reactBox}
                        style={{
                          background:
                            item?.type === "reaction"
                              ? "#625FFF"
                              : item?.type === "comment"
                              ? "#FFAB5E"
                              : "#FF6B6B",
                        }}
                      >
                        {item?.type === "comment" ? (
                          <svg
                            width='12'
                            height='12'
                            viewBox='0 0 12 12'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M6.23927 0.666504C2.38576 0.666504 -0.239667 4.57107 1.21425 8.13978L1.83581 9.66542C1.89143 9.80194 1.85077 9.95878 1.73583 10.0511L0.421506 11.1066C0.31122 11.1952 0.268842 11.3437 0.315788 11.4771C0.362734 11.6106 0.488779 11.6998 0.630227 11.6998H5.84375C9.05891 11.6998 11.6653 9.09343 11.6653 5.87827C11.6653 2.99989 9.33193 0.666504 6.45355 0.666504H6.23927Z'
                              fill='white'
                            />
                          </svg>
                        ) : item?.type === "follow" ? (
                          <svg
                            width='14'
                            height='14'
                            viewBox='0 0 14 14'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M7.57617 0.997603C7.31925 0.554836 6.67985 0.554834 6.42293 0.997603L4.61356 4.11581C4.48664 4.33453 4.26728 4.48379 4.01724 4.52156L0.626361 5.03371C0.0568389 5.11973 -0.142934 5.8388 0.300603 6.20627L2.82666 8.29908C3.06049 8.49281 3.17044 8.79838 3.11364 9.09668L2.45286 12.5668C2.3484 13.1153 2.92426 13.5415 3.41836 13.2814L6.6113 11.6002C6.85432 11.4722 7.14478 11.4722 7.3878 11.6002L10.5807 13.2814C11.0748 13.5415 11.6507 13.1153 11.5462 12.5668L10.8855 9.09668C10.8287 8.79838 10.9386 8.49281 11.1724 8.29908L13.6985 6.20627C14.142 5.8388 13.9423 5.11973 13.3727 5.03371L9.98187 4.52156C9.73183 4.48379 9.51246 4.33453 9.38554 4.11581L7.57617 0.997603Z'
                              fill='white'
                            />
                          </svg>
                        ) : (
                          <svg
                            width='12'
                            height='11'
                            viewBox='0 0 12 11'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M3.6013 0.5C1.7474 0.5 0.167969 1.8862 0.167969 3.68653C0.167969 4.9285 0.749533 5.97412 1.5203 6.84028C2.28841 7.70347 3.2794 8.42801 4.17528 9.03443L5.72103 10.0807C5.89031 10.1953 6.11229 10.1953 6.28158 10.0807L7.82732 9.03443C8.72321 8.42801 9.71419 7.70347 10.4823 6.84028C11.2531 5.97412 11.8346 4.9285 11.8346 3.68653C11.8346 1.8862 10.2552 0.5 8.4013 0.5C7.44565 0.5 6.60474 0.948093 6.0013 1.52789C5.39786 0.948093 4.55695 0.5 3.6013 0.5Z'
                              fill='white'
                            />
                          </svg>
                        )}
                      </Box>
                    </Box>
                    <Box className={classes.texts}>
                      <Typography className={classes.description}>
                        <Typography component='span' className={classes.bold}>
                          {item?.metadata?.user?.username}
                        </Typography>{" "}
                        {item?.type === "reaction" ? (
                          "Reacted to your meme"
                        ) : item?.type === "follow" ? (
                          "Followed you"
                        ) : (
                          <>
                            Commented : <br />
                            <Typography
                              component='span'
                              className={classes.comment}
                            >
                              {item?.metadata?.comment?.comment}
                            </Typography>
                          </>
                        )}
                      </Typography>
                      <Typography className={classes.time}>
                        {moment(
                          new Date(item?.createdAt!),
                          "YYYYMMDD"
                        ).fromNow()}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.memeBox}>
                    {item?.metadata?.post && (
                      <Image
                        src={
                          item?.post?.meme?.thumbnailImage
                            ? item.post.meme.thumbnailImage?.urlOriginal
                            : item?.post?.meme?.renderedImage?.jpegUrl
                        }
                        alt='meme'
                      />
                    )}
                    <Box
                      className={classes.dot}
                      style={{ opacity: item?.isRead ? 0 : 1 }}
                    />
                  </Box>
                </Link>
              ))}

              {total > 5 && (
                <Box
                  style={{
                    display: "flex",
                    padding: "0 24px",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                >
                  <Button
                    className={classes.seeAll}
                    onClick={notification}
                    disabled={isFetchingNext || loading}
                  >
                    {isFetchingNext ? (
                      <CircularProgress style={{ color: "white" }} size={18} />
                    ) : (
                      "See More"
                    )}
                  </Button>
                  <Tooltip title='Mark all as read'>
                    <Button
                      className={classes.markAll}
                      onClick={() => markAllAsRead()}
                      disabled={unreadCount === 0}
                    >
                      <Image
                        alt='double tick'
                        src={`reactionIcons/${imagesSvg.doubleTick}`}
                      />
                    </Button>
                  </Tooltip>
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Box style={{ display: "grid", placeItems: "center" }}>
            <p className={classes.text2}>no notification</p>
          </Box>
        )}
      </Box>
    </StyledMenu>
  );
};

export default NotificationMenu;
