import { Box, Button, Popover } from "@mui/material";
import useAuthPopoverStyles from "./style";
import { useNavigate } from "react-router-dom";

interface AuthPopoverProps {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  anchorEl: null | HTMLElement;
}

const AuthPopover = ({ isOpen, id, onClose, anchorEl }: AuthPopoverProps) => {
  const classes = useAuthPopoverStyles();
  const navigate = useNavigate();
  return (
    <Popover
      id={id}
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      disableRestoreFocus
    >
      <Box className={classes.popoverContainer}>
        <Button
          variant='outlined'
          color='primary'
          onClick={() => navigate("/auth")}
        >
          Sign In to continue
        </Button>
      </Box>
    </Popover>
  );
};

export default AuthPopover;
