import { Box, Typography } from "@mui/material";
import { Avatar } from "@material-ui/core";

import Icon from "components/atoms/Icons";
import useCommunityMemberItemCardDStyles from "./style";
import { PublicUser } from "ymca/models/user.model";
import { Link } from "react-router-dom";
import CommunityManageMemberKebabMenu from '../CommunityManageMemberKebabMenu'
import { Community } from 'ymca/models/community.model'

interface CommunityMemberItemCardProps {
  member: PublicUser
  refetchQuery: Function
  community: Community
}

const CommunityMemberItemCard = ({
  member,
  community,
  refetchQuery
}: CommunityMemberItemCardProps) => {
  const classes = useCommunityMemberItemCardDStyles()
  return (
    <Box className={classes.mainAdminCard}>
      <Link
        to={`/profile/${member.username}`}
        className={classes.mainAdminCardLinkSection}
      >
        <Avatar
          className={classes.mainAdminCardAvatar}
          src={member.avatar?.urlOptimised1000x1000}
        />
        <Box className={classes.mainAdminCardContent}>
          <Box className={classes.mainAdminCardContentTop}>
            <Typography className={classes.mainAdminCardContentDisplayName}>
              {member.displayName}
            </Typography>
            <Icon icon='verified' />
          </Box>
          <Typography className={classes.mainAdminCardContentUsername}>
            @{member.username}
          </Typography>
        </Box>
      </Link>
      <CommunityManageMemberKebabMenu
        member={member}
        refetchQuery={refetchQuery}
        community={community}
      />
    </Box>
  )
}

export default CommunityMemberItemCard;
