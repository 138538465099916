import { makeStyles } from '@material-ui/core/styles'

const useAvatarStyles = makeStyles({
  avatar_picture_wrapper: {
    'border-radius': '50%',
    overflow: 'hidden',
    width: '2.5rem',
    height: '2.5rem',
    '& img': {
      'border-radius': '50%'
    }
  }
})

export default useAvatarStyles
