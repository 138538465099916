const tableHeaderZIndex = 800;
const mobileStepperZIndex = 1000;
const fabZIndex = 1050;
const speedDialZIndex = 1050;
const appBarZIndex = 1100;
const drawerZIndex = 1200;
const modalZIndex = 1300;
const snackbarZIndex = 1400;
const tooltipZIndex = 1500;

export const Z_INDEX_MEME_EDITOR_IMAGE_EDIT = 500
export interface ZIndexThemeOptions {
  mobileStepper: number;
  fab: number;
  speedDial: number;
  appBar: number;
  drawer: number;
  modal: number;
  snackbar: number;
  tooltip: number;

  // for tables header to allow body content go beneath when fixed
  tableHeader: number;
}

const zIndex: ZIndexThemeOptions = {
  mobileStepper: mobileStepperZIndex,
  fab: fabZIndex,
  speedDial: speedDialZIndex,
  appBar: appBarZIndex,
  drawer: drawerZIndex,
  modal: modalZIndex,
  snackbar: snackbarZIndex,
  tooltip: tooltipZIndex,
  tableHeader: tableHeaderZIndex,
};

export default zIndex;
