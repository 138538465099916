import colors from './colors'

const formBorderDefault = `1px solid ${colors.border}`
const formBorderFocus = `1px solid ${colors.border}`
const formBorderError = `1px solid ${colors.border}`

const borders = {
  // FORM
  formBorderDefault,
  formBorderFocus,
  formBorderError
}

export default borders
