import { Image } from 'components/shared/index'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Typography } from '@material-ui/core'
import { imagesSvg } from 'constants/images'
import { PublicUser } from 'ymca/models/user.model'
import { useEffect, useState } from 'react'
import { DefaultYMCA } from 'ymca/ymca'
import colors from 'theme/dark/colors'
import { DEFAULT_AVATAR_IMAGE, DEFAULT_BACKGROUND_IMAGE } from 'ymca/services/image.service'
import { MoreHorizOutlined } from '@material-ui/icons'
import Avatar from 'components/molecules/Avatar'
import getImageResolutions, {
  getImageUrlSourceType
} from 'utils/getImageResolutions'

const useStyles = makeStyles({
  memerContainer: {
    width: '328px',
    boxSizing: 'border-box',
    backgroundColor: colors.darkBlue,
    borderRadius: '17px',
    paddingBottom: 16
  },
  memerBanner: {
    minHeight: '120px'
  },
  userDP: {
    position: 'absolute',
    top: 70,
    left: 16
  },
  userAvatar: {
    height: '90px',
    width: '90px',
    borderRadius: '42px',
    border: `6px solid ${colors.darkBlue}`,
    backgroundColor: colors.profileImageBackground,

    '& img': {
      borderRadius: '35px'
    }
  },
  imageStyle: {
    width: '20px !important'
  },
  userName: {
    fontWeight: 900,
    fontSize: '18px',
    lineHeight: '19px'
  },
  userTag: {
    marginTop: '5px',
    color: '#808893',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16.94px'
  },
  userBox: {
    display: 'flex',
    gap: '2px'
  },
  status: {
    marginTop: '16px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '-0.3px',
    width: '100%'
  },
  follow: {
    color: '#808893',
    fontWeight: 600,
    fontSize: '14px'
  },
  followDetails: {
    display: 'flex',
    gap: '10px',
    marginTop: '20px'
  },
  followValue: {
    color: '#E2E4E9',
    fontWeight: 900,
    fontSize: '14px'
  },
  tags: {
    marginTop: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 7
  },
  tag: {
    fontSize: 12,
    fontWeight: 700,
    height: '24px',
    width: 'auto',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '0 .5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#232A30',
    borderRadius: 7,
    marginRight: 4,
    color: '#FFFFFF'
  },
  followButtonSection: {
    marginTop: '20px'
  },
  changeableTextButton: {
    '& span.changeablehoveralt': {
      display: 'none'
    },
    '& span.changeablehoverorig': {
      display: 'inline'
    },
    '&:hover span.changeablehoveralt': {
      display: 'inline'
    },
    '&:hover span.changeablehoverorig': {
      display: 'none'
    }
  },
  followMemerBtn: {
    width: '225px',
    height: '44px',
    borderRadius: '7px',
    backgroundColor: 'var(--gradient-fall-back)',
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    '&:hover': {
      border: '1px solid #35A6EB',
      color: '#35A6EB'
    }
  },
  unfollowButton: {
    width: '225px',
    height: '44px',
    borderRadius: '7px',
    background: 'transparent',
    mixBlendMode: 'normal',
    border: '1px solid #35A6EB',
    color: '#35A6EB',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '-0.3px',
    '&:hover': {
      border: '1px solid #FF0061',
      color: '#FF0061'
    }
  },
  optionMemerBtn: {
    height: '44px',
    backgroundColor: colors.darkBlue50,
    borderRadius: '7px',
    boxSizing: 'border-box',
    lineHeight: '20px',
    marginLeft: '7px',
    '&:hover': {
      backgroundColor: '#232A30'
    }
  },
  memerBackgroundImage: {
    borderRadius: '7px 7px 0 0',
    width: 328,
    height: 120,
    objectFit: 'cover'
  },
  optionIcon: {
    paddingTop: '5px'
  }
})

export interface MemerProfileProps {
  data: PublicUser
  leave: () => void
  enter: () => void
  handleFollow: () => Promise<void>
}

const MemerProfile = ({
  data,
  enter,
  leave,
  handleFollow
}: MemerProfileProps): JSX.Element => {
  const classes = useStyles()

  // We initialize user state w/ data from props
  // We are then notified everytime there is an update to the user
  const [user, setUser] = useState(data)
  useEffect(() => {
    DefaultYMCA.pubsub.subscribe(user.id, setUser)
    return () => DefaultYMCA.pubsub.unsubscribe(user.id, setUser)
  }, [data])

  const { x2, x3, fallback } = getImageResolutions(data?.avatar)

  async function interceptFollow(): Promise<void> {
    await handleFollow()
  }

  if (user === null) return <></>
  const isOwner = user?.id === DefaultYMCA.selfService.getId()

  return (
    <Box
      className={classes.memerContainer}
      onMouseLeave={leave}
      onMouseOver={enter}
    >
      <Box className={classes.memerBanner}>
        <Image
          src={user.background?.urlOptimised500x500 ?? DEFAULT_BACKGROUND_IMAGE}
          fallbackSrc={user.background?.jpegUrl ?? DEFAULT_BACKGROUND_IMAGE}
          className={classes.memerBackgroundImage}
          lazyLoad={true}
          alt='banner'
        />
      </Box>
      <Box className={classes.userDP}>
        <Avatar
          src={fallback ?? DEFAULT_AVATAR_IMAGE}
          pictureSources={[
            {
              srcSet: `${x2} 1x, ${x3} 2x`,
              type: getImageUrlSourceType(x3)
            }
          ]}
          loading='lazy'
          alt='user icon'
          className={classes.userAvatar}
        />
      </Box>

      <Box style={{ margin: '60px 16px 0' }}>
        <Box>
          <Box className={classes.userBox}>
            <Typography className={classes.userName}>
              {user?.displayName ?? 'SuperFunnyMemes'}
            </Typography>
            <Image
              alt='check'
              src={`headerIcons/${imagesSvg.checkIcon}`}
              className={classes.imageStyle}
            />
          </Box>
          <Typography className={classes.userTag}>
            {`@${user.username}`}
          </Typography>
        </Box>
        <Typography className={classes.status}>{user?.biography}</Typography>
        <Box className={classes.tags}>
          {user.hobbies
            ?.slice(
              0,
              user.hobbies && user.hobbies.length > 3 ? 3 : user.hobbies.length
            )
            .map((item: string) => (
              <Typography key={item} className={classes.tag}>
                {item}
              </Typography>
            ))}
          {user.hobbies?.length > 3 && <MoreHorizOutlined />}
        </Box>
        {!isOwner && (
          <Box className={classes.followButtonSection}>
            <Button
              onClick={interceptFollow}
              className={`${
                user.isUserFollowed
                  ? classes.unfollowButton
                  : classes.followMemerBtn
              } ${classes.changeableTextButton}`}
            >
              <span className='changeablehoverorig'>
                {user.isUserFollowed ? 'Following' : 'Follow'}
              </span>
              <span className='changeablehoveralt'>
                {user.isUserFollowed ? 'Unfollow' : 'Follow'}
              </span>
            </Button>
            <Button className={classes.optionMemerBtn}>
              <Image
                className={classes.optionIcon}
                alt='option'
                src={`events/${imagesSvg.optionIcon}`}
              />
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default MemerProfile
