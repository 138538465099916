import CommunitiesGridContainer from "components/organisms/CommunitiesGridContainer";
import {
  CommunitiesOutletContainer,
  CommunitiesPageTitle,
  SearchCommunityForm,
} from "components/layout/CommunitiesWrapper";
import useSearchCommunity from "hooks/useSearchCommunity";
import { useGetCommunitiesQuery } from "hooks/api-hooks/useCommunity";
import { useAuth } from "context/auth/authContext";
import { MetaTags } from "components/shared";
import { redirectLinks } from "constants/redirectLinks";

const CommunitiesManagingPage = () => {
  const { userInfo } = useAuth();

  const query = useGetCommunitiesQuery({
    viewType: "managed",
    userId: userInfo?.userData.id!,
  });

  const handleOnSearch = (searchValue: string) => {
    query.searchRefetch(searchValue);
  };

  const { searchFormRef } = useSearchCommunity({
    onSearch: handleOnSearch,
  });

  return (
    <CommunitiesOutletContainer aria-label='manage your communities'>
      <MetaTags
        show={window.location.href.includes(
          `/${redirectLinks.communities}/managing`
        )}
        title='Manage Communities | YouMeme'
        description='The World’s First Meme-to-Earn Social Network. Select a meme template, add text, images, stickers and save your meme. Your daily dose of meme is here.'
        url={`${process.env.REACT_APP_DEPLOYMENT_URL}/${redirectLinks.communities}/managing`}
      />
      <SearchCommunityForm ref={searchFormRef} />
      <CommunitiesPageTitle title='Manage your Communities' />
      <CommunitiesGridContainer
        isLoading={query.isLoading}
        communities={query.data?.data!}
        hasNextPage={query.data?.hasMore}
        fetchNextPage={query.fetchNextPage}
        isFetchingNextPage={query.isFetching}
        manageCommunity
      />
    </CommunitiesOutletContainer>
  );
};

export default CommunitiesManagingPage;
