import TypographyThemeOptions from "theme/shared/typography.interface";

const typography: TypographyThemeOptions = {
  fontFamily: ["Inter", "Arial", "Helvetica", "sans-serif"].join(","),
  button: {
    textTransform: "none",
  },
  allVariants: {
    color: "#E2E4E9",
  },
};

export default typography;
