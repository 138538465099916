import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Typography } from "@material-ui/core";
import { imagesPng } from "constants/images";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useLocation, useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";

import { borderRadius } from "constants/variables";
import { DefaultYMCA } from 'ymca/ymca'
import { Event } from 'ymca/models/event.model'
import { StandardPageTemplate } from 'components/layout/PageTemplate'
import useOnScreen from 'hooks/useOnScreen'
import Icon from 'components/atoms/Icons'
import { MetaTags } from 'components/shared'
import { getAssetsPath } from '../../utils/path'
import ContestRewardList from './components/rewards'
import { useAuth } from 'context/auth/authContext'
import ContestCard from './components/contest-card'

const useStyles = makeStyles({
  userThumbnailBox: {
    width: 50,
    height: 50,
    borderRadius,
    position: 'absolute',
    bottom: -20,
    left: 10,
    border: '6px solid #202A41',
    backgroundSize: 'contain',
    backgroundColor: 'white',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  container: {
    left: 0,
    right: 0,
    bottom: 0,
    height: '100vh',
    background: '#151E31',
    zIndex: 9,
    paddingBottom: 120
  },
  banner: {
    height: '5.8rem',
    backgroundImage: `url("/assets/banner/${imagesPng.newContestBanner}")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    left: 0,
    zIndex: 9
  },
  bannerContainer: {
    maxWidth: '1024px',
    zIndex: 99,
    height: '100%',
    margin: '0 auto',
    width: '100%',
    position: 'relative',
    '& img': {
      width: 108,
      height: 108,
      position: 'absolute',
      bottom: -50,
      left: 22,
      zIndex: 99,
      borderRadius,
      border: '8px solid #202A41',
      background: '#2B3853',
      display: 'none'
    },
    '& button': {
      display: 'none'
    }
  },
  body: {
    marginTop: '2rem',
    padding: '0 10px',
    position: 'relative',
    overflow: 'hidden !important',
    maxWidth: '1024px',
    margin: '0 auto'
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 900,
    color: '#E9F0F9'
  },
  feature: {
    fontSize: 18,
    fontWeight: 700,
    color: '#E9F0F9',
    marginLeft: 11
  },
  featureTextBox: {
    display: 'none',
    alignItems: 'center',
    marginTop: 34
  },
  subtitle: {
    fontSize: '1rem',
    fontWeight: 500,
    color: '#8A98B4',
    marginTop: '.5rem'
  },
  root: {
    display: 'block',
    marginTop: 8
  },
  tabs: {
    color: '#fff !important',
    fontWeight: 700,
    '& .MuiTabs-indicator': {
      backgroundColor: '#8B89FF',
      height: 3
    },
    '& .MuiTab-root.Mui-selected': {
      color: '#E9F0F9'
    },
    '& .MuiTab-textColorPrimary': {
      color: '#8A98B4'
    },
    '& 	.MuiTab-root': {
      fontSize: 14,
      textTransform: 'capitalize'
    }
  },
  cards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: 26,
    marginTop: 27
  },
  contestCard: {
    width: '100%',
    background: '#2B3853',
    boxShadow: '0px 0px 20px rgba(0, 7, 24, 0.1)',
    borderRadius: 12,
    padding: 14,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    cursor: 'pointer',
    overflow: 'hidden'
  },
  contentBox: {
    marginTop: 27,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block'
    // -webkit-line-clamp: 2,
    // -webkit-box-orient: vertical
  },
  bannerBox: {
    height: 100,
    backgroundSize: 'cover',
    borderRadius: 12,
    position: 'relative',
    backgroundColor: '#7075FF',
    backgroundPosition: 'center',
    '& img': {
      width: 50,
      height: 50,
      borderRadius,
      position: 'absolute',
      bottom: -20,
      left: 10,
      border: '6px solid #202A41'
    }
  },
  contentInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 10
  },
  contestTitle: {
    color: '#E9F0F9',
    fontSize: 20,
    fontWeight: 800,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
    // "&:first-letter": {
    //   textTransform: "uppercase"
    // }
  },
  contentSubtitle: {
    fontSize: 14,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
    // "&:first-letter": {
    //   textTransform: "uppercase"
    // }
  },
  contestButton: {
    borderRadius: 100,
    height: 56,
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: 15,
    marginTop: 22,
    transition: '0.3s',
    '&:hover': {
      background: 'black',
      opacity: 0.3
    }
  },
  timerBox: {
    position: 'absolute',
    padding: '.4rem',
    background: 'rgba(43, 56, 83, 0.7)',
    borderRadius: 100,
    right: 3,
    top: 3,
    display: 'flex',
    gap: 7,
    alignItems: 'center'
  },
  timer: {
    color: '#FFFFFF',
    fontSize: '.75rem',
    fontWeight: 800
  },
  crumbs: {
    display: 'flex',
    gap: 10,
    '& p': {
      color: '#8A98B4',
      fontSize: 13,
      fontWeight: 600,
      '&:last-child': {
        color: '#8B89FF'
      }
    }
  },
  emptyMessage: {
    marginTop: 34
  },
  addText: {
    bottom: 150,
    color: 'white',
    zIndex: 99,
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '1rem',
    '& span': {
      textDecoration: 'underline'
    }
  },
  floatButton: {
    borderRadius: 100,
    height: 56,
    color: '#FFFFFF',
    fontWeight: 700,
    position: 'fixed',
    fontSize: 15,
    bottom: 50,
    right: 50,
    zIndex: 99,
    padding: '0 2rem',
    background: 'linear-gradient(90deg, #625FFF 0%, #FA00FF 238.69%)',
    display: 'none',
    transition: '0.3s',
    '&:hover': {
      opacity: '0.4'
    }
  },
  '@media (min-width: 1023px)': {
    title: {
      fontSize: '2.2rem'
    },
    subtitle: {
      marginTop: '1rem'
    },
    featureTextBox: {
      display: 'flex'
    },
    addText: {
      display: 'none'
    },
    floatButton: {
      display: 'flex'
    },
    banner: {
      backgroundPosition: 'center',
      minWidth: '100%',
      height: 175,
      '& img': {
        left: 117
      },
      '& button': {
        display: 'flex',
        width: 60,
        height: 60,
        position: 'absolute',
        top: 30,
        left: 117,
        background: 'rgba(43, 56, 83, 0.8)',
        mixBlendMode: 'normal',
        border: '2px solid rgba(138, 152, 180, 0.15)',
        boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.2)',
        borderRadius: 100
      }
    },
    bannerContainer: {
      '& img': {
        left: 0,
        display: 'block'
      },
      '& button': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 40,
        height: 40,
        position: 'absolute',
        top: 30,
        left: 0,
        background: 'rgba(43, 56, 83, 0.8)',
        mixBlendMode: 'normal',
        border: '2px solid rgba(138, 152, 180, 0.15)',
        boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.2)',
        borderRadius: '50%'
      }
    },
    body: {
      padding: 0
    },
    tabs: {
      width: 450,
      textAlign: 'center',
      '& .MuiTabs-indicator': {
        backgroundColor: '#8B89FF',
        height: 3
      },
      '& .MuiTab-root.Mui-selected': {
        color: '#E9F0F9'
      },
      '& .MuiTab-textColorPrimary': {
        color: '#8A98B4'
      },
      '& 	.MuiTab-root': {
        fontSize: 16,
        textTransform: 'capitalize',
        textAlign: 'center'
      }
    },
    root: {
      marginTop: 32
    }
  }
})

interface ContextState {
  ongoing: null | number
  upcoming: null | number
  closed: null | number
  getOngoing: (payload: any) => void
  getUpcoming: (payload: any) => void
  getClosed: (payload: any) => void
}
const initState: ContextState = {
  ongoing: null,
  upcoming: null,
  closed: null,
  getOngoing: (payload: any) => {
    return
  },
  getUpcoming: (payload: any) => {
    return
  },
  getClosed: (payload: any) => {
    return
  }
}
const CountContext = createContext<ContextState>(initState)
function reducer(state: ContextState, action: { type: string; payload: any }) {
  switch (action.type) {
    case 'set_value': {
      return { ...state, ongoing: action.payload.ongoing }
    }
    case 'set_upcoming': {
      return { ...state, upcoming: action.payload.upcoming }
    }
    case 'set_closed': {
      return { ...state, closed: action.payload.closed }
    }
    default:
      return state
  }
}
export function CountContextState({ children }: { children: any }) {
  const [state, dispatch] = useReducer(reducer, initState)
  function getOngoing(payload: any) {
    dispatch({ type: 'set_value', payload })
  }
  function getUpcoming(payload: any) {
    dispatch({ type: 'set_upcoming', payload })
  }
  function getClosed(payload: any) {
    dispatch({ type: 'set_closed', payload })
  }
  return (
    <CountContext.Provider
      value={{
        ongoing: state.ongoing,
        upcoming: state.upcoming,
        closed: state.closed,
        getOngoing,
        getUpcoming,
        getClosed
      }}
    >
      {children}
    </CountContext.Provider>
  )
}
const LIMIT: number = 10

const ContestListPage = (): JSX.Element => {
  const classes = useStyles()
  const { isAuthenticated } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [upcomingEvents, setUpcomingEvents] = useState<Event[]>([])
  const { pathname } = useLocation()
  const value = pathname.split('/')[2]
  const [upcomingCount, setUpcomingCount] = useState(0)

  const [ongoingEvents, setOngoingEvents] = useState<Event[]>([])
  const [ongoingCount, setOngoingCount] = useState(0)
  const [completedEvents, setCompletedEvents] = useState<Event[]>([])
  const [completedCount, setCompletedCount] = useState(0)
  const contest = useContext(CountContext)
  const ref = useRef<HTMLDivElement | null>(null)
  const completedOffset = useRef<number>(0)
  const upcomingOffset = useRef<number>(0)
  const ongoingOffset = useRef<number>(0)
  const [isVisible] = useOnScreen({
    ref,
    rootMargin: '0px',
    threshold: 1.0
  })

  function getContestPageThumbnail(
    type: 'ongoing' | 'upcoming' | 'closed'
  ): string {
    const host = process.env.REACT_APP_DEPLOYMENT_URL
    const path = getAssetsPath(`contest/${type}.jpg`)
    return `${host}${path}`
  }

  async function getLiveEvents() {
    try {
      const ongoing = await DefaultYMCA.eventService.getActiveMemeContests(
        LIMIT,
        ongoingOffset.current
      )
      setOngoingEvents((prev) => [...prev, ...ongoing.data.data])
      setOngoingCount(ongoing.data.count)
      contest.getOngoing({
        ongoing: ongoing.data.count
      })
      ongoingOffset.current = ongoingOffset.current + LIMIT
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  async function getUpcomingEvents() {
    completedOffset.current === 0 && setIsLoading(true)
    try {
      const completed = await DefaultYMCA.eventService.getFutureMemeContests(
        LIMIT,
        upcomingOffset.current
      )
      setUpcomingEvents((prev) => [...prev, ...completed.data.data])
      setUpcomingCount((completed as any).data.count)
      contest.getUpcoming({
        upcoming: completed.data.count
      })
      upcomingOffset.current = upcomingOffset.current + LIMIT
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }
  async function getCompletedEvents() {
    completedOffset.current === 0 && setIsLoading(true)
    try {
      const completed = await DefaultYMCA.eventService.getPastMemeContests(
        LIMIT,
        completedOffset.current
      )
      setCompletedEvents((prev) => [...prev, ...completed.data.data])
      setCompletedCount(completed.data.count)
      contest.getClosed({
        closed: completed.data.count
      })
      completedOffset.current = completedOffset.current + LIMIT
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getLiveEvents()
    getCompletedEvents()
    getUpcomingEvents()
  }, [])

  useEffect(() => {
    if (
      isVisible &&
      pathname.includes('closed') &&
      completedCount >= LIMIT &&
      completedOffset.current <= completedCount
    ) {
      getCompletedEvents()
    }
    if (
      isVisible &&
      pathname.includes('upcoming') &&
      upcomingCount >= LIMIT &&
      upcomingOffset.current <= upcomingCount
    ) {
      getUpcomingEvents()
    }
    if (
      isVisible &&
      pathname.includes('ongoing') &&
      ongoingCount >= LIMIT &&
      ongoingOffset.current <= ongoingCount
    ) {
      getLiveEvents()
    }
  }, [isVisible])

  return (
    <StandardPageTemplate>
      <Box className={classes.container}>
        <Box className={classes.banner}>
          <Box className={classes.bannerContainer}>
            <button onClick={() => navigate(-1)}>
              <Icon icon='arrow-left' fontSize='1.8rem' />
            </button>
          </Box>
        </Box>

        <Box className={classes.body}>
          <Box>
            <Typography
              className={classes.title}
              variant={'body1'}
              variantMapping={{ body1: 'h1' }}
            >
              Create memes, earn money
            </Typography>
            <Typography className={classes.subtitle}>
              Join our Meme Contests & Earn Rewards!
            </Typography>
            <Box className={classes.root}>
              <Box
                color='default'
                style={{ borderBottom: '1px solid #2F3D5A' }}
              >
                <Tabs
                  value={value}
                  onChange={(e, value) => {
                    navigate(`/contest/${value}`, { replace: true })
                  }}
                  variant='fullWidth'
                  classes={{ root: classes.tabs }}
                >
                  <Tab
                    value='ongoing'
                    label={`Ongoing (${contest.ongoing ?? 0})`}
                  />
                  <Tab
                    value='upcoming'
                    label={`Upcoming (${contest.upcoming ?? 0})`}
                  />

                  <Tab
                    value='closed'
                    label={`Closed (${contest.closed ?? 0})`}
                  />
                  {isAuthenticated && (
                    <Tab value='rewards' label='My Rewards' />
                  )}
                </Tabs>
              </Box>
            </Box>

            {isLoading ? (
              <Box style={{ marginTop: 34 }}>
                <Skeleton
                  style={{ height: 27, background: '#2B3853', width: 300 }}
                />

                <Box className={classes.cards} style={{}}>
                  <Box
                    className={classes.contestCard}
                    style={{ height: 350 }}
                  ></Box>
                  <Box
                    className={classes.contestCard}
                    style={{ height: 350 }}
                  ></Box>
                  <Box
                    className={classes.contestCard}
                    style={{ height: 350 }}
                  ></Box>
                </Box>
              </Box>
            ) : (
              <>
                {' '}
                {pathname.includes('ongoing') && (
                  <Box>
                    <MetaTags
                      show={window.location.href.includes(`/contest/`)}
                      title={'Meme Contests | YouMeme'}
                      description={
                        'Participate in YouMeme Meme Contest now! Submit a meme, have fun, and earn the rewards!'
                      }
                      url={`${process.env.REACT_APP_DEPLOYMENT_URL}/contest/ongoing`}
                      thumbnail={getContestPageThumbnail('ongoing')}
                    />
                    {ongoingCount === 0 ? (
                      <Typography
                        className={`${classes.feature} ${classes.emptyMessage}`}
                      >
                        No data available
                      </Typography>
                    ) : (
                      <Box className={classes.cards}>
                        {ongoingEvents.map((event) => (
                          <ContestCard
                            tab={'Enter Competition'}
                            type='ongoing'
                            event={event}
                            key={event.id}
                          />
                        ))}
                      </Box>
                    )}
                    <div ref={ref}></div>
                  </Box>
                )}
                {pathname.includes('upcoming') && (
                  <>
                    <MetaTags
                      show={window.location.href.includes(`/contest/`)}
                      title={'Meme Contests | YouMeme'}
                      description={
                        'Participate in YouMeme Meme Contest now! Submit a meme, have fun, and earn the rewards!'
                      }
                      url={`${process.env.REACT_APP_DEPLOYMENT_URL}/contest/upcoming`}
                      thumbnail={getContestPageThumbnail('upcoming')}
                    />
                    {upcomingCount === 0 ? (
                      <Typography
                        className={`${classes.feature} ${classes.emptyMessage}`}
                      >
                        No data available
                      </Typography>
                    ) : (
                      <Box className={classes.cards}>
                        {upcomingEvents.map((event) => (
                          <ContestCard
                            tab={'Enter Competition'}
                            type='upcoming'
                            event={event}
                            key={event.id}
                          />
                        ))}{' '}
                        <div ref={ref}></div>
                      </Box>
                    )}
                  </>
                )}
                {pathname.includes('closed') && (
                  <>
                    <MetaTags
                      show={window.location.href.includes(`/contest/`)}
                      title={'Meme Contests | YouMeme'}
                      description={
                        'Participate in YouMeme Meme Contest now! Submit a meme, have fun, and earn the rewards!'
                      }
                      url={`${process.env.REACT_APP_DEPLOYMENT_URL}/contest/closed`}
                      thumbnail={getContestPageThumbnail('closed')}
                    />
                    <>
                      {completedCount === 0 ? (
                        <Typography
                          className={`${classes.feature} ${classes.emptyMessage}`}
                        >
                          No data available
                        </Typography>
                      ) : (
                        <Box className={classes.cards}>
                          {completedEvents.map((event) => (
                            <ContestCard
                              tab={'Show Details'}
                              type='completed'
                              event={event}
                              key={event.id}
                            />
                          ))}{' '}
                          <div ref={ref}></div>
                        </Box>
                      )}
                    </>
                  </>
                )}
                {pathname.includes('rewards') && <ContestRewardList />}
              </>
            )}
          </Box>
          <Box
            style={{ width: '100%', display: 'grid', placeItems: 'center' }}
            marginTop={4}
          >
            <a
              href='https://t.me/Alex_andre_18'
              target='_blank'
              rel='noreferrer'
              className={classes.addText}
            >
              Add your project?<span> contact us.</span>
            </a>
          </Box>
          <Button
            className={classes.floatButton}
            href='https://t.me/Alex_andre_18'
            target='_blank'
            rel='noreferrer'
          >
            Add your project
          </Button>
        </Box>
      </Box>
    </StandardPageTemplate>
  )
}

export default ContestListPage;
