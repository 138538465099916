import React, { useState } from "react";
import { Box, IconButton, styled, Snackbar } from "@mui/material";

import Alert from "components/atoms/Alert";
import { Post as PostModel } from "ymca/models/post.model";
import { ReactionType as YMCAReactionType } from "ymca/models/reaction.model";
import { DefaultYMCA } from "ymca/ymca";
import { useAuth } from "context/auth/authContext";
import { usePublicationContext } from "context/PublicationContext";
import { ReactionEnum, ReactionTypes, ReactionType } from 'hooks/useReaction'
import { useLensService } from 'hooks/useLensServices/useLensServices'
import { capitalizeFirstLetter } from 'utils/formatString'
import Icon, { IconType } from 'components/atoms/Icons'
import Typography from 'components/atoms/Typography'
import { BREAKPOINT_MOBILE_SMALL } from 'theme/shared/breakpoint'
import usePopover from 'hooks/usePopover'
import AuthPopover from 'components/organisms/AuthPopover'

export interface ReactionProp {
  post: PostModel
}

const MemeReactionWrapper = styled(Box)(() => ({
  gridArea: 'publicationReaction',

  display: 'flex',
  gap: '.5rem',
  alignItems: 'center',

  [`@media (max-width: ${BREAKPOINT_MOBILE_SMALL}px)`]: {
    gap: '.3rem'
  }
}))

const ReactionButton = styled(IconButton)(({ theme }) => {
  return {
    '&.MuiButtonBase-root': {
      display: 'flex',
      alignItems: 'center',
      gap: '.3rem',
      padding: '.2rem .5rem',
      borderRadius: '6.2rem',
      transition: 'transform 0.1s ease-out',

      '&:hover': {
        transform: 'translateY(1px)',
        background: theme.palette.primary.main
      },
      '&:active': {
        boxShadow: 'none',
        transform: 'translateY(4px)',
        background: theme.palette.primary.main
      },

      [`@media (max-width: ${BREAKPOINT_MOBILE_SMALL}px)`]: {
        gap: '.2rem'
      }
    },
    '&.MuiIconButton-colorPrimary': {
      background: theme.palette.primary.main
    },
    '&.MuiIconButton-colorSecondary': {
      background: theme.palette.secondary.main
    }
  }
})

const ReactionIcon = styled(Icon)(({ theme }) => ({
  fontSize: '1.2rem',

  [`@media (max-width: ${BREAKPOINT_MOBILE_SMALL}px)`]: {
    fontSize: '1rem'
  }
}))

const ReactionTypography = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-body1': {
    fontSize: '1rem',
    [`@media (max-width: ${BREAKPOINT_MOBILE_SMALL}px)`]: {
      fontSize: '.875rem'
    }
  }
}))

const PublicationReaction = ({ post }: ReactionProp): JSX.Element => {
  const { isAuthenticated } = useAuth()
  const { isAuthenticatedOnLens, isLensProfileManagerEnabled } =
    useLensService()
  const [snackbarOpen, setSnackbar] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const postId = post.id
  const isLens = post.isLens ? true : false

  const { reaction } = usePublicationContext()

  const { onLike, onUnLike, reactionCountPayload, activeReaction } = reaction

  // make like API requests
  const handleOnLike = async (
    activeReaction: ReactionType,
    reaction: ReactionType
  ) => {
    if (!isLens) {
      const data = await DefaultYMCA.reactionService.createReaction(
        postId.toString(),
        capitalizeFirstLetter(reaction) as YMCAReactionType
      )
      if (!data) {
        onLike(activeReaction) // Like back previous reaction
        setError('Something went wrong, please try again later')
        setSnackbar(true) // show snackbar
      }
    } else {
      const isLensAuth = await isAuthenticatedOnLens()
      const isLensProfileManager = await isLensProfileManagerEnabled()
      if (!isLensAuth) {
        onLike(activeReaction)
        setError('Please login to lens to be able to react to this post')
        setSnackbar(true)
        return
      } else if (!isLensProfileManager) {
        onLike(activeReaction)
        setError(
          'Please enable lens profile manager to be able to react to this post'
        )
        setSnackbar(true)
        return
      } else {
        let lensPostId = postId.slice(1)
        const res = await DefaultYMCA.lensService.createReaction(lensPostId)
        if (!res) {
          onLike(activeReaction) // Like back previous reaction
          setError('Something went wrong, please try again later')
          setSnackbar(true) // show snackbar
        }
      }
    }
  }
  // make Unlike API requests
  const handleOnUnLike = async (activeReaction: ReactionType) => {
    if (activeReaction !== ReactionEnum.No_Reaction) {
      if (!isLens) {
        const data = await DefaultYMCA.reactionService.deleteReaction(postId)
        if (!data) {
          onLike(activeReaction) // Like back previous reaction
          setError('Something went wrong, please try again later')
          setSnackbar(true) // show snackbar
        }
      } else {
        const isLensAuth = await isAuthenticatedOnLens()
        const isLensProfileManager = await isLensProfileManagerEnabled()
        if (!isLensAuth) {
          onLike(activeReaction)
          setError('Please login to lens to be able to react to this post')
          setSnackbar(true)
          return
        } else if (!isLensProfileManager) {
          onLike(activeReaction)
          setError(
            'Please enable lens profile manager to be able to react to this post'
          )
          setSnackbar(true)
          return
        } else {
          let lensPostId = postId.slice(1)
          const res = await DefaultYMCA.lensService.deleteReaction(lensPostId)
          if (!res) {
            onLike(activeReaction) // Like back previous reaction
            setError('Something went wrong, please try again later')
            setSnackbar(true) // show snackbar
          }
        }
      }
    }
  }

  const handleSnackBarClose = () => {
    setSnackbar(false)
  }

  const {
    isOpen: isAuthPopverOpen,
    close: closeAuthPopover,
    open: openAuthPopover,
    anchorEl: authPopoverAnchorEl
  } = usePopover()

  return (
    <MemeReactionWrapper>
      {ReactionTypes.map((reaction, i) => {
        const iconName = isLens
          ? `reaction-like`
          : (`reaction-${reaction.toLowerCase()}` as IconType)
        if (isLens && reaction !== ReactionEnum.Like) return
        return (
          <ReactionButton
            key={reaction ?? i.toString()}
            color={activeReaction === reaction ? 'primary' : 'secondary'}
            onClick={(e) => {
              if (isAuthenticated === true) {
                if (activeReaction === reaction) {
                  onUnLike(activeReaction)
                  handleOnUnLike(activeReaction)
                } else {
                  onLike(reaction)
                  handleOnLike(activeReaction, reaction)
                }
              } else {
                openAuthPopover(e)
              }
            }}
          >
            <ReactionIcon icon={iconName} disabled />
            <ReactionTypography fontWeight={800}>
              {reactionCountPayload[reaction]}
            </ReactionTypography>
          </ReactionButton>
        )
      })}
      <AuthPopover
        id={post.id}
        onClose={closeAuthPopover}
        isOpen={isAuthPopverOpen}
        anchorEl={authPopoverAnchorEl}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      >
        <Alert severity='error'>{error}</Alert>
      </Snackbar>
    </MemeReactionWrapper>
  )
}

export default PublicationReaction;
