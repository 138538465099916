import "styles/auth.css";
import styled from "styled-components";
import { flexContainer } from "hooks/styles";
import React, { useEffect, useState } from "react";
import Loading from "components/loading/Loading";
import { useAuth } from "context/auth/authContext";
import { Link, Navigate, Outlet } from "react-router-dom";
import {
  AsideText,
  AuthenticationForm,
  HeaderTextContainer,
  YouMemeLogoText,
} from "components/auth/common";
import { getItemFromLocalStorage } from "services/localStorageService";
import { COMING_FROM_SESSION_STORAGE_KEY } from "constants/variables";
import { imagesWebp } from "constants/images";
import { redirectLinks } from "constants/redirectLinks";
import { Helmet } from "react-helmet";
import { BREAKPOINT_LAPTOP_LARGE, BREAKPOINT_LAPTOP } from "theme/shared/breakpoint";
import useWindowSize from "hooks/useWindowSize";
import { MetaTags } from '../shared';

const FooterText = styled(AsideText)`
  font-size: 0.625rem;
`;

const CopyRightText = styled(FooterText)`
  filter: brightness(50%);
  margin-top: 0;
  line-height: 1.3;
`;

const AuthPageContainer = styled.div`
  --border-background: 0, 0%, 100%;
  background: #151e31;
  font-size: clamp(85%, 1.5vw, 110%);
  display: flex;
  color: #ffffff;
  min-height: 100vh;
  letter-spacing: -0.3px;

  main {
    flex: 1 1 44.5%;
    justify-content: space-between;
    padding: 7.5vh 5.21vw 4vh;
    position: relative;
    ${flexContainer("column")}

    ${AuthenticationForm}, ${YouMemeLogoText}, ${HeaderTextContainer}, .confirmation-buttons {
      width: 100%;
    }
    .default-width,

    button {
      transition: color 180ms;
      position: relative;
      cursor: pointer;

      & .loading-spinner {
        --loader-size: 30px;
        opacity: 0;
      }
    }

    .footer-links {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      
      & h5 {
        font-size: 0.8125rem;
        line-height: 1.5rem;
        color: #8C9AB6
      }
      @media (max-width: ${BREAKPOINT_LAPTOP - 1}px) {
        display: none;
      }
    }

    ul {
      list-style: none;
      ${flexContainer()};
      padding: 0;
      margin: 0;
      gap: 12px;
      font-size: 0.8125em;

      li {
        line-height: 1.846;
      }
    }
  }

  figure {
    display: unset;
    flex-basis: 65%;
    margin: 0;
    background: url(${"/assets/banner/" + imagesWebp.login}) no-repeat;
    background-position: center;
    background-size: cover;
    @media (max-width: ${BREAKPOINT_LAPTOP - 1}px) {
      display: none;
    }
  }
`;

const AuthTemplate = ({ children }: { children: any }) => {
  const from: any = getItemFromLocalStorage(COMING_FROM_SESSION_STORAGE_KEY);

  const { isAuthenticated, authService } = useAuth();
  const [isVerifying, setVerifying] = useState<boolean>(false);
  const windowWidth = useWindowSize().width

  useEffect(() => {
    const subscription = authService.subscribe((state) => {
      setVerifying(state.value === "verifying");
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [authService]);

  if (isAuthenticated === true)
    return <Navigate to={from !== "" ? from : "/"} />;
  if (isVerifying) return <Loading />;

  return (
    <AuthPageContainer>
      <MetaTags
        show={true}
        title="Join YouMeme and get 100 YouMeme diamonds 💎"
        description="Hey, check out YouMeme! That's a cool social network for sharing memes. Get 100 free YouMeme diamonds by signing up via referral links"
        thumbnail="https://memes-optimized-cdn.youmeme.com/a34327ad-c2ed-4dea-ac81-f735f78534f5.png.transform.1000.1000.jpeg"
        url={window.location.href}
      />
      <main className='flex-center'>
        <Outlet />
        {children}
        <div className='footer-links'>
          <ul className='other-links'>
            <li>
              <Link to={`/${redirectLinks.contact}`}>
                <FooterText>Contact</FooterText>
              </Link>
            </li>
            <li>
              <Link to={`/${redirectLinks.news}`}>
                <FooterText>News</FooterText>
              </Link>
            </li>
            <li>
              <Link to={`/${redirectLinks.faq}`}>
                <FooterText>FAQ</FooterText>
              </Link>
            </li>
            <li>
              <Link to={`/${redirectLinks.agreement}`}>
                <FooterText>{(windowWidth < BREAKPOINT_LAPTOP_LARGE) ? 'Terms' : 'Terms of service'}</FooterText>
              </Link>
            </li>
            <li>
              <Link to={`/${redirectLinks.privacy}`}>
                <FooterText>{(windowWidth < BREAKPOINT_LAPTOP_LARGE) ? 'Privacy' : 'Privacy Policy'}</FooterText>
              </Link>
            </li>
          </ul>

          <CopyRightText className='copyright-text'>
            © {new Date().getFullYear()} YouMeme, Inc. All rights reserved
          </CopyRightText>
        </div>
      </main>
      <figure />
    </AuthPageContainer>
  );
};

export default AuthTemplate;
