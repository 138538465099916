import { makeStyles } from "@material-ui/core/styles";

const useCommunitiesWrapperStyles = makeStyles({
  wrapper: {
    padding: "0 1rem",
    display: "flex",
    gap: ".5rem",
    flexDirection: "column",
  },
  search: {
    "&.MuiInputBase-root": {
      gap: ".5rem",
    },
    borderRadius: "6.25rem",
    border: "1.5px solid #1C263B",
    background: "#28344D",
    boxShadow: "0px 0px 0px 1px #1C263B",
    height: "2rem",
    padding: ".5rem",

    color: "#8A98B4",
    fontFamily: "Inter",
    fontSize: "0.9375rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "100%",
    letterSpacing: "-0.01875rem",
  },
  outletContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  pageTitle: {
    color: "#E9F0F9",
    fontFamily: "Inter",
    fontSize: "1.25rem",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "normal",
    letterSpacing: "-0.0625rem",
  },
  tabContainer: {
    "& .MuiTabs-indicator": {
      height: "0",
    },
  },
  tabItem: {
    "&.MuiButtonBase-root": {
      color: "#8A98B4",
      textAlign: "center",
      fontFamily: "Inter",
      fontSize: "0.9375rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "1.5rem",
      letterSpacing: "-0.01875rem",
      "&.Mui-selected": {
        borderRadius: "6.25rem",
        background: "#625FFF",
        color: "#FFF",
        borderBottomStyle: "transparent",
      },
    },
  },
  emptyCommunityTitle: {
    color: "#E9F0F9",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "1.625rem",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "100%",
    letterSpacing: "-0.0625rem",
  },
  emptyCommunityDescription: {
    color: "#8A98B4",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "1.1875rem",
    letterSpacing: "-0.03125rem",
  },
});

export default useCommunitiesWrapperStyles;
