import { headerContentHeight } from "constants/variables";
import {
  BREAKPOINT_LAPTOP,
  BREAKPOINT_LAPTOP_LARGE,
  BREAKPOINT_TABLET,
} from "theme/shared/breakpoint";

const getTopSpacing = (haveEthAlert: boolean, breakpoint?: number): string => {
  const haveSurveyBanner = process.env?.REACT_APP_SHOW_SURVEY_BANNER === "true";
  let top = 0;
  // if (breakpoint) {
  //   if (breakpoint === BREAKPOINT_LAPTOP_LARGE) {
  //     const currentTop = headerContentHeight;
  //     top = haveEthAlert ? currentTop * 2 : currentTop;
  //     if (haveSurveyBanner) {
  //       top += headerContentHeight;
  //     }
  //   }
  //   if (breakpoint === BREAKPOINT_LAPTOP) {
  //     top = haveEthAlert ? 6.2 : 3.4;
  //     if (haveSurveyBanner) {
  //       top += 4;
  //     }
  //   }
  //   if (breakpoint === BREAKPOINT_TABLET) {
  //     top = haveEthAlert ? 7.4 : 3.4;
  //     if (haveSurveyBanner) {
  //       top += 4;
  //     }
  //   }
  // } else {
  //   const currentTop = headerContentHeight;
  //   top = haveEthAlert ? currentTop * 2 : currentTop;
  //   if (haveSurveyBanner) {
  //     top += headerContentHeight;
  //   }
  // }

  const currentTop = headerContentHeight;
  top = haveEthAlert ? currentTop * 2 : currentTop;
  if (haveSurveyBanner) {
    top += headerContentHeight;
  }

  return `${top}rem`;
};

export default getTopSpacing;
