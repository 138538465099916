import { useState } from 'react'
import { Snackbar, Box } from '@mui/material'
import { Button, Typography } from '@material-ui/core'
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined'
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined'
import CircularProgress from '@material-ui/core/CircularProgress'
import Linkify from 'react-linkify'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Alert from 'components/atoms/Alert'
import { DEFAULT_AVATAR_IMAGE } from 'ymca/services/image.service'
import { UseCommentResult } from 'hooks/useComments/useComments'
import { Post as PostModel } from 'ymca/models/post.model'
import useCommentListStyles from './styles'
import getImageResolutions, {
  getImageUrlSourceType
} from 'utils/getImageResolutions'
import Avatar from 'components/molecules/Avatar'
import useInfiniteScroll from 'hooks/useInfiniteScroll/useInfiniteScroll'

interface UseCommentListProps extends Omit<UseCommentResult, 'addComment'> {}
export interface PublicationCommentProp {
  post: PostModel
  contestModal?: boolean
  useCommentListProps: UseCommentListProps
}

const PublicationCommentList = ({
  post,
  contestModal,
  useCommentListProps
}: PublicationCommentProp): JSX.Element => {
  const classes = useCommentListStyles()

  const [error, setError] = useState<string | null>(null)

  const { renderComments, loading, hasMore, loadMore, loadingMore } =
    useCommentListProps

  const [infiniteRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: loadMore,
    rootMargin: '10px'
  })

  if (contestModal) return <></>

  return (
    <>
      <Snackbar
        open={error !== null}
        autoHideDuration={4000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity='error' variant='filled' sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      <Box className={classes.wrapper}>
        <Box className={classes.commentListContainer}>
          <Box className={classes.commentRow}>
            {renderComments?.map((item) => {
              const { x2, x3, x4, fallback } = getImageResolutions(
                item?.user?.avatar
              )

              return (
                <Box key={item.id} className={classes.comment}>
                  <Link to={`/profile/${item.user.username}`}>
                    <Avatar
                      alt='user'
                      src={fallback ?? DEFAULT_AVATAR_IMAGE}
                      pictureSources={[
                        {
                          srcSet: `${x4} 1x, ${x3} 2x, ${x2} 3x`,
                          type: getImageUrlSourceType(x4)
                        }
                      ]}
                      className={classes.userIcon}
                    />
                  </Link>
                  <Box
                    sx={{ overflow: 'hidden' }}
                    className={classes.commentBox}
                  >
                    <Typography className={classes.commentUser}>
                      {item?.user?.username}{' '}
                      <span>{moment(new Date(item.createdAt)).fromNow()}</span>
                    </Typography>
                    <Linkify
                      componentDecorator={(decoratedHref) => (
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href={decoratedHref}
                          key={item.id}
                        >
                          {decoratedHref}
                        </a>
                      )}
                    >
                      <Typography className={classes.commentMain}>
                        {item?.comment?.charAt(0).toUpperCase() +
                          item?.comment?.slice(1)}
                      </Typography>
                    </Linkify>
                  </Box>
                </Box>
              )
            })}
          </Box>
          {!loading && (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection='column'
            >
              {hasMore && !loadingMore && <Box ref={infiniteRef} />}
              {loadingMore && (
                <Box display='flex' justifyContent='center'>
                  <CircularProgress size={15} />
                </Box>
              )}
            </Box>
          )}
          {loading ? (
            <Box display='flex' justifyContent='center'>
              <CircularProgress size={15} />
            </Box>
          ) : null}
        </Box>
      </Box>
    </>
  )
}

export default PublicationCommentList
