import { PostContentType } from "ymca/models/post.model";

export const checkIsPostContentPlayable = (contentType: PostContentType) => {
  if(contentType === "video" || contentType === "gif"){
    return true
  }
  return false
}

export const checkIsPostContentCanPlayInHTMLVideo = (contentType: PostContentType) => {
  if(contentType === "video"){
    return true
  }
  return false
}