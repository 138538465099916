import Alert from '@material-ui/lab/Alert'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  alertBox: {
    zIndex: 99,
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    bottom: 50,
    width:"100%",
    left: '50%',
    transform: 'translate(-50%, 0)'
  },
  '@media (max-width: 550px)': {
    alertBox: {
      bottom: 80
    }
  }
})

export interface AlertTextProp{
  show: boolean
  text: string
  success?: boolean
}

const AlertText = ({ show, text, success = true }: AlertTextProp): JSX.Element => {
  const classes = useStyles()
  return (
    <Box className={classes.alertBox} style={{ display: show ? 'flex' : 'none' }}>
      <Alert variant='filled' severity={success ? 'success' : 'error'}>
        {text}
      </Alert>
    </Box>
  )
}
export default AlertText
