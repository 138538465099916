import React, { createContext, useEffect, useState } from "react";
import { useLensService } from 'hooks/useLensServices/useLensServices'
import { useLocation } from "react-router-dom";
export type Source = "lens" | "youmeme" | undefined;

export interface FeedContextInterface {
  source: Source;
  setSource: (source: Source) => void;
  disableToggle: boolean;
}

const FeedContext = createContext<FeedContextInterface>({
  source: "youmeme",
  setSource: () => {},
  disableToggle: false,
});

const FeedProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const { isAuthenticatedOnLens } = useLensService();
  const [source, setSource] = useState<Source>();
  const [disableToggle, setDisableToggle] = useState<boolean>(false);
  useEffect(() => {
    checkLensAuthentication();
  }, []);

  const checkLensAuthentication = async () => {
    const isLens = await isAuthenticatedOnLens();
    if (isLens) {
      setSource("lens");
    } else {
      setSource("youmeme");
    }
  };

  useEffect(() => {
    // if location pathname starts with communities or contest, reset to youmeme
    const path = location?.pathname?.split("/")?.[1];
    if (path === "contest" || path === "communities") {
      setSource("youmeme");
      setDisableToggle(true);
    } else {
      if (disableToggle) {
        setDisableToggle(false);
      }
    }
  }, [location?.pathname]);

  return (
    <FeedContext.Provider value={{ source, setSource, disableToggle }}>
      {children}
    </FeedContext.Provider>
  );
};

export const useFeedContext = () => React.useContext(FeedContext);

export default FeedProvider;