import { useQuery } from '@tanstack/react-query'
import { useRef, useState } from 'react'
import { GetCommunitiesDTO } from 'ymca/dtos/community.dto'
import { GetEventsDto } from 'ymca/dtos/event.dto'
import {
  GetEventTopEntriesDTO,
  GetPostsByEventDTO,
  GetUserPostsByEventDTO
} from 'ymca/dtos/post.dto'
import { DefaultYMCA } from 'ymca/ymca'

interface UseGetContestRewardsProps {
  userId: string
  enabled?: boolean
}

export const useGetContestRewards = ({
  userId,
  enabled = true
}: UseGetContestRewardsProps) => {
  const generatorRef = useRef<any>()
  const previousData = useRef<any[]>([])
  const hasNextPageRef = useRef<any>(true)
  const totalItemReturnRef = useRef<any>(0)
  const [page, setPage] = useState(0)
  // const [search, setSearch] = useState<string>('')

  // const resetPage = () => {
  //   generatorRef.current = null
  //   previousData.current = []
  //   hasNextPageRef.current = true
  //   totalItemReturnRef.current = 0
  //   // setPage(0);
  // }

  const getData = async (props: any) => {
    let generator = undefined
    if (!generatorRef.current) {
      let dto = new GetEventsDto()
      dto.winnerId = userId

      generator = await DefaultYMCA.eventService.getEventsWonByUserId(dto)

      generatorRef.current = await generator
    } else {
      // console.log('generatorRef.current', generatorRef.current)
    }

    const data = await generatorRef.current.next()
    const { value, done } = data
    let newData = value?.data
    if (!newData || !Array.isArray(newData)) {
      newData = []
    }
    totalItemReturnRef.current += newData.length ?? 0
    if (value?.count > totalItemReturnRef.current) {
      hasNextPageRef.current = true
    } else {
      hasNextPageRef.current = false
    }
    if (previousData.current.length > 0) {
      previousData.current = [...previousData.current, ...newData]
    } else {
      previousData.current = newData
    }

    return {
      data: previousData.current,
      hasMore: hasNextPageRef.current
    }
  }

  const query = useQuery({
    queryKey: ['contests', { winner: userId }, page],
    queryFn: getData,
    keepPreviousData: true,
    enabled
  })

  return {
    ...query,
    fetchNextPage: () => setPage((prev) => ++prev)
    // searchRefetch: (value: string) => {
    //   resetPage()
    //   setSearch(value)
    // }
  }
}

interface UseGetContestPostsProps {
  eventId: string
  enabled?: boolean
}
export const useGetContestSubmissions = ({
  eventId,
  enabled = true
}: UseGetContestPostsProps) => {
  const generatorRef = useRef<any>()
  const previousData = useRef<any[]>([])
  const hasNextPageRef = useRef<any>(true)
  const totalItemReturnRef = useRef<any>(0)
  const [page, setPage] = useState(0)

  const resetPage = () => {
    generatorRef.current = null
    previousData.current = []
    hasNextPageRef.current = true
    totalItemReturnRef.current = 0
  }

  const getData = async (props: any) => {
    let generator = undefined
    if (!generatorRef.current) {
      let dto = new GetPostsByEventDTO()
      dto.eventId = eventId

      generator = await DefaultYMCA.postService.getPostsByEvent(dto)

      generatorRef.current = await generator
    } else {
      // console.log('generatorRef.current', generatorRef.current)
    }

    const data = await generatorRef.current.next()
    const { value, done } = data
    let newData = value?.data
    if (!newData || !Array.isArray(newData)) {
      newData = []
    }
    totalItemReturnRef.current += newData.length ?? 0
    if (value?.count > totalItemReturnRef.current) {
      hasNextPageRef.current = true
    } else {
      hasNextPageRef.current = false
    }
    if (previousData.current.length > 0) {
      previousData.current = [...previousData.current, ...newData]
    } else {
      previousData.current = newData
    }

    return {
      data: previousData.current,
      hasMore: hasNextPageRef.current
    }
  }

  const query = useQuery({
    queryKey: ['contests', { id: eventId }, 'submissions', page],
    queryFn: getData,
    keepPreviousData: true,
    enabled
  })

  return {
    ...query,
    fetchNextPage: () => setPage((prev) => ++prev),
    refetch: () => {
      resetPage()
      query.refetch()
    }
  }
}

interface UseGetContestPostsByUserProps {
  userId: string
  eventId: string
  enabled?: boolean
}
export const useGetContestMySubmissions = ({
  userId,
  eventId,
  enabled = true
}: UseGetContestPostsByUserProps) => {
  const generatorRef = useRef<any>()
  const previousData = useRef<any[]>([])
  const hasNextPageRef = useRef<any>(true)
  const totalItemReturnRef = useRef<any>(0)
  const [page, setPage] = useState(0)

  const resetPage = () => {
    generatorRef.current = null
    previousData.current = []
    hasNextPageRef.current = true
    totalItemReturnRef.current = 0
  }

  const getData = async (props: any) => {
    let generator = undefined
    if (!generatorRef.current) {
      let dto = new GetUserPostsByEventDTO()
      dto.eventId = eventId
      dto.userId = userId

      generator = await DefaultYMCA.postService.getUserPostsByEvent(dto)

      generatorRef.current = await generator
    } else {
      // console.log('generatorRef.current', generatorRef.current)
    }

    const data = await generatorRef.current.next()
    const { value, done } = data
    let newData = value?.data
    if (!newData || !Array.isArray(newData)) {
      newData = []
    }
    totalItemReturnRef.current += newData.length ?? 0
    if (value?.count > totalItemReturnRef.current) {
      hasNextPageRef.current = true
    } else {
      hasNextPageRef.current = false
    }
    if (previousData.current.length > 0) {
      previousData.current = [...previousData.current, ...newData]
    } else {
      previousData.current = newData
    }

    return {
      data: previousData.current,
      hasMore: hasNextPageRef.current
    }
  }

  const query = useQuery({
    queryKey: ['contests', { id: eventId, userId }, 'my-submissions', page],
    queryFn: getData,
    keepPreviousData: true,
    enabled
  })

  return {
    ...query,
    fetchNextPage: () => setPage((prev) => ++prev),
    refetch: () => {
      resetPage()
      query.refetch()
    }
  }
}

interface UseGetContestTopEntriesProps {
  postIds: string[]
  enabled?: boolean
}
export const useGetContestTopEntries = ({
  postIds,
  enabled = true
}: UseGetContestTopEntriesProps) => {
  const generatorRef = useRef<any>()
  const previousData = useRef<any[]>([])
  const hasNextPageRef = useRef<any>(true)
  const totalItemReturnRef = useRef<any>(0)
  const [page, setPage] = useState(0)

  const getData = async (props: any) => {
    let generator = undefined
    if (!generatorRef.current) {
      let dto = new GetEventTopEntriesDTO()
      dto.postIds = postIds

      generator = await DefaultYMCA.postService.getEventTopEntries(dto)

      generatorRef.current = await generator
    } else {
      // console.log('generatorRef.current', generatorRef.current)
    }

    const data = await generatorRef.current.next()
    const { value, done } = data
    let newData = value?.data
    if (!newData || !Array.isArray(newData)) {
      newData = []
    }
    totalItemReturnRef.current += newData.length ?? 0
    if (value?.count > totalItemReturnRef.current) {
      hasNextPageRef.current = true
    } else {
      hasNextPageRef.current = false
    }
    if (previousData.current.length > 0) {
      previousData.current = [...previousData.current, ...newData]
    } else {
      previousData.current = newData
    }

    return {
      data: previousData.current,
      hasMore: hasNextPageRef.current
    }
  }

  const query = useQuery({
    queryKey: ['contests', { postIds }, 'top-entry', page],
    queryFn: getData,
    keepPreviousData: true,
    enabled
  })

  return {
    ...query,
    fetchNextPage: () => setPage((prev) => ++prev)
  }
}

