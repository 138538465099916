import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'

const useStyles = makeStyles({
  loader: {
    border: '16px solid #f3f3f3',
    borderTop: '16px solid black',
    borderRadius: '50%',
    width: 120,
    height: 120,
    animation: '$spin 2s linear infinite',
    margin: 'auto',
    marginTop:"1rem",
  },
  loaderContainer: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
})

interface SpinnerProps {
  width: number
  height: number
}

export const Spinner = ({ width, height }: SpinnerProps): JSX.Element | null => {
  const classes = useStyles()

  const [visible, setVisible] = useState<boolean>(true)

  useEffect(() => {
    let isMounted = true;
    setTimeout(() => {
      if(isMounted){
        setVisible(false)
      }
    }, 20000)
    return () => {
      isMounted = false;
    } 
  }, [])

  if (!visible) return null
  return <Box className={classes.loader} style={{ width, height }} />
}

const LoadingSpinner = ({ width, height }: SpinnerProps): JSX.Element => {
  const classes = useStyles()

  return (
    <Box className={classes.loaderContainer} style={{ width, height }}>
      <Box className={classes.loader} />
    </Box>
  )
}

export default LoadingSpinner
