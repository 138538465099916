export const MetaMaskBadge=()=>(
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="100" height="100" rx="50" fill="#EAE0D7" fill-opacity="0.05"/>
<path d="M77.8901 20.8335L53.7188 38.861L58.1886 28.225L77.8901 20.8335Z" fill="#E2761B"/>
<path d="M22.0898 20.8335L46.0668 39.0318L41.8156 28.225L22.0898 20.8335ZM69.1936 62.6212L62.756 72.5253L76.53 76.3309L80.4897 62.8407L69.1936 62.6212ZM19.5391 62.8407L23.4745 76.3309L37.2485 72.5253L30.8109 62.6212L19.5391 62.8407Z" fill="#E4761B"/>
<path d="M36.4711 45.8867L32.6328 51.717L46.3097 52.3269L45.8238 37.5682L36.4711 45.8867ZM63.509 45.8867L54.0348 37.3975L53.719 52.3269L67.3715 51.717L63.509 45.8867ZM37.2484 72.5255L45.4594 68.5004L38.3659 62.9385L37.2484 72.5255ZM54.5206 68.5004L62.7559 72.5255L61.6141 62.9385L54.5206 68.5004Z" fill="#E4761B"/>
<path d="M62.7535 72.5256L54.5183 68.5005L55.1742 73.8917L55.1013 76.1604L62.7535 72.5256ZM37.2461 72.5256L44.8983 76.1604L44.8497 73.8917L45.4571 68.5005L37.2461 72.5256Z" fill="#D7C1B3"/>
<path d="M45.0224 59.377L38.1719 57.3522L43.0061 55.1323L45.0224 59.377ZM54.9582 59.377L56.9745 55.1323L61.8331 57.3522L54.9582 59.377Z" fill="#233447"/>
<path d="M37.2462 72.5253L38.4122 62.6211L30.8086 62.8407L37.2462 72.5253ZM61.5876 62.6211L62.7536 72.5253L69.1912 62.8407L61.5876 62.6211ZM67.3693 51.7168L53.7167 52.3267L54.9799 59.3767L56.9962 55.132L61.8548 57.3519L67.3693 51.7168ZM38.1693 57.3519L43.0279 55.132L45.0199 59.3767L46.3074 52.3267L32.6306 51.7168L38.1693 57.3519Z" fill="#CD6116"/>
<path d="M32.6328 51.7168L38.3659 62.9382L38.1716 57.3519L32.6328 51.7168ZM61.8571 57.3519L61.6141 62.9382L67.3715 51.7168L61.8571 57.3519ZM46.3097 52.3267L45.0221 59.3767L46.6255 67.6952L46.9899 56.7421L46.3097 52.3267ZM53.719 52.3267L53.0631 56.7177L53.3546 67.6952L54.9822 59.3767L53.719 52.3267Z" fill="#E4751F"/>
<path d="M54.9825 59.3768L53.3549 67.6953L54.5209 68.5003L61.6144 62.9384L61.8574 57.3521L54.9825 59.3768ZM38.1719 57.3521L38.3662 62.9384L45.4597 68.5003L46.6258 67.6953L45.0224 59.3768L38.1719 57.3521Z" fill="#F6851B"/>
<path d="M55.1013 76.1602L55.1742 73.8915L54.5669 73.3548H45.4085L44.8497 73.8915L44.8983 76.1602L37.2461 72.5254L39.9183 74.7209L45.3356 78.502H54.6397L60.0813 74.7209L62.7535 72.5254L55.1013 76.1602Z" fill="#C0AD9E"/>
<path d="M54.5201 68.5003L53.354 67.6953H46.6249L45.4589 68.5003L44.8516 73.8915L45.4103 73.3548H54.5687L55.176 73.8915L54.5201 68.5003Z" fill="#161616"/>
<path d="M78.9091 40.0319L80.974 30.079L77.8888 20.8335L54.5191 38.2511L63.5075 45.8866L76.2126 49.619L79.0306 46.3257L77.8159 45.4475L79.7594 43.6667L78.2532 42.4958L80.1966 41.0077L78.9091 40.0319ZM19.0273 30.079L21.0922 40.0319L19.7804 41.0077L21.7238 42.4958L20.242 43.6667L22.1854 45.4475L20.9708 46.3257L23.7644 49.619L36.4696 45.8866L45.4579 38.2511L22.0882 20.8335L19.0273 30.079Z" fill="#763D16"/>
<path d="M76.2142 49.6185L63.5091 45.8862L67.3716 51.7165L61.6142 62.9379L69.1936 62.8403H80.4897L76.2142 49.6185ZM36.4712 45.8862L23.766 49.6185L19.5391 62.8403H30.8109L38.366 62.9379L32.6329 51.7165L36.4712 45.8862ZM53.7191 52.3263L54.5207 38.2507L58.2132 28.2246H41.8156L45.4595 38.2507L46.3097 52.3263L46.6013 56.7661L46.6255 67.6948H53.3547L53.4032 56.7661L53.7191 52.3263Z" fill="#F6851B"/>
</svg>

)