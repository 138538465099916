import { makeStyles } from '@material-ui/core/styles'
import colors from 'theme/dark/colors'

const usePublicationMainStyles = makeStyles((theme: any) => ({
  dialogRightSideContent: {
    background: colors.backgroundPrimary,
    padding: '2rem 0',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.5)',
    display: 'grid',
    gridTemplateAreas: `
      "publicationDetail"
      "publicationCommentList"
      "publicationAddComment"
    `,
    gridTemplateRows: 'max-content minmax(100px, 900px) max-content',
    gridTemplateColumns: '100%',
    gap: '.5rem',
    height: '100%'
  },
  publicationDetailWrapper: {
    padding: '0 .5rem',
    gridArea: 'publicationDetail',
    display: 'grid',
    gridTemplateAreas: `
      "publcationHeader"
      "publicationContentTitle"
      "publicationContent"
      "publicationContentDescription"
    `,
    gridAutoRows: 'max-content',
    gridTemplateColumns: '1fr',
    gap: '.5rem'
  },
  publicationCommentList: {
    gridArea: 'publicationCommentList',
    borderTop: `1px solid #354361`,
    paddingTop: '1rem',
    padding: '0 1rem'
  },
  publicationAddComment: {
    gridArea: 'publicationAddComment',
    borderTop: `1px solid #354361`,
    paddingTop: '1rem',
    padding: '0 1rem'
  },
  dialogLeftSideContent: {
    display: 'flex',
    justifyContent: 'center',
    backgroundSize: 'cover',
    '& section': {
      backdropFilter: 'blur(10px)',
      // backgroundColor: 'rgb(0,0,0, 0.6)',
      width: '100%',
      height: '100%',
      padding: '2rem'
    }
  }
}))

export default usePublicationMainStyles
