import { BaseService } from './base.service'
import { YouMemeJWT } from 'ymca/models/YoumemeJWT'
import { parseJSON } from 'ymca/ymcaHelpers'
import { User, USERNAME_REGEX } from 'ymca/models/user.model'
import {
  getFileFromBase64Embed
} from './image.service'
import { ImagePurpose, MimeType } from 'ymca/models/image.model'
import {
  DeleteProfileImageDto,
  DeleteProfileImagePurpose,
  ProfileImageUploadResponseDto,
  UpdateUserDto,
  UploadProfileImageDto
} from 'ymca/dtos/user.dto'
import { CompleteRegistrationDto, RefreshTokenResponse, TokenSource, CompleteLoginQueryDto, LocalStorageUserData, adaptLoginCredentialsForStorage } from 'ymca/dtos/auth.dto'
import { APIResponse } from 'ymca/jsonFetcher'

export const SELF_DATA_KEY = 'user' // this is compatible w/ existing LocalStorage use

// FIXME instead of the API, use the stored JWT token and
// depend on AuthService when it is implemented
export class SelfService extends BaseService {
  // Note: This service is also a PERFECT place to store singletons
  // relating to the ucrrently logged in user. For example, the
  // user's posts, bookmarks, etc. can be stored here.

  protected parsedAuthToken = new YouMemeJWT()
  protected parsedSessionToken = new YouMemeJWT()
  protected authTokenString = ''
  protected sessionTokenString = ''

  public async setLensProfileId (lensBearerToken: string, lensProfileId: string): Promise<void> {
    const res = await this.common.jsonFetcher.fetchJSON<User>('POST', '/api/self/lens-profile-id', undefined, { lensBearerToken, lensProfileId })
    if (!res.isSuccess) {
      throw new Error(`Failed to set lens profile id - ${res.status.toString()} - ${JSON.stringify(res.data)}`)
    }
  }

  public async updateSelf (changes: UpdateUserDto): Promise<User> {
    const res = await this.common.jsonFetcher.fetchJSON<User>('PATCH', '/api/self', undefined, changes)
    if (!res.isSuccess) {
      throw new Error(`Failed to update self - ${res.status.toString()} - ${JSON.stringify(res.data)}`)
    }
    const user = res.data
    this.setSelfInLocalStorage(user)
    return user
  }

  public async uploadProfileImage (
    purpose: ImagePurpose, file: File
  ): Promise<ProfileImageUploadResponseDto> {
    const dto = new UploadProfileImageDto()
    dto.purpose = purpose
    const res = await this.common.jsonFetcher.fetchJSON<ProfileImageUploadResponseDto>(
      'PUT', '/api/user/profile-image',
      dto, file, file.type as MimeType
    )
    // in case of success, update user in self cache
    if (res.isSuccess) {
      await this.getSelfFromAPI()
    }
    return res.data
  }

  public async uploadProfileImageFromBase64 (
    purpose: ImagePurpose, base64: string
  ): Promise<ProfileImageUploadResponseDto> {
    const file = getFileFromBase64Embed(base64)
    const res = this.uploadProfileImage(purpose, file)
    return await res
  }

  public async deleteProfileImage (purpose: DeleteProfileImagePurpose): Promise<boolean> {
    const dto = new DeleteProfileImageDto()
    dto.purpose = purpose
    const res = await this.common.jsonFetcher.fetchJSON(
      'DELETE', '/api/user/profile-image', dto
    )
    // in case of success, update user in self cache
    if (res.isSuccess) {
      await this.getSelfFromAPI()
    }
    return res.isSuccess
  }

  // getSelfFromAPI returns profile data scoped to the current user
  protected async getSelfFromAPI (): Promise<User | null> {
    const selfId = this.getId()
    if (selfId === '') return null
    const res = await this.common.jsonFetcher.fetchJSON<{ user: User }>('GET', '/api/self')
    if (!res.isSuccess) {
      return null
    } else {
      const user = res.data.user
      this.setSelfInLocalStorage(user)
      return user
    }
  }

  // Meant to be called when creating a post
  public async incrementSelfPostCounter (isCompetition: boolean): Promise<void> {
    const self = await this.getSelf()
    if (self === null) return
    self.nPosts += 1
    if (isCompetition) {
      self.nCompetitionPosts += 1
    }
    this.setSelfInLocalStorage(self)
  }

  // Meant to be called when deleting a post
  public async decrementSelfPostCounter (isCompetition: boolean): Promise<void> {
    const self = await this.getSelf()
    if (self === null) return
    self.nPosts -= 1
    if (isCompetition) {
      self.nCompetitionPosts -= 1
    }
    this.setSelfInLocalStorage(self)
  }

  protected getDataFromLocalStorage (): LocalStorageUserData | null {
    const storedData = this.common.localStorage.getItem(SELF_DATA_KEY)
    const payload = parseJSON(storedData) as LocalStorageUserData | null
    return payload
  }

  public getSelfFromLocalStorage (): User | null {
    const payload = this.getDataFromLocalStorage()
    if (payload === null || payload === undefined) {
      return null
    }
    return payload.userData
  }

  protected getAccessTokenFromLocalStorage (): string {
    const payload = this.getDataFromLocalStorage()
    if (payload === null || payload === undefined) {
      return ''
    }
    return payload.token
  }

  protected getSessionTokenFromLocalStorage (): string {
    const payload = this.getDataFromLocalStorage()
    if (payload === null || payload === undefined) {
      return ''
    }
    return payload.sessionToken
  }

  protected setSelfInLocalStorage (user: User): void {
    const oldPayload = this.getDataFromLocalStorage()
    if (oldPayload === null || oldPayload === undefined) {
      return
    }
    oldPayload.userData = user
    const newPayload = JSON.stringify(oldPayload)
    this.common.localStorage.setItem(SELF_DATA_KEY, newPayload)
    this.common.pubsub.publish(SELF_DATA_KEY, user) // publish to self
    this.common.pubsub.publish(user.id, user) // also publish to user's own id
  }

  public async getSelf (skipCache = false): Promise<User | null> {
    const cachedUser = this.getSelfFromLocalStorage()
    if (!skipCache && cachedUser !== null && cachedUser !== undefined) {
      return cachedUser
    }
    const user = await this.getSelfFromAPI()
    return user
  }

  protected setAccessTokenFromStorage (): void {
    if (this.authTokenString !== '') return
    const token = this.getAccessTokenFromLocalStorage()
    if (token === '') return
    this.authTokenString = token
    const jwt = YouMemeJWT.fromTokenString(token)
    this.parsedAuthToken = jwt
  }

  protected setSessionTokenFromStorage (): void {
    if (this.sessionTokenString !== '') return
    const token = this.getSessionTokenFromLocalStorage()
    if (token === '') return
    this.sessionTokenString = token
    const jwt = YouMemeJWT.fromTokenString(token)
    this.parsedSessionToken = jwt
  }

  protected setJWTFromStorage (): void {
    this.setAccessTokenFromStorage()
    this.setSessionTokenFromStorage()
  }

  public getId (): string {
    this.setJWTFromStorage()
    return this.parsedAuthToken.id
  }

  public getRole (): string {
    this.setJWTFromStorage()
    return this.parsedAuthToken.role
  }

  public getIsModerator (_categoryId: string = ''): boolean {
    const role = this.getRole()
    if (role === 'admin' || role === 'superadmin') return true
    if (role === 'moderator') return true
    return false
  }

  public getIsAdmin (): boolean {
    const role = this.getRole()
    if (role === 'admin' || role === 'superadmin') return true
    return false
  }

  protected async clearSession (): Promise<void> {
    if (globalThis.sessionStorage !== undefined) {
      globalThis.sessionStorage.clear()
    }
    if (globalThis.localStorage !== undefined) {
      globalThis.localStorage.clear()
    }
    this.common.localStorage.clear()
    await this.common.kvStore.clear()
    if (globalThis.location !== undefined) {
      globalThis.location.reload()
    }
  }

  public async getBearerToken (): Promise<string> {
    this.setJWTFromStorage()
    if (this.parsedSessionToken.iat < this.common.config.oldestAllowedSessionStartTime) {
      await this.clearSession()
      return ''
    }
    if (this.parsedAuthToken.expiresIn() < 60) {
      await this.refreshAccessToken()
    }
    return this.authTokenString
  }

  public async sendLoginOrRegistrationEmail (email: string, referrer?: string): Promise<APIResponse<void>> {
    const payload = { email, ...(referrer !== undefined && referrer !== null && { referrer }) }
    const res = await this.common.jsonFetcher.fetchJSON<undefined>('POST', '/api/auth/send-email', undefined, payload, 'application/json', true)
    return res
  }

  public formatUsername (username: string): string {
    if (username.startsWith('@')) {
      username = username.substring(1)
    }
    return username.toLowerCase()
  }

  /**
   * validateUsername is a catch-all utility to validate a username
   * and return errors as a string.
   * @param username username to validate
   * @returns either empty string if everything is okay, or error message
   */
  public async validateUsername (username: string): Promise<string> {
    username = this.formatUsername(username)
    const isValid = this.isUsernameValid(username)
    if (!isValid) {
      return 'Username invalid'
    }
    const res = await this.common.jsonFetcher.fetchJSON<{ available: boolean }>('GET', `/api/user/username-available/${username}`, undefined, undefined, 'application/json', true)
    if (res.isSuccess) {
      if (res.data.available) {
        return ''
      } else {
        return 'Username taken'
      }
    } else {
      return 'Error checking username'
    }
  }

  protected async processRefreshTokenResponse (response: APIResponse<RefreshTokenResponse>): Promise<LocalStorageUserData> {
    if (response.isSuccess) {
      const payload = adaptLoginCredentialsForStorage(response.data)
      const payloadString = JSON.stringify(payload)
      this.common.localStorage.setItem(SELF_DATA_KEY, payloadString)
      return payload
    } else {
      console.error(response)
      const errPayload = JSON.stringify(response)
      const err = new Error(errPayload)
      throw err
    }
  }

  public async completeRegistration (username: string, captcha: string, referrer: string = ''): Promise<LocalStorageUserData> {
    const token = this.common.localStorage.getItem('lastValidatedAuthToken') ?? ''
    const parsedToken = YouMemeJWT.fromTokenString(token)
    const email = parsedToken.email
    const dto: CompleteRegistrationDto = {
      username: this.formatUsername(username),
      displayName: username,
      token,
      email,
      'g-recaptcha-response': captcha,
      ...((typeof referrer === 'string' && referrer.length > 0) && { referrer })
    }
    const response = await this.common.jsonFetcher.fetchJSON<RefreshTokenResponse>('POST', '/api/auth/complete-registration', undefined, dto, 'application/json', true)
    const res = await this.processRefreshTokenResponse(response)
    return res
  }

  public async completeLogin (token: string, source: TokenSource = 'email', referrer?: string): Promise<LocalStorageUserData> {
    const params = new CompleteLoginQueryDto()
    params.source = source
    params.referrer = referrer
    const body = { token }
    const response = await this.common.jsonFetcher.fetchJSON<RefreshTokenResponse>('POST', '/api/auth/complete-login', params, body, 'application/json', true)
    const res = await this.processRefreshTokenResponse(response)
    return res
  }

  public async refreshAccessToken (): Promise<LocalStorageUserData> {
    const sessionToken = this.getSessionTokenFromLocalStorage()
    const response = await this.common.jsonFetcher.fetchJSON<RefreshTokenResponse>('POST', '/api/auth/refresh-access-token', undefined, { sessionToken }, 'application/json', true)
    const res = this.processRefreshTokenResponse(response)
    return await res
  }

  public async logout (): Promise<void> {
    const sessionToken = this.getSessionTokenFromLocalStorage()
    const body = { sessionToken }
    await this.common.jsonFetcher.fetchJSON<undefined>('DELETE', '/api/auth/logout', undefined, body, 'application/json', true)
    await this.clearSession()
  }

  public isUsernameValid (username: string): boolean {
    return USERNAME_REGEX.test(username)
  }

  public async verifyLoginToken (loginToken: string): Promise<{ verified: boolean }> {
    const parts = loginToken.split('.')
    if (parts.length !== 3) {
      const msg = `Invalid token format. Expected 3 parts, got ${parts.length}`
      console.error(msg)
      throw new Error(msg)
    }
    try {
      const tokenStrBase64 = parts[1]
      const tokenStr = atob(tokenStrBase64)
      const token = JSON.parse(tokenStr)
      const tExpiry = token.exp * 1000
      const dExpiry = (new Date(tExpiry)).toString()
      const tNow = Date.now()
      if (tExpiry <= tNow) {
        const msg = `Token expired at ${dExpiry}`
        console.error(msg)
        throw new Error(msg)
      }
      this.common.localStorage.setItem('lastValidatedAuthToken', loginToken)
      return { verified: true }
    } catch (err) {
      console.error(err)
      throw err
    }
  }
}
