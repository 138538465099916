import { Typography } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiCollapse-container': {
      overflow: 'hidden !important'
    }
  },
  heading: {
    font: 'normal normal normal 14px/19px Gotham Rounded'
  },
  detail: {
    font: 'normal normal normal 12px/19px Gotham Rounded'
  }
}))

const CustomizedAccordion = ({ data }: any): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {data.map((item: any, key: number) => (
        <Accordion key={key}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography className={classes.heading}>{item.summary}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.detail}>{item.details}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}

export default CustomizedAccordion
