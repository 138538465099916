export const Argent=(props:any)=>(
  <svg {...props} width="1em" height="1em" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="30" height="30" rx="15" fill="#FF00FF" fill-opacity="0.3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15 2.5C21.9021 2.5 27.5 8.07913 27.5 14.9688H15V2.5Z" fill="#FF00FF" fill-opacity="0.3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15 27.4375C21.9021 27.4375 27.5 21.8584 27.5 14.9688H15V27.4375Z" fill="#FFFF66" fill-opacity="0.3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.0037 21.8523C18.8043 21.8523 21.9059 18.7569 21.9059 14.9689C21.9059 11.1746 18.8043 8.08545 15.0037 8.08545C11.2032 8.08545 8.10156 11.1746 8.10156 14.9689C8.1078 18.7569 11.2032 21.8523 15.0037 21.8523Z" fill="#AD8A60" fill-opacity="0.3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.5403 19.069C20.3967 19.2562 20.247 19.4309 20.0972 19.6057C19.03 20.7727 17.6945 21.4778 16.1406 21.7524V23.2751C16.721 23.3251 17.2889 23.4187 17.8443 23.5497C19.3358 23.893 20.7213 24.5046 21.9569 25.3283L22.0006 25.2971H22.0068C22.3126 25.0849 22.6122 24.8291 22.9242 24.6106L22.9616 24.5857C23.2674 24.3236 23.5545 24.049 23.8541 23.7806C24.3471 23.2876 24.7964 22.7509 25.202 22.183C24.3595 21.9334 23.3298 21.4778 23.748 20.4294C24.1786 19.3498 24.9899 18.5447 23.9601 17.515C23.879 17.4464 23.8041 17.384 23.723 17.3216C22.8868 16.6975 22.7307 16.6101 23.0615 15.543C23.0865 15.4743 23.1052 15.4119 23.1301 15.3433C23.1863 15.181 23.2425 15.0375 23.2674 14.9751H21.8446C21.8508 16.3543 21.4764 17.7709 20.6713 18.8942L20.5403 19.069Z" fill="#660066" fill-opacity="0.3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15 27.4375C8.09785 27.4375 2.5 21.8522 2.5 14.9688H15V27.4375Z" fill="#99FFFF" fill-opacity="0.3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.0037 21.8522C11.1907 21.8522 8.10156 18.7693 8.10156 14.9688H15.0037V21.8522Z" fill="#CF934F" fill-opacity="0.3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.9989 27.4378C12.4278 27.4378 10.0376 26.664 8.04688 25.3347C9.27628 24.511 10.6679 23.8994 12.1595 23.5561C12.7149 23.4251 13.2828 23.3377 13.8632 23.2815V20.6792C14.2313 20.7416 14.612 20.7728 14.9989 20.7728V27.4378Z" fill="#CF934F" fill-opacity="0.3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15 21.8522C18.813 21.8522 21.9021 18.7693 21.9021 14.9688H15V21.8522Z" fill="#FFEEDB" fill-opacity="0.3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15 18.1828C15.1747 18.0642 15.2933 17.9955 15.493 17.8957C15.5991 17.8458 15.7177 17.8208 15.8425 17.8208C15.9798 17.8208 16.1108 17.852 16.2232 17.9144C16.6912 18.1453 17.1156 18.37 17.5649 18.6196C16.9346 19.3622 16.1046 19.7304 15.0062 19.7304L15 18.1828Z" fill="#FF1414" fill-opacity="0.3"/>
<path d="M15 10.4755C15.5929 10.5067 16.1296 10.1385 16.2481 9.47075C16.3043 10.5753 17.6585 11.0559 18.1266 9.60805C18.0267 10.488 18.9753 10.8437 19.8365 10.2633C21.2656 11.3679 21.7711 13.0591 21.9021 14.975H23.2626C23.6745 13.8579 24.6418 13.3399 22.9443 12.5599C22.7259 12.46 22.8819 12.5162 23.2002 12.1168C24.18 10.8312 23.6183 10.2633 22.1331 10.3444C21.9833 10.3507 22.2017 10.1822 22.3078 10.0199C23.4436 8.33496 23.194 7.21164 20.9473 7.7733C21.2157 6.87465 21.6962 5.35817 20.161 5.46426C19.6555 5.49547 19.2624 5.75133 18.8567 6.02592C18.8879 5.02118 18.8318 3.74809 17.4401 4.17869C16.9783 4.33471 16.6413 4.66546 16.3168 5.02118C16.1795 4.23486 15.8425 3.6732 15 3.57959V10.4755Z" fill="white" fill-opacity="0.3"/>
<path d="M14.7283 17.796C14.8906 17.8584 15.0092 18.0144 15.0092 18.2266C15.0154 18.3764 14.953 18.6073 14.6098 18.8507C14.0544 19.1939 13.2556 19.2626 12.7625 18.9568C12.307 18.7446 12.0261 18.3327 12.2009 17.7523C12.3195 17.5027 12.6502 17.3841 12.8749 17.4653C13.1245 17.5589 13.2368 17.821 12.931 17.977C13.0808 17.6774 12.5129 17.4902 12.463 18.0082C12.4505 18.1892 12.7189 18.445 13.0496 18.495C13.3429 18.5387 13.6362 18.4326 13.9171 18.1455C14.0481 18.0207 14.1729 17.8896 14.3414 17.8085C14.4538 17.7648 14.5786 17.7523 14.7283 17.796Z" fill="black" fill-opacity="0.3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 14.9688C2.5 8.08537 8.10409 2.5 15.0062 2.5V14.9688H2.5Z" fill="#FFFF00" fill-opacity="0.3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.10156 14.9689C8.10156 11.1683 11.1907 8.08545 15.0037 8.08545V14.9689H8.10156Z" fill="#EDD491" fill-opacity="0.3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15 27.4378C17.5711 27.4378 19.9613 26.664 21.9521 25.3347C20.7164 24.511 19.331 23.8994 17.8395 23.5561C17.2841 23.4251 16.7162 23.3377 16.1358 23.2815V20.6792C15.7676 20.7416 15.3869 20.7728 15 20.7728V27.4378Z" fill="#FFEEDB" fill-opacity="0.3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.7602 13.7771C18.066 13.7771 18.3094 13.5274 18.3094 13.2279C18.3094 12.9221 18.0598 12.6787 17.7602 12.6787C17.4544 12.6787 17.2111 12.9283 17.2111 13.2279C17.2048 13.5337 17.4544 13.7771 17.7602 13.7771Z" fill="black" fill-opacity="0.3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.1968 23.6309C19.5323 23.9803 20.8116 24.5607 21.9599 25.3283C19.9067 26.695 17.4853 27.4314 15.0078 27.4314V25.9898C15.869 25.9898 16.6491 25.7277 17.2232 25.3096C17.7974 24.8852 18.1656 24.2924 18.1968 23.6309Z" fill="#4B9100" fill-opacity="0.3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.1696 14.3073C11.9075 14.3073 11.6953 14.0951 11.6953 13.833V12.6286C11.6953 12.3665 11.9075 12.1543 12.1696 12.1543C12.4317 12.1543 12.6501 12.3665 12.6501 12.6286V13.833C12.6501 14.0951 12.4317 14.3073 12.1696 14.3073Z" fill="#1A1A1A" fill-opacity="0.3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.88989 10.3383C9.07861 11.1932 8.54815 12.2978 8.24236 13.5896C7.74935 12.7471 7.93657 10.4568 8.56064 9.34599C8.09883 9.06516 7.04416 8.92787 5.23438 9.73915C6.80078 7.57365 8.99124 7.45508 10.3392 7.6735C9.86493 6.86222 9.23462 6.46906 7.51845 5.91364C10.2893 4.98378 11.319 5.60161 12.7731 6.71244C12.7107 5.60161 12.5671 5.12732 11.7246 3.79182C13.0851 3.69197 14.2022 4.58438 15.001 5.65777V9.80156C14.2583 9.84524 13.6842 9.33351 13.4283 8.75937C13.2411 9.25862 13.2723 10.151 13.5095 10.6815C12.3674 10.538 12.0304 9.69547 11.7933 8.99027C11.2566 9.22118 10.2768 9.93261 9.88989 10.3383Z" fill="#FF0000" fill-opacity="0.3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.81 23.6309C10.4682 23.9803 9.19515 24.5607 8.04688 25.3283C10.1 26.695 12.5214 27.4314 14.9989 27.4314H15.0052V25.9898H14.9989C14.1377 25.9898 13.3577 25.7277 12.7835 25.3096C12.2031 24.8852 11.8412 24.2924 11.81 23.6309Z" fill="#FFFFCC" fill-opacity="0.3"/>
</svg>

)