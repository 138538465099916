import {Box, Button, makeStyles, Typography} from '@material-ui/core';
import {styled} from '@mui/material';
import colors from 'theme/dark/colors';
import React, {useEffect, useState} from 'react';
import ReactLoading from 'react-loading'
import Lottie from 'lottie-react';
import FileUploadAnimation from '../../assets/animations/fileUpload.json'
import Alert from '@material-ui/lab/Alert';
import useWindowSize from 'hooks/useWindowSize'
import { Image as CommonImage } from 'components/shared/index'
import { GoofyStarIcon } from 'meme-generator/components/shared/icons/GoofyStarIcon'
import { MagicEditIcon } from 'meme-generator/components/shared/icons/MagicEditIcon'
import {
  BREAKPOINT_MOBILE_LARGE,
  BREAKPOINT_TABLET_LARGE
} from 'theme/shared/breakpoint'
import { CloseOutlined } from '@material-ui/icons'
import { DefaultYMCA } from 'ymca/ymca'
import { processFile } from 'utils/create-meme'

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    [`@media (max-height: 700px) and (min-width: ${BREAKPOINT_TABLET_LARGE}px)`]:
      {
        height: '20.5rem'
      },
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '5px'
    },

    '&::-webkit-scrollbar-track': {
      'box-shadow': 'inset 0 0 6px rgba(0, 0, 0, 0.3)'
    },

    '&::-webkit-scrollbar-thumb': {
      'background-color': colors.primary500,
      outline: `1px solid ${colors.primary500}`,
      borderRadius: '5px'
    }
  },
  faceSwapContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  loadingIcon: {
    position: 'absolute',
    top: 'calc(50% - 16px)',
    left: 'calc(50% - 16px)',
    zIndex: 1
  },
  originalImageSectionContainer: {
    width: '100%',
    paddingRight: '0.9375rem',
    display: 'flex',
    gap: '.75rem',
    flexDirection: 'column',
    '& picture': {
      width: '100%',
      height: '14rem',

      '& img': {
        objectFit: 'contain',
        height: '100%',
        width: '100%'
      }
    }
  },
  secondaryImageSectionContainer: {
    height: '100%',
    width: '100%',
    paddingLeft: '0.9375rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '.75rem'
  },
  sectionTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.7294rem',
    marginTop: '1.625rem'
  },
  sectionTitleText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '1.125rem',
    lineHeight: '1.375rem',
    letterSpacing: '-0.0375rem',
    color: colors.titleColor
  },
  sectionHeadingText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '0.875rem',
    lineHeight: '1.0625rem',
    letterSpacing: '-0.03125rem',
    color: colors.titleColor
  },
  sectionDescriptionText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.8125rem',
    lineHeight: '1.5rem',
    letterSpacing: '-0.03125rem',
    textAlign: 'center',
    color: colors.greyScale500,
    '&.previewSection': {
      marginBottom: '0.5rem'
    }
  },
  originalImageMobileContinue: {
    display: 'none'
  },
  secondaryImageSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',

    '& picture': {
      width: '100%',
      height: '14rem',

      '& img': {
        objectFit: 'contain',
        height: '100%',
        width: '100%'
      }
    }
  },
  originalImageDetectedFacesSection: {
    position: 'relative',
    height: '19%'
  },
  secondaryImageDetectedFaces: {
    position: 'relative',
    height: '23%'
  },
  detectedFacesContainer: {
    width: '100%',
    height: '4.26rem',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '1rem',
    padding: '0 3px',
    marginTop: '0.5rem'
  },
  dragTitle: {
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '1.125rem',
    lineHeight: '1.361rem',
    pointerEvents: 'none',
    '& span': {
      color: colors.gradientPurpleLight
    }
  },
  dragWrapper: {
    height: '14rem',
    maxHeight: '14rem',
    background: 'transparent',
    boxSizing: 'border-box',
    borderRadius: 5,
    fontSize: '1em',
    border: '2px dashed rgba(138, 152, 180, 0.5)',
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    opacity: 0,
    animation: '$appear 200ms 500ms ease both',
    flexGrow: 1,
    cursor: 'pointer'
  },
  '@keyframes appear': {
    to: {
      opacity: 1
    }
  },
  dragged: {
    backgroundColor: '#f0f8ff'
  },
  uploadAnimation: {
    '& > svg': {
      width: '59% !important',
      height: '10rem !important'
    }
  },
  previewSection: {
    background: colors.darkBlueActive,
    borderRadius: '0.75rem',
    width: '100%',
    padding: '1.2rem'
  },
  previewSectionTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem'
  },
  faceImageContainer: {
    width: '4.5rem',
    height: '4.5rem',
    borderRadius: '0.75rem',
    background: colors.greyScale100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  swapButton: {
    marginTop: 'auto',
    background: colors.purpleGradient,
    color: colors.white,
    borderRadius: '0.75rem',
    padding: '0.7075rem',
    '&:disabled': {
      color: colors.greyScale500,
      background: colors.darkBlueActive
    },
    '&.MuiButton-root': {
      fontSize: '1rem',
      lineHeight: '1.1875rem',
      fontWeight: 700
    }
  },
  [`@media (max-width: ${BREAKPOINT_MOBILE_LARGE}px)`]: {
    faceSwapContainer: {
      flexDirection: 'column',
      maxHeight: 'unset'
    },
    originalImageSectionContainer: {
      width: '100%',
      paddingRight: '0',
      '& > picture': {
        height: 'unset !important',
        maxHeight: '61.86%'
      }
    },
    secondaryImageSection: {
      height: 'unset',
      flexGrow: 1,
      '& > picture': {
        height: 'unset !important'
      }
    },
    secondaryImageSectionContainer: {
      width: '100%',
      paddingLeft: '0'
    },
    sectionTitleText: {
      fontSize: '16px'
    },
    sectionTitleContainer: {
      marginTop: '24px'
    },
    dragWrapper: {
      flexGrow: 'unset'
    },
    sectionHeadingText: {
      fontSize: '13px'
    },
    detectedFacesContainer: {
      marginTop: '11px',
      height: '70px',
      gap: '16px'
    },
    originalImageDetectedFacesSection: {
      padding: '4px 0'
    },
    secondaryImageDetectedFaces: {
      padding: '4px 0',
      marginTop: '24px'
    },
    originalImageMobileContinue: {
      display: 'unset',
      marginTop: 'auto',
      '& > button': {
        fontSize: '16px',
        background: 'linear-gradient(90deg, #CF01FF 0%, #6202FF 100%)',
        borderRadius: '15px',
        '&:disabled': {
          background: 'rgba(255, 255, 255, 0.2)',
          color: 'rgba(255, 255, 255, 0.4)'
        }
      }
    },
    dragTitle: {
      fontSize: '16px'
    },
    swapButton: {
      marginTop: 'auto',
      fontSize: '16px !important' as any,
      padding: '12px'
    }
  }
})

interface detectedFaceI {
  image: string
  x: number
  y: number
  width: number
  height: number
}

const SelectedImage = styled(CommonImage, {
  name: 'SelectedImage'
})(() => {
  return {
    borderRadius: '0.75rem',
    background: colors.darkBlueActive,
    objectFit: 'contain',

    width: '100%',
    height: '100%',

    '& picture': {
      width: '100%',
      height: '15rem'
    }
  }
})

interface FaceSwapInterface {
  selectedImage: string
  setSelectedImage: (value: null) => void
  pushImageToEditor: (image: string) => void
}

const FaceSwap = (props: FaceSwapInterface): JSX.Element => {
  const classes = useStyles()
  const [detectedOriginalImageFaces, setDetectedOriginalImageFaces] = useState<
    detectedFaceI[] | null
  >(null)
  const [selectedOriginalImageFace, setSelectedOriginalImageFace] = useState<
    number | null
  >(null)
  const [
    originalImageFaceDetectionLoading,
    setOriginalImageFaceDetectionLoading
  ] = useState<boolean>(false)
  const [secondaryImage, setSecondaryImage] = useState<string | null>(null)
  const [detectedSecondaryImageFaces, setDetectedSecondaryImageFaces] =
    useState<detectedFaceI[] | null>(null)
  const [selectedSecondaryImageFace, setSelectedSecondaryImageFace] = useState<
    number | null
  >(null)
  const [
    secondaryImageFaceDetectionLoading,
    setSecondaryImageFaceDetectionLoading
  ] = useState<boolean>(false)
  const [filesDragged, setFilesDragged] = useState(false)
  const [selectedSection, setSelectedSection] = useState<
    'original' | 'secondary'
  >('original')
  const [err, setErr] = useState(false)
  const [msg, setMsg] = useState('')
  const [swapping, setSwapping] = useState<boolean>(false)
  const isMobile = useWindowSize().width <= BREAKPOINT_MOBILE_LARGE

  useEffect(() => {
    if (props.selectedImage !== '') {
      detectFaces(0)
    }
  }, [props.selectedImage])

  useEffect(() => {
    if (secondaryImage !== '' && secondaryImage) {
      detectFaces(1)
    }
  }, [secondaryImage])

  const handleImageReset = (type: number) => {
    if (type === 0) {
      props.setSelectedImage(null)
      setDetectedOriginalImageFaces(null)
      setSelectedOriginalImageFace(null)
    } else if (type === 1) {
      setSecondaryImage(null)
      setDetectedSecondaryImageFaces(null)
      setSelectedSecondaryImageFace(null)
    }
  }

  function imageToBlob(image: HTMLImageElement): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      if (!ctx) return reject(new Error('Failed to get canvas context'))
      canvas.width = image.width
      canvas.height = image.height
      ctx.drawImage(image, 0, 0)
      canvas.toBlob((blob) => {
        if (!blob) return reject(new Error('Failed to convert canvas to blob'))
        resolve(blob)
      }, 'image/jpeg')
    })
  }

  const detectFaces = async (type: number) => {
    const dummyImage = new Image()
    const offScreenCanvas = document.createElement(
      'canvas'
    ) as HTMLCanvasElement
    const offScreenCanvasCTX = offScreenCanvas.getContext(
      '2d'
    ) as CanvasRenderingContext2D
    if (type === 0) {
      setOriginalImageFaceDetectionLoading(true)
      dummyImage.src = props.selectedImage
    } else if (type === 1 && secondaryImage) {
      setSecondaryImageFaceDetectionLoading(true)
      dummyImage.src = secondaryImage
    }
    dummyImage
      .decode()
      .then(async () => {
        setTimeout(async () => {
          const { naturalWidth, naturalHeight } = dummyImage
          offScreenCanvas.width = naturalWidth
          offScreenCanvas.height = naturalHeight
          offScreenCanvasCTX.drawImage(
            dummyImage,
            0,
            0,
            naturalWidth,
            naturalHeight
          )
          const asBlob = await imageToBlob(dummyImage)
          const detectedPeople = await DefaultYMCA.faceswapService.getFaces(
            asBlob
          )
          let detectedPeopleImages: detectedFaceI[] = []
          for await (const detectedPerson of detectedPeople) {
            detectedPeopleImages.push({
              image: detectedPerson,
              x: 0,
              y: 0,
              width: 0,
              height: 0
            })
          }
          if (type === 0) {
            setDetectedOriginalImageFaces(detectedPeopleImages)
            setSelectedOriginalImageFace(0)
            setOriginalImageFaceDetectionLoading(false)
          } else if (type === 1 && secondaryImage) {
            setDetectedSecondaryImageFaces(detectedPeopleImages)
            setSelectedSecondaryImageFace(0)
            setSecondaryImageFaceDetectionLoading(false)
          }
        }, 300)
      })
      .catch((err) => {
        setOriginalImageFaceDetectionLoading(false)
        setSecondaryImageFaceDetectionLoading(false)
        console.error(err)
      })
  }

  function ensureJpegBase64(img: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      if (!ctx) return reject(new Error('Failed to get canvas context'))
      const image = new Image()

      image.onload = function () {
        canvas.width = image.width
        canvas.height = image.height
        ctx.drawImage(image, 0, 0)

        const jpegBase64 = canvas.toDataURL('image/jpeg')

        resolve(jpegBase64)
      }

      image.onerror = function () {
        reject(new Error('Failed to load PNG image'))
      }

      image.src = img
    })
  }

  const swapFaces = async () => {
    const offScreenCanvas = document.createElement(
      'canvas'
    ) as HTMLCanvasElement
    const offScreenCanvasCTX = offScreenCanvas.getContext(
      '2d'
    ) as CanvasRenderingContext2D

    const resultImg = new Image()
    resultImg.src = props.selectedImage
    await resultImg.decode()
    offScreenCanvas.width = resultImg.width
    offScreenCanvas.height = resultImg.height

    if (selectedSecondaryImageFace === null) return
    if (!detectedSecondaryImageFaces) return
    if (selectedOriginalImageFace === null) return
    if (!detectedOriginalImageFaces) return
    const sourceString =
      detectedSecondaryImageFaces[selectedSecondaryImageFace].image
    const targetString = props.selectedImage
    const targetFaceString =
      detectedOriginalImageFaces[selectedOriginalImageFace].image
    const [source, target, target_face] = await Promise.all([
      ensureJpegBase64(sourceString),
      ensureJpegBase64(targetString),
      ensureJpegBase64(targetFaceString)
    ])
    const response = await DefaultYMCA.faceswapService.swap({
      source,
      target,
      target_face
    })
    const blob = new Blob([response], { type: 'image/jpeg' })
    const url = URL.createObjectURL(blob)
    const swappedImage = new Image()
    swappedImage.src = url
    swappedImage.onload = () => {
      offScreenCanvasCTX.drawImage(swappedImage, 0, 0)
      props.pushImageToEditor(offScreenCanvas.toDataURL())

      URL.revokeObjectURL(url)
    }
  }

  const showError = (msg: string) => {
    setErr(true)
    setMsg(msg)
    setTimeout(() => setErr(false), 2500)
  }
  const dragListener = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault()
    setFilesDragged(false)
    const files = event.dataTransfer.files
    if (files.length === 1) {
      handleProcessFile(files[0])
    } else {
      showError('Invalid Drop')
    }
  }
  const handleProcessFile = async (file: File) => {
    try {
      if (file) {
        let inputElement = document.getElementById(
          'faceSwapInput'
        ) as HTMLInputElement
        inputElement.value = ''

        const processfileRes = await processFile({
          file,
          ignoreResolutionCheck: true
        })
        setSecondaryImage(processfileRes.data)
      }
    } catch (error: any) {
      showError(error?.message)
      console.log(error)
    }
  }

  const openFileChooser = () => {
    const inputElement = document.getElementById('faceSwapInput') as HTMLElement
    inputElement.click()
  }

  const getImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      handleProcessFile(event.target.files[0])
    }
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.faceSwapContainer}>
        <div
          className={classes.originalImageSectionContainer}
          style={
            isMobile && selectedSection === 'secondary'
              ? { display: 'none' }
              : {}
          }
        >
          <div className={classes.sectionTitleContainer}>
            <Typography className={classes.sectionTitleText}>
              Selected Image
            </Typography>
            <CloseOutlined
              htmlColor={colors.greyScale300}
              style={{ marginLeft: 'auto', cursor: 'pointer' }}
              onClick={() => {
                handleImageReset(0)
              }}
            />
          </div>
          {props.selectedImage ? (
            <SelectedImage src={props.selectedImage} alt='primary img' />
          ) : (
            <div
              style={{
                width: '100%',
                height: '67.1%',
                borderRadius: '0.75rem',
                background: colors.darkBlueActive,
                margin: '1.3125rem 0 1.5rem 0'
              }}
            />
          )}

          <div className={classes.originalImageDetectedFacesSection}>
            {detectedOriginalImageFaces?.length === 0 ? (
              <Typography
                className={classes.sectionHeadingText + ' originalImageSection'}
              >
                No face detected.
              </Typography>
            ) : (
              <>
                <Typography
                  className={
                    classes.sectionHeadingText + ' originalImageSection'
                  }
                >
                  {originalImageFaceDetectionLoading
                    ? 'Detecting faces'
                    : 'Choose face to swap'}
                </Typography>
                {originalImageFaceDetectionLoading ? (
                  <ReactLoading
                    className={classes.loadingIcon}
                    type='balls'
                    color={colors.loaderBackground}
                    width={32}
                    height={32}
                  />
                ) : (
                  <div className={classes.detectedFacesContainer}>
                    {detectedOriginalImageFaces?.map(
                      (detectedOriginalImageFaceImage, index) => {
                        return (
                          <img
                            src={detectedOriginalImageFaceImage.image}
                            style={{
                              height: '100%',
                              borderRadius: '0.75rem',
                              cursor: 'pointer',
                              outline:
                                selectedOriginalImageFace === index
                                  ? '3px solid #8B89FF'
                                  : ''
                            }}
                            onClick={() => setSelectedOriginalImageFace(index)}
                            alt='detected face'
                          />
                        )
                      }
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          {isMobile && selectedSection === 'original' && (
            <div className={classes.originalImageMobileContinue}>
              <Button
                disabled={
                  !(
                    detectedOriginalImageFaces &&
                    detectedOriginalImageFaces.length > 0
                  )
                }
                fullWidth
                onClick={() => setSelectedSection('secondary')}
              >
                Continue
              </Button>
            </div>
          )}
        </div>
        <div
          className={classes.secondaryImageSectionContainer}
          style={
            isMobile && selectedSection === 'original'
              ? { display: 'none' }
              : {}
          }
        >
          <div className={classes.sectionTitleContainer}>
            {secondaryImage ? (
              <>
                <Typography
                  className={classes.sectionTitleText}
                  style={{ padding: '1px 0' }}
                >
                  Uploaded face
                </Typography>
                <CloseOutlined
                  htmlColor={colors.greyScale300}
                  style={{ marginLeft: 'auto', cursor: 'pointer' }}
                  onClick={() => {
                    handleImageReset(1)
                  }}
                />
              </>
            ) : (
              <>
                <MagicEditIcon viewBox={'0 0 640 640'} htmlColor='#7167ff' />
                <Typography className={classes.sectionTitleText}>
                  Upload your face
                </Typography>
              </>
            )}
          </div>
          {secondaryImage === null ? (
            <Box
              className={
                filesDragged
                  ? `${classes.dragWrapper} ${classes.dragged}`
                  : classes.dragWrapper
              }
              onDrop={(event: React.DragEvent<HTMLElement>) =>
                dragListener(event)
              }
              onDragLeave={() => setFilesDragged(false)}
              onDragEnter={() => setFilesDragged(true)}
              onDragOver={(e) => {
                e.preventDefault()
              }}
              onClick={() => openFileChooser()}
            >
              <Box
                style={{
                  zIndex: 999,
                  position: 'absolute',
                  background: 'red',
                  width: '100%',
                  height: '100%',
                  display: err === true ? 'flex' : 'none',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 5
                }}
              >
                <Alert severity='error'>{err === true && msg}</Alert>
              </Box>
              <input
                type='file'
                accept='image/*, video/*'
                id='faceSwapInput'
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  getImage(event)
                }
                style={{ display: 'none' }}
              />
              <Typography className={`${classes.dragTitle} text-title`}>
                Drop a selfie or <span>browse image</span>
              </Typography>
              <Lottie
                animationData={FileUploadAnimation}
                loop={true}
                className={classes.uploadAnimation}
              />
            </Box>
          ) : (
            <>
              <div className={classes.secondaryImageSection}>
                {secondaryImage ? (
                  <SelectedImage src={secondaryImage} alt='secondary img' />
                ) : (
                  <div
                    style={{
                      width: '100%',
                      height: '67.1%',
                      borderRadius: '0.75rem',
                      background: colors.darkBlueActive,
                      margin: '1.3125rem 0 1.5rem 0'
                    }}
                  />
                )}
                <div className={classes.secondaryImageDetectedFaces}>
                  {detectedSecondaryImageFaces?.length === 0 ? (
                    <Typography className={classes.sectionHeadingText}>
                      No face detected.
                    </Typography>
                  ) : (
                    <>
                      <Typography className={classes.sectionHeadingText}>
                        {secondaryImageFaceDetectionLoading
                          ? 'Detecting faces'
                          : 'Swap to'}
                      </Typography>
                      {secondaryImageFaceDetectionLoading ? (
                        <ReactLoading
                          className={classes.loadingIcon}
                          type='balls'
                          color={colors.loaderBackground}
                          width={32}
                          height={32}
                        />
                      ) : (
                        <div className={classes.detectedFacesContainer}>
                          {detectedSecondaryImageFaces?.map(
                            (detectedSecondaryImageFaceImage, index) => {
                              return (
                                <img
                                  src={detectedSecondaryImageFaceImage.image}
                                  style={{
                                    height: '100%',
                                    borderRadius: '0.75rem',
                                    cursor: 'pointer',
                                    outline:
                                      selectedSecondaryImageFace === index
                                        ? '3px solid #8B89FF'
                                        : ''
                                  }}
                                  onClick={() =>
                                    setSelectedSecondaryImageFace(index)
                                  }
                                  alt='detected face'
                                />
                              )
                            }
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              {isMobile && (
                <Box display='flex' justifyContent='center'>
                  <Button
                    variant='contained'
                    fullWidth
                    startIcon={<GoofyStarIcon viewBox={'0 0 640 640'} />}
                    className={classes.swapButton}
                    disabled={
                      swapping ||
                      !(
                        detectedOriginalImageFaces &&
                        detectedOriginalImageFaces?.length > 0 &&
                        detectedSecondaryImageFaces &&
                        detectedSecondaryImageFaces?.length > 0
                      )
                    }
                    onClick={() => {
                      setSwapping(true)
                      swapFaces()
                        .then(() => setSwapping(false))
                        .catch((error) => {
                          console.debug(error)
                          setSwapping(false)
                        })
                    }}
                  >
                    {swapping ? 'Swapping faces...' : 'Swap Faces'}
                  </Button>
                </Box>
              )}
            </>
          )}
        </div>
      </div>
      {!isMobile && (
        <Box display='flex' justifyContent='center'>
          <Button
            variant='contained'
            fullWidth
            startIcon={<GoofyStarIcon viewBox={'0 0 640 640'} />}
            className={classes.swapButton}
            disabled={
              swapping ||
              !(
                detectedOriginalImageFaces &&
                detectedOriginalImageFaces?.length > 0 &&
                detectedSecondaryImageFaces &&
                detectedSecondaryImageFaces?.length > 0
              )
            }
            onClick={() => {
              setSwapping(true)
              swapFaces()
                .then(() => setSwapping(false))
                .catch((error) => {
                  console.debug(error)
                  setSwapping(false)
                })
            }}
          >
            {swapping ? 'Swapping faces...' : 'Swap Faces'}
          </Button>
        </Box>
      )}
    </div>
  )
}

export default FaceSwap;
