import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import { DefaultYMCA } from 'ymca/ymca'
import { useWeb3React } from '@web3-react/core'
import { toast } from 'react-hot-toast'
import { useEffect, useState } from 'react'
import useEagerConnect from 'hooks/useEagerConnect'

const useStyles = makeStyles({
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    // padding: '6px 0 6px 10px',
    // margin: '0 0 0 10px',
    flexDirection: 'column'
  },
  itemtitle: {
    color: '#E2E4E9',
    fontWeight: 900,
    fontSize: '26px',
    lineHeight: '31.47px',
    width: '100%'
  },
  description: {
    color: '#808893',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    marginTop: '18px',
    '& span': {
      color: '#ffffff',
      fontWeight: 700,
    }
  },
  itemimage: {
    minWidth: '30px'
  },
  subtitle: {
    fontWeight: 900,
    fontSize: '16px',
    lineHeight: '19.36px',
    color: '#E2E4E9'
  },
  enableButton: {
    background: 'linear-gradient(to right, rgba(83, 255, 112, 1), rgba(42, 196, 87, 1)) !important',
    display: 'flex',
    height: '46px',
    width: '200px',
    borderRadius: '5px',
    marginTop: '30px',
    justifyContent: 'center',
    '&:hover': {
      opacity: '0.8'
    }
  },
  disableButton: {
    composes: 'enableButton',
    background: 'linear-gradient(to right, rgba(255, 0, 0, 1), rgba(255, 0, 0, 0.5)) !important',
    display: 'flex',
    height: '46px',
    width: '200px',
    borderRadius: '5px',
    marginTop: '30px',
    justifyContent: 'center',
    '&:hover': {
      opacity: '0.8'
    }
  },
  enableButtonText: {
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '16.94px',
    paddingLeft: '8.32px'
  },
})

const LensSetting = (): JSX.Element => {
  useEagerConnect();
  const classes = useStyles()
  const context = useWeb3React()
  const { library } = context;
  const [loading, setLoading] = useState<boolean>(false);
  const [disableButton, toggleDisable] = useState<boolean>(false);
  const [isLensProfileManagerEnabled, toggleIsLensProfileManagerEnabled] =
    useState<boolean>(false)
  useEffect(() => {
    const checkIsLensProfileManagerEnabled = async () => {
      const isEnabled =
        await DefaultYMCA.lensService.isSelfLensProfileManagerEnabled()
      toggleIsLensProfileManagerEnabled(isEnabled)
    }
    checkIsLensProfileManagerEnabled()
  }, [])

  const handleSelfLensProfileManager = async () => {
    setLoading(true)
    toggleDisable(true)
    const data = await DefaultYMCA.lensService.setSelfLensProfileManager(
      !isLensProfileManagerEnabled
    )
    library
      .getSigner()
      ._signTypedData(
        data.typedData.domain,
        data.typedData.types,
        data.typedData.value
      )
      .then(async (signature: string) => {
        const broadcastResult = await DefaultYMCA.lensService.broadcastResult({
          id: data.id,
          signature: signature
        })
        if (broadcastResult) {
          if (isLensProfileManagerEnabled) {
            toast.success('Successfully disabled the lens profile manager')
          } else {
            toast.success('Successfully enabled the lens profile manager')
          }
          toggleIsLensProfileManagerEnabled(!isLensProfileManagerEnabled)
        } else {
          toast.error('Could not toggle lens profile manager, please try again')
        }
        setLoading(false)
        toggleDisable(false)
      })
      .catch((error: any) => {
        setLoading(false)
        toggleDisable(false)
        if (error.code && error.code === 'ACTION_REJECTED') {
          toast.error('User rejected signature.')
        } else {
          toast.error('Unable to sign request at the moment.')
        }
      })
  }

  return (
    <Box style={{ padding: '45px 0 0 0' }}>
      <Box className={classes.item}>
        <Typography className={classes.itemtitle}>Lens Protocol</Typography>
        <Typography className={classes.description}>
          Manage your Lens protocol settings.
        </Typography>
        <Divider style={{ margin: '25px 0', backgroundColor: '#2B363E' }} />
        <Box>
          <Box style={{ display: 'flex' }}>
            <Typography
              className={classes.subtitle}
              style={{ marginTop: '10px' }}
            >
              PROFILE MANAGER
            </Typography>
          </Box>
          <Typography
            className={classes.description}
            style={{ width: '687px' }}
          >
            You can enable the lens profile manager to interact with{' '}
            <span>YouMeme</span> without signing any transactions.
          </Typography>
          <Button
            variant='contained'
            style={{ background: 'grey' }}
            className={
              isLensProfileManagerEnabled
                ? classes.disableButton
                : classes.enableButton
            }
            onClick={handleSelfLensProfileManager}
            disabled={disableButton}
          >
            <Typography className={classes.enableButtonText} noWrap>
              {loading ? (
                <CircularProgress size={14} color='inherit' />
              ) : isLensProfileManagerEnabled ? (
                'Disable Profile Manager'
              ) : (
                'Enable Profile Manager'
              )}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default LensSetting
