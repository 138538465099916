import { forwardRef, Ref } from "react";
import Video from "components/atoms/Video";
import { getAssetsPath } from "utils/path";

interface VideoPlayerProps {
  src: string;
}

const VideoPlayer = forwardRef(
  ({ src, ...props }: VideoPlayerProps, ref: Ref<HTMLVideoElement>) => {
    const newSrc = getAssetsPath(src);
    return (
      <Video {...props} ref={ref}>
        <source src={newSrc} type='video/mp4' />
        <source src={newSrc} type='video/mov' />
        <source src={newSrc} type='video/ogg' />
      </Video>
    );
  }
);

export default VideoPlayer;
