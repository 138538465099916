import React, { PropsWithChildren, Ref, forwardRef } from 'react'

import ImagePicture, { ImagePictureProps } from '../ImagePicture'
import useBackgroundImageStyles from './style'

interface BackgroundImageProps extends PropsWithChildren<ImagePictureProps> {}

/**
 * @example
 * <BackgroundImage
    src={fallback ?? DEFAULT_AVATAR_IMAGE}
    pictureSources={[
      {
        srcSet: `${x2} 1x, ${x3} 2x`,
        type: image/avif
      }
    ]}
    loading='lazy'
    alt='user icon'
    className={classes.backgroundImage}
  />
 */

const BackgroundImage = forwardRef(
  (
    { children, ...props }: BackgroundImageProps,
    ref?: Ref<HTMLImageElement>
  ): JSX.Element => {
    const classes = useBackgroundImageStyles()

    if (!props.src) {
      return (
        <div
          className={`${classes.background_picture_wrapper} ${props.className}`}
        >
          {children}
        </div>
      )
    }
    return (
      <>
        <ImagePicture
          {...props}
          ref={ref}
          className={`${classes.background_picture_wrapper} ${props.className}`}
        />
        {children}
      </>
    )
  }
)

export default BackgroundImage
