export const SatisfyIcon = ({ ...props }): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#000000' {...props}>
    <path d='M0 0h24v24H0V0z' fill='none' />
    <path d='M11.99 2C6.47 2 2 6.47 2 12s4.47 10 9.99 10S22 17.53 22 12 17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm1-10.06L14.06 11l1.06-1.06L16.18 11l1.06-1.06-2.12-2.12L13 9.94zm-4.12 0L9.94 11 11 9.94 8.88 7.82 6.76 9.94 7.82 11l1.06-1.06zM12 17.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z' />
  </svg>
)

export const ExpandMoreIcon = ({ ...props }): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#000000' {...props}>
    <path d='M24 24H0V0h24v24z' fill='none' opacity='.87' />
    <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z' />
  </svg>
)

export const ExpandLessIcon = ({ ...props }): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#000000' {...props}>
    <path d='M0 0h24v24H0V0z' fill='none' />
    <path d='M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z' />
  </svg>
)

export const RotateRight = ({ ...props }): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#000000' {...props}>
    <path d='M0 0h24v24H0V0z' fill='none' />
    <path d='M15.55 5.55L11 1v3.07C7.06 4.56 4 7.92 4 12s3.05 7.44 7 7.93v-2.02c-2.84-.48-5-2.94-5-5.91s2.16-5.43 5-5.91V10l4.55-4.45zM19.93 11c-.17-1.39-.72-2.73-1.62-3.89l-1.42 1.42c.54.75.88 1.6 1.02 2.47h2.02zM13 17.9v2.02c1.39-.17 2.74-.71 3.9-1.61l-1.44-1.44c-.75.54-1.59.89-2.46 1.03zm3.89-2.42l1.42 1.41c.9-1.16 1.45-2.5 1.62-3.89h-2.02c-.14.87-.48 1.72-1.02 2.48z' />
  </svg>
)

export const FlipIcon = ({ ...props }): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#000000' {...props}>
    <path d='M0 0h24v24H0z' fill='none' />
    <path d='M15 21h2v-2h-2v2zm4-12h2V7h-2v2zM3 5v14c0 1.1.9 2 2 2h4v-2H5V5h4V3H5c-1.1 0-2 .9-2 2zm16-2v2h2c0-1.1-.9-2-2-2zm-8 20h2V1h-2v22zm8-6h2v-2h-2v2zM15 5h2V3h-2v2zm4 8h2v-2h-2v2zm0 8c1.1 0 2-.9 2-2h-2v2z' />
  </svg>
)

export const DropDownIcon = ({ ...props }): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#000000' {...props}><path d='M0 0h24v24H0z' fill='none' /><path d='M7 10l5 5 5-5z' /></svg>
)

export const CheckIcon = ({ ...props }): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#000000' {...props}><path d='M0 0h24v24H0z' fill='none' /><path d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z' /></svg>
)

export const NotificationIcon = ({ ...props }): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' height='18px' viewBox='0 0 24 24' width='18px' fill='#000000' {...props}><path d='M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z' /></svg>
)

export const SettingIcon = ({ ...props }): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' enableBackground='new 0 0 24 24' height='24px' viewBox='0 0 24 24' width='24px' fill='#000000' {...props}><g><path d='M0,0h24v24H0V0z' fill='none' /><path d='M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z' /></g></svg>
)

export const MoreIcon = ({ ...props }): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#000000' {...props}><path d='M0 0h24v24H0z' fill='none' /><path d='M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z' /></svg>
)

export const LoveIcon = ({ ...props }): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#000000' {...props}><path d='M0 0h24v24H0z' fill='none' /><path d='M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z' /></svg>
)

export const ShareIcon = ({ ...props }): JSX.Element => (
  <svg
    width='16'
    height='20'
    viewBox='0 0 16 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M9.65343 3.88954C9.65343 2.202 11.0277 0.833984 12.7229 0.833984C14.4181 0.833984 15.7923 2.202 15.7923 3.88954C15.7923 5.57708 14.4181 6.9451 12.7229 6.9451C11.8669 6.9451 11.0933 6.59611 10.5371 6.03467L6.28801 8.92774C6.3273 9.12293 6.34787 9.32456 6.34787 9.53057C6.34787 9.93851 6.26727 10.3284 6.12111 10.6848L10.7803 13.746C11.309 13.3154 11.9857 13.0562 12.7229 13.0562C14.4181 13.0562 15.7923 14.4242 15.7923 16.1118C15.7923 17.7993 14.4181 19.1673 12.7229 19.1673C11.0277 19.1673 9.65343 17.7993 9.65343 16.1118C9.65343 15.6698 9.74804 15.249 9.91806 14.8692L5.29655 11.8328C4.75744 12.3013 4.05128 12.5861 3.27843 12.5861C1.58322 12.5861 0.208984 11.2181 0.208984 9.53057C0.208984 7.84303 1.58322 6.47501 3.27843 6.47501C4.25323 6.47501 5.12097 6.9273 5.68281 7.63123L9.80092 4.82737C9.70512 4.53144 9.65343 4.21612 9.65343 3.88954Z'
      fill='#8A98B4'
    />
  </svg>
);

export const SaveIcon = ({ ...props }): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#000000' {...props}><path d='M0 0h24v24H0z' fill='none' /><path d='M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z' /></svg>
)

export const VisibleIcon = ({ ...props }): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#000000' {...props}><path d='M0 0h24v24H0z' fill='none' /><path d='M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z' /></svg>
)

export const ColorIcon = ({ ...props }): JSX.Element => (
  <svg id='Capa_1' height='24' viewBox='0 0 512 512' width='24' xmlns='http://www.w3.org/2000/svg'>
    <g><path d='m90.751 186.698c-53.845 27.306-90.751 83.185-90.751 147.69 0 91.403 74.097 165.5 165.5 165.5 33.404 0 64.491-9.906 90.5-26.927v-226.263z' fill='#09a1e5' />
      <path d='m421.249 186.698-165.249 60v226.263c26.009 17.021 57.096 26.927 90.5 26.927 91.403 0 165.5-74.097 165.5-165.5 0-64.505-36.906-120.384-90.751-147.69z' fill='#89c247' />
      <path d='m256 12.112-60 243.703 225.249-69.117c.163-3.008.251-6.036.251-9.085 0-91.404-74.097-165.501-165.5-165.501z' fill='#cb2e81' />
      <path d='m90.5 177.612c0 3.049.089 6.077.251 9.085l165.249 69.118v-243.703c-91.403 0-165.5 74.097-165.5 165.5z' fill='#ff3187' />
      <path d='m165.5 168.888c-26.899 0-52.295 6.423-74.749 17.81 3.281 60.637 39.2 112.589 90.5 138.605l74.749-129.488c-26.009-17.021-57.096-26.927-90.5-26.927z' fill='#ff5178' />
      <path d='m346.5 168.888c-33.404 0-64.491 9.906-90.5 26.927l74.749 129.488c51.3-26.015 87.219-77.968 90.5-138.605-22.454-11.387-47.85-17.81-74.749-17.81z' fill='#ffd23b' />
      <path d='m330.749 325.302h-104.749l30 147.659c45.159-29.553 75-80.574 75-138.573 0-3.049-.089-6.077-.251-9.086z' fill='#7ed7ff' />
      <path d='m181.251 325.302c-.163 3.009-.251 6.037-.251 9.086 0 57.999 29.841 109.02 75 138.573v-147.659z' fill='#bfebff' />
      <path d='m256 195.815-30 73.649 30 73.649c26.899 0 52.295-6.423 74.749-17.81-2.934-54.218-31.964-101.489-74.749-129.488z' fill='#dff5ff' />
      <path d='m181.251 325.302c22.454 11.387 47.85 17.81 74.749 17.81v-147.297c-42.785 27.999-71.815 75.27-74.749 129.487z' fill='#fff' />
    </g>
  </svg>
)

export const BrightnessIcon = ({ ...props }): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#000000' {...props}><path d='M0 0h24v24H0V0z' fill='none' /><path d='M20 8.69V4h-4.69L12 .69 8.69 4H4v4.69L.69 12 4 15.31V20h4.69L12 23.31 15.31 20H20v-4.69L23.31 12 20 8.69zm-2 5.79V18h-3.52L12 20.48 9.52 18H6v-3.52L3.52 12 6 9.52V6h3.52L12 3.52 14.48 6H18v3.52L20.48 12 18 14.48zM12 6v12c3.31 0 6-2.69 6-6s-2.69-6-6-6z' /></svg>
)

export const WbsunnyIcon = ({ ...props }): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#000000' {...props}><path d='M0 0h24v24H0V0z' fill='none' /><path d='M6.76 4.84l-1.8-1.79-1.41 1.41 1.79 1.79zM1 10.5h3v2H1zM11 .55h2V3.5h-2zm8.04 2.495l1.408 1.407-1.79 1.79-1.407-1.408zm-1.8 15.115l1.79 1.8 1.41-1.41-1.8-1.79zM20 10.5h3v2h-3zm-8-5c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm0 10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm-1 4h2v2.95h-2zm-7.45-.96l1.41 1.41 1.79-1.8-1.41-1.41z' /></svg>
)

export const OpacityIcon = ({ ...props }): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#000000' {...props}><path d='M24 0H0v24h24V0zm0 0H0v24h24V0zM0 24h24V0H0v24z' fill='none' /><path d='M17.66 8L12 2.35 6.34 8C4.78 9.56 4 11.64 4 13.64s.78 4.11 2.34 5.67 3.61 2.35 5.66 2.35 4.1-.79 5.66-2.35S20 15.64 20 13.64 19.22 9.56 17.66 8zM6 14c.01-2 .62-3.27 1.76-4.4L12 5.27l4.24 4.38C17.38 10.77 17.99 12 18 14H6z' /></svg>
)

export const ThermostatIcon = ({ ...props }): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#000000' {...props}><path d='M0 0h24v24H0V0z' fill='none' /><path d='M15 13V5c0-1.66-1.34-3-3-3S9 3.34 9 5v8c-1.21.91-2 2.37-2 4 0 2.76 2.24 5 5 5s5-2.24 5-5c0-1.63-.79-3.09-2-4zm-4-8c0-.55.45-1 1-1s1 .45 1 1h-1v1h1v2h-1v1h1v2h-2V5z' /></svg>
)

export const SearchIcon = ({ ...props }): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#000000' {...props}><path d='M0 0h24v24H0z' fill='none' /><path d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z' /></svg>
)

export const HistoryIcon = ({ ...props }): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#000000' {...props}><path d='M0 0h24v24H0z' fill='none' /><path d='M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z' /></svg>
)

export const HomePageIcon = ({ ...props }): JSX.Element => (
  <svg width='28' height='26' viewBox='0 0 20 18' fill='white' xmlns='http://www.w3.org/2000/svg' {...props}> <path d='M7.83283 17.5834V12.1667H12.1662V17.5834C12.1662 18.1792 12.6537 18.6667 13.2495 18.6667H16.4995C17.0953 18.6667 17.5828 18.1792 17.5828 17.5834V10H19.4245C19.9228 10 20.1612 9.38253 19.782 9.05753L10.7253 0.900029C10.3137 0.531696 9.68533 0.531696 9.27367 0.900029L0.216996 9.05753C-0.151337 9.38253 0.0761625 10 0.574496 10H2.41616V17.5834C2.41616 18.1792 2.90366 18.6667 3.4995 18.6667H6.7495C7.34533 18.6667 7.83283 18.1792 7.83283 17.5834Z' /></svg>
)

export const DicoverIcon = ({ ...props }): JSX.Element => (
  <svg width='32' height='38' viewBox='0 0 23 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}><path d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z' {...props} strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' /><path d='M16 8L13.5 13.5L8 16L10.5 10.5L16 8Z' stroke='white' {...props} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' /></svg>
)