import { useState, useRef } from 'react';
import { PaginatedGenerator, PaginatedResponse } from 'ymca/dtos/common.dto';

export type LoadItemGeneratorResponse<T> = {
  data: T[];
  count: number;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  loadingMore: boolean;
  error: Error | null;
  hasMore: boolean;
  firstFetch: () => void;
  next: () => void;
  incrementCount: () => void;
  decrementCount: () => void;
}

export function useLoadItems<T>(generator: any): LoadItemGeneratorResponse<T> {

  const [data, setData] = useState<T[]>([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [hasMore, setHasMore] = useState<boolean>(false);

  const generatorRef = useRef<any>();

  const firstFetch = async () => {
    setLoading(true);
    generatorRef.current = await generator;
    generatorRef.current.next()
      .then((response: any) => {
        if (response.done) {
          setHasMore(false);
        }
        if (!response.done) {
          setData(response.value.data);
          setCount(response.value.count);
          setHasMore(true);
        }
        setLoading(false);
      })
      .catch((error: any) => {
        setError(error);
        setLoading(false);
      })
  };

  const next = () => {
    if (loading) return;
    if (!hasMore) return;
    setLoadingMore(true);
    generatorRef.current.next()
      .then((response: any) => {
        if (response.done) {
          setHasMore(false);
        }
        if (!response.done) {
          setData(response.value.data);
          if (response.value.count !== count) setCount(response.value.count);
          setHasMore(true);
        }
        setLoadingMore(false);
      })
      .catch((error: any) => {
        setError(error);
        setLoadingMore(false);
      })
  }

  const incrementCount = () => {
    setCount((prev) => prev + 1);
  }

  const decrementCount = () => {
    setCount((prev) => prev - 1);
  }

  return { data, count, incrementCount, decrementCount, loading, setLoading, loadingMore, error, hasMore, firstFetch, next }
}