import { useQuery } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { FindPostsDto } from "ymca/dtos/post.dto";
import { DefaultYMCA } from "ymca/ymca";

interface IUseGetCommunityPosts {
  communityId: string;
  userId?: string;
}

export const useGetCommunityPosts = ({
  communityId,
}: IUseGetCommunityPosts) => {
  const generatorRef = useRef<any>();
  const previousData = useRef<any[]>([]);
  const hasNextPageRef = useRef<any>(true);
  const totalItemReturnRef = useRef<any>(0);
  const [page, setPage] = useState(0);

  const resetPage = () => {
    generatorRef.current = null;
    previousData.current = [];
    hasNextPageRef.current = true;
    totalItemReturnRef.current = 0;
    setPage(0);
  };

  const getData = async (props: any) => {
    let generator = undefined;
    if (!generatorRef.current) {
      let dto = new FindPostsDto();
      dto.communityId = communityId;

      generator = await DefaultYMCA.postService.getCommunityPosts(dto);

      generatorRef.current = await generator;
    }

    const data = await generatorRef.current.next();
    const { value, done } = data;

    let newData = value?.data;

    if (!newData || !Array.isArray(newData)) {
      newData = [];
    }

    totalItemReturnRef.current += newData.length;
    if (value?.count > totalItemReturnRef.current) {
      hasNextPageRef.current = true;
    } else {
      hasNextPageRef.current = false;
    }

    if (previousData.current.length > 0) {
      previousData.current = [...previousData.current, ...newData];
    } else {
      previousData.current = newData;
    }

    return {
      data: previousData.current,
      hasMore: hasNextPageRef.current,
    };
  };
  const query = useQuery({
    queryKey: ["posts", { communityId }, page],
    queryFn: getData,
    keepPreviousData: true,
  });

  return {
    ...query,
    fetchNextPage: () => setPage((prev) => ++prev),
    refetch: () => {
      resetPage();
      query.refetch();
    },
  };
};
