import { useEffect, useState } from "react";
import { useLocation, useMatch } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import CacheBuster from "react-cache-buster";
import ReactGA from "react-ga4";
import { CssBaseline } from "@mui/material";

import "swiper/components/pagination/pagination.min.css";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";

import { AuthProvider } from "./context/auth/authContext";
import "./fonts/Anton/Anton.woff2";
import "./fonts/RobotoMono/RobotoMono.woff2";
import "./styles/App.css";
import "./ymca/ymca"; // sets window.YMC to youmeme client

import { CountContextState } from "pages/ContestListPage";

import { getLibrary } from "utils/web3/web3React";
import ErrorBoundary from "ErrorBoundry";
import theme, { themev4 } from "theme/dark";
import { ThemeProviderMui4, ThemeProvider } from "theme/shared/theme";

import AppRoutes from "routes";
import useEagerConnect from "hooks/useEagerConnect";
import Loading from "components/loading/Loading";
import appPackage from "../package.json";
import { DefaultYMCA } from "./ymca/ymca";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AppProvider from "context/AppContext";

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID ?? "";

// Create a client
const queryClient = new QueryClient();

ReactGA.initialize(TRACKING_ID);
const App = (): JSX.Element => {
  const location = useLocation();
  const isContest = !!useMatch("/contest");

  /**
   * TODO we should switch this to a context provider
   * for YMCA as a whole. This should internally call
   * all async methods that are needed to make YMCA
   * ready to serve requests - like setting tombstones,
   * fetching notifications, selecting the best image
   * format, etc.
   */

  const [_, setIsTombstoneSet] = useState(false);

  useEffect(() => {
    DefaultYMCA.lensTombstoneService.setTombstoneIds().then(() => {
      setIsTombstoneSet(true);
    });
  }, []);

  useEffect(() => {
    if (isContest) {
      window.location.pathname = "/contest/ongoing";
    }
  }, [isContest]);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
      title: `${location.pathname} ${location.search}`
    })
  }, [location]);

  useEagerConnect();

  const isProduction = process.env.NODE_ENV === "production";
  return (
    <CacheBuster
      currentVersion={appPackage.version}
      isEnabled={isProduction} // If false, the library is disabled.
      isVerboseMode // If true, the library writes verbose logs to console.
      loadingComponent={<Loading />} // If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} // If public assets are hosted somewhere other than root on your server.
    >
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <Web3ReactProvider getLibrary={getLibrary}>
            <ThemeProviderMui4 theme={themev4}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <AuthProvider>
                  <CountContextState>
                    <AppRoutes />
                  </CountContextState>
                </AuthProvider>
              </ThemeProvider>
            </ThemeProviderMui4>
          </Web3ReactProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </CacheBuster>
  );
};

export default App;
