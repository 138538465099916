import { useReducer } from 'react'

export interface UseMenuResult {
  isOpen: boolean
  close: () => void
  open: (event: React.MouseEvent<HTMLElement>) => void
  anchorEl?: any
}
interface UseMenuReducerState {
  isOpen: boolean
  anchorEl?: HTMLElement
}

const initial = (isOpen: boolean = false): UseMenuReducerState => ({
  isOpen
})
const initialState: UseMenuReducerState = initial()

enum MENU_ENUM {
  CLOSE,
  SHOW
}

interface UseMenuReducerAction {
  type: MENU_ENUM
  payload?: {
    event: React.MouseEvent<HTMLElement>
  }
}

const closeAction = (): UseMenuReducerAction => ({
  type: MENU_ENUM.CLOSE
})
const showAction = (
  event: React.MouseEvent<HTMLElement>
): UseMenuReducerAction => ({
  type: MENU_ENUM.SHOW,
  payload: {
    event
  }
})

const reducer = (
  state: UseMenuReducerState = initialState,
  action: UseMenuReducerAction
) => {
  const { type, payload } = action
  const { event } = payload ?? {}
  switch (type) {
    case MENU_ENUM.CLOSE:
      return { ...state, isOpen: false, anchorEl: undefined }
    case MENU_ENUM.SHOW:
      return { ...state, isOpen: true, anchorEl: event?.currentTarget }
    default:
      return state
  }
}

export const useMenu = (): UseMenuResult => {
  const [{ isOpen, anchorEl }, dispatch] = useReducer(reducer, initial())

  const open = (event: React.MouseEvent<HTMLElement>) =>
    dispatch(showAction(event))
  const close = () => dispatch(closeAction())

  return {
    isOpen,
    close,
    open,
    anchorEl
  }
}

export default useMenu
