import { parseJSON } from 'ymca/ymcaHelpers'

// StoredItemWithTTL<T> represents a value stored in a KVStore.
export class StoredItemWithTTL<T> {
  // The string key under which this item is stored
  public key: string
  // The value that is stored. This would be stringified via
  // JSON.stringify() usually. Other serializations might also
  // be used by the underlying datastore.
  public value: T
  // The time at which this item expires. This is a Unix timestamp.
  // This is 0 if the item never expires.
  public expiresAt: number

  public constructor (
    key: string,
    value: any,
    expiresAt?: number
  ) {
    this.key = key
    this.value = value
    if (expiresAt === undefined) {
      this.expiresAt = new Date().getTime() + 1000 * 60 * 60
    } else {
      this.expiresAt = expiresAt
    }
  }

  public isExpired (): boolean {
    return this.expiresAt !== 0 && this.expiresAt < Date.now()
  }

  public asJSONString (): string {
    return JSON.stringify(this)
  }

  static fromJSONString<T> (
    key: string,
    storedData: string | null
  ): StoredItemWithTTL<T> | null {
    const storedItem = parseJSON(storedData)
    if (storedItem === null) return null
    if (storedItem.key !== key) return null
    if (typeof storedItem.expiresAt !== 'number') return null
    if (storedItem.expiresAt !== 0 && storedItem.expiresAt < Date.now()) return null
    if (storedItem.value === undefined) return null
    return new StoredItemWithTTL<T>(storedItem.key, storedItem.value, storedItem.expiresAt)
  }
}
