import {useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import Header from "components/layout/Header";
import {Image} from 'components/shared/index'
import {Box, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {imagesSvg} from 'constants/images'
import { redirectLinks } from "constants/redirectLinks";
import MetaTags from './MetaTags';
import useWindowSize from "../../hooks/useWindowSize";
import SidebarMobile from "components/organisms/SidebarMobile";

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  },
  gotolink: {
    textDecoration: 'none'
  },
  title: {
    color: '#A701A7',
    fontSize: '20px'
  },
  link: {
    marginTop: '10px',
    font: 'italic normal normal 25px/31px Gotham Rounded',
    color: '#A701A7',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  wrapper: {
    marginTop: '-60px'
  },
  img: {
    marginTop: '-60px',
    width: '400px'
  },
  '@media (max-width: 500px)': {
    img: {
      width: '80%'
    },
    wrapper: {
      width: '80%'
    }
  }
})

const Page404 = (): JSX.Element => {
  const classes = useStyles()

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const [opensidebar, setOpenSidebar] = useState<boolean>(false);
  const location = useLocation()
  const pathname: string = location.pathname

  const onHandleSidebar = (e: any): void => {
    setOpenSidebar(e)
  }

  return (
    <Box>
      <Header
        handleDrawerOpen={setDrawerOpen}
        drawerOpen={drawerOpen}
        handleSidebar={onHandleSidebar}
        openSidebar={opensidebar}
      />
      <SidebarMobile open={opensidebar} handleSidebar={onHandleSidebar} />
      <Box className={classes.content}>
        <MetaTags
          show={true}
          title={`Not found: ${pathname.replace("/404", "")} | YouMeme`}
          description={`The page you're looking for is not here. Go back to the homepage. Page: ${window.location.href}}`}
          url={window.location.href}
        />
        <Image
          alt='not found icon'
          src={`random/${imagesSvg.icon404}`}
          className={classes.img}
        />
        <Box className={classes.wrapper}>
          <Typography variant='h1' className={classes.title}>
            Oops ! The page you are looking for is not available.
          </Typography>
          <Link to={`/${redirectLinks.homePage}`} className={classes.gotolink}>
            <Typography className={classes.link}>Go to Homepage</Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default Page404
