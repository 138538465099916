import {
  ExternalProvider,
  JsonRpcFetchFunc,
  Web3Provider,
} from "@ethersproject/providers";
import { InjectedConnector, NoEthereumProviderError, UserRejectedRequestError as UserRejectedRequestErrorInjected } from "@web3-react/injected-connector";
import {
  WalletConnectConnector,
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect
} from '@web3-react/walletconnect-connector'
import { getRpcUrls } from "./getRpcUrl";
import { UnsupportedChainIdError } from "@web3-react/core";
import { DefaultYMCA } from 'ymca/ymca'

const POLLING_INTERVAL = 8000

export enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect'
}

export type WalletAddress = string

const supportedChainIds: number[] = [DefaultYMCA.lensService.lensChainId]

export const injected = new InjectedConnector({ supportedChainIds });
export const walletconnect = new WalletConnectConnector({
  qrcode: true,
  rpc: getRpcUrls(),
  bridge: "https://bridge.walletconnect.org/",
  supportedChainIds
});

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
};

export const getLibrary = (
  provider: ExternalProvider | JsonRpcFetchFunc
): Web3Provider => {
  const library = new Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;
  return library;
};

export const getErrorMessage = (error: Error): string => {
  if (error instanceof NoEthereumProviderError) {
    return 'No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.'
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network."
  } else if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorWalletConnect
  ) {
    return 'Please authorize this website to access your Ethereum account.'
  } else {
    console.error(error)
    return 'An unknown error occurred. Check the console for more details.'
  }
}
