import { Box } from "@mui/material";
import AtomLoading from "components/atoms/AtomLoading";
import { EmptyCommunityMemes } from "components/layout/CommunitiesWrapper";

import PublicationList from "components/memePublication/PublicationList/PublicationList";
import { useGetCommunityPosts } from "hooks/api-hooks/usePost";
import useInfiniteScroll from "hooks/useInfiniteScroll/useInfiniteScroll";
import CommunityCreateMeme from "../CommunityCreateMeme";
import { Community } from "ymca/models/community.model";
import config from "utils/config";
import { redirectLinks } from "constants/redirectLinks";

interface CommunityMemesProps {
  community: Community;
  canCreateMeme: boolean;
}
const CommunityMemes = ({ community, canCreateMeme }: CommunityMemesProps) => {
  const { isLoading, data, error, fetchNextPage, isFetching, refetch } =
    useGetCommunityPosts({
      communityId: community.id,
    });

  const hasNextPage = Boolean(data?.hasMore);
  const [infiniteRef] = useInfiniteScroll({
    loading: isFetching!,
    hasNextPage,
    onLoadMore: () => fetchNextPage?.(),
    rootMargin: "10px",
  });

  if (isLoading) {
    return (
      <Box display='flex' justifyContent='center'>
        <AtomLoading />
      </Box>
    );
  }

  if (error) {
    return (
      <Box component='section'>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Box>
    );
  }

  const memes = data?.data ?? [];

  return (
    <Box component='section'>
      {memes.length === 0 && <EmptyCommunityMemes />}
      {memes.length > 0 && (
        <>
          <PublicationList
            publications={memes}
            width={240}
            onDeletePublication={refetch}
            eventProps={{ event: false, setEvent: () => {} }}
            disableEditMeme
            copyLinkBasePath={`${config.deploymentUrl}/${redirectLinks.communities}/${community.slug}`}
            startLoadingMoreIndex={10}
            hasMore={hasNextPage && !isFetching}
            onLoadMore={fetchNextPage}
            isFetching={isFetching}
          />
          {!isLoading && (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection='column'
            >
              {hasNextPage && !isFetching && <Box ref={infiniteRef} />}
              {isFetching && (
                <Box display='flex' justifyContent='center'>
                  <AtomLoading />
                </Box>
              )}
            </Box>
          )}
        </>
      )}

      {canCreateMeme && (
        <CommunityCreateMeme
          communityId={community.id}
          refetchMemes={refetch}
        />
      )}
    </Box>
  );
};

export default CommunityMemes;
