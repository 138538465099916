// Register the service worker service-worker.js
import config from './utils/config';

export function registerSW(): void {
    const publicUrl = new URL(config.deploymentUrl, window.location.href);
    if (publicUrl.origin !== window.location.origin) return;
    window.addEventListener('load', async () => {
        if ('serviceWorker' in navigator) {
            const swUrl = `${config.deploymentUrl}/sw.js`;
            const registration: ServiceWorkerRegistration = await navigator.serviceWorker.register(swUrl);
            console.log('Service worker is registering: ', registration);
            registration.addEventListener('updatefound', async () => {
                let installingWorker = await registration.installing;
                console.log('A new service worker is being installed:', installingWorker);
            });
        } else {
            console.log('Service workers are not supported.');
        }
    });
}

export function unregisterSW(): void {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister().then((success: boolean) => {
        if (success) console.log("Service worker unregistered successfully");
        else console.log("Service worker unregistration failed");
      });
    });
  }
}