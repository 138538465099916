import { Snackbar } from "@mui/material";

import { usePublicationContext } from "context/PublicationContext";

const BookmarkSnackbar = (): JSX.Element => {
  // publication context
  const { bookmark } = usePublicationContext();
  const { isBookmarked, isShowing, hideShowUpdate } = bookmark;

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={isShowing}
      autoHideDuration={2000}
      onClose={hideShowUpdate}
      message={isBookmarked ? "Successfully saved" : "Successfully unsaved"}
    />
  );
};

export default BookmarkSnackbar;
