import { Box, Button, InputBase, Typography ,useMediaQuery  } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useState } from 'react'
import Alert from '@material-ui/lab/Alert'
import ReactLoading from 'react-loading'
import { getImageBase64FromUrl } from '../../utils/utils'
import config from '../../utils/config'
import { checkResolution } from 'utils/create-meme'

const CORS_API_URL = config.corsProxy
const useStyles = makeStyles({
  urlImageTxt: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '24px',
    marginBottom: 14,
    color: '#C2C9D4'
  },
  searchInput: {
    width: '100%',
    height: 45,
    borderRadius: 5,
    backgroundColor: '#1A1E28',
    fontSize: 14,
    padding: '0 15px',
    color: 'white',
    '& input': {
      '&::placeholder': {
        color: '#546277',
        opacity: 1
      }
    }
  },
  selectBtn: {
    color: 'white',
    backgroundColor: 'var(--gradient-fall-back)',
    borderRadius: 5,
    height: 44,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    justifyContent: 'center',
    lineHeight: '17px',
    '&:hover': {
      backgroundColor: 'var(--gradient-fall-back)',
      opacity: 0.95
    }
  },
  mobileBtn: {
    display: 'none'
  },
  desktopOnly: {
    display: 'block'
  },
  urlWrapper: {
    width: '100%',
    padding: 15
  },
  tickBox: {
    display: 'none'
  },
  '@media (max-width: 1160px)': {
    urlWrapper: {
      padding: 0
    }
  },
  '@media (max-width: 760px)': {
    searchInput: {
      flexBasis: '100%',
      width: '100%',
      borderRadius: '6.25rem',
      background: 'rgba(255, 255, 255, 0.3)',
      backdropFilter: 'blur(7.5px)',
      color: 'white',
      '& ::placeholder': {
        color: 'white !important',
        zIndex: 11,
        opacity: 1,
        fontWeight: 500
      }
    },
    urlImageTxt: {
      marginTop: 35,
      marginBottom: 20,
      color: '#FFFFFF',
      display: 'none'
    },
    desktopOnly: {
      display: 'none'
    },
    mobileBtn: {
      display: 'block'
    },
    selectBtn: {
      background: '#120717',
      borderRadius: '6.25rem'
    },
    urlWrapper: {
      padding: 0,
      marginTop: '-3rem'
    },
    tickBox: {
      display: 'flex',
      background: '#884BFF',
      borderRadius: 5,
      marginLeft: 6,
      width: 57,
      padding: 8,
      boxSizing: 'border-box',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
})

const ImageByUrl = (props) => {
  const classes = useStyles()
  const [url, setUrl] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [sysError, setsysError] = useState('')
  const { isOpen, navigate } = props
  const isMobile = useMediaQuery('(max-width: 760px)')
  const setURL = (event) => {
    setUrl(event.target.value)
  }

  const processURL = (url) => {
    if (url) {
      setLoading(true)
      getImageBase64FromUrl(CORS_API_URL + url)
        .then((urlData) => {
          checkResolution(urlData)
            .then((res) => {
              if (urlData) {
                const a = {
                  data: urlData,
                  imageResolution: res.data,
                  type: 'notSvg'
                }
                navigate(a)
              }
            })
            .catch((err) => {
              showError(err?.message)
            })
        })
        .catch(() => {
          setLoading(false)
          showError('Failed to load image')
        })
    }
  }

  const showError = (msg) => {
    setsysError(msg)
    setTimeout(() => setsysError(''), 1500)
  }

  if (!isOpen) return null
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      marginTop={isMobile ? '-2rem' : '30px'}
      flexDirection='column'
    >
      <div className='loadingContainer'>
        {isLoading && (
          <>
            <ReactLoading className='loadingIcon' type='balls' color='black' />
          </>
        )}
        {sysError !== '' && (
          <>
            <Alert severity='error'>{sysError}</Alert>
          </>
        )}
      </div>
      <Box className={classes.urlWrapper}>
        <Typography className={classes.urlImageTxt}>
          Paste the image Url below:
        </Typography>
        <Box display='flex'>
          <InputBase
            type='url'
            placeholder='Image Url goes here...'
            onChange={setURL}
            className={classes.searchInput}
            value={url}
          />
        </Box>
        <Box marginTop='30px' className={classes.desktopOnly}>
          <Button
            className={classes.selectBtn}
            onClick={() => {
              processURL(url)
            }}
            disabled={isLoading || url === ''}
          >
            Ready for the next step!
          </Button>
        </Box>
        <Box marginTop='25px' className={classes.mobileBtn}>
          <Button
            className={classes.selectBtn}
            onClick={() => {
              processURL(url)
            }}
          >
            Pasted it
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ImageByUrl
