import {
  styled,
  Alert as MuiAlert,
  AlertProps as MuiAlertProps,
} from "@mui/material";

interface AlertProps extends MuiAlertProps {}

// Reference: https://mui.com/material-ui/api/alert/#css
const Alert = styled(MuiAlert, {
  name: "Alert",
})<AlertProps>(({ theme }) => ({
  "&.MuiAlert-filledError": {
    backgroundColor: theme.palette.error.main,
  },
  "&.MuiAlert-filledInfo": {
    backgroundColor: theme.palette.info.main,
  },

  // @TODO: continue adding global styles
}));

export default Alert;
