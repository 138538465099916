import { makeStyles } from '@material-ui/core/styles'
import colors from 'theme/dark/colors'

const usePublicationShareStyles = makeStyles((theme: any) => ({
  mobilesharepanel: {
    background: '#161C22',
    display: 'flex',
    flexDirection: 'column',
    padding: 26,
    boxSizing: 'border-box',
    boxShadow: '0px -10px 31px rgba(0, 0, 0, 0.6)',
    borderRadius: '20px 20px 0px 0px',
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    '& >p': {
      color: '#E2E4E9',
      fontWeight: 800,
      fontSize: 20
    },

    '& #copymemetext': {
      color: '#E2E4E9',
      fontWeight: 600,
      fontSize: 14,
      fontFamily: 'inter',
      marginLeft: 7
    }
  },
  mobilesharedash: {
    width: 31,
    height: '4px !important',
    borderRadius: '100px',
    margin: '0px auto',
    background: 'rgba(128, 136, 147, 0.5)',
    border: 0
  },

  imgStyle: {
    width: '20px',
    height: '20px'
  },
  shareiconsbox: {
    marginTop: 30,
    width: '100%',
    '& p': {
      color: '#E2E4E9',
      fontWeight: 500,
      fontSize: 11,
      textTransform: 'capitalize',
      marginTop: -0.5
    }
  },
  share2: {
    display: 'flex',
    marginTop: 20,
    justifyContent: 'space-between',
    marginBottom: 26
  },
  mobilesharecopybutton: {
    width: '100%',
    height: 44,
    background: '#2A3039',
    border: 0,
    borderRadius: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 14
  },
  shareItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    backgroundColor: 'red',

    '& img': {
      borderRadius: 100,
      width: 34,
      margin: 0,
      height: 34
    },
    '& p': {
      fontSize: 14,
      fontWeight: 700,
      color: '#E9F0F9'
    }
  }
}))

export default usePublicationShareStyles
