export class PubSub {
  protected subscribers = new Map<string, Function[]>()

  public publish (topic: string, data: any): void {
    const subscribers = this.subscribers.get(topic)
    if (subscribers != null) {
      for (const subscriber of subscribers) {
        subscriber(data)
      }
    }
  }

  public subscribe (topic: string, callback: Function): void {
    const subscribers = this.subscribers.get(topic)
    if (subscribers != null) {
      subscribers.push(callback)
    } else {
      this.subscribers.set(topic, [callback])
    }
  }

  public unsubscribe (topic: string, callback: Function): void {
    const subscribers = this.subscribers.get(topic)
    if (subscribers != null) {
      const index = subscribers.indexOf(callback)
      if (index > -1) {
        subscribers.splice(index, 1)
      }
    }
  }
}
