import { Alert, Box, Button, Snackbar, Typography } from '@mui/material'
import { Link, useParams } from 'react-router-dom'

import useCommunityDetailStyles from './style'
import PageTemplate from 'components/layout/PageTemplate'
import PageLayout from 'components/layout/PageLayout'
import Icon from 'components/atoms/Icons'
import {
  useGetCommunityBySlugQuery,
  useJoinCommunityMutation
} from 'hooks/api-hooks/useCommunity'
import AtomLoading from 'components/atoms/AtomLoading'
import { formatReadableCount } from 'utils/formatString'
import CommunityKebabMenuDropdown from 'components/organisms/CommunityKebabMenuDropdown'
import useModal from 'hooks/useModal'
import CommunityLeaveModal from 'components/organisms/CommunityLeaveModal'
import useSnackbar from 'hooks/useSnackbar'
import CommunityMemes from 'components/organisms/CommunityMemes'
import { useAuth } from 'context/auth/authContext'
import { MetaTags } from 'components/shared'
import ManageCommunityModal from 'components/organisms/ManageCommunityModal'
import usePopover from 'hooks/usePopover'
import AuthPopover from 'components/organisms/AuthPopover'
import { redirectLinks } from 'constants/redirectLinks'
import { SocialLinks } from 'ymca/models/social-links.model'
import BackgroundImage from 'components/molecules/BackgroundImage'
import getImageResolutions, {
  getImageUrlSourceType
} from 'utils/getImageResolutions'

const CommunityDetailPage = () => {
  const classess = useCommunityDetailStyles()
  const { isAuthenticated } = useAuth()
  const { id: slug } = useParams()

  const {
    isOpen: isManageCommunityOpen,
    close: closeManageCommunity,
    open: openManageCommunity
  } = useModal()

  const {
    isOpen: isJoinSnkbarOpen,
    close: closeJoinSnkBar,
    open: openJoinSnkBar
  } = useSnackbar()

  const {
    isOpen: isJoinErrorSnkbarOpen,
    close: closeJoinErrorSnkbar,
    open: openJoinErrorSnkbar
  } = useSnackbar()

  const { isLoading, data, error, refetch } = useGetCommunityBySlugQuery({
    slug: slug!
  })
  // make an API call to join community
  const mutation = useJoinCommunityMutation({
    onSuccess: () => {
      openJoinSnkBar()
      refetch()
    },
    onError: ({ error }: { error: any }) => {
      openJoinErrorSnkbar()
    },
    data: {
      id: data?.id!
    }
  })

  const handleJoin = () => {
    mutation.mutate({ id: data?.id! })
  }

  const {
    isOpen: isLeaveCommunityOpen,
    open: openLeaveCommunity,
    close: closeLeaveCommunity
  } = useModal()

  const {
    isOpen: isAuthPopoverOpen,
    open: openSigninToContinue,
    close: closeAuthPopover,
    anchorEl: authPopoverAnchorEl
  } = usePopover()

  const canLeave = data?.userRoleInCommunity === 'member'
  const canManage =
    data?.userRoleInCommunity === 'admin' ||
    data?.userRoleInCommunity === 'moderator'

  const canJoin = !canLeave && !canManage

  const socialLinks = data?.socialLinks as SocialLinks
  const { twitter, website, telegram, facebook, instagram } = socialLinks ?? {}

  const canShowSocialSeparator =
    twitter || website || telegram || facebook || instagram

  const { x3, x4, fallback } = getImageResolutions(data?.background!)

  return (
    <PageTemplate>
      <PageLayout marginTop={0}>
        <Box component='section' className={classess.wrapper}>
          <MetaTags
            show={window.location.href.includes(
              `/${redirectLinks.communities}/${slug}`
            )}
            title={`Community: ${slug} | YouMeme`}
            description='The World’s First Meme-to-Earn Social Network. Select a meme template, add text, images, stickers and save your meme. Your daily dose of meme is here.'
            thumbnail={data?.avatar?.jpegUrl}
            url={`${process.env.REACT_APP_DEPLOYMENT_URL}/${redirectLinks.communities}/${slug}`}
          />
          <Box component='section' className={classess.header}>
            <BackgroundImage
              src={fallback}
              pictureSources={[
                {
                  srcSet: `${x4} 1x, ${x3} 2x`,
                  type: getImageUrlSourceType(x3)
                }
              ]}
              className={classess.headerBanner}
            >
              <Link
                className={classess.headerGoBackBtn}
                to={`/${redirectLinks.communities}`}
              >
                <Icon icon='arrow-left' fontSize='2rem' />
              </Link>
            </BackgroundImage>
            {/* <Box
              component='section'
              className={classess.headerBanner}
              style={{
                backgroundImage: `url(${data?.background?.jpegUrl})`,
              }}
              position={"relative"}
            >
              <Link
                className={classess.headerGoBackBtn}
                to={`/${redirectLinks.communities}`}
              >
                <Icon icon='arrow-left' fontSize='2rem' />
              </Link>
            </Box> */}
            <Box className={classess.headerContentWrapper}>
              {isLoading ? (
                <Box display='flex' justifyContent='center'>
                  <AtomLoading />
                </Box>
              ) : (
                <>
                  <Box
                    component='section'
                    className={classess.headerProfileActions}
                  >
                    <Box
                      component='section'
                      className={classess.headerProfileActionsLeftSection}
                    >
                      <Typography className={classess.headerContentTitle}>
                        {data?.name}
                      </Typography>
                    </Box>
                    {data && isAuthenticated && (
                      <Box
                        component='section'
                        className={classess.headerProfileActionsRightSection}
                      >
                        {canLeave && (
                          <Button
                            variant='outlined'
                            color='warning'
                            className={classess.headerProfileActionsLeaveBtn}
                            onClick={openLeaveCommunity}
                          >
                            Leave
                          </Button>
                        )}
                        {canManage && (
                          <>
                            <Button
                              className={classess.headerProfileActionsJoinBtn}
                              onClick={openManageCommunity}
                            >
                              <Icon icon='manage-community' />
                              Manage
                            </Button>
                            <CommunityKebabMenuDropdown community={data} />
                          </>
                        )}
                        {canJoin && (
                          <Button
                            className={classess.headerProfileActionsJoinBtn}
                            onClick={handleJoin}
                          >
                            Join
                            {mutation.isLoading && <AtomLoading />}
                          </Button>
                        )}
                      </Box>
                    )}
                    {data && !isAuthenticated && (
                      <Box
                        component='section'
                        className={classess.headerProfileActionsRightSection}
                      >
                        <Button
                          className={classess.headerProfileActionsJoinBtn}
                          onClick={openSigninToContinue}
                        >
                          Join
                          {mutation.isLoading && <AtomLoading />}
                        </Button>
                        <AuthPopover
                          isOpen={isAuthPopoverOpen}
                          onClose={closeAuthPopover}
                          anchorEl={authPopoverAnchorEl}
                          id={data.id}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box component='section' className={classess.headerContent}>
                    <Typography className={classess.headerContentDescription}>
                      {data?.description}
                    </Typography>
                    <Box className={classess.headerContentMembersSection}>
                      <Link
                        className={classess.headerContentMembersCount}
                        to='members'
                      >
                        {formatReadableCount(data?.nMembers ?? 0)} Member
                        {data?.nMembers && data?.nMembers > 1 && 's'}
                      </Link>

                      <Box className={classess.headerProfileActionsSocials}>
                        {canShowSocialSeparator && (
                          <Box className={classess.headerContentDotSeparator} />
                        )}
                        {data?.socialLinks?.twitter && (
                          <Link
                            to={data.socialLinks.twitter}
                            target='__blank'
                            className={
                              classess.headerProfileActionsSocialsLinks
                            }
                          >
                            <Icon icon='social-twitter' />
                          </Link>
                        )}
                        {data?.socialLinks?.telegram && (
                          <Link
                            to={data.socialLinks.telegram}
                            target='__blank'
                            className={`${classess.headerProfileActionsSocialsLinks}`}
                          >
                            <Icon icon='social-telegram' />
                          </Link>
                        )}

                        {data?.socialLinks?.facebook && (
                          <Link
                            to={data.socialLinks.facebook}
                            target='__blank'
                            className={`${classess.headerProfileActionsSocialsLinks}`}
                          >
                            <Icon icon='social-facebook' />
                          </Link>
                        )}
                        {data?.socialLinks?.instagram && (
                          <Link
                            to={data.socialLinks.instagram}
                            target='__blank'
                            className={`${classess.headerProfileActionsSocialsLinks}`}
                          >
                            <Icon icon='social-instagram' />
                          </Link>
                        )}
                        {data?.socialLinks?.website && (
                          <Link
                            to={data.socialLinks.website}
                            target='__blank'
                            className={
                              classess.headerProfileActionsSocialsLinks
                            }
                          >
                            <Icon icon='social-website' />
                          </Link>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
          {data && (
            <Box component='section' className={classess.main}>
              {/* <Box component='section' className={classess.mainAdmin}>
                <Typography className={classess.mainAdminTitle}>
                  Admin
                </Typography>
                <Box className={classess.mainAdminCard}>
                  <Avatar
                    className={classess.mainAdminCardAvatar}
                    src={data?.owner?.avatar?.jpegUrl}
                  />
                  <Box className={classess.mainAdminCardContent}>
                    <Box className={classess.mainAdminCardContentTop}>
                      <Typography
                        className={classess.mainAdminCardContentDisplayName}
                      >
                        {data?.owner?.displayName}
                      </Typography>
                      <Icon icon='verified' />
                    </Box>
                    <Typography
                      className={classess.mainAdminCardContentUsername}
                    >
                      @{data?.owner?.username}
                    </Typography>
                  </Box>
                </Box>
              </Box> */}
              <Box component='section' className={classess.mainBody}>
                <CommunityMemes
                  community={data}
                  canCreateMeme={canManage || canLeave}
                />
              </Box>
              {isManageCommunityOpen && (
                <ManageCommunityModal
                  isOpen={isManageCommunityOpen}
                  close={closeManageCommunity}
                  onSuccess={refetch}
                  community={data}
                />
              )}
            </Box>
          )}
        </Box>
        {data?.id && (
          <CommunityLeaveModal
            isOpen={isLeaveCommunityOpen}
            close={closeLeaveCommunity}
            avatarSrc={data?.avatar?.jpegUrl}
            community={data}
            refetchQuery={refetch}
          />
        )}
        <Snackbar
          open={isJoinSnkbarOpen}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={2000}
          onClose={closeJoinSnkBar}
        >
          <Alert>Joined community Successfully</Alert>
        </Snackbar>
        <Snackbar
          open={isJoinErrorSnkbarOpen}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={2000}
          onClose={closeJoinErrorSnkbar}
        >
          <Alert severity='error'>Failed to join community</Alert>
        </Snackbar>
      </PageLayout>
    </PageTemplate>
  )
}

export default CommunityDetailPage
