import { Box, Typography } from '@mui/material'
import Icon from 'components/atoms/Icons'
import useRewardEmptyStyles from './style'
import { Link } from 'react-router-dom'

export const RewardsEmptyNonCurrently = () => {
  const classes = useRewardEmptyStyles()
  return (
    <Box className={classes.wrapper}>
      <Icon icon='events-no-reward-current' fontSize='12rem' />
      <Box>
        <Typography className={classes.header}>Keep at it!</Typography>
        <Typography className={classes.description}>
          Your rewards are waiting for you, keep going
        </Typography>
      </Box>
      <Link to='../ongoing' className={classes.browseLink}>
        Browse Contests
      </Link>
    </Box>
  )
}
// export const RewardsEmptyNotParticipated = () => {}
