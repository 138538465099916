const removeEmptyObjectValues = (obj: Record<string, string>) => {
  for (const key in obj) {
    if (obj[key] === "") {
      delete obj[key];
    }
  }
  return obj;
};

export default removeEmptyObjectValues;
