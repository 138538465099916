import { makeStyles } from "@material-ui/core/styles";
import colors from "theme/dark/colors";

const useCreateCommunityMemeStyles = makeStyles({
  createMemeBtn: {
    padding: ".75rem",
    borderRadius: "6.25rem",
    border: `1px solid ${colors.primary500}`,
    background: "rgba(51, 64, 92, .8)",
    boxShadow: "0px 4px 8px 0px rgba(14, 20, 34, 0.20)",

    color: "#FFF",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "0.9375rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "1.1875rem",
    letterSpacing: "-0.04375rem",

    position: "fixed",
    bottom: "10%",
  },
});

export default useCreateCommunityMemeStyles;
