import React, { useEffect, useRef, useState } from 'react'
import { Snackbar } from '@mui/material'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import colors from 'theme/dark/colors'
import Alert from 'components/atoms/Alert'
import { borderRadius } from 'constants/variables'
import { DefaultYMCA } from 'ymca/ymca'
import { useComments } from 'hooks/useComments/useComments'
import { useAuth } from 'context/auth/authContext'
import { User } from 'ymca/models/user.model'
import { Post as PostModel } from 'ymca/models/post.model'
import PublicationAddComment from './add-comment'
import PublicationCommentList from './comment-list'

const useStyles = makeStyles((theme: any) => ({
  form_wrapper: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  form: {
    display: 'flex',
    flexDirection: 'unset',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  commentListContainer: {},
  commentRow: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'flex-start',
    gap: '.5rem'
  },
  userIcon: {
    width: 36,
    height: 36,
    borderRadius,
    backgroundColor: colors.profileImageBackground,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  commentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '.5rem'
  },
  likeIcons: {
    marginRight: 14,
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  search: {
    width: '100%',
    height: 40,
    borderRadius: 100,
    marginLeft: 16,
    marginRight: 10,
    paddingLeft: 20,

    border: '1px solid #2B3853',
    '&::placeholder': {
      color: '#6C7B93',
      opacity: 1,
      marginLeft: 30
    }
  },
  input: {
    fontSize: 14,
    fontWeight: 500,
    color: 'white',
    '&::placeholder': {
      color: '#6C7B93',
      opacity: 1,
      display: 'none !important',
      marginLeft: 30
    }
  },
  postComment: {
    background: 'rgba(41, 54, 82, 0.5)',
    color: '#4F5D79',
    borderRadius: 100,
    fontWeight: 700
  },
  comment: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    width: '100%',
    '& a': {
      color: 'white',
      textDecoration: 'underline'
    }
  },
  commentBox: {
    padding: 10,
    width: '100%',
    background: '#202B42',
    borderRadius: 15
  },
  commentUser: {
    color: '#8B89FF',
    fontSize: 14,
    fontWeight: 800,
    '& span': {
      color: '#8A98B4',
      fontSize: 12,
      fontWeight: 400
    }
  },
  commentMain: {
    color: '#E9F0F9',
    fontSize: 14,
    fontWeight: 600
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-start',
    color: '#8A98B4',
    fontSize: 14,
    fontWeight: 600,
    padding: 0
  },
  '@media (max-width: 750px)': {
    likeIcons: {
      marginRight: 0
    }
  }
}))

export interface PublicationCommentProp {
  post: PostModel
  modal?: boolean
  modalPage?: boolean
  contestModal?: boolean
}

const PublicationComment = ({
  post,
  modal,
  modalPage,
  contestModal
}: PublicationCommentProp): JSX.Element => {
  const classes = useStyles()

  const { isAuthenticated } = useAuth()

  const [commentShow, setCommentShow] = useState<boolean>(false)
  const [, setUserComment] = useState<any>('')

  const [, setSelfData] = useState<User | null>(null)
  const [error, setError] = useState<string | null>(null)

  const useCommentProps = useComments(post.id)
  const { addComment, firstFetch } = useCommentProps

  useEffect(() => {
    const getSelfData = async () => {
      const self = await DefaultYMCA.selfService.getSelf()
      setSelfData(self)
    }
    getSelfData()
  }, [])

  const usePrevious = (value: any): any => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const prevCommentShow = usePrevious(commentShow)

  const showComments = async (): Promise<void> => {
    if (prevCommentShow === undefined || prevCommentShow === commentShow)
      setCommentShow(!commentShow)
  }

  useEffect(() => {
    firstFetch()
  }, [post?.id, isAuthenticated])

  useEffect(() => {
    if (modal === true) showComments()
  }, [modal])

  if (contestModal) return <></>

  return (
    <>
      <Box className={classes.commentContainer}>
        <PublicationAddComment
          post={post}
          contestModal={contestModal}
          addComment={addComment}
        />
        <PublicationCommentList
          post={post}
          contestModal={contestModal}
          useCommentListProps={useCommentProps}
        />
      </Box>
    </>
  )
}

export default PublicationComment
