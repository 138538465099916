import { makeStyles } from "@material-ui/core/styles";
import {
  BREAKPOINT_LAPTOP_SMALL,
  BREAKPOINT_MOBILE_MEDIUM,
  BREAKPOINT_TABLET,
  BREAKPOINT_TABLET_LARGE,
} from "theme/shared/breakpoint";

const useCreateCommunityModalStyles = makeStyles({
  dialog: {
    "backdrop-filter": "blur(15px)",
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "64rem",
      width: "64rem",
    },
  },
  dialogCloseWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "transparent",

    position: "absolute",

    right: "2px",
    top: "2px",

    [`@media (max-width: ${BREAKPOINT_TABLET_LARGE}px)`]: {
      display: "none",
    },
  },
  dialogCloseBtn: {
    width: "2.5rem",
    height: "2.5rem",
    backgroundColor: "#33405C",
  },
  appBar: {
    display: "none",
    background: "rgba(32, 42, 65, 1)",
    boxShadow: "0px 0px 70px 0px rgba(14, 20, 34, 0.40)",

    [`@media (max-width: ${BREAKPOINT_MOBILE_MEDIUM}px)`]: {
      display: "block",
    },
  },
  dialogContent: {
    backgroundColor: "#212C41",

    display: "flex",

    "&.MuiDialogContent-root": {
      padding: "0",
    },
  },
  dialogContentLeft: {
    width: "30rem",
    backgroundImage: "url(/assets/random/community-runway-muscle.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",

    [`@media (max-width: ${BREAKPOINT_LAPTOP_SMALL}px)`]: {
      width: "25rem",
    },

    [`@media (max-width: ${BREAKPOINT_TABLET_LARGE}px)`]: {
      width: "100%",
      height: "100%",
      position: "absolute",
      background:
        "linear-gradient(220deg, rgba(0, 0, 0, 0.00) 0%, #151E31 43%), url(/assets/random/community-runway-muscle.jpg) #414f87 50% / cover no-repeat",
      marginTop: "-1rem",
    },
  },
  dialogContentLeftImage: {},
  dialogContentRight: {
    flex: 1,
    overflowY: "auto",

    [`@media (max-width: ${BREAKPOINT_TABLET_LARGE}px)`]: {
      zIndex: 10,
      "backdrop-filter": "blur(2px)",
    },
  },
  dialogContentTopSection: {
    padding: "2rem 2rem",

    display: "flex",
    flexDirection: "column",
    gap: "1rem",

    [`@media (max-width: ${BREAKPOINT_MOBILE_MEDIUM}px)`]: {
      padding: "1rem",
    },
  },

  dialogContentTopSectionBorderBottom: {
    borderBottom: "2px solid #2A3753",
  },

  dialogContentTopSectionSeparator: {
    height: "1rem",
  },
  dialogContentFileUploader: {
    height: "12rem",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "1rem",

    border: "1px dashed #404C67",
    "border-radius": "0.9375rem",
    "box-shadow": "0px 0px 65px 0px rgba(0, 0, 0, 0.15)",

    cursor: "pointer",
  },
  dialogContentFileUploaderError: {
    borderColor: "red",
  },

  dialogContentFileUploaded: {
    height: "12rem",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",

    border: "1px dashed #404C67",
    "border-radius": "0.9375rem",
    "box-shadow": "0px 0px 65px 0px rgba(0, 0, 0, 0.15)",
  },

  dialogContentFileUploadedActions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: ".25rem",

    padding: ".25rem",
    "border-radius": "0.65rem",
    border: "1px solid #404C67",

    background: "#404C67",
    opacity: 0.75,

    cursor: "pointer",

    // typography font
    "font-family": "Inter",
    "font-size": "0.75rem",
    "font-style": "normal",
    "font-weight": 500,
    "line-height": "1.25rem",
    "letter-spacing": "-0.03125rem",
  },

  dialogContentFormFieldContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  dialogContentFormFieldGridContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridAutoRows: "1fr",
    gap: "1rem",

    [`@media (max-width: ${BREAKPOINT_TABLET_LARGE}px)`]: {
      gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    },

    [`@media (max-width: ${BREAKPOINT_MOBILE_MEDIUM}px)`]: {
      gridTemplateColumns: "1fr",
    },
  },
  dialogContentWizardStepIndicator: {
    color: "#817EFF",
    "font-family": "Inter",
    "font-size": "0.875rem",
    "font-style": "normal",
    "font-weight": 400,
    "line-height": "1.375rem",
    "letter-spacing": "-0.03125rem",

    [`@media (max-width: ${BREAKPOINT_TABLET_LARGE}px)`]: {
      "font-weight": 700,
    },

    [`@media (max-width: ${BREAKPOINT_MOBILE_MEDIUM}px)`]: {
      display: "none",
    },
  },
  dialogContentCommunityTypeSection: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  dialogContentHeaderTitle: {
    color: "#E9F0F9",
    "font-family": "Inter",
    "font-size": "0.1.375rem",
    "font-style": "normal",
    "font-weight": 800,
    "line-height": "normal",
    "letter-spacing": "-0.0625rem",

    [`@media (max-width: ${BREAKPOINT_TABLET_LARGE}px)`]: {
      "font-size": "1.625rem",
    },
  },
  dialogContentBottomSection: {
    padding: "1rem 2rem",
    [`@media (max-width: ${BREAKPOINT_MOBILE_MEDIUM}px)`]: {
      padding: "1rem",
    },
  },
  dialogContentCommunityType: {
    color: "#E9F0F9",
    "font-family": "Inter",
    "font-size": "0.875rem",
    "font-style": "normal",
    "font-weight": 700,
    "line-height": "100%",
    "letter-spacing": "-0.01875rem",
  },
  dialogActions: {
    backgroundColor: "#212C41",
    background:
      "linear-gradient(90deg, rgba(32, 42, 65, 0.90) 0%, rgba(32, 42, 65, 0.00) 100%)",
    boxShadow: "0px 0px 70px 0px rgba(14, 20, 34, 0.40)",

    display: "flex",
    padding: "1rem",

    "&.MuiDialogActions-root": {
      justifyContent: "space-between",
      [`@media (max-width: ${BREAKPOINT_TABLET_LARGE}px)`]: {
        justifyContent: "center",
      },
    },

    [`@media (max-width: ${BREAKPOINT_TABLET_LARGE}px)`]: {
      zIndex: 10,
    },
  },
  dialogActionsBtns: {
    display: "flex",
    gap: ".5rem",
  },
  dialogActionsTypography: {
    color: "rgba(140, 154, 182, 0.90)",
    "font-family": "Inter",
    "font-size": "0.8125rem",
    "font-style": "normal",
    "font-weight": 500,
    "line-height": "1.3125rem",
    "letter-spacing": "-0.01875rem",

    [`@media (max-width: ${BREAKPOINT_TABLET_LARGE}px)`]: {
      display: "none",
    },
  },

  dialogActionsTypographyLink: {
    color: "#817EFF",
  },
  dialogActionsContinueBtn: {
    "border-radius": "6.25rem",
    background: "linear-gradient(65deg, #625FFF 0%, #A55FFF 100%)",
    "box-shadow": "0px 4px 8px 0px rgba(14, 20, 34, 0.20)",

    padding: ".5rem 1.5rem",
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "0.9375rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "1.1875rem",
    letterSpacing: "-0.04375rem",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [`@media (max-width: ${BREAKPOINT_TABLET_LARGE}px)`]: {
      padding: "1rem 2rem",
    },
  },
  dialogActionsCancelBtn: {
    "border-radius": "6.25rem",
    background: "#28344D",
    "box-shadow": "0px 4px 8px 0px rgba(14, 20, 34, 0.20)",

    padding: ".5rem 1rem",
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "0.9375rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "1.1875rem",
    letterSpacing: "-0.04375rem",

    [`@media (max-width: ${BREAKPOINT_TABLET_LARGE}px)`]: {
      padding: "1rem 2rem",
    },

    [`@media (max-width: ${BREAKPOINT_MOBILE_MEDIUM}px)`]: {
      display: "none",
    },
  },
  dialogActionsGoBackBtn: {
    "border-radius": "6.25rem",
    background: "#28344D",
    border: "1px solid #625FFF",
    "box-shadow": "0px 4px 8px 0px rgba(14, 20, 34, 0.20)",

    padding: ".5rem 1rem",
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "0.9375rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "1.1875rem",
    letterSpacing: "-0.04375rem",

    [`@media (max-width: ${BREAKPOINT_TABLET_LARGE}px)`]: {
      padding: "1rem 2rem",
    },
  },
  radioGroup: {
    gap: "1rem",
  },
  radioField: {
    display: "flex",
    justifyContent: "space-between",

    alignItems: "center",

    "&.MuiFormControlLabel-root": {
      "margin-left": "0",
      "margin-right": "0",
    },
  },

  radioFieldLabelTitle: {
    color: "#E9F0F9",
    "font-family": "Inter",
    "font-size": "0.92188rem",
    "font-style": "normal",
    "font-weight": 700,
    "line-height": "100%",
    "letter-spacing": "-0.01875rem",
  },

  radioFieldLabelTitleDisabled: {
    color: "rgba(233, 240, 249, 0.30)",
  },

  radioFieldLabelDescription: {
    color: "#8A98B4",
    "font-family": "Inter",
    "font-size": "0.8125rem",
    "font-style": "normal",
    "font-weight": 500,
    "line-height": "1.25rem",
    "letter-spacing": "-0.03125rem",

    "& span:nth-child(2)": {
      display: "none",
    },

    [`@media (max-width: ${BREAKPOINT_TABLET}px)`]: {
      "& span:nth-child(1)": {
        display: "none",
      },

      "& span:nth-child(2)": {
        display: "block",
      },
    },
  },

  radioFieldLabelDescriptionDisabled: {
    color: "rgba(233, 240, 249, 0.30)",
  },

  radioFieldLabel: {
    display: "flex",
    flexDirection: "column",
  },
  radioFieldLabelHeader: {
    display: "flex",
    gap: ".75rem",
    alignItems: "center",
  },
  radioFieldComingSoon: {
    width: "5.5rem",
    padding: ".2rem",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    // position: "absolute",
    // top: ".5rem",
    // left: "28%",

    "border-radius": " 6.25rem",
    background: "#28344D",
    "box-shadow": "0px 4px 8px 0px rgba(14, 20, 34, 0.20)",
  },
  radioFieldComingSoonText: {
    color: "#817EFF",
    "text-align": "center",
    "font-family": "Inter",
    "font-size": "0.6875rem",
    "font-style": "normal",
    "font-weight": 700,
    "line-height": "1.1875rem" /* 172.727% */,
    "letter-spacing": "-0.01875rem",
  },

  inputLabel: {
    color: "#E9F0F9",
    "font-family": "Inter",
    "font-size": "0.875rem",
    "font-style": "normal",
    "font-weight": 700,
    "line-height": "100%",
    "letter-spacing": "-0.01875rem",
  },
  inputLabelHint: {
    color: "#8A98B4",
    "font-family": "Inter",
    "font-size": "0.875rem",
    "font-style": "normal",
    "font-weight": 500,
    "line-height": "100%",
    "letter-spacing": "-0.01875rem",
  },

  uploadFileTitle: {
    color: "#E9F0F9",
    "font-family": "Inter",
    "font-size": "1rem",
    "font-style": "normal",
    "font-weight": 800,
    "line-height": "normal",
    "letter-spacing": "-0.0375rem",

    "& span": {
      background: "linear-gradient(90deg, #908BFF 0%, #B4A4FF 100%)",
      "background-clip": "text",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
    },
  },
  uploadFileSubtitle: {
    color: "#8A98B4",
    "font-family": "Inter",
    "font-size": "0.8125rem",
    "font-style": "normal",
    "font-weight": 400,
    "line-height": "1.25rem",
    "letter-spacing": "-0.03125rem",
  },
});

export default useCreateCommunityModalStyles;
