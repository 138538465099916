import { Box } from "@mui/material";

import useCommunityMemberItemCardDStyles from "./style";
import { PublicUser } from "ymca/models/user.model";
import useInfiniteScroll from "hooks/useInfiniteScroll/useInfiniteScroll";
import CommunityMemberItemCard from "../CommunityMemberItemCard";
import AtomLoading from "components/atoms/AtomLoading";
import { CommunityRole } from 'ymca/models/community-role.model'
import { Community } from 'ymca/models/community.model'

interface CommunityMemberGridContainerProps {
  members: PublicUser[]
  isFetchingNextPage: boolean
  hasNextPage: boolean
  fetchNextPage: Function
  refetchQuery: Function
  listFilterRole?: CommunityRole
  community: Community
}

const CommunityMemberGridContainer = ({
  members,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
  listFilterRole,
  refetchQuery,
  community
}: CommunityMemberGridContainerProps) => {
  const classes = useCommunityMemberItemCardDStyles()

  const [infiniteRef] = useInfiniteScroll({
    loading: isFetchingNextPage!,
    hasNextPage: hasNextPage!,
    onLoadMore: () => fetchNextPage(),
    rootMargin: '50px'
  })

  return (
    <>
      <Box
        display='grid'
        gridTemplateColumns='repeat(auto-fill, minmax(200px, 1fr))'
        gap='.5rem'
      >
        {members?.map((member: PublicUser) => {
          return (
            <CommunityMemberItemCard
              member={member}
              key={member.id}
              refetchQuery={refetchQuery}
              community={community}
            />
          )
        })}
      </Box>

      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
      >
        {hasNextPage && !isFetchingNextPage && <Box ref={infiniteRef} />}
        {isFetchingNextPage && (
          <Box display='flex' justifyContent='center'>
            <AtomLoading />
          </Box>
        )}
      </Box>
    </>
  )
}

export default CommunityMemberGridContainer;
