import { useState } from "react";
import useShow from "../useShow";

interface IOpenMessage {
  message?: string;
}

export interface IUseAlert {
  isOpen: boolean;
  close: () => void;
  open: (data: IOpenMessage) => void;
  message?: string;
}

const useAlert = (): IUseAlert => {
  const { isShowing: isOpen, show, hide } = useShow();
  const [message, setMessage] = useState<string | undefined>();

  const open = ({ message }: IOpenMessage) => {
    setMessage(message);
    return show();
  };

  const close = () => {
    setMessage(undefined)
    return hide()
  }

  return {
    isOpen,
    close,
    open,
    message
  }
};

export default useAlert;
