export const BREAKPOINT_MOBILE_SMALL = 320;
export const BREAKPOINT_MOBILE_MEDIUM = 400;
export const BREAKPOINT_MOBILE_LARGE = 550;
export const BREAKPOINT_TABLET = 750;
export const BREAKPOINT_TABLET_MEDIUM = 800;
export const BREAKPOINT_TABLET_LARGE = 960;
export const BREAKPOINT_LAPTOP = 1024;
export const BREAKPOINT_LAPTOP_SMALL = 1280;
export const BREAKPOINT_LAPTOP_MEDIUM = 1360;
export const BREAKPOINT_LAPTOP_LARGE = 1440;
export const BREAKPOINT_LAPTOP_LARGER = 1920;
export const BREAKPOINT_4k = 2560;

type BreakpointKeys = "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

export interface BreakPointThemeValueOptions {
  xxs: number;
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
}

export interface BreakPointThemeOptions {
  values: BreakPointThemeValueOptions;
  keys: BreakpointKeys[];
}

const breakpointValues: BreakPointThemeValueOptions = {
  xxs: BREAKPOINT_MOBILE_SMALL,
  xs: BREAKPOINT_MOBILE_MEDIUM,
  sm: BREAKPOINT_MOBILE_LARGE,
  md: BREAKPOINT_TABLET,
  lg: BREAKPOINT_LAPTOP,
  xl: BREAKPOINT_LAPTOP_LARGE,
  xxl: BREAKPOINT_4k,
};

const breakpointKeys: BreakpointKeys[] = Object.keys(
  breakpointValues
) as BreakpointKeys[];

const breakpoints: BreakPointThemeOptions = {
  values: breakpointValues,
  keys: breakpointKeys,
};
export default breakpoints;
