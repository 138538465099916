import { URLSearchParamsCapable } from 'ymca/jsonFetcher';

export class GetTwitterAuthUrlDto implements URLSearchParamsCapable {
  public callbackUrl: string;

  constructor(callbackUrl: string) {
    this.callbackUrl = callbackUrl;
  }

  public toURLSearchParams(): URLSearchParams {
    const params = new URLSearchParams();
    params.append('callbackUrl', this.callbackUrl);
    return params;
  }
}

export type VerifyTwitterAuthDto = {
  oauth_token: string;
  oauth_token_secret: string;
  oauth_verifier: string;
}
