import { makeStyles } from '@material-ui/core/styles'

import colors from 'theme/dark/colors'
import {
  BREAKPOINT_MOBILE_LARGE,
  BREAKPOINT_TABLET_MEDIUM
} from 'theme/shared/breakpoint'

const useMemeCamStyles = makeStyles({
  root: {
    fontSize: '1rem',
    display: 'flex',
    gap: '1rem',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    height: '79%'
  },
  dragwrapper: {
    background: 'transparent',
    boxSizing: 'border-box',
    borderRadius: 5,
    flexBasis: '56.02%',
    height: '56.02%',
    fontSize: '1em',
    marginBottom: 12,
    border: '2px dashed rgba(138, 152, 180, 0.5)',
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    opacity: 0,
    animation: '$appear 200ms 500ms ease both',
    flexGrow: 1,
    cursor: 'pointer'
  },

  dragged: {
    backgroundColor: '#f0f8ff'
  },

  dragTitle: {
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '0.8135rem',
    lineHeight: '1rem',
    pointerEvents: 'none'
  },
  dragFormat: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.8135rem',
    lineHeight: '1rem',
    pointerEvents: 'none',
    marginTop: '10px'
  },
  dragTxtOne: {
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '1.25rem',
    lineHeight: '1.5125rem',
    pointerEvents: 'none',
    color: colors.gradientPurpleLight,
    marginTop: '0.6rem',
    cursor: 'pointer'
  },
  dragTxtTwo: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    color: '#BA98B4',
    pointerEvents: 'none'
  },
  memeCamButton: {
    padding: 10,
    height: '3rem',
    background: colors.darkBlueActive,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    textAlign: 'justify',
    cursor: 'pointer',
    transition: 'transform 300ms ease, opacity 200ms ease',
    position: 'relative',
    fontSize: '0.875em',
    animation:
      '$riseUp var(--delay) 200ms ease-in-out both running, gradient 10s ease infinite paused',
    '&:hover': {
      background: colors.purpleGradient,
      backgroundSize: '400% 400%',
      animationPlayState: 'paused, running'
    }
  },
  '@keyframes riseUp': {
    from: {
      opacity: 0,
      transform: 'translateY(3em)'
    },

    to: {
      opacity: 1,
      transform: 'translateY(0)'
    }
  },
  memeCamButtonTitle: {
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '1.5em',
    fontSize: '1.25rem'
  },
  memeCamFileUploadWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  memeCamFooter: {
    [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
      backgroundColor: '#09000b',
      position: 'fixed',
      padding: '.5rem',
      bottom: 0,
      width: '100%',
      left: 0
    }
  },
  memeCamFooterBottonGenerateWrapper: {
    display: 'flex',
    width: '100%',
    marginTop: '1.5rem',
    gap: '.5rem',
    [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
      display: 'grid',
      gridTemplateAreas: `"resetBtn regenerateBtn attemptCountInfo"`,
      gridTemplateColumns: 'repeat(auto-fit, minmax(11em, 1fr))',
      marginTop: 'unset'
    },

    [`@media (max-width: ${BREAKPOINT_MOBILE_LARGE}px)`]: {
      gridTemplateAreas: `
      "resetBtn regenerateBtn" 
      "attemptCountInfo attemptCountInfo"
      `
    }
  },
  memeCamGridWrapper: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
    gridAutoRows: '230px',
    gap: '.5rem',
    padding: '.3rem',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 6,
      borderRadius: 20,
      backgroundColor: '#0E1017',
      cursor: 'pointer'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colors.memeGenBlue50 + ' !important',
      borderRadius: 20,
      width: 6
    },

    [`@media (max-width: ${BREAKPOINT_MOBILE_LARGE}px)`]: {
      padding: '0.2rem ',
      marginBottom: '3rem',
      gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
      gridAutoRows: '220px'
    }
  },

  memeCamText: {
    margin: '0 .4rem .75rem .4rem',
    fontWeight: 800,
    fontSize: '1.1rem'
  },

  memeCaption: {
    position: 'absolute',
    fontFamily: 'Anton',
    fontSize: '1rem',
    color: 'white',
    textTransform: 'uppercase',
    textShadow:
      'rgb(0, 0, 0) -1px -1px 1px, rgb(0, 0, 0) 1px -1px 1px, rgb(0, 0, 0) -1px 1px 1px, rgb(0, 0, 0) -1px -1px 1px',
    textAlign: 'center',
    cursor: 'pointer'
  },
  memeCaptionTop: {
    top: 4,
    left: 0,
    width: '100%'
  },
  memeCaptionBottom: {
    bottom: 4,
    left: 0,
    width: '100%'
  },
  memecamItemWrapper: {
    outline: `0px solid ${colors.gradientPurple}`,
    borderRadius: '.5rem',
    '&:hover': {
      outlineWidth: 3
    }
  },
  // Small text, centered vertically, pulled to the right
  attemptsInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    fontSize: '0.75rem',
    color: colors.greyScale500,

    justifyContent: 'flex-end',
    marginTop: '0.5rem',
    width: '100%',
    gridArea: 'attemptCountInfo'
  },

  '@keyframes appear': {
    to: {
      opacity: 1
    }
  },
  button: {
    background: colors.darkBlueActive,
    width: '147px',
    height: '3rem',
    boxShadow: '0px 4px 8px rgba(14, 20, 34, 0.2)',
    borderRadius: '10px',
    color: '#8A98B4',
    letterSpacing: '-0.7px',
    lineHeight: '19px',
    fontSize: '15px',
    fontWeight: 600,
    position: 'relative',
    '&.selected': {
      background: colors.purpleGradient,
      color: colors.white,
      fontWeight: 700
    },
    '&.Mui-disabled': {
      color: colors.gray
    }
  },
  [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
    memeCamButtonTitle: {
      fontSize: '1rem'
    },
    root: {
      flexDirection: 'column',
      justifyContent: 'unset',
      xpadding: '0px !important',
      '& > button': {
        display: 'unset'
      }
    },
    dragwrapper: {
      // flexBasis: '41%',
      height: '75%',
      marginBottom: 60
    },
    button: {
      width: '100%',
      height: '45px',
      borderRadius: '100px',
      boxShadow: 'none',
      fontSize: '14px',
      color: '#E7B8FF',
      border: 'none',
      background: 'none',
      '&.selected': {
        background: colors.white,
        color: colors.black80,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        fontWeight: 700,
        '&.Mui-disabled': {
          background: colors.white,
          opacity: '0.5'
        }
      }
    }
  },
  alert: {
    '&.MuiAlert-standardError': {
      color: 'rgb(84, 18, 18) !important',
      'background-color': 'rgb(250, 234, 234) !important'
    }
  }
})

export default useMemeCamStyles
