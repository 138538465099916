export const MetaMask=(props: any)=>(
  <svg {...props} width="1em" height="1em" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="30" height="30" rx="15" fill="#EAE0D7"/>
<path d="M23.3686 6.25L16.1172 11.6583L17.4582 8.46746L23.3686 6.25Z" fill="#E2761B"/>
<path d="M6.63241 6.25L13.8255 11.7095L12.5501 8.46746L6.63241 6.25ZM20.7635 18.7863L18.8323 21.7576L22.9645 22.8992L24.1524 18.8522L20.7635 18.7863ZM5.86719 18.8522L7.04782 22.8992L11.18 21.7576L9.24875 18.7863L5.86719 18.8522Z" fill="#E4761B"/>
<path d="M10.9405 13.766L9.78906 15.5151L13.8921 15.6981L13.7464 11.2705L10.9405 13.766ZM19.0519 13.766L16.2097 11.2192L16.1149 15.6981L20.2107 15.5151L19.0519 13.766ZM11.1738 21.7576L13.637 20.5501L11.509 18.8815L11.1738 21.7576ZM16.3554 20.5501L18.826 21.7576L18.4835 18.8815L16.3554 20.5501Z" fill="#E4761B"/>
<path d="M18.8319 21.7578L16.3613 20.5503L16.5581 22.1676L16.5363 22.8483L18.8319 21.7578ZM11.1797 21.7578L13.4754 22.8483L13.4608 22.1676L13.643 20.5503L11.1797 21.7578Z" fill="#D7C1B3"/>
<path d="M13.5083 17.8129L11.4531 17.2055L12.9034 16.5396L13.5083 17.8129ZM16.489 17.8129L17.0939 16.5396L18.5515 17.2055L16.489 17.8129Z" fill="#233447"/>
<path d="M11.1735 21.7577L11.5233 18.7864L9.24219 18.8523L11.1735 21.7577ZM18.4759 18.7864L18.8257 21.7577L20.757 18.8523L18.4759 18.7864ZM20.2104 15.5151L16.1146 15.6981L16.4936 17.8131L17.0985 16.5397L18.5561 17.2057L20.2104 15.5151ZM11.4504 17.2057L12.908 16.5397L13.5056 17.8131L13.8918 15.6981L9.78878 15.5151L11.4504 17.2057Z" fill="#CD6116"/>
<path d="M9.78906 15.5151L11.509 18.8816L11.4507 17.2057L9.78906 15.5151ZM18.5563 17.2057L18.4835 18.8816L20.2107 15.5151L18.5563 17.2057ZM13.8921 15.6981L13.5059 17.8131L13.9869 20.3086L14.0962 17.0227L13.8921 15.6981ZM16.1149 15.6981L15.9181 17.0154L16.0056 20.3086L16.4939 17.8131L16.1149 15.6981Z" fill="#E4751F"/>
<path d="M16.4963 17.813L16.008 20.3085L16.3578 20.55L18.4859 18.8815L18.5588 17.2056L16.4963 17.813ZM11.4531 17.2056L11.5114 18.8815L13.6395 20.55L13.9893 20.3085L13.5083 17.813L11.4531 17.2056Z" fill="#F6851B"/>
<path d="M16.5363 22.8482L16.5581 22.1676L16.3759 22.0066H13.6284L13.4608 22.1676L13.4754 22.8482L11.1797 21.7578L11.9814 22.4165L13.6065 23.5508H16.3978L18.0303 22.4165L18.8319 21.7578L16.5363 22.8482Z" fill="#C0AD9E"/>
<path d="M16.3615 20.5501L16.0117 20.3086H13.9929L13.6431 20.5501L13.4609 22.1675L13.6286 22.0064H16.3761L16.5583 22.1675L16.3615 20.5501Z" fill="#161616"/>
<path d="M23.6755 12.0095L24.2949 9.02365L23.3694 6.25L16.3585 11.4753L19.055 13.7659L22.8665 14.8856L23.7119 13.8977L23.3475 13.6342L23.9305 13.1L23.4787 12.7487L24.0617 12.3023L23.6755 12.0095ZM5.71094 9.02365L6.3304 12.0095L5.93686 12.3023L6.51989 12.7487L6.07533 13.1L6.65836 13.6342L6.29396 13.8977L7.13207 14.8856L10.9436 13.7659L13.6401 11.4753L6.62921 6.25L5.71094 9.02365Z" fill="#763D16"/>
<path d="M22.8697 14.8855L19.0582 13.7658L20.217 15.5148L18.4897 18.8813L20.7635 18.852H24.1524L22.8697 14.8855ZM10.9468 13.7658L7.13527 14.8855L5.86719 18.852H9.24875L11.5153 18.8813L9.79534 15.5148L10.9468 13.7658ZM16.1212 15.6978L16.3617 11.4751L17.4694 8.46729H12.5501L13.6433 11.4751L13.8984 15.6978L13.9858 17.0297L13.9931 20.3084H16.0119L16.0264 17.0297L16.1212 15.6978Z" fill="#F6851B"/>
</svg>

)