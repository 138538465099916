import { useEffect, useState } from "react";
import { Box, styled } from "@mui/material";
import { Outlet } from "react-router-dom";

import Sidebar, { EmptySidebarWrapper } from "components/sidebar/Sidebar";
import MobileFooter from "components/mobile/MobileFooter";
import { useUserSelf } from "hooks/useUserSelf";
import SidebarMobile from "components/organisms/SidebarMobile";

import { DashboardUpdateProvider } from "./DashboardTemplate";
import FeedProvider from "context/feed/feedContext";
import {
  BREAKPOINT_LAPTOP,
  BREAKPOINT_LAPTOP_LARGE,
  BREAKPOINT_TABLET_MEDIUM,
} from "theme/shared/breakpoint";
import Header from "./Header";
import AppProvider from "context/AppContext";

const Wrapper = styled(Box, {
  name: "AppTemplateWrapper",
})(({ theme }) => {
  return {
    background: theme.palette.background.default,
    width: "100%",
    paddingBottom: "3rem",

    display: "grid",

    gridTemplateColumns: "1fr",
  };
});

const Content = styled(Box, {
  name: "TemplateContent",
})<{ haveSidebar?: boolean }>(({ theme, haveSidebar }) => ({
  display: "grid",
  gridTemplateColumns: haveSidebar ? "max-content minmax(60rem, 1fr)" : "1fr",
  justifySelf: haveSidebar ? "center" : "",
  maxWidth: haveSidebar ? "80rem" : "100%",

  [`@media (max-width: ${BREAKPOINT_LAPTOP_LARGE}px)`]: {
    gridTemplateColumns: haveSidebar ? "max-content minmax(50rem, 1fr)" : "1fr",
  },

  [`@media (max-width: ${BREAKPOINT_LAPTOP}px)`]: {
    gridTemplateColumns: haveSidebar ? "max-content minmax(45rem, 1fr)" : "1fr",
  },

  [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
    marginTop: 0,
    gridTemplateColumns: "1fr",
    maxWidth: "100%",
    justifySelf: "unset",
  },
}));

const ChildrenContentWrapper = styled(Box, {
  name: "AppTemplateChildrenContentWrapper",
})(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.divider}`,
}));

interface PageTemplateProps {
  children: any;
  haveHeader?: boolean;
  haveSidebar?: boolean;
}

const AppTemplate = ({
  children,
  haveHeader = true,
  haveSidebar = true,
}: PageTemplateProps): JSX.Element => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [opensidebar, setOpenSidebar] = useState<boolean>(false);
  const { selfData, showEthAlert } = useUserSelf();
  const [headerHaveEthAlertContent, setHeaderHaveEthAlertContent] =
    useState<boolean>(Boolean(showEthAlert));

  const onHandleSidebar = (e: any): void => {
    setOpenSidebar(e);
  };

  useEffect(() => {
    setHeaderHaveEthAlertContent(Boolean(showEthAlert));
  }, [showEthAlert]);

  return (
    <DashboardUpdateProvider>
      <Wrapper>
        <FeedProvider>
          <AppProvider>
            {opensidebar && (
              <SidebarMobile
                open={opensidebar}
                handleSidebar={onHandleSidebar}
              />
            )}
            {haveHeader && (
              <Header
                handleDrawerOpen={setDrawerOpen}
                drawerOpen={drawerOpen}
                handleSidebar={onHandleSidebar}
                openSidebar={opensidebar}
                onDismissEthAlertHeader={() =>
                  setHeaderHaveEthAlertContent(false)
                }
                // @TODO: Find a way to make the Header available to other components
              />
            )}
            <Content haveSidebar={haveSidebar}>
              {haveSidebar && (
                <>
                  <Sidebar
                    headerHaveEthAlertContent={headerHaveEthAlertContent}
                  />
                  <EmptySidebarWrapper />
                </>
              )}
              <ChildrenContentWrapper>
                <Outlet />
                {children}
              </ChildrenContentWrapper>
            </Content>
            <MobileFooter />
          </AppProvider>
        </FeedProvider>
      </Wrapper>
    </DashboardUpdateProvider>
  )
};

export default AppTemplate;
