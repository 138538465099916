import { makeStyles } from "@material-ui/styles";

import { getAssetsPath } from "utils/path";
import { imagesSvg } from "constants/images";
import colors from "theme/dark/colors";
import {
  BREAKPOINT_MOBILE_LARGE,
  BREAKPOINT_TABLET_LARGE,
  BREAKPOINT_TABLET_MEDIUM,
} from "theme/shared/breakpoint";

const useMemeTemplateStyles = makeStyles({
  memeTemplate: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",

    width: "100%",

    [`@media (max-height: 700px) and (min-width: ${BREAKPOINT_TABLET_LARGE}px)`]:
      {
        height: "20.5rem",
      },
  },
  templatesWrapper: {
    overflowY: "scroll",
    paddingRight: ".25rem",

    "&::-webkit-scrollbar": {
      width: "5px",
    },

    "&::-webkit-scrollbar-track": {
      "box-shadow": "inset 0 0 6px rgba(0, 0, 0, 0.3)",
    },

    "&::-webkit-scrollbar-thumb": {
      "background-color": colors.primary500,
      outline: `1px solid ${colors.primary500}`,
      borderRadius: "5px",
    },
  },
  templateGridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(130px, 1fr))",
    gridAutoRows: "8rem",
    gap: ".5rem",
    [`@media (max-width: ${BREAKPOINT_MOBILE_LARGE}px)`]: {
      gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
    },

    // overflowY: "hidden",
  },
  templateGridItem: {
    height: "100%",
    width: "100%",

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      display: "block",
      "border-radius": "10px",
      "backdrop-filter": "brightness(110%)",

      "&:hover": {
        border: "2px solid #625FFF",
      },
    },
  },
  searchWrapper: {},
  searchForm: {
    height: "3rem",
    fontSize: "0.875rem",
    borderRadius: 7,
    display: "flex",
    gap: "1rem",
    width: "100%",
  },

  searchBar: {
    flex: 1,
    fontSize: "1em",
    height: "100%",
    borderRadius: "inherit",
    background: colors.darkBlue50,
    paddingLeft: "calc(1.18em + 24.5px)",
    color: "#919AA5",
    fontWeight: 600,
    backgroundPosition: "1.18em center",
    lineHeight: "1.714em",
    backgroundImage: `url(${getAssetsPath(
      `checkIcons/${imagesSvg.lookingGlass}`
    )}))`,
    backgroundRepeat: "no-repeat",
    border: "0px solid var(--gradient-fall-back)",
    transition: "border-width 100ms ease, filter 100ms",
    boxSizing: "border-box",
    flexBasis: "80%",

    [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
      flexBasis: "100%",
      width: "100%",
      fontSize: "14px",
      borderRadius: "100px",
      background: "rgba(255, 255, 255, 0.3)",
      backdropFilter: "blur(7.5px)",
      color: "white",
    },
  },

  tabTitle: {
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "1.5em",
    marginRight: "1.5em",
  },
  tabDesc: {
    fontStyle: "normal",
    fontWeight: 500,
    color: "#546277",
  },

  ButtonLabel: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: "-0.6rem",
    top: "-0.6rem",
    fontSize: "0.7rem",
    borderRadius: "0.4rem",

    color: colors.skyBlue,
    background: colors.darkBlueActive,
    padding: "0.07rem 0.6rem",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backdropFilter: "blur(50px)",
  },
  "@media (max-width: 1160px)": {
    tabTitle: {
      marginRight: "2em",
    },

    uploadBtn: {
      marginTop: 87,
      height: 40,
    },
  },
  memes: {
    padding: "padding: 1rem 0 !important",
    "& img": {
      borderRadius: "0.938rem",
      width: "100%",
      height: "auto",
      marginBottom: 10,
    },
  },
  [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
    memes: {
      "& img": {
        borderRadius: "0.938rem",
        width: "100%",
        height: "auto",
        marginBottom: 10,
      },
    },

    additionalText: {
      fontSize: "18px",
      fontWeight: 800,
      marginBottom: "16px",
    },
    tabBox: {
      justifyContent: "flex-end",
      padding: "2rem",
      background: "rgba(18, 7, 23, 0.9)",
      backdropFilter: "blur(15px)",
      borderRadius: "20px 20px 0px 0px",
      position: "absolute",
      bottom: 0,
      width: "100%",
      gap: "10px",
    },
    tabItem: {
      height: "107px",
      background: "linear-gradient(90deg, #DC01FF 0%, #7402FF 81.25%);",
      backdropFilter: "blur(15px)",
      borderRadius: "20px",
      display: "flex",
      justifyContent: "space-between",
      padding: "30px",
      "& img": {
        position: "absolute",
        right: 0,
      },
      "& span": {
        color: "rgba(255, 255, 255, 0.7)",
        fontWeight: 800,
        fontSize: "11px",
      },
      "& i": {
        opacity: 1,
      },
    },
    tabTitle: {
      fontWeight: 800,
      fontSize: "20px",
      lineHeight: "24px",
    },
    root: {
      flexDirection: "column",
      justifyContent: "unset",
      xpadding: "0px !important",
      "& > button": {
        display: "unset",
      },
    },
    randomDesktop: {
      display: "none",
    },
    mostUsed: {
      display: "none",
    },
  },
});

export default useMemeTemplateStyles;
