export 
const ArrowBack = (props: any) => (
  <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.6654 17C23.2176 17 23.6654 16.5523 23.6654 16C23.6654 15.4477 23.2176 15 22.6654 15L22.6654 17ZM9.33203 15C8.77975 15 8.33203 15.4477 8.33203 16C8.33203 16.5523 8.77975 17 9.33203 17L9.33203 15ZM22.6654 15L9.33203 15L9.33203 17L22.6654 17L22.6654 15Z"
                fill="white"
              />
              <path
                d="M13.9583 22.0398C14.3488 22.4303 14.9819 22.4303 15.3725 22.0398C15.763 21.6493 15.763 21.0161 15.3725 20.6256L13.9583 22.0398ZM9.33203 15.9993L8.62492 15.2922C8.43739 15.4798 8.33203 15.7341 8.33203 15.9993C8.33203 16.2646 8.43739 16.5189 8.62492 16.7065L9.33203 15.9993ZM15.3725 11.3731C15.763 10.9826 15.763 10.3494 15.3725 9.95891C14.9819 9.56838 14.3488 9.56838 13.9583 9.95891L15.3725 11.3731ZM15.3725 20.6256L10.0391 15.2922L8.62492 16.7065L13.9583 22.0398L15.3725 20.6256ZM10.0391 16.7065L15.3725 11.3731L13.9583 9.95891L8.62492 15.2922L10.0391 16.7065Z"
                fill="white"
              />
            </svg>
);

