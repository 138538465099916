import type { ReactElement } from 'react'
import { useEffect, useRef } from 'react'

export interface TimerProps {
  duration: number
  callback?: GenericCallback
}

export type GenericCallback = () => void

const secondsToTime = (secs: number): string => {
  let hours: number | string = Math.floor(secs / (60 * 60))
  hours = hours < 10 ? `0${hours}` : hours

  const divisorForMinutes: number = secs % (60 * 60)
  let minutes: number | string = Math.floor(divisorForMinutes / 60)
  minutes = minutes < 10 ? `0${minutes}` : minutes

  const divisorForSeconds: number = divisorForMinutes % 60
  let seconds: number | string = Math.ceil(divisorForSeconds)
  seconds = seconds < 10 ? `0${seconds}` : seconds

  return `${minutes}:${seconds}`
}

const Timer = ({ duration = 0, callback }: TimerProps): ReactElement | null => {
  const spanElementRef = useRef<HTMLSpanElement>(null)
  const durationRef = useRef(duration)

  useEffect(() => {
    if (spanElementRef.current == null) return
    durationRef.current = duration

    const { current: spanElement } = spanElementRef
    spanElement.classList.remove('complete')

    const timerId = setInterval(() => {
      if (durationRef.current === 0) {
        clearInterval(timerId)
        spanElement.classList.add('complete')

        console.log('Timer cleared!')
        callback?.()
        return
      }

      durationRef.current -= 1
      spanElement.textContent = secondsToTime(durationRef.current)
    }, 1000)

    return () => {
      clearInterval(timerId)
    }
  }, [duration, callback])

  return (
    <span className='timer' ref={spanElementRef}>
      {secondsToTime(duration)}
    </span>
  )
}

export default Timer
