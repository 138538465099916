import {useRef, useState } from 'react'
import styled from 'styled-components'
import { useFLIP } from 'hooks/hooks'
import { flexContainer } from 'hooks/styles'

export const InputContainer = styled.p`
  ${flexContainer('column')}
  span {
    display: block;
    transition: opacity 150ms;
    opacity: var(--opacity-display);
    padding-left:0;
    margin:0 !important
  }
`

const handleValidation = async (inputValue: any, callbackOrObj: any): Promise<any> => {
  if (typeof callbackOrObj !== 'function' && typeof callbackOrObj !== 'object') return

  let issue: any = ''

  if (typeof callbackOrObj === 'function') {
    issue = await callbackOrObj(inputValue)
  } else {
    const errorMessages = (
      await Promise.allSettled(
        Object.values(callbackOrObj).map((validationFn: any) => validationFn(inputValue))
      )
    ).filter((value) => typeof value === 'string')

    issue = errorMessages[0]
  }

  return issue
}

interface InputProp {
  validationObjOrFn?: any
  next:(validity:boolean)=>void
  [key: string]: any,
}

// Validation Obj is an object of functions or a single function
const Input = ({ validationObjOrFn, next,...htmlInputProps  }: InputProp): JSX.Element => {
  const inputRef: any = useRef()

  const initialErrorState: {
    errorDisplay: number
    message: any
  } = {
    errorDisplay: 0,
    message: null
  }

  const [error, setError] = useState<{
    errorDisplay: number
    message: any
  }>(initialErrorState)
  const [isValid, setValidity] = useState<any>(null)

  useFLIP(inputRef, (deltaX: number, deltaY: number, deltaW: number, deltaH: number) =>
    inputRef.current.animate(
      [
        {
          transformOrigin: 'top left',
          transform: `
          translate(${deltaX}px, ${deltaY}px)
          scale(${deltaW}, ${deltaH})
        `
        },
        { transformOrigin: 'top left', transform: 'none' }
      ],
      {
        duration: 300,
        easing: 'ease-in-out',
        fill: 'both'
      }
    )
  )

  const handleStateUpdate = (value: any): void => {
    if (typeof value !== 'string') {
      setValidity(true)
      setError(initialErrorState)
      next(true)
    } else {
      setValidity(false)
      setError({
        errorDisplay: 1,
        message: value
      })
      next(false)
    }
  }

  const handleValidationOnBlur = async (e: React.FocusEvent<HTMLInputElement>): Promise<any> => {
    let inputValue = e.target.value;
    const htmlValidity = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/.test(inputValue)
    let usernameRegex = /^@?[a-zA-Z0-9]([_](?![_])|[a-zA-Z0-9]){1,13}[a-zA-Z0-9]$/.test(inputValue)
    if (inputValue.length === 0) {
      setError(initialErrorState)
      return
    }

    if ( inputRef?.current?.id === "email" && !htmlValidity) {
      handleStateUpdate(`Enter a valid ${inputRef?.current?.id}`)
      return
    }
    if (inputRef?.current?.id === "Username" && !usernameRegex) {
      handleStateUpdate(`${inputRef?.current?.id} at least 3 characters, it can only contain letters, numbers and underscores (underscore must not be the first or last character nor appear consecutively).`)
      return
    }
    else if(inputRef?.current?.id === "Username" && usernameRegex && inputValue[0] === '@'){
      inputValue = e.target.value.slice(1);
    }
    const validationData = await handleValidation(inputValue, validationObjOrFn)
    handleStateUpdate(validationData ?? true)
  }

  const { errorDisplay, message } = error
  const statefulClassNames = errorDisplay !== 0 ? 'error' : isValid === true ? 'success' : ''

  return (
    <InputContainer className={statefulClassNames}>
      <span>{message}</span>
      <input ref={inputRef} onBlur={(event: React.FocusEvent<HTMLInputElement>) => {handleValidationOnBlur(event);}} {...htmlInputProps} />
    </InputContainer>
  )
}

export default Input
