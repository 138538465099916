import { useEffect, useState } from "react";
import { useLoadItems } from "../useLoadItems/useLoadItem";
import { DefaultYMCA } from "ymca/ymca";
import { Comment } from "ymca/models/comment.model";
import { usePublicationContext } from "context/PublicationContext";

export interface UseCommentResult {
  renderComments: Comment[]
  loading: boolean
  loadingMore: boolean
  error: any
  hasMore: boolean
  totalComments: number
  firstFetch: VoidFunction
  showMoreComments: VoidFunction
  addComment: (postId: string, omment: any) => Promise<void>
  hideComments: VoidFunction
  loadMore: VoidFunction
}

export function useComments(postId: string): UseCommentResult {
  const { totalComments, updateTotalCommment } = usePublicationContext()

  const [comments, setComments] = useState<Comment[]>([])
  const [renderComments, setRenderComments] = useState<Comment[]>([])

  const {
    data,
    loading,
    loadingMore,
    setLoading,
    count,
    incrementCount,
    error,
    hasMore,
    firstFetch,
    next
  } = useLoadItems<Comment>(
    DefaultYMCA.commentService.getPostCommentsGenerator(postId, 10)
  )

  useEffect(() => {
    setComments((prev) => [...prev, ...data])
    setRenderComments((prev) => [...prev, ...data])
  }, [data])

  const addComment = async (postId: string, comment: any): Promise<void> => {
    setLoading(true)
    return new Promise(async (resolve, reject) => {
      try {
        const res = await DefaultYMCA.commentService.createComment(
          postId,
          comment
        )
        setComments((prev) => [res.data, ...prev])
        setRenderComments((prev) => [res.data, ...prev])
        incrementCount()
        updateTotalCommment()
        setLoading(false)
        resolve()
      } catch (error) {
        reject(error)
        setLoading(false)
      }
    })
  }

  const showMoreComments = () => {
    if (
      comments.length < totalComments ||
      renderComments.length === comments.length
    )
      next()
    else
      setRenderComments((prev) => [
        ...prev,
        ...comments.slice(prev.length, prev.length + 3)
      ])
  }

  const hideComments = () => {
    setRenderComments((prev) => prev.slice(0, 3))
  }

  return {
    renderComments,
    loading,
    error,
    hasMore,
    totalComments,
    firstFetch,
    showMoreComments,
    addComment,
    hideComments,
    loadMore: next,
    loadingMore
  }
}