import { ReactNode, useEffect } from "react";
import { Alert, Box, Button, Snackbar, Typography } from '@mui/material'
import useRightSidebarTopCommunitiesStyles from './style'
import {
  useGetCommunitiesSidebarQuery,
  useJoinCommunityMutation
} from 'hooks/api-hooks/useCommunity'
import AtomLoading from 'components/atoms/AtomLoading'
import { Community } from 'ymca/models/community.model'
import { formatReadableCount } from 'utils/formatString'
import useShow from 'hooks/useShow'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import useSnackbar from 'hooks/useSnackbar'
import { redirectLinks } from 'constants/redirectLinks'
import usePopover from 'hooks/usePopover'
import AuthPopover from '../AuthPopover'
import { useAuth } from 'context/auth/authContext'
import Avatar from 'components/molecules/Avatar'
import getImageResolutions, {
  getImageUrlSourceType
} from 'utils/getImageResolutions'

interface RightSidebarTopCommunitiesProps {}
interface RightSidebarTopCommunitiesListItemProps {
  community: Community
  refetchListOnJoinSuccess: Function
}

const RightSidebarTopCommunities = ({}: RightSidebarTopCommunitiesProps) => {
  const classes = useRightSidebarTopCommunitiesStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const { isShowing, toggle } = useShow()

  const { isLoading, data, error, refetch } = useGetCommunitiesSidebarQuery({
    enabled: isShowing
  })

  const handler = () => {
    const { pathname = '' } = location
    if (pathname === `/${redirectLinks.communities}/explore`) {
      // hide Top communities
      if (isShowing) {
        toggle()
      }
    } else {
      // show Top communities
      if (!isShowing) {
        toggle()
      }
    }
  }

  useEffect(() => {
    handler()
  }, [location.pathname])

  if (!isShowing) return null

  if (isLoading) {
    return (
      <Box>
        <AtomLoading />
      </Box>
    )
  }

  const communitiesData = data?.data ?? []

  return (
    <>
      {communitiesData.length > 0 && (
        <Box
          component='section'
          aria-label='Top Commmunities Sidebar'
          className={classes.wrapper}
        >
          <Typography className={classes.headerTitle}>
            Top Communities
          </Typography>
          {communitiesData.map((community) => {
            return (
              <RightSidebarTopCommunitiesListItem
                key={community.id}
                community={community}
                refetchListOnJoinSuccess={refetch}
              />
            )
          })}
          {/* show see more button and navigate user back to explore page */}
          {data?.hasMore && (
            <Button
              className={classes.seeMoreBtn}
              onClick={() => navigate(`/${redirectLinks.communities}/explore`)}
            >
              See more
            </Button>
          )}
        </Box>
      )}
    </>
  )
}

const RightSidebarTopCommunitiesListItem = ({
  community,
  refetchListOnJoinSuccess
}: RightSidebarTopCommunitiesListItemProps) => {
  const classes = useRightSidebarTopCommunitiesStyles()
  const { isAuthenticated } = useAuth()

  const {
    isOpen: isJoinSnkbarOpen,
    close: closeJoinSnkBar,
    open: openJoinSnkBar
  } = useSnackbar()

  const {
    isOpen: isJoinSnkbarOpenErr,
    close: closeJoinSnkBarErr,
    open: openJoinSnkBarErr
  } = useSnackbar()

  // make an API call to join community
  const mutation = useJoinCommunityMutation({
    onSuccess: (data: any) => {
      openJoinSnkBar()
      refetchListOnJoinSuccess()
    },
    onError: () => {
      openJoinSnkBarErr()
    },
    data: {
      id: community.id
    }
  })

  const {
    isOpen: isAuthPopoverOpen,
    close: closeAuthPopover,
    open: openAuthPopover,
    anchorEl: authPopoverAnchorEl
  } = usePopover()

  const handleJoinCommunity = (event: React.MouseEvent<HTMLElement>) => {
    if (isAuthenticated) {
      mutation.mutate({ id: community.id })
    } else {
      openAuthPopover(event)
    }
  }

  const { x2, x3, fallback } = getImageResolutions(community.avatar!)

  return (
    <Box className={classes.communitiesListItem}>
      <Link
        to={`/${redirectLinks.communities}/${community.slug}`}
        className={classes.communitiesListItemLink}
      >
        <Avatar
          src={fallback}
          className={classes.communitiesListItemAvatar}
          pictureSources={[
            {
              srcSet: `${x2} 1x, ${x3} 2x`,
              type: getImageUrlSourceType(x3)
            }
          ]}
        />
        <Box>
          <Typography className={classes.communitiesListItemName}>
            {community.name}
          </Typography>
          <Typography className={classes.communitiesListItemMemberCount}>
            {formatReadableCount(community.nMembers)} Member
            {community.nMembers > 1 ? 's' : ''}
          </Typography>
        </Box>
      </Link>
      <Button
        className={classes.communitiesListItemJoinBtn}
        onClick={handleJoinCommunity}
      >
        Join
        {mutation.isLoading && <AtomLoading />}
      </Button>
      <AuthPopover
        onClose={closeAuthPopover}
        isOpen={isAuthPopoverOpen}
        anchorEl={authPopoverAnchorEl}
        id={community.id}
      />
      <Snackbar
        open={isJoinSnkbarOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={closeJoinSnkBar}
      >
        <Alert>Joined community Successfully</Alert>
      </Snackbar>
      <Snackbar
        open={isJoinSnkbarOpenErr}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={closeJoinSnkBarErr}
      >
        <Alert severity='error'>Failed to join community</Alert>
      </Snackbar>
    </Box>
  )
}

export default RightSidebarTopCommunities;
