import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink as DefaultNavLink, useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography as MuiTypography } from "@material-ui/core";
import { styled, Box, Button } from "@mui/material";
import Image from "components/shared/Image";

import {
  BREAKPOINT_LAPTOP,
  BREAKPOINT_LAPTOP_LARGE,
  BREAKPOINT_LAPTOP_SMALL,
  BREAKPOINT_TABLET_MEDIUM,
} from "theme/shared/breakpoint";
import { DefaultYMCA } from "ymca/ymca";
import { getReadableCategory } from "utils/formatString";
import { redirectLinks } from "constants/redirectLinks";
import { useAuth } from "context/auth/authContext";
import colors from "theme/dark/colors";
import Divider from "components/atoms/Divider";
import Icon from "components/atoms/Icons";
import Typography from "components/atoms/Typography";
import UserProfileSetting from "components/user/UserProfileSetting";
import getTopSpacing from "./getTopSpacing";
import ReactGA from "react-ga4";
import useSidebarStyles from "./sidebar.style";
import CreateCommunityModal from "components/organisms/CreateCommunityModal";
import useModal from "hooks/useModal";
import config from "utils/config";
import {
  CommunityRole,
  MANAGE_COMMUNITY_ROLES,
} from "ymca/models/community-role.model";

const Wrapper = styled(Box, {
  name: 'SidebarWrapper'
})<{ headerHaveEthAlertContent: boolean }>(
  ({ theme, headerHaveEthAlertContent }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'fixed',

    // ensure to modify this section if more things are added to the header elemnt
    top: getTopSpacing(headerHaveEthAlertContent),
    height: 'calc(100vh - 72px)',

    width: '18rem',
    boxSizing: 'border-box',
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      width: 0,
      backgroundColor: 'transparent'
    },

    [`@media (max-width: ${BREAKPOINT_LAPTOP_LARGE}px)`]: {
      width: '16rem',
      top: getTopSpacing(headerHaveEthAlertContent, BREAKPOINT_LAPTOP_LARGE)
    },

    // change position from fixed to relative
    [`@media (max-width: ${BREAKPOINT_LAPTOP}px)`]: {
      fontSize: '1rem',
      flexShrink: 1,
      whiteSpace: 'nowrap',
      position: 'relative',
      top: 0,
      left: 0,
      width: '4rem',
      zIndex: 'unset',
      background: colors.darkBlue,
      boxShadow: '2px 0 2px rgb(0 0 0 / 50%)',
      height: '100%',

      padding: '0 .25rem'
    },

    [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
      display: 'none'
    }
  })
)

// component is a duplicate of Wrapper since that is a fixed position, having this ensures we have real width on dom since fixed position
export const EmptySidebarWrapper = styled(Box, {
  name: 'EmptySidebarWrapper'
})(({ theme }) => ({
  width: '18rem',

  [`@media (max-width: ${BREAKPOINT_LAPTOP_LARGE}px)`]: {
    width: '16rem'
  },

  [`@media (max-width: ${BREAKPOINT_LAPTOP}px)`]: {
    display: 'none'
  },

  [`@media (max-width: ${BREAKPOINT_TABLET_MEDIUM}px)`]: {
    display: 'none'
  }
}))

const useStyles = makeStyles({
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
    marginTop: '1rem',
    padding: '0 1rem',

    [`@media (max-width: ${BREAKPOINT_LAPTOP}px)`]: {
      padding: 'unset'
    }
  },
  category: {
    cursor: 'pointer',
    paddingBottom: '1rem',
    marginLeft: 17,
    fontSize: '0.75rem'
  },
  navigation: {
    width: '100%',
    height: 40,
    borderRadius: 5,
    display: 'flex',
    padding: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    color: '#E2E4E9',
    position: 'relative',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      color: '#fff'
    }
  },
  active: {
    backgroundColor: '#35A6EB',
    color: '#fff'
  },
  disabled: {
    color: 'gray',
    '& p': {
      color: 'gray'
    },
    '&:hover': {
      color: 'gray'
    }
  },
  text3: {
    marginLeft: 40,
    fontSize: 15,
    fontWeight: 500,
    color: '#E2E4E9'
  },
  divider: {
    backgroundColor: colors.border,
    margin: '16px 0'
  },
  sidebarFooter: {
    borderTop: '1px solid #192129',
    padding: '.5rem .25rem 3rem 0',

    [`@media (max-width: ${BREAKPOINT_LAPTOP}px)`]: {
      display: 'none'
    }
  },
  items: {
    display: 'inline-flex',
    gap: 8,
    flexWrap: 'wrap',
    fontSize: 12,
    '& a': {
      color: '#959BAA'
    }
  },
  text4: {
    color: '#5A6370',
    fontSize: 12,
    marginTop: 20,
    marginBottom: 20
  },
  arrowUp: {
    position: 'fixed',
    left: 5,
    top: 46,
    backgroundImage: 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1))'
  },
  arrowDown: {
    position: 'fixed',
    left: 5,
    bottom: 0,
    backgroundImage: 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1))'
  },
  serviceButton: {
    height: 48,
    width: '100%',
    borderRadius: 5,
    fontSize: 14,
    fontWeight: 700,
    color: 'white',
    minWidth: 0,
    padding: 0,
    transition: '0.4s all',
    textShadow: ' 0px 0px 5px rgba(0,0,0,0.1)',
    position: 'relative',
    backgroundImage:
      'linear-gradient(90.99deg, rgba(153, 117, 255, 0.25) 0.85%, rgba(255, 117, 192, 0.25) 99.91%)',
    animation: '$spin 1s infinite alternate pause',
    '&span': { zIndex: 9 },
    '&:hover': {
      backgroundImage:
        'linear-gradient(90.99deg, rgba(153, 117, 255, 0.25) 0.85%, rgba(255, 117, 192, 0.25) 99.91%)',
      animation: '$spin 1s infinite alternate running'
    }
  },
  '@keyframes spin': {
    '0%': {
      transform: 'scale(1)'
    },
    '100%': {
      transform: 'scale(1.1)'
    }
  },
  '@media (max-width: 1380px)': {
    items: {
      marginLeft: '0.75em'
    },
    text4: {
      marginLeft: '0.75em'
    }
  },
  '@media (max-width: 1200px)': {
    container: {
      display: 'flex',
      flexDirection: 'column'
    },
    navigation: {
      width: '90%',
      justifyContent: 'center !important',
      paddingLeft: 0
    },
    serviceButton: {
      width: '90%'
    },
    text3: {
      display: 'none'
    }
  },
  rewardSvg: {
    width: 85,
    height: 85
  }
})

interface CategoryLinkProps {
  isactive?: boolean
}

const CategoryLink = styled(Link)<CategoryLinkProps>(({ isactive }) => ({
  backgroundColor: isactive ? colors.darkBlue50 : '',
  padding: '.2rem 1rem',
  borderRadius: '.5rem',

  '&:hover': {
    backgroundColor: colors.darkBlue50
  }
}))

const CategoriesSection = styled(Box)(({ theme }) => ({
  paddingRight: '1rem',
  display: 'flex',
  gap: '.4rem',
  flexDirection: 'column',

  [`@media (max-width: ${BREAKPOINT_LAPTOP}px)`]: {
    display: 'none'
  }
}))

interface NavLinkProps {
  disabled?: boolean
}

const NavSection = styled(Box, { name: 'NavSection' })({
  display: 'flex',
  flexDirection: 'column',
  gap: '.3rem',
  paddingRight: '1rem',

  [`@media (max-width: ${BREAKPOINT_LAPTOP}px)`]: {
    paddingRight: '0'
  }
})

const NavLink = styled(DefaultNavLink)<NavLinkProps>(({ theme, disabled }) => {
  const baseStyle = {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    padding: '.5rem',
    borderRadius: '.5rem',
    gap: '.75rem',
    fontSize: '1rem',
    filter: 'drop-shadow(1px 5px 5px rgb(10, 12, 37))',

    [`@media (max-width: ${BREAKPOINT_LAPTOP}px)`]: {
      justifyContent: 'center'
    },

    '& button': {
      display: 'flex',
      justifyContent: 'center',

      borderRadius: '0.5rem',
      background: '#28344D',
      height: '32px',
      alignContent: 'right',

      '&.MuiButton-root': {
        padding: '0',
        minWidth: '0',

        width: '1.875rem',
        maxWidth: '1.875rem'
      }
    }
  }

  let style: { [key: string]: any } = {
    ...baseStyle,

    '&.active': {
      color: theme.palette.text.primary,
      backgroundColor: colors.secondary900,
      // backgroundColor: theme.palette.primary.main,
      fontWeight: 600,

      '& button': {
        background: '#192133'
      }
    },

    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: colors.secondary900,
      fontWeight: 600,
      '& button': {
        background: '#192133'
      }
    }
  }

  if (disabled) {
    style = {
      ...baseStyle,

      '&:hover': {
        cursor: 'not-allowed'
      }
    }
  }

  return style
})

const NavLinkTypography = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-body1': {
    color: 'inherit',
    fontWeight: 'inherit',
    fontSize: 'inherit'
  },

  [`@media (max-width: ${BREAKPOINT_LAPTOP}px)`]: {
    display: 'none'
  }
}))

const NavLinkIcon = styled(Icon)({})

const DiscoverTypography = styled(Typography)(({ theme }) => ({
  [`@media (max-width: ${BREAKPOINT_LAPTOP}px)`]: {
    display: 'none'
  }
}))

const ReferralSection = styled(Box, { name: 'ReferralSection' })(
  ({ theme }) => ({
    display: 'flex',
    gap: '.1rem',
    justifyContent: 'space-between',
    marginRight: '.5rem',
    padding: '.5rem',

    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '1rem',
    backgroundImage: `linear-gradient(75.66deg, ${colors.secondary900} -1.7%,  ${colors.secondary800} 25.73%, ${colors.backgroundPrimary} 96.1%)`,

    [`@media (max-width: ${BREAKPOINT_LAPTOP}px)`]: {
      display: 'none'
    }
  })
)

const LeftReferralContent = styled(Box)({
  display: 'flex',
  gap: '1rem',
  flexDirection: 'column'
})

const LeftContentSection = styled(Box)({})

const LearnMoreButton = styled(Button)({
  '&.MuiButton-sizeSmall': {
    fontSize: '.7rem'
  },

  '& .MuiButton-iconSizeSmall > *:first-child': {
    fontSize: '.7rem'
  }
})

const RightReferralContent = styled(Box)({})

const ReferFriendsTypography = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-body1': {
    color: theme.palette.text.secondary,
    fontWeight: 600,
    fontSize: '.8rem'
  }
}))

const GetDiamondTypography = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-body1': {
    color: theme.palette.text.primary,
    fontWeight: 800,
    fontSize: '.9rem'
  }
}))

export interface FeedItems {
  icon: JSX.Element
  to: string
  title: string
}

export interface FooterItems {
  to: string
  title: string
  hide?: boolean
}

const Sidebar = ({
  headerHaveEthAlertContent = false
}: {
  headerHaveEthAlertContent?: boolean
}): JSX.Element => {
  const classes = useStyles()
  const sidebarClasses = useSidebarStyles()
  const navigate = useNavigate()
  const { isAuthenticated, userInfo } = useAuth()
  const { experimentalCommunityFlag } = config

  const listInnerRef = useRef()
  const location: string = window.location.pathname

  const [modalopen, setModalOpen] = useState<boolean>(false)
  const [, setActive] = useState<number | null>(isAuthenticated ? 0 : null)

  const [trendes, setTrendes] = useState<{ id: string; name: string }[]>([])
  const getTrendes = async (): Promise<any> => {
    const trandes = await DefaultYMCA.categoryService.getAllCategories()
    if (trandes && Array.isArray(trandes.data.data))
      setTrendes(trandes.data.data)
  }

  // create community modal
  const { isOpen, close, open } = useModal()

  useEffect(() => {
    getTrendes()
  }, [])

  const handleModalClose = (): void => {
    setModalOpen(false)
    setActive(0)
  }

  const feedItems: FeedItems[] = [
    {
      icon: <NavLinkIcon icon='home' />,
      to: `/`,
      title: 'Home'
    },
    {
      icon: <NavLinkIcon icon='users' />,
      to: `/${redirectLinks.followers}`,
      title: 'Following'
    },
   /* {
      icon: <NavLinkIcon icon='trending' />,
      to: `/${redirectLinks.trending}`,
    }*/
    ]

  const openFeedItems: FeedItems[] = feedItems.filter(
    (item) => item.title !== 'Following'
  )

  const footerItems: FooterItems[] = [
    { to: `/${redirectLinks.contact}`, title: 'Contact' },
    { to: `/${redirectLinks.privacy}`, title: 'Privacy Policy' },
    { to: `/${redirectLinks.faq}`, title: 'FAQ' },
    { to: `/${redirectLinks.news}`, title: 'News' },
    { to: `/${redirectLinks.agreement}`, title: 'Terms Of Service' },
    {
      to: `/${redirectLinks.about}`,
      title: 'About',
      hide: !config.experimentalYoumemeIOEnabledFlag
    }
  ]

  const handleReferralButton = () => {
    navigate('/referral')
    ReactGA.event({
      category: 'Referral',
      action: `Navigate to referral page`
    })
  }

  const handleOnCreateCommunity = (event: React.SyntheticEvent) => {
    event.stopPropagation() // stop bubbling up
    event.preventDefault() // prevent default behaviour of button click
    console.log('stop propagating')

    // open modal
    open()
  }

  const canCreateCommunity = MANAGE_COMMUNITY_ROLES.includes(
    userInfo?.userData?.role as CommunityRole
  )

  return (
    <Wrapper
      ref={listInnerRef as any}
      headerHaveEthAlertContent={headerHaveEthAlertContent}
    >
      <Box className={classes.container}>
        <DiscoverTypography color={colors.titleColor} fontWeight={800}>
          Discover
        </DiscoverTypography>
        <NavSection>
          {(isAuthenticated ? feedItems : openFeedItems).map(
            (item: FeedItems, index: number) => (
              <NavLink to={item.to} key={index}>
                {item.icon}
                <NavLinkTypography
                  variant={'body1'}
                  variantMapping={{ body1: 'h3' }}
                >
                  {item.title}
                </NavLinkTypography>
              </NavLink>
            )
          )}
          <NavLink to={`/${redirectLinks.ongoingcontest}`}>
            <NavLinkIcon icon='competition' />
            <NavLinkTypography>Competitions</NavLinkTypography>
          </NavLink>
          {experimentalCommunityFlag && (
            <NavLink to={`/${redirectLinks.communities}`}>
              <NavLinkIcon icon='communities' />
              <NavLinkTypography>Communities</NavLinkTypography>
              {canCreateCommunity && (
                <Box className={sidebarClasses.navLinkCta}>
                  <Button
                    // className={sidebarClasses.navLinkCtaButton}
                    onClick={handleOnCreateCommunity}
                  >
                    <NavLinkIcon icon='plus' />
                  </Button>
                </Box>
              )}
            </NavLink>
          )}
        </NavSection>
      </Box>
      <Divider />
      <Box className={classes.container}>
        {trendes.length > 0 && (
          <CategoriesSection>
            <Typography color={colors.titleColor} fontWeight={800}>
              CATEGORIES
            </Typography>
            {trendes.map(({ id, name }) => {
              return (
                <CategoryLink
                  to={`/categories/${name.toLowerCase()}`}
                  key={id}
                  data-test-categories={id}
                  isactive={
                    location === `/categories/${name.toLowerCase()}`
                      ? true
                      : undefined
                  }
                >
                  <Typography
                    variant={'body1'}
                    variantMapping={{ body1: 'h4' }}
                    fontSize='1rem'
                    color={colors.darkGray}
                  >
                    {getReadableCategory(name)}
                  </Typography>
                </CategoryLink>
              )
            })}
          </CategoriesSection>
        )}
        <ReferralSection>
          <LeftReferralContent>
            <LeftContentSection>
              <ReferFriendsTypography>Refer friends</ReferFriendsTypography>
              <GetDiamondTypography>Get Diamonds</GetDiamondTypography>
            </LeftContentSection>
            <LearnMoreButton
              variant='contained'
              endIcon={<Icon icon='arrow-right' />}
              size='small'
              onClick={handleReferralButton}
            >
              Learn More
            </LearnMoreButton>
          </LeftReferralContent>
          <RightReferralContent>
            <Image
              src={'/assets/sidebar/gift.svg'}
              className={classes.rewardSvg}
            />
          </RightReferralContent>
        </ReferralSection>
        <Box className={classes.sidebarFooter}>
          <Box className={classes.items}>
            {footerItems.map((item: FooterItems, index: number) => {
              if (item.hide) return <></>

              return (
                <Link to={item.to} key={index}>
                  <Typography
                    variant={'body2'}
                    variantMapping={{ body2: 'h3' }}
                    fontSize='12px'
                  >
                    {item.title}
                  </Typography>
                </Link>
              )
            })}
          </Box>
          <MuiTypography className={classes.text4}>
            &copy; {new Date().getFullYear()} YouMeme, Inc. All rights reserved
          </MuiTypography>
        </Box>
      </Box>
      <CreateCommunityModal isOpen={isOpen} close={close} />
      <UserProfileSetting open={modalopen} handleClose={handleModalClose} />
    </Wrapper>
  )
}

export default Sidebar;
