import { SocialLinks } from 'ymca/models/social-links.model'
import { QuerySearchParamsDto } from './common.dto'
import { CommunityRole } from 'ymca/models/community-role.model'
import { URLSearchParamsCapable } from "ymca/jsonFetcher";

export class CreateCommunityDTO {
  public name = "";
  public description = "";
  public isPrivate = false;
  public isNsfw = false;
  public avatarImage = "";
  public backgroundImage = "";
  public socialLinks: SocialLinks = {};
}

export class UpdateCommunityDTO {
  public name?: string;
  public description?: string;
  public isPrivate?: boolean;
  public isNsfw?: boolean;
  public fkOwnerId?: string;
  public avatarImage?: string;
  public backgroundImage?: string;
  public socialLinks: SocialLinks = {};
}

export type CommunitySortField = "createdAt" | "name" | "nMembers";
export const CommunitySortFields = [
  "createdAt",
  "name",
  "nMembers",
] as CommunitySortField[];

export class GetCommunitiesDTO extends QuerySearchParamsDto {
  public sortField: CommunitySortField = "nMembers";
  public ids: string[] = [];
  public name = "";
  public slug = "";
  public search = "";
  public ownerId: string[] = [];
  public hideJoined = false;
  public joinedByUserId = "";
  public membershipRoles: CommunityRole[] = [];

  public toURLSearchParams(): URLSearchParams {
    const params = super.toURLSearchParams();
    if (this.ids.length > 0) {
      params.append("ids", this.ids.join(","));
    }
    if (this.name !== "") {
      params.append("name", this.name);
    }
    if (this.slug !== "") {
      params.append("slug", this.slug);
    }
    if (this.search !== "") {
      params.append("search", this.search);
    }
    if (this.ownerId.length > 0) {
      params.append("ownerId", this.ownerId.join(","));
    }
    if (this.hideJoined) {
      params.append("hideJoined", "true");
    }
    if (this.joinedByUserId !== "") {
      params.append("joinedByUserId", this.joinedByUserId);
    }
    if (this.membershipRoles.length > 0) {
      params.append("membershipRoles", this.membershipRoles.join(","));
    }
    return params;
  }
}

export class GetCommunityDTO
  extends GetCommunitiesDTO
  implements URLSearchParamsCapable
{
  // using setter to setId
  public setId(id: string) {
    if (id !== "") {
      this.ids = [id];
    }
  }
}

export class ListCommunityMembersDTO extends QuerySearchParamsDto {
  public communityId = "";
  public slug = "";
  public search = "";
  public role: CommunityRole[] = [];

  public toURLSearchParams(): URLSearchParams {
    const params = super.toURLSearchParams();
    if (this.role.length > 0) {
      params.append("role", this.role.join(","));
    }
    if (this.communityId !== "") {
      params.append("communityId", this.communityId);
    }
    if (this.slug !== "") {
      params.append("slug", this.slug);
    }
    if (this.search !== "") {
      params.append("search", this.search);
    }
    return params;
  }
}

export class CommunityUserIdDTO {
  public communityId = "";
  public userId = "";
}

export class CommunityUserRoleDTO {
  public communityId = "";
  public userId = "";
  public role: CommunityRole = "member";
}

export class CommunityMembershipVisibilityDTO {
  public communityId = "";
  public isMembershipHidden = false;
}

export class GetJoinedCommunitiesDTO extends GetCommunitiesDTO {
  public userId = "";
  public role: CommunityRole[] = ["admin", "moderator", "member"];

  public toURLSearchParams(): URLSearchParams {
    const params = super.toURLSearchParams();
    if (this.role.length > 0) {
      params.append("role", this.role.join(","));
    }
    if (this.userId !== "") {
      params.append("userId", this.userId);
    }
    return params;
  }
}

export class CommunityInvitationsDTO extends QuerySearchParamsDto {
  public communityId?: string
  public includeAccepted = false

  public toURLSearchParams (): URLSearchParams {
    const params = super.toURLSearchParams()
    if (this.communityId !== undefined) {
      params.append('communityId', this.communityId)
    }
    if (this.includeAccepted) {
      params.append('includeAccepted', 'true')
    }
    return params
  }
}
