import { makeStyles } from '@material-ui/core/styles'
import colors from 'theme/dark/colors'

const useCommentListStyles = makeStyles((theme: any) => ({
  userIcon: {
    width: 32,
    height: 32,
    marginTop: 10,
    backgroundColor: colors.profileImageBackground,
    '&:hover': {
      cursor: 'pointer'
    },

    '& img': {}
  },
  commentListContainer: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
      width: 2
    },

    '&::-webkit-scrollbar-thumb': {
      background: colors.purpleGradient,
      borderRadius: 10
    },

    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none'
  },
  commentRow: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'flex-start',
    gap: '.5rem'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    gap: '.5rem',
    width: '100%'
  },
  comment: {
    display: 'flex',
    gap: '.5rem',
    width: '100%',
    '& a': {
      color: 'white',
      textDecoration: 'underline'
    }
  },
  commentBox: {
    padding: 10,
    width: '100%',
    background: '#202B42',
    borderRadius: 15
  },
  commentUser: {
    color: '#8B89FF',
    fontSize: 14,
    fontWeight: 800,
    '& span': {
      color: '#8A98B4',
      fontSize: 12,
      fontWeight: 400
    }
  },
  commentMain: {
    color: '#E9F0F9',
    fontSize: 14,
    fontWeight: 600,
    'word-break': 'break-all'
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-start',
    color: '#8A98B4',
    fontSize: 14,
    fontWeight: 600,
    padding: 0
  }
}))

export default useCommentListStyles
