import clsx from 'clsx'
import { useEffect, useState } from 'react'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'
import { Image } from 'components/shared/index'
import PreferSetting from 'components/modals/settings/PreferSetting'
import GeneralSetting from 'components/modals/settings/GeneralSetting'
import PrivacySetting from 'components/modals/settings/PrivacySetting'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import SecuritySetting from 'components/modals/settings/SecuritySetting'
import NotificationSetting from 'components/modals/settings/NotificationSetting'
import { imagesSvg } from 'constants/images'
import colors from 'theme/dark/colors'
import LensSetting from 'components/modals/settings/LensSetting'
import { useLensService } from 'hooks/useLensServices/useLensServices'

const useStyles = makeStyles({
  paper: {
    position: 'absolute',
    width: '1085px',
    height: '691px',
    backgroundColor: colors.darkBlue,
    boxShadow: '0px 3px 10px #0000001A',
    padding: '10px',
    boxSizing: 'border-box',
    borderRadius: '10px',
    overflowY: 'auto !important' as any,
    overflowX: 'none !important' as any,
    '&:focus': {
      outline: 'none'
    },
    '&::-webkit-scrollbar': {
      backgroundColor: '#181B22 !important',
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#707070 !important',
      borderRadius: '35px'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentContainer: {
    marginLeft: '337px'
  },
  text: {
    width: '150px',
    marginTop: 'auto',
    padding: '45px 5px 19px 35px',
    fontWeight: 900,
    fontSize: '26px',
    color: '#E2E4E9',
    lineHeight: '31.47px',
    fontStyle: 'normal',
    letterSpacing: '-0.3px'
  },
  mainBox: {
    marginTop: '10px',
    width: '300px',
    height: 'auto',
    background: 'white',
    boxShadow: '0px 3px 8px #0000001A',
    borderRadius: '15px',
    opacity: 1,
    padding: '25px 15px 22px 15px',
    boxSizing: 'border-box'
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '10px'
  },
  mainTitle: {
    color: '#000000',
    opacity: 1,
    fontSize: '16px',
    fontWeight: 'bold'
  },
  textAll: {
    color: colors.mainPurple,
    opacity: 1,
    fontSize: '14px',
    font: 'normal normal normal 14px/24px Gotham Rounded'
  },
  dataContainer: {
    display: 'flex',
    padding: '16px 25px',
    alignItems: 'center',
    width: '100%',
    borderRadius: '5px',
    textAlign: 'left',
    boxSizing: 'border-box !important' as any,
    height: '50px',
    '& span': {
      justifyContent: 'stretch'
    },
    '&:hover': {
      backgroundColor: colors.darkBlue
    }
  },
  imageBox: {
    borderRadius: '50px',
    height: '38px',
    width: '38px',
    overflow: 'hidden !important',
    '& img': {
      width: '100%'
    }
  },
  popularName: {
    fontWeight: 600,
    fontSize: '14px',
    color: '#808893',
    lineHeight: '16.94px',
    fontStyle: 'normal'
  },
  popularDetails: {
    fontSize: '16px',
    color: colors.mainGrey,
    marginRight: '10px'
  },
  savebtn: {
    marginTop: 'auto',
    padding: '30px 20px 10px'
  },
  item: {
    display: 'flex',
    borderBottom: '1px solid #EFEFEF',
    padding: '6px 0 6px 10px',
    margin: '0 0 0 10px'
  },
  itemtitle: {
    font: 'normal normal normal 12px/31px Gotham Rounded',
    color: '#1A0030',
    width: '33%',
    fontWeight: 'bold'
  },
  itemcontent: {
    font: 'normal normal normal 12px/31px Gotham Rounded',
    color: '#808080',
    width: '60%'
  },
  itemimage: {
    minWidth: '30px'
  },
  dbcolor: {
    color: '#1A0030',
    fontWeight: 'bold'
  },
  selected: {
    background: 'linear-gradient(to right, rgba(53, 166, 235, 1),rgba(112, 117, 255, 1))',
    padding: '16px 25px',
    borderRadius: '5px',
    fontStyle: 'normal',
    width: '243px',
    height: '50px',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: '#252A3A',
      borderRadius: '5px',
      fontStyle: 'normal'
    }
  },
  imgStyle: {
    width: '15px',
    height: '15px'
  },
  leftMenu: {
    backgroundColor: colors.darkBlue50,
    borderRadius: '10px',
    width: '100%',
    height: '672px',
    padding: '0 15px',
    boxSizing: 'border-box'
  },
  socialIconsContainer: {
    padding: '0 0px',
    boxSizing: 'borderBox' as any,
    marginTop: '18px',
    width: '100%',
    height: '60px'
  },
  socialIcons: {
    borderRadius: '10px',
    backgroundColor: '#1D232B',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '18px 20px'
  },
  youmemesocial: {
    color: '#808893',
    fontWeight: 600,
    fontSize: '14px',
    fontStyle: 'normal'
  },
  socialIconsList: {
    display: 'flex',
    '& img': {
      marginLeft: '15px'
    }
  },
  closeBtn: {
    position: 'absolute',
    right: 0
  },
  '@media (max-width: 750px)': {
    modal: {
      display: 'none'
    }
  }
})

interface UserProfileSettingProp {
  open: boolean
  handleClose: () => void
}

export const getNeccessaryComponentForProfileSetting = (value: string): JSX.Element | undefined => {
  switch (value) {
    case 'profile':
      return <GeneralSetting />
    case 'security':
      return <SecuritySetting />
    case 'notifications':
      return <NotificationSetting />
    case 'preferences':
      return <PreferSetting />
    case 'privacy':
      return <PrivacySetting />
    case 'lens':
      return <LensSetting />
    default:
  <></>
  }
}

const UserProfileSetting = ({ open, handleClose }: UserProfileSettingProp): JSX.Element => {
  const classes = useStyles()
  const { isAuthenticatedOnLens } = useLensService();

  const [menu, selectMenu] = useState<string>('profile')
  const [isLens, toggleLens] = useState<boolean>(false);

  const checkLens = async () => {
    let lens = await isAuthenticatedOnLens();
    toggleLens(lens);
  }

  useEffect(() => {
    checkLens();
  }, [])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      className={classes.modal}
    >
      <Box className={classes.paper} style={{ overflowY: 'auto' }}>
        <Button onClick={handleClose} className={classes.closeBtn}>
          <Image alt="close icon" src={`moveIcons/${imagesSvg.closeBtnIcon}`} />
        </Button>
        <Grid container>
          <Grid item xs={4} style={{ position: 'fixed', width: '275px' }}>
            <Grid className={classes.leftMenu}>
              <Typography className={classes.text}>Settings</Typography>

              <Button
                className={clsx(classes.dataContainer, menu === 'profile' ? classes.selected : '')}
                onClick={() => selectMenu('profile')}
                disableRipple
              >
                <Box>
                  <Typography
                    className={classes.popularName}
                    style={{ color: menu === 'profile' ? '#FFFFFF' : '' }}
                  >
                    Profile Settings
                  </Typography>
                </Box>
              </Button>
              <Button
                className={clsx(classes.dataContainer, menu === 'privacy' ? classes.selected : '')}
                onClick={() => selectMenu('privacy')}
                disableRipple
              >
                <Box>
                  <Typography
                    className={classes.popularName}
                    style={{ color: menu === 'privacy' ? '#FFFFFF' : '' }}
                  >
                    Privacy & Security
                  </Typography>
                </Box>
              </Button>
              <Button
                className={clsx(classes.dataContainer, menu === 'preferences' && classes.selected)}
                onClick={() => selectMenu('preferences')}
                disableRipple

              >
                <Box>
                  <Typography
                    className={classes.popularName}
                    style={{ color: menu === 'preferences' ? '#FFFFFF' : '' }}
                  >
                    Preferences
                  </Typography>
                </Box>
              </Button>
              <Button
                className={clsx(
                  classes.dataContainer,
                  menu === 'notifications' && classes.selected
                )}
                onClick={() => selectMenu('notifications')}
                disableRipple
              >
                <Box>
                  <Typography
                    className={classes.popularName}
                    style={{ color: menu === 'notifications' ? '#FFFFFF' : '' }}
                  >
                    Notifications
                  </Typography>
                </Box>
              </Button>
              {
                isLens
                ?
                  <Button
                    className={clsx(
                      classes.dataContainer,
                      menu === 'lens' && classes.selected
                    )}
                    onClick={() => selectMenu('lens')}
                    disableRipple
                  >
                    <Box>
                      <Typography
                        className={classes.popularName}
                        style={{ color: menu === 'lens' ? '#FFFFFF' : '' }}
                      >
                        Lens Protocol
                      </Typography>
                    </Box>
                  </Button>
                : ''
              }
            </Grid>
          </Grid>
          <Grid item xs={8} className={classes.contentContainer}>
            {getNeccessaryComponentForProfileSetting(menu)}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default UserProfileSetting
