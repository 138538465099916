import { Box } from '@material-ui/core'
import { useEffect } from 'react'

import { Post as PostModel } from 'ymca/models/post.model'
import PublicationContent from '../../PublicationContent/PublicationContent'
import PublicationHeader from '../../PublicationHeader/PublicationHeader'
import PublicationContentTitle from '../../PublicationContentTitle/PublicationContentTitle'
import type { HeadingType } from 'components/memePublication/PublicationContentTitle/PublicationContentTitle'
import PublicationContentDescription from '../../PublicationContentDescription/PublicationContentDescription'
import usePublicationMainStyles from './style'
import PublicationTag from '../../PublicationTag'
import PublicationReaction from '../../PublicationReaction'
import PublicationCommentCount from '../../PublicationCommentCount'
import PublicationShare from '../../PublicationShare'
import PublicationAddComment from '../../PublicationComment/add-comment'
import PublicationCommentList from '../../PublicationComment/comment-list'
import { useComments } from 'hooks/useComments/useComments'
import { checkIsPostContentPlayable } from 'utils/postContent'
import getImageResolutions from 'utils/getImageResolutions'

export interface IonScreenProps {
  isOnScreen: boolean
  modal: boolean
}
export interface PublicationMainProp {
  post: PostModel
  onModalToggle?: () => void
  modal?: boolean
  play?: boolean // auto plays video
  onScreenProps?: IonScreenProps
  fontSize?: string
  setEvent?: any
  modalPage?: boolean
  contestModal?: boolean
  showComment?: boolean
  headingType?: HeadingType
  disableEditMeme?: boolean
  copyLinkBasePath?: string
}

const PublicationModalMain = (props: PublicationMainProp): JSX.Element => {
  const classes = usePublicationMainStyles()

  let { post, onModalToggle = () => {}, headingType, contestModal } = props

  const useCommentProps = useComments(post.id)
  const { addComment, firstFetch } = useCommentProps

  useEffect(() => {
    firstFetch()
  }, [post?.id])

  const { meme, contentType } = post
  const { renderedImage, thumbnailImage } = meme ?? {}
  const isPlayable = checkIsPostContentPlayable(contentType)

  const { x1 } = getImageResolutions(
    isPlayable ? thumbnailImage! : renderedImage!
  )

  return (
    <>
      <Box
        className={classes.dialogLeftSideContent}
        style={{
          backgroundImage: `url(${x1})`
        }}
      >
        <PublicationContent {...props} modal onToggle={onModalToggle} />
      </Box>
      <Box className={classes.dialogRightSideContent}>
        <Box className={classes.publicationDetailWrapper}>
          <PublicationHeader {...props} />
          <PublicationContentTitle post={post} headingType={headingType} />
          <PublicationContentDescription {...props} />
          <Box display='flex' justifyContent='space-between' px={0}>
            <PublicationReaction post={post} />
            {!contestModal && (
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: '.5rem'
                }}
              >
                <PublicationCommentCount onModalToggle={() => {}} />
                <PublicationShare post={post} />
              </Box>
            )}
          </Box>
          <PublicationTag tags={post.tags} />
        </Box>
        <Box className={classes.publicationCommentList}>
          <PublicationCommentList
            {...props}
            useCommentListProps={{ ...useCommentProps }}
          />
        </Box>
        <Box className={classes.publicationAddComment}>
          <PublicationAddComment {...props} addComment={addComment} />
        </Box>
      </Box>
    </>
  )
}

export default PublicationModalMain
