/**
 * NOTE: Auth APIs have been written in multiple places.
 * This is the only one that is used. Others should be
 * deleted.
 */
import { TokensInterface, User } from '../../types/runtimeSchema'
import { getTokenJSON, getUserObjFromLocalStorageWithUserSchema, storeUserObjInLocalStorageWithUserSchema } from 'utils/utils'
import { inputRegex } from '../../constants/variables'
import { youMemeAxiosInstance } from 'services/axios/axios'
import { DefaultYMCA } from 'ymca/ymca'
import { User as UserModel } from 'ymca/models/user.model'


export const sendEmail = async (email: string, referrer?: string): Promise<any> => {
  return await DefaultYMCA.selfService.sendLoginOrRegistrationEmail(email, referrer);
}

export const verifyAuthenticationToken = async (authToken: string): Promise<any> => {
  const parts = authToken.split('.')
  if (parts.length !== 3) {
    const msg = `Invalid token format. Expected 3 parts, got ${parts.length}`
    console.error(msg)
    throw new Error(msg)
  }
  try {
    const tokenStrBase64 = parts[1]
    const tokenStr = atob(tokenStrBase64)
    const token = JSON.parse(tokenStr)
    const tExpiry = token.exp * 1000
    const dExpiry = (new Date(tExpiry)).toString()
    const tNow = Date.now()
    if (tExpiry <= tNow) {
      const msg = `Token expired at ${dExpiry}`
      console.error(msg)
      throw new Error(msg)
    }
    window.sessionStorage.setItem('lastValidatedAuthToken', authToken)
    return { verified: true }
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const verifyUsername = async (username: string): Promise<boolean> => {
  const response = await youMemeAxiosInstance.post<{ available: boolean }>(
    '/auth/is-username-available', { username }
  )
  return response.data.available
}

export const checkUsernameValidation = (username: string): boolean => {
  if (inputRegex.test(username)) {
    return true
  }

  return false
}

export const completeLogin = async (token: string, source: string = 'email', referrer?: string): Promise<User> => {
  let reqURL = `/auth/complete-login?source=${source}`
  if (referrer) {
    reqURL += `&referrer=${referrer}`
  }
  const response = await youMemeAxiosInstance.post<{
    accessToken: string
    sessionToken: string
    user: UserModel
  }>(reqURL, {token})

  const responseData = response.data

  return {
    token: responseData.accessToken,
    sessionToken: responseData.sessionToken,
    userData: responseData.user
  }
}

export const completeRegistration = async (
  userInfo: any
): Promise<User> => {
  const response = await youMemeAxiosInstance.post<{
    accessToken: string
    sessionToken: string
    user: UserModel
  }>('/auth/complete-registration', userInfo)

  const responseData = response.data

  return {
    token: responseData.accessToken,
    sessionToken: responseData.sessionToken,
    userData: responseData.user
  }
}

const checkForAccessTokenExpiry = (accessToken: string): boolean => {
  const { exp } = getTokenJSON(accessToken)

  const currentTime: number = Date.now()
  const expirationTimeStamp: number = exp * 1000

  return currentTime >= expirationTimeStamp
}

export const rotateUserTokens = (tokensObj: TokensInterface, store: boolean = false): any => {
  const userCredentials = getUserObjFromLocalStorageWithUserSchema()
  const newUserCredentials = { ...userCredentials, ...tokensObj }

  if (!store) return newUserCredentials
  storeUserObjInLocalStorageWithUserSchema(newUserCredentials)
}

const refreshAuthToken = async (sessionToken: string): Promise<TokensInterface> => {
  const response = await youMemeAxiosInstance.post<TokensInterface>('/auth/refresh-access-token', {
    token: sessionToken
  })
  return response.data
}

export const persistUserSession = async (): Promise<any> => {
  const { token: accessToken, sessionToken } = getUserObjFromLocalStorageWithUserSchema()
  const accessTokenHasExpired: boolean = checkForAccessTokenExpiry(accessToken)

  if (!accessTokenHasExpired) return

  const newTokens = await refreshAuthToken(sessionToken)
  return newTokens
}
